import React, { useState } from 'react'
import { Dialog, DialogContent, DialogTitle, withStyles, DialogActions, Button, TextField } from '@material-ui/core';
import { copyMultipleOffers } from '../Requests';
import { showErrorNotification, showSuccessNotification } from '../../../../helpers/UtilHelper';
import createDialog from '../../../../DialogRenderer';

export async function openDialogCopyOffer(offerId) {
    return createDialog(({ show, onSubmit, onDismiss }) => (
        <DialogCopyOffer
            offerId={offerId}
            open={show}
            onCancel={onDismiss}
            onConfirm={onSubmit}
        />
    ));
}

function DialogCopyOffer(props) {
    const { offerId, onCancel, onConfirm, open } = props;
    const [selectedJob, setSelectedJob] = useState(undefined);
    const [selectedPage, setSelectedPage] = useState(undefined);
    const [errorSelectedJob, setErrorSelectedJob] = useState("");
    const [errorSelectedPage, setErrorSelectedPage] = useState("");
    const [loading, setLoading] = useState(false);

    const copyOfferToPublication = async () => {
        if (!selectedJob || selectedJob.length === 0) {
            setErrorSelectedJob("Feltet er påkrævet");
            return;
        }
        if (!selectedPage || selectedPage.length === 0) {
            setErrorSelectedPage("Feltet er påkrævet");
            return;
        }

        try {
            setLoading(true);
            await copyMultipleOffers(offerId, selectedJob, selectedPage);
            showSuccessNotification(`Tilbuddet er blevet kopieret til ${selectedJob} side ${selectedPage}`);
            onConfirm();
            setLoading(false);
        } catch (e) {
            showErrorNotification("Noget gik galt, prøv venligst igen.");
            setLoading(false);
        }
    }

    return (
        <Dialog
            open={open}
            onClose={onCancel}>
            <DialogContent style={{ width: 400 }}>
                <DialogTitle>Kopier tilbud til publikation</DialogTitle>
                <DialogContent>
                    <TextField
                        margin="dense"
                        label="Job nummer"
                        type="text"
                        name="jobNummer"
                        value={selectedJob}
                        onChange={(ev) => setSelectedJob(ev.target.value)}
                        InputProps={{
                            multiline: false
                        }}
                        fullWidth
                        error={!!errorSelectedJob}
                        helperText={errorSelectedJob}
                    />
                    <TextField
                        margin="dense"
                        label="Side"
                        type="text"
                        name="side"
                        value={selectedPage}
                        onChange={(ev) => setSelectedPage(ev.target.value)}
                        InputProps={{
                            multiline: false
                        }}
                        fullWidth
                        error={!!errorSelectedPage}
                        helperText={errorSelectedPage}
                    />
                </DialogContent>
                <DialogActions>
                    <Button
                        onClick={onCancel}
                        disabled={loading}
                    >
                        Annuller
                    </Button>
                    <Button
                        color="primary"
                        onClick={copyOfferToPublication}
                        disabled={loading}
                    >
                        Kopier
                    </Button>
                </DialogActions>
            </DialogContent>
        </Dialog>)
}
