import React from 'react';
import { Dialog, DialogTitle, DialogContent,
    DialogActions,  } from '@material-ui/core';


export default function DialogPreviewImage({
    onClose,
    imgUrl
}) {
    return (
        <Dialog
            onClose={onClose}
            open={true}
            maxWidth="xl"
    >
        <DialogTitle>
        </DialogTitle>
        <DialogContent >
            <div
                style={{
                    backgroundImage: `url(${imgUrl})`,
                    width: 500,
                    height: 700,
                    backgroundSize: "cover",
                    backgroundPosition: "center"
                }}
            >

            </div>
        </DialogContent>
        <DialogActions >
        </DialogActions> 
    </Dialog>
    );

}