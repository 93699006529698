import React, { Component } from "react";

// Dropzone new
import { FilePond } from "react-filepond";
import "filepond/dist/filepond.min.css";
import "filepond-plugin-image-preview/dist/filepond-plugin-image-preview.min.css";
import { allowedPhotoFormatArray } from "../../../../constants/enums";
import {validateFileExtentionOnUpload} from "../../../shared/SharedFunctions";

export default class UploadTab extends Component {
  render() {
    const {
      files,
      handleSetfile
    } = this.props;
    return (
      <>
      <div style={{minHeight:500}}>
        <FilePond
          allowImagePreview={true}
          files={files}
          onupdatefiles={handleSetfile}
          allowMultiple={false}
          // maxFiles={99}
          imagePreviewHeight={600}
          beforeAddFile={(item) => validateFileExtentionOnUpload(item.file)}
          labelIdle={
            'Træk og slip din billedfil eller <span class="filepond--label-action">Gennemse</span>'
          }
        />
        </div>
      </>
    );
  }
}
