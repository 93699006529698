import React from 'react'
import { useState, useEffect } from 'react';
import { Typography } from '@material-ui/core';
import { fetchDeleteWT } from '../../../../helpers/HttpHelper';
import { endpoints } from '../../../../constants/services.constants';
import { Dialog } from '@material-ui/core';
import { getScreens } from './SharedRequests';
import DescriptionButton from './sharedComponents/DescriptionButton';
import { styles } from './shared';
import { openDialogConfirmDelete } from '../../../shared/dialogs/DialogConfirm';
import CustomArrow from './sharedComponents/CustomArrow';

export default function MainMenuCarousel(props) {
    const take = 7;
    const [visibleIndex, setVisibleIndex] = useState(0);
    const [screens, setScreens] = useState([]);


    const [loading, setLoading] = useState(true);

    const MINUTE_MS = 5000;
    useEffect(() => {
        getData();
        const interval = setInterval(() => {
            getData();
        }, MINUTE_MS);
        return () => {
            clearInterval(interval);
        }
    }, []);

    const getData = async () => {
        setLoading(true);
        const _screens = await getScreens(props.customer);
        setScreens(_screens);
        setLoading(false);
    }


    const onArrowClick = (direction) => {
        const increment = direction === 'left' ? -1 : 1;
        const newIndex = (visibleIndex + increment + screens.length) % screens.length;

        if (
            newIndex >= 0
            && newIndex + take <= screens.length
        )
            setVisibleIndex(newIndex);

        setTimeout(() => {
        }, 0);
    };



    if (!screens || screens.length === 0) {
        if (loading === true) return <Typography align="center" style={{ marginTop: 100 }}>Henter data...</Typography>
        else return (<div></div>);
    }
    const itemsToRender = screens.length < take + 1 ? screens
        : screens.slice(visibleIndex, visibleIndex + take);


    return (
        <>
            <div className='carouselContainer'>

                {/* Left arrow */}
                {visibleIndex > 0 && screens && screens.length > 1 &&
                    <div style={{ cursor: "pointer" }} onClick={() => onArrowClick('left')}>
                    <CustomArrow
                        className={styles.customArrowBack} />
                    </div>
                }


                <div style={{ width: '100%', display: 'inline-flex', justifyContent: 'center' }}>
                    {itemsToRender.map((z, i) =>
                        <MainMenuCarouselItem
                            item={z}
                            key={z.id}
                            onDeleted={getData}
                            {...props}
                        />
                    )
                    }
                </div>

                {/* Right arrow */}
                {screens && visibleIndex + take < screens.length &&
                    <div style={{ cursor: "pointer" }} onClick={() => onArrowClick('right')}>
                    <CustomArrow />
                    </div>
                }
            </div>

        </>
    );
}

function MainMenuCarouselItem({ item, editScreen, onDeleted }) {

    const [videoDialog, setVideoDialog] = useState(null)
    function download(fileUrl, fileName) {
        var a = document.createElement("a");
        a.href = fileUrl;
        a.setAttribute("download", fileName);
        a.setAttribute("target", "_blank");
        a.click();
    }

    function renderVideoDialog() {
        return (
            <Dialog
                onClose={() => setVideoDialog(null)} open={true}>
                <video height={700} src={videoDialog} autoPlay controls loop>
                    Your browser does not support the video tag.
                </video>

            </Dialog>
        );
    }
    async function handleDelete() {
        const message = `Ønsker du at slette skærme?`;
        const confirmed = await openDialogConfirmDelete("Slet skærme", message);
        if (confirmed) {
            setDeleting(true);
            fetchDeleteWT(endpoints.FILMMAKER_SCREEN.replace("{id}", item.id), null,
                (r) => { onDeleted(); setDeleting(false); },
                (e) => { console.error(e); setDeleting(false); }
            );
        }

    }
    const [deleting, setDeleting] = useState(false);

    return (<div
        style={{
            width: 130,
            margin: "0 5px"
        }}
    >
        {
            item.state === 4 ? <img
                style={{ cursor: "pointer" }}
                onClick={() => setVideoDialog(endpoints.STORAGE_PUBLIC_FILMMAKER.replace("{path}",item.urlRelative))}
                width="130"
                height="240"
                src={endpoints.STORAGE_PUBLIC_FILMMAKER.replace("{path}",item.urlThumbnailRelative)}
                alt={item.name}
            />
                : <img
                    width="130"
                    height="240"
                    src={endpoints.STORAGE_PUBLIC_FILMMAKER.replace("{path}",item.urlThumbnailRelative)}
                    alt={item.name}
                />
        }
        <br />
        <DescriptionButton
            disabled={deleting}
            onClick={() => editScreen(item)}
            text={item.name}
            style={styles.boldButton}
        />
        {
            [0, 1].includes(item.state) ? <p style={styles.itemDescription}>Draft</p>
                : item.state === 2 ? <p style={styles.itemDescription}>Pending</p>
                    : item.state === 3 ? <p style={styles.itemDescription}>Rendering</p>
                        : item.state === 5 ? <p style={styles.itemDescription}>Error</p>
                            : item.state === 4 ?
                                <p
                                    onClick={() => download(endpoints.STORAGE_PUBLIC_FILMMAKER.replace("{path}",item.urlRelative), item.name + ".mp4")}
                                    style={styles.itemDescriptionAction}
                                > Download MP4</p>
                                : "State " + item.state
        }
        <DescriptionButton
            disabled={deleting || item.state === 3}
            onClick={handleDelete}
            fullWidth
            text="Delete"
            style={styles.underlineButton}
        />
        {videoDialog == null ? undefined : renderVideoDialog()}

    </div>
    )
}
