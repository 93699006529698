import { getOffersUsingPhoto, getPublicationsForOffers, getLatestValidToOfferDatePhotoIsUsed } from "./Requests";
import {showErrorNotification} from "../../helpers/UtilHelper";

export async function GetPublicationsDatePhotoWasUsed(id) {
    // used to check if there are any active publications where photo is used
    const dateNow = new Date();
    let publications = [];
    let dateValidTo = undefined;
    const offers = await getOffersUsingPhoto(id);

    if (offers.length > 0) {
        publications = await getPublicationsForOffers(offers.map(x => x.id));
    }

    if (publications.length > 0) {
        dateValidTo = publications.find(x => dateNow < Date.parse(x.endDate));
    }

    return {
        publications: publications,
        dateValidTo: dateValidTo
    }
}

export async function GetLatestOfferValidToDateWherePhotoIsUsed(motivId) {
    return await getLatestValidToOfferDatePhotoIsUsed(motivId);
}

export function allowUsingExclusivePhoto(exclusiveForCustomersArray, customerId) {
    return exclusiveForCustomersArray.length === 0 ||
        exclusiveForCustomersArray.includes(customerId);
}

export function validateFileExtentionOnUpload(file) {

    let fileTypeIsValid = true;

    const allowedFileExentions = new Set(["jpeg", "jpg", "psd", "png" ]);

        var fileType = file.name.split(".").pop().toLowerCase();

        if (!allowedFileExentions.has(fileType)) {
            fileTypeIsValid = false;
            showErrorNotification("Du må kun uploade jpeg, jpg, psd, png filer");
        } 

    return fileTypeIsValid;
}