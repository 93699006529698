import React, { Component } from 'react'
import { connect } from "react-redux";
import { Typography } from '@material-ui/core';
import packageJson from "../../../../package.json";
import { Helmet } from 'react-helmet';

class Support extends Component {
    constructor(props) {
        super(props);
        this.state = {
            queryString: ""
        };
    }
 
    render() {
        return (
            <>
                <Helmet>
                    <title>Support</title>
                </Helmet>
            <div style={{
                textAlign : "center",
                position: "absolute",
                top: "50%",
                left: "50%",
                height: 500,
                width: 400,
                transform: `translateY(-50%)`
              }}>
                <img src="/images/mb2-logo2.svg" alt="Multibasen logo" style={{width: "300px",}}></img>
                    <h1>Multibasen
                        <span>
                    {packageJson.version} {packageJson.ci_build_Number}
                        </span>
                    </h1>
               <Typography variant="p" gutterBottom align="center">
                    Hvis du oplever problemer med MultiBasen, eller har spørgsmål til funktionalitet, kan du kontakt support på <a href="mailto:mbsupport@republica.dk">mbsupport@republica.dk</a>
                </Typography>
            </div>
            </>
        )
    }
}
export default connect(state => state)(Support);