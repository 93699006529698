import { Button, Checkbox, FormControlLabel, MuiThemeProvider, TextField, Typography } from '@material-ui/core';
import { DatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import { RepublicaDatePickerMuiTheme } from "../../../../../src/RepublicaMuiTheme";
import React, { useEffect, useState } from 'react';
import MomentUtils from "@date-io/moment";
import moment from "moment";
import { fetchPhoto, fetchPhotoOrderV2, updatePhotoValidToDate } from '../SharedRequests';
import { showErrorNotification } from "../../../../helpers/UtilHelper";

function ExpirePhotoForm(props) {
    //PhotoOrderID is from multiPhotoorder in database
    const { photoOrderID } = props

    //Photo handles from multiPhoto in database
    const [photo, setPhoto] = useState(undefined);
    const [validToDateCheckBox, setValidToDateCheckBox] = useState(false)
    const [validToDate, setValidToDate] = useState(undefined)
    let todayDate = new Date()

    useEffect(() => {
        const Fetching = async () => {
            const getPhotoOrder = await fetchPhotoOrderV2(photoOrderID)
            const getPhoto = await fetchPhoto(getPhotoOrder.photoId)
            setPhoto(getPhoto)
            var date = getPhoto.validTo
            if (getPhoto.validTo && getPhoto.validTo.length > 20) {
                date = getPhoto.validTo.slice(0, 19)
            }
            if (date) {
                setValidToDateCheckBox(true)
            }
            setValidToDate(date)

        }
        Fetching();
    }, [null]) //do not update

    const handleCheckboxChange = () => {
        setValidToDateCheckBox(!validToDateCheckBox);
        ShowValidDateInput()
    };

    function UpdateValidDateOnPhoto() {
        if (Date.parse(validToDate) < todayDate) {
            showErrorNotification("Udløbsdatoen skal være efter dags dato")
            return
        }
        if (validToDate == null) {
            updatePhotoValidToDate(photo.id, "")
        } 
        else {
            updatePhotoValidToDate(photo.id, validToDate)
        }
        setValidToDateCheckBox(false)
    }

    function ShowValidDateInput() {
        todayDate.setMonth(todayDate.getMonth() + 3) // assuring for validFrom date and End Date isn't the same.
        todayDate = todayDate.toISOString().slice(0, 19)
        if (photo != undefined && photo.validTo != null && validToDate) { // Fetch objects have happen
            return
        }
        setValidToDate(todayDate)
    }

    function UpdateValidDateOnPhotoBody() {
        if (validToDateCheckBox) // only show when it's necessary 
        {
            return <>
                <Button variant='contained'
                    color="success"
                    style={{ marginLeft: "10px" }}
                    onClick={() => UpdateValidDateOnPhoto()}>
                    OPDATÉR
                </Button>
            </>
        }
    }

    function handleDateChangeTo(newDate) {
        ShowValidDateInput()
        if (newDate != null) {
            if (Date.parse(newDate._d.toISOString()) < new Date()) { //handles wrong inputs
                showErrorNotification("Udløbsdatoen skal være efter dags dato")
                return
            }
            setValidToDate(newDate._d.toISOString())
        } else {
            setValidToDate(null)
            setValidToDateCheckBox(!validToDateCheckBox)
        }
    }

    return (
        <MuiPickersUtilsProvider utils={MomentUtils} moment={moment}>
            <MuiThemeProvider theme={RepublicaDatePickerMuiTheme}>
                <div>
                    <FormControlLabel
                        control={
                            <>
                                <Checkbox
                                    name="checkboxPhotoOrderExpireDate"
                                    checked={validToDateCheckBox}
                                    onChange={handleCheckboxChange}
                                    />
                                <Typography style={{marginRight:"4%"}}>Udløbsdato:</Typography>
                                <div>

                                    <DatePicker
                                        minDateMessage="Udløbsdatoen er sat inden sidste brug af billedet"
                                        onChange={(date) => handleDateChangeTo(date)}
                                        label="Tilgængeligt til:"
                                        value={validToDate}
                                        clearable
                                        style={{ paddingRight: "2rem" }}
                                        format="Do[/]MM-YY"
                                        disabled={!validToDateCheckBox}
                                    />
                                </div>
                            </>
                        }
                    />
                    {UpdateValidDateOnPhotoBody()}
                </div>
            </MuiThemeProvider>
        </MuiPickersUtilsProvider>
    );
}

export default ExpirePhotoForm;