import React, { useEffect, useState, useContext } from 'react';
import { Typography, withStyles, Tooltip, Fab, LinearProgress } from '@material-ui/core';
import FeedsContextProvider, { FeedsContext } from '../contexts/FeedsContext';
import AddIcon from "@material-ui/icons/Add";
import DialogAdd from './Dialogs/DialogAdd';
import MediaContextProvider, { MediaContext } from '../contexts/MediaContext';
import MediaTable from './DataTable';
import DialogConfirmDelete from './Dialogs/DialogConfirmDelete';
import DialogCopyLink from './Dialogs/DialogCopyLink';

const styles = theme => ({
    root: {
        display: "grid",
        gridTemplateColumns: "auto 65px"
    },
    iconButton: {
        margin: "0px 0px 20px 15px"
    }
});

export function Media(props) {
    return (
        <MediaContextProvider>
            <FeedsContextProvider>
                <MediaBase {...props} />
            </FeedsContextProvider>
        </MediaContextProvider>
    )
}

function MediaBase(props) {
    const [showAddDialog, setShowAddDialog] = useState(false);
    const {
        media: mediaState,
        confirmDelete: confirmDeleteState,
        loading: loadingState,
        openCopyLinkDialog: openCopyLinkDialogState,
    } = useContext(MediaContext);
    const [media,] = mediaState;
    const [confirmDelete,] = confirmDeleteState;
    const [loading,] = loadingState;
    const [openCopyLinkDialog,] = openCopyLinkDialogState;

    const handleClose = () => {
        setShowAddDialog(false);
    }

    const { classes } = props;

    return (
        <>
            <Typography variant="h4">Medier</Typography>
            <div className={classes.root}>
                <div style={{ visibility: "hidden" }}>
                    {/* Search bar shoulud be here */}
                </div>
                <Tooltip title={"Opret feed"}>
                    <Fab
                        size="small"
                        color="primary"
                        className={classes.iconButton}
                        onClick={() => setShowAddDialog(true)}
                    >
                        <AddIcon />
                    </Fab>
                </Tooltip>
            </div>
            {showAddDialog ? <DialogAdd open={showAddDialog} handleClose={handleClose} /> : undefined}
            {media && media.length !== 0 && !loading ? <MediaTable /> : media && media.length === 0 && !loading ? <Typography style={{ textAlign: "center" }} variant="h6">Ingen medier er fundet</Typography> : <LinearProgress />}
            {confirmDelete ? <DialogConfirmDelete open={true} /> : undefined}
            {openCopyLinkDialog ? <DialogCopyLink open={true} /> : undefined}
        </>
    )
}

export default withStyles(styles)(Media)
