import { IconButton, Tooltip } from '@material-ui/core';
import React from 'react';

export const ActionButtonSimple = (props) => {
    const { toolTipTitle, className, color, onClick, icon, showMenu, disabled, name } = props;

    return (
        <Tooltip
            title={toolTipTitle}
        >
            <IconButton
                className={!className ? "float-left smaller_icon" : className}
                color={!color ? "primary" : color}
                aria-label={toolTipTitle}
                onClick={(e) => onClick(e)}
                aria-owns={showMenu} // allows dropdown menu
                disabled={disabled}
                name={name}
                id={"buttonGeneric"+name}
            >
                {icon}
            </IconButton>
        </Tooltip>
    );
}