import store from "../store";
import { addToastMessage } from "../actions/toasts.actions";

export function handleApiSuccessNotificaiton(message) {
  store.dispatch(
    addToastMessage({
      message: message,
      type: "success",
      details: null
    })
  );
}

export function handleApiErrorNotification(message) {
  store.dispatch(
    addToastMessage({
      message: message,
      type: "error",
      details: null
    })
  );
}
