import React from 'react';
import Button from '@material-ui/core/Button';
import FormControl from '@material-ui/core/FormControl';
import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
import Typography from '@material-ui/core/Typography';
import withStyles from '@material-ui/core/styles/withStyles';
import { getSessionToken, requestResetCode } from '../../helpers/AuthHelper/AuthHelperV2';
import { stringIsEmpty } from '../../helpers/UtilHelper';

const styles = theme => ({
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    marginTop: theme.spacing(3),
  },
  linkButton: {
    textTransform: 'none',
  },
  errorContainer: {
    color: "#ed2424",
    textAlign: "center",
    marginTop: 15
  }
});

class Login extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      username: '',
      password: '',
      loading: false,
      forgetPassword: false,
      forgetPasswordSent: false,
      validationMessage: null,
      error: false,
      errorText: ""
    };
    this.baseState = { ...this.state };
  }

  componentDidMount() {
    if (this.props.error && this.props.errorText !== "") {
      this.setState({
        error: this.props.error,
        errorText: this.props.errorText
      })
    }
  }
  handleInputChange = (event) => {
    const { value, name } = event.target;
    this.setState({
      [name]: value
    });
  }

  async doLogin() {
    this.setState({
      'loading': true
    });
    let r = await getSessionToken(this.state.username, this.state.password);

    //if(r is false there was an error logging in?);

    if (r.resultLogin) {
      this.setState({
        error: false,
        errorText: ""
      })
      window.location.reload();
    } else {
      this.setState({
        'loading': false,
        error: true,
        errorText: r.errorMsg,
      });
    }
  }

  async doRequestResetCode() {
    if (stringIsEmpty(this.state.username)) {
      this.setState({
        validationMessage: "Brugernavn skal udfyldes"
      });
      return;
    }

    this.setState({
      'loading': true,
      validationMessage: null
    });


    requestResetCode(this.state.username,
      (data) => {
        this.setState({ forgetPasswordSent: true, validationMessage: "", loading: false });
      },
      (error) => this.setState({ validationMessage: "Noget gik galt", loading: false }));
  }

  handleLogin = (event) => {
    event.preventDefault();
    this.doLogin();
  }

  handleForgetPassword(event) {
    event.preventDefault();
    this.setState({ forgetPassword: true })
  }

  handleSendResetCode(event) {
    event.preventDefault();
    this.doRequestResetCode();
  }


  handleComeBackToLogin() {
    this.setState(this.baseState);
  }

  renderLogin() {
    const { classes } = this.props;
    return (<>
      <Typography component="h1" variant="h5" style={{ textAlign: "center" }}>
        Velkommen til
      </Typography>
      <Typography component="h1" variant="h5" style={{ textAlign: "center" }}>
        MultiBasen
      </Typography>

      {this.state.error ? (<Typography className={classes.errorContainer}>{this.state.errorText}</Typography>) : undefined}

      <Typography variant="subtitle1" style={{ textAlign: "center", paddingTop: 20 }}>
        Log ind
      </Typography>
      <form className={classes.form} onSubmit={this.handleLogin}>
        <FormControl margin="normal" required fullWidth>
          <InputLabel htmlFor="email">Brugernavn</InputLabel>
          <Input
            id="username" autoComplete="username" autoFocus
            type="text"
            name="username"
            placeholder="Indtast brugernavn"
            value={this.state.username}
            onChange={this.handleInputChange}
            required
          />
        </FormControl>
        <FormControl margin="normal" required fullWidth>
          <InputLabel htmlFor="password">Kodeord</InputLabel>
          <Input
            type="password"
            name="password"
            id="password"
            placeholder="Indtast kodeord"
            autoComplete="current-password"
            value={this.state.password}
            onChange={this.handleInputChange}
            required
          />
        </FormControl>
        {!this.state.loading ?
          <><Button
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            className={classes.submit}
          >
            Log ind
            </Button>
            <br />
            <br />
            <Button variant="text" className={classes.linkButton}
              onClick={this.handleForgetPassword.bind(this)}
            >
              Har du glemt dit kodeord?
            </Button>
          </>
          :
          <Button
            fullWidth
            variant="contained"
            color="primary"
            className={classes.submit}
            disabled={true}
          >Øjeblik...
          </Button>
        }
      </form>
    </>
    );
  }

  renderSendForgetPasswordCode() {
    const { classes } = this.props;

    return (<>
      <Typography component="h1" variant="h5" style={{ textAlign: "center" }}>Har du glemt dit kodeord?</Typography>
      {!this.state.forgetPasswordSent ?
        <form className={classes.form} onSubmit={this.handleLogin}>
          <FormControl margin="normal" required fullWidth>
            <InputLabel htmlFor="email">Brugernavn</InputLabel>
            <Input
              id="username" autoComplete="username" autoFocus
              type="text"
              name="username"
              placeholder="Indtast brugernavn"
              value={this.state.username}
              onChange={this.handleInputChange}
              required
            />
          </FormControl>

          <br />
          {!this.state.loading ?
            <>
              <Button
                // type="submit"
                fullWidth
                variant="contained"
                color="primary"
                className={classes.submit}
                onClick={this.handleSendResetCode.bind(this)}
              >
                Send
                </Button>
              <Button
                fullWidth
                variant="contained"
                color="default"
                className={classes.submit}
                onClick={this.handleComeBackToLogin.bind(this)}
              >Tilbage
                </Button>

            </>
            :
            <>
              <Button
                fullWidth
                variant="contained"
                color="primary"
                className={classes.submit}
                disabled={true}
              >Henter
          </Button>
              <Button
                fullWidth
                variant="contained"
                color="default"
                className={classes.submit}
                disabled={true}

              // onClick={this.handleComeBackToLogin.bind(this)}
              >Tilbage til Login
                </Button>

            </>
          }

          {this.state.validationMessage != null ? <><br /><br /><Typography variant="body1" color="error" align="center">{this.state.validationMessage}</Typography></> : undefined}
        </form>
        : <>
          {/* {this.state.forgetPasswordTextSent === true ?
              <> */}
          <br />
          <Typography component="p" variant="body1">
            Hvis du har en konto, vil der blive sendt en email med instruktioner til dig.</Typography>
          <Button
            fullWidth
            variant="contained"
            color="primary"
            className={classes.submit}
            onClick={this.handleComeBackToLogin.bind(this)}
          >Tilbage til Login
                </Button>
          {/* </>
              : undefined */}
          {/* } */}
        </>
      }

    </>)
  }
  render() {
    return (
      this.state.forgetPassword ?
        this.renderSendForgetPasswordCode()
        : this.renderLogin()
    );
  }
}

export default withStyles(styles)(Login);