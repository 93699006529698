import React, { useContext, useEffect, useState } from 'react';
import MUIDataTable from 'mui-datatables';
import { getLongDate } from '../../../../helpers/UtilHelper';
import { IconButton } from '@material-ui/core';
import DeleteIcon from "@material-ui/icons/Delete";
import { CollectionsContext } from '../contexts/CollectionsContext';
import store from '../../../../redux/store';
import { addToastMessage } from '../../../../redux/actions/toasts.actions';

function CollectionsTable(props) {
    const { collections: collectionsState, collectionFeeds: collectionFeedsState } = useContext(CollectionsContext);
    const [collections,] = collectionsState;
    const [collectionFeeds,] = collectionFeedsState;

    const columns = [
        {
            name: "Id",
            options: {
                filter: false,
                sort: false,
                display: false,
            }
        },
        {
            name: "Kunde",
            options: {
                filter: false,
                sort: false,
                display: true,
            }
        },
        {
            name: "Navn",
            options: {
                filter: false,
                sort: false,
                display: true,
            }
        },
        {
            name: "Type",
            options: {
                filter: false,
                sort: false,
                display: true,
            }
        },
        {
            name: "Senest hentet",
            options: {
                filter: false,
                sort: false,
                display: true,
            }
        },
        {
            name: "Antal feeds",
            options: {
                filter: false,
                sort: false,
                display: true,
            }
        },
        // {
        //     name: "",
        //     options: {
        //         filter: false,
        //         sort: false,
        //         display: true,
        //     }
        // },
    ]

    const handleDelete = async (collectionIds) => {
        // await deleteCollections(collectionIds);
        // const updateCollections = await fetchCollections();
        // setCollections(updateCollections);
        store.dispatch(
            addToastMessage({
                message: "Det er ikke muligt at slette collections i øjeblikket.",
                type: "error",
                details: null
            })
        );

        return;
    }

    const options = {
        textLabels: {
            body: {
                noMatch: "",
                toolTip: "Sorter"
            },
            pagination: {
                next: "Næste side",
                previous: "Forrige side",
                rowsPerPage: "Collections per side:",
                displayRows: "af"
            },
            selectedRows: {
                text: "valgt række(r)",
            }
        },
        filter: false,
        filterType: "checkbox",
        rowsPerPage: 15,
        print: false,
        responsive: "scrollFullHeight",
        download: false,
        viewColumns: false,
        search: false,
        selectableRows: true,
        expandableRows: false,
        customToolbarSelect: (selectedRows) => {
            let collectionIds = [];
            selectedRows.data.forEach(x => collectionIds.push(collections[x.dataIndex].collectionId));
            return (
                <div className={"custom-toolbar-select"}>
                    <IconButton
                        color="primary"
                        onClick={() => handleDelete(collectionIds)}
                        style={{
                            marginRight: "24px",
                            display: "inlineBlock",
                            position: "relative",
                            top: "50%",
                        }}
                        className="float-left smaller_icon"
                    >
                        <DeleteIcon />
                    </IconButton>
                </div>)
        },
    }

    const getDataTable = () => {
        const dateType = /(\d{4})([\/-])(\d{1,2})\2(\d{1,2})/;

        return collections.map((item) => {
            return [
                item.collectionId,
                item.customer ? item.customer.name : "",
                item.name ? item.name.name : "",
                item.type ? item.type.type : "",
                item.dates && item.dates.lastUpdated && dateType.test(item.dates.lastUpdated) ? getLongDate(item.dates.lastUpdated) : item.dates.lastUpdated,
                collectionFeeds.size !== 0 ? collectionFeeds.get(item.collectionId) : "",

                // Action buttons are temporary disabled we need to figure out how to check if collection has feed and other stuff before deleting
                // <ActionButtons item={item} fetchCollections={() => fetchCollections()} />
            ]
        }
        )
    }

    return (
        <>
            <MUIDataTable
                data={getDataTable(collections)}
                columns={columns}
                options={options}
            />
        </>
    )
}
export default CollectionsTable