import EditIcon from "@material-ui/icons/Edit";
import PhotoSizeSelectLargeIcon from "@material-ui/icons/PhotoSizeSelectLarge";
import DeleteIcon from "@material-ui/icons/Delete";
import DownloadIcon from "@material-ui/icons/CloudDownload";
import React from "react";
import ImageIcon from "@material-ui/icons/Image";
import AddAPhotoIcon from "@material-ui/icons/AddAPhoto";
import MessageIcon from "@material-ui/icons/Message";
import MoreVertIcon from "@material-ui/icons/MoreVert";

export const EditIconComponent = <EditIcon />;
export const ConvertIconComponent = <PhotoSizeSelectLargeIcon />;
export const DeletetIconComponent = <DeleteIcon />;
export const DownloadIconComponent = <DownloadIcon />;
export const ImageIconComponent = <ImageIcon />;
export const AddPhotoIconComponent = <AddAPhotoIcon />;
export const MessageIconComponent = ({ className }) => <MessageIcon className={className} />;
export const MoreVertIconComponent = <MoreVertIcon />;