import React, { useContext } from "react";
import { Tooltip, IconButton } from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';
import { MediaContext } from "../contexts/MediaContext";
import DownloadIcon from "@material-ui/icons/CloudDownload";
import { downloadLatestMediaRequest, produceMediaRequest } from "./Logic/Requests";
import PermMediaIcon from '@material-ui/icons/PermMedia';
import FileCopyIcon from '@material-ui/icons/FileCopy';
import { getShortDate } from "../../../../helpers/UtilHelper";

function ActionButtons(props) {
    const {
        confirmDelete: confirmDeleteState,
        selectedMedia: selectedMediaState,
        openCopyLinkDialog: openCopyLinkDialogState,
    } = useContext(MediaContext);
    const [, setSelectedMedia] = selectedMediaState;
    const [, setConfirmDelete] = confirmDeleteState;
    const [, setOpenCopyLinkDialog] = openCopyLinkDialogState;

    const { item } = props;

    const downloadMedia = (mediaDefinitionId) => {
        let mediaName = (item.mediaName.name).replace(/ /g, "");
        let date = getShortDate(new Date()).replace("-", "");
        let expression = /\//g;
        date = date.replace(expression, "");
        mediaName = `${mediaName}.${date}`;
        downloadLatestMediaRequest(mediaDefinitionId, mediaName);
    }

    const copyMediaLink = () => {
        setSelectedMedia(item);
        setOpenCopyLinkDialog(true);
    }

    return (
        <>
            <Tooltip title={"Slet collection"}>
                <span>
                    <IconButton
                        className="float-left smaller_icon"
                        color="primary"
                        aria-label="overflow button"
                        onClick={() => { setConfirmDelete(true); setSelectedMedia(item) }}
                    >
                        <DeleteIcon />
                    </IconButton>
                </span>
            </Tooltip>
            {item.mediaTransport.id === 4 ? (
                <Tooltip title={"Copy link"}>
                    <IconButton
                        className="float-left smaller_icon"
                        color="primary"
                        onClick={() => copyMediaLink(item.mediaDefinitionId)}
                    >
                        <FileCopyIcon />
                    </IconButton>
                </Tooltip>

            ) : (
                    <>
                        <Tooltip title={"Hent"}>
                            <IconButton
                                className="float-left smaller_icon"
                                color="primary"
                                onClick={() => downloadMedia(item.mediaDefinitionId)}
                            >
                                <DownloadIcon />
                            </IconButton>
                        </Tooltip>
                        <Tooltip title={"Generate media"}>
                            <IconButton
                                className="float-left smaller_icon"
                                color="primary"
                                onClick={() => produceMediaRequest({
                                    mediaDefinitionId: item.mediaDefinitionId,
                                    customer: ""
                                })}
                            >
                                <PermMediaIcon />
                            </IconButton>
                        </Tooltip>
                    </>
                )}
        </>
    )
}

export default ActionButtons;