import {
  RESETPASSWORD_OPEN_BEGIN,
  RESETPASSWORD_CLOSE_BEGIN,
  RESETPASSWORD_BEGIN,
  RESETPASSWORD_FAILURE,
  RESETPASSWORD_SUCCESS
} from "../actions/users/resetpassword.users.acions";
import {
  CLEAN_USERS,
  CREATE_OPEN_BEGIN,
  CREATE_CLOSE_BEGIN,
  FETCH_USERS_BEGIN,
  FETCH_USERS_SUCCESS,
  FETCH_USERS_FAILURE,
  GET_USER_BEGIN,
  GET_USER_SUCCESS,
  GET_USER_FAILURE,
  SEARCH_USERS_BEGIN,
  SEARCH_USERS_SUCCESS,
  SEARCH_USERS_FAILURE,
  ADD_CUSTOMER_PERMITS_BEGIN,
  ADD_CUSTOMER_PERMITS_FAILURE,
  ADD_CUSTOMER_PERMITS_SUCCESS,
  REMOVE_CUSTOMER_PERMITS_BEGIN,
  REMOVE_CUSTOMER_PERMITS_SUCCESS,
  REMOVE_CUSTOMER_PERMITS_FAILURE,
  ADD_PERMITS_BEGIN,
  ADD_PERMITS_FAILURE,
  ADD_PERMITS_SUCCESS,
  REMOVE_PERMITS_BEGIN,
  REMOVE_PERMITS_SUCCESS,
  REMOVE_PERMITS_FAILURE,
} from "../actions/users/users.actions";
import {
  DELETE_CLOSE_BEGIN,
  DELETE_OPEN_BEGIN
} from "../actions/users/delete.users.actions";
import {
  EDIT_OPEN_BEGIN,
  EDIT_CLOSE_BEGIN
} from "../actions/users/edit.users.actions";
import { PERMITS_CLOSE, PERMITS_OPEN } from "../actions/users/permits.users.actions";

const initState = {
  id: null,
  resetpasswordDialog: false,
  editDialog: false,
  deleteDialog: false,
  createDialog: false,
  permitsDialog: false,
  loading: false,
  error: null,
  users: null,
  user: null,
  searchQuery: ""
};

export default (state = initState, action) => {
  switch (action.type) {
    //-------------------------------------------
    case RESETPASSWORD_OPEN_BEGIN:
      return {
        ...state,
        id: action.payload.id,
        resetpasswordDialog: true
      };
    case RESETPASSWORD_CLOSE_BEGIN:
      return {
        ...state,
        id: null,
        resetpasswordDialog: false,
        user: null
      };
    //-------------------------------------------
    case RESETPASSWORD_BEGIN:
      return {
        ...state,
        loading: true
      };
    case RESETPASSWORD_SUCCESS:
      return {
        ...state,
        loading: false
      };
    case RESETPASSWORD_FAILURE:
      return {
        ...state,
        loading: false
      };
    //-------------------------------------------
    case DELETE_OPEN_BEGIN:
      return {
        ...state,
        deleteId: action.payload.id,
        deleteUser: null,
        deleteDialog: true
      };
    case DELETE_CLOSE_BEGIN:
      return {
        ...state,
        deleteId: null,
        deleteUser: null,
        deleteDialog: false
      };
    //-------------------------------------------
    case CREATE_OPEN_BEGIN:
      return {
        ...state,
        createDialog: true
      };
    case CREATE_CLOSE_BEGIN:
      return {
        ...state,
        createDialog: false
      };
    //-------------------------------------------
    case FETCH_USERS_BEGIN:
      return {
        ...state,
        loading: true,
        error: null
      };
    case FETCH_USERS_SUCCESS:
      return {
        ...state,
        loading: false,
        users: action.payload.users
      };
    case FETCH_USERS_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload.error,
        users: null
      };
    //-------------------------------------------
    case SEARCH_USERS_BEGIN:
      return {
        ...state,
        loading: true,
        error: null
      };
    case SEARCH_USERS_SUCCESS:
      return {
        ...state,
        loading: false,
        users: action.payload.users
      };
    case SEARCH_USERS_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload.error,
        users: null
      };
    //-------------------------------------------
    case EDIT_OPEN_BEGIN:
      return {
        ...state,
        id: action.payload.id,
        editDialog: true
      };
    case EDIT_CLOSE_BEGIN:
      return {
        ...state,
        id: null,
        editDialog: false,
        user: null
      };
    //-------------------------------------------
    case PERMITS_OPEN:
      return {
        ...state,
        permitsId: action.payload.id,
        permitsDialog: true
      };
    case PERMITS_CLOSE:
      return {
        ...state,
        permitsId: null,
        permitsDialog: false,
        user: null
      };
    //-------------------------------------------
    case GET_USER_BEGIN:
      return {
        ...state,
        loadingUser: true,
        errorUser: null,
        id: null // set it to null, to handle infinite renders in componentDidUpdate()
      };
    case GET_USER_SUCCESS:
      return {
        ...state,
        loadingUser: false,
        user: action.payload.user
      };
    case GET_USER_FAILURE:
      return {
        ...state,
        loadingUser: false,
        errorUser: action.payload.user
      };

    //-------------------------------------------
    case ADD_CUSTOMER_PERMITS_BEGIN:
      return {
        ...state,
        loadingAddCustomerPermits: true,
        errorUser: null,
        // id: null // set it to null, to handle infinite renders in componentDidUpdate()
      };
    case ADD_CUSTOMER_PERMITS_SUCCESS:
      return {
        ...state,
        loadingAddCustomerPermits: false,
        user: action.payload.user
      };
    case ADD_CUSTOMER_PERMITS_FAILURE:
      return {
        ...state,
        loadingAddCustomerPermits: null,
        errorUser: action.payload.user
      };
    //-------------------------------------------
    case REMOVE_CUSTOMER_PERMITS_BEGIN:
      return {
        ...state,
        loadingRemoveCustomerPermits: true,
        errorUser: null,
      };
    case REMOVE_CUSTOMER_PERMITS_SUCCESS:
      return {
        ...state,
        loadingRemoveCustomerPermits: false,
        user: action.payload.user
      };
    case REMOVE_CUSTOMER_PERMITS_FAILURE:
      return {
        ...state,
        loadingRemoveCustomerPermits: null,
        errorUser: action.payload.user
      };


    //-------------------------------------------
    case ADD_PERMITS_BEGIN:
      return {
        ...state,
        loadingAddPermits: true,
        errorUser: null,
        // id: null // set it to null, to handle infinite renders in componentDidUpdate()
      };
    case ADD_PERMITS_SUCCESS:
      return {
        ...state,
        loadingAddPermits: false,
        user: action.payload.user
      };
    case ADD_PERMITS_FAILURE:
      return {
        ...state,
        loadingAddPermits: null,
        errorUser: action.payload.user
      };
    //-------------------------------------------
    case REMOVE_PERMITS_BEGIN:
      return {
        ...state,
        loadingRemovePermits: true,
        errorUser: null,
      };
    case REMOVE_PERMITS_SUCCESS:
      return {
        ...state,
        loadingRemovePermits: false,
        user: action.payload.user
      };
    case REMOVE_PERMITS_FAILURE:
      return {
        ...state,
        loadingRemovePermits: null,
        errorUser: action.payload.user
      };


    //-------------------------------------------
    case CLEAN_USERS:
      return {
        ...state,
        id: null,
        resetpasswordDialog: false,
        editDialog: false,
        deleteDialog: false,
        createDialog: false,
        loading: false,
        error: null,
        users: null,
        user: null,
        searchQuery: "",
      };
    //-------------------------------------------
    default:
      return state;
  }
};
