import { getToken } from "./AuthHelper";
import {
  handleAPIErrors,
  handleAPIErrorNotification,
  handleNullResponse
} from "../redux/Handlers/ErrorHandlers";
import Resources from "../Resources.json"
export const headersJsonToken = {
  Accept: "application/json",
  "Content-Type": "application/json",
  Authorization: "Bearer " + getToken(),
  "x-organization-id": Resources.Organization
};

export const imageHeaders = {
  Accept: "image/*",
  Authorization: "Bearer " + getToken(),
  "x-organization-id": Resources.Organization
};

export async function _requestImage(options) {

  const {
    method,
    endpoint,
    sendError,
    callbackError,
    callbackSucceed,
    skipErrorNotification,
    skipBodyParsing,
    throwError,
    headers
  } = options;


    let response = await fetch(endpoint, {
      method,
      headers: headers
    });

    await handleAPIErrors(response);

    // Handle requests without body
    if (
      !(response instanceof Response && response.ok && (response.status === 200 || response.status === 204))) {
      if (typeof callbackError !== "function") {
        throw new Error();
      }

      callbackError();

      return;
    }

    if (typeof callbackSucceed === "function") {
        callbackSucceed(response);
    }
    return response;
 
}


export async function _request(options) {

  const {
    method,
    endpoint,
    body,
    sendError,
    callbackError,
    callbackSucceed,
    acceptNull = false,
    sendHeader = true,
    skipErrorNotification,
    skipBodyParsing,
    throwError,
    headers
  } = options;

  


  try {
    let response = await fetch(endpoint, {
      method,
      headers: headers || (sendHeader ? headersJsonToken : undefined),
      body
    });

    await handleAPIErrors(response);

    let json = undefined;

    if (!skipBodyParsing) {
      json = await handleNullResponse(response, acceptNull)
    }

    // Handle requests without body
    if (skipBodyParsing &&
      !(response instanceof Response && response.ok && (response.status === 200 || response.status === 204))) {
      if (typeof callbackError !== "function") {
        throw new Error();
      }

      callbackError();

      return;
    }

    if (typeof callbackSucceed === "function") {
      callbackSucceed(json);
    }

    return json;
  } catch (error) {
    if (sendError !== false) {
      handleAPIErrorNotification(endpoint, error, skipErrorNotification);
    }

    if (typeof callbackError === "function") {
      callbackError(error);
    }

    if (throwError) {
      throw(error);
    }
  }

  // On catch.
  return undefined;
}

export async function fetchGetWT(endpoint, callbackSucceed, callbackError, sendError, acceptNull = false) {
  return await _request({
    method: "GET",
    endpoint,
    callbackSucceed,
    callbackError,
    sendError,
    acceptNull
  });
}

export async function fetchGet(endpoint, callbackSucceed, callbackError, sendError) {
  return await _request({
    method: "GET",
    endpoint,
    callbackSucceed,
    callbackError,
    sendHeader: false,
    sendError
  });
}

export async function fetchPostWT(endpoint, body, callbackSucceed, callbackError, skipErrorNotification) {
  return await _request({
    method: "POST",
    endpoint,
    body,
    callbackSucceed,
    callbackError,
    skipErrorNotification
  });
}

export async function fetchPostWTNoBodyResponse(endpoint, body, callbackSucceed, callbackError) {
  return await _request({ method: "POST", endpoint, body, callbackSucceed, callbackError, skipBodyParsing: true });
}

export async function fetchPostFormDataWT(endpoint, form, callbackSucceed, callbackError) {
  const headers = {
    Accept: "application/json, application/xml, text/plain, text/html, *.*",
    Authorization: "Bearer " + getToken(),
    "x-organization-id": Resources.Organization
  };
  return await _request({ method: "POST", endpoint, body: form, callbackSucceed, callbackError, headers });
}

export function fetchPostMSingleFileMultipartDataWT(
  endpoint,
  file,
  callbackSucced,
  callbackError
) {

  const formData = new FormData();
  formData.append('file', file);

  let options = {
    Accept: "application/json, application/xml, text/plain, text/html, *.*",
    Authorization: "Bearer " + getToken(),
    "x-organization-id": Resources.Organization
  };

  return fetch(endpoint, {
    method: "POST",
    headers: options,
    body: formData
  })
    .then(handleAPIErrors)
    .then(handleNullResponse)
    .then(json => {
      callbackSucced(json);
      return json;
    })
    .catch(error => {
      handleAPIErrorNotification(endpoint, error);
      callbackError(error);
    });
}

export async function fetchPutWT(endpoint, body, callbackSucceed, callbackError) {
  return await _request({ method: "PUT", endpoint, body, callbackSucceed, callbackError });
}

export async function fetchPatchWT(endpoint, body, callbackSucceed, callbackError, skipBodyParsing = false) {
  return await _request({ method: "PATCH", endpoint, body, callbackSucceed, callbackError, skipBodyParsing });
}

export async function fetchDeleteWT(endpoint, body, callbackSucceed, callbackError) {
  return await _request({ method: "DELETE", endpoint, body, callbackSucceed, callbackError });
}

export async function fetchDeleteWTNoBodyResponse(endpoint, body, callbackSucceed, callbackError) {
  return await _request({ method: "DELETE", endpoint, body, callbackSucceed, callbackError, skipBodyParsing: true });
}