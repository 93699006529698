import React from 'react';
import { Typography } from '@material-ui/core';
import { styles } from './shared';
import MainMenuCarousel from './MainMenuCarousel';
import { ActionButton } from './offerComponents/views/editOffer';
import { useStyles } from './Screen';

export default function MainMenu({ setSection, customer }) {
    const classes = useStyles();
    return (
        <div style={{minHeight:720}}>
            <Typography variant="h4" style={styles.viewText}>Vælg</Typography>
            <div style={{
                width: '100%',
                display: 'flex',
                justifyContent: 'center',
                columnGap: 10
            }}>
                <ActionButton
                    text="Opret ny skærm"
                    onClick={() => setSection("screen")}
                    className={classes.buttonMain}

                />
                <ActionButton
                    text="Opret fra skabelon"
                    onClick={() => setSection("templates")}
                    className={classes.buttonMain}

                />
                <ActionButton
                    text="Mediebibliotek"
                    onClick={() => setSection("media")}
                    className={classes.buttonMain}

                />
                  <ActionButton
                    text="Baggrund"
                    onClick={() => setSection("background")}
                    className={classes.buttonMain}

                />
            </div>
            <hr style={{ margin:"100px 0 20px 0",borderTop: "1px solid white"}}/>
            <Typography variant="h4" style={styles.viewText}>Tidligere skærme</Typography>
            <MainMenuCarousel 
                editScreen={(s)=>setSection("screen/"+s.id)} 
                customer={customer}
            />

        </div>
    )
}
