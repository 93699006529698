import React from "react";

// Redux
import { connect } from "react-redux";
import {
    endpoints,
    apiGetParamsReplace
  } from "../../../constants/services.constants";
// import Material components
import { fetchGetWT } from "../../../helpers/HttpHelper";

import SearchBar from "./SearchBar";
import store from '../../../redux/store';
import { addToastMessage } from '../../../redux/actions/toasts.actions';
// Actions

class PosterGenerator extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      queryString: ""
    };
  }
  componentDidMount() {
    
  }

  handleChangeQueryString = event => {
    this.setState({ queryString: event.target.value });
  };

  keyPress = e => {
    if (e.keyCode === 13) {
      this.performSearch();
    }
  };

  performSearch = () => {
    store.dispatch(
      addToastMessage({
          message: "Generering af plakat",
          type: "success",
          details: null
      }));


    console.log(this.state.queryString, "query string");
    const queryString = this.state.queryString;
    if(this.state.queryString !== ""){
        const endpoint = apiGetParamsReplace(
            endpoints.INDESIGN_GENERATE_POSTER,
            ...[queryString]
          );
        console.log(endpoint, "endpoint");
        fetchGetWT(
            endpoint,
            json => {
              store.dispatch(
                addToastMessage({
                    message: "Generering af plakat succesfuld",
                    type: "success",
                    details: null
                })
            );
            },
            error => {
              store.dispatch(
                addToastMessage({
                    message: "Generering af plakater mislykkedes",
                    type: "error",
                    details: null
                })
            );
            
            }
          );
    }
    
  };

  

  componentWillUnmount() {
    //clear view
    // this.props.dispatch(cleanUsers());
  }

  render() {
   

      return (
        <>
          <SearchBar
              queryString={this.state.queryString}
              handleChangeQueryString={this.handleChangeQueryString.bind(this)}
              performSearch={this.performSearch.bind(this)}
              keyPress={this.keyPress.bind(this)}
            />
        </>
      );
    }
}

export default connect(state => state)(PosterGenerator);

