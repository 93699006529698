import React from "react";

// Redux imports
import { connect } from "react-redux";

import MomentUtils from "@date-io/moment";
import moment from "moment";
import { MuiPickersUtilsProvider, DatePicker } from "@material-ui/pickers";

// material
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";
import { FormControl, InputLabel, Select, MenuItem, withStyles, MuiThemeProvider, InputAdornment } from "@material-ui/core";
import { RepublicaDatePickerMuiTheme } from "../../../../RepublicaMuiTheme";
import AutoSuggestionWithChip from "../../../shared/components/autoSuggestionComponent/AutoSuggestionWithChip";
import { offerDefinitionsEnum } from "../../../../constants/enums";
import { IsFeatureEnabled, featureNames } from "../../../../helpers/FeaturesHelper";

moment.updateLocale("dk", {
  months: [
    "Januar",
    "Februar",
    "Marts",
    "April",
    "Maj",
    "Juni",
    "Juli",
    "August",
    "September",
    "Oktober",
    "November",
    "December"
  ],
  monthsShort: [
    "jan",
    "feb",
    "mar",
    "apr",
    "maj",
    "juni",
    "juli",
    "aug",
    "sep",
    "okt",
    "nov",
    "dec"
  ],
  weekdays: [
    "søndag",
    "mandag",
    "tirsdag",
    "onsdag",
    "torsdag",
    "fredag",
    "lørdag"
  ],
  weekdaysShort: ["søn", "man", "tir", "ons", "tor", "fre", "lør"],
  weekdaysMin: ["sø", "ma", "ti", "on", "to", "fr", "lø"],
  week: {
    dow: 1, // First day of week is Monday
    doy: 4 // First week of year must contain 4 January (7 + 1 - 4)
  }
});
moment.locale("dk");

const labelFieldMap = new Map([
  ["manustype", "Manus type"],
  ["prisstyrke", "Pris styrke"],
  ["maengdeangivelse", "Mængde angivelse"],
  ["varebeskrivendemaengde", "Vare beskrivende mængde"],
  ["maengdebegraensning", "Mængde begrænsning"],
  ["sparbudskabtekst", "Sparbudskab tekst"],
  ["pristekst", "Pris tekst"],
  ["layoutbemaerkning", "Layout bemaerkning"]
]);

const styles = {
  contentHolder: {
    display: "grid",
    gridTemplateColumns: "1fr 1fr",
    width: "100%",
    gridColumnGap: 18
  },
  autoSuggestContainer: {
    margin: "16px 0px 8px 0px"
  }
}

const InformationTab = ({
  classes,
  name,
  description,
  validFrom,
  validTo,
  offerPriceField,
  normalPrice,
  price,
  customer,
  tags,
  canEditPrices,
  offerDefinitionId,
  fieldValues,
  onChange,
  onFieldValuesChange,
  offerPublicationName,
  offerSegments,
  offerRankings,
  handleDateChangeFrom,
  handleDateChangeTo,
  handleChangeOfferScores,
  offersTags,
  setTags,
}) => {

  const regularFieldValues = fieldValues.filter(x => x.name !== "layoutbemaerkning");
  const layout = fieldValues.find(x => x.name === "layoutbemaerkning");
  return (
    <>
      <TextField
        margin="normal"
        label="Titel"
        type="text"
        name="description"
        value={description || ""}
        onChange={onChange}
        InputProps={{
          multiline: true
        }}
        fullWidth
      />
      <MuiPickersUtilsProvider utils={MomentUtils} moment={moment}>
        <MuiThemeProvider theme={RepublicaDatePickerMuiTheme}>
          <DatePicker
            margin="normal"
            label="Gyldig fra"
            value={validFrom}
            style={{ width: "49%", float: "left" }}
            onChange={(date) => handleDateChangeFrom(date)}
            format="Do[.] MMM"
          />

          <DatePicker
            margin="normal"
            label="Gyldig til"
            value={validTo}
            style={{ width: "49%", float: "right" }}
            onChange={(date) => handleDateChangeTo(date)}
            format="Do[.] MMM"
          />
        </MuiThemeProvider>
      </MuiPickersUtilsProvider>
      <div className={classes.contentHolder}>
        <TextField
          margin="normal"
          label="Normal pris"
          type="text"
          name="normalPrice"
          value={normalPrice}
          onChange={onChange}
          fullWidth
          disabled={!canEditPrices}
          InputProps={{
            endAdornment: <InputAdornment position="end">kr.</InputAdornment>,
          }}
        />
        <TextField
          margin="normal"
          label="Tilbud pris"
          type="text"
          name={offerPriceField}
          value={offerPriceField === "price" ? price : normalPrice}
          onChange={onChange}
          fullWidth
          disabled={true}
          InputProps={{
            endAdornment: <InputAdornment position="end">kr.</InputAdornment>,
          }}
        />
        <TextField
          margin="normal"
          label="Pris"
          type="text"
          name="price"
          value={price}
          onChange={onChange}
          fullWidth
          disabled={!canEditPrices}
          InputProps={{
            endAdornment: <InputAdornment position="end">kr.</InputAdornment>,
          }}
        />

        <TextField
          margin="normal"
          label="Organisation"
          type="text"
          name="Organisation"
          value={customer || ""}
          fullWidth
          disabled={true}
        />

        {!IsFeatureEnabled(featureNames.Offers_Definitions_Enabled) ? undefined :



          <FormControl fullWidth style={{ marginTop: 16 }}>
            <InputLabel htmlFor="offerDefinitionId">Tilbudstype</InputLabel>
            <Select
              fullWidth
              value={offerDefinitionId || 0}
              onChange={onChange}
              id="selectorOfferEditDefinition"
              inputProps={{
                name: "offerDefinitionId",
                id: "offerDefinitionId"
              }}
            >
              <MenuItem style={{ fontStyle: "italic" }} key={0} value={0}>
                Ingen type
              </MenuItem>
              {Object.entries(offerDefinitionsEnum).map(([key, item]) => (
                <MenuItem key={item.id} value={item.id} className="offerDefinitionDropdownItems">
                  {item.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        }
        <TextField
          margin="normal"
          label="Avis"
          type="text"
          name="avis"
          value={offerPublicationName || ""}
          fullWidth
          disabled={true}
          InputLabelProps={{ shrink: true }}
        />
      </div>
      <div className={classes.autoSuggestContainer}>
        <AutoSuggestionWithChip
          autoSuggestData={offersTags}
          tags={tags}
          setTags={(newTags) => setTags(newTags)}
          allowAddNew={true}
        />
      </div>
      <div className={classes.contentHolder}>
        {regularFieldValues.map((fieldValue, index) =>
          <TextField
            key={fieldValue.id}
            id={fieldValue.id}
            data-key={fieldValue.id}
            margin="normal"
            label={labelFieldMap.has(fieldValue.name) ? labelFieldMap.get(fieldValue.name) : fieldValue.name}
            type="text"
            name={fieldValue.name}
            value={fieldValue.value ?? ""}
            onChange={onFieldValuesChange}
            fullWidth
            disabled={fieldValue.name === "manustype" || fieldValue.name === "prisstyrke" ? true : false}
            style={regularFieldValues.length % 2 === 1 && regularFieldValues.length - 1 === index ?
              { gridColumnEnd: 'span 2' } : undefined}
          />)}
      </div>
      <TextField
        margin="normal"
        label="Layout bemaerkning"
        type="text"
        name={layout.name}
        value={layout.value ?? ""}
        fullWidth
        InputLabelProps={{ shrink: true }}
        onChange={onFieldValuesChange}
      />
      {offerSegments.map((segment, index) => <TextField
        key={`segment_${index}`}
        id={index.toString()}
        data-key={index}
        margin="normal"
        label="Segment"
        type="text"
        name="segment"
        value={segment.multiOfferSegmentType.name || ""}
        onChange={() => { }}
        fullWidth
        disabled={true}
      />)}

      {!IsFeatureEnabled(featureNames.Offers_Rankings_Enabled) ? undefined :

        <div>
          <div style={{ marginTop: 10 }}>
            <Typography variant="subtitle1">
              Tilbudsrangering
            </Typography>
          </div>
          <div className={classes.contentHolder}>
            {offerRankings === null ? <Typography variant="body1">Dette tilbud har ingen tilgængelige rangeringer</Typography> : 
              <>
                <TextField
                  key={`ranking_${offerRankings.offerId}`}
                  id={offerRankings.offerId}
                  data-key={offerRankings.offerId}
                  margin="normal"
                  label="Rangeringsnavn"
                  type="text"
                  name="rankingNavn"
                  value={"Resten"}
                  onChange={() => { }}
                  fullWidth
                  disabled={true}
                />
                <TextField
                  key={`score_${offerRankings.offerId}`}
                  id={"rest_ranking"}
                  data-key={offerRankings.offerId}
                  margin="normal"
                  label="Værdi"
                  type="text"
                  name="textfieldOfferEditRank"
                  value={offerRankings.restScore.toString() || ""}
                  onChange={(e) => handleChangeOfferScores(e.target)}
                  fullWidth
                />
              </>
              }
          </div>
        </div>
    }
    </>)
}

const InformationTabStyled = withStyles(styles)(InformationTab);
export default connect(state => state)(InformationTabStyled);
