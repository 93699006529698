import React from 'react';
import { Hidden, Drawer, Divider } from '@material-ui/core';
import NavLinks from '../../routes/NavLinks';
import { SystemMaintenancePaddingTop } from './SystemMaintenanceHeader';

export default (props)=> {
 return     <nav className={props.classes.drawer}>
 {/* The implementation can be swapped with js to avoid SEO duplication of links. */}
   <Hidden mdUp implementation="css">
   <Drawer
     container={props.container}
     variant="temporary"
     anchor={props.theme.direction === 'rtl' ? 'right' : 'left'}
     open={props.mobileOpen}
     onClose={props.handleDrawerToggle}
     classes={{
       paper: props.classes.drawerPaper,
     }}
     style={{width : 300}}
   >
    <div>
        <div className={props.classes.toolbar} />
        <Divider />
        <NavLinks />
      </div>

   </Drawer>
 </Hidden>
   <Hidden smDown implementation="css">
   <Drawer
     classes={{
       paper: props.classes.drawerPaper,
      }}
     variant="permanent"
     open
   >
    <div>
        <div className={props.classes.toolbar} />
      <div />
        <Divider style={{"paddingTop": SystemMaintenancePaddingTop()}}/>
        <NavLinks />
      </div>
   </Drawer>
 </Hidden>
</nav>
}
  