import { showSuccessNotification } from "../../../../../../helpers/UtilHelper";
import { generatePoster, sendToNewIndesign } from "../../../../publikationstyring/Requests";


export function initializeTemplates(spreads, setSelectedTemplates, templates, setLoading) {

    if (templates.length === 0) {
        setLoading(false);
        return;
    }

    const deafultTemp = templates[0];
    //Now some logic to take either the left or the right side template.
    //Should I get left and right side requests from the server? 
    //Or easier is probably assign a value for which page side it is..
    

    const initializedTemplates = assignTemplateToPage(spreads, deafultTemp.fileName);

    setSelectedTemplates(initializedTemplates);
    setLoading(false);
}

function assignTemplateToPage(spreads, templateName) {

    let initializedTemplates = [];
    for (let i = 0; i < spreads.length; i++) {
        const pageLeft = spreads[i][0];
        const pageRight = spreads[i][1];

        const templateLeft = { page: pageLeft, name: templateName };
        const templateRight = { page: pageRight, name: templateName };

        initializedTemplates = [...initializedTemplates, templateLeft, templateRight];
    }

    return initializedTemplates;
}


export function getPageInfos(selectedPages, selectedTemplates, pageSpreadLookup) {
    let pageInfos = [];

    for (let i = 0; i < selectedPages.length; i++) {
        const page = selectedPages[i];
        const template = selectedTemplates.find(x => x.page === page);
        const spreadNumber = pageSpreadLookup.get(page);
        const pageInfo = {
            PageNumber: page,
            TemplateName: template ? template.name : "", // TODO add fallback template
            Spread: spreadNumber
        };
        if(page != null){
            pageInfos = [...pageInfos, pageInfo];
        }
    }

    return pageInfos;
}

export function displaySelectedTemplate(pageNumber, selectedTemplates) {
    let templateName;
    selectedTemplates.some(x => {
        if (x.page === pageNumber) {
            templateName = x.name;
            return true;
        }
    })
    return templateName;
}

export async function handleGeneratePoster(
    jobId, 
    selectedPages, 
    selectedTemplates, 
    onConfirm, 
    pageSpreadLookup) {
    const _pageInfos = getPageInfos(selectedPages, selectedTemplates, pageSpreadLookup);

    const body = {
        workbookId: jobId,
        pageInfos: _pageInfos
    };

    generatePoster(body);
    showSuccessNotification("Siderne er sendt til Poster");
    onConfirm();

}

export async function handleSendToNewIndesign(
    jobId,
    selectedPages,
    selectedTemplates,
    onConfirm,
    pageSpreadLookup) {

    const body = generateRequestBody(jobId, selectedPages, selectedTemplates, pageSpreadLookup);

    sendToNewIndesign(body);
    showSuccessNotification("Siderne er sendt til Indesign");
    onConfirm();
}

function generateRequestBody(jobId, selectedPages, selectedTemplates, pageSpreadLookup) {
    const _pageInfos = getPageInfos(selectedPages, selectedTemplates, pageSpreadLookup);

    const body = {
        workbookId: jobId,
        pageInfos: _pageInfos
    };

    return body;
}

export function mapPageNumberToSpreadNumber(spreads) {
    let lookup = new Map();

    for (let index = 0; index < spreads.length; index++) {
        let spread = spreads[index];
        lookup.set(spread[0], index + 1);
        lookup.set(spread[1], index + 1);  
    }
    return lookup;
}

function setAllPagesAsSelected(spreads, setSelectedPages) {
    let selectedPages = [];

    for (let index = 0; index < spreads.length; index++) {
        const spread = spreads[index];
        
        selectedPages = [...selectedPages, ...spread];
    }

    setSelectedPages(selectedPages)

}

export function setSelectedTemplateOnAllPages(spreads, setSelectedTemplates, template, setLoading, setSelectedPages) {
    if (!template) {
        setLoading(false);
        return;
    }

    const initializedTemplates = assignTemplateToPage(spreads, template);
    setAllPagesAsSelected(spreads, setSelectedPages);
    setSelectedTemplates(initializedTemplates);
    setLoading(false);
}