import React from "react";
import SortingSelecting from "./PhotosDataTable";
import { connect } from "react-redux";
import {
  cleanSearchPhotosSuccess, deletePhotos,
} from "../../../redux/actions/photos/search.photos.actions";
import { Typography } from "@material-ui/core";
import { fetchCustomers } from "../../../redux/actions/utils/customers.utils.actions";
import { fetchFeatures } from "../../../redux/actions/utils/features.utils.actions";
import { canReadApplication, getPermissions } from "../../../helpers/CredentialsHelper";
import { applications } from "../../../constants/app.constants";
import { LoadingLogo } from "../../shared/Loading";

import { searchPhotos } from "./Requests";
import DialogPreReplacePhoto from "./dialogs/DialogPreReplacePhoto";
import DialogReplacePhoto from "./dialogs/DialogReplacePhoto";
import { GetLatestOfferValidToDateWherePhotoIsUsed } from "../../shared/SharedFunctions";
import DialogDeletePhotoV2 from "./dialogs/DialogDeletePhotoV2";
import { loadFiltersFromStorage } from "../../../helpers/SearchHelper";
import SearchBarV2 from "../../shared/components/searchBarV2/SearchBarV2";
import { getMotivId, showErrorNotification, sortArrayAlphabetically, stringIsEmpty, validateSearchString } from "../../../helpers/UtilHelper";
import Pagination from "material-ui-flat-pagination";
import { getPhotoFiltersForRequest, getPhotoFiltersForUi } from "./PhotoSearchFilters";
import { Helmet } from "react-helmet";
import DialogDeleteLastProductPhoto from "../produkstyring/dialogs/DialogDeleteLastProductPhoto/DialogDeleteLastProductPhoto";
import { checkIfPhotoIsLastProductPhoto } from "../../shared/Requests";
import DialogUploadNewProductPhotoBeforeDelete from "../produkstyring/dialogs/DialogDeleteLastProductPhoto/DialogUploadNewProductPhotoBeforeDelete";
import { fetchPhotoOrderV2 } from "../fototracker/SharedRequests";
import getProductCategories, { getProductPhotosForPhoto } from "../produkstyring/Requests";
import { patchMaster } from "../../../redux/actions/product/edit.products.actions";
import CustomSearchBar from "../../shared/components/customSearchBar/CustomSearchBar";
import PhotosDataTable from "./PhotosDataTable";
import DialogAddPhotoOrderV2 from "../fototracker/Dialogs/DialogAddPhotoOrder/DialogAddPhotoOrderV2";
import { photoOrderOrigin } from "../../../constants/enums";
import { Tooltip } from "@material-ui/core";
import { Fab } from "@material-ui/core";
import AddIcon from "@material-ui/icons/Add";
import DialogUploadPhoto from "./dialogs/dialogUploadPhoto/DialogUploadPhoto";
import SearchBarWithAutoSuggest from "../../shared/components/searchBarTags/searchBar/SearchBarWithAutoSuggest";
import { getPhotoTags } from "../fototracker/SharedRequests";
import Resources from "../../../Resources.json";
import { GetResource } from "../../../helpers/ResourceHelper";

import { IsFeatureEnabled, featureNames } from "../../../helpers/FeaturesHelper";

class Photos extends React.Component {
  state = {
    storageKeyText: "search_text_photo",
    storageKeyFilters: "search_filters_photo",
    loading: true,
    searchText: "",
    searchFilters: [], // options to filter on while making search request
    searchResult: null,
    searchRowOffset: 0,
    rowsPerPage: 10,
    tagsSearchArray: [],

    performedFirstSearch: false,
    sectionId: applications.FOTOS,
    selectedMotivId: undefined, // used to edit photo
    dialogWherePhotoUsed: false,
    dialogReplacePhoto: false,
    reloadPhoto: false,
    openDialogDeletePhoto: false,
    deletePhotos: [],
    dateDeletePhoto: undefined,
    dialogAddPhotoOrder: false,
    isLastProductPhoto: false,
    productIdWithLastPhoto: undefined,
    uploadNewProductPhoto: false,
    productCategories: [],
    showAddPhotoOrderDialog: false,
    dialogUploadPhoto: false,
    photoTagsAutoSuggest: [],
    allFilters:[],
  };
  keyDownListener = (e) => this.handleKeyDown(e);


  async componentDidMount() {
    if (canReadApplication(applications.FOTOS)) {

      await this.props.dispatch(fetchCustomers());
      await this.props.dispatch(fetchFeatures());
      let categories = await getProductCategories();
      categories = sortArrayAlphabetically(categories);

      await this.initPhotoTags();

      let filters = getPhotoFiltersForUi(this.props.utilsReducer.customers, this.props.utilsReducer.features);

      // load search request arguments from cache
      let searchText = "";
      try {
        filters = loadFiltersFromStorage(filters, this.state.storageKeyFilters);
        searchText = localStorage.getItem(this.state.storageKeyText);
      } catch { }

      window.addEventListener("keydown", this.keyDownListener);
      this.setState({
        searchText: searchText,
        searchFilters: filters,
        productCategories: categories,
        allFilters: filters
      },
        () => this.performSearch()
      );
    }
  }

  async initPhotoTags(){
    let photoTags = await getPhotoTags();
    photoTags = sortArrayAlphabetically(photoTags);
    this.setState({
      photoTagsAutoSuggest: photoTags,
    });
  }

  componentDidUpdate(prevProps, prevState) {
    if (!this.state.loading &&
      (prevState.searchRowOffset !== this.state.searchRowOffset || prevState.searchFilters !== this.state.searchFilters)) {
      this.performSearch();
    }
  }

  componentWillUnmount() {
    this.props.dispatch(cleanSearchPhotosSuccess());
    window.removeEventListener("keydown", this.keyDownListener);
  }

  handleKeyDown(e) {
    if (this.state.loading || e.target instanceof HTMLInputElement) {
      return;
    }

    switch (e.code) {
      case "ArrowRight":
        if (this.state.searchResult.totalRows > this.state.searchRowOffset + this.state.rowsPerPage) {
          this.setState({ searchRowOffset: this.state.searchRowOffset + this.state.rowsPerPage })
        }
        break;

      case "ArrowLeft":
        if (this.state.searchRowOffset > 0) {
          this.setState({ searchRowOffset: this.state.searchRowOffset - this.state.rowsPerPage })
        }
        break;
      default:
        break;
    }
  }

  async performSearch() {
    const textIsValid = validateSearchString(this.state.searchText);
    if (!textIsValid) {
      showErrorNotification("Ugyldig søgetekst");
      this.setState({
        searchText: ""
      });

      return;
    }

    try {
      this.setState({ loading: true });


      const searchRequest = {
        searchText: this.state.searchText,
        filters: getPhotoFiltersForRequest(this.state.searchFilters, this.state.tagsSearchArray, this.state.allFilters, this.props.utilsReducer.customers),
        startRow: this.state.searchRowOffset,
        numberOfRows: this.state.rowsPerPage
      };

      const searchResult = await searchPhotos(searchRequest);
      this.setState({ loading: false, searchResult: searchResult });
    } catch (e) {
      showErrorNotification("Search failed");
      this.setState({ loading: false });
    }

    // cache search request arguments
    localStorage.setItem(this.state.storageKeyText, this.state.searchText);
    localStorage.setItem(this.state.storageKeyFilters, JSON.stringify(this.state.searchFilters));
  };

  setSelectedMotivId = (motivId) => {
    this.setState({
      selectedMotivId: motivId,
      dialogWherePhotoUsed: true
    })
  }

  handleCloseDialogWherePhotoUsed = () => {
    this.setState({
      selectedMotivId: undefined,
      dialogWherePhotoUsed: false
    })
  }

  confirmReplacePhoto = () => {
    this.setState({
      dialogReplacePhoto: true,
      dialogWherePhotoUsed: false
    })
  }

  closeDialogReplacePhoto = () => {
    this.setState({
      dialogReplacePhoto: false,

    });

    setTimeout(() => {
      this.performSearch();
    }, 1000);
  }

  refreshPhoto = () => {
    this.setState({
      reloadPhoto: !this.state.reloadPhoto
    })
  }

  handleDelete = async (photo) => {
    if (!photo) {
      return;
    }

    // check if photo is the last product photo
    const { isLastPhoto, productIds } = await checkIfPhotoIsLastProductPhoto(photo.id);

    const openDeleteDialog = isLastPhoto ? false : true;

    this.setState({
      dataLoading: true,
      openDialogDeletePhoto: openDeleteDialog,
      deletePhotos: [photo.id],
      isLastProductPhoto: isLastPhoto,
      productIdWithLastPhoto: productIds[0] || undefined
    });

    const dateValidTo = await GetLatestOfferValidToDateWherePhotoIsUsed(photo.id);
    // if photo is not used in any publication it returns null
    this.setState({
      dataLoading: false,
      dateDeletePhoto: dateValidTo
    })
  };

  handleCloseDeletePhotoDialog = () => {
    this.setState({
      openDialogDeletePhoto: false,
      deletePhotos: [],
      dateDeletePhoto: undefined
    })
  }

  handleNewSearch() {
    this.setState({ searchRowOffset: 0 }, () => this.performSearch());
  }

  openAddPhotoOrderDialog = () => {
    this.setState({
      showAddPhotoOrderDialog: true
    });
  }

  closeDialogDeleteLastProductPhoto = () => {
    this.setState({
      openDialogDeletePhoto: false,
      deletePhotos: [],
      isLastProductPhoto: false,
      showAddPhotoOrderDialog: false
    });
  }

  confirmDeleteLastProductPhoto = () => {
    this.setState({
      isLastProductPhoto: false,
      openDialogDeletePhoto: true
    });
  }

  openDialogUploadNewPhoto = () => {
    this.setState({
      uploadNewProductPhoto: true,
      isLastProductPhoto: false
    });
  }

  closeDialogUploadNewPhoto = () => {
    this.setState({
      uploadNewProductPhoto: false,
      isLastProductPhoto: false,
      openDialogDeletePhoto: false,
      deletePhotos: [],
      productIdWithLastPhoto: undefined
    });
  }

  deletePhotoAfterNewWasUploaded = async (photoOrderId) => {
    const deleteDto = {
      photoIds: this.state.deletePhotos.map(x => parseInt(x)),
      reason: 5, // Billedet er udgået, change later to enum value
      details: "",
    };

    this.props.dispatch(deletePhotos(deleteDto, () => this.performSearch()));
    this.closeDialogDeleteLastProductPhoto();

    // get photo id from a photo order
    const photoOrder = await fetchPhotoOrderV2(photoOrderId);

    if (photoOrder) {
      // get product photo id
      const productPhoto = await getProductPhotosForPhoto(photoOrder.photoId);
      if (!productPhoto) {
        showErrorNotification(`Failed getting product photo ${photoOrder.photoId}`);
      } else {
        // set new photo as a default image on a product
        this.props.dispatch(patchMaster(this.state.productIdWithLastPhoto, productPhoto[0].id));
      }
    }

    this.setState({
      productIdWithLastPhoto: undefined
    })
  }

  keyPress = e => {
    if (!this.state.loading) {
      if (e.keyCode === 13) {
        this.handleNewSearch();
      }
    }
  };

  handleChangeTagsText = value => {
    this.setState({ 
      tagsSearchArray: value,
      searchRowOffset: 0
     }, () => this.performSearch());
  };

  handleCloseDialogUploadPhoto = (photoId) => {

    this.initPhotoTags();

    this.setState({
      dialogUploadPhoto: false,
    });

    if (photoId) {
      this.setState({
        searchText: getMotivId(photoId)
      });
      this.performSearch();
    };

  };

  render() {
    if (!IsFeatureEnabled(featureNames.Photos_Enabled) || !canReadApplication(applications.FOTOS)) {
      return <Typography variant="subtitle1" align="center">
        Du har ikke adgang til denne side. Kontakt support
          (mbsupport@republica.dk), hvis dette er en fejl.
      </Typography>
    }

    let searchState = null;
    if (this.state.loading) {
      searchState = <LoadingLogo />;
    } else if (!this.state.searchResult || this.state.searchResult.rows.length === 0) {
      searchState = <Typography variant="subtitle1" align="center">Ingen fotos fundet...</Typography>;
    } else {
      searchState = <>
        <PhotosDataTable
          loading={this.state.loading}
          rowsPerPage={this.state.rowsPerPage}
          searchRowOffset={this.state.searchRowOffset}
          searchResult={this.state.searchResult}
          reloadSearch={() => this.handleNewSearch()}
          onSearchRowOffsetChanged={offset => this.setState({ searchRowOffset: offset })}
          onRowsPerPageChanged={x => this.setState({ rowsPerPage: x, searchRowOffset: 0 })}
          handleDelete={(item) => this.handleDelete(item)}
          onPhotoChanged={() => { }} // we don't auto search for now
          onEditImage={(x) => this.setSelectedMotivId(x)}
        />

        {this.state.searchResult.totalRows === this.state.searchResult.numberOfRows ? (
          undefined
        ) : (
          <div style={{ margin: 10 }}>
            <Pagination
              limit={this.state.rowsPerPage}
              offset={this.state.searchRowOffset}
              total={Math.min(this.state.searchResult.totalRows, 100 * this.state.rowsPerPage)}
              onClick={(event, offset) => this.setState({ searchRowOffset: offset })}
              centerRipple={true}
              style={{ textAlign: "center" }}
            />
          </div>
        )}
      </>
    }

    return (
      <>
        <Helmet>
          <title>{GetResource(Resources.Photos_Helmet_Title)}</title>
        </Helmet>
        <Typography variant="h4">{GetResource(Resources.Photos_Title)}</Typography>


            <div
              style={{
                display: "grid",
                gridTemplateColumns: IsFeatureEnabled(featureNames.Photos_Create_Enabled) ? 'auto 40% 50px' : 'auto 40%',
                columnGap: 10
              }}
            >
        
          <SearchBarV2
            onSearch={() => this.handleNewSearch()}
            onSearchTextChanged={text => this.setState({ searchText: text })}
            onSearchFiltersChange={filters => this.setState({ searchFilters: filters })}
            searchFilters={this.state.searchFilters}
            searchText={this.state.searchText}
        />
          <SearchBarWithAutoSuggest
            autoSuggestTags={this.state.photoTagsAutoSuggest}
            queryArray={this.state.tagsSearchArray}
            handleChangeTagsSearchArray={(value) => this.handleChangeTagsText(value)}
          />
          {IsFeatureEnabled(featureNames.Photos_Create_Enabled) && getPermissions("Fotos.UploadFoto").canRead ?
            (<Tooltip title={"Upload fotoet"}>
            <Fab
              size="small"
              color="primary"
              // className={classes.iconButton}
              onClick={() => this.setState({ dialogUploadPhoto: true })}
              name="buttonPhotosAdd"
            >
              <AddIcon />
            </Fab>
            </Tooltip>) : undefined}
        </div>

        {searchState}

        {this.state.openDialogDeletePhoto && this.state.deletePhotos ?
          (<DialogDeletePhotoV2
            performSearch={this.performSearch.bind(this)}
            dateDeletePhotoAllowed={this.state.dateDeletePhoto}
            dataLoading={this.state.dataLoading}
            photoIds={this.state.deletePhotos}
            handleClose={() => this.handleCloseDeletePhotoDialog()}
            productIdWithLastPhoto={this.state.productIdWithLastPhoto}
          />) : undefined
        }
        {this.state.deletePhotos && this.state.isLastProductPhoto ?
          (<DialogDeleteLastProductPhoto
            openAddPhotoOrderDialog={() => this.openAddPhotoOrderDialog()}
            closeDialogDeleteLastProductPhoto={() => this.closeDialogDeleteLastProductPhoto()}
            confirmDeleteLastProductPhoto={() => this.confirmDeleteLastProductPhoto()}
            openUploadNewPhoto={() => this.openDialogUploadNewPhoto()}
          />) : undefined
        }
        {this.state.dialogWherePhotoUsed && this.state.selectedMotivId ?
          (<DialogPreReplacePhoto
            open={true}
            motiveId={this.state.selectedMotivId}
            handleClose={this.handleCloseDialogWherePhotoUsed}
            renderActionButtons={true}
            confirmReplacePhoto={this.confirmReplacePhoto}
            messageReplacePhoto="Du er ved at erstatte motiv-ID med følgende billede."
            messageConfirmReplacePhoto="Vil du fortsat ændre billedet?"
          />) : undefined
        }
        {this.state.dialogReplacePhoto ?
          <DialogReplacePhoto
            motivId={this.state.selectedMotivId}
            handleCancel={this.closeDialogReplacePhoto}
            refreshPhoto={() => this.refreshPhoto()}
            performSearch={(searchText, filters) => this.performSearch(searchText, filters)}
          /> : undefined
        }
        {!this.state.showAddPhotoOrderDialog ? undefined :
          <DialogAddPhotoOrderV2
            deletePhotoAfterNewWasUploaded={(photoOrderId) => this.deletePhotoAfterNewWasUploaded(photoOrderId)}
            categories={this.state.productCategories}
            productId={this.state.productIdWithLastPhoto}
            orderOrigin={parseInt(photoOrderOrigin.PhotoTracker)}
          />}
        {this.state.uploadNewProductPhoto ?
          <DialogUploadNewProductPhotoBeforeDelete
            productId={this.state.productIdWithLastPhoto}
            closeDialogUploadNewPhoto={() => this.closeDialogUploadNewPhoto()}
            photoIds={this.state.deletePhotos}
            hideUploadButton={true}
            performSearch={this.performSearch.bind(this)}
          />
          : undefined}
        {this.state.dialogUploadPhoto ?
          <DialogUploadPhoto
            handleClose={(photoId) => this.handleCloseDialogUploadPhoto(photoId)}
          /> : undefined}
      </>
    );
  }
}
export default connect((state) => state)(Photos);
