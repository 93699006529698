
import React from "react";
import Lock from '@material-ui/icons/Lock';
import { Tooltip } from "@material-ui/core";
import store from "../../../redux/store";

export default function ExclusiveIcon({ exclusiveForCustomerIds }) {
    if (!exclusiveForCustomerIds) 
        return null;

    const customerNames = store.getState().utilsReducer.customers
        .filter(x => exclusiveForCustomerIds.includes(x.id))
        .map(x => x.name);
    const tooltip = "Dette foto er eksklusivt for " + customerNames.join(", ");

    return (
        <div className="exclusivePhotoIcon">
            <Tooltip title={tooltip}>
                <Lock fontSize="small" style={{ color: "rgb(244, 67, 54)" }} />
            </Tooltip>
        </div>
    )
}