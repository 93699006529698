import React from 'react';
import { Paper } from '@material-ui/core';
import { StyledInput } from './editOffer/EditOfferField';


export default function SearchOfferField({ placeHolder, onChange, searchText, onKeyDown }) {
    return (
            <Paper
                elevation={0}
                style={{
                    borderRadius: 40,
                    padding: 4,
                    width: 250,
                    height: 45,
                    display: "inline-block"
                }}
            >
            <StyledInput
                autoFocus
                placeholder={placeHolder}
                onChange={e => onChange(e.target.value)}
                value={searchText}
                onKeyDown={(e) => onKeyDown(e)}
            />
        </Paper>
    )
}