
export function getPrisStyrke(offer) {
    const item = offer.fieldValues.find(fieldValue => fieldValue.name === "prisstyrke");
    return item ? item.value : "";
}

export function getProductNumbersFromOffer(offer) {
    let offerProductIds = [];

    // if offer is mixmatch or sammix manus
    if (offer.childOffers.length > 0) {
        offer.childOffers.forEach(x => {
            const childOfferProducts = x.products.map(x => x.id);
            offerProductIds = [...offerProductIds, ...childOfferProducts];

        });
    } else {
        // offer is mix or sammix pmr
        offerProductIds = offer.products.map(x => x.id);
    }
    return offerProductIds;
}