import { getSessionToken } from "../../../../helpers/AuthHelper/AuthHelperV2";
import { stringIsEmpty } from "../../../../helpers/UtilHelper";

export async function doLogIn(userName, passWord, setError, setLoading) {
    const isNotValid = validateInput(userName, passWord);
    if (isNotValid) {
        setError("Brugernavn og kodeord skal udfyldes");
        return;
    } else {
        setError(undefined);
        setLoading(true);
        const logInResponse = await getSessionToken(userName, passWord);

        if (logInResponse.resultLogin) {
            window.location.reload();
        } else {
            setLoading(false);
            setError(logInResponse.errorMsg);
        }
    }

}

function validateInput(userName, passWord) {
    return stringIsEmpty(userName) || stringIsEmpty(passWord);
}