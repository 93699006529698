import React, { useState, useEffect } from 'react'
import { Typography, Button, LinearProgress, FormControlLabel, Switch, TextField } from '@material-ui/core'
import { Link as RouterLink } from 'react-router-dom';
import { endpoints, apiGetParamsReplace } from '../../../constants/services.constants';
import { fetchGetWT, fetchPostWT, fetchDeleteWT } from '../../../helpers/HttpHelper';
import { customers } from '../../../constants/app.constants';
// import { getNumberOfWeek } from '../../../helpers/UtilHelper';
import CorrectorSegmentOfferCombinations from './CorrectorSegmentOfferCombinations';
import PaginationWithStyles from 'material-ui-flat-pagination';
import { Helmet } from 'react-helmet';
import { Box } from '@material-ui/core';
import { IconButton } from '@material-ui/core';
import SearchIcon from '@material-ui/icons/Search';
import { Paper } from '@material-ui/core';
import InputBase from '@material-ui/core/InputBase';
// import usePagination from '@mariosant/react-pagination-hook';
const Link1 = React.forwardRef((props, ref) => <RouterLink innerRef={ref} {...props} />);


export default function CorrectorSegment(props) {
    const [yearWeekSegmentCombinations, setYearWeekSegmentCombinations] = useState(null);
    const [paginationLimit, setPaginationLimit] = useState(5);
    const [paginationOffset, setPaginationOffset] = useState(0);
    const [offers, setOffers] = useState(null);
    const [error, setError] = useState(null);
    const customerObj = customers.find(x => x.id === parseInt(props.customer));
    const [search, setSearch] = useState("")

    useEffect(() => {
        fetchOffers();
        fetchWeekSegmentCombinations();
        return () => {
            setOffers(null);
            setYearWeekSegmentCombinations(null);
            setError(null);
            setPaginationLimit(null);
            setPaginationOffset(null);
        };
    }, []);

    useEffect(() => {
        // console.log("paginationOffset trigger launched")
        window.scrollTo(0, 100);
    }, [paginationOffset])

    const isWeek = props.year != null && props.week != null;

    function fetchWeekSegmentCombinations() {
        if (isWeek) {

            const endpoint = apiGetParamsReplace(endpoints.PHOTOCOMBINATION_WEEKSEGMENTCOMBINATIONS_GET,
                props.year, props.week, customerObj.id, props.segment);
            fetchGetWT(
                endpoint,
                data => {
                    // console.log(data);
                    setYearWeekSegmentCombinations(data);
                },
                error => {
                    console.log(new Error(error.message))
                    setYearWeekSegmentCombinations("Error");
                }
            );
        }
    }

    function saveWeekSegmentCombination(selectionId, callback, callbackError) {
        const endpoint = apiGetParamsReplace(endpoints.PHOTOCOMBINATION_WEEKSEGMENTCOMBINATIONS_POST);
        if (!Array.isArray(selectionId)) {
            selectionId = [selectionId];
        }


        let postObj = selectionId.map(x => {
            return {
                "week": parseInt(props.week),
                "year": parseInt(props.year),
                "customer": parseInt(customerObj.id),
                "segment": props.segment,
                "combinationId": x
            }
        })




        fetchPostWT(
            endpoint,
            JSON.stringify(postObj)
            ,
            data => {
                // console.log(data);
                fetchWeekSegmentCombinations();
                if (callback != null) callback(data);
                // setWeekSegments(data);
            },
            error => {
                console.log(new Error(error.message))
                if (callbackError != null) callbackError(error);
                // setWeekLoading(false);
            }
        );
    }
    function deleteWeekSegmentCombination(combinationId) {
        const s = yearWeekSegmentCombinations.find(x => x.combinationId === combinationId);
        if (s != null) {
            const endpoint = apiGetParamsReplace(endpoints.PHOTOCOMBINATION_WEEKSEGMENTCOMBINATIONS_DELETE, s.id);
            // console.log(selection)
            fetchDeleteWT(
                endpoint, null,
                data => {
                    fetchWeekSegmentCombinations();
                },
                error => {
                    console.log(new Error(error.message))
                }
            );
        }
    }
    function fetchOffers() {
        if (isWeek) fetchWeekOffers();
        else fetchMonthOffers();
    }
    function fetchWeekOffers() {
        // console.log(w);
        //todo:fix year
        const yearWeekCode = props.year + "" + ("0" + props.week).slice(-2);
        const endpoint = apiGetParamsReplace(
            endpoints.OLDAPI_OFFERS_BY_CUSTOMER_SEGMENT_WEEK
            , customerObj.name, props.segment, yearWeekCode);
        setError(null);
        setOffers('loading');
        // console.log()
        fetchGetWT(
            endpoint,
            data => {
                if (data.rankedOffers != null) {
                    if (data.rankedOffers[customerObj.name] != null && data.rankedOffers[customerObj.name][props.segment] != null) {
                        let ofs = data.rankedOffers[customerObj.name][props.segment].filter(x => x.NumberOfProducts !== 0);
                        setOffers(ofs);
                    } else {
                        setOffers('Error');
                        setError("Der er ingen tilbud for dette segment i denne uge.");
                        console.error("error in data", data);

                    }
                }
                else {
                    setOffers('Error');
                    setError("Der er ingen tilbud for dette segment i denne uge.");
                    console.error("error in data", data);

                }

            },
            error => {
                setOffers('Error');
                setError("Der er ingen tilbud for dette segment i denne uge.");
                console.error(error.message)
            }, false
        );
    }

    function fetchMonthOffers() {
        const endpoint = apiGetParamsReplace(
            endpoints.OLDAPI_OFFERS_BY_CUSTOMER_CUPOFTHEMONTH
            , props.month, customerObj.name);
        setError(null);
        setOffers('loading');
        // console.log()
        fetchGetWT(
            endpoint,
            data => {
                if (data[customerObj.name] != null) {
                    let ofs = data[customerObj.name].filter(x => x.NumberOfProducts !== 0);
                    setOffers(ofs);
                } else {
                    setOffers("Der er ingen data, endnu.");
                    setError("Der er ingen data, endnu.");
                }
            },
            error => {
                setOffers("Der er ingen data, endnu.");
                setError("Der er ingen data, endnu.");
                console.error(error.message)
            }
        );
    }
    function handleSetSearch(ev) {
        let val = ev.target.value.trim();
        setPaginationOffset(0);
        setSearch(val);
    }

    if (error != null) return <>
        <Typography variant="caption">{error}</Typography><br /><br />
        <Button component={Link1}
            // style={{ float: 'left' }}
            to={isWeek ? "/corrector/week/" + props.year + "/" + props.week : "/corrector"}
            variant="contained" color="primary">Tilbage</Button>
    </>
    if (offers == null) return <Typography variant="caption">Init...</Typography>
    if (offers === 'loading') return <LinearProgress />


    let filteredOffers = search.trim() === "" ? offers : offers.filter(x => x.Header.toUpperCase().includes(search.toUpperCase()) || x.Id.toString().includes(search));
    // console.log(offersEmpty)
    return (
        <>
            <Helmet>
                <title>Billedkombinationer</title>
            </Helmet>
            <div>
                <Button component={Link1} style={{ float: 'left' }}
                    to={isWeek ? "/corrector/week/" + props.year + "/" + props.week : "/corrector"}
                    variant="contained" color="primary">Tilbage</Button>
                {/* <FormControlLabel
                    control={
                        <Switch
                            checked={includeOneProduct}
                            onChange={event =>
                                setIncludeOneProduct(event.target.checked)
                            }
                            value="includeOneProduct"
                            color="primary"
                        />
                    }
                    style={{ float: 'right' }}
                    label="Vis tilbud med et produkt"
                    labelPlacement="start"
                /> */}
                <Typography variant="h4" align="center">{customerObj.renderName} <span style={{ fontSize: 28 }}>{!isWeek ? "Måned "+props.month : "Uge "+props.week+" - "+props.segment}</span></Typography>
                <div style={{ clear: 'both' }}></div>
                <Box justifyContent="center" display="flex" >
                    <Paper style={{ paddingLeft: 10, marginBottom: 10 }} elevation={0}>
                        <InputBase
                            placeholder="Søg"
                            inputProps={{ 'aria-label': 'search' }}
                            style={{ width: 260 }} onBlur={handleSetSearch} onKeyDown={e => e.key === 'Enter' ? handleSetSearch(e) : undefined} />
                        <IconButton aria-label="search">
                            <SearchIcon />
                        </IconButton>
                    </Paper>
                </Box>


                {!Array.isArray(filteredOffers) ? "InvalidObject"
                    : filteredOffers.length === 0 ? <Typography variant="caption">Der er ingen tilbud for dette segment i denne uge.</Typography>
                        : <div>
                            <PaginationWithStyles
                                align="center"
                                limit={paginationLimit}
                                offset={paginationOffset}
                                total={filteredOffers.length}
                                onClick={(e, os) => setPaginationOffset(os)}
                            />
                            {filteredOffers.slice(paginationOffset, paginationOffset + paginationLimit).map(x => (
                                <div key={x.Id}>
                                    <CorrectorSegmentOfferCombinations data={x}
                                        handleSaveWeekSegmentCombination={saveWeekSegmentCombination}
                                        handleDeleteWeekSegmentCombination={deleteWeekSegmentCombination}
                                        yearWeekSegmentCombinations={yearWeekSegmentCombinations}
                                        isWeek={isWeek}
                                    />
                                    <br />
                                </div>
                            ))}
                            <PaginationWithStyles
                                align="center"
                                limit={paginationLimit}
                                offset={paginationOffset}
                                total={filteredOffers.length}
                                onClick={(e, os) => setPaginationOffset(os)}
                            />
                        </div>
                }
            </div>
        </>
    )
}
