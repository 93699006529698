import React, { Component } from "react";
import "./App.css";
import { connect } from "react-redux";
import { getToken } from "./helpers/AuthHelper";
import { checkSessionToken } from "./helpers/AuthHelper/AuthHelperV2";
import { RepublicaMuiTheme } from "./RepublicaMuiTheme";
import { MuiThemeProvider } from "@material-ui/core";
import { appConstants } from "./constants/app.constants";
import AppLayout from "./components/layout/AppLayout";
import packageJson from "../package.json";
import { fetchGet } from "./helpers/HttpHelper";
import { fetchCredentials } from "./redux/actions/credentials.actions";
import AppLoginLayout from "./components/layout/AppLoginLayout";
import { LoadingLogo } from "./components/shared/Loading";
import { addToastMessage } from "./redux/actions/toasts.actions";
import Login from "./components/auth/Login";
import RedirectToLogIn from "./components/auth/RedirectToLogIn";
import { instanceOf } from "prop-types";
import { MainContext } from "./components/sections/dco/contexts/MainContext";

import { fetchFeatures } from "./redux/actions/utils/features.utils.actions";

class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
      validToken: false,
      tokenCheckedOn: null,
      loading: "initial",
      tokenErrorText: ""
    };
  }

  async componentDidMount() {
    
    this.props.dispatch(fetchFeatures());

    this.setState({ ...this.state, loading: "true" });
    
    let _timeout = 1000;
    setTimeout(() => {
      if (getToken() != null) {
        this.checkEnvironment(
          () => {
            this.interval = setInterval(
              () => this.checkEnvironment(),
              appConstants.TOKEN_CHECKING
            );
          }
        );
      }
      else {
        this.setState({
          ...this.state,
          validToken: false,
          tokenCheckedOn: Date.now(),
          loading: "false"
        });
      }
    }, _timeout);
  }

  componentWillUnmount() {
    clearInterval(this.interval);
  }

  async checkEnvironment(callbackInit) {
    await this.checkBuildNumber();
    await this.checkToken();

    if (this.state.validToken) {
      this.getCredentials();
      if (callbackInit) callbackInit();
    }
  }

  async checkBuildNumber() {
    // if(packageJson.ci_build_Number!=="DevEnv"){
    await fetchGet(
      "/manifest.json?" + Math.floor(Math.random() * 10000) + 1,
      json => {
        if (json.ci_build_Number !== packageJson.ci_build_Number) {
          window.location.reload();
        } else
          console.log(
            "You have the correct live version: " + json.ci_build_Number
          );
      },
      error => console.error(error)
    );
    // }
  }

  async checkToken() {

    let r = await checkSessionToken();

    if (typeof r === "boolean") {
      this.setState({
        tokenErrorText: "", validToken: r, tokenCheckedOn: Date.now(), loading: "false"
      })
    } else if (r instanceof Object) {
      this.setState({
        tokenErrorText: r.errorMsg, validToken: r.tokenIsValid, tokenCheckedOn: Date.now(), loading: "false"
      },
        () => {
          console.log(
            "Introspection checked, token checked at: " +
            this.state.tokenCheckedOn +
            ", it will be cheched again at: " +
            (this.state.tokenCheckedOn + appConstants.TOKEN_CHECKING) +
            " (milliseconds)"
          );
        }
      );

    }
  }

  getCredentials() {
    let self = this;
    // window.setTimeout(function () {
    self.props.dispatch(
      fetchCredentials(() => {
        var me = self.props.credentialsReducer.me;

        if (Array.isArray(me) && me.length !== 0) {
          this.setState({ ...this.state, userId: me[0].multiUserId });
        }
      },
        () => {
          window.setTimeout(function () {
            self.props.dispatch(fetchCredentials(() => { }, (error) => { alert("Your credentials could not be retrieved, please try again later. If the problem persists, contact Multibasen support.  \n " + error) }));
          }, 2000);

        }, false));
    // }, 1000);
  }
  render() {
    let mainContext = this.context;

    if (mainContext.userManager && typeof this.state.userId === "string" && this.state.userId !== "") {
      mainContext.userManager.subsribeUserToGroup(this.state.userId);
    }

    if (this.state.loading === "initial") {
      return <h2 style={{ margin: "40px 60px" }}>Intializing...</h2>;
    }
    if (this.state.loading === "true" || (this.state.validToken && this.props.credentialsReducer.me == null)) {
      return (

        <div style={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: `translate(-50%,-50%)`
        }}
        >
          <LoadingLogo style={{ width: "auto", height: 150 }} />
        </div>
      );
    }
    if (!this.state.validToken) {
      return (
        <MuiThemeProvider theme={RepublicaMuiTheme}>
          <div className="App">
            <div className="container">
              <AppLoginLayout error={!this.state.validToken} errorText={this.state.tokenErrorText} />
            </div>
          </div>
        </MuiThemeProvider>
      );
    }
    return (
      <MuiThemeProvider theme={RepublicaMuiTheme}>
        <div className="App">
          <div className="container">
            <AppLayout />
          </div>
          {/* dialogs will be added to this container */}
          <div id="dialog-container"></div>
        </div>
      </MuiThemeProvider>
    );
  }
}

App.contextType = MainContext;

export default connect(state => state)(App);

