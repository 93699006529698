import React, { useState, useEffect } from 'react'
import { Typography, Grid, withStyles, Button, MenuItem, TextField, LinearProgress, createMuiTheme, MuiThemeProvider } from '@material-ui/core'
import { Link as RouterLink } from 'react-router-dom';
import { lightGreen } from '@material-ui/core/colors';
import { RepublicaMuiTheme } from '../../../RepublicaMuiTheme';
import { Helmet } from 'react-helmet';
const Link1 = React.forwardRef((props, ref) => <RouterLink innerRef={ref} {...props} />);

const styles = theme => ({
    root: {
        flexGrow: 1,
    },
    paper: {
        padding: theme.spacing(2),
        textAlign: 'center',
        color: theme.palette.text.secondary,
    },
    rootButtons: {
        flexGrow: 1,
        // marginTop: 10,
        marginBottom: 20,
        maxWidth: 300
    },
    buttonLeft: { marginRight: 8 },
    floatingWeekSelector: {
        position: 'fixed',
        top: 100,
        right: 50
    },
    heading: {
        color: "rgba(0, 0, 0, 0.87)",
        fontSize: "2.1rem",
        marginTop: "0.5em",
        fontFamily: "Noto Sans",
        fontWeight: "bold",
        lineHeight: 1.17
    },
    buttonsWrapper: {
        paddingTop: 20
    }
});

function CorrectorMonth(props) {
    const { classes } = props;
    const theme = createMuiTheme(JSON.parse(JSON.stringify(RepublicaMuiTheme)));
    theme.palette.secondary.main = lightGreen[800];
    theme.palette.secondary.dark = lightGreen[900];

    function renderCustomerGrid(customer) {
        return (
            <Grid container className={classes.rootButtons} >
                <Grid item xs className={classes.buttonLeft}>
                    <Button component={Link1}
                        to={"/corrector/month/" + customer.id + customer.url}// + props.customer.id + "/" + props.year + "/" + props.week + "/" + segment}
                        variant="contained"
                        color="primary"
                        fullWidth>
                        {customer.name}
                    </Button>
                </Grid>
            </Grid>
        )
    }

    return (
        <div>
            <Helmet>
                <title>MB-Billedkombinationer</title>
            </Helmet>
            <Typography variant="h3" align="center">Månedens Køb</Typography>
            <br />
            <br />

            <div className={classes.root}>
                <Grid container spacing={2} className={classes.root} align="center">
                    <Grid item md={6}>
                        <span className={classes.heading}>Denne måned</span>
                        <div className={classes.buttonsWrapper}>
                            {renderCustomerGrid({ id: 17, name: "DagliBrugsen", url: "/0" })}
                            {renderCustomerGrid({ id: 11, name: "SuperBrugsen", url: "/0" })}
                            {renderCustomerGrid({ id: 20, name: "Kvickly", url: "/0" })}
                            {renderCustomerGrid({ id: 10, name: "Fakta", url: "/0" })}
                            {renderCustomerGrid({ id: 51, name: "Coop 365", url: "/0" })}
                        </div>
                    </Grid>
                    <Grid item md={6}>
                        <span className={classes.heading}>Næste måned</span>
                        <div className={classes.buttonsWrapper}>
                            {renderCustomerGrid({ id: 17, name: "DagliBrugsen", url: "/1" })}
                            {renderCustomerGrid({ id: 11, name: "SuperBrugsen", url: "/1" })}
                            {renderCustomerGrid({ id: 20, name: "Kvickly", url: "/1" })}
                            {renderCustomerGrid({ id: 10, name: "Fakta", url: "/1" })}
                            {renderCustomerGrid({ id: 51, name: "Coop 365", url: "/1" })}
                        </div>
                    </Grid>
                </Grid>
            </div>
        </div>
    )
}



export default withStyles(styles)(CorrectorMonth);