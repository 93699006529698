import {
  endpoints,
  apiGetParamsReplace
} from "../../../constants/services.constants";
import { fetchDeleteWT } from "../../../helpers/HttpHelper";
// OPEN MESSAGE FORM
export const DELETE_OPEN_BEGIN = "PRODUCT_DELETE_OPEN_BEGIN";
export const DELETE_CLOSE_BEGIN = "PRODUCT_DELETE_CLOSE_BEGIN";

export const deleteDialogOpenBegin = (id, productName) => ({
  type: DELETE_OPEN_BEGIN,
  payload: { id, productName }
});

export const deleteDialogCloseBegin = () => ({
  type: DELETE_CLOSE_BEGIN
});

export function deleteDialogOpen(id, productName) {
  return dispatch => {
    dispatch(deleteDialogOpenBegin(id, productName));
  };
}
export function deleteDialogClose() {
  return dispatch => {
    dispatch(deleteDialogCloseBegin());
  };
}

// DELETE PRODUCT
export const DELETE_BEGIN = "PRODUCT_DELETE_BEGIN";
export const DELETE_BEGIN_SUCCESS = "PRODUCT_DELETE_SUCCESS";
export const DELETE_BEGIN_FAILURE = "PRODUCT_DELETE_FAILURE";

export const deleteProductBegin = () => ({
  type: DELETE_BEGIN
});

export const deleteProductSuccess = products => ({
  type: DELETE_BEGIN_SUCCESS,
  payload: { products }
});

export const deleteProductFailure = error => ({
  type: DELETE_BEGIN_FAILURE,
  payload: { error }
});

export function deleteProduct(multiProductId, callback) {
  const endpoint = apiGetParamsReplace(
    endpoints.PRODUCT_DELETE,
    ...[multiProductId]
  );

  return dispatch => {
    dispatch(deleteProductBegin());
    return fetchDeleteWT(
      endpoint,
      null,
      json => {
        dispatch(deleteProductSuccess(json));
        if (callback) {
          callback();
        }
      },
      error => dispatch(deleteProductFailure(error))
    );
  };
}
