import React, { Component } from "react";
import { connect } from "react-redux";
import Pagination from "material-ui-flat-pagination";
// Material
import { Typography, LinearProgress } from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import Fade from "@material-ui/core/Fade";
import SortingItem from "./SortingItem";

class SortingSelecting extends Component {

  setDataCard(data) {
    if (data === null) return;
    return;
  }

  render() {
    return (
      <>
        <Fade in={true}>
          <Grid container>
            {this.props.dataRows.map(offer => (
              <SortingItem
                offer={offer}
                key={offer.id}
                loadOffersTags={() => this.props.loadOffersTags()}
              />
            ))}
          </Grid>
        </Fade>
      </>
    );
  }
}

export default connect(state => state)(SortingSelecting);
