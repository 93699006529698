import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import InputBase from '@material-ui/core/InputBase';
import IconButton from '@material-ui/core/IconButton';
import SearchIcon from '@material-ui/icons/Search';
import FilterList from '@material-ui/icons/FilterList';
import Grid from '@material-ui/core/Grid';
import SearchFilterPopup from './SearchFilterPopup';




const styles = {
  root: {
    padding: '2px 4px',
    display: 'flex',
    alignItems: 'center',
    width: '100%',
    marginBottom: 20
  },
  input: {
    marginLeft: 20,
    marginRight: 20,
    flex: 1,
  },
  iconButton: {
    padding: 10,
  },
  divider: {
    width: 1,
    height: 28,
    margin: 4,
  },
  filterIcon: {
    color: "#f02805"
  },
  wrapper: {
    zIndex: 101,
    display: "grid",
    gridTemplateColumns: "100%",
    position: "absolute",
    top: 60,
    width: "100%",
    justifyItems: "end"
  },
  mainContainer: {
    position: "relative"
  }
};


function SearchBar(props) {
  const { classes, onSearch } = props;
  const [searchText, setSearchText] = useState("");
  const [searchFilters, setSearchFilters] = useState(null);
  const [showSearchFilterPopover, setShowSearchFilterPopover] = useState(false);

  useEffect(() => {
    if (props.searchFilters) {
      setSearchFilters(props.searchFilters);
    }
    if (props.searchText) {
      setSearchText(props.searchText);
    }
  }, [props.searchFilters, props.searchText]);

  const handleCancelPopup = () => {
    setShowSearchFilterPopover(false)
  }

  const handleConfirmPopup = (newFilters) => {
    setSearchFilters(newFilters);
    setShowSearchFilterPopover(false);
    handleSearch();
  }

  const hasActiveFilter = () => {
    if (searchFilters) {
      for (const category of searchFilters) {
        if (category.options.some(x => x.selected))
          return true;
      }
    }
    return false;
  }

  const onKeyDown = e => {
    if (e.keyCode === 13) { // enter
      handleSearch();
    }
  }

  const handleSearch = () => {
    const activeFilters = {};
    if (searchFilters) {
      for (const category of searchFilters) {
        const selectedOptions = category.options.filter(x => x.selected).map(x => x.id);
        if (selectedOptions.length > 0) {
          activeFilters[category.key] = selectedOptions;
        }
      }
    }
    onSearch(searchText, activeFilters);
  }


  return (
    <Grid container className={classes.mainContainer}>

      {/* Search bar */}
      <Grid item xs={12}>
        <Paper className={classes.root} elevation={1} >
          <InputBase autoFocus className={classes.input} placeholder="Søg..."
            onKeyDown={onKeyDown}
            onChange={e => setSearchText(e.target.value)}
            value={searchText} />
          <IconButton className={classes.iconButton}
            onClick={() => onSearch(searchText, searchFilters)} >
            <SearchIcon />
          </IconButton>

          {searchFilters ?
            <IconButton onClick={() => setShowSearchFilterPopover(true)}>
              <FilterList className={hasActiveFilter() ? classes.filterIcon : undefined} />
            </IconButton>
            : undefined
          }

        </Paper>
      </Grid>

      {/* Popover */}
      {showSearchFilterPopover ?
        <div className={classes.wrapper}>
          <SearchFilterPopup
            filterOptions={searchFilters}
            onCancel={handleCancelPopup}
            onSubmit={handleConfirmPopup} />
        </div>
        : undefined}

    </Grid>
  );
}

SearchBar.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(SearchBar);

