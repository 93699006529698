import React from 'react';

export default function CustomArrow({ className }) {
    return (
        <img
            style={className}
            alt="plus"
            src={process.env.PUBLIC_URL + '/images/filmmakerIcons/arrow.png'}
        />
    )
}