import React from "react";
import { connect } from "react-redux";
import { withStyles } from "@material-ui/core";
import ElectronicOfferCard from "./ElectronicOfferCard";

const styles = (theme) => ({
  fab: {
    position: "fixed",
    bottom: theme.spacing(6),
    right: theme.spacing(6),
  },
  separator: {
    display: "grid",
    gridTemplateColumns: "auto 1fr",
    gridColumnGap: 5,
  },
  borderSeparator: {
    width: "100%",
    borderTop: "3px dashed #bbb",
  },

});

function OffersContent({ offers, publication, onOfferUpdated }) {

  if (!offers)
    return null;

  return (
    <>
      <section>
        {offers.length === 0 ? (
          <h4>Ingen tilbud</h4>
        ) :
          offers.map((offer) =>
            <ElectronicOfferCard
              publication={publication}
              offer={offer}
              onOfferUpdated={onOfferUpdated}
              key={offer.id}
            />
          )}

        <br style={{ clear: "both" }} />

      </section>
    </>
  );

}

export default withStyles(styles)(connect((state) => state)(OffersContent));
