import React, { useEffect, useState } from 'react';
import { apiGetParamsReplace, endpoints } from '../../../constants/services.constants';
import EditIcon from "@material-ui/icons/Edit";
import IconButton from "@material-ui/core/IconButton";
import { withStyles } from '@material-ui/core';
import { applications } from '../../../constants/app.constants';
import { canReadApplication } from '../../../helpers/CredentialsHelper';

const IconButtonStyled = withStyles({
    root: {
        '&:hover': {
            backgroundColor: "transparent",
        }

    }
})(IconButton);

const styles = {
    editIcon: {
        position: "absolute",
        bottom: 0,
        left: 140,
    }
}

function ImageEditable({ classes, photoId, handleEditImage, randomNumber }) {
    const [motivId, setMotivId] = useState(undefined);
    
    useEffect(() => {
        setMotivId(photoId);
    }, [photoId]);

    if (!motivId) return null;

    return (
        <>
                <div
                    style={{
                        height: 135,                  
                        backgroundImage: `url(${apiGetParamsReplace(endpoints.PHOTO_SINGLE_200, motivId)}?randomNumber=${randomNumber})`,
                        width: 200,
                        backgroundSize: "contain",
                        backgroundRepeat: "no-repeat",
                        backgroundPosition: "center",
                        position: "relative",
                        marginTop: 16
                    }}
                >
                    
                    {!canReadApplication(applications.ERSTATFOTO) ? undefined : 
                        <div className={classes.editIcon}>
                            <IconButtonStyled
                            color="primary"
                            aria-label="edit"
                            onClick={() => handleEditImage()}
                        >
                            <EditIcon />
                        </IconButtonStyled>
                        </div>
                    }
            </div>
        </>
    )
}

export default withStyles(styles)(ImageEditable);