import { useState, useEffect } from "react";
import { fetchGetWT } from "../../../../helpers/HttpHelper";
import { apiGetParamsReplace, endpoints } from "../../../../constants/services.constants";
import { getMotiv300 } from "../../../../helpers/ImageHelper";
function useFetch(combinationId) {
    const [data, setData] = useState(null);
    const [error, setError] = useState(null);
    const [offer, setOffer] = useState(null);
    const [loading, setLoading] = useState(false);
    const [imagesLoaded, setImagesloaded] = useState(0);
    const [imagesTotal, setImagesTotal] = useState(1000);

    useEffect(() => {
        if (offer != null) {
            preloadImages();
        }
    }, [offer]);


    useEffect(() => {
        if (imagesLoaded === 0 && imagesTotal === 999) {
            // let products = typeof (data.jsonTemplate) === 'string' ? JSON.parse(data.jsonTemplate).objects : data.jsonTemplate.objects;
            if (offer.products != null) {
                setImagesTotal(offer.products.length);
                setImagesloaded(0);
                offer.products.forEach(x => {
                    downloadImage(getMotiv300(x.selectedPhotoId,x.rotate));
                });
            }
        }
    }, [imagesLoaded, imagesTotal]);



    function fetchPhotoCombination(callback, callbackError) {

        let endpoint = apiGetParamsReplace(endpoints.PHOTOCOMBINATION_STORE_GETBYID, [combinationId]);
        fetchGetWT(endpoint,
            x => {
                if (x.jsonTemplate === undefined) callbackError(x);

                else callback(x);
            }
            , callbackError
        )
    }

    function fetchOffer(offerId, callback, callbackError) {

        let endpoint = apiGetParamsReplace(endpoints.OFFER_SINGLE_V2, [offerId]);
        fetchGetWT(endpoint,
            z => {
                if (z.products === undefined || z.products.lenth === 0) fetchErrorCallback(z);
                else callback(z);

            }, callbackError
        )
    }

    function fetchErrorCallback(error) {
        console.log("error", error)

        setData(null);
        setError(JSON.stringify(error))
        setLoading(false);

    }


    function loadData() {
        setImagesloaded(0);
        setImagesTotal(0);
        setData(null);
        setLoading(true);
        fetchPhotoCombination(x => {
            x.correctionId = x.id;
            setData(x);
            fetchOffer(x.offerId,
                z => {
                    setOffer(z);
                    setLoading(false);
                },
                fetchErrorCallback
            )
        },
            fetchErrorCallback
        );
    }

    function addImageloaded() {
        setImagesloaded(imagesLoaded => imagesLoaded + 1);
    }

    function preloadImages() {
        setImagesTotal(999);
        setImagesloaded(0);

    }
    async function downloadImage(url) {
        var img = new Image();
        img.src = url;
        img.onload = () => addImageloaded();
    }

    return [data,offer, loading, loadData, imagesLoaded, imagesTotal, error,setData];
}


export { useFetch };