import React, { useState, useEffect } from 'react'
import { Typography, Grid, Button } from '@material-ui/core'
import { endpoints, apiGetParamsReplace } from '../../../constants/services.constants';
import CorrectorDialog from './CorrectorDialog';
import DoneAllIcon from '@material-ui/icons/DoneAll';
import { fetchGetWT, fetchPatchWT, fetchPostWT } from '../../../helpers/HttpHelper';
import CorrectorSegmentOfferCombinationMediaCard from './CorrectorSegmentOfferCombinationMediaCard';
import { IconButton } from '@material-ui/core';
import RefreshIcon from '@material-ui/icons/Refresh';
import AddIcon from '@material-ui/icons/Add';
import { Tooltip } from '@material-ui/core';
import { DialogTitle } from '@material-ui/core';
import { Dialog } from '@material-ui/core';
import { DialogContent } from '@material-ui/core';
import { DialogContentText } from '@material-ui/core';
import { DialogActions } from '@material-ui/core';
import DialogAddPhotoToCombination from './dialogs/addPhotoToCorrections/DialogAddPhotoToCombination';
import { getInfoCombination } from "./dialogs/addPhotoToCorrections/Requests";


export default function CorrectorSegmentOfferCombinations({
    data,
    handleSaveWeekSegmentCombination,
    handleDeleteWeekSegmentCombination,
    yearWeekSegmentCombinations,
    isWeek }) {
    const [error, setError] = useState(null);
    const [generations, setGenerations] = useState(null);
    const [loadMore, setLoadMore] = useState(0);
    // const [openCanvas, setOpenCanvas] = React.useState(false);
    const [selectedGeneration, setSelectedGeneration] = React.useState(null);

    const [correctionResult, setCorrectionResult] = useState(null);

    const [refreshProcessing, setRefreshProcessing] = useState(null);
    const [refreshProcessings, setRefreshProcessings] = useState([]);
    const [loading, setLoading] = useState(false);
    const [dialogRefreshAll, setDialogRefreshAll] = useState(false);
    const [dialogAddPhoto, setDialogAddPhoto] = useState(false);

    useEffect(() => {
        fetchPhotoCombinatonOfferGenerations();
    }, []);
    useEffect(() => {
        if (correctionResult != null) {

            var newCollection = generations;
            let index = newCollection.findIndex(x => x.id === correctionResult.correctionId);
            newCollection[index] = correctionResult;

            setGenerations(sortGenerations(newCollection));
            // handleSaveWeekSegmentCombination(correctionResult.id)
            handleCloseCanvas();
        }
    }, [correctionResult]);
    useEffect(() => {
        if (Array.isArray(yearWeekSegmentCombinations)
            && Array.isArray(generations)
        ) {
            setGenerations(sortGenerations(generations));

        }
        return () => {

        }
    }, [yearWeekSegmentCombinations])

    useEffect(() => {

        if (refreshProcessings.length > 0 && refreshProcessing == null) {
            let p = refreshProcessings[0];
            setRefreshProcessing(p);
            setRefreshProcessings(refreshProcessings.filter(x => x.id != p.id));
        }
        return () => {
        }
    }, [refreshProcessings, refreshProcessing])

    useEffect(() => {
        if (refreshProcessing != null) {
            let endpoint = apiGetParamsReplace(
                endpoints.PHOTOCOMBINATION_OFFERS_GENERATIONS_REFRESH,
                ...[refreshProcessing.id]);
            fetchPatchWT(
                endpoint,
                null,
                response => {
                    fetchGetWT(apiGetParamsReplace(endpoints.PHOTOCOMBINATION_OFFERS_GET_GENERATION_INFO,
                        refreshProcessing.offerId, response.combinationId
                    ),
                        (result) => {
                            let newCollection = [...generations];
                            let index = newCollection.findIndex(x => x.id === refreshProcessing.id);
                            newCollection[index] = result;
                            setGenerations(sortGenerations(newCollection));
                            setRefreshProcessing(null);
                        }, (error) => {
                            console.error(error);
                            setError(error)
                        })
                },
                error => {
                    console.error(new Error(error.message))
                    setError(error);
                }
            );

        }
    }, [refreshProcessing]);

    async function refreshPhotoCombination(newData) {
        const requestIds = newData.ok.map(x => x.combinationId);
        const offerId = newData.ok[0].offerId;
        const result = await fetchNewPhotoCombinations(offerId, requestIds);

        setGenerations(sortGenerations(result));
    };

    const fetchNewPhotoCombinations = async (offerId, combinationIds) => {
        return await Promise.all(
            combinationIds.map(async id => {
                const data = await getInfoCombination(offerId, id);
                return data;
            })
        );
    }

    function handleClickOpenCanvas(selection) {
        setSelectedGeneration(selection);
        //   setOpenCanvas(true);
    }

    function handleCloseCanvas(selection) {
        //   setOpenCanvas(false);
        setSelectedGeneration(null);
    };



    function fetchPhotoCombinatonOfferGenerations() {
        // console.log(w);
        //todo:fix year
        const endpoint = apiGetParamsReplace(endpoints.PHOTOCOMBINATION_OFFERS_GENERATIONS, data.Id);
        setError(null);
        setGenerations('loading');
        // console.log()
        fetchGetWT(
            endpoint,
            response => {
                // console.log(response)
                if (response != null) {
                    if (response.length != 0) {
                        response.offerId = data.Id;
                        setGenerations(sortGenerations(response));
                    } else {
                        setGenerations('error');
                        setError("Der er ingen billeder genereret for dette tilbud.");
                    }
                }
                else {

                    setGenerations('error');
                    setError(JSON.stringify(response));
                }

            },
            error => {
                setError(JSON.stringify(error.message));
                setGenerations('error');
                console.error(error)
            }
        );

    }

    function generationsToRender() {
        return generations.slice(0, 2 + loadMore);
    }


    function renderMessage(text) {
        return (
            <>
                <Typography variant="h6">{data.Header} <Typography variant="caption">{text}</Typography></Typography>

            </>
        )
    }

    const isSelected = (c) => {
        if (!isWeek || yearWeekSegmentCombinations == null) return false;
        let r = yearWeekSegmentCombinations.find(x => x.combinationId === c.id);
        return r;
    }

    function sortGenerations(data) {
        let gs = [...data];
        gs.sort((a, b) => isSelected(a) === undefined && isSelected(b) !== undefined ? -1 : 0);
        return gs;
    }

    function checkAllVisibles() {
        let cs = generationsToRender().filter(x => isSelected(x) === undefined);
        setLoading(true);
        handleSaveWeekSegmentCombination(cs.map(x => x.id), data => setLoading(false), error => setLoading(false));
    }

    function refreshCombinations(selectionIds, callback, callbackError) {
        const endpoint = endpoints.PHOTOCOMBINATION_OFFERS_GENERATIONS_REFRESH_POST;
        if (!Array.isArray(selectionIds)) {
            selectionIds = [selectionIds];
        }


        let postObj = selectionIds.map(x => x);



        fetchPostWT(
            endpoint,
            JSON.stringify(postObj)
            ,
            data => {
                if (callback != null) callback(data);
                fetchPhotoCombinatonOfferGenerations();
                setDialogRefreshAll(false);
            },
            error => {
                console.Error(error)
                if (callbackError != null) callbackError(error);
                fetchPhotoCombinatonOfferGenerations();
            }
        );
    }
    var onRefreshAllConfirmation = () => {
        setDialogRefreshAll("request")
        setLoading(true);
        refreshCombinations(generations.map(x => x.id),
            data => {
                setLoading(false);
                if (data.errors.length === 0) {
                    setDialogRefreshAll(false);
                } else {
                    setDialogRefreshAll(data);
                }
            },
            error => {
                setLoading(false)
                setDialogRefreshAll({ serviceError: error });
                console.Error(error);
            });

    }
    function renderDialogRefreshAll() {

        var confirm = (
            <>
                <DialogContent>
                    <DialogContentText>
                        Er du sikker på at du vil opdatere alle?
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button
                        onClick={() => setDialogRefreshAll(false)}
                        color="primary">
                        Tilbage
                    </Button>
                    <Button
                        onClick={onRefreshAllConfirmation}
                        color="primary" autoFocus>
                        Bekræft
                    </Button>
                </DialogActions>
            </>
        );
        var requesting = (
            <DialogContent>
                <DialogContentText>
                    Opdaterer ...
                </DialogContentText>
            </DialogContent>);


        function renderErrors(errors) {
            return (
                <>
                    <DialogContent>
                        <DialogContentText>
                            En eller flere kan ikke opdateres
                        </DialogContentText>
                        <DialogContentText>
                            {errors.join(", ")}.
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button
                            onClick={() => setDialogRefreshAll(false)}
                            color="primary">
                            Luk
                        </Button>
                    </DialogActions>
                </>
            );

        }

        return (<Dialog
            open={true}
        // onClose={() => setDialogRefreshAll(false)}
        >
            <DialogTitle>Opdater alle</DialogTitle>

            {dialogRefreshAll === "confirm" ? confirm
                : dialogRefreshAll === "request" ? requesting
                    : typeof dialogRefreshAll === 'object' && data.error != null && data.error.length > 0 ? renderErrors(data.errors)
                        : <Button
                            onClick={() => setDialogRefreshAll(false)}
                            color="primary">
                            Close
                        </Button>}
        </Dialog>);
    }


    if (error != null) return renderMessage(error);
    if (generations == null) return renderMessage("Init...");
    if (generations === 'loading') return renderMessage("Henter billeder...");
    if (generations === 'error') return renderMessage("Error...");


    var pendings = generations.length - generationsToRender().length;

    return (
        <>
            <Typography variant="h4"
                style={{ marginBottom: 10 }}
            >{data.Header}
                {!isWeek ? undefined :
                    <IconButton style={{ marginLeft: 20, marginBottom: 5 }}
                        disabled={loading}
                        onClick={checkAllVisibles}>
                        <DoneAllIcon fontSize="large" />
                    </IconButton>
                }
                <Tooltip title="Opdater alle formater" placement="right-start">

                    <IconButton style={{ marginLeft: 0, marginBottom: 5 }}
                        disabled={loading}
                        onClick={() => setDialogRefreshAll("confirm")}
                    >
                        <RefreshIcon fontSize="large" />
                    </IconButton>
                </Tooltip>
                <Tooltip title="Opdater alle formater" placement="right-start">

                    <IconButton style={{ marginLeft: 0, marginBottom: 5 }}
                        disabled={loading}
                        onClick={() => setDialogAddPhoto(true)}
                    >
                        <AddIcon fontSize="large" />
                    </IconButton>
                </Tooltip>
            </Typography>



            <Grid container spacing={2} style={{ marginBottom: 20 }}>



                {generationsToRender().map((z, i) =>

                    <Grid item xs key={z.id}>
                        <CorrectorSegmentOfferCombinationMediaCard
                            loadingMulticheck={loading}
                            refreshProcessing={refreshProcessing}
                            refreshProcessings={refreshProcessings}
                            setRefreshProcessings={setRefreshProcessings}
                            handleClickOpenCanvas={handleClickOpenCanvas}
                            yearWeekSegmentCombinations={yearWeekSegmentCombinations}
                            combination={z}
                            handleDeleteWeekSegmentCombination={handleDeleteWeekSegmentCombination}
                            handleSaveWeekSegmentCombination={handleSaveWeekSegmentCombination}
                            isSelected={isSelected}
                            isWeek={isWeek}
                        />
                    </Grid>
                )}
                {pendings > 0 ? <Button fullWidth variant="outlined" onClick={() => setLoadMore(loadMore + 10)}>Load more ({pendings} left)</Button> : undefined}
                {selectedGeneration != null && selectedGeneration.id != null ? <CorrectorDialog
                    selectedGeneration={selectedGeneration}
                    open={selectedGeneration != null && selectedGeneration.id != null}
                    onClose={handleCloseCanvas}
                    setCorrectionResult={setCorrectionResult}
                />
                    : undefined}
            </Grid>
            {dialogRefreshAll ? renderDialogRefreshAll() : undefined}
            {dialogAddPhoto ?
                <DialogAddPhotoToCombination
                    handleClose={() => setDialogAddPhoto(false)}
                    correctionIds={generations.map(combination => combination.id)}
                    refreshCombinations={(newData, oldCorrectionIds) => refreshPhotoCombination(newData, oldCorrectionIds)}
                    setCorrectionResult={setCorrectionResult}
                /> :
                undefined}
        </>
    )
}