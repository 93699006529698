import {
  endpoints,
  apiGetParamsReplace,
} from "../../../constants/services.constants";
import {
  fetchGetWT,
  fetchPostWT,
  fetchDeleteWT,
  fetchPatchWT,
} from "../../../helpers/HttpHelper";

// POST
export const SAVE_PHOTOORDER_BEGIN = "PHOTO_SAVE_PHOTOORDER_BEGIN";
export const SAVE_PHOTOORDER_SUCCESS = "PHOTO_SAVE_PHOTOORDER_SUCCESS";
export const SAVE_PHOTOORDER_FAILURE = "PHOTO_SAVE_PHOTOORDER_FAILURE";

export const savePhotoOrderBegin = () => ({
  type: SAVE_PHOTOORDER_BEGIN,
});

export const savePhotoOrderSuccess = (fototracker) => ({
  type: SAVE_PHOTOORDER_SUCCESS,
  payload: { fototracker },
});

export const savePhotoOrderFailure = (error) => ({
  type: SAVE_PHOTOORDER_FAILURE,
  payload: { error },
});

export function savePhotoOrder(order, callback, callbackError) {
  const endpoint = endpoints.PHOTO_ORDER_ADD_V2;
  let orderBody = Object.assign({}, order);
  return (dispatch) => {
    dispatch(savePhotoOrderBegin());
    return fetchPostWT(
      endpoint,
      JSON.stringify(orderBody),
      (json) => {
        dispatch(savePhotoOrderSuccess(json));
        if (callback) callback(json);
      },
      (error) => {
        dispatch(savePhotoOrderFailure(error));
        if (callbackError) callbackError(error);
      }
    );
  };
}

// PATCH
export const PATCH_PHOTOTRACKER_BEGIN = "PHOTO_PATCH_PHOTOTRACKER_BEGIN";
export const PATCH_PHOTOTRACKER_SUCCESS = "PHOTO_PATCH_PHOTOTRACKER_SUCCESS";
export const PATCH_PHOTOTRACKER_FAILURE = "PHOTO_PATCH_PHOTOTRACKER_FAILURE";

export const patchPhototrackerBegin = () => ({
  type: PATCH_PHOTOTRACKER_BEGIN,
});

export const patchPhototrackerSuccess = (fototracker) => ({
  type: PATCH_PHOTOTRACKER_SUCCESS,
  payload: { fototracker },
});

export const patchPhototrackerFailure = (error) => ({
  type: PATCH_PHOTOTRACKER_FAILURE,
  payload: { error },
});

export function patchPhototracker(id, body) {
  const endpoint = apiGetParamsReplace(endpoints.EDIT_FOTO_ORDER, [
    id,
  ]);

  return (dispatch) => {
    dispatch(patchPhototrackerBegin());
    return fetchPatchWT(
      endpoint,
      JSON.stringify(Object.assign({}, body)),
      (json) => () => { },
      (error) => dispatch(patchPhototrackerFailure(error)),
      true
    );
  };
}

// OPEN EDIT FORM
export const PHOTOTRACKER_OPEN_BEGIN = "PHOTO_PHOTOTRACKER_OPEN_BEGIN";
export const PHOTOTRACKER_CLOSE_BEGIN = "PHOTO_PHOTOTRACKER_CLOSE_BEGIN";

export const phototrackerDialogOpenBegin = (id) => ({
  type: PHOTOTRACKER_OPEN_BEGIN,
  payload: { id },
});

export const phototrackerDialogCloseBegin = () => ({
  type: PHOTOTRACKER_CLOSE_BEGIN,
});

export function phototrackerDialogOpen(id) {
  return (dispatch) => {
    dispatch(phototrackerDialogOpenBegin(id));
  };
}
export function phototrackerDialogClose() {
  return (dispatch) => {
    dispatch(phototrackerDialogCloseBegin());
  };
}

// CHANGE PRODUCT
export const CHANGE_SELECTED_PRODUCT = "PHOTO_CHANGE_SELECTED_PRODUCT";

export const changeSelectedProductSuccess = (product) => ({
  type: CHANGE_SELECTED_PRODUCT,
  payload: { product },
});

export function changeSelectedProduct(product) {
  return (dispatch) => dispatch(changeSelectedProductSuccess(product));
}
