import React from "react";

// Redux imports
import { connect } from "react-redux";

import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import Typography from "@material-ui/core/Typography";


import { LoadingLinear } from "../../shared/Loading";

// actions
import { getUser, addCustomerPermits, removeCustomerPermits } from "../../../redux/actions/users/users.actions";
import { permitsDialogClose } from "../../../redux/actions/users/permits.users.actions";
import PermitControls from "./PermitControls";

class DialogPermits extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      fullWidth: true,
      maxWidth: "md",
    };
    // this.baseState = this.state;
  }


  componentDidMount() {
    this.props.dispatch(getUser(this.props.usersReducer.permitsId));
  }

  handleUpdate = () => {

    this.handleClose();
  };

  handleClose = () => {
    this.props.dispatch(permitsDialogClose());
  };

  render() {
    let user = this.props.usersReducer.user;
    return (
      <div>
        <Dialog
          open={this.props.usersReducer.permitsDialog}
          onClose={this.handleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          fullWidth={this.state.fullWidth}
          maxWidth={this.state.maxWidth}
        >
          <DialogTitle id="alert-dialog-title">Vælg rettigheder{user == null ? undefined : ": " + user.userName}</DialogTitle>
          <DialogContent>
            {this.props.usersReducer.errorUser != null ? (
              <Typography component={"span"}>
                {this.props.usersReducer.errorUser}
              </Typography>
            ) : this.props.usersReducer.loadingUser ||
              this.props.usersReducer.loadingUser == null
                // || this.props.usersReducer.loadingAddCustomerPermits
                ? (
                  <>
                    <Typography variant="caption">Henter brugerdata...</Typography>
                    <LoadingLinear />
                  </>
                ) : this.props.usersReducer.user == null ?

                  <>
                    <Typography variant="caption">Henter brugerdata...</Typography>
                    <LoadingLinear />
                  </>
                  : (
                    <>
                      <PermitControls user={this.props.usersReducer.user}
                        customers={this.props.utilsReducer.customers}
                        addCustomerPermits={(u, c) => this.props.dispatch(addCustomerPermits(u, c))}
                        removeCustomerPermits={(u, c) => this.props.dispatch(removeCustomerPermits(u, c))}
                        loadingAddCustomerPermits={this.props.usersReducer.loadingAddCustomerPermits}
                        loadingRemoveCustomerPermits={this.props.usersReducer.loadingRemoveCustomerPermits}
                      />
                    </>
                  )}

          </DialogContent>
          <DialogActions>
            {/* <Button onClick={this.handleClose} color="primary" autoFocus>
              Fortryd
            </Button> */}
            <Button onClick={this.handleClose} variant="outlined" color="default">
              Close
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    );
  }
}

export default connect(state => state)(DialogPermits);
