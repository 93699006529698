import React, { useState, useContext, useEffect } from 'react';
import { withStyles, TextField, Paper, FormControl, InputLabel, Select, MenuItem, FormHelperText, Checkbox, MuiThemeProvider, IconButton } from '@material-ui/core';
import { FeedsContext } from '../../../contexts/FeedsContext';
import { DatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import MomentUtils from '@date-io/moment';
import moment from 'moment';
import { RepublicaDatePickerMuiTheme } from '../../../../../../RepublicaMuiTheme';
import CloseIcon from '@material-ui/icons/Close';
import { RenderValueField, RenderValue2Field } from "./InputFields";
import { validateValueType } from './FieldsValidation';

const styles = () => ({
    selectField: {
        marginTop: 4
    },
    cardHolder: {
        padding: 60,
        display: "grid",
        gridTemplateColumns: "1fr 1fr 1fr",
        gridColumnGap: "10px"
    },
    cardHolderExtended: {
        padding: 60,
        display: "grid",
        gridTemplateColumns: "1fr 1fr 1fr 1fr",
        gridColumnGap: "10px"
    },
    error: {
        color: "#f44336"
    }
});


function FilterProperty(props) {
    const { filterProperties: filterPropertiesState, filterChild: filterChildState, filterWasCreated: filterWasCreatedState, filterExists: filterExistsState } = useContext(FeedsContext);
    const [filterProperties,] = filterPropertiesState;
    const [filterChild, setFilterChild] = filterChildState;
    const [filterExists, setFilterExists] = filterExistsState;
    const [operators, setOperators] = useState([]);
    const [validPropertyType, setValidPropertyType] = useState("");
    const [filterWasCreated, setFilterWasCreated] = filterWasCreatedState;

    // Validation on requited fields
    const [propertyError, setPropetyError] = useState("");
    const [operatorError, setOperatorError] = useState("");
    const [valueError, setValueError] = useState("");
    const [value2Error, setValue2Error] = useState("");


    const { classes, index: filterChildIndex, filterStatementIndex } = props;

    const [filterProperty, setFilterProperty] = useState({
        propertyId: "",
        operation: { id: "" },
        value: "",
        value2: ""
    });

    useEffect(() => {
        if (filterExists && !filterWasCreated) {
            setFilterData();
        }
    }, [])

    const validateRequiredFields = () => {
        let isValid = false;

        if (filterProperty.propertyId === "") {
            setPropetyError("Skal være udfyldt!");
        } else {
            setPropetyError("");
        }

        if (filterProperty.operation.id === "") {
            setOperatorError("Skal være udfyldt!")
        } else {
            setOperatorError("");
        }

        if (filterProperty.operation.id === 1 && filterProperty.value2 === "") {
            setValue2Error("Skal være udfyldt!");
        } else {
            setValue2Error("");
        }

        if (filterProperty.value === "") {
            setValueError("Skal være udfyldt!");
        } else {
            setValueError("");
        }

        if (filterProperty.operation.id === 1) {
            isValid = !!filterProperty.propertyId && !!filterProperty.operation.id && !!filterProperty.value2 && !!filterProperty.value;
        } else {
            isValid = !!filterProperty.propertyId && !!filterProperty.operation.id && !!filterProperty.value;
        }
        return isValid;
    }

    const validateRequiredFieldsOnEdit = (patchObject) => {
        let isValid = false;

        if (patchObject.value === "") {
            setValueError("Skal være udfyldt!");
        } else {
            setValueError("");
        }

        if (patchObject.operation.id === 1) {
            if (patchObject.value2 === "") {
                setValue2Error("Skal være udfyldt!");
            } else {
                setValue2Error("");
            }
        }

        if (patchObject.operation.id === 1) {
            isValid = !!patchObject.value2 && !!patchObject.value
        } else {
            isValid = !!patchObject.value;
        }

        return isValid;
    }

    const handleSelection = ({ propertyId, operation, value, value2, validPropertyType, final }) => {
        // Clear the values when property has changed
        let newFilterProperty = { ...filterProperty }

        if (propertyId !== undefined && propertyId !== filterProperty.propertyId) {
            newFilterProperty.operation.id = "";
            newFilterProperty.value = "";
            newFilterProperty.value2 = "";
        }

        // Do not check if field is required if date or bool is a valid property type
        if ((final && validPropertyType !== "Date") && (final && validPropertyType !== "Boolean")) {
            if (!validateRequiredFields()) {
                return;
            }
        }

        // Clone value to remove references from state.
        // Clone only properties with values, ignore undefined.
        let tempObject = { propertyId, operation, value, value2 };
        Object.entries(tempObject).forEach(([key, value]) => {
            if (value === undefined) {
                return;
            }

            newFilterProperty[key] = value;
        });

        if (value !== undefined || value2 !== undefined) {
            // If one one the values was updated validate type for it.
            const validValue = validateValueType({ validPropertyType, value: value || value2 });

            if (!validValue) { // Invalid type.
                return;
            }
        }

        // Parse string to int before saving
        if (validPropertyType === "Number") {

            if (value !== undefined) {
                if (newFilterProperty.value !== "") {
                    newFilterProperty.value = parseFloat(newFilterProperty.value);
                }
            } else {
                if (newFilterProperty.value2 !== "") {
                    newFilterProperty.value2 = parseFloat(newFilterProperty.value2);
                }
            }
        }

        props.isEdit && filterStatementIndex !== undefined ? handleEditFilter({ newFilterProperty, final }) : updateSelection({ newFilterProperty, final });

    }

    const updateSelection = ({ newFilterProperty, final }) => {
        // To assign new property to a correct index we check if we add it to a new filter or edit excisting
        let newPropertyIndex = undefined;
        props.isEdit ? newPropertyIndex = filterChildIndex - 1 : newPropertyIndex = filterChildIndex;
        // Clone array to not have the same reference.
        const newFilterProperties = [...filterChild];
        // Set value to correct index. (Don't touch indexes on this array!!!)
        newFilterProperties[newPropertyIndex] = newFilterProperty;

        // Update states.
        setFilterProperty(newFilterProperty);

        // Do not send POST when Operator is "between" but only one value is sent
        if (final === undefined || (final && newFilterProperty.operation.id === 1 && newFilterProperty.value2 === "") || (final && newFilterProperty.operation.id === 1 && newFilterProperty.value === "")) {
            return;
        }

        if (props.isEdit) {
            setFilterWasCreated(true);
        }

        const finalFilterProperties = [...newFilterProperties]

        // Clear Value2 if it's not between operator.
        if (filterProperty.operation.id !== 1) {
            delete finalFilterProperties[newPropertyIndex].value2;
        }

        setFilterChild(finalFilterProperties);
    }

    const getAvaiableProperites = (selectedValue) => {
        var inUseProperites = new Set(filterChild.map(x => x.propertyId));
        return filterProperties.filter(x => selectedValue === x.propertyId || !inUseProperites.has(x.propertyId));
    }

    const handleEditFilter = ({ newFilterProperty, final }) => {
        let connectorNew = { connector: filterChild[filterStatementIndex].connector };
        // Clone array to not have the same reference.
        const newFilterProperties = [...filterChild];
        // Set value to correct index. (Don't touch indexes on this array!!!)
        newFilterProperties[filterStatementIndex] = newFilterProperty;

        setFilterProperty(newFilterProperty);
        if (final === undefined || (final && !validateRequiredFieldsOnEdit(newFilterProperty))) {
            return;
        }

        setFilterWasCreated(true);
        const filterProperties = [...newFilterProperties];

        let temporaryNewProperty = { ...filterProperties[filterStatementIndex], ...connectorNew };

        filterProperties[filterStatementIndex] = temporaryNewProperty;


        // Clear Value2 if it's not "between" operator.
        if (filterProperty.operation.id !== 1) {
            delete filterProperties[filterStatementIndex].value2;
        }
        setFilterChild(filterProperties);
    }

    useEffect(() => {
        const property = filterProperties.find(x => x.PropertyId === filterProperty.propertyId);
        if (!property) {
            return;
        }
        const operators = (property || {}).ValidOperators || [];
        setOperators(operators);
        const validPropertyType = property.Type;
        setValidPropertyType(validPropertyType)
    }, [filterProperty, filterProperties, operators])

    const setFilterData = () => {
        let tempValues = filterProperty;
        if (props.statement) {
            tempValues.propertyId = props.statement.propertyId;
            tempValues.operation = { id: props.statement.operation.id };
            tempValues.value = props.statement.value;
            tempValues.value2 = props.statement.value2;
        }

        setFilterProperty(tempValues)
    }

    const selectFilterPropertyParameter = ({ propertyId }) => {
        handleSelection({ propertyId: propertyId });
    }

    const selectFilterPropertyOperator = ({ operation }) => {
        let final = undefined;
        let id = operation.id;
        props.isEdit && filterStatementIndex !== undefined && filterProperty.operation.id !== "" ? final = true : final = undefined;
        handleSelection({ operation: { id: id }, final });
    }

    const handleDateSelection = (date, value2) => {
        let dateFormatted = undefined;

        // Check if operator "Between" is selected
        if (value2 === undefined) {
            dateFormatted = new Date(date.toJSON().split("T")[0]);
            handleSelection({ value: dateFormatted, validPropertyType, final: true });
        } else {
            dateFormatted = new Date(date.toJSON().split("T")[0]);
            handleSelection({ value2: dateFormatted, validPropertyType, final: true });
        }
    }

    const handleNumberSelection = ({ value, value2, final, validPropertyType }) => {
        let isValue2 = false;

        if (value2 !== undefined) {
            isValue2 = true;
        }
        // Allow only int and float
        let isFloat = false;
        isValue2 ? isFloat = /^\d*,?\d*$/.test(value2) : isFloat = /^\d*,?\d*$/.test(value);

        if (!isFloat) {
            isValue2 ? setValue2Error("Kun tal og decimaler") : setValueError("Kun tal og decimaler");
            return;
        } else {
            isValue2 ? setValue2Error("") : setValueError("");
        }

        isValue2 ? handleSelection({ value2: value2, validPropertyType, final }) : handleSelection({ value: value, validPropertyType, final })
    }

    return (
        <Paper style={{ marginTop: 20 }}>
            {/* <IconButton edge="start" color="inherit" onClick={(e) => deleteFilterProperty()} aria-label="close">
                <CloseIcon />
            </IconButton> */}
            <div className={filterProperty.operation.id === 1 ? classes.cardHolderExtended : classes.cardHolder}>
                <FormControl fullWidth>
                    <InputLabel shrink htmlFor="typeId">Parameter</InputLabel>
                    <Select
                        style={{ marginTop: 21 }}
                        fullWidth
                        value={filterProperty.propertyId}
                        onChange={(e) => selectFilterPropertyParameter({ propertyId: e.target.value })}
                        MenuProps={{ classes: { paper: classes.dropdownStyle } }}
                        inputProps={{
                            name: "typeId",
                            id: "typeId"
                        }}
                    >
                        {getAvaiableProperites(filterProperty.propertyId).map((property, index) => (
                            <MenuItem key={index} value={property.PropertyId}>
                                {property.DisplayName}
                            </MenuItem>
                        ))}

                    </Select>
                    {!!propertyError && <FormHelperText className={classes.error}>{propertyError}</FormHelperText>}
                </FormControl>

                <FormControl fullWidth>
                    <InputLabel shrink htmlFor="typeId">Operator</InputLabel>
                    <Select
                        style={{ marginTop: 21 }}
                        fullWidth
                        value={filterProperty.operation.id}
                        onChange={(e) => selectFilterPropertyOperator({ operation: { id: e.target.value } })}
                        MenuProps={{ classes: { paper: classes.dropdownStyle } }}
                        inputProps={{
                            name: "typeId",
                            id: "typeId"
                        }}
                    >
                        {operators.map((operator, index) => (
                            <MenuItem key={index} value={operator.id}>
                                {operator.DisplayName}
                            </MenuItem>
                        ))}

                    </Select>
                    {!!operatorError && <FormHelperText className={classes.error}>{operatorError}</FormHelperText>}
                </FormControl>
                <RenderValueField
                    validPropertyType={validPropertyType}
                    valueError={valueError}
                    filterProperty={filterProperty}
                    isEdit={props.isEdit}
                    handleSelection={(e) => handleSelection(e)}
                    handleNumberSelection={(e) => handleNumberSelection(e)}
                    handleDateSelection={(e) => handleDateSelection(e)}
                />
                {filterProperty.operation.id === 1 ?
                    <RenderValue2Field
                        validPropertyType={validPropertyType}
                        value2Error={valueError}
                        filterProperty={filterProperty}
                        isEdit={props.isEdit}
                        handleSelection={(e) => handleSelection(e)}
                        handleNumberSelection={(e) => handleNumberSelection(e)}
                        handleDateSelection={(e) => handleDateSelection(e)}

                    />
                    : undefined}
            </div >
        </Paper>
    )

}

export default withStyles(styles)(FilterProperty)