import React from "react";

import { connect } from "react-redux";

import EditIcon from "@material-ui/icons/Edit";
import DeleteIcon from "@material-ui/icons/Delete";
import IconButton from "@material-ui/core/IconButton";
import BuildIcon from "@material-ui/icons/Build";

import { deleteDialogOpen } from "../../../redux/actions/users/delete.users.actions";
import { editDialogOpen } from "../../../redux/actions/users/edit.users.actions";
import history from "../../../routes/history";
import { applications } from "../../../constants/app.constants";
import { canDeleteApplication } from "../../../helpers/CredentialsHelper";

class ActionButtons extends React.Component {
  // handleResetpasswordClickOpen = id => {
  //   this.props.dispatch(resetpasswordDialogOpen(id));
  // };

  handleEditClickOpen = id => {
    this.props.dispatch(editDialogOpen(id));
  };


  // handlePermitsClickOpen = id => {
  //   this.props.dispatch(permitsDialogOpen(id));
  // };


  handleDeleteClickOpen = id => {
    this.props.dispatch(deleteDialogOpen(id));
  };

  handlePermitsClickOpen = id => {
    history.push("/userpermits/" + id);
  }

  render() {
    return (
      <>
        {/*RESET PASSWORD BUTTON */}
        {/* <IconButton
          className="float-left"
          color="primary"
          aria-label="reset password"
          onClick={() => this.handleResetpasswordClickOpen(this.props.item.id)}
        >
          <MailIcon />
        </IconButton> */}

        {/*EDIT BUTTON */}
        <IconButton
          className="float-left"
          color="primary"
          aria-label="edit"
          onClick={() => this.handleEditClickOpen(this.props.item.id)}
        >
          <EditIcon />
        </IconButton>


        {/*PERMITS BUTTON */}
        <IconButton
          className="float-left"
          color="primary"
          aria-label="edit"
          onClick={() => this.handlePermitsClickOpen(this.props.item.id)}
        >
          <BuildIcon />
        </IconButton>

        {canDeleteApplication(applications.BRUGERSTYRING) !== true ? undefined :

          <IconButton
            color="primary"
            className="float-left"
            aria-label="delete"
            onClick={() => this.handleDeleteClickOpen(this.props.item.id)}
          >
            <DeleteIcon />
          </IconButton>
        }
      </>
    );
  }
}

export default connect(state => state)(ActionButtons);
