export var loadEntity = function(entity,dispatch, action) {
    if(entity==null
       || entity.length===0)
      dispatch(action());
  };
export var checkCustomerState= function(reducer){
    if(reducer.loadingCustomers==null)
        return "Initializing...";
    
    if(reducer.loadingCustomers===true)
        return "Loading.";
    
    if(reducer.loadingCustomers===false
       && reducer.errorCustomers!=null)
        return "Error produced fetching data.";

    if(reducer.customers==null)
        return "No customers data fetched.";

}