import React, { useEffect, useState } from 'react';
import Container from '@material-ui/core/Container';
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';
import AddHelpPage from './AddHelpPage';
import Grid from '@material-ui/core/Grid';
import { deleteHelpPage, getHelpPages } from './businessLogic/FetchHeadlines'
import { apiGetParamsReplace, endpoints } from "../../../constants/services.constants";
import { getToken } from "../../../helpers/AuthHelper";
import Resources from "../../../Resources.json";

function Headline(props) {
    const { page, privAddHelpPage, setHelppages } = props;
    const [file, setFile] = useState([]);

    const deletePage = async (index) => {
        await deleteHelpPage(index);
        fetchAllPages();
    }
    const fetchAllPages = async () => {
        const pages = await getHelpPages();
        if (!pages) {
            setHelppages([]);
            return;
        }
        setHelppages(pages);
    }

    async function fetchPicture(filename, index) {
        const endpoint = apiGetParamsReplace(endpoints.HELPPAGE_Get_Single_PictureFile, filename);
        let reader = new FileReader();
        const response = await fetch(endpoint, {
            method: 'GET', headers: {
                Authorization: "Bearer " + getToken(),
                "x-organization-id": Resources.Organization
            }
        });
        const resBlob = await response.blob();
        reader.onload = function () {
            setFile(file => [...file, { index: index, picture: reader.result }]);
        }
        reader.readAsDataURL(resBlob);
    }

    useEffect(() => {
        const runEffect = async () => {
            if (file !== []) { setFile([]) }
            page.steps.map(async (step, index) => {
                if (step.fileName !== "") {
                    await fetchPicture(step.fileName, index)
                }
            })
        }
        runEffect();
    }, [props.page.id])

    return (
        <div>
            <h2>{page.title}</h2>
            {page.steps.map((step, index) => {
                return (
                    <div style={{ marginButtom: 30 }}>
                        <Grid container spacing={3}>
                            <Grid item xs={0}>
                                <p align='center' style={{ fontWeight: 'bold' }}>{index + 1 + "."}</p>
                            </Grid>
                            <Grid item xs={11}>
                                <p>
                                    {step.text}
                                </p>
                                {
                                    file.map((pictureFile) => {
                                        return (
                                            pictureFile.index == index &&
                                            <img src={pictureFile.picture}
                                                style={{ maxHeight: 500, maxWidth: 800 }}
                                            />
                                        )
                                    }
                                    )
                                }
                            </Grid>
                        </Grid>
                    </div>
                )
            })
            }
            {!privAddHelpPage ? undefined :
                (<Grid container>
                    <Grid xs={6}>
                        <div align="left">
                            <Button variant="contained" color="secondary" onClick={() => deletePage(page.id)} style={{ margin: 8 }}>Slet</Button>
                        </div>
                    </Grid>
                    <Grid xs={6}>
                        <div align="right">
                            <AddHelpPage buttonText="Rediger siden" helpPageObject={page} setHelppages={setHelppages} />
                        </div>
                    </Grid>
                </Grid>)
            }
            <br />
        </div>
    );
}

export default Headline;