import React from "react";
import {
  Card,
  IconButton,
  CardMedia,
  CardContent,
  Divider,
  CardActions,
  Button,
  withStyles,
  Tooltip
} from "@material-ui/core";
import DeleteIcon from "@material-ui/icons/Delete";
import EditIcon from "@material-ui/icons/Edit";
import PauseIcon from "@material-ui/icons/Pause";
import PlayIcon from "@material-ui/icons/PlayArrow";
import { addToastMessage } from "../../../redux/actions/toasts.actions";
import store from "../../../redux/store";
import { apiGetParamsReplace, endpoints } from "../../../constants/services.constants";
import { getMotivId, getShortDate } from "../../../helpers/UtilHelper";
import { openDialogFullsizePhoto } from "../dialogs/DialogFullsizePhoto";
import { orange } from "@material-ui/core/colors";
import ExclusiveIcon from "../components/ExclusiveIcon";
import { canReadApplicationForCustomer } from "../../../helpers/CredentialsHelper";
import { applications } from "../../../constants/app.constants";
import ActiveButton from "./ActiveButton";
import { photoStatusEnum } from "../../../constants/enums";
import { getPhotoOrderSingleV2, getPhotoOrdersByPhotoIds } from "../../sections/fotohaandtering/Requests";

const styles = theme => ({
  card: {
    maxWidth: "210px",
    position: "relative",
    overflow: "visible"
  },
  cardMedia: {
    height: 140,
    backgroundSize: "contain",
    cursor: "pointer"
  },
  cardActions: {
    display: "inherit",
    textAlign: "center",
    paddingTop: 5,
    paddingBottom: 5
  },
});

function CardPictureItem(props) {

  const { photoId, order, isVariant, isActive, created, deleteDate, showVariant, onSetVariant, onSetActive, onDelete, onOrderStatus, photoStatus,
    onEdit, classes, loading, customerIdForPhoto, exclusiveForCustomerIds, photoType, isDeselected } = props;

  const showErrorClickCacidiButton = () => {
    store.dispatch(
      addToastMessage({
        message: "Aktive billeder bliver altid sendt til Cacidi",
        type: "error"
      })
    );
  }


var isPackPhoto = photoType === 'Pack';
var changeOrderStatusTooltip = isDeselected ? "Aktiver foto" : "Deaktiver foto";

return isDeselected ? (
  <>
    <div style={{ display: "flex", justifyContent: "end", alignItems: "center", height: 30 }}>
        <Tooltip title={ changeOrderStatusTooltip }>        
          <IconButton color="primary" size="small" onClick={() => onOrderStatus(photoId, false)}>
              <PlayIcon />
            </IconButton> 
        </Tooltip>
    </div>
    <Card className={classes.card} image={apiGetParamsReplace(endpoints.PHOTO_SINGLE_200, photoId)}>
      <CardMedia className={classes.cardMedia}
            image={apiGetParamsReplace(endpoints.PHOTO_SINGLE_200, photoId)}
            title=""
          />
      <CardContent style={{ height: 60 }}>
          <div>
            <span style={{ fontWeight: 600 }}>Motiv-Id: </span>
            <span>{getMotivId(photoId)}</span>
          </div>
      </CardContent>
      <Divider />
      <CardActions className={classes.cardActions} disabled={true}> 
        {loading ? 
          <Button disabled={true}> ... </Button> :
          <Button disabled={true}> DEAKTIVERET FOTO </Button>
        }
        </CardActions>
      </Card>
    </>
  )
  :
  ( //active component
    <>
      <div style={{ display: "flex", justifyContent: "end", alignItems: "center", height: 30 }}>
        {exclusiveForCustomerIds && exclusiveForCustomerIds.length > 0 ?
          <ExclusiveIcon exclusiveForCustomerIds={exclusiveForCustomerIds} /> : undefined
        }
        {canReadApplicationForCustomer(applications.FOTOS, customerIdForPhoto) ?
          <IconButton color="primary" size="small" onClick={() => onEdit(photoId)}>
            <EditIcon />
          </IconButton> : null
        }
  
        {isPackPhoto && !isDeselected && !isActive ? 
          <Tooltip title={ changeOrderStatusTooltip }>
            <IconButton disabled={isActive} color="primary" size="small" onClick={() => onOrderStatus(photoId, true)}>
                <PauseIcon />
              </IconButton> 
          </Tooltip>: undefined
        }     
        {!isActive ?
 
          <IconButton color="secondary" size="small" onClick={() => onDelete(photoId, photoStatus)}>
            <DeleteIcon />
          </IconButton>
          : undefined
        }
      </div>
      <Card className={classes.card}>
        <CardMedia className={classes.cardMedia}
          image={apiGetParamsReplace(endpoints.PHOTO_SINGLE_200, photoId)}
          onClick={() => openDialogFullsizePhoto(photoId)}
          title=""
        />
        <CardContent style={{ height: 60 }}>
          <div>
            <span style={{ fontWeight: 600 }}>Motiv-Id: </span>
            <Tooltip title={"Oprettet: "+getShortDate(created)}><span>{getMotivId(photoId)}</span></Tooltip>
          </div>

          {deleteDate && photoStatus === photoStatusEnum.Active.key ?
            <div style={{ color: orange[500] }}>
              Slettes: {getShortDate(deleteDate)}
            </div>
            : null
          }
        </CardContent>
        <Divider />

        {/* Active button */}
        
        <CardActions className={classes.cardActions} disabled={true}> 
          <ActiveButton 
            photoId={photoId}
            isExclusive={exclusiveForCustomerIds.length > 0 && !exclusiveForCustomerIds.includes(customerIdForPhoto)}
            isLoading={loading}
            isActive={isActive}
            onSetActive={() => onSetActive(photoId)}
          />

          {/* Variant Button */}
          {showVariant ? (loading ? (
            <Button disabled color="primary">
              ...
            </Button>
          ) : !isVariant && !isActive ? (
            <Button onClick={() => onSetVariant(photoId, !isVariant)}>
              InDesign
            </Button>
          ) : isActive ? (
            <Button onClick={() => showErrorClickCacidiButton()} style={{
              backgroundColor: "#00008e",
              color: "white"
            }}>
              InDesign
            </Button>
          ) : (
            <Button onClick={() => onSetVariant(photoId, !isVariant)} style={{
              backgroundColor: "#00008e",
              color: "white"
            }}>
              InDesign
            </Button>)) : undefined}
        </CardActions>
      </Card>
    </>
  );
}

export default withStyles(styles)(CardPictureItem);
