import {
  apiGetParamsReplace,
  endpoints
} from "../../../constants/services.constants";
import { fetchGetWT } from "../../../helpers/HttpHelper";

// OPEN EDIT FORM
export const EDIT_OFFERPRODUCT_OPEN_BEGIN =
  "OFFER_EDIT_OFFERPRODUCT_OPEN_BEGIN";
export const EDIT_OFFERPRODUCT_CLOSE_BEGIN =
  "OFFER_EDIT_OFFERPRODUCT_CLOSE_BEGIN";

export const editDialogOpenBegin = (offerproductId, offerId) => ({
  type: EDIT_OFFERPRODUCT_OPEN_BEGIN,
  payload: { offerproductId, offerId }
});

export const editDialogCloseBegin = () => ({
  type: EDIT_OFFERPRODUCT_CLOSE_BEGIN
});

export function editDialogOpen(offerproductId, offerId) {
  return dispatch => {
    dispatch(editDialogOpenBegin(offerproductId, offerId));
  };
}
export function editDialogClose() {
  return dispatch => {
    dispatch(editDialogCloseBegin());
  };
}

// GET BY ID
export const GET_OFFERPRODUCT_BEGIN = "OFFER_GET_OFFERPRODUCT_BEGIN";
export const GET_OFFERPRODUCT_SUCCESS = "OFFER_GET_OFFERPRODUCT_SUCCESS";
export const GET_OFFERPRODUCT_FAILURE = "OFFER_GET_OFFERPRODUCT_FAILURE";

export const getOfferproductBegin = () => ({
  type: GET_OFFERPRODUCT_BEGIN
});

export const getOfferproductSuccess = (offerproduct, offerId) => ({
  type: GET_OFFERPRODUCT_SUCCESS,
  payload: { offerproduct, offerId }
});

export const getOfferproductFailure = error => ({
  type: GET_OFFERPRODUCT_FAILURE,
  payload: { error }
});

export function getOfferproduct(offerproductId,callback) {
  const endpoint = apiGetParamsReplace(endpoints.OFFER_PRODUCT_SINGLE, [
    offerproductId
  ]);
  return dispatch => {
    dispatch(getOfferproductBegin());
    return fetchGetWT(
      endpoint,
      json => {
        dispatch(getOfferproductSuccess(json, null))
        if(callback)
          callback();
      },
      error => dispatch(getOfferproductFailure(error))
    );
  };
}
