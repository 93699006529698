// OPEN EDIT FORM
export const EDIT_OPEN_BEGIN = "USER_EDIT_OPEN_BEGIN";
export const EDIT_CLOSE_BEGIN = "USER_EDIT_CLOSE_BEGIN";

export const editDialogOpenBegin = id => ({
  type: EDIT_OPEN_BEGIN,
  payload: { id }
});

export const editDialogCloseBegin = () => ({
  type: EDIT_CLOSE_BEGIN
});

export function editDialogOpen(id) {
  return dispatch => {
    dispatch(editDialogOpenBegin(id));
  };
}
export function editDialogClose() {
  return dispatch => {
    dispatch(editDialogCloseBegin());
  };
}
