import { endpoints } from "../../../../../../constants/services.constants";
import { getToken } from "../../../../../../helpers/AuthHelper";
import { _request } from "../../../../../../helpers/HttpHelper";

import Resources from "../../../../../../Resources.json";

export async function uploadPhoto(body) {
    const headers = {
        Accept: "application/json, application/xml, text/plain, text/html, *.*",
        Authorization: "Bearer " + getToken(),
        "x-organization-id": Resources.Organization
    };
    return _request({
        method: "POST",
        endpoint: endpoints.PHOTO_UPLOAD,
        body: body,
        throwError: true,
        headers: headers,
    });
}