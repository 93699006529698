import React, { useContext, useEffect } from 'react';
import MUIDataTable from 'mui-datatables';
import { FotoClippingContext } from './FotoClippingContext';
import DownloadIcon from "@material-ui/icons/CloudDownload";
import ArchiveIcon from '@material-ui/icons/Archive';
import { getShortDate, showSuccessNotification } from '../../../helpers/UtilHelper';
import { photoClippingStatus } from "../../../constants/enums";
import { IconButton, TableCell, TableRow, Tooltip } from '@material-ui/core';
import { downloadFileFromClippingOrder, downloadFilesAsZip } from "./Requests";

function PhotoClippingOrdersTable(props) {
    const {
        openClippingOrderDialog: openClippingOrderDialogState,
        fotoClippingOrders: fotoClippingOrdersState,
    } = useContext(FotoClippingContext);

    const [groups, setFotoClippingOrders] = fotoClippingOrdersState;

    useEffect(() => {
        getDataTable();
    }, [groups])

    const columns = [
        {
            name: "Id",
            options: {
                filter: false,
                sort: false,
                display: false,
            }
        },
        {
            name: "WB jobnummer",
            options: {
                filter: false,
                sort: false,
                display: true,
            }
        },
        {
            name: "Filnavn",
            options: {
                filter: false,
                sort: false,
                display: true,
            }
        },
        {
            name: "Genereret filnavn til Bangladesh",
            options: {
                filter: false,
                sort: false,
                display: true,
            }
        },
        {
            name: "Anmodet",
            options: {
                filter: false,
                sort: false,
                display: true,
            }
        },
        {
            name: "Færdiggjort",
            options: {
                filter: false,
                sort: false,
                display: true,
            }
        },
        {
            name: "Status",
            options: {
                filter: false,
                sort: false,
                display: true,
            }
        },
        {
            name: "",
            options: {
                filter: false,
                sort: false,
                display: true,
            }
        },
    ]

    const components = {
        ExpandButton: function (props) {
            if (props.dataIndex === 3 || props.dataIndex === 4) return <div style={{ width: '24px' }} />;
            return <this.ExpandButton {...props} />;
        }
    }

    const options = {
        textLabels: {
            body: {
                noMatch: "",
                toolTip: "Sorter"
            },
            pagination: {
                next: "Næste side",
                previous: "Forrige side",
                rowsPerPage: "Fritlægninger per side:",
                displayRows: "af"
            },
            selectedRows: {
                text: "valgt række(r)",
            }
        },
        filter: false,
        filterType: "checkbox",
        rowsPerPage: 15,
        print: false,
        rowHover: false,
        responsive: "scrollFullHeight",
        download: false,
        viewColumns: false,
        search: false,
        selectableRows: false,
        expandableRowsOnClick: false,
        expandableRows: true,
        isRowExpandable: (dataIndex) => {
            const group = groups[dataIndex];
            return group.requests.length > 1;
        },
        renderExpandableRow: (rowData, rowMeta) => {
            const group = groups.find(x => x.requests[0].id === rowData[0]);
            return <>
                {group.requests.map(request =>

                    <TableRow style={{ backgroundColor: "#F5F5F9" }} >
                        <TableCell />
                        <TableCell>{request.jobId}</TableCell>
                        <TableCell>{request.fileName}</TableCell>
                        <TableCell>{request.generatedFileName}</TableCell>
                        <TableCell>{request.dateRequested ? getShortDate(request.dateRequested) : ""}</TableCell>
                        <TableCell>{request.dateCompleted ? getShortDate(request.dateCompleted) : ""}</TableCell>
                        <TableCell>{photoClippingStatus[request.status]}</TableCell>
                        <TableCell>{request.status === "Complete" ?
                            getDownloadFileButton(request.id)
                            : undefined}
                        </TableCell>
                    </TableRow>
                )}
            </>
        },
    }

    const handleDownloadZip = async (groupingId) => {
        showSuccessNotification("Filerne bliver klargjort. Du vil modtage dem i en email inden længe.");
        downloadFilesAsZip(groupingId);
    }

    const getGroupingStatus = (grouping) => {
        if (grouping.requests.some(x => x.status === "Failed"))
            return "Failed";
        if (grouping.requests.some(x => x.status === "InProgress"))
            return "InProgress";
        if (grouping.requests.some(x => x.status === "New"))
            return "New";
        return "Complete";
    }

    const getDownloadFileButton = (requestId) => {
        return <Tooltip title={"Hent fil"}>
            <IconButton
                className="float-left smaller_icon"
                color="primary"
                onClick={() => downloadFileFromClippingOrder(requestId)}
            >
                <DownloadIcon />
            </IconButton>
        </Tooltip>
    }

    const getDownloadZipButton = (groupingId, fileCount) => {
        return <Tooltip title={`Hent ${fileCount} filer`}>
            <IconButton
                className="float-left smaller_icon"
                color="primary"
                onClick={() => handleDownloadZip(groupingId)}
            >
                <ArchiveIcon />
            </IconButton>
        </Tooltip>
    }

    const getCompleteDate = (grouping) => {
        let maxDate = grouping.requests[0].dateCompleted;

        for (const request of grouping.requests) {
            if (maxDate < request.dateCompleted)
                maxDate = request.dateCompleted;
        }

        return maxDate;
    }

    const getDataTable = () => {
        if (!groups) {
            return;
        }

        return groups.map(group => {
            const request = group.requests[0];
            const isGroup = group.requests.length > 1;
            const status = getGroupingStatus(group);
            const completeDate = status === "Complete" ? getCompleteDate(group) : null;
            const completedFiles = group.requests.filter(x => x.status == "Complete");
            return [
                request.id,
                request.jobId,
                isGroup ? `Fotos ${request.jobId}.zip` : request.fileName,
                isGroup ? "" : request.generatedFileName,
                request.dateRequested ? getShortDate(request.dateRequested) : "",
                completeDate ? getShortDate(completeDate) : "",
                photoClippingStatus[status],
                !isGroup && request.status === "Complete" ?
                    getDownloadFileButton(request.id) :
                    isGroup && completedFiles.length > 0 ?
                        getDownloadZipButton(group.groupingId, completedFiles.length)
                        : undefined
            ]
        });
    }

    return (
        <>
            <MUIDataTable
                data={getDataTable()}
                columns={columns}
                options={options}
                stickyHeader={true}
            />
        </>
    )
}
export default PhotoClippingOrdersTable