import React, { useEffect } from "react";
import { useState } from "react";
import { fetchFeedsRequest } from "../feeds/Logic/requests";

export const FeedsContext = React.createContext({
    feeds: [],
    showFeedBuilder: [],
    selectedFeed: 0,
    filterProperties: [],
    filterWasCreated: false,
    filterChild: [],
    filterMaxLength: 0,
    selectedFilter: {},
    filterExists: false,
    filterData: {},
    loading: false,
    selectedOptions: [],
    lastConnectorIndex: [],
    filterStatistics: {},
});

const Index = ({ children }) => {
    useEffect(() => {
        const runEffect = async () => {
            setLoading(true);
            const feedsArray = await fetchFeedsRequest();
            if (!feedsArray) {
                setFeeds([]);
                setLoading(false);
                return;
            }

            setFeeds(feedsArray);
            setLoading(false);
        };
        runEffect();
    }, []);
    const statisticsInitialState = {
        numFilteredOffers: 0,
        numOffersOnCollection: 0
    };
    const [feeds, setFeeds] = useState([]);
    const [showFeedBuilder, setShowFeedBuilder] = useState(false);
    const [selectedIndex, setSelectedIndex] = useState(0);
    const [filterProperties, setFilterProperties] = useState([]);
    const [filterChild, setFilterChild] = useState([]);
    const [selectedFilter, setSelectedFilter] = useState({});
    const [filterExists, setFilterExists] = useState(false);
    const [filterData, setFilterData] = useState({});
    const [loading, setLoading] = useState(false);
    const [filterMaxLength, setFilterMaxLength] = useState(0);
    const [filterWasCreated, setFilterWasCreated] = useState(false);
    const [selectedOptions, setSelectedOptions] = useState([]);
    const [lastConnectorIndex, setLastConnectorIndex] = useState([]);
    const [filterStatistics, setFilterStatistics] = useState(statisticsInitialState);

    return (
        <FeedsContext.Provider value={{
            feeds: [feeds, setFeeds],
            showFeedBuilder: [showFeedBuilder, setShowFeedBuilder],
            selectedFeed: [selectedIndex, setSelectedIndex],
            filterProperties: [filterProperties, setFilterProperties], // a properties avaliable to select to create a filter
            filterChild: [filterChild, setFilterChild], // a list of properties on a filter
            selectedFilter: [selectedFilter, setSelectedFilter], // filter opened in a feedBuilder
            filterExists: [filterExists, setFilterExists], // check to define if dialog will be used for create or edit filter
            filterData: [filterData, setFilterData], // if filter exists, populate fields with this data
            loading: [loading, setLoading],
            filterMaxLength: [filterMaxLength, setFilterMaxLength], // a filter can contain as many filter properties as length of properties array
            filterWasCreated: [filterWasCreated, setFilterWasCreated],
            selectedOptions: [selectedOptions, setSelectedOptions], // connectors between filter statements
            lastConnectorIndex: [lastConnectorIndex, setLastConnectorIndex], // set index of the last filter property on existing filter to be able to add new props  
            filterStatistics: [filterStatistics, setFilterStatistics],
        }}>
            {children}
        </FeedsContext.Provider>
    )
}

export default Index;