import React, { useState } from "react";
import {
    apiGetParamsReplace,
    endpoints,
} from "../../../../constants/services.constants";

import { openConfirmClipPhotoDialog } from "../dialogs/DialogConfirmClipPhoto";
import { downloadImage, transformPhoto } from "../Requests";
import PhotoClippingButton from "../../../shared/components/PhotoClippingButton";
import { showSuccessNotification } from "../../../../helpers/UtilHelper";
import { openDialogEditPhoto } from "../dialogs/DialogEditPhoto";
import { ActionButtonSimple } from "../../../shared/components/tableActionButton/ActionButtonSimple";
import { TooltipTexts } from "../../../../constants/enums";
import { ConvertIconComponent, DeletetIconComponent, DownloadIconComponent, EditIconComponent } from "../../../../constants/IconsExport";
import { DropdownMenu } from "../../../shared/components/tableActionButton/DropdownMenu";
import { handleClickPopper, handleClosePopper } from "./EventHandler";
import { IsFeatureEnabled, featureNames } from "../../../../helpers/FeaturesHelper";


export default function PhotoTableActionButtons({ photo, onEditImage,
    onPhotoChanged, canDelete, handleDelete,reloadSearch }) {

    let deleted = photo.status === "Deleted";

    const [anchorEl, setAnchorEl] = useState(null);

    //As defined in PhotoService.PhotoApplication.IsSupportedFileType
    let supportedFormats = { jpg: "jpg",  jpeg: "jpeg",  png: "png",  psd: "psd",  };
    let supportedFormat = photo.format in supportedFormats;
    let postFix = supportedFormat ? "" : " ("+photo.format+" ikke understøttet)";

    // Data for the dropdown menu items
    const dropdownMenuData = [
        {
            "func": downloadImage,
            "arguments": [apiGetParamsReplace(endpoints.PHOTO_SINGLE_200, [photo.id,]),
            photo.id + "-200"],
            "name": "200px" + postFix,
            disabled: !supportedFormat
        },
        {
            "func": downloadImage,
            "arguments": [apiGetParamsReplace(endpoints.PHOTO_SINGLE_800, [
                photo.id,
            ]),
            photo.id + "-800"],
            "name": "800px" + postFix,
            disabled: !supportedFormat
        },
        {
            "func": downloadImage,
            "arguments": [apiGetParamsReplace(endpoints.PHOTO_SINGLE_ORG, [
                photo.id,
            ]),
            photo.id],
            "name": "Fuld Størrelse"
        },
    ];

    const handleEditPhoto = async (photoId) => {
        const confirmed = await openDialogEditPhoto(photoId, onEditImage, reloadSearch);
        if (confirmed) {
            onPhotoChanged(photoId);
        }
    };

    const handleClipPhoto = async () => {
        const success = await openConfirmClipPhotoDialog(photo.motiveId);
        if (success) {
            // todo bjørn- we should update the status of the photo. 23/03 Added Alina, ask what should happen here
        }
    }

    const handleTransformImage = async (motiveId) => {
        showSuccessNotification(motiveId + " konverteres...");
        await transformPhoto(motiveId);
        showSuccessNotification(motiveId + " er blevet konvertet");
    }

    return (
        <>
            {/*EDIT BUTTON. do not show on deleted photos*/}
            {deleted ? undefined :
                <ActionButtonSimple
                    toolTipTitle={TooltipTexts.Edit}
                    onClick={() => handleEditPhoto(photo.id)}
                    icon={EditIconComponent}
                    name="PhotoEdit"
                />
            }
            {/*DOWNLOAD BUTTON */}  
            { deleted || !IsFeatureEnabled(featureNames.Photos_Download_Enabled) ? undefined :            
                <ActionButtonSimple
                    toolTipTitle={TooltipTexts.Download}
                    onClick={(e) => handleClickPopper(e, setAnchorEl)}
                    showMenu={anchorEl ? "simple-menu" : undefined}
                    icon={DownloadIconComponent}
                />
            }

            {/*DOWNLOAD MENU */}
            {deleted || !IsFeatureEnabled(featureNames.Photos_Download_Enabled) ? undefined :
                <DropdownMenu
                    anchorEl={anchorEl}
                    handleClosePopper={() => handleClosePopper(setAnchorEl)}
                    menuItemData={dropdownMenuData}
                />
            }

            {deleted || !IsFeatureEnabled(featureNames.Photos_Clipping_Enabled) ? undefined :
                <PhotoClippingButton
                photoStatus={photo.status}
                isClipped={photo.isClipped}
                onClick={() => handleClipPhoto()}
                />
            }
            
            
            {(deleted || !canDelete || !IsFeatureEnabled(featureNames.Photos_Convert_Enabled)) ? undefined :
                <ActionButtonSimple
                    toolTipTitle={TooltipTexts.Convert}
                    onClick={() => handleTransformImage(photo.motiveId)}
                    icon={ConvertIconComponent}
                />
            }

            {/* Will want to display this menu item if we have the appropriate permissions. */}
            {canDelete && photo.status !== "Deleted" && photo.validTo === null && IsFeatureEnabled(featureNames.Photos_Delete_Enabled) ?
            
                <ActionButtonSimple
                    toolTipTitle={TooltipTexts.Delete}
                    onClick={() => handleDelete(photo)}
                    icon={DeletetIconComponent}
                    name="PhotoDelete"
                />
                : undefined}
        </>
    )
}