import { endpoints } from '../../constants/services.constants';
import { fetchGetWT } from '../../helpers/HttpHelper';

export const FETCH_NOTIFICATIONS_BEGIN = 'FETCH_NOTIFICATIONS_BEGIN';
export const FETCH_NOTIFICATIONS_SUCCESS = 'FETCH_NOTIFICATIONS_SUCCESS';
export const FETCH_NOTIFICATIONS_FAILURE = 'FETCH_NOTIFICATIONS_FAILURE';

export const fetchNotificationsBegin = () => ({
  type: FETCH_NOTIFICATIONS_BEGIN
});

export const fetchNotificationsSuccess = notifications => ({
  type: FETCH_NOTIFICATIONS_SUCCESS,
  payload: { notifications }
});

export const fetchNotificationsFailure = error => ({
  type: FETCH_NOTIFICATIONS_FAILURE,
  payload: { error }
});


export function fetchNotifications() {

  return dispatch => {
    const endpoint=endpoints.NOTIFICATION_ALL; 
    dispatch(fetchNotificationsBegin());
    return fetchGetWT(endpoint,
      (json) => {
        let notifications = json.reverse();
        dispatch(fetchNotificationsSuccess(notifications.map(function (o) {
        return {
          "id": o.id,
          "header": o.header,
          "message": o.message,
          "refUrl": o.refUrl,
          "isRead": o.isRead,
          "created": o.created
        };
      })));
    },
    (error)=>dispatch(fetchNotificationsFailure(error))
    )
   
  };
}

