import React, { useEffect, useState } from 'react';
import { Dialog, DialogTitle, DialogContent, DialogActions, Button, LinearProgress, Typography } from '@material-ui/core';
import { apiGetParamsReplace, endpoints } from '../../../../constants/services.constants';
import { fetchGetWT, fetchPostWT } from '../../../../helpers/HttpHelper';
import { getMotivId } from '../../../../helpers/UtilHelper';


export default function DialogPreReplacePhoto({
  open,
  handleClose,
  motiveId,
  renderActionButtons,
  confirmReplacePhoto,
  messageReplacePhoto,
  messageConfirmReplacePhoto
}) {
  const [data, setData] = useState(null);
  const [dataLoading, setDataLoading] = useState(false);
  const [dataError, setDataError] = useState(null);

  useEffect(() => {
    const photoId = typeof motiveId === "string" ? motiveId.replace("-", "") : motiveId;
    const endpoint = apiGetParamsReplace(endpoints.OFFER_RELATED_TO_PHOTO, photoId);
    setDataLoading(true);
    fetchGetWT(
      endpoint,
      (offers) => {
        if (offers.length === 0) {
          setDataLoading(false);
          setData([]);
        } else {
          const offerIds = offers.map(x => x.id);
          fetchPostWT(
            endpoints.PUBLICATION_INCLUDES_OFFERS,
            JSON.stringify(offerIds),
            (publications) => {
              setDataLoading(false);
              setData(publications);
            },
            error => {
              setDataLoading(false);
              setDataError(error)
            });
        }
      },
      error => {
        setDataLoading(false);
        setDataError(error)
      }
    );
    return () => {
    };
  }, [])

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle
        id="alert-dialog-title"
        style={{ whiteSpace: 'pre-line' }}
      >
        {messageReplacePhoto &&
          <Typography variant="h6" align="center">
            {messageReplacePhoto}
          </Typography>
        }
        <Typography variant="h6" align="center">
          Motivet {getMotivId(motiveId)} har været brugt i
        </Typography>
      </DialogTitle>
      <DialogContent
        style={{ maxHeight: 700, minHeight: 600 }}
      >
        {dataLoading ? <LinearProgress />
          : dataError != null ? <Typography>Der opstod en fejl : { dataError }</Typography> 
              : data == null ? <Typography>Not valid response from service</Typography>
                : data.length === 0 ? <Typography>Ingen publikationer fundet</Typography>
                  : <table><tbody>
                    {data.map(d => {
                      return (<tr key={d.jobId}>
                        <td>{d.jobId}</td>
                        <td>-</td>
                        <td>{d.name}</td>
                      </tr>)
                    })}
                  </tbody></table>            
        }
      </DialogContent>
      {messageConfirmReplacePhoto &&
        <Typography variant="h6" align="center">
          {messageConfirmReplacePhoto}
        </Typography>
      }
      <DialogActions>
        <Button onClick={handleClose} color="secondary" autoFocus>
          Annuller
          </Button>
        {renderActionButtons ?
          <Button onClick={confirmReplacePhoto} color="primary" autoFocus>
            Fortsæt
          </Button> : undefined}
      </DialogActions>
    </Dialog>
  )
}
