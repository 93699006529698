import React, { useState } from 'react';
import PasswordSent from "./PasswordSent";
import { SendForgotPasswordCode } from "./SendForgotPasswordCode";

export default function ResetPassWord(props) {
    const { setForgotPassword } = props;
    const [forgetPasswordSent, setForgetPasswordSent] = useState(false);
    return (
        <>
            {!forgetPasswordSent ?
                <SendForgotPasswordCode
                    classes={props.classes}
                    setForgetPasswordSent={() => setForgetPasswordSent(true)}
                    setForgotPassword={(value) => setForgotPassword(value)}
                /> :
                <PasswordSent
                    classes={props.classes}
                    setForgotPassword={(value) => setForgotPassword(value)}
                />

            }
        </>
    )
}