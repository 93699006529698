import {
  endpoints,
  apiGetParamsReplace
} from "../../../constants/services.constants";
import { fetchPostWT } from "../../../helpers/HttpHelper";

import { handleApiSuccessNotificaiton } from "../../Handlers/SuccessHandlers";

// OPEN EDIT FORM
export const RESETPASSWORD_OPEN_BEGIN = "USER_RESETPASSWORD_OPEN_BEGIN";
export const RESETPASSWORD_CLOSE_BEGIN = "USER_RESETPASSWORD_CLOSE_BEGIN";

export const resetpasswordDialogOpenBegin = id => ({
  type: RESETPASSWORD_OPEN_BEGIN,
  payload: { id }
});

export const resetpasswordDialogCloseBegin = () => ({
  type: RESETPASSWORD_CLOSE_BEGIN
});

export function resetpasswordDialogOpen(id) {
  return dispatch => {
    dispatch(resetpasswordDialogOpenBegin(id));
  };
}
export function resetpasswordDialogClose() {
  return dispatch => {
    dispatch(resetpasswordDialogCloseBegin());
  };
}

// RESET PASSWORD
export const RESETPASSWORD_BEGIN = "USER_RESETPASSWORD_BEGIN";
export const RESETPASSWORD_SUCCESS = "USER_RESETPASSWORD_SUCCESS";
export const RESETPASSWORD_FAILURE = "USER_RESETPASSWORD_FAILURE";

export const resetpasswordBegin = () => ({
  type: RESETPASSWORD_BEGIN
});

export const resetpasswordSuccess = () => ({
  type: RESETPASSWORD_SUCCESS
});

export const resetpasswordFailure = error => ({
  type: RESETPASSWORD_FAILURE,
  payload: { error }
});

export function resetpassword(userId, username) {
  const endpoint = apiGetParamsReplace(
    endpoints.USER_RESETPASSWORD,
    ...[userId]
  );
  return dispatch => {
    dispatch(resetpasswordBegin());
    return fetchPostWT(
      endpoint,
      null,
      json => {
        dispatch(resetpasswordSuccess(json));
        handleApiSuccessNotificaiton(
          "Der er sendt en e-mail med anmoding om kodeord til brugeren " +
            username
        );
      },
      error => dispatch(resetpasswordFailure(error))
    );
  };
}
