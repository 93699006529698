import { configurations as endpointConfigs } from "../../constants/services.constants";

export const getTokenFailMessageDefault = "Der skete en fejl ved log ind. Fejl: ";
export const tokenExpiredMessage = "Du er blevet logget ud pga. inaktivitet. Log venligst på igen."

export const tokenDeatails = (userName, passWord) => {
    const details = {
        username: userName,
        password: passWord,
        grant_type: endpointConfigs.IDENTITY_GRANT_TYPE,
        client_id: endpointConfigs.IDENTITY_CLIENT_ID,
        client_secret: endpointConfigs.IDENTITY_CLIENT_SECRET,
        scope: endpointConfigs.IDENTITY_SCOPE
    }
    return details;
};

export const loginErrorTranslations = (error) => {
    let errorMessage = "";

    switch (error) {
        case "invalid_grant":
            errorMessage = "Forkert brugernavn eller kodeord.";
            break;
        default:
            errorMessage = "Der er en fejl under login: " + error;
    };

    return errorMessage;
}