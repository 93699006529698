import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from "@material-ui/core";
import { registerPlugin } from "react-filepond";
import React, { useEffect, useState } from "react";
import { photoTypeEnum } from "../../../../../constants/enums";
import PhotoInformationComponent from "./PhotoInformationComponent";

import "filepond/dist/filepond.min.css";
import FilePondPluginImagePreview from "filepond-plugin-image-preview";
import "filepond-plugin-image-preview/dist/filepond-plugin-image-preview.min.css";
import FilePondPluginFileValidateType from "filepond-plugin-file-validate-type";
import UploadComponent from "./UploadComponent";
import { handleAddEans, handleAddTag, handleChangeState, handleDeleteEans, handleDeleteTag } from "./logic/EventHandler";
import { handleSave, handleSetFile, validateRequiredFields } from "./logic/BusinessLogic";
import { LoadingLogo } from "../../../../shared/Loading";
registerPlugin(FilePondPluginImagePreview, FilePondPluginFileValidateType);

export default function DialogUploadPhoto(props) {
    const { handleClose } = props;
    const [loading, setloading] = useState(false);
    const [files, setFiles] = useState([]);
    const [photoInformationStep, setPhotoInformationStep] = useState(false);
    const [productNumbers, setProductNumbers] = useState([]);
    const [photoType, setPhotoType] = useState(photoTypeEnum.Environment.id);
    const [imageBase64, setImageBase64] = useState("");
    const [photoId, setPhotoId] = useState(undefined);

    const [state, setState] = useState({
        name: "",
        description: "",
        customerId: undefined,
        tags: [],
        exclusiveForCustomerIds: []
    });

    const [validationErrors, setValidationErrors] = useState({
        customerIdError: "",
        tagsErrror: ""
    });

    const onSave = async () => {
        setloading(true);
        await handleSave(
            validationErrors, setValidationErrors, state, files, photoType, productNumbers, setPhotoId, handleClose
        );
        setloading(false);
    }

    return (
        <>
            <Dialog
                open={true}
                onClose={() => handleClose()}
                aria-labelledby="form-dialog-title"
                fullWidth
                maxWidth="md"
            >
                <DialogTitle id="form-dialog-title">
                    Upload billede
                </DialogTitle>
                <DialogContent>
                    {loading ? <LoadingLogo /> : !photoInformationStep ?
                        (<UploadComponent
                            photoTypeSelected={photoType}
                            setPhotoTypeSelected={(photoType) => setPhotoType(photoType)}
                            files={files}
                            handleSetFile={(fileItems) => handleSetFile(fileItems, setFiles, setImageBase64)}

                        />) : (
                            <PhotoInformationComponent
                                photo={state}
                                onChange={(e) => handleChangeState(state, setState, e)}
                                photoTypeSelected={photoType}
                                setPhotoTypeSelected={(photoType) => setPhotoType(photoType)}
                                handleAddTag={(newTag) => handleAddTag(state, setState, newTag)}
                                handleDeleteTag={(tagToDelete) => handleDeleteTag(state, setState, tagToDelete)}
                                imageBase64={imageBase64.File}
                                photoId={photoId}
                                productNumbers={productNumbers}
                                handleAddEans={(newEan) => handleAddEans(productNumbers, setProductNumbers, newEan)}
                                handleDeleteEans={(eanToDelete) => handleDeleteEans(productNumbers, setProductNumbers, eanToDelete)}
                                validationErrors={validationErrors}
                            />
                        )
                    }
                </DialogContent>
                <DialogActions>
                    <Button
                        onClick={() => handleClose()}
                    >
                        Annuler
                    </Button>
                    <Button
                        onClick={
                            () => {
                                !photoInformationStep ?
                                    setPhotoInformationStep(true) :
                                    onSave()
                            }
                        }
                        disabled={files.length === 0}
                        name="buttonPhotosDialogNextTapOrAddPhoto"
                    >
                        {photoInformationStep ? "Gem" : "Næste"}
                    </Button>
                </DialogActions>
            </Dialog>
        </>

    )
}