import {
  endpoints,
  apiGetParamsReplace
} from "../../../constants/services.constants";
import { fetchGetWT, fetchPatchWT } from "../../../helpers/HttpHelper";

//----------Set order status type 70 Genbrug ----------------------------- 

export const SET_ORDERSTATUSTYPE_70_BEGIN = "PHOTO_SET_ORDERSTATUSTYPE_70_BEGIN";
export const SET_ORDERSTATUSTYPE_70_SUCCESS = "PHOTO_SET_ORDERSTATUSTYPE_70_SUCCESS";
export const SET_ORDERSTATUSTYPE_70_FAILURE = "PHOTO_SET_ORDERSTATUSTYPE_70_FAILURE";

export const setOrderStatusType70Begin = orderId => ({
  type: SET_ORDERSTATUSTYPE_70_BEGIN,
  payload: { orderId: orderId }
});

export const setOrderStatusType70Success = (orderId, statusId, photoId) => ({
  type: SET_ORDERSTATUSTYPE_70_SUCCESS,
  payload: { orderId, statusId, photoId }
});

export const setOrderStatusType70Failure = error => ({
  type: SET_ORDERSTATUSTYPE_70_FAILURE,
  payload: { error }
});

export const setOrderStatusReusedPhoto = (orderId, photoId) => {
  const statusId = 70;
  return dispatch => {
    const enpoint = apiGetParamsReplace(
      endpoints.PHOTOTRACKER_PHOTO_REUSE, orderId, photoId);
    dispatch(setOrderStatusType70Begin(orderId));
    return fetchPatchWT(
      enpoint,
      null,
      json => {
        dispatch(setOrderStatusType70Success(orderId, statusId, photoId));
      },
      error => {
        dispatch(setOrderStatusType70Failure(error));
      },
      true
    );
  };
};