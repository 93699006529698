import React, { useContext, useState, useRef } from 'react';
import { withStyles, Dialog, DialogContent, DialogActions, Button, Typography, TextField, Tooltip, IconButton } from '@material-ui/core';
import clsx from 'clsx';
import { MediaContext } from '../../contexts/MediaContext';
import FileCopyIcon from '@material-ui/icons/FileCopy';

const styles = theme => ({
    input: {
        marginBottom: 35
    },
    actions: {
        display: "grid",
        gridTemplateColumns: "1fr 1fr 1fr 1fr 1fr",
        gridGap: "1rem",
        marginBottom: 30
    },
    actionButton: {
        width: 110,
        justifySelf: "end",
    },
    positionGroup: {
        gridColumn: 5
    },
    error: {
        color: "#f44336"
    },
    contentHolder: {
        height: "100%",
        width: "100%",
        margin: "0 auto",
        display: "grid",
        gridTemplateColumns: "auto 30px",
        puddingButtom: 25
    },
    textArea: {
        width: "100%",
        overflow: "auto",
        resize: "none",
        outline: "none",
        border: "1px solid #cccccc",
        padding: "12px 5px 0px 5px"
    },
    notification: {
        textAlign: "center",
        color: "#8E8E8E"
    },
    copyIcon: {
        paddingTop: 8
    }
});

const ColorButton = withStyles(theme => ({
    root: {
        marginRight: 10,
        backgroundColor: "#263238",
        color: "#ffffff",
        '&:hover': {
            backgroundColor: "#11171A",
            color: "#ffffff"
        },
    },
}))(Button);

function DialogCopyLink(props) {
    const { open, classes } = props;
    const {
        selectedMedia: selectedMediaState,
        openCopyLinkDialog: openCopyLinkDialogState,
    } = useContext(MediaContext);
    const [, setSelectedMedia] = selectedMediaState;
    const [, setOpenCopyLinkDialog] = openCopyLinkDialogState;
    const [copySuccess, setCopySuccess] = useState("");
    const textAreaRef = useRef(undefined);

    const handleClose = () => {
        setSelectedMedia({});
        setOpenCopyLinkDialog(false);
    }

    const copyLink = (e) => {
        textAreaRef.current.select();
        document.execCommand("copy");
        e.target.focus();
        setCopySuccess("Copied!");
    }

    return (
        <>
            <Dialog
                open={open}
                onClose={handleClose}
            >
                <DialogContent>
                    <Typography variant="h6" style={{ padding: "5% 0px", textAlign: "center" }}>Copy media link</Typography>
                    <div className={classes.contentHolder}>
                        <form>
                            <textarea
                                className={classes.textArea}
                                ref={textAreaRef}
                                value="https://material.io/resources/icons/?search=copy&icon="
                            />
                        </form>
                        {
                            document.queryCommandSupported('copy') &&
                            <div className={classes.copyIcon}>
                                <Tooltip title={"Copy link"}>
                                    <IconButton
                                        className="float-left smaller_icon"
                                        color="primary"
                                        onClick={copyLink}
                                    >
                                        <FileCopyIcon />
                                    </IconButton>
                                </Tooltip>
                            </div>
                        }
                        <div className={classes.notification}>{copySuccess}</div>
                    </div>
                </DialogContent>
                <DialogActions>
                    <div className={classes.actions}>
                        <ColorButton className={clsx(classes.actionButton, classes.positionGroup)} onClick={() => handleClose()} color="primary">
                            Annuller
                  </ColorButton>
                    </div>
                </DialogActions>
            </Dialog>
        </>
    )

}

export default withStyles(styles)(DialogCopyLink)