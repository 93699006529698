import { endpoints, apiGetParamsReplace } from "../../../../../constants/services.constants";
import { getToken } from "../../../../../helpers/AuthHelper";
import store from "../../../../../redux/store";
import { addToastMessage } from "../../../../../redux/actions/toasts.actions";
import { handleAPIErrorNotification, handleAPIErrors } from "../../../../../redux/Handlers/ErrorHandlers";
import { fetchGetWT, fetchPostWT, fetchPutWT } from "../../../../../helpers/HttpHelper";
import Resources from "../../../../../Resources.json";
export const fetchTemplatesRequest = async () => {
    const endpoint = endpoints.TEMPLATES_GET_ALL;
    return fetchGetWT(
        endpoint,
        json => {
            return json;
        }
        ,
        callError => {
            return [];
        });
}

export const fetchMediaTransportsRequest = async () => {
    const endpoint = endpoints.MEDIA_TRANSPORTS_GET_ALL;

    let result = [];
    await fetchGetWT(
        endpoint,
        json => {
            result = JSON.parse(json);
        }
        ,
        callError => {
            result = [];
        });
    return result;
}

export const fetchMediaTypesRequest = async () => {
    const endpoint = endpoints.MEDIATYPES_GET_ALL;

    let result = [];
    await fetchGetWT(
        endpoint,
        json => {
            result = JSON.parse(json);
        }
        ,
        callError => {
            result = [];
        });
    return result;
}

export const createNewMediaDefinitionRequest = async (mediaObject) => {
    if (!mediaObject) {
        return;
    }

    const headersJsonToken = {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: "Bearer " + getToken(),
        "x-organization-id": Resources.Organization
    };
    const endpoint = endpoints.CREATE_MEDIADEFINITION;

    return fetch(endpoint, {
        method: "POST",
        headers: headersJsonToken,
        body: JSON.stringify(mediaObject)
    })
        .then(res => {
            if (!res.ok) {
                throw Error(res.statusText);
            }
            if (res.ok && res.status === 200) {
                store.dispatch(
                    addToastMessage({
                        message: `"${mediaObject.mediaName.name}" blev skabt`,
                        type: "success",
                        details: null
                    })
                );
            }
        })
        .catch(error => {
            handleAPIErrorNotification(endpoint, error);
        });
}

export const fetchMediaDefinitions = async () => {
    const endpoint = endpoints.MEDIADEFINITIONS_GET_ALL;
    let result = [];
    await fetchGetWT(
        endpoint,
        json => {
            result = json;
        }
        ,
        callError => {
            result = [];
        });
    return result;
}

export const deleteMediaRequest = async (id) => {
    if (!id) {
        return;
    }

    id = `mediaDefinitionId=${id}`;
    return new Promise((resolve, reject) => {

        const endpoint = apiGetParamsReplace(endpoints.MEDIADEFINITIONS_DELETE_SINGLE, id);
        const headers = {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: "Bearer " + getToken(),
            "x-organization-id": Resources.Organization
        };
        fetch(endpoint, {
            method: "DELETE",
            headers: headers
        })
            .then(handleAPIErrors)
            .then(res => {
                if (res.ok && res.status === 200) {
                    resolve();
                    store.dispatch(
                        addToastMessage({
                            message: "Det valgte medie blev slettet",
                            type: "success",
                            details: null
                        })
                    );
                }
            })
            .catch(error => {
                reject()
                handleAPIErrorNotification(endpoint, error);
            })
    });
}

export const downloadLatestMediaRequest = async (mediaDedinitionId, mediaName) => {
    const endpoint = apiGetParamsReplace(endpoints.MEDIADEFINITIONS_GET_LATEST_MEDIA, mediaDedinitionId);

    const response = await fetch(endpoint, {
        method: "GET",
        headers: { "Authorization": "Bearer " + getToken() }
    });

    const blob = await response.blob();

    let url = window.URL.createObjectURL(blob);
    let a = document.createElement("a");
    a.href = url;
    a.download = `${mediaName}.csv`;
    a.click();
}

export const produceMediaRequest = async (body) => {
    const endpoint = endpoints.PRODUCE_MEDIA;

    const response = await fetch(endpoint, {
        method: "POST",
        body: JSON.stringify(body),
        headers: {
            "Authorization": "Bearer " + getToken(),
            "Content-Type": "application/json"
        }
    });

    if (response.ok && response.status === 202) {
        store.dispatch(
            addToastMessage({
                message: "Medie er ved at blive skabt",
                type: "success",
                details: null
            })
        );
    } else {
        store.dispatch(
            addToastMessage({
                message: "Medie kan ikke skabes",
                type: "error",
                details: null
            })
        );
    }
}
