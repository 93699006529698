import { Typography } from '@material-ui/core';
import React from 'react';
export function LogInHeader({ text }) {
    return (
        <>
            {text.map(x =>
                <Typography
                    component="h1"
                    variant="h5"
                    style={{
                        textAlign: "center"
                    }}>
                    {x}
                </Typography>)}
        </>
    )
}