import {
    HubConnectionBuilder,
    HubConnectionState,
} from '@microsoft/signalr';
import { endpoints } from '../constants/services.constants';
import { fetchNotifications } from '../redux/actions/notifications.actions';
import store from '../redux/store';
import { getToken } from './AuthHelper';
import Resources from "../Resources.json";
const serverMethods = {
    AddUserToGroup: "AddUserToGroup"
}

const serverChannels = {
    Message: "Message",
    Notification: "Notification"
}

const multibaseNotifications = ["PhotoOrderUploadPhoto"];
const multibaseNotificationsSet = new Set(multibaseNotifications);

export const notificationChannels = {
    UserAddedToGroup: "UserAddedToGroup",
    FailedToAddUserToGroup: "FailedToAddUserToGroup"
}

multibaseNotifications.forEach(x => notificationChannels[x] = x);
export class NotificationManager {
    subscriptions = new Map();

    subscribeToChannel = (channel, callback) => {
        if (this.subscriptions.has(channel)) {
            this.subscriptions.get(channel).push(callback);
        } else {
            this.subscriptions.set(channel, [callback]);
        }
    }

    unsubscribeToChannel = (channel, callback) => {
        if (!this.subscriptions.has(channel)) {
            return;
        }

        this.subscriptions.set(channel, this.subscriptions.get(channel).filter(x => x !== callback));
    }

    _notificationReceived = (_, message) => {
        // temporary solution to fetch notification

        if (getToken()) {
            store.dispatch(fetchNotifications());
        }

        const messageChannel = message.channel;

        if (!this.subscriptions.has(messageChannel)) {

            if (message.channel === undefined && message === "Connected") {
                const _callbacks = this._validateCallbacks("Connected");
                _callbacks.forEach(callback => callback(undefined));
            }

            return;
        }

        const callbacks = this._validateCallbacks(messageChannel);

        if (multibaseNotificationsSet.has(messageChannel)) {
            let notifications = store.getState().notificationsReducer.notifications;
            notifications = [...notifications, message];

            callbacks.forEach(callback => callback(notifications));
        } else {
            callbacks.forEach(callback => callback(message));
        }
    }

    _validateCallbacks(channel) {
        let callbacks = this.subscriptions.get(channel);

        if (!Array.isArray(callbacks) || callbacks.length === 0) {
            return [];
        }

        const invalidCallbacks = callbacks.filter(x => typeof x !== "function");

        if (invalidCallbacks.length !== 0) {
            // Remove any invalid callbacks.
            callbacks = callbacks.filter(x => typeof x === "function");
            this.subscriptions.set(channel, callbacks);
        }

        return callbacks;
    }
}

export class UserManagerSignalr {
    _hasSubscribed = false;
    _waiting = false;
    _notificationManager = undefined;
    _userId = undefined;
    connection = undefined;

    constructor(_connection, notificationManager) {
        this.connection = _connection;
        this._notificationManager = notificationManager;
        this.subsribeToUserGroupsChannels();
    }

    subsribeToUserGroupsChannels() {
        this._notificationManager.subscribeToChannel(notificationChannels.UserAddedToGroup, _ => {
            this._hasSubscribed = true;
            this._waiting = false;
        });

        this._notificationManager.subscribeToChannel(notificationChannels.FailedToAddUserToGroup, _ => {
            this._hasSubscribed = false;
            this._waiting = false;
        });

        this._notificationManager.subscribeToChannel("Connected", _ => {
            if (this._hasSubscribed === true) {
                this._hasSubscribed = false;
                this._waiting = false;
                if (this._userId) {
                    this.subsribeUserToGroup(this._userId);
                }
            }
        });
    }

    subsribeUserToGroup = async (userId) => {
        if (this._hasSubscribed || this._waiting) {
            return;
        }

        this._waiting = true;
        this._userId = userId;

        await this._subsribeUserToGroup(userId);
    }

    _subsribeUserToGroup = async (userId) => {
        try {
            await this.connection.invoke(serverMethods.AddUserToGroup, userId, JSON.stringify({ UserId: userId }));
        } catch (e) {
            console.error(e);
        }
    }

}

const orgHeader = {
    'x-organization-id' : Resources.Organization
};

export const subscribeToNotifications = async () => {

    const connection = new HubConnectionBuilder()
        .withUrl(`${endpoints.NOTIFICATIONS_SIGNALR}`,
        {
            headers:orgHeader
        })
        .withAutomaticReconnect()
        .build();

    const notificationManager = new NotificationManager();
    const messageManager = new NotificationManager();
    const userManager = new UserManagerSignalr(connection, notificationManager);

    connection.on(serverChannels.Message, (message) => {
        messageManager._notificationReceived(message.TypeName, message);
    });

    connection.on(serverChannels.Notification, (notification) => {
        notificationManager._notificationReceived(notification.channelName, notification);
    });

    try {
        await connection.start();
    } catch (err) {
        console.error("signalr:", err);
    }

    if (connection.state === HubConnectionState.Connected) {
        console.log("connected to signalr");
    }

    return [notificationManager, messageManager, userManager];
};