import React, { useState, useContext } from 'react';
import { Typography, Tooltip, Fab, withStyles, Button } from '@material-ui/core';
import { FilePond } from 'react-filepond';
import { generateReceipts, downloadTemplate } from './Requests';
import { Helmet } from 'react-helmet';


const styles = {
    searchBar: {
        width: "100%",
        alignItems: "center",
        display: "grid",
        gridTemplateColumns: "auto 60px",
        marginBottom: 20
    },
    iconButton: {
        marginLeft: 10,
        marginTop: 5
    },
    button: {
        textAlign: "center"
    },
    downloadButton: {
        textAlign: "end",
        marginBottom: 20
    }
}

function PriceCheckUpload(props) {
    const { classes } = props;
    const [files, setFiles] = useState([]);
    const [upload, setUpload] = useState(false);

    const handleSetFile = (file) => {
        let photoFile = file.map(item => item.file);
        setFiles(photoFile);
    }

    const uploadFile = async () => {
        setUpload(true);

        const formData = new FormData();

        formData.append("file", files[0]);

        await generateReceipts(formData);

        setUpload(false);
    }

    return (
        <>
            <Helmet>
                <title>Pristjek</title>
            </Helmet>
            <Typography variant="h4">Pristjek</Typography>
            <div className={classes.downloadButton}>
                <Button onClick={() => downloadTemplate()} color="primary" variant="outlined" style={{ backgroundColor: "#263238", color: "#ffffff" }}>
                    Hent skabelon
            </Button>
            </div>
            <div className="filePond">
                <FilePond
                    style={{ height: 300 }}
                    allowImagePreview={false}
                    files={files}
                    onupdatefiles={(file) => handleSetFile(file)}
                    allowMultiple={false}
                    imagePreviewHeight={600}
                    acceptedFileTypes={[]}
                    labelIdle={
                        'Træk og slip din Excel-fil eller <span class="filepond--label-action">Gennemse</span>'
                    }
                />
                {files.length === 0 ? undefined :
                    upload ?
                        (<div className={classes.button}>
                            <Button disabled color="primary">
                                Genererer billeder
                            </Button>
                        </div>)
                        : !upload ?
                            (<div className={classes.button}>
                                <Button onClick={uploadFile} color="primary" variant="outlined">
                                    Generér
                        </Button>
                            </div>)
                            : <>
                                (<div className={classes.button}>
                                    <Button onClick={uploadFile} color="primary" variant="outlined">
                                        Generér
                            </Button>
                                </div>
                                <Typography>Error uploading: {JSON.stringify(upload)}</Typography>
                            </>
                }
            </div>

        </>
    )
}

export default withStyles(styles)(PriceCheckUpload)