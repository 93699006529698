import { FormControl } from "@material-ui/core";
import ChipInput from "material-ui-chip-input";
import React, { useEffect, useState } from "react";
import { labelTexts, photoTypeEnum } from "../../../../../constants/enums";
import store from "../../../../../redux/store";
import AutoSuggestionWithChipApi from "../../AutoSuggestionWithChipApi ";
import { TextFieldComponent, SelectComponent } from "./EditPhotoFields";

export default function PhotoInformationComponent(props) {
    const utilReducer = store.getState().utilsReducer;

    const {
        handleAddTag, handleDeleteTag,
        handleAddEans, photoId, handleDeleteEans, productNumbers, photo, onChange, photoTypeSelected,
        setPhotoTypeSelected, imageBase64, validationErrors
    } = props;

    const tagsError = validationErrors.tagsErrror === "" ?
        "Tryk enter for hvert tag" : <div style={{ color: "#f44336" }}>{validationErrors.tagsErrror}</div>

    return (
        <div>
            <div className="imageBlock">
                <div
                    style={{
                        height: 135,
                        backgroundImage: `url(${imageBase64})`,
                        width: 200,
                        backgroundSize: "contain",
                        backgroundRepeat: "no-repeat",
                        backgroundPosition: "center",
                        position: "relative",
                        marginTop: 16
                    }}
                ></div>
                <div>
                    <div className="dialogFormRow">
                        <TextFieldComponent
                            name={labelTexts.Name}
                            setValue={(e) => onChange(e)}
                            value={photo.name}
                        />
                        <SelectComponent
                            value={photoTypeSelected}
                            handleSelection={(e) => setPhotoTypeSelected(e.target.value)}
                            name={labelTexts.PhotoType}
                            dataArray={Object.values(photoTypeEnum)}
                        />
                    </div>
                    <div className="dialogFormRow">
                        <SelectComponent
                            value={photo.customerId}
                            handleSelection={(e) => onChange(e)}
                            name={labelTexts.Customer}
                            dataArray={utilReducer.customers}
                            validationError={validationErrors.customerIdError}
                        />
                        <FormControl>
                            <ChipInput
                                className="chipInput"
                                label={labelTexts.Tags}
                                value={photo.tags}
                                onAdd={handleAddTag}
                                onDelete={handleDeleteTag}
                                helperText={tagsError}
                                fullWidth
                                validationError={validationErrors.tagsErrror}
                                id="textfieldPhotoEditTags"
                            />
                        </FormControl>
                    </div>
                </div>
            </div>

            <TextFieldComponent
                name={labelTexts.Descriptioin}
                id="textfieldPhotoEditDescription"
                setValue={(e) => onChange(e)}
                value={photo.description} />
            <AutoSuggestionWithChipApi
                MotiveId={photoId}
                handleAddEans={handleAddEans}
                handleDeleteEans={handleDeleteEans}
                gtiNs={productNumbers}
            />
        </div>
    )
}