import React, { useEffect, useState } from 'react'
import { Dialog, LinearProgress, Typography, Slide, AppBar, Toolbar } from '@material-ui/core'
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import CanvasCorrectorApp from './canvasCorrectorApp/CanvasCorrectorApp';
import { DialogContent } from '@material-ui/core';
import { Box } from '@material-ui/core';


const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

export default function CorrectorDialog(props) {
    const { onClose, selectedGeneration, open, ...other } = props;


    function handleClose() {
        onClose(selectedGeneration);
    }

    return (
        <Dialog onClose={handleClose} open={open}
            fullScreen TransitionComponent={Transition}
        >
            <AppBar style={{
                position: 'relative',
            }}>
                <Toolbar>
                    <IconButton edge="start" color="inherit" onClick={handleClose} aria-label="close">
                        <CloseIcon />
                    </IconButton>
                    <Typography variant="h6" style={
                        {
                            marginLeft: 20,
                            flex: 1,
                            color: "white"
                        }
                    }>
                        Ret Billed Kombination
                    </Typography>
                </Toolbar>
            </AppBar>
            <DialogContent >
                <Box display="flex" 
                    alignItems="center"
                    justifyContent="center"
                    marginTop={5}
                    >
                    <CanvasCorrectorApp combinationId={props.selectedGeneration.id} setCorrectionResult={props.setCorrectionResult} />
                </Box>
            </DialogContent>
        </Dialog>
    );
}
