import React, { useEffect, useState } from "react";

import { OfferProductInformationTab } from "./OfferProductInformationTab";

import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import Typography from "@material-ui/core/Typography";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import { LoadingLogo } from "../../../../shared/Loading";

import { withStyles } from "@material-ui/core/styles";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";

// actions
import {
  apiGetParamsReplace,
  endpoints
} from "../../../../../constants/services.constants";

import { getOfferProductOld, updateOfferProduct } from "../../Requests";
import { getMotivId, showSuccessNotification } from "../../../../../helpers/UtilHelper";
import createDialog from "../../../../../DialogRenderer";

export async function openDialogEditOfferProduct(offerProductId) {
  return createDialog(({ show, onSubmit, onDismiss }) => (
    <DialogEditOfferProductStyled offerProductId={offerProductId}
      open={show}
      onCancel={onDismiss}
      onConfirm={onSubmit}
    />
  ));
}



const styles = theme => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper
  },
  tabsRoot: {
    borderBottom: "1px solid #e8e8e8"
  },
  tabsIndicator: {
    backgroundColor: "#1890ff"
  },
  dialog: {
    minHeight: "760px",
    maxHeight: "760px"
  },
  typography: {
    padding: theme.spacing(3)
  }
});

function DialogEditOfferProduct({ offerProductId, open, onConfirm, onCancel, classes }) {

  const [loading, setLoading] = useState(true);
  const [offerProduct, setOfferProduct] = useState(null);
  const [tabIndex, setTabIndex] = useState(0);

  useEffect(() => {
    loadData();
  }, []);

  const loadData = async () => {
    try {
      setLoading(true);
      const dto = await getOfferProductOld(offerProductId);
      setOfferProduct(dto);

    } finally {
      setLoading(false);
    }
  }

  const handleFieldValuesChange = event => {
    const clone = Object.assign({}, offerProduct);
    const { value, id } = event.target;
    const itemClone = Object.assign({}, clone.productFieldValueItems[id]);
    itemClone.fieldValue = value;
    clone.productFieldValueItems[id] = itemClone;
    setOfferProduct(clone);
  };

  const handleChangeState = event => {
    const clone = Object.assign({}, offerProduct);
    switch (event.target.name) {
      case "name":
        clone.name = event.target.value;
        break;
      // case "description":
      //   clone.name = event.target.value;
      //   break;
    }
    setOfferProduct(clone);
  };

  const handleSave = async () => {
    let data = {
      name: offerProduct.name,
      description: offerProduct.description,
      productNumber: offerProduct.productNumber,
      productFieldValueItems: offerProduct.productFieldValueItems
    };

    try {
      await updateOfferProduct(offerProduct.id, data);
      showSuccessNotification("Tilbudsproduktet er blevet opdateret");
      onConfirm();
    } catch (e) { }
  };




  return (
    <div>
      <Dialog
        open={open}
        onClose={onCancel}
        fullWidth={true}
        maxWidth="md"
        disableBackdropClick={true}
      // className={"republica-dialog"}
      >
        <DialogTitle>
          Ret tilbudsprodukt
            <br />
          {loading ? (
            "Henter..."
          ) : (
            <>
              <img
                src={apiGetParamsReplace(endpoints.PHOTO_SINGLE_200, offerProduct.selectedMultiPhotoId)}
                className="productImage"
                alt="Product"
              />
              <div className="dialogHeadline">
                <Typography variant="h6" gutterBottom>
                  {offerProduct.name}
                </Typography>
                <Typography variant="subtitle1">
                  Produktnummer: {offerProduct.productNumber}
                </Typography>
                <Typography variant="subtitle1">
                  Aktivt billede: {getMotivId(offerProduct.selectedMultiPhotoId)}
                </Typography>
              </div>
            </>
          )}
        </DialogTitle>
        <DialogContent className="customDialogContent">

          {loading ? (
            <LoadingLogo />
          ) : (
            <>
              <br />
              <br />
              <Tabs
                value={tabIndex}
                onChange={(e, i) => setTabIndex(i)}
                classes={{
                  root: classes.tabsRoot,
                  indicator: classes.tabsIndicator
                }}
              >
                <Tab
                  disableRipple
                  classes={{
                    root: classes.tabRoot,
                    selected: classes.tabSelected
                  }}
                  label="INFORMATION"
                />
              </Tabs>
              {tabIndex === 0 && (
                <OfferProductInformationTab
                  name={offerProduct.name}
                  // description={offerProduct.description}
                  multiProductFieldValues={offerProduct.productFieldValueItems}
                  handleMultiProductFieldValuesChange={handleFieldValuesChange}
                  onChange={handleChangeState}
                />
              )}
            </>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={onCancel}>Annuller</Button>
          <Button onClick={handleSave}
            variant="contained"
            disabled={loading}
            color="primary"
            autoFocus>
            Gem
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

const DialogEditOfferProductStyled = withStyles(styles)(DialogEditOfferProduct);
