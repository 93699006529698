import { Checkbox, FormControlLabel } from "@material-ui/core";
import React from "react";
import Page from "./Page";

function PageSelector({ selectedPages, spread,
    handleCheckPageChange, position, page }) {
    return (
        <FormControlLabel
            control={position === "left" ?
                <Checkbox
                    checked={selectedPages.includes(page)}
                    name={page}
                    onChange={event =>
                        handleCheckPageChange(event)}
                /> :
                <Page
                    page={page}
                />
            }
            style={{
                marginLeft: "0",
                marginRight: "0"
            }}
            label={position === "left" ?
                <Page
                    page={page}
                /> :
                <Checkbox
                    checked={selectedPages.includes(page)}
                    name={page}
                    onChange={event =>
                        handleCheckPageChange(event)}
                />
            }
        />
    )
}

export default PageSelector;