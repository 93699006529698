import React, { useState } from 'react';
import { Dialog, DialogTitle, DialogContent, TextField, DialogActions, Button, LinearProgress, InputAdornment } from '@material-ui/core';
import { FilePond } from 'react-filepond';
import { showSuccessNotification } from '../../../../helpers/UtilHelper';
import createDialog from '../../../../DialogRenderer';
import { createLogo, createLogo2 } from '../Requests';
import ChipInput from 'material-ui-chip-input';

export async function openDialogAddLogo() {
    return createDialog(({ show, onSubmit, onDismiss }) => (
        <DialogAddLogo
            open={show}
            onCancel={onDismiss}
            onConfirm={onSubmit}
        />
    ));
}

function DialogAddLogo(props) {
    const { open, onCancel, onConfirm } = props;

    const [loading, setLoading] = useState(false);
    const [name, setName] = useState("");
    const [mappings, setMappings] = useState([]);
    const [tempMappingsText, setTempMappingsText] = useState("");
    const [file, setFile] = useState(null);
    const [width, setWidth] = useState(7);
    const [height, setHeight] = useState(7);

    const handleCreateLogo = async () => {
        setLoading(true);
        try {
            await createLogo(name, mappings, width, height, file);
            showSuccessNotification("Logoet er blevet oprettet");
            onConfirm();
        } catch (e) {
            // showErrorNotification("Logoet kunne ikke blive oprettet");
        } finally {
            setLoading(false);
        }
    }

    const handleFilesChanged = files => {
        if (files && files.length > 0) {
            setFile(files[0].file);
        } else {
            setFile(null);
        }
    }

    const handleAddMapping = text => {
        setTempMappingsText("");
        setMappings([...mappings, text]);
    }

    return (
        <>
            <Dialog
                fullWidth
                maxWidth="sm"
                open={open}
                onClose={onCancel}
            >
                <DialogTitle>Opret logo</DialogTitle>
                <DialogContent>
                    {loading ? <LinearProgress /> : null}
                    <TextField
                        margin="normal"
                        label="Navn"
                        type="text"
                        value={name}
                        fullWidth
                        onChange={(e) => setName(e.target.value)}
                    />
                    <ChipInput
                        style={{ marginBottom: 20 }}
                        label="Mappings"
                        value={mappings}
                        onAdd={x => handleAddMapping(x)}
                        inputValue={tempMappingsText}
                        onDelete={tag => setMappings(mappings.filter(x => tag !== x))}
                        onUpdateInput={e => setTempMappingsText(e.target.value)}
                        helperText={"tryk enter for hver mapping"}
                        fullWidth
                    />
                    <div>
                        <TextField
                            margin="normal"
                            label="Bredde"
                            type="number"
                            value={width}
                            style={{marginRight: 20, width: 110}}
                            onChange={(e) => setWidth(e.target.value)}
                            InputProps={{
                                endAdornment: <InputAdornment position="end">mm</InputAdornment>,
                            }}
                        />
                        <TextField
                            margin="normal"
                            label="Højde"
                            type="number"
                            value={height}
                            style={{ marginRight: 20, width: 110 }}
                            onChange={(e) => setHeight(e.target.value)}
                            disabled
                            InputProps={{
                                endAdornment: <InputAdornment position="end">mm</InputAdornment>,
                            }}
                        />
                    </div>
                    <div className="filePond" style={{ marginTop: 20 }}>
                        <FilePond
                            allowImagePreview={true}
                            onupdatefiles={files => handleFilesChanged(files)}
                            allowMultiple={false}
                            imagePreviewHeight={300}
                            labelIdle={
                                'Træk og slip din billedfil eller <span class="filepond--label-action">Gennemse</span>'
                            }
                        />
                    </div>
                </DialogContent>
                <DialogActions>
                    <Button onClick={onCancel}>
                        Annuller
                    </Button>
                    <Button onClick={handleCreateLogo} color="primary" disabled={loading || !name || !file}>
                        Opret
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    )
}

export default DialogAddLogo