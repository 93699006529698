import React from 'react'
import { Button } from '@material-ui/core';
import { Typography } from '@material-ui/core';
import { useState } from 'react';
import { useEffect } from 'react';
import { styles } from '../../../shared';
import { getSingleOfferV2 } from '../../../../../tilbudsstyring/Requests';
import EditOfferContent from './EditOfferContent';
import EditGreenlandOfferContent from '../editOffer/Greenland/EditGreenlandOfferContent';
import { allowedInfoTextOfferFilmmaker } from '../../../../../../../constants/enums';


export default function EditOfferMain({ 
    tilbage, 
    offerId, 
    saveOfferScreen, 
    addItem,
    customer,
    setTool,
    selectedBackground
}) {
    const [offer, setOffer] = useState(undefined);
    const [fields, setFields] = useState([]);

    useEffect(() => {
        const runEffect = async () => {
            if (!offerId) {
                return;
            }
            const _offer = await getSingleOfferV2(offerId);

            if (_offer) {
                setOffer(_offer);

                const newInfoText = _offer.fieldValues.filter(x => allowedInfoTextOfferFilmmaker.has(x.name));
                setFields(newInfoText);
            }
        };
        runEffect();
    }, [offerId]);

    const renderView = () => {
        switch(customer) {
            case 48:
              return (
                <>
                {offer ? 
                    <EditGreenlandOfferContent
                    offerId={offer && offer.id}
                    offer={offer}
                    saveOfferScreen={(data) => saveOfferScreen(data)}
                    addItem={(item) => addItem(item)}
                    customer={customer}
                    setTool={(tool) => setTool(tool)}
                    selectedBackground={!selectedBackground ? undefined : selectedBackground.urlRelative}
                /> : undefined
                }
                </>
              );
            default:
              return (
                <>
                {fields.length > 0 ? (
                <EditOfferContent
                    fields={fields}
                    offerId={offer && offer.id}
                    offer={offer}
                    saveOfferScreen={(data) => saveOfferScreen(data)}
                    addItem={(item) => addItem(item)}
                    customer={customer}
                />
                ) : <p>Offer does not contain any fields</p>}
                </>
              )
          }
    }

    return (
        <div>
            <div style={{ width: '100%', display: 'inline-flex', justifyContent: 'space-between' }}>
                <Typography variant="h4" style={styles.viewText}>Rediger tilbud</Typography>
                <Button variant="text" color="primary" size="large" style={styles.viewButton}
                    onClick={tilbage}
                >
                    {"<"} Tilbage
                </Button>
            </div>
           {renderView()}
        </div>
    )
}
