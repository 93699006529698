import React, { useEffect, useState } from 'react';
import Typography from '@material-ui/core/Typography';
import Headline from './Headline';
import AddHelpPage from './AddHelpPage';
import { getHelpPages } from './businessLogic/FetchHeadlines';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import { canReadApplication } from "../../../helpers/CredentialsHelper";
import { applications } from "../../../constants/app.constants";
function Hyperlinks() {
    const [selectedItemId, setCount] = useState(undefined);
    const [privAddHelpPage, setPrivAddHelpPage] = useState(false);
    const [headlines, setHeadlines] = useState([]);

    useEffect(() => {
        if (canReadApplication(applications.HELP_PAGE)) {
            setPrivAddHelpPage(true);
        }
        const runEffect = async () => {
            const pages = await getHelpPages();
            if (!pages) {
                setHeadlines([])
                return;
            }
            setHeadlines(pages);
        }
        runEffect();
    }, [headlines.length])

    function setIndex(index) {
        setCount(index);
    }

    function setHeadlineView() {
        if (selectedItemId && selectedItemId <= headlines.length)
            return <Headline privAddHelpPage={privAddHelpPage} page={headlines[selectedItemId - 1]} setHelppages={setHeadlines} />;
    }

    return (
        <div>
            <Typography variant='h4' style={{ marginButtom: 20 }}>Hjælp</Typography>

            {!privAddHelpPage ? undefined :
                (<div align="left">
                    <AddHelpPage buttonText="Tilføj Hjælpe side" setHelppages={setHeadlines} />
                </div>)
            }
            <div>
                <div>
                    <Grid container spacing={1}>
                        {headlines.map((headline, index) => {
                            return (
                                <Grid item xs={3}>
                                    <Button disableElevation={true} style={{ fontWeight: 'bold', textTransform: 'unset' }} variant={'text'} onClick={() => setIndex(index + 1)}>{headline.title}</Button>
                                </Grid>
                            )
                        })
                        }
                    </Grid>
                </div>
                <br/>
                <hr />
                <div style={{ paddingTop: 20 }}>
                    {setHeadlineView()}
                </div>
            </div>
        </div>
    );
}

export default Hyperlinks;