import { endpoints, apiGetParamsReplace } from "../../../../../constants/services.constants";
import { getToken } from "../../../../../helpers/AuthHelper";
import store from "../../../../../redux/store";
import { addToastMessage } from "../../../../../redux/actions/toasts.actions";
import { handleAPIErrorNotification, handleAPIErrors } from "../../../../../redux/Handlers/ErrorHandlers";
import { fetchGetWT, fetchPostWT, fetchPutWT } from "../../../../../helpers/HttpHelper";
import Resources from "../../../../../Resources.json";
export const fetchFeedsRequest = async () => {
    const endpoint = endpoints.FEEDS_GET_ALL;
    return fetchGetWT(
        endpoint,
        json => {
            if (json) {
                return json;
            } else {
                return [];
            }
        }
        ,
        callError => {
            return [];
        });
}

export const createNewFeedRequest = async (feed) => {
    if (!feed) {
        return;
    }

    const headersJsonToken = {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: "Bearer " + getToken(),
        "x-organization-id": Resources.Organization
    };
    const endpoint = endpoints.FEEDS_ADD_FEED;

    return fetch(endpoint, {
        method: "POST",
        headers: headersJsonToken,
        body: JSON.stringify(feed)
    })
        .then(res => {
            if (!res.ok) {
                throw Error(res.statusText);
            }
            if (res.ok && res.status === 200) {
                store.dispatch(
                    addToastMessage({
                        message: ` Feed "${feed.FeedName.Name}" blev skabt`,
                        type: "success",
                        details: null
                    })
                );
            }
        })
        .catch(error => {
            handleAPIErrorNotification(endpoint, error);
        });
}

export const fetchFilterPropertiesRequest = async (type) => {
    if (!type) {
        return [];
    }

    const endpoint = apiGetParamsReplace(endpoints.FILTER_GET_PROPERTIES, type);

    let result = [];

    await fetchGetWT(
        endpoint,
        json => {
            result = JSON.parse(json);
        }
        ,
        callError => {
            result = [];
        });

    return result;
}

export const createNewFilterRequest = async (filter) => {
    const endpoint = endpoints.FILTER_POST;
    return fetchPostWT(
        endpoint,
        JSON.stringify(filter),
        json => {
            if (json !== undefined) {
                store.dispatch(
                    addToastMessage({
                        message: "Filter blev oprettet",
                        type: "success",
                        details: null
                    })
                );
            }
            return json;
        },
        error => {
            throw Error("Failed to create new filter");
        }
    );
}

export const patchFilterRequest = async (id, filter) => {
    const endpoint = apiGetParamsReplace(endpoints.FILTER_PATCH, [id]);

    const headersJsonToken = {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: "Bearer " + getToken(),
        "x-organization-id": Resources.Organization
    };

    return fetch(endpoint, {
        method: "PUT",
        headers: headersJsonToken,
        body: JSON.stringify(filter)
    })
        .then(res => {
            if (!res.ok) {
                throw Error(res.statusText);
            }
            if (res.ok && res.status === 200) {
                store.dispatch(
                    addToastMessage({
                        message: "Filteret blev opdateret",
                        type: "success",
                        details: null
                    })
                );
            }
        })
        .catch(error => {
            handleAPIErrorNotification(endpoint, error);
        });
}

export const getFilterRequest = async (id) => {
    const endpoint = apiGetParamsReplace(endpoints.FILTER_GET_SINGLE, [id]);
    return fetchGetWT(
        endpoint,
        json => {
            return json;
        },
        error => {
            throw Error(error);
        })
}

export const filterExistsRequest = async (id) => {
    const endpoint = apiGetParamsReplace(endpoints.FILTER_EXISTS, [id]);
    return fetchGetWT(
        endpoint,
        json => {
            return json;
        },
        error => {
            throw Error(error);
        })
}

export const feedApplyFilter = async (feedId) => {
    const endpoint = apiGetParamsReplace(endpoints.FEED_APPLY_FILTER, [feedId]);
    return fetchGetWT(
        endpoint,
        json => {
            return json;
        },
        error => {
             throw Error(error);
        })
}