// Magical function that updates imageIndex index so your images flip. Don't try to change it so imageIndex start from 1 or the images array gonna explode :P
/**
 * 
 * @param {number} imageIndex  - current image index.
 * @param {number} imagesSize - size of image array.
 * @param {string} position - position we move to either prev or next.
 * @param {boolean} mobileMode - define if displaying 1 picture on screen, you want it on phones. (future proof not used rn)
 * @param {function} setImageIndex - update the state of the component.
 */
export function onButtonClick(imageIndex, imagesSize, position, mobileMode, setImageIndex) {

    let _imageIndex;

    switch (position) {
        case 'prev':
            if (imageIndex === 0) {
                break;
            }

            _imageIndex = imageIndex - (mobileMode ? 1 : 2);

            if (_imageIndex < 0) {
                _imageIndex = 0;
            }

            break;
        case 'next':
            const maxLength = imagesSize - 1;
            if (imageIndex === maxLength) {
                break;
            }

            if (imageIndex === 0) {
                if (maxLength >= 1) {
                    _imageIndex = 1;
                }
                break;
            }

            _imageIndex = imageIndex + (mobileMode ? 1 : 2);
            if (_imageIndex > maxLength) {

                if (maxLength % 2 !== 0) {
                    _imageIndex = maxLength;
                } else {
                    _imageIndex = maxLength - 1;
                }
            }
            break;
        default:
            throw Error("invalid position");
    }

    if (_imageIndex !== undefined) {
        setImageIndex(_imageIndex);
    }

};