import { 
  FETCH_CUSTOMERS_BEGIN, FETCH_CUSTOMERS_SUCCESS, FETCH_CUSTOMERS_FAILURE,
  FETCH_CUSTOMER_BEGIN, FETCH_CUSTOMER_SUCCESS, FETCH_CUSTOMER_FAILURE }
from "../actions/utils/customers.utils.actions";
import { 
  FETCH_FEATURES_BEGIN, FETCH_FEATURES_SUCCESS, FETCH_FEATURES_FAILURE }
from "../actions/utils/features.utils.actions";
  const initState = {
    customers:null,
    features:null
  }
  export default (state = initState, action) => {
    switch(action.type) {
        case FETCH_CUSTOMERS_BEGIN:
        return {
          ...state,
          loadingCustomers: true,
          errorCustomers: null
        };
      case FETCH_CUSTOMERS_SUCCESS:
        return {
          ...state,
          loadingCustomers: false,
          customers: action.payload.customers,
        };
  
      case FETCH_CUSTOMERS_FAILURE:
        return {
          ...state,
          loadingCustomers: false,
          errorCustomers: action.payload.error,
          customers: null
        };
      case FETCH_CUSTOMER_BEGIN:
        return {
          ...state,
          loadingCustomer: true,
          errorCustomer: null,
        };
      case FETCH_CUSTOMER_SUCCESS:
          return {
            ...state,
            loadingCustomer: false,
            customer: action.payload.customer,
          };
      case FETCH_CUSTOMER_FAILURE:
        return {
          ...state,
          loadingCustomer: false,
          errorCustomer: action.payload.error,
          customer: null
        };
        case FETCH_FEATURES_BEGIN:
          return {
            ...state,
            loadingFeatures: true,
            errorFeatures: null
          };
        case FETCH_FEATURES_SUCCESS:
          return {
            ...state,
            loadingFeatures: false,
            features: action.payload.features,
          };
    
        case FETCH_FEATURES_FAILURE:
          return {
            ...state,
            loadingFeatures: false,
            errorFeatures: action.payload.error,
            features: null
          };
  //-------------------------------------------
  //-------------------------------------------
      default:
        return state;
    }
  }