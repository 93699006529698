import React from "react";
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Typography } from "@material-ui/core";
import { useEffect, useState } from "react";
import createDialog from "../../../../DialogRenderer";
import { getMotivId } from "../../../../helpers/UtilHelper";
import AutoSuggestionWithChipApi from "../../photos/AutoSuggestionWithChipApi ";
import { createProductPhotos, deleteProductPhotos, getProduct, getProductPhotosForPhotoId, getProducts } from "../Requests";
import { apiGetParamsReplace, endpoints } from "../../../../constants/services.constants";
import { LoadingLogo } from "../../../shared/Loading";
import { withStyles } from "@material-ui/styles";



export async function openDialogUnlinkPhoto(photoId, productId) {
    return createDialog(({ show, onSubmit, onDismiss }) => (
        <DialogUnlinkPhotoStyled
            onCancel={onDismiss}
            onConfirm={onSubmit}
            open={show}
            photoId={photoId}
            productId={productId}
        />
    ));
}

function style() {
    return {
        sectionHeader: {
            fontSize: "1.1rem",
            marginBottom: 20
        },
        imageContainer: {
            width: 100,
            height: 100,
            marginRight: 20,
            display: "flex",
            justifyContent: "center"
        },
        imageAndTextContainer: {
            display: "flex",
            flexDirection: "row",
            marginBottom: 50
        },
        image: {
            objectFit: "contain",
            maxWidth: "100%",
            maxHeight: "100%",
            width: "auto",
            height: "auto"
        }
    }
}

const DialogUnlinkPhotoStyled = withStyles(style)(DialogUnlinkPhoto);
function DialogUnlinkPhoto({ onCancel, onConfirm, open, photoId, productId, classes }) {
    const [loading, setLoading] = useState(true);
    const [hasChanges, setHasChanges] = useState(false);
    const [productName, setProductName] = useState(undefined);
    const [productNumber, setProductNumber] = useState(undefined);
    const [productPhotos, setProductPhotos] = useState([]);
    const [productLinks, setProductLinks] = useState([]);

    useEffect(() => {
        init();
    }, []);

    const init = async () => {
        try {
            const product = await getProduct(productId);
            setProductName(product.name);
            setProductNumber(product.productNumber);

            // get product links for photo
            const productPhotos = await getProductPhotosForPhotoId(photoId);
            setProductPhotos(productPhotos);
            const productIds = productPhotos.map(x => x.productId);
            if (productIds.length > 0) {
                const products = await getProducts(productIds, true, false);
                const links = products.map(x => {
                    return {
                        IsNewAdded: false,
                        IsDeleted: false,
                        name: x.name ?? "",
                        productId: x.id,
                        productNumber: x.productNumber,
                        photoId: photoId,
                        isDefault: productPhotos.some(x => x.productId === x.id && x.isDefault),
                        noOfPhotoWithProduct: x.photoIds.length,
                    }
                });
                setProductLinks(links);
            }
        }
        finally {
            setLoading(false);
        }
    }

    const handleSave = async () => {
        setLoading(true);

        // delete links
        const productPhotosToDelete = productLinks.filter(x => x.IsDeleted && !x.IsNewAdded)
            .map(link => productPhotos.find(productPhoto => productPhoto.productId === link.productId))
            .map(x => x.id);
        if (productPhotosToDelete.length > 0) {
            await deleteProductPhotos(productPhotosToDelete);
        }

        // add new links
        const productPhotosToAdd = productLinks.filter(x => x.IsNewAdded && !x.IsDeleted)
            .map(x => ({ productId: x.productId, photoId: photoId }));
        if (productPhotosToAdd.length > 0) {
            await createProductPhotos(productPhotosToAdd);
        }

        onConfirm();

        setLoading(false);
    }

    const handleAddLink = async (link) => {
        setProductLinks([...productLinks, link]);
        setHasChanges(true);
    }

    const handleRemoveLink = async (productNumber) => {
        const link = productLinks.find(x => x.productNumber == productNumber);
        const index = productLinks.indexOf(link);

        const cloneLinks = [...productLinks];
        cloneLinks[index] = { ...link };
        cloneLinks[index].IsDeleted = true;

        setProductLinks(cloneLinks);
        setHasChanges(true);
    }

    const getDialogContent = () => {
        return <>
            <div className={classes.imageAndTextContainer}>
                <div className={classes.imageContainer}>
                    <img className={classes.image} src={apiGetParamsReplace(endpoints.PHOTO_SINGLE_200, photoId)} />
                </div>

                <Typography>
                    Du er ved at unlinke fotoet {getMotivId(photoId)} fra {productName} ({productNumber})
                </Typography>
            </div>


            <div className={classes.sectionHeader}>
                Tilknytninger for fotoet
            </div>

            <div className={classes.autoSuggestionField}>
                <AutoSuggestionWithChipApi
                    classes={classes}
                    MotiveId={photoId}
                    handleAddEans={handleAddLink}
                    handleDeleteEans={handleRemoveLink}
                    gtiNs={productLinks}
                />
            </div>
        </>
    }

    return (
        <Dialog
            open={open}
            fullWidth={true}
            maxWidth="md"
            onClose={onCancel}
        >
            <DialogContent>
                <DialogTitle>Unlink foto</DialogTitle>
                <DialogContent>
                    {loading ? <LoadingLogo /> : getDialogContent()}
                </DialogContent>
                <DialogActions>
                    <Button onClick={onCancel}>Annuller</Button>
                    <Button color="secondary" disabled={loading || !hasChanges} onClick={handleSave}>Gem og unlink</Button>
                </DialogActions>
            </DialogContent>
        </Dialog>)
}