import React from "react";
import Switch from "react-router-transition-switch";
import Fader from "react-fader";
import { Route } from "react-router-dom";
// import Login from "../components/auth/Login";
// import ResetPassword from "../components/auth/ResetPassword";
import ResetPassWordV2 from "../components/auth/resetPassWord/ResetPassWordV2";
import Login from "../components/auth/logIn/LoginV2";
const AppLoginRoutes = (props) => {

  return (
    <Switch component={Fader}>
      <Route path="/" render={() => <Login {...props}  />} exact={true} />
      <Route path="/resetpassword/:username/:code" component={ResetPassWordV2} exact={true} />


      <Route render={() => <Login {...props} />} />
    </Switch>
  );
};
export default AppLoginRoutes;
