import { TextField, Typography } from '@material-ui/core'
import React from 'react'
import { Button } from '@material-ui/core';
import { styles } from './shared';
import { useState, useEffect } from 'react';
import { ActionButton } from './offerComponents/views/editOffer';
import { useStyles } from './Screen';
import SearchOfferField from './offerComponents/views/SearchOfferField';
import { fetchGetWT } from '../../../../helpers/HttpHelper';
import { endpoints } from '../../../../constants/services.constants';

export default function ScreenSaveName({ tilbage, screenName, setScreenName, saveScreen, onKeyDown, templateMode }) {
    const [name, setName] = useState("");
    const classes = useStyles();

    const [fetching, setFetching] = useState(false);
    const [nameExists, setNameExists] = useState(null);

    useEffect(() => {
        if (templateMode && name.trim() !== "") {
            setFetching(true);
            fetchGetWT(
                endpoints.FILMMAKER_TEMPLATES_CHECKNAME.replace("{name}", name),
                (r) => {
                    setFetching(false);
                    setNameExists(r);
                },
                (e) => {
                    setFetching(false);
                    setNameExists(null);
                }
            );
        }
        return () => {

        }
    }, [name]);


    function handleSetScreenName() {
        setScreenName(name.trim());
    };
    const textActionButton = !templateMode ? "Gem skærm" : nameExists ? "Overwrite skabelon" : "Gem skabelon";
    return (
        <div>
            <div style={{ width: '100%', display: 'inline-flex', justifyContent: 'space-between' }}>
                <Typography variant="h4" style={styles.viewText}>Gem ny {templateMode ? "skabelon" : "skærm"}</Typography>
                <Button variant="text" color="primary" size="large" style={styles.viewButton}
                    onClick={tilbage}
                >
                    {"<"} Tilbage
                </Button>
            </div>
            <div style={{
                marginTop: 50,
                textAlign: "center"
            }}>
                <SearchOfferField
                    searchText={name}
                    onChange={value => setName(value)}
                    placeHolder="Indtast navn på skærm"
                    onKeyDown={(z) => z.key === 'Enter' ? handleSetScreenName() : undefined}
                />
                <br />
                <ActionButton
                    text={textActionButton}
                    onClick={() => fetching ? alert("checking name...") : handleSetScreenName()}
                    className={classes.buttonMain}

                />
            </div>
        </div>
    )
}
