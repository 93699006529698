import { apiGetParamsReplace, endpoints } from "../../../constants/services.constants";
import { getToken } from "../../../helpers/AuthHelper";
import { _request } from "../../../helpers/HttpHelper";
import { combineQueryParameters, getBase64, urltoFile } from "../../../helpers/UtilHelper";
import Resources from "../../../Resources.json";
export async function getOffersForPublication(publicationId) {
  return _request({
    method: "GET",
    endpoint: apiGetParamsReplace(endpoints.OFFER_GET_FOR_PUBLICATION, publicationId, true, true, true),
    throwError: true
  });
}

export async function getPublication(publicationId) {
  return _request({
    method: "GET",
    endpoint: apiGetParamsReplace(endpoints.PUBLICATION_SINGLE_V2, publicationId),
    throwError: true
  });
}

export async function getPublicationByJobId(jobId, merged) {
  return _request({
    method: "GET",
    endpoint: apiGetParamsReplace(endpoints.PUBLICATION_BY_JOBID_V2, jobId, `merged=${merged}`),
    throwError: true
  });
}

export async function getOffer(offerId) {
  return _request({
    method: "GET",
    endpoint: apiGetParamsReplace(endpoints.OFFER_SINGLE_V2, offerId),
    throwError: true
  });
}

export async function getPhotos(photoIds) {
  return _request({
    method: "POST",
    endpoint: endpoints.PHOTO_INFO_MULTIPLE,
    body: JSON.stringify(photoIds),
    throwError: true
  });
}

export async function clipPhoto(photoId, jobId, forceShadow = false) {
  return _request({
    method: "POST",
    endpoint: apiGetParamsReplace(endpoints.PHOTOCLIPPING_ADD_PHOTO, `?jobId=${jobId}&photoId=${photoId}&forceShadow=${forceShadow}`),
    throwError: true
  });
}

export async function copyOffer(offerId, jobId, pageNumber) {
  const queryParams = `jobId=${jobId}&pageNumber=${pageNumber}`;
  return _request({
    method: "POST",
    endpoint: apiGetParamsReplace(endpoints.OFFER_COPY, offerId, queryParams),
    skipBodyParsing: true,
    throwError: true
  });
}

export async function deleteOfferProduct(offerProductId) {
  return _request({
    method: "DELETE",
    endpoint: apiGetParamsReplace(endpoints.OFFER_DELETE_PRODUCT_V2, offerProductId),
    skipBodyParsing: true,
    throwError: true
  });
}

export async function deleteOffer(offerId, publicationPageId) {
  return _request({
    method: "DELETE",
    endpoint: apiGetParamsReplace(endpoints.PUBLICATION_DELETE_OFFER_ON_PAGE, offerId, publicationPageId),
    skipBodyParsing: true,
    throwError: true
  });
}

export async function getTemplates(customerId, type) {
  return await _request({
    method: "GET",
    endpoint: apiGetParamsReplace(endpoints.INDESIGN_GET_TEMPLATES, customerId, type)
  });
}

export async function getOfferProductOld(offerProductId) {
  return await _request({
    method: "GET",
    endpoint: apiGetParamsReplace(endpoints.OFFER_PRODUCT_SINGLE, offerProductId)
  });
}

export function updateOfferProduct(offerProductId, dto) {
  return _request({
    method: "PUT",
    endpoint: apiGetParamsReplace(endpoints.OFFER_PRODUCT_UPDATE, offerProductId),
    body: JSON.stringify(dto),
    throwError: true
  });
}

export async function getPhotoOrdersByPhotoIds(photoIds) {
  return _request({
    method: "POST",
    endpoint: endpoints.PHOTO_ORDERS_GET_BY_PHOTO_IDS,
    body: JSON.stringify(photoIds),
    throwError: true
  });
}

export async function getPhotoOrderSingleV2(orderId) {
  const endpoint = apiGetParamsReplace(endpoints.PHOTO_FOTOTRACKER_SINGLE_V2, [
    orderId,
  ]);
  return await _request({
    method: "GET",
    endpoint: endpoint
  });
}

export async function getBase64FromBlob(files, url) {
  let type = "";
  return await Promise.all(
    files.map(async item => {
      const endpoint = apiGetParamsReplace(url, [
        item.id
      ]);

      const response = await fetch(endpoint, {
        method: "GET",
        headers: 
        { 
          Authorization: "Bearer " + getToken(),
          "x-organization-id": Resources.Organization
        }
      });

      const resBlob = await response.blob();
      const data = await getBase64(resBlob);
      type = resBlob.type;

      if (resBlob.type === "") {
        const fileType = item.originalFileName.split(".")[1];

        switch (fileType) {
          case "pdf":
            type = "application/pdf";
            break;
          default:
            type = "image/*";
        }
      }

      const file = await urltoFile(data, item.originalFileName, type);
      return file;
    }));
}
export async function getDeactivatedPhotos(publicationId) {
  const endpoint = apiGetParamsReplace(endpoints.PUBLICATION_GET_DEACTIVATED_PHOTOS, [publicationId]);
  return _request({
    method: "GET",
    endpoint: endpoint,
    throwError: true
  });
};
export async function checkExcistingPdfs(jobNumber) {
  const endpoint = apiGetParamsReplace(endpoints.GET_EXISTING_PDFS_FOR_PAGES, [
    jobNumber,
  ]);
  return await _request({
    method: "GET",
    endpoint: endpoint
  });
}

export async function copyMultipleOffers(offerIds, jobId, pageNumber) {
  const formData = new FormData();
  formData.append("jobId", jobId);
  formData.append("pageNumber", pageNumber);

  offerIds.map((offerId, index) => formData.append(`offerIds[${index}]`, offerId));

  return _request({
    method: "POST",
    endpoint: endpoints.OFFERS_COPY_MULTIPLE,
    body: formData,
    skipBodyParsing: true,
    throwError: true,
    headers: {
      Accept: "application/json, application/xml, text/plain, text/html, *.*",
      Authorization: "Bearer " + getToken(),
      "x-organization-id": Resources.Organization
    }
  });
}

export async function UpdatePageContentDescription(pageId,contentDescription){
  const endpoint = apiGetParamsReplace(endpoints.PUBLICATION_CONTENT_DESCRIPTION, [
    pageId,
  ]);
  return _request({
    method: "PATCH",
    endpoint: endpoint,
    body: JSON.stringify(contentDescription),
    throwError: true,
    skipBodyParsing: true
  });
}