import React from "react";
export default function SliderButton({ onButtonClick, right }) {
    return (
        <button
            onClick={onButtonClick}
            style={{
                position: 'absolute',
                top: '50%',
                backgroundColor: 'rgba(0,0,0,0)',
                color: 'white',
                border: 0,
                fontFamily: `'Arial Rounded MT Bold','Helvetica Rounded',Arial,sans-serif`,
                fontSize: 80,
                fontWeight: 400,
                cursor: 'pointer',
                userSelect: 'none',
                right: right ? 0 : undefined
            }}
        >{right ? <>&gt;</> : <>&lt;</>}</button>
    )
}