import React, { useState, useContext } from 'react';
import { withStyles, Dialog, DialogTitle, DialogContent, DialogActions, TextField, Button, FormControl, InputLabel, Select, MenuItem, FormHelperText } from '@material-ui/core';
import clsx from 'clsx';
import { createNewFeedRequest, fetchFeedsRequest } from '../Logic/requests';
import { FeedsContext } from '../../contexts/FeedsContext';

const styles = theme => ({
    input: {
        marginBottom: 35
    },
    actions: {
        display: "grid",
        gridTemplateColumns: "1fr 1fr 1fr 1fr 1fr",
        gridGap: "1rem",
        marginBottom: 30
    },
    actionButton: {
        width: 110,
        justifySelf: "end",
    },
    positionGroup: {
        gridColumn: 4
    },
    error: {
        color: "#f44336"
    }
});

const ColorButton = withStyles(theme => ({
    root: {
        marginRight: 10,
        backgroundColor: "#263238",
        color: "#ffffff",
        '&:hover': {
            backgroundColor: "#11171A",
            color: "#ffffff"
        },
    },
}))(Button);

function DialogAdd(props) {
    const { feeds: feedsState } = useContext(FeedsContext);
    const [feeds, setFeeds] = feedsState;
    const [feedName, setFeedName] = useState("");
    const [collectionId, setCollectionId] = useState(null);
    const [errorFeedName, setErrorFeedName] = useState("");
    const [errorCollection, setErrorCollection] = useState(null);
    const { open, handleClose, collections, classes } = props;

    const handleSave = async () => {
        if (!feedName) {
            setErrorFeedName("Skal være udfyldt!");
            return;
        } else {
            setErrorFeedName("");
        }
        if (!collectionId) {
            setErrorCollection("Skal være udfyldt!");
            return;
        } else {
            setErrorCollection("");
        }

        let feed = {
            CollectionId: collectionId,
            FeedName: {
                Name: feedName
            }
        }

        handleClose();
        await createNewFeedRequest(feed);

        let feedsUpdated = await fetchFeedsRequest();
        if (!feedsUpdated) {
            setFeeds([]);
        }
        setFeeds(feedsUpdated);
    }
    return (
        <>
            <Dialog
                open={open}
                onClose={handleClose}
            >
                <DialogTitle id="form-dialog-title">Opret feed</DialogTitle>
                <DialogContent>
                    <TextField
                        error={!!errorFeedName}
                        helperText={errorFeedName}
                        className={classes.input}
                        margin="dense"
                        label="Navn"
                        type="text"
                        name="name"
                        value={feedName}
                        InputProps={{
                            multiline: true
                        }}
                        onChange={(e) => setFeedName(e.target.value)}
                        fullWidth

                    />
                    <FormControl className={classes.input} fullWidth>
                        <InputLabel shrink htmlFor="typeId">Collection</InputLabel>
                        <Select
                            fullWidth
                            value={collectionId ? collectionId : ""}
                            onChange={(e) => setCollectionId(e.target.value)}
                            MenuProps={{ classes: { paper: classes.dropdownStyle } }}
                            inputProps={{
                                name: "typeId",
                                id: "typeId"
                            }}
                        >
                            {collections.map((collection, index) => (
                                <MenuItem key={index} value={collection.collectionId}>
                                    {collection.name.name}
                                </MenuItem>
                            ))}

                        </Select>
                        {!!errorCollection && <FormHelperText className={classes.error}>{errorCollection}</FormHelperText>}
                    </FormControl>
                </DialogContent>
                <DialogActions>
                    <div className={classes.actions}>
                        <ColorButton className={clsx(classes.actionButton, classes.positionGroup)} onClick={handleClose} color="primary">
                            Afbryd
                  </ColorButton>

                        <ColorButton className={classes.actionButton} variant="contained" onClick={handleSave} color="primary">
                            Opret
                  </ColorButton>
                    </div>
                </DialogActions>
            </Dialog>
        </>
    )

}

export default withStyles(styles)(DialogAdd)