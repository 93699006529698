import React from "react";
export default function SliderImage({ position, image }) {
    return (
        <div style={{
            display: 'flex',
            flex: '1 1 auto',
            backgroundSize: 'contain',
            backgroundRepeat: 'no-repeat',
            backgroundImage: `url(${image})`,
            backgroundPosition: position
        }}></div>
    )
}