import { photoOrderTypeEnum, photoTypeEnum } from "../../../../../../constants/enums";
import { getDateDatePicker, showErrorNotification } from "../../../../../../helpers/UtilHelper";
import { getProductName } from "../../../SharedRequests";
import validateExclusiveCustomer from "./Logic";

// TODO Alina share with edit photo order
export async function addProductNumber(order, setOrder, state, setState, chips) {
    const product = await getProductName(chips);
    setState({ ...state, inputValueGTIN: "" });
    let photoOrder = { ...order };
    photoOrder.productNumbers = [...photoOrder.productNumbers, `${product.productNumber}(${product.name})`];
    setOrder(photoOrder);
}

// TODO Alina share with edit photo order
export function changeFieldValue(order, setOrder, value, fieldName) {
    let photoOrder = { ...order };
    photoOrder[fieldName] = value;
    setOrder(photoOrder);
}

// TODO Alina share with edit photo order
export function handleUpdateInputGTIN(state, setState, e) {
    e.persist();
    if (/^[0-9]*$/.test(e.target.value)) {
        setState({ ...state, inputValueGTIN: e.target.value });
    }
};

// TODO Alina share with edit photo order
export function handleDeleteGTIN(order, setOrder, deletedChip) {
    let photoOrder = { ...order };
    if (photoOrder.productNumbers.length === 1) {
        showErrorNotification("Du må ikke slette det sidste produkt nummer");
        return;
    }
    const productNumbersCopy = [...photoOrder.productNumbers];
    const updatedProductNumbers = productNumbersCopy.filter(c => c !== deletedChip);
    setOrder({ ...order, productNumbers: updatedProductNumbers });
}

// TODO Alina share with edit photo order
export function setExclusiveCustomerIds(ids, jobNo, selectedCustomer, state, setState) {
    if (!selectedCustomer || selectedCustomer === "") {
        showErrorNotification("Du skal først vælge kunde");
        return;
    };

    if (jobNo === "") {
        showErrorNotification("Du skal først vælge en job nummer");
        return;
    };

    const { selectionIsValid, errorMsg } = validateExclusiveCustomer(ids, jobNo, selectedCustomer);
    if (!selectionIsValid) {
        showErrorNotification(errorMsg);
        return;
    }

    setState({ ...state, exclusiveForCustomerIds: ids });
}

// Used only when create new photo order from photo tracker
export function setJobId(order, setOrder, _jobid) {
    _jobid = _jobid.trim();
    setOrder({ ...order, jobId: _jobid });
}

// TODO Alina share with edit photo order
export function handlePhotoTypeChange(order, setOrder, state, setState, parentPhotoType, e, fieldChanged) {
    const newStatusTypeId = e.target.value;
    let photoOrder = { ...order };
    switch (fieldChanged) {
        case "ParentType":
            //If we change parent photo type, we want to reset the multiPhotoTypeId.
            if (parentPhotoType !== newStatusTypeId) {
                photoOrder.type = newStatusTypeId === photoTypeEnum.Pack.id ?
                    photoOrderTypeEnum.Pack.id :
                    photoOrderTypeEnum.Environment.id;

                if (newStatusTypeId === 3) {
                    photoOrder.digitalPack = false;
                }
                setState({ ...state, multiPhotoParentTypeId: newStatusTypeId });
                setOrder(photoOrder);
            }
            break;
        case "SubType":
            const newType = newStatusTypeId;
            setOrder({ ...order, type: newType });
            break;
        default:
    }
}

// TODO Alina share with edit photo order
export function handleChangeDate(order, setOrder, date, fieldName) {
    const newDate = new Date(date);
    const dateFormatted = getDateDatePicker(newDate);

    const orderCopy = { ...order };
    orderCopy[fieldName] = dateFormatted;
    setOrder(orderCopy);
}