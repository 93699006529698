import React from "react";
import { connect } from "react-redux";
import { InformationTab } from "../TabContainers/InformationTab";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import Typography from "@material-ui/core/Typography";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import { withStyles } from "@material-ui/core/styles";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import { editDialogClose, updateProduct } from "../../../../redux/actions/product/edit.products.actions";
import { handleApiSuccessNotificaiton } from "../../../../redux/Handlers/SuccessHandlers";
import { endpoints } from "../../../../constants/services.constants";
import { fetchPostFormDataWT } from "../../../../helpers/HttpHelper";
import { getMasterId, getMotivId, sortArrayAlphabetically } from "../../../../helpers/UtilHelper";
import UploadPhotoTab from "../TabContainers/UploadPhotoTab";
import GsPhotoTab from "../TabContainers/GsPhotoTab";
import PhotoTab from "../TabContainers/PhotoTab";
import { LoadingLogo } from "../../../shared/Loading";
import ImageEditable from "../../../shared/photoThumbnail/ImageEditable";
import {getGs1Data} from "../Requests";
import {mapGs1DataForLeverandorSelection} from "../Helpers";
import { getProductTags } from "../../fototracker/SharedRequests";
import { IsFeatureEnabled, featureNames } from "../../../../helpers/FeaturesHelper";
import Resources from "../../../../Resources.json"
import {GetResource} from "../../../../helpers/ResourceHelper"
const styles = (theme) => ({
  main: {
    height: "calc(100% - 96px)",
  },
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
  },
  tabsRoot: {
    borderBottom: "1px solid #e8e8e8",
  },
  tabsIndicator: {
    backgroundColor: "#1890ff",
  },
  dialog: {
    minHeight: "760px",
    maxHeight: "760px",
  },
  typography: {
    padding: theme.spacing(3),
  },
});

class DialogEditProduct extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      fullWidth: true,
      maxWidth: "md",
      productId: null,
      value: 0,
      prevName: "",
      prevDescription: "",
      multiProductFieldValues: [],
      name: "",
      description: "",
      multiCustomerId: null,
      multiPhotoTypeId: null,
      files: [],
      isFileUploadPening: false,
      uploading: false,
      //gs1 information
      leverandor: "",
      category: 0,
      tags:[],
      allTags:[],
      gs1DataAutoSuggest: [],
      productDefaultPhoto: undefined,
      tabs: new Map()
    };

    this.baseState = this.state;
  }

  async componentDidMount() {
    var tabIndex = 0;
    this.state.tabs.set(tabIndex++, "information");
    this.state.tabs.set(tabIndex++, "photo");
    if(IsFeatureEnabled(featureNames.Products_Edit_GS1_Enabled)){
      this.state.tabs.set(tabIndex++, "gs1-photo");
    }
    if(IsFeatureEnabled(featureNames.Products_Upload_Photo_Enabled)){
      this.state.tabs.set(tabIndex++, "upload-photo");
    }
  }

  async componentDidUpdate() {
    if (this.props.productsReducer.id === this.state.productId) {
      return;
    }

    if (
      this.props.edit &&
      this.props.productsReducer.product != null &&
      this.state.productId == null
    ) {
      const p = this.props.productsReducer.product;
      // get gs1 information
      const gs1Data = await getGs1Data(this.props.productsReducer.product.productNumber);
      const gs1DataMapped = mapGs1DataForLeverandorSelection(gs1Data);

      const allTags = await getProductTags();
      var mappedTags = allTags.map(m=>m.name);
      mappedTags = sortArrayAlphabetically(allTags);
      this.state.allTags = mappedTags;



      const leverandorFromDb = p.multiProductFieldValues.find(x => x.fieldName === "Leverandørnavn");
      const leverandorName = leverandorFromDb ? leverandorFromDb.fieldValue : "";
      this.setState({
        productId: p.id,
        prevName: p.name,
        //  prevDescription: p.description,
        name: p.name,
        description: p.description,
        disableBackdropClick: true,
        multiProductFieldValues: p.multiProductFieldValues,
        productDefaultPhoto: this.getMasterImage(),
        leverandor: leverandorName,
        category: p.categoryCustomerId,
        tags:p.tags.map(m=>m.productTag.tag),
        allTags:this.state.allTags,
        gs1DataAutoSuggest: gs1DataMapped,
      });
    }
  }

  handleChange = (event, value) => {
    this.setState({ value });

    console.log(this.state.tabs.get(value));

  };

  handleMultiProductFieldValuesChange = (event) => {
    const { value, id } = event.target;
    let multiProductFieldValues = [...this.state.multiProductFieldValues];
    let item = { ...multiProductFieldValues[id] };

    item.fieldValue = value;

    multiProductFieldValues[id] = item;
    this.setState({ multiProductFieldValues });
  };

  handleChangeState = (event) => {
    this.setState({ [event.target.name]: event.target.value });
  };

  handleSetfile = (fileItems) => {
    if (fileItems.length !== 0) {
      this.setState({
        files: fileItems.map((fileItem) => fileItem.file),
      });
    }
  };

  handleSave = async () => {
    let productFieldValuesUpdated = [...this.state.multiProductFieldValues];
    const leverandor = productFieldValuesUpdated.find(x => x.fieldName === "Leverandørnavn");
    if(leverandor != null)
      leverandor.fieldValue = this.state.leverandor;
    let data = {
      name: this.state.name,
      description: this.state.description,
      multiProductFieldValues: productFieldValuesUpdated,
      multiProductLogos: this.props.productsReducer.logos,
      categoryCustomerId: this.state.category,
      multiCustomerId: this.state.multiCustomerId,
      multiPhotoTypeId: this.state.multiPhotoTypeId,
      tags: this.state.tags,
    };

    this.props.dispatch(
      updateProduct(data, this.state.productId, () => {
        this.handleClose();
        handleApiSuccessNotificaiton("Produktet er opdateret");
      })
    );
  };

  getMasterImage() {
    if (!this.props.productsReducer.product) {
      return;
    }
    const array = this.props.productsReducer.product.multiProductPhotos;
    const photo = array.find(x => x.isDefaultImage === true);
    return photo ? photo.multiPhotoId : undefined;
  }

  handleClose = () => {
    this.props.dispatch(editDialogClose());
    this.setState(this.baseState);
    this.props.closeEditDialog();
  };

  handerCustomer = (cusotmerId) => {
    this.setState({ multiCustomerId: cusotmerId });
  };

  handerPhotoType = (phototypeId) => {
    this.setState({ multiPhotoTypeId: phototypeId });
  };

  addImagesToProduct = async () => {
    this.setState({ uploading: true }, async () => {
      let files = this.state.files;
      if (!this.state.multiCustomerId || !this.state.multiPhotoTypeId) return;

      const formData = new FormData();
      formData.append("file", files[0]);
      formData.append("customerId", this.state.multiCustomerId);
      formData.append("Type", this.state.multiPhotoTypeId);
      formData.append("RelatedProductIds[0]", this.props.productsReducer.product.id);

      const endpoint = endpoints.PHOTO_UPLOAD;
      await fetchPostFormDataWT(
        endpoint,
        formData,
        (json) => {
          //  setloading(false);
          handleApiSuccessNotificaiton("Foto tilføjet til produkt");
          this.setState({
            uploading: false,
            files: [],
            multiCustomerId: null,
            multiPhotoTypeId: null,
          });
        },
        (error) => {
          //setloading(false);

          this.setState({
            uploading: false,
            files: [],
            multiCustomerId: null,
            multiPhotoTypeId: null,
          });
          throw Error("Failed to create new filter");
        }
      );
    });
  };

  selectLeverandor = (value) => {
    this.setState({
      leverandor: value
    });
  }
  selectTags = (value) => {
    this.setState({
      tags: value
    });
  }
  render() {
    let r = this.props.productsReducer;
    const { classes } = this.props;
    const { value } = this.state;
    const noDefaultImage = r.product == null || r.product.multiProductPhotos.length === 0
      || !this.state.productDefaultPhoto ? true : false;

    return (
      <div>
        <Dialog
          open={r.editDialog}
          onClose={this.handleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          fullWidth={this.state.fullWidth}
          maxWidth={this.state.maxWidth}
          disableBackdropClick={this.state.disableBackdropClick}
          className={classes.main}
        >
          <DialogTitle>
            {GetResource(Resources.Products_Edit_Header)}
            <br />
            {r.loadingProduct || r.loadingProduct == null ? (
              "Loading..."
            ) : r.product == null ? (
              "Intet produkt"
            ) : (
              <div style={{ display: "flex" }}>
                {noDefaultImage ?
                  (<img
                    src="/images/placeholders/no_photo_placeholder.png"
                    className="productImage"
                    alt="Product"
                  />) :
                  <ImageEditable
                    photoId={this.state.productDefaultPhoto}
                    handleEditImage={() => this.props.onEditImage(this.state.productDefaultPhoto)}
                  />
                }
                <div className="dialogHeadline">
                  <Typography variant="h6" gutterBottom>
                    {r.product.name}
                  </Typography>
                  <Typography variant="subtitle1">
                    {GetResource(Resources.Products_Edit_ProductNumber_Title)}: {r.product.productNumber}
                  </Typography>
                  <Typography variant="subtitle1">
                    Motiv-ID: {getMotivId(getMasterId(r.product))}
                  </Typography>
                </div>
              </div>
            )}
          </DialogTitle>
          <DialogContent className="customDialogContent">
            {r.errorProduct != null ? (
              <Typography>{r.errorProduct}</Typography>
            ) : r.loadingProduct || r.loadingProduct == null ? (
              <LoadingLogo />
            ) : r.product == null ? undefined : (
              // input
              <>
                <br />
                <br />
                <Tabs
                  value={value}
                  onChange={this.handleChange}
                  classes={{
                    root: classes.tabsRoot,
                    indicator: classes.tabsIndicator,
                  }}
                >
                  <Tab
                    disableRipple
                    classes={{
                      root: classes.tabRoot,
                      selected: classes.tabSelected,
                    }}
                    label="INFORMATION"
                  />
                  <Tab
                    disableRipple
                    classes={{
                      root: classes.tabRoot,
                      selected: classes.tabSelected,
                    }}
                    name="buttonProductPhotos"
                    label="Foto"
                  />
                  {IsFeatureEnabled(featureNames.Products_Edit_GS1_Enabled) ?
                    <Tab
                      disableRipple
                      classes={{
                        root: classes.tabRoot,
                        selected: classes.tabSelected,
                      }}
                      label="Gs1 billede"
                    />
                  :undefined
                  }
                  {IsFeatureEnabled(featureNames.Products_Upload_Photo_Enabled) ?
                    <Tab
                      disableRipple
                      classes={{
                        root: classes.tabRoot,
                        selected: classes.tabSelected,
                      }}
                      name="buttonProductUploadPhoto"
                      label="Upload billede"
                    />
                  : undefined
                  }
                </Tabs>
                {this.state.tabs.get(value )=== "information" &&
                  <InformationTab
                    name={this.state.name}
                    description={this.state.description}
                    multiProductFieldValues={this.state.multiProductFieldValues}
                    handleMultiProductFieldValuesChange={
                      this.handleMultiProductFieldValuesChange
                    }
                    onChange={this.handleChangeState}
                    leverandor={this.state.leverandor}
                    gs1DataAutoSuggest={this.state.gs1DataAutoSuggest}
                    selectLeverandor={(value) => this.selectLeverandor(value)}
                    selectTags={(value) => this.selectTags(value)}
                    allTags={this.state.allTags}
                    tags={this.state.tags}
                  />
                }
                {this.state.tabs.get(value) === "photo" &&
                  <PhotoTab
                    deletePhoto={(productPhotoId) => this.props.deletePhoto(productPhotoId)}
                    updateMaster={()=>this.setState({productDefaultPhoto: this.getMasterImage()})}
                  />}
                {this.state.tabs.get(value) === "gs1-photo" &&
                  <GsPhotoTab />
                }
                {this.state.tabs.get(value) === "upload-photo" &&
                  <UploadPhotoTab
                    files={this.state.files}
                    setFile={this.handleSetfile.bind(this)}
                    handerCustomer={this.handerCustomer}
                    handerPhotoType={this.handerPhotoType}
                    addImagesToProduct={this.addImagesToProduct}
                    uploading={this.state.uploading}
                  />
                }
              </>
            )}
          </DialogContent>
          <DialogActions>
            {r.updateLoading ? (
              <Button
                onClick={this.handleClose}
                disabled={true}
                color="primary"
              >
                Opdaterer...
              </Button>
            ) : r.loading ? (
              <Button
                onClick={this.handleClose}
                disabled={true}
                color="primary"
              >
                Opdaterer listen...
              </Button>
            ) : (
              <>
                <Button onClick={this.handleClose}>Annuller</Button>
                <Button
                  style={{ marginLeft: "10px" }}
                  onClick={this.handleSave}
                  variant="contained"
                  color="primary"
                  autoFocus
                  disabled={this.state.isGenEnable}
                  name="buttonProductSave"
                >
                  Gem
                </Button>
              </>
            )}
          </DialogActions>
        </Dialog>
      </div>
    );
  }
}

export default connect((state) => state)(withStyles(styles)(DialogEditProduct));
