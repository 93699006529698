import React from 'react';
import { withStyles, Button } from '@material-ui/core';


const ColorButton = withStyles(() => ({
    root: {
        marginRight: "10px",
        backgroundColor: "#263238",
        color: "#ffffff",
        "&:hover": {
            backgroundColor: "#11171A",
            color: "#ffffff",
        },
        marginTop: 20
    },
}))(Button);

export default function GoBackButton({ className, onClick, disabled }) {
    return (
        <ColorButton
            className={className}
            onClick={() => onClick()}
            color="primary">
            Tilbage
        </ColorButton>
    )

}