import React from "react";

// material
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";
import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import AccordionDetails from "@material-ui/core/AccordionDetails";

import ExpandMoreIcon from "@material-ui/icons/ExpandMore";

export const OfferProductInformationTab = ({
  name,
  description,
  multiProductFieldValues,
  onChange,
  handleMultiProductFieldValuesChange,
  ...props
}) => (
  <div style={{ maxHeight: 400 }}>
    <TextField
      margin="normal"
      label="Produkt navn"
      type="text"
      name="name"
      value={name || ""}
      onChange={onChange}
      fullWidth
    />

    {/* <TextField
      margin="normal"
      label="Produkt beskrivelse"
      type="text"
      name="description"
      value={description || ""}
      onChange={onChange}
      InputProps={{
        multiline: true,
      }}
      fullWidth
    /> */}

    <br />
    <br />

    <Accordion>
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls="panel1a-content"
        id="panel1a-header"
      >
        <Typography>Yderligere information</Typography>
      </AccordionSummary>
      <AccordionDetails
        style={{
          display: "inline-block",
          width: "100%",
        }}
      >
        {multiProductFieldValues.map((data, index) => (
          <>
            <TextField
              key={index}
              id={index.toString()}
              data-key={index}
              label={data.fieldName}
              type="text"
              name={data.fieldName}
              value={data.fieldValue || ""}
              InputProps={{
                multiline: true,
              }}
              onChange={handleMultiProductFieldValuesChange}
              fullWidth
              style={{
                marginBottom: "20px",
              }}
            />
          </>
        ))}
      </AccordionDetails>
    </Accordion>
  </div>
);
