import { FilePond, registerPlugin } from "react-filepond";
import React, { useEffect, useState } from "react";
import SelectPhotoTypes from "../../../../shared/PhotoSelectionComponents/SelectPhotoType";
import {validateFileExtentionOnUpload} from "../../../../shared/SharedFunctions";

export default function UploadComponent(props) {
    const { photoTypeSelected, setPhotoTypeSelected, files, handleSetFile } = props;
    return (
        <div style={{ minHeight: 500 }}>
            <div style={{ display: "flex", alignItems: "center", justifyContent: "center", marginBottom: 20 }}>
                <SelectPhotoTypes
                    selection={photoTypeSelected}
                    handleSelectPhotoType={(photoType) => setPhotoTypeSelected(photoType)}
                />
            </div>
            <FilePond
                allowImagePreview={true}
                files={files}
                onupdatefiles={(fileItems) => handleSetFile(fileItems)}
                allowMultiple={false}
                maxFiles={1}
                imagePreviewHeight={400}
                beforeAddFile={(item) => validateFileExtentionOnUpload(item.file)}
                labelIdle={
                    'Træk og slip din billedfil eller <span class="filepond--label-action">Gennemse</span>'
                }
            />
        </div>
    );
}