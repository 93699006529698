import React from "react";

// Redux
import { connect } from "react-redux";

// import Material components
import { Typography } from "@material-ui/core";

// Import components
import SearchBar from "./SearchBar";
import CustomToolbar from "./CustomToolbar";
import SortingSelecting from "./SortingSelecting";
import DialogCreate from "./DialogCreate";
import DialogEdit from "./DialogEdit";
import {
  cleanUsers,
  searchUsers,
  fetchUsers
} from "../../../redux/actions/users/users.actions";
import { fetchCustomers } from "../../../redux/actions/utils/customers.utils.actions";
import { checkCustomerState } from "../../../helpers/UtilsReducerHelper";
import DialogPermits from "./DialogPermits";

import { canReadApplication } from "../../../helpers/CredentialsHelper";
import { applications } from "../../../constants/app.constants";
import { LoadingLogo } from "../../shared/Loading";
import DialogDelete from "./DialogDelete";
import { Helmet } from "react-helmet";
import { stringIsEmpty } from "../../../helpers/UtilHelper";

// Actions

class Brugerstyring extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      queryString: "",
      storageKeyText: "search_text_users"
    };
  }

  componentDidMount() {
    if (canReadApplication(applications.BRUGERSTYRING)) {
      const searchText = localStorage.getItem(this.state.storageKeyText) ?? "";

      if (stringIsEmpty(searchText)) {
        this.props.dispatch(fetchCustomers());
      } else {
        this.setState({ queryString: searchText });
        this.props.dispatch(searchUsers(searchText));
      }
    }
  }

  handleChangeQueryString = event => {
    this.setState({ queryString: event.target.value });
  };

  keyPress = e => {
    if (e.keyCode === 13) {
      this.performSearch();
    }
  };

  performSearch = () => {
    if (this.state.queryString === "") {
      this.props.dispatch(fetchUsers());
      return;
    }
    this.props.dispatch(searchUsers(this.state.queryString));
    localStorage.setItem(this.state.storageKeyText, this.state.queryString);
  };

  componentWillUnmount() {
    //clear view
    this.props.dispatch(cleanUsers());
  }

  render() {
    if (canReadApplication(applications.BRUGERSTYRING)) {
      // let preloadState = checkCustomerState(this.props.utilsReducer);
      // if (preloadState === "Loading.") return <LoadingLogo />;
      // else if (preloadState != null)
      //   return (
      //     <Typography variant="subtitle1" align="center">
      //       {preloadState}
      //     </Typography>
      //   );

      return (
        <>
          <Helmet>
            <title>Brugere</title>
          </Helmet>
          <Typography variant="h4">Brugerstyring</Typography>
          <div
            style={{
              width: "100%",
              alignItems: "center",
              display: "flex"
            }}
          >
            <SearchBar
              queryString={this.state.queryString}
              handleChangeQueryString={this.handleChangeQueryString.bind(this)}
              performSearch={this.performSearch.bind(this)}
              keyPress={this.keyPress.bind(this)}
            />

            <CustomToolbar />
          </div>
          <SortingSelecting />
          {/* <DialogResetpassword /> */}
          {/* <DialogDelete /> */}
          {this.props.usersReducer.createDialog ? <DialogCreate /> : undefined}
          {this.props.usersReducer.editDialog ? <DialogEdit /> : undefined}
          {this.props.usersReducer.deleteDialog ? <DialogDelete /> : undefined}
          {this.props.usersReducer.permitsDialog ? <DialogPermits /> : undefined}
        </>
      );
    }
    else {
      return (
        <Typography variant="subtitle1" align="center">
          Du har ikke adgang til denne side. Kontakt support
          (mbsupport@republica.dk), hvis dette er en fejl.
        </Typography>
      );

    }
  }

}

export default connect(state => state)(Brugerstyring);
