import { endpoints } from "../../../constants/services.constants";
import { handleAPIErrors } from "../../../redux/Handlers/ErrorHandlers";
import { getToken } from "../../../helpers/AuthHelper";
import store from "../../../redux/store";
import { addToastMessage } from "../../../redux/actions/toasts.actions";
import Resources from "../../../Resources.json";
export const generateReceipts = async (form) => {
    if (!form) {
        return;
    }
    let result = false;

    const endpoint = endpoints.RECEIPTGENERATOR_DOWNLOAD_RECEIPTS;

    let options = {
        Accept: "application/json, application/xml, text/plain, text/html, *.*",
        Authorization: "Bearer " + getToken(),
        "x-organization-id": Resources.Organization
    };
    try {
        let response = await fetch(endpoint, {
            method: "POST",
            headers: options,
            body: form
        });

        if (!response.ok) {
            handleAPIErrors();
        }

        const contentDisposition = response.headers.get("content-disposition");
        const ext = contentDisposition.split(";")[1];
        const fileName = ext.split("=")[1].replace(/\"/g, "");

        let blob = await response.blob();

        let url = window.URL.createObjectURL(blob);
        let a = document.createElement("a");
        a.href = url;
        a.download = fileName;
        a.setAttribute('download', fileName);
        document.body.appendChild(a);
        a.click();

        result = true;
    }
    catch (error) {
        throw Error(error);
    }

    return result;
}

export const downloadTemplate = () => {
    const endpoint = endpoints.RECEIPTGENERATOR_GET_TEMPLATE;

    fetch(endpoint, {
        method: "get",
        headers: {
            Accept: "*.*",
            "Content-Type": "application/octet-stream",
            Authorization: "Bearer " + getToken(),
            "x-organization-id": Resources.Organization
        },
    }).then((response) => {

        const contentDisposition = response.headers.get("content-disposition");

        if (!contentDisposition) {
            store.dispatch(
                addToastMessage({
                    message: "Can not download file",
                    type: "error",
                    details: null
                })
            );
        }
        const ext = contentDisposition.split(";")[1];
        const filename = ext.split("=")[1].replace(/\"/g, "");
        response.blob().then((blob) => {
            let url = window.URL.createObjectURL(blob);
            let a = document.createElement("a");
            a.href = url;
            a.download = filename;
            a.setAttribute('download', filename);
            document.body.appendChild(a);
            a.click();
        });
    });
}