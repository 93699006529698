import { endpoints, apiGetParamsReplace } from "../../../constants/services.constants";
import { fetchGetWT, fetchPatchWT, fetchPostWT, _request } from "../../../helpers/HttpHelper";

export async function searchOffersWithCustomersFilters(searchText, numberOfRows, customers, includeOldOffers = false) {
  const searchRequest = {
    searchText: searchText,
    filters: {
      definitions: [],
      customerIds: customers == null ? [] : [...customers],
      IncludeOldOffers: includeOldOffers
    },
    startRow: 0,
    numberOfRows: numberOfRows
  };

  return searchOffers(searchRequest);
}

export async function getOffers(offerIds, includeProducts, includeFieldValues, includePhotos, includeLogos) {
  const queryParams = `includeProducts=${includeProducts}&includeFieldValues=${includeFieldValues}&includePhotos=${includePhotos}&includeLogos=${includeLogos}&asImported=true`
  return _request({
    method: "POST",
    endpoint: apiGetParamsReplace(endpoints.OFFER_MULTIPLE_V2, queryParams),
    body: JSON.stringify(offerIds),
    throwError: true
  });
}

export async function searchOffers(searchRequest) {
  return _request({
    method: "POST",
    endpoint: endpoints.SEARCH_OFFERS,
    body: JSON.stringify(searchRequest),
    throwError: true
  });
}

export async function getPublicationByOfferIds(offerIds) {
  const endpoint = endpoints.GET_ALL_PUBLICATION_BY_OFFERS;

  return fetchPostWT(
    endpoint,
    JSON.stringify(offerIds),
    (data) => {
      return data;
    }
  )
}

export async function getSinglePublicationFromSolr(publicationId) {
  return _request({
    method: "GET",
    endpoint: apiGetParamsReplace(endpoints.SEARCH_PUBLICATION_SINGLE, publicationId),
    throwError: true,
    skipBodyParsing: false
  });
}

export async function getOfferSegments(offerId) {
  return _request({
    method: "GET",
    endpoint: apiGetParamsReplace(endpoints.OFFER_GET_SEGMENTS, offerId)
  });
}


export function getOffer(offerId) {
  return _request({
    method: "GET",
    endpoint: apiGetParamsReplace(endpoints.OFFER_SINGLE_V2, offerId)
  });
}

export function updateOffer(offerId, dto) {
  return _request({
    method: "PATCH",
    body: JSON.stringify(dto),
    skipBodyParsing: true,
    endpoint: apiGetParamsReplace(endpoints.OFFER_UPDATE_V2, offerId)
  });
}

export function getOfferRankings(offerId) {
  return _request({
    method: "GET",
    endpoint: apiGetParamsReplace(endpoints.GET_RANKING_OFFER, offerId),
    acceptNull: true
  });
}

export function updateScores(offerId, scores) {
  return _request({
    method: "PATCH",
    body: JSON.stringify(scores),
    endpoint: apiGetParamsReplace(endpoints.PATCH_OFFER_SCORES, offerId)
  });
}

export async function getSingleOfferV2(id) {
  return _request({
    method: "GET",
    endpoint: apiGetParamsReplace(endpoints.OFFER_SINGLE_V2, id),
    throwError: true,
    skipBodyParsing: false
  });
}
