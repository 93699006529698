import { Typography } from '@material-ui/core';
import React from 'react';
import styled from "styled-components";

export function LogInError(props) {
    const { errorText } = props;

    const StyledErrorDiv = styled(Typography)`
    color: #ed2424;
    text-align: center;
    margin-top: 15px
    `;
    return (
        <>
            <StyledErrorDiv>
                {errorText}
            </StyledErrorDiv>
        </>
    )
}