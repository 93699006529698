import { FormControl, Input, InputLabel } from "@material-ui/core";
import React from 'react';

export function InputField(props) {
    const { labelText, name,
        type, placeHolder, value, handleInputChange, onKeyPress } = props;
    return (
        <FormControl
            margin="normal"
            required={true}
            fullWidth>
            <InputLabel
                htmlFor={name}
            >
                {labelText}
            </InputLabel>
            <Input
                id={name}
                autoComplete={name}
                autoFocus
                type={type}
                name={name}
                placeholder={placeHolder}
                value={value}
                onChange={handleInputChange}
                required={true}
                onKeyDown={(e) => onKeyPress ? onKeyPress(e) : undefined}
            />
        </FormControl>
    )
}