import { getToken } from "../../../helpers/AuthHelper";
import store from "../../../redux/store";
import { addToastMessage } from "../../../redux/actions/toasts.actions";
import Resources from "../../../Resources.json";

const { apiGetParamsReplace, endpoints } = require("../../../constants/services.constants");
const { _request } = require("../../../helpers/HttpHelper");



export const searchPhotoClippingOrders = async (searchString) => {
    let queryParam = "";
    if (searchString) {
        queryParam = `?searchText=${searchString}`;
    }
    return _request({
        method: "GET",
        endpoint: apiGetParamsReplace(endpoints.PHOTOCLIPPING_SEARCH, queryParam),
    });
}

export async function addNewPhotoClippingOrder(jobId, shadow, groupingId, file) {
    const formData = new FormData();
    formData.append("file", file);
    let queryParams = `?jobId=${jobId}&includeShadow=${shadow}`;
    if (groupingId) {
        queryParams += `&groupingId=${groupingId}`
    }


    return _request({
        method: "POST",
        endpoint: apiGetParamsReplace(endpoints.PHOTOCLIPPING_ADD_FILE, queryParams),
        body: formData,
        skipBodyParsing: true,
        throwError: true,
        skipErrorNotification: true,
        headers: {
            Accept: "application/json, application/xml, text/plain, text/html, *.*",
            Authorization: "Bearer " + getToken(),
            "x-organization-id": Resources.Organization
        }
    });
}

export const downloadFileFromClippingOrder = async (id) => {
    if (!id) {
        return;
    }

    const endpoint = apiGetParamsReplace(endpoints.PHOTOCLIPPING_DOWNLOAD, `${id}`);

    fetch(endpoint, {
        method: "get",
        headers: {
            Accept: "image/jpeg",
            "Content-Type": "image/jpeg",
            Authorization: "Bearer " + getToken(),
            "x-organization-id": Resources.Organization
        },
    }).then((response) => {

        const contentDisposition = response.headers.get("content-disposition");

        if (!contentDisposition) {
            store.dispatch(
                addToastMessage({
                    message: "Can not download file",
                    type: "error",
                    details: null
                })
            );
        }
        const ext = contentDisposition.split(";")[1];
        const filename = ext.split("=")[1].replace(/\"/g, "");
        response.blob().then((blob) => {
            let url = window.URL.createObjectURL(blob);
            let a = document.createElement("a");
            a.href = url;
            a.download = filename;
            a.setAttribute('download', filename);
            document.body.appendChild(a);
            a.click();
        });
    });
}

export const downloadFilesAsZip = async (groupingId) => {
    return _request({
        method: "GET",
        skipBodyParsing: true,
        endpoint: apiGetParamsReplace(endpoints.PHOTOCLIPPING_DOWNLOAD_ZIP, groupingId)
    });
}

export const validateJobNumber = async (jobNumber) => {
    return _request({
        method: "GET",
        endpoint: apiGetParamsReplace(endpoints.IS_VALID_JOB, jobNumber),
        throwError: true
    });
}