import React from "react";

import { connect } from "react-redux";

import Tooltip from "@material-ui/core/Tooltip";
import AddIcon from "@material-ui/icons/Add";
import { withStyles } from "@material-ui/core/styles";

import Fab from "@material-ui/core/Fab";

import { createDialogOpen } from "../../../redux/actions/users/users.actions";

const defaultToolbarStyles = {
  iconButton: {
    margin: "0px 0px 20px 15px"
  }
};

class CustomToolbar extends React.Component {
  handleClick = () => {
    this.props.dispatch(createDialogOpen());
  };

  render() {
    const { classes } = this.props;

    return (
      <>
        <Tooltip title={"Opret bruger"}>
          {/* <IconButton className={classes.iconButton} onClick={this.handleClick}>
            <AddIcon className={classes.deleteIcon} />
          </IconButton> */}
          <Fab
            size="small"
            color="primary"
            className={classes.iconButton}
            onClick={this.handleClick}
          >
            <AddIcon />
          </Fab>
        </Tooltip>
      </>
    );
  }
}

export default connect(state => state)(
  withStyles(defaultToolbarStyles)(CustomToolbar)
);
