import React, { useEffect, useState } from 'react'
import { makeStyles } from '@material-ui/core';
import { generatePngFromIframe } from '../../../businessLogic/Requests';
import { ActionButton, EditOfferField, OfferFields, EditOfferPoster} from '../index';
import EditGreenlandOfferContainer from "./EditGreenlandOfferContainer";
import { endpoints } from '../../../../../../../../constants/services.constants';
import { apiGetParamsReplace } from './../../../../../../../../constants/services.constants';

const useStyles = makeStyles({
    picture: {
        gridArea: "picture"
    },
    addBackground: {
        gridArea: "AddBackground"
    },
    saveButton: {
        gridArea: "Save"
    },
    seconds: {
        gridArea: "Seconds"
    },
    offerTitle: {
        gridArea: "OfferTitle"
    },
    offerDescription: {
        gridArea: "OfferDescription"
    },
    offerInfoText: {
        gridArea: "OfferInfoText"
    },
    offerPrice: {
        gridArea: "OfferPrice"
    },
    savingAmount: {
        gridArea: "SavingAmount"
    },
});

export default function EditGreenlandOfferContent({ 
    fields, 
    offerId, 
    offer, 
    addItem,
    customer,
    setTool,
    selectedBackground
}) {
    const classes = useStyles();
    // Values used in poster
    const [offerDescription, setOfferDescription] = useState("");
    const [offerPrice, setOfferPrice] = useState("");
    const [howMany, setHowMany] = useState("");
    const [savingAmount, setSavingAmount] = useState("");
    const [metric, setMetric] = useState("");

    const [videoDuration, setVideoDuration] = useState(4); // sec
    
    const photoCombination=apiGetParamsReplace( endpoints.PHOTOCOMBINATION_OFFERS_LUCKY,offerId,930,930,true);
    const backgroundUrl = endpoints.STORAGE_PUBLIC_FILMMAKER.replace("{path}", selectedBackground);

    const baseUrl = `${endpoints.FRONTENDPOSTER_TEMPLATE1}?customerId=${customer}&offerId=${offerId}&background=${backgroundUrl}&savingAmount=${savingAmount}&metric=${metric}&photo=${encodeURIComponent(photoCombination)}&offerPrice=${offerPrice}&offerHeader=${offerDescription}&offerId=${offerId}&howMany=${howMany}`;

    const [_offer, setOffer] = useState(undefined);
    const [url, setUrl] = useState(baseUrl);

    const [buttonText, setButtonText] = useState("Gem");
    // only re-render iframe when focus is out
    const [dataChanged, setDataChanged] = useState(false);

    useEffect(() => {
        setUrl(baseUrl);
    }, [dataChanged]);

    useEffect(() => {
        updateOffer(setOffer, offer, setOfferDescription, setOfferPrice, setHowMany, setMetric, setSavingAmount);
    }, [offer]);

    useEffect(() => {
        setDataChanged(true);
    }, []);

    const updatePosterState = (e) => {
        if (e.keyCode === 13) { // enter
            setDataChanged(!dataChanged);
        }
    };

    const generateImageFromIframe = async () => {
        setButtonText("Loading...");
        const response = await generatePngFromIframe(url, videoDuration, customer);
        // add poster to the video request body
        addItem(response);
        setButtonText("Gem");
    }
    
    const handleChangeValue = (newValue, field) => {
        switch (field) {
            case "offerDescription":
                setOfferDescription(newValue);
                break;
                case "offerPrice":
                    setOfferPrice(newValue);
                    break;
                    case "savingAmount":
                        setSavingAmount(newValue);
                        break;
                        default:
                            console.log("You are trying to update wrong field", field);
                        }
                    }
                    
    const fieldsMapGreenland = new Map([
        ["offerDescription", { value: offerDescription, className: classes.offerDescription }],
        ["savingAmount", { value: savingAmount, className: classes.savingAmount }],
        ["offerPrice", { value: offerPrice, className: classes.offerPrice }]]
    );

    function updateOffer(setOffer, offer, setOfferDescription, setOfferPrice, setHowMany, setMetric, setSavingAmount) {
        setOffer(offer);
        if (offer) {
            const offerMetric = offer.fieldValues.find(x => x.name === "varebeskrivendemaengde");
            setOfferDescription(offer.description || "");
            setOfferPrice(offer.prices.price || "");
            setHowMany(offer.offerPriceQuantityDescription || "");
            setMetric(offerMetric ? offerMetric.value : "");
            setSavingAmount(offer.prices.savingsMessage);
            setTimeout(function(){
                setDataChanged(!dataChanged);
            }, 0);
        }
    }

    return (
        <EditGreenlandOfferContainer>
            <OfferFields
                offerFieldsValues={fieldsMapGreenland}
                handleChangeValue={(newValue, text) => handleChangeValue(newValue, text)}
                updatePosterState={(e) => updatePosterState(e)}
            />
            <EditOfferPoster
                url={url}
                classes={[classes.poster]}
            />
            <div
                className={classes.addBackground}
                style={{
                    display: "flex",
                    justifyContent: "center"
                }}
            >
                <ActionButton
                text="Tilføj baggrund"
                onClick={() => setTool("background")} // will be added in v2
            />
            </div>
            <div
                className={classes.saveButton}
                style={{
                    display: "flex",
                    justifyContent: "center"
                }}>
                <ActionButton
                onClick={() => generateImageFromIframe()}
                disabled={buttonText === "Loading..." ? true : false}
                text={buttonText}
            />
            </div>
            <EditOfferField
                className={classes.seconds}
                placeHolder=" Antal sekunder"
                onChange={(newValue) => setVideoDuration(newValue)}
                fieldValue={videoDuration}
                onKeyPress={() => { }} // we do not update poster when duration is changed
            />
        </EditGreenlandOfferContainer>
    )
}
