import React, { Component } from "react";

// Redux imports
import { connect } from "react-redux";

import Grid from "@material-ui/core/Grid";

import { patchMaster } from "../../../../redux/actions/product/edit.products.actions";
import CardPictureItem from "./CardPictureItem";

import { LoadingLogo } from "../../../shared/Loading";
import {
  apiGetParamsReplace,
  endpoints,
} from "../../../../constants/services.constants";
import { fetchGetWT } from "../../../../helpers/HttpHelper";

// Dropzone new
import { registerPlugin } from "react-filepond";
import "filepond/dist/filepond.min.css";
import FilePondPluginImagePreview from "filepond-plugin-image-preview";
import "filepond-plugin-image-preview/dist/filepond-plugin-image-preview.min.css";
import FilePondPluginFileValidateType from "filepond-plugin-file-validate-type";
import { getPhotos } from "../../digitalmediamanagement/Requests";

import { IsFeatureEnabled, featureNames } from "../../../../helpers/FeaturesHelper";

// Register the plugins
registerPlugin(FilePondPluginImagePreview, FilePondPluginFileValidateType);

class PhotoTab extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: false,
      multiProductPhotos: [],
      multiPhotos: [],
      uploading: false,
    };
  }

  handleSetMaster = async (i) => {
    await this.props.dispatch(
      patchMaster(i.multiProductId, i.id, () => {
        this.setState({ selectedItem: i.id });
      })
    );
    
    await this.fetchData();
    this.props.updateMaster();
  };

  componentDidMount = () => {
    this.fetchData();
  };

  componentDidUpdate = async () => {
    if (this.props.productsReducer.reloadProductPhotos && !this.state.loading) {
      this.setState({ loading: true });
      await this.fetchData();
      this.setState({ loading: false });
    }
  }

  fetchData = async () => {

    var product = this.props.productsReducer.product;

    const endpoint = apiGetParamsReplace(endpoints.PRODUCT_GET_BY_PRODUCTNUMBER_CUSTOMER, product.productNumber, product.customerId == null ? "" : product.customerId);

    this.setState({ loading: true }, async () => {
      await fetchGetWT(
        endpoint,
        (data) => {
          
          this.setState({
            multiProductPhotos: data.multiProductPhotos,
          });

          this.setState(async () => {

            const photoIds = data.multiProductPhotos.map(m=>m.multiPhotoId)
            var photos = await getPhotos(photoIds);

            this.setState({ multiPhotos: photos });
            this.setState({ loading: false });   

          });    
        },
        (error) => {
          console.log(error);
          this.setState({ loading: false });
        }
      );
    });
  };

  handleRemoveLink(photoId) {
    const productPhotos = this.state.multiProductPhotos.filter(x => x.multiPhotoId !== photoId);
    this.setState({multiProductPhotos: productPhotos});
  }

  setDataCard(data) {
    return data.map((photo, index) => (
      <Grid item xs={3} key={photo.id}>
        <CardPictureItem
          photo={photo}
          handleSetMaster={() => this.handleSetMaster(photo)}
          loadingSetmaster={this.props.productsReducer.loadingSetmaster}
          multiProductPhotos={this.state.multiProductPhotos[index]}

          deletePhoto={(productPhotoId) => this.props.deletePhoto(productPhotoId)}
       
          unlinkPhoto={(photoId) => this.handleRemoveLink(photoId)}
          multiPhotos = {this.state.multiPhotos}
        />
      </Grid>
    ));
  }

  

  render() {
    if (this.state.multiProductPhotos === 0) return null;
    return (
      <>
        {this.state.loading ? <LoadingLogo /> :
          (
            <Grid container>
              <Grid container spacing={24} style={{ marginTop: 20 }}>
                {this.setDataCard(
                  this.state.multiProductPhotos
                )}
              </Grid>
            </Grid>
          )
        }

      </>
    );
  }
}

export default connect((state) => state)(PhotoTab);
