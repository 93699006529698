import React, { useState } from 'react';
import Button from '@material-ui/core/Button';
import Modal from '@material-ui/core/Modal';
import Paper from '@material-ui/core/Paper';
import Container from '@material-ui/core/Container';
import AddHelpPageContent from './AddHelpPageContent'

function AddHelpPage(props) {
  const { buttonText, helpPageObject, setHelppages } = props;
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  return (
    <div>
      <Button type="button" variant="contained" color="info" onClick={handleOpen}>
        {buttonText}
      </Button>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
        style={{ overflow: "scroll" }}
      >
        <Container maxWidth="lg" align="center">
          <div style={{ margin: "3rem" }} />
          <Paper>
            <div align="right" style={{margin: "1rem"}}>
              <Button type="button" variant="contained" color="primary" onClick={handleClose}>
                Gå tilbage
              </Button>
            </div>
            <AddHelpPageContent helpPageObject={helpPageObject} modalOpen={setOpen} setHelppages ={setHelppages}/>
          </Paper>
        </Container>
      </Modal>
    </div>
  );
}

export default AddHelpPage;