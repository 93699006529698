export const handleChangeState = (state, setState, event) => {
    switch (event.target.name) {
        case 'Navn':
            setState({ ...state, name: event.target.value });
            break;
        case 'Kunde':
            setState({ ...state, customerId: event.target.value });
            break;
        case 'Beskrivelse':
            setState({ ...state, description: event.target.value });
            break;
        default:
            console.log(`You are not allowed to edit ${event.target.name} field.`);
    }
}

export const handleAddTag = (state, setState, newTag) => {
    setState({ ...state, tags: [...state.tags, newTag] });
};

export const handleDeleteTag = (state, setState, tagToDelete) => {
    setState({ ...state, tags: state.tags.filter(x => x !== tagToDelete) });
};

export const handleAddEans = (productNumbers, setProductNumbers, newEan) => {
    setProductNumbers([...productNumbers, newEan]);
};

export const handleDeleteEans = (productNumbers, setProductNumbers, productNumberToDelete) => {
    const cloneGtins = [...productNumbers];

    const gtiN = cloneGtins.filter((m) => m.productNumber === productNumberToDelete);

    const index = cloneGtins.indexOf(gtiN[0]);
    cloneGtins[index] = { ...gtiN[0] };
    cloneGtins[index].IsDeleted = true;

    setProductNumbers(cloneGtins);
};