import React from 'react';
import { FormControl, FormHelperText, InputLabel, MenuItem, Select } from '@material-ui/core';
import { photoTypeEnum } from '../../../constants/enums';

export default function SelectPhotoTypes({ selection, handleSelectPhotoType }) {
    return (
        <FormControl>
            <InputLabel htmlFor="multiPhotoTypeId-id">
                Fototype
      </InputLabel>
            <Select
                value={selection}
                onChange={(e) => handleSelectPhotoType(e.target.value)}
                inputProps={{
                    name: "multiPhotoTypeId",
                    id: "multiPhotoTypeId-id"
                }}
                style={{ textAlign: "center", minWidth: 400 }}
            >
                <MenuItem value={photoTypeEnum.Pack.id}>
                    {photoTypeEnum.Pack.name}
                </MenuItem>
                <MenuItem value={photoTypeEnum.Environment.id}>
                    {photoTypeEnum.Environment.name}
                </MenuItem>

            </Select>
            {!selection && (
                <FormHelperText style={{ color: "#e61c1c" }}>
                    Skal være udfyldt
                </FormHelperText>
            )}
        </FormControl>
    );
}