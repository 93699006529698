import { getReadPermissions } from "../../../helpers/CredentialsHelper";

export function getPublicationFiltersForUi(allCustomers) {
    let filters = [];

    // customers
    const visibleCustomerIds = getReadPermissions("Publikationer");
    const customers = allCustomers.filter(x => visibleCustomerIds.includes(x.id));

    if(customers.length > 1){
      filters.push({
        key: "customer",
        displayName: "Kunde",
        options: customers.map(x => {
          return {
            key: x.id,
            displayName: x.name,
            selected: false
          }
        })
      });
    }

    return filters;
}

export function GetSearchCustomers(allCustomers, uiFilters){
  const visibleCustomerIds = getReadPermissions("Publikationer");

  if(uiFilters.length > 0){
    const filterOnCustomerIds = uiFilters[0].options.filter(option => option.selected)
    .map(option => option.key);
   
    const visibleFilters = filterOnCustomerIds.filter(key => visibleCustomerIds.includes(key));
    if(visibleFilters.length > 1)
    {
        return uiFilters.find(x => x.key === "customer").options;
    }
    return visibleFilters.map(x => {
      return {
        key: x,
        selected: true
      }});
  }
  return [];

  
}

export function getPublicationFiltersForRequest(uiFilters, electronic, allCustomers) {
    const customerOptions = GetSearchCustomers(allCustomers, uiFilters);
    return {
      customerIds: customerOptions.filter(x => x.selected).map(x => x.key),
      electronic: electronic
    };
}