import React, { useState } from 'react';
import { withStyles, Dialog, DialogTitle, DialogContent, DialogActions, TextField, Button, FormControl, InputLabel, Select, MenuItem, FormHelperText } from '@material-ui/core';
import clsx from 'clsx';
import { validateUrl } from '../../../../../helpers/UtilHelper';
import { collectionTypes } from '../../../../../constants/enums';

const styles = theme => ({
    input: {
        marginBottom: 35
    },
    actions: {
        display: "grid",
        gridTemplateColumns: "1fr 1fr 1fr 1fr 1fr",
        gridGap: "1rem",
        marginBottom: 30
    },
    actionButton: {
        width: 110,
        justifySelf: "end",
    },
    positionGroup: {
        gridColumn: 4
    },
    error: {
        color: "#f44336"
    }
});

const ColorButton = withStyles(theme => ({
    root: {
        marginRight: 10,
        backgroundColor: "#263238",
        color: "#ffffff",
        '&:hover': {
            backgroundColor: "#11171A",
            color: "#ffffff"
        },
    },
}))(Button);

function DialogAdd(props) {
    const [url, setUrl] = useState("");
    const [collectionName, setCollectionName] = useState("");
    const [errorUrl, setErrorUrl] = useState("");
    const [errorName, setErrorName] = useState("");
    const [errorType, setErrorType] = useState("");
    const [customerName, setCustomerName] = useState("");
    const [errorCustomer, setErrorCustomer] = useState("");

    const { open, handleClose, createNewCollection, allowedCustomers, classes } = props;

    const handleSave = () => {
        if (!collectionName) {
            setErrorName("Felter er påkrævet!");
            return;
        } else {
            setErrorName("");
        }

        if (!customerName) {
            setErrorCustomer("Felter er påkrævet!");
            return;
        } else {
            setErrorCustomer("");
        }

        if (!url) {
            setErrorUrl("Felter er påkrævet!");
            return;
        } else {
            setErrorUrl("");
        }

        if (!validateUrl(url)) {
            setErrorUrl("Adressen er ikke korrekt!");
            return;
        } else {
            setErrorUrl("");
        }

        let collection = {
            Name: {
                Name: collectionName
            },
            SourceUrl: {
                Url: url
            },
            Customer: {
                Name: customerName
            },
            Type: {
                Type: "multibasen"
            }
        }

        createNewCollection(collection);
        handleClose();
    }
    return (
        <>
            <Dialog
                open={open}
                onClose={handleClose}
            >
                <DialogTitle id="form-dialog-title">Opret collection</DialogTitle>
                <DialogContent>
                    <TextField
                        error={!!errorName}
                        helperText={errorName}
                        className={classes.input}
                        margin="dense"
                        label="Navn"
                        type="text"
                        name="name"
                        value={collectionName}
                        InputProps={{
                            multiline: true
                        }}
                        onChange={(e) => setCollectionName(e.target.value)}
                        fullWidth

                    />
                    {/* This collectionType is not in use rn because we have only one type currently */}
                    {/* <FormControl className={classes.input} fullWidth>
                        <InputLabel shrink htmlFor="typeId">Type</InputLabel>
                        <Select
                            fullWidth
                            value={typeId ? typeId : ""}
                            onChange={(e) => setTypeId(e.target.value)}
                            MenuProps={{ classes: { paper: classes.dropdownStyle } }}
                            inputProps={{
                                name: "typeId",
                                id: "typeId"
                            }}
                        >
                            {collectionTypes.map((type, index) => (
                                <MenuItem key={index} value={type.id}>
                                    {type.name}
                                </MenuItem>
                            ))
                            }

                        </Select>
                        {!!errorType && <FormHelperText className={classes.error}>{errorType}</FormHelperText>}
                    </FormControl> */}
                    <FormControl className={classes.input} fullWidth>
                        <InputLabel shrink htmlFor="customerName">Kunde</InputLabel>
                        <Select
                            fullWidth
                            value={customerName ? customerName : ""}
                            onChange={(e) => setCustomerName(e.target.value)}
                            MenuProps={{ classes: { paper: classes.dropdownStyle } }}
                            inputProps={{
                                name: "customerName",
                                id: "customerName"
                            }}
                        >
                            {allowedCustomers.map((customer, index) => (
                                <MenuItem key={index} value={customer.name}>
                                    {customer.name}
                                </MenuItem>
                            )
                            )
                            }
                        </Select>
                        {!!errorCustomer && <FormHelperText className={classes.error}>{errorCustomer}</FormHelperText>}
                    </FormControl>
                    <TextField
                        error={!!errorUrl}
                        helperText={errorUrl}
                        margin="dense"
                        label="Kilde URL"
                        type="text"
                        name="url"
                        value={url}
                        InputProps={{
                            multiline: true
                        }}
                        onChange={(e) => setUrl(e.target.value)}
                        fullWidth
                    />
                </DialogContent>
                <DialogActions>
                    <div className={classes.actions}>
                        <ColorButton className={clsx(classes.actionButton, classes.positionGroup)} onClick={handleClose} color="primary">
                            Annuller
                  </ColorButton>

                        <ColorButton className={classes.actionButton} variant="contained" onClick={handleSave} color="primary">
                            Opret
                  </ColorButton>
                    </div>
                </DialogActions>
            </Dialog>
        </>
    )

}

export default withStyles(styles)(DialogAdd)