import { changePasswordWithCode } from "../../../helpers/AuthHelper/AuthHelperV2";
import { stringIsEmpty } from "../../../helpers/UtilHelper";
import React from 'react';
import { LogInError } from "../logIn/components/LogInError";

const errorMessageEmptyField = "Alle felter skal udfyldes.";
const errorMessagePasswordsDoNOtMatch = "De 2 indtastede passwords matcher ikke.";
const messagePasswordIsChanged = "Dit password er nu blevet ændret!";

export async function handleChangePassWord(newPassWord, newPassWordRepeat,
    setValidationMessage, setValidationSuccess, userName, code, setLoading) {

    const inputIsInValid = validateChangePassWordFields(newPassWord, newPassWordRepeat);

    if (inputIsInValid) {
        setValidationSuccess(false);
        setValidationMessage(inputIsInValid);
        return;
    }

    setLoading(true);
    setValidationMessage(undefined);
    const response = await changePasswordWithCode(userName, newPassWord, code);

    handleResponse(response, setValidationSuccess, setValidationMessage, setLoading);
}

function validateChangePassWordFields(newPassWord, newPassWordRepeat) {
    let errorMessage = undefined;

    if (stringIsEmpty(newPassWord) || stringIsEmpty(newPassWordRepeat)) {
        errorMessage = errorMessageEmptyField;
    } else if (newPassWord.trim() !== newPassWordRepeat.trim()) {
        errorMessage = errorMessagePasswordsDoNOtMatch;
    }

    return errorMessage;
}

export function renderValidationMessage(validationMessage) {
    if (typeof validationMessage === "string") {
        return <LogInError
            errorText={validationMessage}
        />
    } else if (typeof validationMessage === "object") {
        return (
            <>
                {validationMessage.map(x => <LogInError
                    errorText={x.Description}
                    key={x.Code}
                />)}
            </>

        )
    } else return undefined;
}

function handleResponse(response, setValidationSuccess, setValidationMessage, setLoading) {
    const error = !response.success && response.message === "VALIDATION_ERROR"
        && response.messageJson !== null;

    let message = "";
    let validationFinal = false;

    if (response.success) {
        message = messagePasswordIsChanged;
        validationFinal = true;
    } else if (error) {
        message = JSON.parse(response.messageJson);
    } else {
        message = response.message;
    }

    setValidationSuccess(validationFinal);
    setValidationMessage(message);
    setLoading(false);

}