import React from 'react';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import { Link } from 'react-router-dom'

export default (props)=> {
 return   <Menu
      anchorEl={props.anchorEl}
      anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      transformOrigin={{ vertical: 'top', horizontal: 'right' }}
      open={props.open}
      onClose={props.handleMenuClose}
    >
    {/* <MenuItem onClick={props.handleMenuClose}>Profile</MenuItem>
    <MenuItem onClick={props.handleMenuClose}>My account</MenuItem> */}
      <MenuItem to={"/support"} component={Link} onClick={props.handleMenuSupport}>Support</MenuItem>
      <MenuItem onClick={props.handleMenuLogout}>Log ud</MenuItem>
    </Menu>
}
  