import {
  endpoints, apiGetParamsReplace
} from "../../../constants/services.constants";
import { fetchGetWT, fetchGet } from "../../../helpers/HttpHelper";


export const GET_GS1_IMAGES_BEGIN = "PHOTO_GS1_IMAGES_BEGIN";
export const GET_GS1_IMAGES_SUCCESS = "PHOTO_GS1_IMAGES_SUCCESS";
export const GET_GS1_IMAGES_FAILURE = "PHOTO_GS1_IMAGES_FAILURE";

export const gs1ImagesBegin = () => ({
  type: GET_GS1_IMAGES_BEGIN
});

export const gs1ImagesSuccess = gs1Images => ({
  type: GET_GS1_IMAGES_SUCCESS,
  payload: { gs1Images }
});

export const gs1ImagesFailure = error => ({
  type: GET_GS1_IMAGES_FAILURE,
  payload: { error }
});

export function gs1Images(productNumber,callback) {
  const endpoint =  apiGetParamsReplace(endpoints.PHOTO_GS1_IMAGES,[productNumber]);
  return dispatch => {
    dispatch(gs1ImagesBegin());
    return fetchGet(
      endpoint,
      json => {
        dispatch(gs1ImagesSuccess(json))
        callback(json);
      },
      error => {
         dispatch(gs1ImagesFailure(error));
        callback(error);
      },
      false
    );
  };
}
// ADD GS1 IMAGES TO PRODUCT
export const ADD_GS1_IMAGES_TO_PRODUCT_BEGIN = "PRODUCT_ADD_GS1_IMAGES_BEGIN";
export const ADD_GS1_IMAGES_TO_PRODUCT_SUCCESS = "PRODUCT_ADD_GS1_IMAGES_SUCCESS";
export const ADD_GS1_IMAGES_TO_PRODUCT_FAILURE = "PRODUCT_ADD_GS1_IMAGES_FAILURE";

export const AddGS1ImagesToProductBegin = () => ({
  type: ADD_GS1_IMAGES_TO_PRODUCT_BEGIN
});

export const AddGS1ImagesToProductSuccess = gs1Images => ({
  type: ADD_GS1_IMAGES_TO_PRODUCT_SUCCESS,
  payload: { gs1Images }
});

export const AddGS1ImagesToProductFailure = error => ({
  type: ADD_GS1_IMAGES_TO_PRODUCT_FAILURE,
  payload: { error }
});

export function AddGS1ImagesToProduct(productNumber,callback) {
  const endpoint =  apiGetParamsReplace(endpoints.PRODUCT_ADD_GS1_IMAGES,[productNumber]);
  return dispatch => {
    dispatch(AddGS1ImagesToProductBegin());
    return fetchGetWT(
      endpoint,
      json => {
        dispatch(AddGS1ImagesToProductSuccess(json))
        if(callback)
          callback(json);
      },
      error => dispatch(AddGS1ImagesToProductFailure(error))
    );
  };
}