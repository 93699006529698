import React, { useState } from 'react';
import { Dialog, DialogTitle, 
    DialogContent, TextField, DialogActions, withStyles, 
    Button, Checkbox, InputLabel, CircularProgress, TableRow, 
    TableCell, Typography, Table } from '@material-ui/core';
import { FilePond } from 'react-filepond';
import { showErrorNotification, showSuccessNotification, stringIsEmpty } from '../../../../helpers/UtilHelper';
import { addNewPhotoClippingOrder, validateJobNumber } from '../Requests';
import { v4 as uuidv4 } from 'uuid';
import createDialog from '../../../../DialogRenderer';

import DoneIcon from '@material-ui/icons/Done';
import ErrorIcon from '@material-ui/icons/Error';

import {validateFileExtentionOnUpload} from "../../../shared/SharedFunctions";

export async function openDialogAddPhotoClipping() {
    return createDialog(({ show, onSubmit, onDismiss }) => (
        <DialogAddPhotoClippingStyled
            open={show}
            onCancel={onDismiss}
            onConfirm={onSubmit}
        />
    ));
}

const styles = {
    checkBoxInput: {
        marginTop: 23,
        display: "inline-block",
        marginLeft: 10
    },
    filePond: {
        marginTop: 10
    }
}

const ColorButton = withStyles(theme => ({
    root: {
        marginRight: 10,
        backgroundColor: "#263238",
        color: "#ffffff",
        '&:hover': {
            backgroundColor: "#11171A",
            color: "#ffffff"
        },
    },
}))(Button);

const uploadStatesTypes = {
    AWAITING: "Awaiting",
    UPLOADING: "Uploading",
    COMPLETED: "Completed",
    FAILED: "Failed"
}

function DialogAddPhotoClipping(props) {
    const { classes, open, onCancel, onConfirm } = props;
    // data
    const [jobNumber, setJobNumber] = useState("");
    const [includeShadow, setIncludeShadow] = useState(false);
    const [files, setFiles] = useState([]);
    // validation
    const [errorJobNumber, setErrorJobNumber] = useState("");
    // state
    const [isUploading, setIsUploading] = useState(false);
    const [uploadStates, setUploadStates] = useState(null);

    const savePhotoClippingOrder = async () => {
        const formIsValid = await validateRequiredFields();
        if (!formIsValid) {
            return;
        }


        const states = files.map(x => uploadStatesTypes.AWAITING);
        setUploadStates(states);
        setIsUploading(true);

        const groupingId = files.length > 1 ? uuidv4() : null;

        for (let i = 0; i < files.length; i++) {
            try {
                states[i] = uploadStatesTypes.UPLOADING;
                setUploadStates([...states]);
                await addNewPhotoClippingOrder(jobNumber, includeShadow, groupingId, files[i]);

                states[i] = uploadStatesTypes.COMPLETED;
                setUploadStates([...states]);
            } catch (e) {
                states[i] = uploadStatesTypes.FAILED;
                setUploadStates([...states]);
            }
        }

        if (states.every(x => x === uploadStatesTypes.COMPLETED)) {
            onConfirm();
            showSuccessNotification("Filerne er blevet sendt til fritlægning");
        } else {
            const failedFiles = states.filter(x => x === uploadStatesTypes.FAILED);
            showErrorNotification(`${failedFiles.length} filer blev ikke korrekt uploadet. Upload venligst de fejlede filer igen`);
        }
    }

    const validateRequiredFields = async () => {
        let formIsValid = true;
        let jobNumberIsValid = false;

        if (stringIsEmpty(jobNumber)) {
            setErrorJobNumber("Skal udfyldes!");
        } else {
            setErrorJobNumber("");
        }

        if (jobNumber !== "") {
            jobNumberIsValid = await validateJobNumber(jobNumber);

            !jobNumberIsValid ? setErrorJobNumber("Invalidt jobnummer!") : setErrorJobNumber("");
        }

        if (files.length === 0) {
            formIsValid = false;
            showErrorNotification("Foto skal udfyldes");
        }

        stringIsEmpty(jobNumber) || !jobNumberIsValid || files.length === 0 ? formIsValid = false : formIsValid = true;

        return formIsValid;
    }

    const getUploadIcon = (uploadState) => {
        switch (uploadState) {
            case uploadStatesTypes.UPLOADING:
                return <CircularProgress size={20} />;
            case uploadStatesTypes.FAILED:
                return <ErrorIcon />;;
            case uploadStatesTypes.COMPLETED:
                return <DoneIcon />;;
            default:
                return null;
        }
    }

    return (
        <>
            <Dialog
                open={open}
                maxWidth="sm"
                onClose={() => onCancel()}
                fullWidth={true}
            >
                <DialogTitle id="form-dialog-title">Bestil fritlægning</DialogTitle>
                <DialogContent style={{ minHeight: 500, maxHeight: 500 }}>
                    <div >
                        <TextField
                            disabled={isUploading}
                            error={!!errorJobNumber}
                            helperText={errorJobNumber}
                            className={classes.input}
                            margin="dense"
                            label="Job nr."
                            type="text"
                            name="jobNumber"
                            value={jobNumber}
                            InputProps={{
                                multiline: false
                            }}
                            onChange={(e) => setJobNumber(e.target.value)}
                        />
                        <div className={classes.checkBoxInput}>
                            <Checkbox
                                disabled={isUploading}
                                checked={includeShadow}
                                onChange={(e) => setIncludeShadow(e.target.checked)}
                                inputProps={{
                                    name: "fritlaegges",
                                    id: "fritlaegges-id"
                                }}
                            />
                            <InputLabel htmlFor="fritlaegges-id" shrink={true} style={{ display: "inline" }}>
                                Inkluder skygge
                        </InputLabel>
                        </div>


                        {!isUploading ? (
                            // File selector
                            <div className="filePond" style={{ maxHeight: 350 }}>
                                <FilePond
                                    style={{ height: 350 }}                                 
                                    allowImagePreview={true}
                                    onupdatefiles={(files) => setFiles(files.map(x => x.file))}
                                    allowMultiple={true}
                                    imagePreviewHeight={100}
                                    beforeAddFile={(item) => validateFileExtentionOnUpload(item.file)}
                                    labelIdle={
                                        'Træk og slip din billedfil eller <span class="filepond--label-action">Gennemse</span>'
                                    }
                                />
                            </div>
                        ) : (
                            // Upload progress
                            <div style={{ marginTop: 20, maxHeight: 350 }}>
                                <Typography variant="h5" >
                                    Upload status
                                </Typography>
                                <Table>
                                    {uploadStates.map((state, i) => {

                                        return <TableRow key={i}>
                                            <TableCell>{files[i].name}</TableCell>
                                            <TableCell>
                                                {getUploadIcon(state)}
                                            </TableCell>
                                        </TableRow>
                                    })}
                                </Table>
                            </div>
                        )}

                    </div>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => onCancel()}>
                        Annuller
                    </Button>

                    <ColorButton className={classes.actionButton}
                        variant="contained"
                        onClick={savePhotoClippingOrder}
                        color="primary"
                        disabled={isUploading}>
                        Opret
              </ColorButton>
                </DialogActions>
            </Dialog>
        </>
    )
}
const DialogAddPhotoClippingStyled = withStyles(styles)(DialogAddPhotoClipping)
