import React, { useContext } from 'react';
import MUIDataTable from 'mui-datatables';
import { getShortDate } from '../../../../helpers/UtilHelper';
import ActionButtons from './ActionButtons';
import { FeedsContext } from '../contexts/FeedsContext';
import { CollectionsContext } from '../contexts/CollectionsContext';


function FeedsTable(props) {
    const { feeds: feedsState } = useContext(FeedsContext);
    const [feeds, setFeeds] = feedsState;
    const { collections: collectionsState } = useContext(CollectionsContext);
    const [collections, setCollections] = collectionsState;
    const columns = [
        {
            name: "Id",
            options: {
                filter: false,
                sort: false,
                display: false,
            }
        },
        {
            name: "Navn",
            options: {
                filter: false,
                sort: false,
                display: true,
            }
        },
        {
            name: "Collection",
            options: {
                filter: false,
                sort: false,
                display: true,
            }
        },
        {
            name: "Periode",
            options: {
                filter: false,
                sort: false,
                display: true,
            }
        },
        {
            name: "Aktive tilbud i perioden",
            options: {
                filter: false,
                sort: false,
                display: true,
            }
        },
        {
            name: "",
            options: {
                filter: false,
                sort: false,
                display: true,
            }
        },
    ]

    const options = {
        textLabels: {
            body: {
                noMatch: "",
                toolTip: "Sorter"
            },
            pagination: {
                next: "Næste side",
                previous: "Forrige side",
                rowsPerPage: "Feeds per side:",
                displayRows: "af"
            },
            selectedRows: {
                text: "valgt række(r)",
            }
        },
        filter: false,
        filterType: "checkbox",
        rowsPerPage: 15,
        print: false,
        responsive: "scrollFullHeight",
        download: false,
        viewColumns: false,
        search: false,
        selectableRows: true,
        expandableRows: false,
    }

    const getDataTable = () => {
        return feeds.map(item => {
            let collectionName = "";
            if (item.collectionId !== undefined) {
                let collection = collections.find(x => x.collectionId === item.collectionId);
                collectionName = collection.name.name;
            }

            return [
                item.feedId ? item.feedId : "",
                item.feedName.name ? item.feedName.name : "",
                collectionName,
                item.validPeriodForOffers.startDate && item.validPeriodForOffers.endDate ? getShortDate(item.validPeriodForOffers.startDate).toString() + " - " + getShortDate(item.validPeriodForOffers.endDate).toString() : "Ingen aktive tilbud",
                item.amountOfOffers !== undefined ? item.amountOfOffers.toString() : "",
                <ActionButtons item={item} handleOpenFeedBuilder={(id) => props.handleOpenFeedBuilder(id)} />
            ]
        }
        )
    }

    return (
        <>
            <MUIDataTable
                data={getDataTable(feeds)}
                columns={columns}
                options={options}
            />

        </>
    )
}
export default FeedsTable