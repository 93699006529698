import React from "react";

import { connect } from "react-redux";

// Material
import MessageIcon from "@material-ui/icons/Message";
import AddAPhotoIcon from "@material-ui/icons/AddAPhoto";
import ImageIcon from "@material-ui/icons/Image";
import IconButton from "@material-ui/core/IconButton";
import Tooltip from "@material-ui/core/Tooltip";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";

import { canDeleteApplication, canReadApplication, canReadApplicationForCustomer } from "../../../helpers/CredentialsHelper";

// Actions
import { uploadDialogOpen } from "../../../redux/actions/photos/uploads.fototrackers.actions";
import { phototrackerDialogOpen } from "../../../redux/actions/photos/fototracker.photos.actions";
import { applications } from "../../../constants/app.constants";
//import classes from "*.module.css";
import { withStyles } from "@material-ui/core";
import { openDialogFullsizePhoto } from "../../shared/dialogs/DialogFullsizePhoto";
import { openDialogConfirmDelete } from "../../shared/dialogs/DialogConfirm";
import { getMotivId, showSuccessNotification } from "../../../helpers/UtilHelper";
import { deletePhotoOrder } from "./SharedRequests";
import { openDialogPhotoOrderBriefs } from "./Dialogs/DialogPhotoOrderBriefs";
import { openDialogReference } from "./Dialogs/DialogReference";
import { openDialogLayout } from "./Dialogs/DialogLayout";

const styles = {
  hasMessages: {
    color: "#71af1e"
  }
}

class ActionButtons extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      expanded: false,
      checked: true,
      anchorEl: null,
      allowUploadPhoto: false,
      orderStatus: undefined
    };

  }

  handleEditClickOpen = id => {
    this.props.dispatch(phototrackerDialogOpen(id));
    this.props.getPhotoOrderToEdit(id);
    this.setState({ anchorEl: null });
  };

  handleUploadClickOpen = (id, customerId) => {
    this.props.dispatch(uploadDialogOpen(id, customerId));
  };
  handleSkitseClickOpen = photoOrderId => {
    openDialogLayout(photoOrderId)
    this.setState({ anchorEl: null });
  };
  
  handleReferenceClickOpen = photoOrderId => {
    openDialogReference(photoOrderId);
    this.setState({ anchorEl: null });
  };

  async handleDeleteClick() {
    const confirmed = await openDialogConfirmDelete("Slet fotobestilling", `Vil du slette fotobestilling med motiv-ID: ${getMotivId(this.props.photoOrder.photoId)} permanent?`);
    if (confirmed) {
      await deletePhotoOrder(this.props.photoOrder.id);
      showSuccessNotification("Fotobestillingen er blevet slettet");
    }
    this.setState({ anchorEl: null });
  };

  handleMessageClickOpen = photoOrderId => {
    openDialogPhotoOrderBriefs(photoOrderId);
  };
  handleDraftClickOpen = () => {
    this.dialogDraft.handleClickOpen();
  };
  handleAnchorClick = event => {
    this.setState({ anchorEl: event.currentTarget });
  };

  handleAnchorClose = () => {
    this.setState({ anchorEl: null });
  };

  checkAllowedActions = () => {
    const allowPhotoUploadStatuses = new Set(["ProductNotReceived", "ReadyForPhotography", "ProductReceived", "ReShoot"]);
    if (allowPhotoUploadStatuses.has(this.props.photoOrder.status)) {
      this.setState({ allowUploadPhoto: true });
    } else {
      this.setState({ allowUploadPhoto: false });
    }
  }

  componentDidMount() {
    this.checkAllowedActions();
    this.setState({ orderStatus: this.props.photoOrder.status });
  }

  // Update component when status of the order has been changed
  componentDidUpdate() {
    if (this.props.photoOrder.status !== this.state.orderStatus) {
      this.setState({ orderStatus: this.props.photoOrder.status });
      this.checkAllowedActions();
    }
  }

  render() {
    const { photoOrder } = this.props;
    return (
      <div style={{ width: 150 }}>
        {/*PHOTO BUTTON */}
        <Tooltip title={"Vis foto"}>
          <span>
            <IconButton

              onClick={() => openDialogFullsizePhoto(!photoOrder.reusePhotoId ? photoOrder.photoId : photoOrder.reusePhotoId)}
              className="float-left smaller_icon"
              color="primary"
              aria-label="Add"
            >
              <ImageIcon />
            </IconButton>
          </span>
        </Tooltip>


        {/*ADD PHOTO BUTTON */}
        <Tooltip title={"Upload foto"}>
          <span>
            <IconButton
              className="float-left smaller_icon"
              color="primary"
              aria-label="Add"
              onClick={() => this.handleUploadClickOpen(photoOrder.id, photoOrder.customerId)}
              disabled={!this.state.allowUploadPhoto}
            >
              <AddAPhotoIcon />
            </IconButton>
          </span>
        </Tooltip>


        {/* MESSAGE BUTTON */}
        <Tooltip title={"Samtale center"}>
          <span>
            <IconButton
              color="primary"
              className="float-left smaller_icon"
              aria-label="message"
              onClick={() => this.handleMessageClickOpen(photoOrder.id)}
            >
              <MessageIcon className={photoOrder.hasBriefs ? this.props.classes.hasMessages : undefined} />
            </IconButton>
          </span>
        </Tooltip>

        {/* Overflow Menu */}
        {
          !canReadApplication(applications.FOTOTRACKER) && !canDeleteApplication(applications.FOTOTRACKER) && !photoOrder.hasLayoutFiles && !photoOrder.hasReferenceFiles ?
            undefined :
            <Tooltip title={"Flere muligheder"}>
              <span>
                <IconButton
                  className="float-left smaller_icon"
                  color="primary"
                  aria-label="overflow button"
                  onClick={this.handleAnchorClick}>
                  <MoreVertIcon />
                </IconButton>
              </span>
            </Tooltip>
        }

        <Menu
          id="simple-menu"
          anchorEl={this.state.anchorEl}
          open={Boolean(this.state.anchorEl)}
          onClose={this.handleAnchorClose}
        >
          {
            canReadApplication(applications.FOTOTRACKER) ? <MenuItem onClick={() => this.handleEditClickOpen(photoOrder.id)}>Rediger bestilling</MenuItem>
              : undefined
          }
          {
            !photoOrder.hasLayoutFiles ? undefined :
              <MenuItem onClick={() => this.handleSkitseClickOpen(photoOrder.id)}>Se skitser</MenuItem>
          }
          {
            !photoOrder.hasReferenceFiles ? undefined :
              <MenuItem onClick={() => this.handleReferenceClickOpen(photoOrder.id)}>Se reference filer</MenuItem>
          }
          {
            canDeleteApplication(applications.FOTOTRACKER) ?
              <MenuItem onClick={() => this.handleDeleteClick()}>Slet permanent</MenuItem>
              : undefined
          }
        </Menu>
      </div>
    );
  }
}

export default connect(state => state)(withStyles(styles)(ActionButtons));
