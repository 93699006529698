import React, { useState } from 'react';
import { Typography, Button, withStyles, CssBaseline, Paper } from '@material-ui/core';
import AppLoginLayout from '../layout/AppLoginLayout';

const styles = theme => ({
    main: {
        width: 'auto',
        display: 'block', // Fix IE 11 issue.
        marginLeft: theme.spacing(3),
        marginRight: theme.spacing(3),
        [theme.breakpoints.up(400 + theme.spacing(3) * 2)]: {
            width: 400,
            marginLeft: 'auto',
            marginRight: 'auto',
        },
    },
    paper: {
        marginTop: theme.spacing(8),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        padding: `${theme.spacing(2)}px ${theme.spacing(3)}px ${theme.spacing(3)}px`,
    },
    submit: {
        marginTop: theme.spacing(3),
    },
    linkButton: {
        textTransform: 'none',
    },
    errorContainer: {
        color: "#ed2424",
        textAlign: "center",
        marginTop: 15
    },
    message: {
        marginTop: 24,
        textAlign: "center"
    }
});

const RedirectToLogIn = (props) => {

    const { classes } = props;
    const [baseState, setBaseState] = useState(false);

    const handleComeBackToLogin = () => {
        setBaseState(true);
    }

    return (<>
        {!baseState ?
            (<div className={classes.root}>
                <main className={classes.main}>
                    <CssBaseline />
                    <Paper className={classes.paper}>
                        <img
                            src="/images/mb2-logo2.png"
                            alt="MultibasenLogo"
                            style={{ width: 40 }}
                        />
                        <Typography component="p" variant="body1" className={classes.message}>
                            Du er blevet logget ud pga. inaktivitet.
                            <br />
                            Log venligst på igen.
                        </Typography>
                        <Button
                            fullWidth
                            variant="contained"
                            color="primary"
                            className={classes.submit}
                            onClick={handleComeBackToLogin}
                        >Tilbage til Login
                        </Button>
                    </Paper>
                </main>
            </div>)
            : <AppLoginLayout />}
    </>)

}

export default withStyles(styles)(RedirectToLogIn);