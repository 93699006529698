import React, { useEffect, useState } from "react";

import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button
} from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import createDialog from "../../../../../DialogRenderer";
import { getTemplates } from "../../Requests";
import { TemplateTypes } from "../../../../../constants/enums";
import {
  displaySelectedTemplate,
  handleGeneratePoster,
  handleSendToNewIndesign,
  initializeTemplates,
  mapPageNumberToSpreadNumber,
  setSelectedTemplateOnAllPages
} from "./businessLogic/Logic";
import SelectTemplate from "./components/SelectTemplate";
import {
  handleCheckPageChangePublication,
  handleCheckPageChangePoster,
  handleTemplateChange,
  selectPageOnFirstTemplateSelection
} from "./businessLogic/EventHandler";
import PageSelector from "./components/PageSelector";
import { LoadingLogo } from "../../../../shared/Loading";

const styles = {
  table: {
    textAlign: "center",
  }
};

//Perhaps use workbookId instead
export async function openDialogIndesign(spreads, jobId,
  multiCustomerId, indesignDialogType) {
  return createDialog(({ show, onSubmit, onDismiss }) => (
    <DialogIndesignStyled
      onConfirm={onSubmit}
      onCancel={onDismiss}
      spreads={spreads}
      jobId={jobId}
      show={show}
      multiCustomerId={multiCustomerId}
      indesignDialogType={indesignDialogType}>
    </DialogIndesignStyled>
  ));
}

function DialogIndesign(props) {

  const {
    onConfirm, onCancel, spreads,
    show, classes, jobId, multiCustomerId, indesignDialogType
  } = props;

  const [selectedPages, setSelectedPages] = useState([]);
  const [availableTemplates, setAvailableTemplates] = useState([]);
  const [selectedTemplates, setSelectedTemplates] = useState([]);
  const [loading, setLoading] = useState(false);

  const [pageSpreadLookup, setPageSpreadLookup] = useState(new Map());
  const [templateForAllPages, setTemplateForAllPages] = useState("");

  const indesignHeader = "Send til Indesign";
  const posterHeader = "Send til Indesign poster";
  const header = indesignDialogType === TemplateTypes.Publication ? indesignHeader : posterHeader;

  useEffect(() => {
      loadInitialData(multiCustomerId);
    
  }, []);

  useEffect(() => {
    if (templateForAllPages === "") {
      return;
    }

    setSelectedTemplateOnAllPages(spreads, setSelectedTemplates, templateForAllPages, setLoading, setSelectedPages);
  }, [templateForAllPages]);

  //Need to know if it is either a poster or a publication page. The templates we get will be different.
  const loadInitialData = async (multiCustomerId) => {
    setLoading(true);
    const lookup = mapPageNumberToSpreadNumber(spreads);
    setPageSpreadLookup(lookup);
    const _availableTemplates = await getTemplates(multiCustomerId, indesignDialogType);
    setAvailableTemplates(_availableTemplates);
    initializeTemplates(spreads, setSelectedTemplates, _availableTemplates, setLoading);
  }

  const handleSelectTemplate = (e) => {
    // automatically set page as selected on first template select
    selectPageOnFirstTemplateSelection(e, selectedPages, setSelectedPages);

    // set selected template
    handleTemplateChange(e, selectedTemplates, setSelectedTemplates);
  }

  const handleCheckPageChange = (event, selectedPages, setSelectedPages, spread) => {
    const func = indesignDialogType === TemplateTypes.Publication ? handleCheckPageChangePublication : handleCheckPageChangePoster;
    func(event, selectedPages, setSelectedPages, spread);
  }

  const isDisabled = selectedPages.length === 0;

  return (
    <Dialog
      open={show}
      onClose={() => onCancel()}
    >
      <DialogTitle>
        {header}
      </DialogTitle>
      <DialogContent
        className="customDialogContent"
        style={{ width: "600px" }}
      >
        {!loading ? (
          <>
          <div style={{
                marginLeft: 17,
                marginBottom: 20
          }}>
            <div>
            Vælg skabelon til alle sider
            </div>
            <SelectTemplate
              handleSelectionChanged={event =>
                setTemplateForAllPages(event.target.value)}
              value={templateForAllPages}
              availableTemplates={availableTemplates || []}
            /> 
          </div>
            {spreads.map(function (spread, index) {
            return (
              <div className={classes.table} key={index}>
                {/* Select template for all pages */}
                {/* User select template per page. */}
                <SelectTemplate
                  handleSelectionChanged={event =>
                    handleSelectTemplate(event)}
                  spread={spread[0]}
                  value={displaySelectedTemplate(spread[0] || '', selectedTemplates)}
                  selectedPages={selectedPages}
                  availableTemplates={availableTemplates}
                />
                <PageSelector
                  selectedPages={selectedPages}
                  spread={spread}
                  page={spread[0]}
                  handleCheckPageChange={(event) =>
                    handleCheckPageChange(event, selectedPages, setSelectedPages, spread)}
                  position="left"

                />
                <PageSelector
                  selectedPages={selectedPages}
                  spread={spread}
                  page={spread[1]}
                  handleCheckPageChange={(event) =>
                    handleCheckPageChange(event, selectedPages, setSelectedPages, spread)}
                  position="right"

                />
                <SelectTemplate
                  handleSelectionChanged={event =>
                    handleSelectTemplate(event)}
                  spread={spread[1]}
                  value={displaySelectedTemplate(spread[1] || '', selectedTemplates)}
                  selectedPages={selectedPages}
                  availableTemplates={availableTemplates}
                />
              </div>
            );
          })}
          </>

        ) :  <LoadingLogo />
        
        }

      </DialogContent>
      <DialogActions>
        <Button
          onClick={() => onCancel()}>
          Annuller
        </Button>
        {indesignDialogType === TemplateTypes.Publication ? (
        <Button
          disabled={isDisabled}
            onClick={() =>
              handleSendToNewIndesign(jobId, selectedPages, selectedTemplates, onConfirm, pageSpreadLookup)}
          color="primary">
          Generér Indesign
        </Button>
        ) : (
            <>
            <Button
              disabled={isDisabled}
                onClick={() => handleGeneratePoster(jobId, selectedPages, selectedTemplates, onConfirm, pageSpreadLookup)}
                color="primary">
                Generér Poster
              </Button>
          </>

        )}
      </DialogActions>
    </Dialog>
  );
}

const DialogIndesignStyled = withStyles(styles)(DialogIndesign)
