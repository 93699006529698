import React, { Component } from "react";

// Redux imports
import { connect } from "react-redux";

import Typography from "@material-ui/core/Typography";
import { Button, CardMedia, Grid, colors, Box } from "@material-ui/core";
import { gs1Images } from "../../redux/actions/gs1/gs1.actions";
import { LoadingLogo } from "./Loading";
import { getLongDate } from "../../helpers/UtilHelper";

class GS1Tab extends Component {
  constructor(props) {
    super(props);

    this.state = {
      gs1Message: "",
      gs1Images: [],
      gsclicked: true,
      file: "",
      Loading: false,
      currentImageurl: null,
    };
    this.setGS1Image = this.setGS1Image.bind(this);
  }

  componentDidMount() {
    if (
      this.props.productNumner !== null &&
      this.props.productNumner === undefined
    )
      return;
      
      this.fetchGs1();
    }
    
    setGS1Image = (imageurl) => {
      this.setState({ currentImageurl: imageurl }, async () => {
        await this.props.addGs1ImagesToProduct(imageurl, true);
      });
    };
    
    fetchGs1=()=>{
    let productNumber = this.props.productNumner;
    this.setState({ Loading: true }, () => {
      this.props.dispatch(
        gs1Images(productNumber, (json) => {
          if (typeof(json)==="string") {
            this.setState({
              gs1Message: "An error has been produced",
              Loading: false,
            });
          }
          if (typeof(json)==="object"&&json.Error && json.Error === 'No valid image for that GTIN exists') {
            this.setState({
              gs1Message: "Produktet har ingen billeder i GS1.",
              Loading: false,
            });
          } 
          else if (typeof(json)==="object"&&json.Error) {
            this.setState({
              gs1Message: "Kan ikke finde produkt i Gs1.",
              Loading: false,
            });
          } 
          else if (Array.isArray(json) && json.length === 0) {
            this.setState({
              gs1Message: "Produktet har ingen billeder i GS1.",
              Loading: false,
            });
          } 
          else {
            this.setState({ gs1Images: json, Loading: false });
          }
        })
      );
    });
  }
  render() {
    const { selection, handleSelectPhotoType, isEdit } = this.props;

    return (
      <>
        <Grid
          container
          style={{ justifyContent: "center", marginTop: 20, minHeight: 300 }}
        >
          {this.state.Loading ? (
            <LoadingLogo />
          ) :  this.state.gs1Message!==""
          ||!Array.isArray( this.state.gs1Images) 
          ? (
            <Box>
            {/* <Typography>
              Error fetching GS1 photos 
            </Typography>  */}
            <Typography>
            {this.state.gs1Message}
            </Typography>
            <br/>
              <Button size="large" color="primary" variant="contained" onClick={this.fetchGs1}>Prøv igen</Button>
            </Box>
            )
          :(
            this.state.gs1Images.map((gs1Image) => {
              let imageurl = gs1Image.Url;

              return (
                <Grid
                  key={gs1Image.Url}
                  container
                  style={{ justifyContent: "center", width: 200, height: 320 }}
                >
                  <CardMedia
                    image={gs1Image.Url}
                    title={gs1Image.FileName}
                    style={{
                      width: 200,
                      height: 200,
                      backgroundSize: "contain",
                      margin: 20,
                    }}
                    key={gs1Image.FileName}
                  />
                  {gs1Image.DataSourceName}
                  <br/>
                  {getLongDate(gs1Image.QualificationDateTime)}
                  <br/>
                  {"Format:"+gs1Image.fileFormat}

                  <Grid container style={{ justifyContent: "center" }}>
                    <Button
                      variant="contained"
                      color={
                        this.state.currentImageurl !== imageurl
                          ? "primary"
                          : "secondary"
                      }
                      style={{ marginTop: 5 }}
                      onClick={() => this.setGS1Image(imageurl)}
                    >
                      {this.props.title}
                    </Button>
                  </Grid>
                </Grid>
              );
            })
          )}
        </Grid>
      </>
    );
  }
}
export default connect((state) => state)(GS1Tab);
