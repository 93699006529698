import { Dialog } from "@material-ui/core";
import { withStyles } from "@material-ui/styles";
import React from "react";
import { onButtonClick } from "./Logic";
import SliderButton from "./sliderButton";
import SliderImage from "./sliderImage";
import SliderPageMenu from "./sliderPageMenu";

const styles = theme => ({
    dialogRoot: {
        height: '100vh',
        backgroundColor: 'rgb(34, 39, 43)',
    },
    contentHolder: {
        height: 'calc(100% - 70px - 20px)'
    },
    sliderWrapper: {
        height: '100%',
        width: '100%',
        display: 'flex'
    }
});

function DialogPreviewPublicationUI({ classes, handleClose, pageMenuPosition,
    imageIndex, images, setImageIndex
}) {
    return (
        <Dialog
            fullScreen
            open={true}
            onClose={() => handleClose()}>
            <div className={classes.dialogRoot}>
                {pageMenuPosition === 'top' ?
                    <SliderPageMenu
                        currentPage={imageIndex + 1}
                        pageCount={images.length}
                        handleClose={() => handleClose()}
                    /> : <></>}
                <div className={classes.contentHolder}>
                    {imageIndex === 0 ? <></> :
                        <SliderButton
                            onButtonClick={() => onButtonClick(imageIndex, images.length, 'prev', false, setImageIndex)} />
                    }
                    <div className={classes.sliderWrapper}>
                        {imageIndex === 0 || (imageIndex === (images.length - 1)) ?
                            <SliderImage image={images[imageIndex]} position='center' /> :
                            <>
                                <SliderImage image={images[imageIndex]} position='right' />
                                <SliderImage image={images[imageIndex + 1]} position='left' />
                            </>}
                    </div>
                    {imageIndex === images.length - 1 ? <></> :
                        <SliderButton right onButtonClick={() => onButtonClick(imageIndex, images.length, 'next', false, setImageIndex)} />
                    }
                </div>
                {pageMenuPosition === 'bottom' ? <SliderPageMenu /> : <></>}
            </div >
        </Dialog>
    )

}

export default (withStyles(styles)(DialogPreviewPublicationUI));