import { Tooltip, Typography } from "@material-ui/core";

import { canDeleteApplication, canReadApplication } from "../../../helpers/CredentialsHelper";
import React, { Component, useEffect, useState } from "react";
import AddIcon from "@material-ui/icons/Add";
import { connect } from "react-redux";
import { LoadingLogo } from "../../shared/Loading";
import { searchLogos } from "./Requests";
import { applications } from "../../../constants/app.constants";
import SearchBar from "../../shared/components/searchBar/SearchBar";
import DataTable from "./DataTable";
import { Fab } from "@material-ui/core";
import { openDialogAddLogo } from "./dialogs/DialogAddLogo";
import { withStyles } from "@material-ui/styles";
import { Helmet } from "react-helmet";
import { IsFeatureEnabled, featureNames } from "../../../helpers/FeaturesHelper";

const styles = {
  searchBar: {
    width: "100%",
    alignItems: "center",
    display: "grid",
    gridTemplateColumns: "auto 60px",
    marginBottom: 20
  },
  iconButton: {
    marginLeft: 10,
    marginTop: 5,
    marginBottom: 20
  }
}

function LogoSection({ classes }) {

  const [loading, setLoading] = useState(false);
  const [rows, setRows] = useState([]);
  const [queryString, setQueryString] = useState("");
  const storageKey = "search_logos";

  useEffect(() => {
    const cachedSearchText = localStorage.getItem(storageKey);
    if (cachedSearchText) {
      setQueryString(cachedSearchText);
      performSearch(cachedSearchText);
    }
  }, []);

  const performSearch = async (searchText) => {
    setQueryString(searchText);
    if (!loading) {
      setLoading(true);
      const data = await searchLogos(searchText);
      setRows(data);
      setLoading(false);
    }
    localStorage.setItem(storageKey, searchText);
  };

  const handleCreateLogo = async () => {
    const confirmed = await openDialogAddLogo();
    if (confirmed) {
      performSearch(queryString);
    }
  };

  if (IsFeatureEnabled(featureNames.Logos_Enabled) && canReadApplication(applications.LOGOSTYRING)) {
    const canDelete = canDeleteApplication(applications.LOGOSTYRING);

    return (
      <>
        <Helmet>
          <title>Logoer</title>
        </Helmet>
        <Typography variant="h4">Logoer</Typography>

        <div className={classes.searchBar}>
          <SearchBar
            onSearch={(searchText) => performSearch(searchText)}
            searchText={queryString}
          />
          {canDelete ? (
            <Tooltip title={"Opret logo"}>
              <Fab
                size="small"
                color="primary"
                className={classes.iconButton}
                onClick={() => handleCreateLogo()}
              >
                <AddIcon />
              </Fab>
            </Tooltip>
          ) : (
            ""
          )}
        </div>
        {loading ?
          <LoadingLogo /> : rows.length === 0 ? undefined :
            <DataTable
              data={rows}
              onDataChanged={() => performSearch(queryString)}
            />}
      </>
    );
  }
  else {
    return (
      <Typography variant="subtitle1" align="center">
        Du har ikke adgang til denne side. Kontakt support
          (mbsupport@republica.dk), hvis dette er en fejl.
      </Typography>
    )
  }
}

export default withStyles(styles)(LogoSection);