import { Typography } from '@material-ui/core';
import React from 'react';
import styled from "styled-components";

const DivStyled = styled.div`
border: 1px solid black;
width: 50px;
background-color: #eceff1;
margin: 3px;
&:hover {
    cursor: pointer;
    background: #cfd8dc;
},
textAlign: center
`;
export default function Page({ page }) {

    return (
        <DivStyled>
            <Typography
                variant="h4"
                key={page}>
                {page}
            </Typography>
        </DivStyled>
    )
}