import { getFileBase } from "../../../../../../helpers/UtilHelper";
import { updatePhoto } from "../../../Requests";
import { uploadPhoto } from "./Requests";

export function validateRequiredFields(validationErrors, setValidationErrors, state) {
    const validationMessages = { ...validationErrors };

    if (state.tags.length === 0) {
        validationMessages.tagsErrror = "Tags skal være udfyldt";
    } else {
        validationMessages.tagsErrror = "";
    }

    if (!state.customerId) {
        validationMessages.customerIdError = "Kunde skal være udfyldt";
    } else {
        validationMessages.customerIdError = "";
    }

    setValidationErrors(validationMessages);
    return validationMessages;
};

export async function handleSetFile(fileItems, setFiles, setImageBase64) {
    setFiles(fileItems.map(fileItem => fileItem.file));
    // get base64 to display it in a new tab
    const fileBase = await getFileBase(fileItems.map(fileItem => fileItem.file));
    setImageBase64(fileBase[0]);
};

export async function handleSave(
    validationErrors, setValidationErrors, state, files, photoType, productNumbers, setPhotoId, handleClose
) {
    // Validate required fields
    const validationResult = validateRequiredFields(validationErrors, setValidationErrors, state);
    if (Object.values(validationResult).find(x => x !== "")) {
        return;
    };

    // First step upload photo
    const formData = new FormData();
    formData.append("file", files[0]);
    formData.append("customerId", state.customerId);
    formData.append("Type", photoType);
    productNumbers.forEach((x, index) => formData.append(`RelatedProductIds[${index}]`, x.productId));

    const newMotiv = await uploadPhoto(formData);
    setPhotoId(newMotiv);
    // Update photo with the info from the form

    const photoDto = { ...state, photoType };
    await updatePhoto(newMotiv, photoDto);

    handleClose(newMotiv);
}