//import { SET_PUBLIKATION } from '../types/notifications';

import {
  CLEAN_SEARCH_PHOTOS,
  DELETE_OPEN_BEGIN,
  DELETE_CLOSE_BEGIN,
  DELETE_PHOTOS_BEGIN,
  DELETE_PHOTOS_BEGIN_SUCCESS,
  DELETE_PHOTOS_BEGIN_FAILURE,
} from "../actions/photos/search.photos.actions";

const initState = {
  photos: [],
  queryString: "",
  searchParameters: {},
  photoId: null,
  editDialog: false,
  loadEdit: false,
  errorEdit: null,
  loadingUpdate: false,
  errorUpdate: null,
  deleteDialog: false,
  deleteUndoDialog: false,
  loading: false,
  loadDeletePhotos: false,
  errorDeletePhotos: false,
};
export default (state = initState, action) => {
  switch (action.type) {

    //--------------------------------
    case DELETE_OPEN_BEGIN:
      return {
        ...state,
        photoIds: action.payload.photoIds,
        deleteDialog: true
      };
    case DELETE_CLOSE_BEGIN:
      return {
        ...state,
        photoIds: null,
        deleteDialog: false,
        reloadProductPhotos: false // it is possible to delete a photo from edit product dialog
      };
    //-------------------------------------------
    case DELETE_PHOTOS_BEGIN:
      return {
        ...state,
        loadDeletePhotos: true,
        errorDeletePhotos: null
      };
    case DELETE_PHOTOS_BEGIN_SUCCESS:
      return {
        ...state,
        loadDeletePhotos: false,
        reloadProductPhotos: true, // it is possible to delete a photo from edit product dialog
        // photos: state.photos.filter(
        //   photo => action.payload.photoIds.indexOf(photo.id) < 0
        // )
      };
    case DELETE_PHOTOS_BEGIN_FAILURE:
      return {
        ...state,
        loadDeletePhotos: false,
        errorDeletePhotos: action.payload.error
      };
    //-------------------------------------------
    case CLEAN_SEARCH_PHOTOS:
      return {
        ...state,
        loading: null,
        error: null,
        photos: null,
        queryString: null
      };


    default:
      return state;
  }
};