import {
  DELETE_OPEN_BEGIN,
  DELETE_CLOSE_BEGIN,
  DELETE_BEGIN,
  DELETE_BEGIN_SUCCESS,
  DELETE_BEGIN_FAILURE
} from "../actions/product/delete.products.actions";
import {
  EDIT_OPEN_BEGIN,
  EDIT_CLOSE_BEGIN,
  GET_PRODUCT_BEGIN,
  GET_PRODUCT_SUCCESS,
  GET_PRODUCT_FAILURE,
  PATCH_MASTER_BEGIN,
  PATCH_MASTER_SUCCESS,
  PATCH_MASTER_FAILURE,
  UPDATE_PRODUCT_BEGIN,
  UPDATE_PRODUCT_SUCCESS,
  UPDATE_PRODUCT_FAILURE
} from "../actions/product/edit.products.actions";
import {
  CLEAN_SEARCH_PRODUCTS,
  RELOAD_PRODUCT_PHOTOS,
} from "../actions/product/products.actions";
import { ADD_GS1_IMAGES_TO_PRODUCT_BEGIN, ADD_GS1_IMAGES_TO_PRODUCT_SUCCESS, ADD_GS1_IMAGES_TO_PRODUCT_FAILURE } from "../actions/gs1/gs1.actions";

const initState = {
  id: null,
  productName: null,
  deleteDialog: false,
  editDialog: false,
  product: null,
  products: [],
  queryString: "",
  saveLoading: false,
  updateLoading: false,
  deleteLoading: false,
  loadingSetmaster: false,
  productPerPage: 12,
  offset: 0,
  logos: [],
  totalCount: 0,
  loadingTotalCount: false,
  errorTotalCount: null,
  loading: false,
  loadingGS1Images: false,
  errorGS1Images: null,
  allowedCustomers: [],
  reloadProductPhotos: false
};

export default (state = initState, action) => {
  switch (action.type) {
    //-------------------------------------------
    case DELETE_OPEN_BEGIN:
      return {
        ...state,
        id: action.payload.id,
        productName: action.payload.productName,
        deleteDialog: true
      };
    case DELETE_CLOSE_BEGIN:
      return {
        ...state,
        id: null,
        productName: null,
        deleteDialog: false
      };
    //-------------------------------------------
    case EDIT_OPEN_BEGIN:
      return {
        ...state,
        id: action.payload.id,
        editDialog: true
      };
    case EDIT_CLOSE_BEGIN:
      return {
        ...state,
        id: null,
        editDialog: false,
        product: null,
        logos: []
      };
    //-------------------------------------------
    case GET_PRODUCT_BEGIN:
      return {
        ...state,
        loadingProduct: true,
        errorProduct: null,
        id: null // set it to null, to handle infinite renders in componentDidUpdate()
      };
    case GET_PRODUCT_SUCCESS:
      return {
        ...state,
        loadingProduct: false,
        product: action.payload.product,
        logos: action.payload.product.multiProductLogos
      };
    case GET_PRODUCT_FAILURE:
      return {
        ...state,
        loadingProduct: false,
        errorProduct: action.payload.error
      };
    //-------------------------------------------
    case PATCH_MASTER_BEGIN:
      return {
        ...state,
        loadingSetmaster: true,
        errorSetmaster: null
      };
    case PATCH_MASTER_SUCCESS:
      return {
        ...state,
        loadingSetmaster: false,
        product: {
          ...state.product,
          multiProductPhotos: action.payload.productPhotos
        }
      };
    case PATCH_MASTER_FAILURE:
      return {
        ...state,
        loadingSetmaster: false,
        errorSetmaster: action.payload.error
      };
    //-------------------------------------------
    case UPDATE_PRODUCT_BEGIN:
      return {
        ...state,
        updateLoading: true,
        error: null
      };
    case UPDATE_PRODUCT_SUCCESS:
      return {
        ...state,
        updateLoading: false
      };
    case UPDATE_PRODUCT_FAILURE:
      return {
        ...state,
        updateLoading: false,
        error: action.payload.error
      };
    //-------------------------------------------
    case DELETE_BEGIN:
      return {
        ...state,
        deleteLoading: true,
        error: null
      };
    case DELETE_BEGIN_SUCCESS:
      return {
        ...state,
        deleteLoading: false
      };
    case DELETE_BEGIN_FAILURE:
      return {
        ...state,
        deleteLoading: false,
        error: action.payload.error
      };
    //---------------------------------------------------------------------------------GS1 Images 
    case ADD_GS1_IMAGES_TO_PRODUCT_BEGIN:
      return {
        ...state,
        loadingGS1Images: true,
        errorGS1Images: null,
        gs1Images: [],
      };
    case ADD_GS1_IMAGES_TO_PRODUCT_SUCCESS:

      state.product.multiProductPhotos = state.product.multiProductPhotos.concat(action.payload.gs1Images);
      return {
        ...state,
        loadingGS1Images: false,
        errorGS1Images: null,
        gs1Images: action.payload.gs1Images
      };
    case ADD_GS1_IMAGES_TO_PRODUCT_FAILURE:
      return {
        ...state,
        loadingGS1Images: false,
        errorGS1Images: action.payload.error,
        gs1Images: []
      };
    //---------------------------------------------------------------------------------GS1 Images
    case RELOAD_PRODUCT_PHOTOS:
      return {
        ...state,
        reloadProductPhotos: action.payload.reload
      };

    case CLEAN_SEARCH_PRODUCTS:
      return {
        ...state,
        id: null,
        deleteDialog: false,
        editDialog: false,
        products: [],
        product: null,
        queryString: "",
        loadingSetmaster: false,
        logos: [],
        productPerPage: 12,
        offset: 0,
        gs1Images: [],
        loadingGS1Images: false,
        errorGS1Images: null,
      };
    //-------------------------------------------
    default:
      return state;
  }
};
