import React from "react";

import { isImage, showErrorNotification } from "../../../../helpers/UtilHelper";

// Redux imports
import { connect } from "react-redux";
import {
  uploadDialogClose,
  uploadImagePhotoOrder,
  uploadDialogRemoveId,
  updoadGs1PhotoUrl
} from "../../../../redux/actions/photos/uploads.fototrackers.actions";

import DialogTitle from "@material-ui/core/DialogTitle";
import Dialog from "@material-ui/core/Dialog";
import { Tabs, Tab, DialogContent, DialogActions, Button } from "@material-ui/core";

import { withStyles } from "@material-ui/core/styles";

// Dropzone new
import { registerPlugin } from "react-filepond";
import "filepond/dist/filepond.min.css";
import FilePondPluginImagePreview from "filepond-plugin-image-preview";
import "filepond-plugin-image-preview/dist/filepond-plugin-image-preview.min.css";
import FilePondPluginFileValidateType from "filepond-plugin-file-validate-type";
import { changeSelectedProductSuccess, refreshPhotoOrderList } from "../../../../redux/actions/photos/fototracker.photos.actions";
import UploadTab from "./../TabContainer/UploadTab";
import ChooseProductPicture from "./../TabContainer/ChooseProductPicture";
import { setOrderStatusReusedPhoto } from "../../../../redux/actions/photos/orderstatustypes.photos.actions";
import { cleanProductSearch } from "../../../../redux/actions/product/products.actions";
import ExpirePhotoForm from "./ExpirePhotoForm";
import { canReadApplicationForCustomer } from "../../../../helpers/CredentialsHelper";
import { applications } from "../../../../constants/app.constants";
import GS1Tab from "../../../shared/GS1Tab";

// Register the plugins
registerPlugin(FilePondPluginImagePreview, FilePondPluginFileValidateType);

const styles = theme => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper
  },
  tabsRoot: {
    borderBottom: "1px solid #e8e8e8"
  },
  tabsIndicator: {
    backgroundColor: "#1890ff"
  },
  tabRoot: {
    minWidth: 0
  }
});

class DialogUpload extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      fullWidth: true,
      maxWidth: "md",
      disableBackdropClick: true,
      orderId: null,
      isFotograf: null,
      filesTab: [],
      value: 0,
      statusTab: undefined,
      isDisabled: true,
      photoOrder: undefined,
      gs1Url: undefined
    };
  }

  componentDidUpdate() {
    if (this.props.uploadsFototrackerReducer.id !== null && this.props.uploadsFototrackerReducer.customerId != null) {

      this.setState(
        { 
          orderId: this.props.uploadsFototrackerReducer.id,
          isFotograf: canReadApplicationForCustomer(applications.FOTOGRAF, this.props.uploadsFototrackerReducer.customerId),
          photoOrder: this.props.photoOrder
        },
        () => {
          this.props.dispatch(uploadDialogRemoveId());
        }
      );
    }
  }

  handleClose = () => {
    this.props.dispatch(uploadDialogClose());
    this.props.dispatch(changeSelectedProductSuccess(null));
    this.setState({
      orderId: null,
      filesTab: [],
      isFotograf: null,
      statusTab: undefined,
      value: 0,
      isDisabled: true
    });
  };

  handleSetfile = (fileItems, status) => {
    this.uploadIsDisabled(this.state.value);

    const photoStatus = this.state.isFotograf ? 30 : 100;

    this.setState({
      filesTab: fileItems.map(fileItem => fileItem.file),
      statusTab: photoStatus
    });
  };

  handleChangeTab = (event, value) => {
    this.uploadIsDisabled(value);
    this.setState({ value });
    this.setState({ gs1Url: undefined });
  };

  handleSave = (overrideResolutionRequirements) => {
    switch (this.state.value) {
      case 0:
        const { filesTab, statusTab } = this.state;
        this.saveUploadedImage(filesTab, statusTab, overrideResolutionRequirements);
        break;
      case 1:
        if (this.props.fototrackerReducer.selectedproduct) {
          this.handleChoosePicture();
        }
        break;
      case 2:
          if (this.state.gs1Url) {
            this.props.dispatch(updoadGs1PhotoUrl(this.state.orderId, this.state.gs1Url, () => {
                this.props.updateDataTable(this.state.orderId);
                this.handleClose();
                this.uploadIsDisabled();
            }));
          }
      break;
      default:
        return;
    }
  }

  saveUploadedImage = (files, status, overrideResolutionRequirements) => {
    if (files.length === 0) {
      showErrorNotification("Ingen filer er valgt");
    }
    else {
      let form = new FormData();
      let fileError = false;
      const file = files[0];
      if (isImage(file.name)) {
        form.append("file", file, file.name);
      } else {
        fileError = true;
      }

      if (fileError) {
        showErrorNotification("Ikke et korrekt filformat");
      }
      else {
        this.props.dispatch(uploadImagePhotoOrder(this.state.orderId, status, overrideResolutionRequirements, form, () => {

          if(!this.props.uploadsFototrackerReducer.lowResolution)
          {
            this.props.updateDataTable(this.state.orderId);
            this.handleClose();
            this.uploadIsDisabled();
          }
        }));
      }
    }
  }

  handleChoosePicture = () => {
    const reducer = this.props.fototrackerReducer;
    if (!reducer.selectedproduct) return;

    const product = reducer.selectedproduct;

    this.props.dispatch(
      setOrderStatusReusedPhoto(this.state.orderId, product.multiPhotoId));
    this.handleClose();
    this.uploadIsDisabled();
    this.props.updateDataTable(this.state.orderId);
  };

  uploadIsDisabled = (tabIndex) => {
    let isDisabled = true;
    let hasProduct = this.props.fototrackerReducer.selectedproduct;

    switch (tabIndex) {
      case 0: //upload 
        const { filesTab } = this.state;
        isDisabled = filesTab.length === 0 ? true : false;
        break;
      case 1: //reuse
        isDisabled = !hasProduct;
        break;
      case 2: //gs1
        isDisabled = !this.state.gs1Url;
      default:
        isDisabled = true;
    }
    this.setState({
      isDisabled: isDisabled
    });
  }

  setGs1Url = (url) => {

    this.setState({
      gs1Url: url,
      isDisabled: !url
    });


  }

  render() {
    const { classes, uploadsFototrackerReducer, fototrackerReducer } = this.props;
    const { value } = this.state;


    const hasSingleProduct = !this.state.photoOrder ? false : this.state.photoOrder.products.length == 1;

    const singleProductNumber = !hasSingleProduct ? null : this.state.photoOrder.products[0].productNumber;

    return (
      <>
        <Dialog
          aria-labelledby="addfile-dialog-title"
          fullWidth={true}
          maxWidth="md"
          open={this.props.uploadsFototrackerReducer.uploadDialog}
          onClose={this.handleClose}
        >
          <DialogTitle id="addfile-dialog-title">
            Tilføj billede | {this.state.orderId}
          </DialogTitle>
          <DialogContent>
            <Tabs
              value={value}
              onChange={this.handleChangeTab}
              classes={{
                root: classes.tabsRoot,
                indicator: classes.tabsIndicator
              }}
            >
              <Tab
                disableRipple
                classes={{
                  root: classes.tabRoot,
                  selected: classes.tabSelected
                }}
                label = {this.state.isFotograf ?  "FOTOGRAFUPLOAD" : "LEVERANDØRUPLOAD"}
             />
              <Tab
                disableRipple
                classes={{
                  root: classes.tabRoot,
                  selected: classes.tabSelected
                }}
                label="GENBRUG"
              />

              {hasSingleProduct &&

                <Tab
                  disableRipple
                  classes={{
                    root: classes.tabRoot,
                    selected: classes.tabSelected
                  }}
                  label="GS1 FOTO"
                />
              }
            </Tabs>

            {value === 0 && (
              <UploadTab
                uploadingFile={uploadsFototrackerReducer.uploadingFile}
                files={this.state.filesTab}
                handleSetfile={this.handleSetfile}
              />
            )}

            {value === 1 && (
              <ChooseProductPicture
                orderId={this.state.orderId}
                uploadIsDisabled={(value) => this.uploadIsDisabled(value)}
                value={this.state.value}
              />
            )}
            {hasSingleProduct && value === 2 && (
              <GS1Tab
                productNumner={singleProductNumber}
                addGs1ImagesToProduct={this.setGs1Url}
                uploadIsDisabled={(value) => this.uploadIsDisabled(value)}
                title="vælg billede"
              />
            )}

        {uploadsFototrackerReducer.lowResolution &&
            (<label style={{ color: '#FFA500' }}>{uploadsFototrackerReducer.lowResolutionMessage}</label> )
          }
          </DialogContent>

          <DialogActions>
            {uploadsFototrackerReducer.uploadingFile || fototrackerReducer.loadingSaveProduct ? (
              <Button disabled={true}>Sender...</Button>
            ) : (
              <>
                <Button onClick={this.handleClose}>
                  Annuller
                </Button>

                {uploadsFototrackerReducer.lowResolution ? (
                        <Button
                        disabled={this.state.isDisabled}
                        color="primary" autoFocus
                          onClick={() => this.handleSave(true)}
                          variant="contained">
                        Tilføj Alligevel
                      </Button>
 
                    )
                    : 
                    (
                      <Button
                      disabled={this.state.isDisabled}
                      color="primary" autoFocus
                        onClick={() => this.handleSave(false)}
                        variant="contained">
                      Tilføj
                    </Button>
                    )
                 }
              </>
            )}
          </DialogActions>
        </Dialog>
      </>
    );
  }
}

export default connect(state => state)(withStyles(styles)(DialogUpload));
