import React from "react";

import { connect } from "react-redux";

import SortingSelecting from "./SortingSelecting";
import { Typography, Paper, Button, Tooltip, IconButton } from "@material-ui/core";
import SearchBar from "./SearchBar";
import LastEanSearchBar from "./LastEanSearchBar";
import DialogUpload from "./Dialogs/DialogUpload";
import CustomToolBar from "./CustomToolBar";
import {
  phototrackerDialogClose
} from "../../../redux/actions/photos/fototracker.photos.actions";
import { canReadApplication, getReadPermissions } from "../../../helpers/CredentialsHelper";
import { applications } from "../../../constants/app.constants";
import { LoadingLogo } from "../../shared/Loading";
import './printStyles.css';
import { fetchCustomers } from "../../../redux/actions/utils/customers.utils.actions";
import { fetchPhotoOrderV2, getSinglePhotoOrderFromSolr, searchPhotoOrders } from "./SharedRequests";
import { CategotyFilter, TagFilter, CustomerFilter, DeletedFilter, PageFilter, PhotoTypeFilter, StatusFilter, HideUnusedFilter } from "./PhotoTrackerFilter/PhotoTrackerFilterProperties";
import { showErrorNotification, validateSearchString } from "../../../helpers/UtilHelper";
import { photoOrderTypeEnum, photoTypeEnum } from "../../../constants/enums";
import Pagination from "material-ui-flat-pagination";
import DialogEditPhotoOrder from "./Dialogs/DialogEditPhotoOrder/DialogEditPhotoOrder";
import { Helmet } from "react-helmet";
import { filterEnumOnValues } from "../../../helpers/EnumHelper";
import getProductCategories from "../produkstyring/Requests";
import DialogCommentTags from "./Dialogs/DialogCommentTags/DialogCommentTags";
import { openDialogSendPhotoOrderListe } from "./Dialogs/DialogSendPhotoOrderList";
import EmailIcon from "@material-ui/icons/Email";
import { IsFeatureEnabled, featureNames } from "../../../helpers/FeaturesHelper";

export const PACK_GROUP = "photo type pack";
export const ENVIRONMENT_GROUP = "photo type environment";
class Fototracker extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      searchText: "",
      eanSearchText: "",
      searchRowOffset: 0,
      rowsPerPage: 50,
      searchResult: null,
      searchIsActive: false,
      filterCustomer: [],
      filterStatus: ["ProductNotReceived"],
      filterPhotoTypes: [],
      filterTag: [],
      filterPages: "",
      filterCategories: [],
      filterDeleted: false,
      filterHideUnused: false,
      filterWithoutCategory: false,
      photoOrderCategories: [],
      photoOrderEdit: undefined,
      updateOrder: undefined,
      showCommentDialog: false,
    };
    this.handleChange = this.handleChange.bind(this);
    this.handleChangeFotoType = this.handleChangeFotoOrderType.bind(this);
    this.handleChangeCategories = this.handleChangeCategories.bind(this);
  }

  async componentDidMount() {
    if (canReadApplication(applications.FOTOTRACKER)) {
      let photoOrderId = this.props.match.params.photoOrderId;
      if (photoOrderId != null) {
        this.setState({ searchText: photoOrderId }, this.performSearch);
      }
      await this.props.dispatch(fetchCustomers());

      const categories = await getProductCategories();
      categories.sort((a, b) => a.name > b.name ? 1 : -1);

      this.setState({
        photoOrderCategories: categories,
      });
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (!this.state.loading && prevState.searchRowOffset !== this.state.searchRowOffset) {
      this.performSearch();
    }
  }

  getFilters() {
    const filters = {
      customerIds: this.state.filterCustomer,
      types: this.state.filterPhotoTypes.filter(x => x !== PACK_GROUP && x !== ENVIRONMENT_GROUP),
      statuses: this.state.filterStatus,
      pageNumbers: this.getPageFilter(),
      tags: this.state.filterTag,
      categoryIds: this.state.filterCategories.map(x => x.id),
      onlyDeleted: this.state.filterDeleted,
      hideUnused: this.state.filterHideUnused,
    };
    return filters;
  }

  getPageFilter() {
    const pageFilterText = this.state.filterPages.trim();
    if (pageFilterText === "") {
      return [];
    } else {
      // try parse page number text
      try {
        return this.parsePageFilterText(pageFilterText);
      } catch {
        showErrorNotification("Filteret sidenr er forkert");
        return [];
      }
    }
  }

  parsePageFilterText(pageFilterText) {
    const pageNumbers = [];
    for (const text of pageFilterText.split(",")) {
      const split = text.split("-");
      if (split.length === 1) {
        pageNumbers.push(parseInt(split[0]));
      } else {
        const min = parseInt(split[0]);
        const max = parseInt(split[1]);
        for (let i = min; i <= max; i++) {
          pageNumbers.push(i);
        }
      }
    }
    return pageNumbers;
  }


  handleChangeSearchText = event => {
    this.setState({ searchText: event.target.value });
  };


  handleChangeEanSearchText = event => {
    this.setState({ eanSearchText: event.target.value });
  };


  handleChange(e) {
    let change = {}
    change[e.target.name] = e.target.value;
    this.setState(change);
  }

  selectOrdersWithoutCategory(e) {
    this.setState({ filterWithoutCategory: e.target.checked });
  }

  handleChangeCategories(e) {
    let change = {}
    let selections = e.target.value;
    if (selections.includes(1000)) {
      selections = this.state.photoOrderCategories
    } else if (selections.includes(1001)) {
      selections = this.state.photoOrderCategories.filter(x => x.name !== "Tekstil");
    } else if (selections.includes(1002)) {
      selections = [];
    }
    change[e.target.name] = selections;
    this.setState(change)
  }


  handleChangeFotoOrderType(e) {
    let selections = e.target.value;

    let environmentPhotoOrderTypes = filterEnumOnValues(photoOrderTypeEnum, x => x.photoType === photoTypeEnum.Environment);
    let packPhotoOrderTypes = filterEnumOnValues(photoOrderTypeEnum, x => x.photoType === photoTypeEnum.Pack);

    // if we selected/deselected the Pack or Environment photo type it should select/deselect all photo order types for that
    const oldSelections = this.state.filterPhotoTypes;
    if (selections.includes(ENVIRONMENT_GROUP) && !oldSelections.includes(ENVIRONMENT_GROUP)) {
      selections = this.arrayUnique(selections.concat(environmentPhotoOrderTypes));
    }
    else if (!selections.includes(ENVIRONMENT_GROUP) && oldSelections.includes(ENVIRONMENT_GROUP)) {
      selections = selections.filter(x => !environmentPhotoOrderTypes.includes(x));
    }

    if (selections.includes(PACK_GROUP) && !oldSelections.includes(PACK_GROUP)) {
      selections = this.arrayUnique(selections.concat(packPhotoOrderTypes));
    }
    else if (!selections.includes(PACK_GROUP) && oldSelections.includes(PACK_GROUP)) {
      selections = selections.filter(x => !packPhotoOrderTypes.includes(x));
    }
    this.setState({ filterPhotoTypes: selections })
  }

  arrayUnique(array) {
    var a = array.concat();
    for (var i = 0; i < a.length; ++i) {
      for (var j = i + 1; j < a.length; ++j) {
        if (a[i] === a[j])
          a.splice(j--, 1);
      }
    }

    return a;
  }

  keyPress = e => {
    // Make sure that nothing is being searched currently.
    if (!this.state.loading) {
      if (e.keyCode === 13) {
        this.handleOnNewSearch();
      }
    }
  };

  handleOnNewSearch() {
    this.setState({ searchRowOffset: 0, loading: true }, () => this.performSearch())
  }

  async handleSendToListClick() {
    await openDialogSendPhotoOrderListe(this.state.filterStatus, this.state.filterCategories, []);
  }


  async performSearch() {
    const textIsValid = validateSearchString(this.state.searchText);
    if (!textIsValid) {
      showErrorNotification("Ugyldig søgetekst");
      this.setState({
        searchText: ""
      });

      return;
    }
    try {
      this.setState({ searchIsActive: true, loading: true });

      // construct search request
      const searchRequest = {
        searchText: this.state.searchText,
        last6Ean: this.state.eanSearchText,
        filters: this.getFilters(),
        startRow: this.state.searchRowOffset,
        numberOfRows: this.state.rowsPerPage
      };

      const searchResult = await searchPhotoOrders(searchRequest);
      this.setState({ loading: false, searchResult: searchResult });
    } catch (e) {
      showErrorNotification("Search failed");
      this.setState({ loading: false, searchResult: null });
    }
  };

  // this is a temporary call to edit a photo order, until other dialogs are also rewritten
  getPhotoOrderToEdit = async (id) => {
    const order = await fetchPhotoOrderV2(id);
    this.setState({
      photoOrderEdit: order
    })
  }

  closeEditPhotoOrderDialog = () => {
    this.props.dispatch(phototrackerDialogClose());
    this.setState({
      photoOrderEdit: undefined
    })
  }

  updateDataTable = (photoOrderId) => {
    setTimeout(async () => {
      const photoOrder = await getSinglePhotoOrderFromSolr(photoOrderId);
      const stateSearchResult = { ...this.state.searchResult };
      const photoOrders = stateSearchResult.rows;
      const updatePhotoOrder = photoOrders.findIndex(x => x.id === photoOrderId);
      if (updatePhotoOrder !== undefined) {
        photoOrders[updatePhotoOrder] = photoOrder;
        this.setState({
          searchResult: stateSearchResult,
          updateOrder: photoOrder
        })

        setTimeout(() => {
          this.setState({
            updateOrder: undefined
          })
        }, 0)
      }
    }, 6000);
  }

  setShowCommentDialog = (id, showDialog) => {
    if (id) {
      this.getPhotoOrderToEdit(id);
      this.setState({
        showCommentDialog: showDialog
      });
    } else {
      this.setState({
        showCommentDialog: showDialog,
        photoOrderEdit: undefined
      });
    }
  }

  renderFilters() {
    const { classes } = this.props;

    const visibleCustomerIds = getReadPermissions("Fototracker");
    const customers = this.props.utilsReducer.customers ?
      this.props.utilsReducer.customers.filter(x => visibleCustomerIds.includes(x.id)) :
      [];

    return (
      <Paper style={{ padding: 20 }}  >
        <div className="filterContentHolder">
          <SearchBar
            queryString={this.state.searchText}
            handleChangeQueryString={this.handleChangeSearchText.bind(this)}
            performSearch={this.handleOnNewSearch.bind(this)}
            keyPress={this.keyPress.bind(this)}
            disabled={this.state.loading}
          />

          {/* temp, please fix me. */}
          <LastEanSearchBar
            queryString={this.state.eanSearchText}
            handleChangeQueryString={this.handleChangeEanSearchText.bind(this)}
            performSearch={this.handleOnNewSearch.bind(this)}
            keyPress={this.keyPress.bind(this)}
            disabled={this.state.loading}
          />
          <PageFilter
            handleChange={this.handleChange}
          />

          <div style={{ marginTop: 10, marginLeft: 30 }}>
            <Button
              variant="contained"
              color="primary"
              onClick={this.handleOnNewSearch.bind(this)}>
              Søg
            </Button>
          </div>

        </div>

        <div className="filterProperties">
          <CustomerFilter
            selectedCustomerIds={this.state.filterCustomer}
            handleChange={this.handleChange}
            allCustomers={customers}
          />
          <PhotoTypeFilter
            filterPhotoTypes={this.state.filterPhotoTypes}
            handleChangeFotoType={(e) => this.handleChangeFotoOrderType(e)}
          />
          <StatusFilter
            selectedStatuses={this.state.filterStatus}
            handleChange={this.handleChange}
          />

          <CategotyFilter
            selectedCategories={this.state.filterCategories}
            handleChangeCategories={this.handleChangeCategories}
            categories={this.state.photoOrderCategories}
            noCategorySelected={this.state.filterWithoutCategory}
            selectOrdersWithoutCategory={(e) => this.selectOrdersWithoutCategory(e)}
          />

          <TagFilter
            selectedTagFilters={this.state.filterTag}
            handleChange={this.handleChange}
          />

          <HideUnusedFilter
            filterHideUnused={this.state.filterHideUnused}
            handleChange={this.handleChange}
          />

          <DeletedFilter
            filterDeleted={this.state.filterDeleted}
            handleChange={this.handleChange}
          />
        </div>
      </Paper>
    );
  }


  FindOrderById(id)
  {
    if(this.state.searchResult === null) return null;

    for(var i = 0; i < this.state.searchResult.numberOfRows; i++){
      if(this.state.searchResult.rows[i].id == id){
        return this.state.searchResult.rows[i];
      }
    }
    return null;
  }


  render() {
    if (!IsFeatureEnabled(featureNames.PhotoOrders_Enabled) || !canReadApplication(applications.FOTOTRACKER)) {
      return (
        <Typography variant="subtitle1" align="center">
          Du har ikke adgang til denne side. Kontakt support
          (mbsupport@republica.dk), hvis dette er en fejl.
        </Typography>
      )
    }


    const reducer = this.props.fototrackerReducer;

    let searchState;

    if (!this.state.searchIsActive) {
      searchState = undefined
    } else if (this.state.loading) {
      searchState = <LoadingLogo />;

    } else if (!this.state.searchResult || this.state.searchResult.rows.length === 0) {
      searchState = <Typography variant="subtitle1" align="center">Ingen fotobestillinger fundet...</Typography>;
    } else {
      searchState = <>
        <SortingSelecting
          getPhotoOrderToEdit={(id) => this.getPhotoOrderToEdit(id)}
          dataRows={this.state.searchResult.rows}
          categories={this.state.photoOrderCategories}
          onSendToList={() => this.handleSendToListClick()}
          photoOrder={this.state.updateOrder}
          setShowCommentDialog={(id, showDialog) => this.setShowCommentDialog(id, showDialog)}
        />
        {this.state.searchResult.totalRows === this.state.searchResult.numberOfRows ? (
          undefined
        ) : (
          <div style={{ margin: 10 }}>
            <Pagination
              limit={this.state.rowsPerPage}
              offset={this.state.searchRowOffset}
              total={Math.min(this.state.searchResult.totalRows, 100 * this.state.rowsPerPage)}
              onClick={(event, offset) => this.setState({ searchRowOffset: offset })}
              centerRipple={true}
              style={{ textAlign: "center" }}
            />
          </div>
        )}
      </>
    }


    return (
      <>
        <Helmet>
          <title>Fototracker</title>
        </Helmet>
        <Typography variant="h4">Fototracker</Typography>
        <div style={{
          width: "100%",
          alignItems: "center",
          display: "grid",
          gridTemplateColumns: "auto 60px"
        }}>
          {this.renderFilters()}
          <div>
          <CustomToolBar
            categories={this.state.photoOrderCategories}
          />
            <Tooltip title={"Send liste"}>
              <IconButton
                onClick={() => this.handleSendToListClick()}
                style={{ marginLeft: 1 }}
              >
                <EmailIcon fontSize="large" color="primary" />
              </IconButton>
            </Tooltip>
          </div>
        </div>
        <br />
        {searchState}
        {!reducer.editDialog ? undefined :
          <DialogEditPhotoOrder
            categories={this.state.photoOrderCategories}
            photoOrder={this.state.photoOrderEdit}
            handleClose={() => this.closeEditPhotoOrderDialog()}
            updateDataTable={(id) => this.updateDataTable(id)}
          />
        }

        <DialogUpload
          photoOrder={this.FindOrderById(this.props.uploadsFototrackerReducer.id)}
          message={this.props.uploadsFototrackerReducer.error}
          file={this.props.uploadsFototrackerReducer.file}
          updateDataTable={(orderId) => this.updateDataTable(orderId)}
        />
        {this.state.showCommentDialog ?
          <DialogCommentTags
            setShowCommentDialog={(id, showDialog) => this.setShowCommentDialog(id, showDialog)}
            photoOrder={this.state.photoOrderEdit}
            updateDataTable={(id) => this.updateDataTable(id)}
          /> : undefined}
      </>
    );
  }
}

export default connect(state => state)(Fototracker);
