import { Typography } from "@material-ui/core";
import React from 'react';
import { LogInHeader } from "./LogInHeader";
import StyledButtonLogIn from "./StyledButton";

export default function PasswordSent(props) {
    const { setForgotPassword } = props;
    const forgotPasswordText = ["Har du glemt dit kodeord?"];
    return (
        <>
            <LogInHeader
                text={forgotPasswordText}
            />
            <div style={{
                marginTop: 24
            }}>
                <Typography
                    component="p"
                    variant="body1"
                >
                    Hvis du har en konto, vil der blive sendt en email med instruktioner til dig.
                </Typography>
            </div>
            <StyledButtonLogIn
                fullWidth
                variant="contained"
                color="primary"
                handleOnClick={() => setForgotPassword(false)}
                buttonText="Tilbage til Login"
            />
        </>
    )
}