import React from "react";
import { connect } from "react-redux";
import { IconButton, TextField, Tooltip, Typography, withStyles } from "@material-ui/core";
import Offer from "./Offer";
import { LoadingLogo } from "../../../shared/Loading";
import EditIcon from "@material-ui/icons/Edit";
import DoneIcon from '@material-ui/icons/Done';
import { validateUrl } from "../../../../helpers/UtilHelper";
import { UpdatePageContentDescription } from "../Requests";

const styles = (theme) => ({
  fab: {
    position: "fixed",
    bottom: theme.spacing(6),
    right: theme.spacing(6),
  },
  separator: {
    display: "grid",
    gridTemplateColumns: "auto 1fr",
    gridColumnGap: 5,
  },
  borderSeparator: {
    width: "100%",
    borderTop: "3px dashed #bbb",
  },
  NoPageTitle:{
    color: "#BBBBBB"
  }
});
class OffersContent extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      anchorEl: null,
      copyOfferId: null,
      offerCardVisible: false,
    };
    this.pageContainerRefs = [React.createRef(), React.createRef()];
  }

  componentDidMount() {
    this.props.onRef(this);
    this.props.onPageContainerRefs(this.pageContainerRefs);
  }

  showOfferCard = () => {
    this.setState({ offerCardVisible: !this.state.offerCardVisible });
  };

  SavePageDescription = (index,page) => {
      this.props.savePageContentDescription(index,page);
  }

  handlePageDescriptionChange = (index,value) =>{
    this.props.setPageContentDescription(index,value);
  }

  handleClickEditPageDescription = (index) => {
    this.props.togglePageContentDisabled(index);
  }

  renderOffersPerPage = (page, offers, index) => {
    const { classes, handleEditOffer } = this.props;

    
    //defining functions in an array instead of directly in the onclick because react will trigger both onClick functions otherwise. 
    let editIconFunctions = [ () => this.handleClickEditPageDescription(0), ()=> this.handleClickEditPageDescription(1)];
    let savePageContentFunctions = [(index,page) => this.SavePageDescription(index,page), () => this.SavePageDescription(index,page)];
    let pageDescriptionChangeFunctions = [(index,value) => this.handlePageDescriptionChange(index,value), (index,value) => this.handlePageDescriptionChange(index,value)]

    return (
      <div className="offerPage" data-pagenumber={page.pageNumber} ref={this.pageContainerRefs[index]} style={{ display: "grid" }}>
        <div style={{display: "flex", alignItems: "center"}}>
          <Typography variant="subtitle1" style={{ fontWeight: "bold", flexShrink:0 }}>
            Side {page.pageNumber}
          </Typography>
          <TextField
            margin="dense"
            disabled={page.isContentFieldDisabled || this.props.isReadOnlyMode}
            type="text"
            name="title"
            value={page.contentDescription}
            onChange={(ev) => pageDescriptionChangeFunctions[index](index,ev.target.value)}
            InputProps={{
                multiline: false
            }}
            fullWidth
            style={{marginLeft: "10px"}}
            
            />
      
         {
         this.props.isReadOnlyMode ? undefined :
         (page.isContentFieldDisabled?
            <Tooltip title={"Redigér titel"}>
            <IconButton
              
              color="primary"
              aria-label="edit"
              onClick={editIconFunctions[index]}
            >
            <EditIcon fontSize="small" />
            </IconButton>
          </Tooltip>
          :
            <Tooltip title={"Gem ændringer"}>
              <IconButton
                
                color="primary"
                aria-label="edit"
                onClick={() => savePageContentFunctions[index](index,page)}
              >
              <DoneIcon fontSize="small" />
              </IconButton>
            </Tooltip>)
          }
        
        </div>
        <hr className={classes.borderSeparator}></hr>

        {offers.length === 0 ? (
          <h4>Side uden tilbud</h4>
        ) :
        offers.map((o) => {
            let isExpandable = false;

            if (o.childItems != null && o.childItems.length > 0)
              isExpandable = true;
            else if (o.productItems != null && o.productItems.length > 0)
              isExpandable = true;

            return (
              <Offer
                offer={o}
                onOfferChange={this.props.onOfferChange}
                page={page}
                key={o.id}
                id={o.id}
                handlePhotoOrderClicked={() => this.handlePhotoOrderClicked(o)}
                isExpandable={isExpandable}
                handleCreatePhotoOrderOffer={(offer) => this.props.handleCreatePhotoOrderOffer(offer)}
                categories={this.props.categories}
                handleEditOffer={(id) => handleEditOffer(id)}
                deactivatedPhotos={this.props.deactivatedPhotos}
                updateDeactivatedPhotos={this.props.updateDeactivatedPhotos}
              />
            );
          })}
      </div>
    );
  };

  render() {
    const { fotohaandteringReducer } = this.props;
    if (fotohaandteringReducer.loadingOffers) {
      return <LoadingLogo />;
    }
    if (fotohaandteringReducer.error) {
      return <h4>{fotohaandteringReducer.error.message}</h4>;
    }

    return (
      <>
        <section
          style={{ display: "grid" }}
        >
          {this.props.pagesWithOffers.map((item, index) => (
            this.renderOffersPerPage(item.page, item.offers, index)
          ))}
          <br style={{ clear: "both" }} />
        </section>
      </>
    );
  }
}

OffersContent = connect((state) => state)(OffersContent);
export default withStyles(styles)(OffersContent);
