import React, { useState, useEffect } from 'react';
import Carousel from "../templates/Carousel";
import Asset from "../Asset";
import { endpoints, apiGetParamsReplace } from '../../../../../constants/services.constants';
import DialogPreviewImage from "../backgroundComponent/DialogPreviewImage";

export default function BackgroundCarousel({
    backgrounds,
    mediaSelection
}) {

    const [carouselData, setCarouselData] = useState([]);
    const [previewImageDialog, setPreviewImageDialog] = useState(null);

    function renderPreviewImageDialog() {
        return (
            <DialogPreviewImage 
                onClose={() => setPreviewImageDialog(null)}
                imgUrl={previewImageDialog}
            />
        );
    }
    useEffect(() => {
        if (!backgrounds) {
            return;
        }
        const data = backgrounds.map(x =>
            <Asset 
                key={x.id}
                item={x}
                // blocked, 
                setPreviewDialog={setPreviewImageDialog} 
                mediaSelection={mediaSelection} 
                isImage={true}
                setPreviewEndpoint={endpoints.STORAGE_PUBLIC_FILMMAKER.replace("{path}", x.urlRelative)} 
                deleteEndpoint={apiGetParamsReplace(endpoints.FILMMAKER_BACKGROUND_DELETE, x.id)}
            />);

        setCarouselData(data);
    }, [backgrounds]);

 
    return (
        <>
            <Carousel
                items={carouselData}
            />
            {!previewImageDialog ? undefined : renderPreviewImageDialog()}
        </>

        )
}