import React from "react";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import MonochromePhotosIcon from "@material-ui/icons/MonochromePhotos";
import PeopleIcon from "@material-ui/icons/People";
import SvgIcon from "@material-ui/core/SvgIcon";
import AssignmentTurnedInIcon from "@material-ui/icons/AssignmentTurnedIn";
import InfoIcon from "@material-ui/icons/Info";
import CreateIcon from "@material-ui/icons/Create";
import ShoppingCartIcon from "@material-ui/icons/ShoppingCart";
import PhotoCameraIcon from "@material-ui/icons/PhotoCamera";
import MotorcycleIcon from "@material-ui/icons/Motorcycle";
import ReceiptIcon from '@material-ui/icons/Receipt';
//import CakeIcon from "@material-ui/icons/Cake";
import StorefrontIcon from '@material-ui/icons/Storefront';
import MovieIcon from "@material-ui/icons/Movie";
import Folder from "@material-ui/icons/Folder";
import { NavLink, Link, withRouter } from "react-router-dom";
import { withStyles } from "@material-ui/core";
import { appThemeConstants, applications } from "../constants/app.constants";
import WidgetsIcon from "@material-ui/icons/Widgets";
import { canReadApplication } from "../helpers/CredentialsHelper";
import Collapse from '@material-ui/core/Collapse';
import HelpIcon from '@material-ui/icons/Help';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import MovieCreationIcon from '@material-ui/icons/MovieCreation';
import {customersDictionary} from "../constants/enums";
import store from "../redux/store";
import { fetchFeatures } from "../redux/actions/utils/features.utils.actions";
import { IsFeatureEnabled, featureNames } from "../helpers/FeaturesHelper";
import Resources from "../Resources.json";
import { features } from "../constants/services.constants";
import {GetResource} from "../helpers/ResourceHelper";

const styles = theme => ({
  listItem: {
    "&:hover": {
      backgroundColor: "unset"
    }
  },
  listText: {
    color: "rgba(255,255,255,0.8)", //blueGrey[50],
    fontSize: "0.875rem",
    fontFamily: "Noto Sans",
    fontWeight: "normal",
    "&:hover": {
      color: "#fafafa"
    }
  },
  listIcon: {
    color: "rgba(255,255,255,0.8)"
  },
  nested: {
    paddingLeft: theme.spacing(9),
    background: appThemeConstants.PRIMARY_2 + "!important"
  }
});

function HomeIcon(props) {
  return (
    <SvgIcon {...props}>
      <path d="M10 20v-6h4v6h5v-8h3L12 3 2 12h3v8z" />
    </SvgIcon>
  );
}

const CustomNavLink = React.forwardRef((props, ref) => (
  <NavLink innerRef={ref} {...props} />
));

class NavLinks0 extends React.Component {
  state = {
    publicationCollapseOpen: true,
    dcoOpen: true,
    photosOpen: true,
    generateReceiptOpen: true,
    filmMakerOpen: true
  };

  handleClickPublicationCollapseOpen = () => {
    this.setState({
      publicationCollapseOpen: !this.state.publicationCollapseOpen
    });
  };

  handleClickOpenDco = () => {
    this.setState({ dcoOpen: !this.state.dcoOpen });
  }

  handleClickOpenPhotos = () => {
    this.setState({ photosOpen: !this.state.photosOpen });
  }

  handleClickOpenGenerateReceipt = () => {
    this.setState({ generateReceiptOpen: !this.state.generateReceiptOpen });
  }
  handleClickOpenFilmMaker = () => {
    this.setState({ filmMakerOpen: !this.state.filmMakerOpen });
  }


  listItemGeneric(to, text, icon) {
    const { classes } = this.props;
    return (<ListItem
      component={CustomNavLink}
      to={to}
      activeClassName="activeRoute"
      button
      className={classes.listItem}
      name={text}
    >
      <ListItemIcon>
        {icon === "InfoIcon" ?
          <InfoIcon className={classes.listIcon} /> :
          icon === "PeopleIcon" ?
            <PeopleIcon className={classes.listIcon} /> :
            icon === "MonochromePhotosIcon" ?
              <MonochromePhotosIcon className={classes.listIcon} /> :
              icon === "PhotoCameraIcon" ?
                <PhotoCameraIcon className={classes.listIcon} /> :
                icon === "HomeIcon" ?
                  <HomeIcon className={classes.listIcon} /> :
                  icon === "StorefrontIcon" ?
                    <StorefrontIcon className={classes.listIcon} /> :
                    icon === "MotorcycleIcon" ?
                      <MotorcycleIcon className={classes.listIcon} /> :
                      icon === "Folder" ?
                        <Folder className={classes.listIcon} /> :
                        icon === "ShoppingCartIcon" ?
                          <ShoppingCartIcon className={classes.listIcon} /> :
                          icon === "CreateIcon" ?
                            <CreateIcon className={classes.listIcon} /> :
                            icon === "WidgetsIcon" ?
                              <WidgetsIcon className={classes.listIcon} /> :
                              icon === "HelpIcon" ?
                                <HelpIcon className={classes.listIcon} /> :
                                icon === "MovieCreationIcon" ?
                                  <MovieCreationIcon className={classes.listIcon} /> :
                                  <InfoIcon className={classes.listIcon} /> 
        }
      </ListItemIcon>
      <ListItemText
        primary={text}
        classes={{ primary: classes.listText }}
      />
    </ListItem>
    );
  }



  listItemFotohaandtering(to, text) {
    const classes = this.props.classes;
    // if (canReadApplication(applications.FOTOHANDTERING))

    if(!IsFeatureEnabled(featureNames.Publications_Digital_Enabled)){
      return (
        <>

        <ListItem component={CustomNavLink}
        to="/publikationer"
        className={classes.listIcon}
        activeClassName="activeRoute" button >
                          <ListItemIcon>
          <AssignmentTurnedInIcon className={classes.listIcon} />
        </ListItemIcon>
        <ListItemText primary={GetResource(Resources.MainMenu_Publications_Publications_Title)} classes={{ primary: classes.listText }} />
      </ListItem>
      </>
      );
    }

    return (

      <>
        <ListItem onClick={this.handleClickPublicationCollapseOpen} button>
          <ListItemIcon>
            <AssignmentTurnedInIcon className={classes.listIcon} />
          </ListItemIcon>
          <ListItemText primary={GetResource(Resources.MainMenu_Publications_Title)} name="buttonNavigatePublicationDropdown" classes={{ primary: classes.listText }} />
          {this.state.publicationCollapseOpen ?
            <ExpandLess className={classes.listIcon} />
            : <ExpandMore className={classes.listIcon} />}
        </ListItem>

        <Collapse in={!this.state.publicationCollapseOpen} timeout="auto" unmountOnExit>
          <List component="div" disablePadding>
            <ListItem component={CustomNavLink}
              to="/publikationer"
              className={[classes.nested, classes.listItem].join(" ")}
              activeClassName="activeRoute" button name="buttonPublication" >
              <ListItemText primary={GetResource(Resources.MainMenu_Publications_Publications_Title)} classes={{ primary: classes.listText }} />
            </ListItem>
            {canReadApplication(applications.DIGITALEMEDIER) !== true ? undefined :
              <ListItem component={CustomNavLink}
                to="/elektronisk"
                className={[classes.nested, classes.listItem].join(" ")}
                activeClassName="activeRoute" button name="buttonDigitalPublication">
                <ListItemText primary={GetResource(Resources.MainMenu_Publications_Digital_Title)} classes={{ primary: classes.listText }} />
              </ListItem>
            }

          </List>
        </Collapse>
      </>
    );
  }
  listItemMonstring(to, text) {
    const classes = this.props.classes;

    return (
      <ListItem
        component={CustomNavLink}
        to={to}
        button
        className={[
          ["/publikationer", "/elektronisk", "/monstring/"].includes(
            this.props.location.pathname
          )
            ? "activeRoute"
            : "",
          classes.listItem
        ].join(" ")}
      >
        <ListItemIcon>
          <AssignmentTurnedInIcon className={classes.listIcon} />
        </ListItemIcon>
        <ListItemText
          primary={text}
          classes={{ primary: classes.listText }}
        />
      </ListItem>
    );
  }

  // React Router Links can't handle external links, see:
  // https://github.com/ReactTraining/react-router/issues/1147
  listItemAtag(to, text) {
    const classes = this.props.classes;
    // Ensure similar look
    let stolenClassNames = 'MuiButtonBase-root-94 MuiListItem-root-150 MuiListItem-default-153 MuiListItem-gutters-158 MuiListItem-button-159';

    return (
      <a
        href={to}
        target="_blank"
        rel="noopener noreferrer"
        className={[
          stolenClassNames,
          classes.listItem
        ].join(" ")}
      >
        <ListItemIcon>
          <AssignmentTurnedInIcon className={classes.listIcon} />
        </ListItemIcon>
        <ListItemText
          primary={text}
          classes={{ primary: classes.listText }}
        />
      </a>
    );
  }


  listItemDco(to, text) {
    const classes = this.props.classes;

    return (
      <>
        <ListItem onClick={this.handleClickOpenDco} button>
          <ListItemIcon>
            <MovieIcon className={classes.listIcon} />
          </ListItemIcon>
          <ListItemText primary="AutoMate" classes={{ primary: classes.listText }} />
          {this.state.dcoOpen ?
            <ExpandLess className={classes.listIcon} />
            : <ExpandMore className={classes.listIcon} />}
        </ListItem>

        <Collapse in={!this.state.dcoOpen} timeout="auto" unmountOnExit>
          <List component="div" disablePadding>
            <ListItem component={CustomNavLink}
              to="/collections"
              className={[classes.nested, classes.listItem].join(" ")}
              activeClassName="activeRoute" button >
              <ListItemText primary="Collections" classes={{ primary: classes.listText }} />
            </ListItem>
            <ListItem component={CustomNavLink}
              to="/feeds"
              className={[classes.nested, classes.listItem].join(" ")}
              activeClassName="activeRoute" button >
              <ListItemText primary="Feeds" classes={{ primary: classes.listText }} />
            </ListItem>
            <ListItem component={CustomNavLink}
              className={[classes.nested, classes.listItem].join(" ")}
              to="/media" activeClassName="activeRoute" button >
              <ListItemText primary="Media" classes={{ primary: classes.listText }} />
            </ListItem>
          </List>
        </Collapse>
      </>
    )
  }

  listItemPhoto(to, text) {
    const classes = this.props.classes;

    if(!IsFeatureEnabled(featureNames.Photos_Clipping_Enabled)){
      return (
        <>
            <ListItem component={CustomNavLink}
              to="/fotos"
              className={classes.listIcon}
              activeClassName="activeRoute" button >
              <ListItemIcon>
                <PhotoCameraIcon className={classes.listIcon} />
              </ListItemIcon>
              <ListItemText primary={GetResource(Resources.MainMenu_Photo_Title)} classes={{ primary: classes.listText }} />
            </ListItem>
      </>
      );
    }

    return (
      <>
        <ListItem onClick={this.handleClickOpenPhotos} button>
          <ListItemIcon>
            <PhotoCameraIcon className={classes.listIcon} />
          </ListItemIcon>
          <ListItemText primary={GetResource(Resources.MainMenu_Photo_Title)} classes={{ primary: classes.listText }} name="buttonNavigatePhotosDropdown" />
          {this.state.photosOpen ?
            <ExpandLess className={classes.listIcon} />
            : <ExpandMore className={classes.listIcon} />}
        </ListItem>

        <Collapse in={!this.state.photosOpen} timeout="auto" unmountOnExit>
          <List component="div" disablePadding>
            <ListItem component={CustomNavLink}
              to="/fotos"
              className={[classes.nested, classes.listItem].join(" ")}
              activeClassName="activeRoute" button >
              <ListItemText primary={GetResource(Resources.MainMenu_Photo_Photos_Title)} name="buttonNavigatePhoto" classes={{ primary: classes.listText }} />
            </ListItem>
            {canReadApplication(applications.MINEFRITLÆGNINGER) && IsFeatureEnabled(featureNames.Photos_Clipping_Enabled) ? 
              (
                <ListItem component={CustomNavLink}
                  to="/minefritlægninger"
                  className={[classes.nested, classes.listItem].join(" ")}
                  activeClassName="activeRoute" button >
                  <ListItemText primary={GetResource(Resources.MainMenu_Photo_MyClippings_Title)} classes={{ primary: classes.listText }} />
                </ListItem>
              ) :undefined
            }
          </List>
        </Collapse>
      </>
    )
  }

  listItemReceiptGenerator(to, text) {
    const classes = this.props.classes;

    return (
      <>
        <ListItem onClick={this.handleClickOpenGenerateReceipt} button>
          <ListItemIcon>
            <ReceiptIcon className={classes.listIcon} />
          </ListItemIcon>
          <ListItemText primary={GetResource(Resources.MainMenu_Tools_Title)} classes={{ primary: classes.listText }} />
          {this.state.generateReceiptOpen ?
            <ExpandLess className={classes.listIcon} />
            : <ExpandMore className={classes.listIcon} />}
        </ListItem>

        <Collapse in={!this.state.generateReceiptOpen} timeout="auto" unmountOnExit>
          <List component="div" disablePadding>
            <ListItem component={CustomNavLink}
              to="/værktøjer"
              className={[classes.nested, classes.listItem].join(" ")}
              activeClassName="activeRoute" button >
              <ListItemText primary={GetResource(Resources.MainMenu_Tools_PriceCheck_Title)} classes={{ primary: classes.listText }} />
            </ListItem>
          </List>
        </Collapse>
      </>
    )
  }

  
  listItemFilmMaker(to, text) {
    const classes = this.props.classes;

    return (
      <>
        <ListItem onClick={this.handleClickOpenFilmMaker} button>
          <ListItemIcon>
            <MovieCreationIcon className={classes.listIcon} />
          </ListItemIcon>
          <ListItemText primary={GetResource(Resources.MainMenu_FilmMaker_Title)} classes={{ primary: classes.listText }} />
          {this.state.filmMakerOpen ?
            <ExpandLess className={classes.listIcon} />
            : <ExpandMore className={classes.listIcon} />}
        </ListItem>
        {Object.keys(customersDictionary).map(x => 
          (
            <Collapse in={!this.state.filmMakerOpen} timeout="auto" unmountOnExit>
              <List component="div" disablePadding>
                <ListItem component={CustomNavLink}
                  to={`/FilmMaker/home/${x}`}
                  className={[classes.nested, classes.listItem].join(" ")}
                  activeClassName="activeRoute" button >
                  <ListItemText primary={x} classes={{ primary: classes.listText }} />
                </ListItem>
              </List>
            </Collapse>
          ))
        }
      </>
    )
  }


  renderListItem(to, text, icon) {
    switch (to) {
      case "/publikationer":
        return canReadApplication(applications.PUBLICATION) ? this.listItemFotohaandtering(to, text) : undefined;
      case "/Elektronisk":
        return canReadApplication(applications.DIGITALEMEDIER) ? this.listItemFotohaandtering(to, text) : undefined;
      case "/fototracker":
        return canReadApplication(applications.FOTOTRACKER) ? this.listItemGeneric(to, text, icon) : undefined;
      case "/tilbud":
        return canReadApplication(applications.TILBUDSSTYRING) ? this.listItemGeneric(to, text, icon) : undefined;
      case "/produkter":
        return canReadApplication(applications.PRODUKTER) ? this.listItemGeneric(to, text, icon) : undefined;
      case "/brugers":
        return canReadApplication(applications.BRUGERSTYRING) ? this.listItemGeneric(to, text, icon) : undefined;
      case "/logos":
        return canReadApplication(applications.LOGOSTYRING) ? this.listItemGeneric(to, text, icon) : undefined;
      case "/publikation":
        return canReadApplication(applications.FOTOHAANDTERING) ? this.listItemGeneric(to, text, icon) : undefined;
      case "/corrector":
        return canReadApplication(applications.CORRECTOR) ? this.listItemGeneric(to, text, icon) : undefined;
      case "http://webproof.republica.dk/default.asp":
        return canReadApplication(applications.PUBLICATION) ? this.listItemAtag(to, text) : undefined;
      case "/dco":
        return canReadApplication(applications.DCO) ? this.listItemDco(to, text) : undefined;
      case "/fotos":
        return canReadApplication(applications.FOTOS) ? this.listItemPhoto(to, text, icon) : undefined;
      case "/værktøjer":
        return canReadApplication(applications.KVITTERINGSGENERATOR) ? this.listItemReceiptGenerator(to, text, icon) : undefined;
      case "/FilmMaker":
        return canReadApplication(applications.KVITTERINGSGENERATOR) ?
          this.listItemFilmMaker(to, text, icon)
          // this.listItemGeneric(to, text, icon)
          : undefined;
      default: return this.listItemGeneric(to, text, icon);
    }
  }

  render() {
    return  this.renderOrganizationMenu();
  }

  renderOrganizationMenu(){

    let menu = [];

    //All menus with submenus have their title setup in a function, and not here
    //Those menus are initialized with an empty title

    //Home always enabled
    menu.push(this.renderListItem("/home", GetResource(Resources.MainMenu_Home_Title), "HomeIcon"))

    if(IsFeatureEnabled(featureNames.Publications_Enabled))
          menu.push(this.renderListItem("/publikationer", GetResource(Resources.MainMenu_Publications_Title)))

    if(IsFeatureEnabled(featureNames.Photos_Enabled))
          menu.push(this.renderListItem("/fotos", "", "PhotoCameraIcon"))

    if(IsFeatureEnabled(featureNames.PhotoOrders_Enabled))
          menu.push(this.renderListItem("/fototracker", GetResource(Resources.MainMenu_PhotoOrders_Title), "MonochromePhotosIcon"))

    if(IsFeatureEnabled(featureNames.Offers_Enabled))
          menu.push(this.renderListItem("/tilbud", GetResource(Resources.MainMenu_Offers_Title), "ShoppingCartIcon"))

    if(IsFeatureEnabled(featureNames.Logos_Enabled))
          menu.push(this.renderListItem("/logos", GetResource(Resources.MainMenu_Logos_Title), "CakeIcon"))

    if(IsFeatureEnabled(featureNames.Products_Enabled))
          menu.push(this.renderListItem("/produkter", GetResource(Resources.MainMenu_Products_Title), "StorefrontIcon")) 
    
    //Users is always enabled
    menu.push(this.renderListItem("/brugers", GetResource(Resources.MainMenu_Users_Title), "PeopleIcon"))

    if(IsFeatureEnabled(featureNames.PhotoCombinations_Enabled))
          menu.push(this.renderListItem("/corrector", GetResource(Resources.MainMenu_PhotoCombinations_Title), "CreateIcon"))

    if(IsFeatureEnabled(featureNames.Dco_Enabled))
          menu.push(this.renderListItem("/dco", GetResource(Resources.MainMenu_Dco_Title), "MovieIcon"))


    if(IsFeatureEnabled(featureNames.Tools_Enabled))
          menu.push(this.renderListItem("/værktøjer", ""))

    if(IsFeatureEnabled(featureNames.Filmmaker_Enabled))
          menu.push(this.renderListItem("/FilmMaker", "", "MovieCreationIcon"))

    //Help always enabled
    menu.push(this.renderListItem("/hjælp", GetResource(Resources.MainMenu_Help_Title), "HelpIcon"))

  return <List>
    {menu}
  </List>
  }
}
const NavLinks = withRouter(props => <NavLinks0 {...props} />);
export default withStyles(styles)(NavLinks);
