import { photoStatusEnum } from "../../../constants/enums";
import { apiGetParamsReplace, endpoints, RequestHeaders } from "../../../constants/services.constants";
import { getToken } from "../../../helpers/AuthHelper";
import { filterEnumOnValues } from "../../../helpers/EnumHelper";
import { _request } from "../../../helpers/HttpHelper";
import { getMotivId } from "../../../helpers/UtilHelper";
import { addToastMessage } from "../../../redux/actions/toasts.actions";
import store from "../../../redux/store";


import Resources from "../../../Resources.json";

export async function searchPhotosWithoutFilters(searchText, tags, numberOfRows, startRow) {
  const searchRequest = {
    searchText: searchText,
    filters: {
      types: [],
      customerIds: [],
      formats: [],
      isClipped: null,
      statuses: filterEnumOnValues(photoStatusEnum, x => x !== photoStatusEnum.Deleted && x !== photoStatusEnum.DoesNotExist && x !== photoStatusEnum.Inactive),
      tags: tags
    },
    startRow: startRow,
    numberOfRows: numberOfRows
  };

  return searchPhotos(searchRequest);
}

export async function searchPhotos(searchRequest) {
  return _request({
    method: "POST",
    endpoint: endpoints.SEARCH_PHOTOS,
    body: JSON.stringify(searchRequest),
    throwError: true
  });
}

export async function replacePhoto(form, motivId) {
  const endpoint = apiGetParamsReplace(endpoints.PHOTO_REPLACE, motivId);

  const headers = {
    Accept: "application/json, application/xml, text/plain, text/html, *.*",
    Authorization: "Bearer " + getToken(),
    "x-organization-id": Resources.Organization
  };

  const skipBodyParsing = true;

  try {
    await _request({ method: "PUT", endpoint, body: form, headers, skipBodyParsing });
  }
  catch {

  }
}

export function DefaultHeaders(){
  return { "x-organization-id": Resources.Organization };
}

export async function clipPhoto(photoId, jobId) {
  const endpoint = apiGetParamsReplace(endpoints.PHOTOCLIPPING_ADD_PHOTO, `?jobId=${jobId}&photoId=${photoId}`);
  return await _request({ 
    method: "POST", 
    endpoint, 
    body: null
  });
}

export async function transformPhoto(motiveId) {
  return await _request({
    method: "GET",
    endpoint: apiGetParamsReplace(endpoints.PHOTO_TRANSFORM, motiveId)
  });
}

export async function downloadImage(url, name) {
  fetch(url, {
    method: 'GET',
    headers:DefaultHeaders()

  }).then(response => {
    if (!response.ok && response.status === 404) {
      store.dispatch(
        addToastMessage({
          message: `Fotoet ${name} findes ikke`,
          type: "error",
          details: null
        })
      );
      return;
    }

    const contentype = response.headers.get("content-type");
    let fileName = "";
    if (contentype) {
      const ext = contentype.split("/");
      fileName = getMotivId(name) + "." + (ext[1] === "jpeg" ? "jpg" : ext[1]);
    }
    response.blob().then(blob => {
      let url = window.URL.createObjectURL(blob);
      let a = document.createElement('a');
      a.href = url;
      a.download = fileName || `${name}.jpeg`;
      a.click();
    });
  });
}

export async function getEarliestExpirationDate(photoId) {
  return _request({
    method: "GET",
    endpoint: apiGetParamsReplace(endpoints.EARLIEST_EXPIRATION_DATE, photoId),
    throwError: true
  });
}

export async function getPhoto(photoId) {
  return _request({
    method: "GET",
    endpoint: apiGetParamsReplace(endpoints.PHOTO_INFO_SINGLE, photoId),
    throwError: true
  });
}

export async function updatePhoto(photoId, dto) {
  return _request({
    method: "PATCH",
    endpoint: apiGetParamsReplace(endpoints.PHOTO_UPDATE_V3, photoId),
    body: JSON.stringify(dto),
    skipBodyParsing: true,
    throwError: true
  });
}

export async function addProductPhoto(productId, productPhoto) {
  return _request({
    method: "POST",
    endpoint: apiGetParamsReplace(endpoints.PRODUCT_ADD_MULTOPHOTOPRODUCT, productId),
    body: JSON.stringify(productPhoto),
    skipBodyParsing: true,
    throwError: true
  });
}

export async function deleteProductPhoto(productPhotoId) {
  return _request({
    method: "DELETE",
    endpoint: apiGetParamsReplace(endpoints.PRODUCT_DELETE_MULTOPHOTOPRODUCT, productPhotoId),
    skipBodyParsing: true,
    throwError: true
  });
}

export async function isPhotoLastProductPhoto(photoId) {
  return _request({
    method: "GET",
    endpoint: apiGetParamsReplace(endpoints.LAST_PRODUCT_PHOTO, photoId),
    throwError: true
  })
}

export async function updatePhotoValidToDate(photoId, validDate) {
  return _request({
      method: "PATCH",
      endpoint: apiGetParamsReplace(endpoints.PHOTO_UPDATE_V2_VALIDDATE, photoId,validDate),
      throwError: true,
      skipBodyParsing: true
  });
}