import { getCorrections } from "../Requests";
import { notificationChannelsEnum } from "../../../../constants/enums";
import { subscribeUserToNotificationChannelRecord } from "../../../layout/businessLogic/Requests";

export async function updateCorrectionsView(publicationId, stateCorrections) {
    const _corrections = await getCorrections([publicationId]);
    let stateCorrectionsCopy = [...stateCorrections];
    // remove old correctins and replace with new
    stateCorrectionsCopy = stateCorrectionsCopy.filter(x => x.publicationId !== publicationId);
    stateCorrectionsCopy = [...stateCorrectionsCopy, ..._corrections];

    return stateCorrectionsCopy;
}

export async function subscribeUserToPublicationNotifications(recordId) {
    const _channel = notificationChannelsEnum["PublicationUpdated"];
    const requestBody = {
        userId: "c89f4f02-03e3-4f3b-948e-698929e9e0c9", // replace it with a legit user id, right now this value is replaced in the service
        channel: _channel,
        recordId: parseInt(recordId)
    };

    await subscribeUserToNotificationChannelRecord(requestBody);
}
