import React, { useState } from "react";
import { Grid, Checkbox, Button, CardContent, CardActions, Card } from "@material-ui/core";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import { withStyles } from "@material-ui/core/styles";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import DialogTitle from "@material-ui/core/DialogTitle";

const styles = {
    root: {
        padding: 5,
    },
    modalContainer: {
        fontSize: 14,
    },
    cardActions: {
        justifyContent: "flex-end",
        margin: "0 0 15px 0"
    },
    column: {
        minWidth: 100,
    },
    columnItem: {
        minWidth: "100%",
        fontWeight: 700
    },
    headerContainer: {
        display: "grid",
        gridTemplateColumns: "1fr 1fr"
    },
    closeButtonColumn: {
        justifySelf: "end"
    },
    heading: {
        color: "rgba(0, 0, 0, 0.87)",
        fontSize: "1.2rem",
        fontFamily: "Open Sans",
        fontWeight: 500,
        lineHeight: 1.6,

    },
    saveButton: {
        fontSize: "1.2rem",
        fontFamily: "Open Sans",
        fontWeight: 500,
        lineHeight: 1.6
    }
};

const ColorButton = withStyles(theme => ({
    root: {
        marginRight: "10px",
        backgroundColor: "#263238",
        color: "#ffffff",
        '&:hover': {
            backgroundColor: "#11171A",
            color: "#ffffff"
        },
    },
}))(Button);

const SearchFilterPopup = (props) => {
    // A default options to control the state
    const [filterOptions, setFilterOptions] = useState(props.filterOptions);
    const { classes, onCancel, onSubmit } = props;
    // Defines a number of columns for the grid
    const columnsCount = 12 / (Object.keys(filterOptions).length);

    // Uncheck all the checkboxes
    const resetFilterOptions = () => {
        for (const category of filterOptions) {
            for (const option of category.options) {
                option.selected = false;
            }
        }
        setFilterOptions([...filterOptions]);
    }

    const handleCheckboxChange = (option, event) => {
        option.selected = event.target.checked;
        setFilterOptions([...filterOptions]);
    }

    const createColumn = (category, index) => {
        return (<Grid item xs={columnsCount} key={index} className={classes.column}>
            {/* header */}
            <Grid item xs={columnsCount} key={category.key} className={classes.columnItem}>{category.name}</Grid>

            {/* options */}
            {category.options.map((option, index) =>
                <div key={index} >
                    <FormControlLabel
                        control={
                            <Checkbox
                                checked={option.selected}
                                onChange={e => handleCheckboxChange(option, e)}
                                value={option.name}
                                color="primary"
                            />
                        }
                        label={option.name}
                    />
                </div>
            )
            }
        </Grid>)
    }

    return (
        <Card className={classes.root}>
            <div className={classes.headerContainer}>
                <DialogTitle
                    id="form-dialog-title"
                    style={{ padding: "16px 15px" }}
                >
                    Filter
                </DialogTitle>
                <div className={classes.closeButtonColumn}>
                    <IconButton onClick={x => onCancel()}>
                        <CloseIcon />
                    </IconButton>
                </div>
            </div>
            <CardContent>
                < Grid container >
                    {filterOptions.map((x, i) => createColumn(x, i))}
                </Grid >

            </CardContent>
            <CardActions className={classes.cardActions}>
                <Button
                    onClick={() => resetFilterOptions()}
                    color="primary">
                    Nulstil
                </Button>
                <ColorButton
                    type="submit"
                    variant="contained"
                    size="small"
                    onClick={() => onSubmit(filterOptions)}
                    className={classes.SaveButton}>Anvend</ColorButton>
            </CardActions>

        </Card >
    )
}
export default withStyles(styles)(SearchFilterPopup)