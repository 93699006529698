import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle
} from "@material-ui/core";
import { CircularProgress, TextField } from "@material-ui/core";
import React, { useState } from 'react';
import { handleApiSuccessNotificaiton } from "../../../../redux/Handlers/SuccessHandlers";
import { clipPhoto } from "../Requests";
import createDialog from "../../../../DialogRenderer";


export async function openConfirmClipPhotoDialog(motiveId) {
  var result = createDialog(({ show, onSubmit, onDismiss }) => (
      <DialogConfirmClipPhoto open={show} onCancel={onDismiss} onSuccess={onSubmit} motiveId={motiveId} />
  ));
  return result;
}

function DialogConfirmClipPhoto({ open, motiveId, onCancel, onSuccess }) {

  const [jobId, setJobId] = useState();
  const [isLoading, setIsLoading] = useState(false);

  const handleClipPhoto = async () => {
    if (isLoading) {
      return;
    }

    setIsLoading(true);
    const result = await clipPhoto(motiveId.replace("-", ""), jobId);
    setIsLoading(false);
    if (result !== undefined) {
      handleApiSuccessNotificaiton(`${motiveId} er blevet sendt til fritlægning`);
      onSuccess();
    }
  };

  return (
    <Dialog
    open={open}
    onClose={onCancel}
  >
    <DialogTitle>Send til fritlægning </DialogTitle>
    <DialogContent>
      <DialogContentText>
        Vil du sende {motiveId} til fritlægning?
        <br /> <br />
        <TextField
          fullWidth
          placeholder={"Jobnummer"}
          required
          onChange={event => setJobId(event.target.value)}
          type="text"
        />
      </DialogContentText>

      {isLoading === true ? (
        <CircularProgress
          size={24}
          style={{ marginLeft: 35, position: "relative", top: 4 }}
        />
      ) : undefined }
    </DialogContent>
    <DialogActions>
      <Button onClick={onCancel} autoFocus>
        Annuller
      </Button>
      <Button disabled={!jobId || isLoading} onClick={() => handleClipPhoto(motiveId)} color="primary">
        Send
      </Button>
    </DialogActions>
  </Dialog>
  )
}


export default (DialogConfirmClipPhoto);
