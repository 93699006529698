import React, { Component } from "react";

import { connect } from "react-redux";


// Material
import { Typography, withStyles } from "@material-ui/core";
import Card from "@material-ui/core/Card";
import CardActions from "@material-ui/core/CardActions";
import CardMedia from "@material-ui/core/CardMedia";
import CardContent from "@material-ui/core/CardContent";
import Divider from "@material-ui/core/Divider";
import Fade from "@material-ui/core/Fade";
import ActionButtons from "./ActionButtons";

import {
  endpoints,
  apiGetParamsReplace,
} from "../../../constants/services.constants";

// actions

import {
  getMotivId,
} from "../../../helpers/UtilHelper";
import { openDialogFullsizePhoto } from "../../shared/dialogs/DialogFullsizePhoto";
import { CustomTooltip } from "../fototracker/SortingSelecting";
import Resources from "./../../../Resources.json"
import { GetResource } from "../../../helpers/ResourceHelper";

const styles = {
  card: {
    maxWidth: 345,
  },
  media: {
    height: 140,
  },
  pagination: {
    paddingTop: "15px",
    textAlign: "center",
  },
  productItemsHolder: {
    display: "grid",
    gridTemplateColumns: "repeat(4, 1fr)",
  },
};

class SortingSelecting extends Component {
  constructor(props) {
    super(props);
    this.state = {
      offset: 0,
      productsPerPage: 12,
      totalProdcuts: 1200,

      photoOrderStatus: undefined,
      photoOrderCreated: undefined,
    };
  }

  setDataCard(products) {
    return products.map((product) => (

      <Card style={{ maxWidth: 345, marginBottom: 20 }} key={product.id}>

      <CardMedia
        title={product.name}
        style={{
          height: 140,
          backgroundSize: "contain",
          marginTop: 20,
        }}
        className={"cardMedia"}
        image={product.defaultPhotoId == null ?
          "/images/placeholders/no_photo_placeholder.png" :
          apiGetParamsReplace(endpoints.PHOTO_SINGLE_200, [product.defaultPhotoId])}
        onClick={() => openDialogFullsizePhoto(product.defaultPhotoId)}
      />

        <CardContent style={{ height: 115 }}>
          <Typography
            variant="subtitle2"
            gutterBottom
            style={{ fontSize: "0.875rem", fontWeight: "bold" }}
          >
            <CustomTooltip title={`${product.name}`}>
              <div className="max1line">
                {product.name}
              </div>
            </CustomTooltip>
          </Typography>
          <Typography variant="caption" style={{ fontSize: "0.575rem" }}>
            {GetResource(Resources.Products_Search_ProductNumber_Title)}:
            <span style={{ float: "right" }} name="labelProductNumber" value={product.productNumber}>{product.productNumber}</span>
          </Typography>
          <br />
          <Typography variant="caption" style={{ fontSize: "0.575rem" }}>
            Motiv:
            <span style={{ float: "right" }}>{getMotivId(product.defaultPhotoId)}</span>
          </Typography>
        </CardContent>
        <Divider />
        <CardActions
          style={{
            display: "inherit",
            textAlign: "center",
            paddingTop: 0,
            paddingBottom: 0,
          }}
        >
          <ActionButtons product={product} openEditDialog={this.props.openEditDialog} refreshSearch={this.props.refreshSearch} />
        </CardActions>
      </Card>
    ));
  }

  render() {
    const { classes, dataRows } = this.props;

    return (
      <>
        <Fade in={true}>
          <div className={classes.productItemsHolder}>
            {this.setDataCard(dataRows)}
          </div>
        </Fade>
      </>
    );
  }
}

export default connect((state) => state)(withStyles(styles)(SortingSelecting));
