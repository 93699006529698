import { Typography } from "@material-ui/core";
import React, { useState } from 'react';
import { requestResetCode } from "../../../../helpers/AuthHelper/AuthHelperV2";
import { stringIsEmpty } from "../../../../helpers/UtilHelper";
import { InputField } from "./InputField";
import { LogInHeader } from "./LogInHeader";
import StyledButtonLogIn from "./StyledButton";

export function SendForgotPasswordCode(props) {
    const { setForgetPasswordSent, setForgotPassword } = props;

    const forgotPasswordText = ["Har du glemt dit kodeord?"];
    const [loading, setLoading] = useState(false);
    const [userName, setUserName] = useState("");
    const [validationMessage, setVallidationMessage] = useState(undefined);

    async function doRequestResetCode() {
        if (stringIsEmpty(userName)) {
            setVallidationMessage("Brugernavn skal udfyldes");
            return;
        }
        setLoading(true);
        setVallidationMessage(undefined);

        requestResetCode(userName,
            (data) => {
                setForgetPasswordSent(true);
                setVallidationMessage(undefined);
                setLoading(false);
            },
            (error) => {
                setVallidationMessage("Noget gik galt");
                setLoading(false);
            });
    } 

    return (
        <>
            <LogInHeader
                text={forgotPasswordText}
            />
            <InputField
                labelText="Brugernavn"
                name="username"
                type="text"
                placeHolder="Indtast brugernavn"
                value={userName}
                handleInputChange={(e) => setUserName(e.target.value)}
            />
            <StyledButtonLogIn
                fullWidth
                variant="contained"
                color="primary"
                handleOnClick={() => doRequestResetCode()}
                disabled={loading}
                buttonText={!loading ? "Send" : "Henter"}
            />
            <StyledButtonLogIn
                fullWidth
                variant="contained"
                color="default"
                handleOnClick={() => setForgotPassword(false)}
                disabled={loading}
                buttonText={!loading ? "Tilbage" : "Tilbage til Login"}
            />
            {validationMessage ? (
                <div style={{ marginTop: 24 }}>
                    <Typography
                        variant="body1"
                        color="error"
                        align="center">
                        {validationMessage}
                    </Typography>
                </div>
            ) : undefined}
        </>
    )
}