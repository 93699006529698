import { createStore, combineReducers, applyMiddleware, compose } from "redux";
import messageReducer from "./reducers/message";
import fotohaandteringReducer from "./reducers/fotohaandtering.reducer";
import searchPhotosReducer from "./reducers/searchPhotos.reducer";
import fototrackerReducer from "./reducers/fototracker.reducer";
import notificationsReducer from "./reducers/notifications.reducer";
import toastsReducer from "./reducers/toasts.reducer";
import utilsReducer from "./reducers/utils.reducer";
import uploadsFototrackerReducer from "./reducers/uploads.fototrackers.reducers";
import productsReducer from "./reducers/products.reducer";
import usersReducer from "./reducers/users.reducers";
import offersReducer from "./reducers/offers.reducers";
import credentialsReducer from "./reducers/credentials.reducer";

import thunk from "redux-thunk";
const reducer = combineReducers({
  messageReducer,
  toastsReducer,
  notificationsReducer,
  fotohaandteringReducer,
  searchPhotosReducer,
  utilsReducer,
  fototrackerReducer,
  uploadsFototrackerReducer,
  productsReducer,
  usersReducer,
  offersReducer,
  credentialsReducer,
});

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const middleware = process.env.REACT_APP_ENVIRONMENT_NAME === "DEVELOPMENT" ? composeEnhancers(applyMiddleware(thunk)) : applyMiddleware(thunk)

const store = createStore(reducer, middleware);

export default store;
