import React, { useEffect, useState } from 'react'
import { makeStyles } from '@material-ui/core';
import { generatePngFromIframe } from '../../businessLogic/Requests';
import { ActionButton, EditOfferField, OfferFields, EditOfferPoster, EditOfferContainer } from './index'
import { endpoints } from '../../../../../../../constants/services.constants';
import { generateQueryParametersArray } from '../../businessLogic/Helpers';
import { apiGetParamsReplace } from './../../../../../../../constants/services.constants';

const useStyles = makeStyles({
    picture: {
        gridArea: "picture"
    },
    addBackground: {
        gridArea: "AddBackground"
    },
    saveButton: {
        gridArea: "Save"
    },
    seconds: {
        gridArea: "Seconds"
    },
    offerTitle: {
        gridArea: "OfferTitle"
    },
    offerDescription: {
        gridArea: "OfferDescription"
    },
    offerInfoText: {
        gridArea: "OfferInfoText"
    },
    offerPrice: {
        gridArea: "OfferPrice"
    },
    offerSaving: {
        gridArea: "OfferInfoText"
    },
});

export default function EditOfferContent({ 
    fields, 
    offerId, 
    offer, 
    addItem,
    customer
}) {
    const classes = useStyles();
    // Values used in poster
    const [offerDescription, setOfferDescription] = useState("");
    const [offerPrice, setOfferPrice] = useState("");
    const [offerTitle, setOfferTitle] = useState("ALTID BIllIGT");
    const [offerInfoText, setOfferInfoText] = useState("");
    const [offerSaving, setOfferSaving] = useState("0");

    const [videoDuration, setVideoDuration] = useState(4); // sec

    const logoIdsSearchParameters = [];// TODO ALB, Logos are no longer ids, but a list of strings in field 'logo-navn': generateQueryParametersArray(offer.logoIds);
    const photoCombination=apiGetParamsReplace( endpoints.PHOTOCOMBINATION_OFFERS_LUCKY,offerId,930,930,true);
    const baseUrl = `${endpoints.FRONTENDPOSTER_TEMPLATE1}?title=${offerTitle}&customerId=${customer}&savingAmount=${offerSaving}&photo=${encodeURIComponent(photoCombination)}&offerPrice=${offerPrice}&offerHeader=${offerDescription}&offerId=${offerId}&offerInfoText=${offerInfoText}${logoIdsSearchParameters}`;

    const [_offer, setOffer] = useState(undefined);
    const [url, setUrl] = useState(baseUrl);

    const [buttonText, setButtonText] = useState("Gem");
    // only re-render iframe when focus is out
    const [dataChanged, setDataChanged] = useState(false);

    useEffect(() => {
        setUrl(baseUrl);
    }, [dataChanged]);

    useEffect(() => {
        setDataChanged(true);
    }, []);

    const updatePosterState = (e) => {
        if (e.keyCode === 13) { // enter
            setDataChanged(!dataChanged);
        }
    };

    useEffect(() => {
        updateOfferInfo(fields, setOfferInfoText);
    }, [fields]);

    useEffect(() => {
        updateOffer(setOffer, offer, setOfferDescription, setOfferPrice);
    }, [offer]);

    const generateImageFromIframe = async () => {
        setButtonText("Loading...");
        const response = await generatePngFromIframe(url, videoDuration, customer);
        // add poster to the video request body
        addItem(response);
        setButtonText("Gem");
    }

    const handleChangeValue = (newValue, field) => {
        switch (field) {
            case "offerDescription":
                setOfferDescription(newValue);
                break;
            case "offerPrice":
                setOfferPrice(newValue);
                break;
            case "offerInfoText":
                setOfferInfoText(newValue);
                break;
            case "offerTitle":
                setOfferTitle(newValue.toUpperCase());
                break;
            default:
                console.log("You are trying to update wrong field", field);
        }
    }

    const fieldsMap = new Map([
        ["offerTitle", { value: offerTitle, className: classes.offerTitle }],
        ["offerDescription", { value: offerDescription, className: classes.offerDescription }],
        ["offerInfoText", { value: offerInfoText, className: classes.offerInfoText }],
        ["offerPrice", { value: offerPrice, className: classes.offerPrice }]]
    );

    return (
        <EditOfferContainer>
            <OfferFields
                offerFieldsValues={fieldsMap}
                handleChangeValue={(newValue, text) => handleChangeValue(newValue, text)}
                updatePosterState={(e) => updatePosterState(e)}
            />
            <EditOfferPoster
                url={url}
                classes={[classes.poster]}
            />
            <div
                className={classes.addBackground}
                style={{
                    display: "flex",
                    justifyContent: "center"
                }}
            >
                <ActionButton
                // className={classes.addBackground}
                text="Tilføj baggrund"
                    onClick={() => console.log("lol")} // will be added in v2
            />
            </div>
            <div
                className={classes.saveButton}
                style={{
                    display: "flex",
                    justifyContent: "center"
                }}>
                <ActionButton
                // className={classes.saveButton}
                onClick={() => generateImageFromIframe()}
                disabled={buttonText === "Loading..." ? true : false}
                text={buttonText}
            />
            </div>
            <EditOfferField
                className={classes.seconds}
                placeHolder=" Antal sekunder"
                onChange={(newValue) => setVideoDuration(newValue)}
                fieldValue={videoDuration}
                onKeyPress={() => { }} // we do not update poster when duration is changed
            />
        </EditOfferContainer>
    )
}

function updateOffer(setOffer, offer, setOfferDescription, setOfferPrice) {
    setOffer(offer);
    setOfferDescription(offer.description);
    setOfferPrice(offer.prices.price || "");
}

function updateOfferInfo(fields, setOfferInfoText) {
    let _varebeskrivelse = fields.find(x => x.name === "varebeskrivelse");
    _varebeskrivelse = _varebeskrivelse ? _varebeskrivelse.value : "";

    let _varebeskrivendemaengde = fields.find(x => x.name === "varebeskrivendemaengde");
    _varebeskrivendemaengde = _varebeskrivendemaengde ? _varebeskrivendemaengde.value : "";

    let _prissammenligning = fields.find(x => x.name === "prissammenligning");
    _prissammenligning = _prissammenligning ? _prissammenligning.value : "";

    let _flerevarianter = fields.find(x => x.name === "flerevarianter");
    _flerevarianter = _flerevarianter ? _flerevarianter.value : "";

    let _maengdebegraensning = fields.find(x => x.name === "maengdebegraensning");
    _maengdebegraensning = _maengdebegraensning ? _maengdebegraensning.value : "";

    let _fritvalg = fields.find(x => x.name === "fritvalg");
    _fritvalg = _fritvalg ? _fritvalg.value : "";

    setOfferInfoText(`${_varebeskrivelse} ${_varebeskrivendemaengde} ${_prissammenligning} ${_flerevarianter} ${_maengdebegraensning} ${_fritvalg} `);
}

