import { getToken } from '../../../../helpers/AuthHelper';

import { apiGetParamsReplace, endpoints } from "../../../../constants/services.constants";
import { _request } from "../../../../helpers/HttpHelper";
import Resources from "../../../../Resources.json";
export async function getScreens(customerId) {
    const endpoint = apiGetParamsReplace(endpoints.FILMMAKER_SCREENS_GET_FOR_CUSTOMER, customerId);
    return _request({
        method: "GET",
        endpoint: endpoint,
        acceptNull: true
    });
}

export async function getTemplates(customerId) {
    const endpoint = apiGetParamsReplace(endpoints.FILMMAKER_TEMPLATES_GET_FOR_CUSTOMER, customerId);
    return _request({
        method: "GET",
        endpoint: endpoint,
        acceptNull: true
    });
}

export async function getScreenSingle(screenId) {
    return _request({
        method: "GET",
        endpoint: endpoints.FILMMAKER_SCREENS + "/" + screenId,
        acceptNull: true
    });
}

export async function uploadFile(data, endpoint) {
    return _request({
        method: "POST",
        endpoint: endpoint,
        body: data,
        skipBodyParsing: true,
        headers: {
            Accept: "application/json, application/xml, text/plain, text/html, *.*",
            Authorization: "Bearer " + getToken(),
            "x-organization-id": Resources.Organization
        }
    });
}

export async function getBackgrounds(customerId) {
    const endpoint = apiGetParamsReplace(endpoints.FILMMAKER_BACKGROUND_GET_ALL, customerId);
    return _request({
        method: "GET",
        endpoint: endpoint,
        acceptNull: true
    });
}