import { handleAPIErrors, handleAPIErrorNotification } from "../../../../../redux/Handlers/ErrorHandlers";
import store from "../../../../../redux/store";
import { addToastMessage } from "../../../../../redux/actions/toasts.actions";
import { getToken } from "../../../../../helpers/AuthHelper";
import { apiGetParamsReplace, endpoints } from "../../../../../constants/services.constants";
import { fetchGetWT } from "../../../../../helpers/HttpHelper";
import Resources from "../../../../../Resources.json";
export const deleteCollections = (ids) => {
    if (!ids) {
        return;
    }
    return new Promise((resolve, reject) => {
        // Check if obj or arr
        if (!Array.isArray(ids) && typeof (ids) === "object") {
            ids = [ids.collectionId];
        }

        ids = ids.map((id, index) => {
            if (index === 0) {
                return `idList=${id}`;
            } else {
                return `&idList=${id}`;
            }
        });

        ids = ids.toString().replace(/,/g, "");

        const endpoint = apiGetParamsReplace(endpoints.COLLECTIONS_DELETE_COLLECTIONS, ids);
        const headers = {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: "Bearer " + getToken(),
            "x-organization-id": Resources.Organization
        };
        fetch(endpoint, {
            method: "DELETE",
            headers: headers
        })
            .then(handleAPIErrors)
            .then(res => {
                if (res.ok && res.status === 200) {
                    resolve()
                    store.dispatch(
                        addToastMessage({
                            message: "Den valgte kollektion blev slettet",
                            type: "success",
                            details: null
                        })
                    );
                }
            })
            .catch(error => {
                reject()
                handleAPIErrorNotification(endpoint, error);
            })
    });
}

export const fetchCollections = async () => {
    const endpoint = endpoints.COLLECTIONS_GET_ALL;
    return fetchGetWT(
        endpoint,
        json => {
            return json.reverse();
        }
        ,
        callError => {
            return [];
        });
}

export const createNewCollectionReq = async (collection) => {
    const headersJsonToken = {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: "Bearer " + getToken(),
        "x-organization-id": Resources.Organization
    };
    const endpoint = endpoints.COLLECTIONS_ADD_COLLECTION;

    return fetch(endpoint, {
        method: "POST",
        headers: headersJsonToken,
        body: JSON.stringify(collection)
    })
        .then(res => {
            if (!res.ok) {
                if (res.status === 500) {
                    throw Error(res.statusText);
                }
                else if (res.status === 400) {
                    throw Error(`${res.statusText}: Adressen er ikke korrekt!`); // 400 error occures when url is invalid
                }
            }
            if (res.ok && res.status === 202) {
                store.dispatch(
                    addToastMessage({
                        message: `Systemet er ved at skabe en ny kollektion ${collection.Name.Name}. Det kan tage et par minutter før det er færdigt`,
                        type: "success",
                        details: null
                    })
                );
                return res.json();
            } else {
                store.dispatch(
                    addToastMessage({
                        message: `Fejl: Det lykkedes ikke at tilføje ny kollektion ${collection.Name.Name}`,
                        type: "error",
                        details: null
                    })
                )
            }
        })
        .catch(error => {
            handleAPIErrorNotification(endpoint, error);
        });
}

export const checkCollectionStatus = async (collectionId) => {
    const endpoint = apiGetParamsReplace(endpoints.COLLECTIONS_GET_STATUS, collectionId);
    const headersJsonToken = {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: "Bearer " + getToken(),
        "x-organization-id": Resources.Organization
    };
    return fetch(endpoint, {
        method: "GET",
        headers: headersJsonToken
    })
        .then(handleAPIErrors)
        .then(res => res.json())
        .then(json => {
            if (json === null) {
                throw Error("The server response has been 'null'.")
            }
            return json;
        }
        )
        .catch(error => {
            handleAPIErrorNotification(endpoint, error);
        });
}

export const collectionCountFeeds = async (collectionId) => {
    const endpoint = apiGetParamsReplace(endpoints.COLLECTION_COUNT_FEEDS, collectionId);

    return fetchGetWT(
        endpoint,
        json => {
            return json;
        },
        callError => {
            return undefined;
        });
}
