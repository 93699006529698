import React, { Component } from "react";

import { connect } from "react-redux";
import { withStyles } from "@material-ui/core/styles";
import { Badge, Card, CardContent, Grow, Tooltip, Typography } from "@material-ui/core";
import { CustomTooltip } from "../../fototracker/SortingSelecting";


const styles = (theme) => ({
    fab: {
      position: "fixed",
      bottom: theme.spacing(6),
      right: theme.spacing(6),
    },
    separator: {
      display: "grid",
      gridTemplateColumns: "auto 1fr",
      gridColumnGap: 5,
    },
    borderSeparator: {
      width: "100%",
      borderTop: "3px dashed #bbb",
    },
  
  });
class EmptyProduct extends Component{
    constructor(props) {
        super(props);
    }

    render() {

        let backGroundClass = "backgroundHeadlineSingleProduct";
        const {
            classes, offerHeaderElement} = this.props;

        return (<>
        <Grow in={this.props.expanded} mountOnEnter unmountOnExit>
          <div
            className={"offerProductCard"}
            style={{ clipPath: "inset(-5px -5px -5px 0px)" }
            }
          >
            <div
              className={backGroundClass}
              style={offerHeaderElement && offerHeaderElement.current ?
                { height: offerHeaderElement.current.clientHeight } : undefined}>
            </div>
            <div className="cardTypographyFill" />
            <Card className="offerCard " >
              <div style={{ width: 160, height: 108 }}></div>
              <CardContent className="cardContent">
                
                <Typography
                  variant="subtitle2"
                  gutterBottom
                  style={{ fontSize: "0.875rem", fontWeight: "bold" }}
                >
                  <CustomTooltip title="Ingen produkter">
                    <div className="max1line">
                        Ingen produkter
                    </div>
                  </CustomTooltip>
                </Typography>
                <table className={"textCardTable"}>
                </table>
              </CardContent>
            </Card>
          </div>
        </Grow>

            {/* <p>No peculiar Products on this particular offer</p> */}
        </>)
    }
}

export default connect(state => state)(withStyles(styles)(EmptyProduct));