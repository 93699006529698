import MomentUtils from "@date-io/moment";
import { Chip, DialogContentText, MuiThemeProvider, TextField } from "@material-ui/core";
import { Clear } from "@material-ui/icons";
import { DatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import { withStyles } from "@material-ui/styles";
import clsx from "clsx";
import ChipInput from "material-ui-chip-input";
import moment from "moment";
import React from "react";
import { applications } from "../../../../../../constants/app.constants";
import { canReadApplication } from "../../../../../../helpers/CredentialsHelper";
import { RepublicaDatePickerMuiTheme } from "../../../../../../RepublicaMuiTheme";
import { CategorySelect, Clipping, CustomerSelect, MakeDigitalPack, PhotoAngleSelect, PhotoOrderTypeSelect, PhotoPrioritySelect, PhotoTypeSelect } from "../../../FototrackerFormInputs";
import { FilePond } from "react-filepond";
import AutoSuggestionWithChip from "../../../../../shared/components/autoSuggestionComponent/AutoSuggestionWithChip";
import PhotoRestrictionField from "../../../../../shared/components/PhotoRestrictionField";
import GetAutoCompleteSuggestions from "../../DialogAdd/GetAutoCompleteSuggestions";
import { photoOrderOrigin } from "../../../../../../constants/enums";
const styles = {
    row: {
        display: "grid",
        gridTemplateColumns: "1fr 1fr",
        gridGap: "1rem",
    },
    rowFullWidth: {
        display: "grid",
        gridTemplateColumns: "1fr",
        marginBottom: 25,
    },
    rowExpended: {
        marginBottom: 20,
    },
    selectInput: {
        marginTop: 6,
    },
    expandedInput: {
        marginBottom: 15,
    },
    title: {
        color: "rgba(0, 0, 0, 0.87)",
        "& h6": {
            fontWeight: 900,
        },
    },
}

const PhotoOrderDialogFields = ({
    classes, validation, utilsReducer,
    credentialsReducer, handleAddGTIN, inputValueGTIN, handleDeleteGTIN,
    handleUpdateInputGTIN,
    phototrackerReducer, handlePhotoTypeChange, multiPhotoParentTypeId,
    handlePhotoSubTypeChange, categories, comment,
    validFrom, validTo,
    handleDeleteLayout,handlePreviewLayout, handlePreviewRefFile, allDisabled, layouts, layoutFiles, updateFiles,
    refFiles, handleDeleteRefFile, newReferenceFiles, setNewReferenceFiles,
    handleDateChangeFrom, handleDateChangeTo, jobNumberDisabled, handleJobNumberChange, order,
    omskud, autoSuggestTags, exclusiveForCustomerIds, onExclusiveForCustomerIdsChange,
    autoSuggestPhotoTags, photoTags, setPhotoTags, changeFieldValue, origin

}) => {
    const newPhotoOrder = (origin === parseInt(photoOrderOrigin.PhotoTracker)) || (origin === parseInt(photoOrderOrigin.Publication));
    const showClipping = omskud || newPhotoOrder;
    const showDigitalPack = (omskud && multiPhotoParentTypeId === 6) || (newPhotoOrder && multiPhotoParentTypeId === 6);
    return (
        <MuiPickersUtilsProvider utils={MomentUtils} moment={moment}>
            <div className={classes.row}>
            <PhotoPrioritySelect
                    priority={order.priority}
                    onChange={(event) => changeFieldValue(event.target.value, "priority")}
            />
                <CustomerSelect
                validation={validation || {}}

                utilsReducer={utilsReducer}
                credentialsReducer={credentialsReducer}
                    multiCustomerId={order.customerId}
                    onChange={(event) => changeFieldValue(event.target.value, "customerId")}
                    disabled={origin === parseInt(photoOrderOrigin.Publication) || !newPhotoOrder ? true : false}
                />
            </div>
            <div className={classes.rowFullWidth}>
                <div className={classes.expandedInput}>
                    <ChipInput
                        label="EANS (GTIN)"
                        value={order.productNumbers}
                        inputValue={inputValueGTIN}
                        onAdd={(GTIN) => handleAddGTIN(GTIN)}
                        onUpdateInput={(e) => handleUpdateInputGTIN(e)}
                        fullWidth
                        onDelete={(deleteGTIN) => handleDeleteGTIN(deleteGTIN)}
                        disabled={allDisabled}
                        error={validation.gtinsErrorMessage &&
                            validation.gtinsErrorMessage.length > 0}
                        helperText={
                            validation.gtinsErrorMessage &&
                                validation.gtinsErrorMessage.length > 0
                                ? validation.gtinsErrorMessage
                                : "tryk enter for hvert nummer"
                        }
                    />
                </div>
            </div>  

            {origin === parseInt(photoOrderOrigin.Publication) || !newPhotoOrder ? (
                <TextField
                    disabled={jobNumberDisabled || (origin === parseInt(photoOrderOrigin.Publication))}
                    margin="dense"
                    label="Jobnummer"
                    type="text"
                    name="productNumber"
                    value={order.jobId}
                    onChange={e => handleJobNumberChange(e)}
                    fullWidth
                />
            ) : (
                <div className={classes.rowFullWidth}>
                    <GetAutoCompleteSuggestions
                        setJobId={(value) => handleJobNumberChange(value)}
                            error={validation.workbookJodIdErrorMessage &&
                                validation.workbookJodIdErrorMessage.length > 0}
                            helperText={
                                validation.workbookJodIdErrorMessage &&
                                    validation.workbookJodIdErrorMessage.length > 0
                                    ? validation.workbookJodIdErrorMessage
                                    : ""
                            }
                    />
                </div>
            )}

            <div className={clsx(classes.row, classes.rowExpended)}>
                <PhotoTypeSelect
                    fototrackerReducer={phototrackerReducer}
                    validation={validation || {}}
                    onChange={(e) => handlePhotoTypeChange(e)}
                    multiPhotoParentTypeId={multiPhotoParentTypeId}
                    disabled={allDisabled}
                />
                {!canReadApplication(applications.FOTOTRACKER) ? undefined : (
                  <PhotoOrderTypeSelect
                        photoOrderTypeId={order.type}
                        photoParentTypeId={multiPhotoParentTypeId}
                        onChange={(e) => handlePhotoSubTypeChange(e)}
                        disabled={allDisabled}
                  />
                )}
            </div>
            <div
                className={classes.row}
                style={{ marginBottom: 15 }}>
                <div>
                    {showClipping ? (
                        <Clipping
                            fritlaegges={order.clipPhoto}
                            handleFritlaeggesChange={(e, value) => changeFieldValue(value, "clipPhoto")}
                        />
                    ) : undefined}
                </div>
                <div>
                    {showDigitalPack ?
                        (<MakeDigitalPack
                            createDigitalPackOrder={order.digitalPack}
                            handleDigipackChange={(e, value) => changeFieldValue(value, "digitalPack")}
                        />) : undefined}

                </div>
            </div>
            <div className={classes.row}>
                <CategorySelect
                    categories={categories}
                    handleSelectCategory={(e) => changeFieldValue(e.target.value, "productMainCategoryId")}
                    validation={validation || {}}
                    selectedCategory={order.productMainCategoryId}
                    disabled={allDisabled}
                />
                {!canReadApplication(applications.FOTOTRACKER) ? undefined : (
                    <PhotoAngleSelect
                        multiPhotoAngleId={order.angle}
                        handleSelectAngle={(value) => changeFieldValue(value, "angle")}
                        disabled={allDisabled}
                    />
                )}

            </div>
            <div className={classes.rowFullWidth}>
                <TextField
                    margin="dense"
                    label="Fotobrief"
                    type="text"
                    name="instructions"
                    id="textfieldPhotoOrderAddPhotoBrief"
                    value={!order.instructions ? "" : order.instructions.replace(/\\n|\\r/g, "")}
                    InputProps={{
                        multiline: true,
                    }}
                    rows="6"
                    onChange={(e) => changeFieldValue(e.target.value, "instructions")}
                    fullWidth
                    disabled={allDisabled}
                    error={validation.photoBriefErrorMessage &&
                        validation.photoBriefErrorMessage.length > 0}
                    helperText={
                        validation.photoBriefErrorMessage &&
                            validation.photoBriefErrorMessage.length > 0
                            ? validation.photoBriefErrorMessage
                            : ""
                    }
                />
            </div>
            <div className={classes.rowFullWidth}>
                <AutoSuggestionWithChip
                    autoSuggestData={autoSuggestTags}
                    tags={order.tags}
                    setTags={(newTags) => changeFieldValue(newTags, "tags")}
                    allowAddNew={false}
                    label="Fotokoordinator-tags"
                />
            </div>
            <div className={classes.rowFullWidth}>
                <AutoSuggestionWithChip
                    autoSuggestData={autoSuggestPhotoTags}
                    tags={photoTags}
                    setTags={(newTags) => setPhotoTags(newTags)}
                    allowAddNew={true}
                    label="Foto-tags"
                />
            </div>
            <PhotoRestrictionField
                selectedCustomerIds={exclusiveForCustomerIds}
                onSelectedCustomerIdsChange={ids => onExclusiveForCustomerIdsChange(ids)}
              />
            <div className={classes.rowFullWidth}>
                <TextField
                    margin="dense"
                    label="Kommentar"
                    type="text"
                    name="Kommentar"
                    value={comment}
                    InputProps={{
                        multiline: true,
                    }}
                    rows="2"
                    onChange={(e) => changeFieldValue(e.target.value, "comment")}
                    fullWidth
                />
            </div>

            <div style={{ overflow: "auto" }}>
                <MuiThemeProvider theme={RepublicaDatePickerMuiTheme}>
                    <DatePicker
                        margin="normal"
                        label="Tilgængeligt fra:"
                        onChange={(date) => handleDateChangeFrom(date)}
                        style={{ float: "left", width: "49%" }}
                        value={validFrom}
                        clearable
                        disabled={allDisabled}
                        format="Do[.] MMM"
                    />
                    <DatePicker
                        margin="normal"
                        label="Tilgængeligt til:"
                        onChange={(date) => handleDateChangeTo(date)}
                        style={{ float: "right", width: "49%" }}
                        value={validTo}
                        clearable
                        disabled={allDisabled}
                        format="Do[.] MMM"
                    />
                </MuiThemeProvider>
            </div>
            <div style={{ marginTop: 20 }}>
                {
                    layouts.map(x => <Chip
                        label={x.FileName}
                        clickable
                        onClick={() => handlePreviewLayout(x)}
                        onDelete={() => handleDeleteLayout(x)}
                        deleteIcon={<Clear />}
                        style={{ marginRight: 5, marginBottom: 5 }}
                        disabled={allDisabled}
                    />
                    )
                }
            </div>
            <DialogContentText>Layout</DialogContentText>
            <FilePond
                allowImagePreview={true}
                imagePreviewTransparencyIndicator={"grid"}
                files={layoutFiles}
                onupdatefiles={(files) => updateFiles(files)}
                allowMultiple={true}
                maxFiles={6}
                imagePreviewHeight={200}
                acceptedFileTypes={["image/*", "application/pdf"]}
                labelIdle={
                    'Træk og slip dine filer eller <span class="filepond--label-action">Gennemse</span>'
                }
                disabled={allDisabled}
            />
            <div style={{ marginTop: 20 }}>
                {
                    refFiles.map(x => <Chip
                        label={x.FileName}
                        clickable
                        onClick={() => handlePreviewRefFile(x)}
                        onDelete={() => handleDeleteRefFile(x)}
                        deleteIcon={<Clear />}
                        style={{ marginRight: 5, marginBottom: 5 }}
                        disabled={allDisabled}
                    />
                    )
                }
            </div>
            <DialogContentText>Upload referencefiler</DialogContentText>
            <FilePond
                allowImagePreview={true}
                imagePreviewTransparencyIndicator={"grid"}
                files={newReferenceFiles}
                onupdatefiles={(fileItems) => setNewReferenceFiles(fileItems)}
                allowMultiple={true}
                maxFiles={5}
                imagePreviewHeight={200}
                acceptedFileTypes={["image/jpeg", "application/postscript", "image/png", "application/pdf"]}
                labelIdle={
                    'Træk og slip din billedfil eller <span class="filepond--label-action">Gennemse</span>'
                }
                disabled={allDisabled}
            />
        </MuiPickersUtilsProvider>
    )
}

export default (withStyles)(styles)(PhotoOrderDialogFields);
