import { apiGetParamsReplace, endpoints } from "../../../constants/services.constants";
import { getToken } from "../../../helpers/AuthHelper";
import { _request } from "../../../helpers/HttpHelper";
import Resources from "../../../Resources.json";
export async function createLogo(name, mappings, width, height, file) {
  const data = { name, mappings, width, height };
  const formData = new FormData();
  formData.append("file", file);
  formData.append("jsonData", JSON.stringify(data));

  return _request({
    method: "POST",
    endpoint: endpoints.LOGO_ADD,
    body: formData,
    throwError: true,
    headers: {
      Accept: "application/json, application/xml, text/plain, text/html, *.*",
      Authorization: "Bearer " + getToken(),
      "x-organization-id": Resources.Organization
    }
  });
}

export async function updateLogo(logoId, name, mappings, width, height, file) {
  const data = { name, mappings, width, height };
  const formData = new FormData();
  formData.append("file", file);
  formData.append("jsonData", JSON.stringify(data));

  return _request({
    method: "PATCH",
    endpoint: apiGetParamsReplace(endpoints.LOGO_UPDATE, logoId),
    body: formData,
    throwError: true,
    headers: {
      Accept: "application/json, application/xml, text/plain, text/html, *.*",
      Authorization: "Bearer " + getToken(),
      "x-organization-id": Resources.Organization
    }
  });
}

export async function refreshLogoPreview(logoId) {
  return _request({
    method: "POST",
    endpoint: apiGetParamsReplace(endpoints.LOGO_REFRESH_PREVIEW, logoId),
    skipBodyParsing: true,
    throwError: true
  });
}

export async function deleteLogo(logoId) {
  return _request({
    method: "DELETE",
    endpoint: apiGetParamsReplace(endpoints.LOGO_DELETE, logoId),
    throwError: true,
    skipBodyParsing: true,
  });
}

export async function searchLogos(searchText) {
  return _request({
    method: "GET",
    endpoint: apiGetParamsReplace(endpoints.LOGO_SEARCH, `searchText=${searchText}`)
  });
}

export async function getLogo(logoId) {
  return _request({
    method: "GET",
    endpoint: apiGetParamsReplace(endpoints.LOGO_SINGLE, logoId),
    throwError: true
  });
}

export async function downloadLogo(logoId) {

  const endpoint = apiGetParamsReplace(endpoints.LOGO_FILE_ORIGINAL, logoId)
  const response = await fetch(endpoint, {
    method: "get",
    headers:{"x-organization-id": Resources.Organization}
  })

  const contentDisposition = response.headers.get("content-disposition");
  const ext = contentDisposition.split(";")[1];
  const filename = ext.split("=")[1].replace(/\"/g, "");
  response.blob().then((blob) => {
    let url = window.URL.createObjectURL(blob);
    let a = document.createElement("a");
    a.href = url;
    a.download = filename;
    a.setAttribute('download', filename);
    document.body.appendChild(a);
    a.click();
  });
}