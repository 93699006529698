import React from "react";

import { connect } from "react-redux";
import { editDialogOpen } from "../../../redux/actions/product/edit.products.actions";

// Material
import EditIcon from "@material-ui/icons/Edit";
import DeleteIcon from "@material-ui/icons/Delete";
import IconButton from "@material-ui/core/IconButton";
import { getProduct } from "../../../redux/actions/product/edit.products.actions";
import OpenDialogWithInformation from "../../shared/OpenDialogWithInformation";
import { openDialogConfirmDelete } from "../../shared/dialogs/DialogConfirm";
import { deleteProduct, getOffersWithProductNumber, getActivePublicationsWithOffers } from "./Requests";
import { showSuccessNotification } from "../../../helpers/UtilHelper";
import { IsFeatureEnabled, featureNames } from "../../../helpers/FeaturesHelper";


class ActionButtons extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
      showAdvancedDelete: false,
    };
  }
  handleEditClickOpen = (id) => {
    this.props.dispatch(getProduct(id));
    this.props.dispatch(editDialogOpen(id));
    this.props.openEditDialog();
  };
  handleClose = () => {
    this.setState({ showAdvancedDelete: false });
  };

 // test
  handleDeleteClickOpen = async (id, name) => {
    try {
      const offerIds = await getOffersWithProductNumber(this.props.product.productNumber);
      const publications = await getActivePublicationsWithOffers(offerIds);
      if (publications.length > 0) {
        this.setState({ data: publications, showAdvancedDelete: true });
      }
      else {
        const confirmed = await openDialogConfirmDelete("Slet produkt", `Er du sikker på at du vil slette ${this.props.product.name}?`);
        if (confirmed) {
          await deleteProduct(this.props.product.id);
          showSuccessNotification("Produktet er blevet slettet");
        }
      }
    } catch (e) {
      console.log(e);
    }
  };

  render() {
    return (
      <>
        {/*EDIT BUTTON */}
        <IconButton
          color="primary"
          aria-label="edit"
          name="buttonProductEdit"
          onClick={() => this.handleEditClickOpen(this.props.product.id)}
        >
          <EditIcon />
        </IconButton>

        {/*DELETE BUTTON */}
        {IsFeatureEnabled(featureNames.Products_Delete_Enabled) ?
        
        <IconButton
          color="primary"
          aria-label="delete"
          name="buttonGenericDelete"
          onClick={() =>
            this.handleDeleteClickOpen(this.props.product.id, this.props.product.name)
          }
        >
          <DeleteIcon />
        </IconButton> : null}

        {this.state.showAdvancedDelete ? (
          <OpenDialogWithInformation
            title="Produktbilledet kan ikke slettes, da det bruges i følgende publikationer:"
            data={this.state.data}
            keyVal="id"
            valueToDipplay="name"
            open={this.state.showAdvancedDelete}
            handleClose={this.handleClose}
            error={""}
          />
        ) : null}
      </>
    );
  }
}

export default connect((state) => state)(ActionButtons);
