import React from "react";

import Autosuggest from 'react-autosuggest';
import match from 'autosuggest-highlight/match';
import parse from 'autosuggest-highlight/parse';
import Paper from '@material-ui/core/Paper';
import { withStyles } from '@material-ui/core/styles';
import TextField from "@material-ui/core/TextField";
import MenuItem from "@material-ui/core/MenuItem";

import { connect } from "react-redux";
import { fetchGetWT } from "../../../../../helpers/HttpHelper";
import { endpoints, apiGetParamsReplace } from "../../../../../constants/services.constants";


function renderInputComponent(inputProps) {
  const { classes, inputRef = () => { }, ref, ...other } = inputProps;

  return (
    <TextField
      fullWidth
      InputProps={{
        inputRef: node => {
          ref(node);
          inputRef(node);
        },
        classes: {
          input: classes.input,
        },
      }}
      {...other}
    />
  );
}

function renderSuggestion(suggestion, { query, isHighlighted }) {
  const matches = match(suggestion.label, query);
  const parts = parse(suggestion.label, matches);

  return (
    <MenuItem selected={isHighlighted} component="div">
      <div>
        {parts.map((part, index) =>
          part.highlight ? (
            <span key={String(index)} style={{ fontWeight: 500 }}>
              {part.text}
            </span>
          ) : (
              <strong key={String(index)} style={{ fontWeight: 300 }}>
                {part.text}
              </strong>
            ),
        )}
      </div>
    </MenuItem>
  );
}

const styles = theme => ({
  root: {
    height: 250,
    flexGrow: 1,
  },
  container: {
    position: 'relative',
  },
  suggestionsContainerOpen: {
    position: 'absolute',
    zIndex: 1,
    marginTop: theme.spacing(1),
    left: 0,
    right: 0,
  },
  suggestion: {
    display: 'block',
  },
  suggestionsList: {
    margin: 0,
    padding: 0,
    listStyleType: 'none',
  },
  divider: {
    height: theme.spacing(2),
  },
});

class GetAutoCompleteSuggestions extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      popper: '',
      suggestions: [],
      workbookJobId: ""
    };
  }
  numbersOnly = (input) => {
    if (/^[0-9]*$/.test(input))
      return input;
    else
      return input.replace(/[^0-9]/, '');
  };
  GetAutoCompleteSuggestions(jobid) {
    const endpoint = apiGetParamsReplace(
      endpoints.GET_JOBID_AUTOCOMPLETE,
      ...[jobid]
    );
    fetchGetWT(
      endpoint,
      json => {
        let filteredSuggestions = json;

        if (filteredSuggestions === undefined || filteredSuggestions === null)
          filteredSuggestions = [];
        else {
          filteredSuggestions = filteredSuggestions.map(function (suggestion) {
            return { label: suggestion.jobId + " - " + suggestion.jobName };
          });
        }
        this.setState({ suggestions: filteredSuggestions });
      },
      error => {
        console.warn("error", error);
      })
  }
  handleSuggestionsFetchRequested = ({ value }) => {

    value = this.numbersOnly(value);
    if (value.length > 2 && value !== this.state.workbookJobId)
      this.GetAutoCompleteSuggestions(value);
  };
  handleSuggestionsClearRequested = () => {
    this.setState({
      suggestions: [],
    });
  };
  handleAutoCompleteChange = name => (event, { newValue }) => {
    if (newValue != null) {
      const userInput = this.numbersOnly(newValue);
      this.setState({
        [name]: userInput,
      });
    }
  };
  setJobId(jobId) {
    this.setState({ workbookJobId: jobId.label.split("-")[0] }, () => {
      this.props.setJobId(this.state.workbookJobId);
    });
  }

  render() {

    const { classes } = this.props;
    const autosuggestProps = {
      renderInputComponent,
      suggestions: this.state.suggestions,
      onSuggestionsFetchRequested: this.handleSuggestionsFetchRequested,
      onSuggestionsClearRequested: this.handleSuggestionsClearRequested,
      getSuggestionValue: this.setJobId.bind(this),//this.props.setJobId,
      renderSuggestion,
    };

    return (<Autosuggest
      {...autosuggestProps}
      inputProps={{
        classes,
        placeholder: 'Jobnummer',
        value: this.state.workbookJobId,
        onChange: this.handleAutoCompleteChange('workbookJobId'),
        error: this.props.error,
        label: "Jobnummer",
        helperText: this.props.helperText
      }}
      theme={{
        container: classes.container,
        suggestionsContainerOpen: classes.suggestionsContainerOpen,
        suggestionsList: classes.suggestionsList,
        suggestion: classes.suggestion,
      }}
      renderSuggestionsContainer={options => (
        <Paper {...options.containerProps} square>
          {options.children}
        </Paper>
      )}
    />)
  }
}
export default connect(state => state)(withStyles(styles, { withTheme: true })(GetAutoCompleteSuggestions));