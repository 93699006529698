import React, { } from 'react'
import { Grid } from '@material-ui/core';
import CardPictureItem from './CardPictureItem';


function GridPhotos({ items, orderItems, showVariant, onSetVariant, onSetActive, onDelete, onEdit, loading, onOrderStatus, deactivatedOfferPhotos }) {
 

  const getOrderType = (item, orderItems) => {


    let orderItem = orderItems.find(order => order === null ? null : order.photoId == item.photoId);

    const isDeactivated = deactivatedOfferPhotos.map(x=> x.photoId).includes(item.photoId);
    const isSelectedPhoto = item.selectedOfferProductPhotoId === item.photoId;
    const isPhotoOrderInActive = orderItem !== undefined && orderItem.status.toLowerCase() === 'inactive';

    if(isSelectedPhoto){
      return "selectedPhoto";
    }

    //photo can be both deactivated and variant.
    //place isDeactivated clause first, other properties are not relevant if the photo is deactive.
    if(isDeactivated){
      return "deactivePhoto";
    }

    if(item.isVariant){
      return "variantPhoto";
    }

    if(isPhotoOrderInActive){
      return "inactivePhotoOrder";
    }
    
    return "other"
  }

  var order = {
    selectedPhoto : 1,
    variantPhoto : 2,
    other: 3,
    inactivePhotoOrder : 4,
    deactivePhoto : 5,
  };

  let sortedItems = items.sort((itemA,itemB) => {

    const orderTypeA = getOrderType(itemA,orderItems);
    const orderTypeB = getOrderType(itemB,orderItems);


    if (order[orderTypeA] < order[orderTypeB]) {
      return -1;
    }
    if (order[orderTypeA] > order[orderTypeB]) {
      return 1;
    }

    //If order is the same, ie two variant photos, then orders by the highest numerical photoId.
    return itemB.photoId - itemA.photoId;
  })

  return (
    <Grid container spacing={24}>
      {sortedItems.map((item) => (
        <Grid item xs={3} key={item.id} style={{ paddingTop: 18 }}>
          <CardPictureItem
            showVariant={showVariant}
            customerIdForPhoto={item.customerId}
            exclusiveForCustomerIds={item.exclusiveForCustomerIds}
            photoId={item.photoId}
            created={item.created}
            deleteDate={item.deleteDate}
            isActive={item.isActive}
            isVariant={item.isVariant}
            photoStatus={item.status}
            onSetActive={onSetActive}
            onSetVariant={onSetVariant}
            onDelete={onDelete}
            onEdit={onEdit}
            onOrderStatus={onOrderStatus}
            order={orderItems.find(order => order === null ? null : order.photoId == item.photoId)}
            loading={loading}
            photoType={item.type}
            isDeselected={deactivatedOfferPhotos.find(d=>d.photoId === item.photoId) !== undefined}
          />
        </Grid>))}
    </Grid>
  )
}

export default GridPhotos
