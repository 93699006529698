import {
  PHOTOTRACKER_OPEN_BEGIN,
  PHOTOTRACKER_CLOSE_BEGIN,
  SAVE_PHOTOORDER_BEGIN,
  SAVE_PHOTOORDER_SUCCESS,
  SAVE_PHOTOORDER_FAILURE,
  CHANGE_SELECTED_PRODUCT,
  PATCH_PHOTOTRACKER_BEGIN,
  PATCH_PHOTOTRACKER_SUCCESS,
  PATCH_PHOTOTRACKER_FAILURE,
} from "../actions/photos/fototracker.photos.actions";
import {
  // Genbrug status
  SET_ORDERSTATUSTYPE_70_BEGIN,
  SET_ORDERSTATUSTYPE_70_SUCCESS,
  SET_ORDERSTATUSTYPE_70_FAILURE,
} from "../actions/photos/orderstatustypes.photos.actions";

import {
  FETCH_LISTE_BEGIN,
  FETCH_LISTE_SUCCESS,
  FETCH_LISTE_FAILURE,
} from "../actions/photos/sendliste.fototracker.actions";
const initState = {
  fototrackers: null,
  fototracker: null,
  id: null,
  editDialog: false,
  loading: false,
  loadingDeletePhototracker: false,
  selectedproduct: null,
  loadingSaveProduct: false,
  queryString: "",
  loadingPhototype: false,
  errorPhototype: false,
  IsFotoOrder: false,
  getPhotoTrackerLoading: false
};
export default (state = initState, action) => {
  switch (action.type) {
    //-------------------------------------------
    case SAVE_PHOTOORDER_BEGIN:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case SAVE_PHOTOORDER_SUCCESS:
      //We call this from monstring as well as from fototracker.
      //If called in fototracker, the fototrackers array exists and we want to add the newly created fotoorder.
      //If called in monstring it doesn't much matter.
      // let newArray = [];
      // if (state.fototrackers != null) {
      //   newArray = state.fototrackers.slice(); //Returns new array
      //   newArray.splice(action.index, 0, action.payload.fototracker);
      // } else {
      //   newArray = [];
      // }

      return {
        ...state,
        loading: false
      };
    case SAVE_PHOTOORDER_FAILURE:
      return {
        ...state,
        loading: true,
        error: action.payload.error,
      };
    //-------------Genbrug obsolete(?) ----------------------
    case SET_ORDERSTATUSTYPE_70_BEGIN:
      return {
        ...state,
        loadingSetOrderstatustype: true,
        loadingSetOrderstatustypeParent: action.payload.orderId,
        errorSetOrderstatustype: null,
      };

    case SET_ORDERSTATUSTYPE_70_SUCCESS:
      const { orderId, statusId, photoId } = action.payload;
      // let photoOrder = state.fototrackers.find(fototracker => fototracker.id === orderId);
      // photoOrder.multiPhotoOrderStatusTypeId = parseInt(statusId);
      // if (photoId) {
      //   photoOrder.reUseFromMultiPhotoId = parseInt(photoId);
      // }
      return {
        ...state,
        loadingSetOrderstatustype: false,
        loadingSetOrderstatustypeParent: null,
      };

    case SET_ORDERSTATUSTYPE_70_FAILURE:
      return {
        ...state,
        loadingSetOrderstatustype: false,
        errorSetOrderstatustype: action.payload.error,
      };
    //-------------------------------------------
    case PHOTOTRACKER_OPEN_BEGIN:
      return {
        ...state,
        editDialog: true,
        id: action.payload.id,
      };
    case PHOTOTRACKER_CLOSE_BEGIN:
      return {
        ...state,
        id: null,
        editDialog: false,
        fototracker: null,
        Get: null,
        loading: false,
      };
    //-------------------------------------------
    case CHANGE_SELECTED_PRODUCT:
      return {
        ...state,
        selectedproduct: action.payload.product,
      };
    //-------------------------------------------
    case FETCH_LISTE_BEGIN:
      return {
        ...state,
        loadingFetchListe: true,
      };
    case FETCH_LISTE_SUCCESS:
      return {
        ...state,
        photoListe: action.payload.liste,
        loadingFetchListe: false,
      };
    case FETCH_LISTE_FAILURE:
      return {
        ...state,
        fetchListeError: action.payload.error,
        loadingFetchListe: false,
      };
    //--------------------------------------------
    case PATCH_PHOTOTRACKER_BEGIN:
      return {
        ...state,
        loading: true,
        error: null,
      };

    case PATCH_PHOTOTRACKER_SUCCESS:
      state.fototrackers[
        state.fototrackers.findIndex(
          (fototracker) => fototracker.id === action.payload.fototracker.id
        )
      ] = action.payload.fototracker;
      return {
        ...state,
        loading: false,
      };

    case PATCH_PHOTOTRACKER_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload.error,
      };

    default:
      return state;
  }
};
