import React, { useContext } from 'react';
import { withStyles, Dialog, DialogContent, DialogActions, Button, Typography } from '@material-ui/core';
import clsx from 'clsx';
import { deleteMediaRequest, fetchMediaDefinitions } from '../Logic/Requests';
import { MediaContext } from '../../contexts/MediaContext';

const styles = theme => ({
    input: {
        marginBottom: 35
    },
    actions: {
        display: "grid",
        gridTemplateColumns: "1fr 1fr 1fr 1fr 1fr",
        gridGap: "1rem",
        marginBottom: 30
    },
    actionButton: {
        width: 110,
        justifySelf: "end",
    },
    positionGroup: {
        gridColumn: 4
    },
    error: {
        color: "#f44336"
    }
});

const ColorButton = withStyles(theme => ({
    root: {
        marginRight: 10,
        backgroundColor: "#263238",
        color: "#ffffff",
        '&:hover': {
            backgroundColor: "#11171A",
            color: "#ffffff"
        },
    },
}))(Button);

function DialogConfirmDelete(props) {
    const { open, classes } = props;
    const { confirmDelete: confirmDeleteState, selectedMedia: selectedMediaState, media: mediaState } = useContext(MediaContext);
    const [selectedMedia, setSelectedMedia] = selectedMediaState;
    const [, setConfirmDelete] = confirmDeleteState;
    const [media, setMedia] = mediaState;

    const handleClose = () => {
        setConfirmDelete(false);
    }

    const handleDelete = async () => {
        await deleteMediaRequest(selectedMedia.mediaDefinitionId);
        const updatedMedia = await fetchMediaDefinitions();
        setMedia(updatedMedia);
    }

    return (
        <>
            <Dialog
                open={open}
                onClose={handleClose}
            >
                <DialogContent>
                    <Typography variant="h6" style={{ padding: "10% 0px", textAlign: "center" }}>Er du sikker på at du vil slette ”{selectedMedia.mediaName.name}” media?</Typography>
                </DialogContent>
                <DialogActions>
                    <div className={classes.actions}>
                        <ColorButton className={clsx(classes.actionButton, classes.positionGroup)} onClick={() => setConfirmDelete(false)} color="primary">
                            Annuller
                  </ColorButton>
                        <ColorButton className={classes.actionButton} variant="contained" onClick={() => { handleDelete(); setConfirmDelete(false); }}>
                            Slet
                  </ColorButton>
                    </div>
                </DialogActions>
            </Dialog>
        </>
    )

}

export default withStyles(styles)(DialogConfirmDelete)