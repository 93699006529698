import React from 'react'
import { fabric } from 'fabric'
import {
  saveCombinationTemplate,
  generateCombination,
  fetchRotationRecalculation
} from './PhotoCombinationCalls'
import { PhotoCombinationPolice } from './PhotoCombinationPolice'
import { PhotoCombinationBase } from './PhotoCombinationBase'
import backgroundImage from './gray-tile.png'

export class PhotoCombination extends PhotoCombinationBase {
  constructor(props) {
    super(props)

    this.sendToPolice = this.sendToPolice.bind(this)
    this.imageLoadComplete = this.imageLoadComplete.bind(this)

    this.state = {
      loadingImages: [],
      loadingImagesError: null,
      currentStatus: null,
      policeEvaluation: [],
      showAnyway: false,
      template: props.template,
      reloadingPhoto: false
    }
    this.imgRefs = []

  }


  componentDidMount() {
    this.canvas = new fabric.Canvas(this.props.canvasId, {
      // selection: false
      fireRightClick: true,
      uniScaleTransform: false
    })
    this.canvas.preserveObjectStacking = true;
    this.addDoubleClickEventListener();
    this.addRightClickEventListener();
    this.addKeydownEventListener();
    this.addBlockOutboundHandler();

    this.renderCanvas();
    this.setQuerySnapshot();
  }

  componentDidUpdate() {
    if (!this.isQuerySnapshot()) {
      this.renderCanvas()
      this.setQuerySnapshot()
    }
  }

  componentWillUnmount() {
    if (!this.imgRefs.length === 0) {
      return
    }
    this.imgRefs.forEach((x, i) => {
      x.onload = function () { }
      x.onerror = function () { }
      delete this.imgRefs[i]
    })
    this.removeDoubleClickEventListener();
    this.removeRightClickEventListener();
    this.removeKeydownEventListener();
    this.removeBlockOutbound();

    this.canvas.dispose();
  }

  triggerRightClickBehavior(e) {
    if (e.button === 3) {
      let o = this.canvas.getActiveObject()
      if (o != null && o.get('type') === 'image') {
        // console.log(o);
        var answer = prompt('Please enter desired degrees for this photo id ' + o.motiveId, o.rotate)
        console.log(answer)
        if (answer == null || answer.trim() === '') {
          console.log('User cancelled the prompt.')
        } else {
          answer = parseInt(answer)
          if (!Number.isInteger(answer)) { console.log('Input has to be a number.') } else {
            o.rotate = answer
            console.log('The degrees are ' + answer + '! ')
            this.getRotationRecalculation()
          }
        }
      }
    }
  }


  getRotationRecalculation() {
    let query = this.state.template

    query.jsonTemplate = JSON.stringify(this.getJsonTemplate())
    query.images = null
    this.canvas.selectable = false
    this.setState({ reloadingPhoto: true },
      fetchRotationRecalculation(
        query,
        (data) => {
          this.setState({ template: data, reloadingPhoto: false, validate: false }, this.renderCanvas)
          this.canvas.selectable = true
        }
        // , () => console.log(this.state)
      ))
  }

  sendToPolice() {
    // if (this.props.validate == null || this.props.validate) {
    if (this.validate) {
      var police = new PhotoCombinationPolice(this)
      var evaluation = police.defaultEvaluation()
      this.setState({
        policeEvaluation: evaluation
      }
        // , () => console.log(this.state.policeEvaluation)
      )

      if (this.props.setValidations !== undefined) this.props.setValidations(this.state.template, evaluation)
    }
  }

  setQuerySnapshot() {
    this.imagesSnapshot = this.props.images
    this.canvasWidthSnapshot = this.canvasWidth
    this.canvasHeightSnapshot = this.canvasHeight
  }
  isQuerySnapshot() {
    return (
      this.imagesSnapshot === this.props.images &&
      this.canvasHeightSnapshot === this.canvasHeight &&
      this.canvasWidthSnapshot === this.canvasWidth
    )
  }

  sendGenerationCombination(callback) {
    let t = this.getJsonTemplate()
    console.log(this.props)
    this.setState({ currentStatus: 'Generating...' })
    generateCombination(
      {
        'canvasHeight': this.canvasHeight,
        'canvasWidth': this.canvasWidth,
        'combinedImageWidth': this.combinedImageWidth,
        'combinedImageHeight': this.combinedImageHeight,
        'photos': t.objects.map(element => {
          element.width = element.width * element.scaleX
          element.scaleX = 1
          element.height = element.height * element.scaleY
          element.scaleY = 1
          return element
        })
      }, data => {
        this.setState({ currentStatus: 'Done! ' + data })
        if (callback !== undefined) callback(data);
      }
    )
  }


  imageLoadStarts(url) {
    var newCollection = this.state.loadingImages

    newCollection.push(url)
    this.setState({ loadingImages: newCollection }
      // , () => console.log(this.state.loadingImages)
    )
  }

  imageLoadComplete(url) {
    let index = this.state.loadingImages.indexOf(url)
    if (index > -1) {
      let temp = this.state.loadingImages
      temp.splice(index, 1)
      this.setState({ loadingImages: temp }
        , this.sendToPolice
      )
    }
  }

  sendCombinationTemplate(origin, next, callback) {
    let t = this.getJsonTemplate()
    var currentdate = new Date()
    var datetime = currentdate.getDay() + currentdate.getMonth() + currentdate.getFullYear() + '-' + currentdate.getHours() + currentdate.getMinutes() + currentdate.getSeconds()
    var fixedObjects = Object.assign({}, t)
    fixedObjects.objects = t.objects.map(element => {
      element.width = element.width * element.scaleX
      element.scaleX = 1
      element.height = element.height * element.scaleY
      element.scaleY = 1
      return element
    })
    this.setState({ currentStatus: 'Gemmer...' })


    let s = {
      // "OfferProductIds":'',
      // "ProductIds":"",
      // "MotiveIds":"",
      'offerId': this.props.offerId,
      // "ranking": ,
      'canvasHeight': this.canvasHeight,
      'canvasWidth': this.canvasWidth,
      'combinedImageWidth': this.combinedImageWidth,
      'combinedImageHeight': this.combinedImageHeight,
      'qty': t.objects.length,
      'name': 'CombinationTemplate' + t.objects.length + 'P' + datetime,
      'jSONTemplate': JSON.stringify(fixedObjects),
      // "image64Template": this.getImage64Template(),
      'imagesJSON': JSON.stringify(this.props.images),
      // "images": fixedObjects.objects,
      'idPublication': this.props.publicationId,
      'correctionId': this.props.correctionId,
      'excludeMotives': this.props.excludeMotives,
      'maxMotives': this.props.maxMotives
    }
    saveCombinationTemplate(
      origin,
      s,
      data => {
        this.setState({ currentStatus: 'Saved.' })
        if (this.props.addNewStoredTemplate != null) this.props.addNewStoredTemplate(data)
        if (this.props.refresh != null) this.props.refresh(data)
        if (next === true) this.props.nextOffer()
        if (callback !== undefined) callback(data)

      })

  }

  deleteCombinationTemplate() {
    this.props.deleteCombinationTemplate(this.state.template.id)
  }

  saveAndNext(origin) {
    this.sendCombinationTemplate(origin, true)
  }

  showAnyway() {
    this.setState({ showAnyway: !this.state.showAnyway }
      // , console.log(this.state.showAnyway)
    )
  }

  get validate() {
    if (this.state.validate !== null) { return this.state.validate } else if (this.props.validate !== null) { return this.props.validate } else if (this.props.validate === null) { return true } else return false
  }





  renderCanvas() {
    this.canvas.clear();
    this.canvas.setWidth(this.canvasWidth);
    this.canvas.setHeight(this.canvasHeight);
    this.canvas.backgroundColor = new fabric.Pattern({ source: backgroundImage });//this.props.backgroundColor;
    let jsonObjects;
    jsonObjects = this.parseTemplateObjects(this.state.template.jsonTemplate)// JSON.parse(this.state.template.jsonTemplate).objects;

    jsonObjects.forEach((e, i) => {
      console.log(e);
      this.canvasImage(e)
    })

    this.addGrid();
    if (this.props.takeSizeMutations !== undefined) this.takeSizeMutationsHandler();

    this.disableSelectionSideGrowingHandler();
    this.canvas.renderAll();
    // this.setQuerySnapshot();
  }




  render() {
    const message = this.state.currentStatus != null ? this.state.currentStatus : ''
    const messageImages =
      this.state.reloadingPhoto ? 'Recalculating image...'
        : this.state.loadingImagesError != null ? this.state.loadingImagesError
          : this.state.loadingImages.length > 0 ? 'Loading images... (' + this.state.loadingImages.length + ' remaining)'
            : this.props.images != null ? 'Ready (' + this.props.images.length + ' images) ' + this.state.template.id : ''
    const canvasVisibility =
      (
        !this.state.reloadingPhoto &&
        this.state.loadingImages.length === 0 &&
        this.state.policeEvaluation.length === 0) ||
      this.state.showAnyway

    return (
      <div >
        <span style={{ marginLeft: 20 }}>{messageImages}</span>

        {this.state.policeEvaluation.length > 0 ? <div>
          <span>This generation {this.state.template.id} has not passed the tests: {this.state.policeEvaluation.toString()}</span><br />
          <button type='button' className='btn btn-info btn-sm' onClick={this.showAnyway.bind(this)}>Show anyway</button>
        </div> : undefined}

        <div hidden={!canvasVisibility} >
          <div
            id={this.props.canvasId + '-wrapper'}
            style={{ display: 'inline-block' }}
            onContextMenu={(e) => {
              e.preventDefault()
              e.stopPropagation()
              return false
            }}>
            <canvas id={this.props.canvasId}
              width={this.canvasWidth} height={this.canvasHeight}
            />
          </div>
          <br />
          <br />

          <span style={{ marginLeft: 20 }}>{message}</span></div>
        <br />
      </div>
    )
  }
}
