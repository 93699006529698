import { endpoints } from "../../../../../../constants/services.constants";
import { _request } from "../../../../../../helpers/HttpHelper";

export async function deleteTemplate(templateId) {
    return _request({
        method: "DELETE",
        endpoint: endpoints.FILMMAKER_SCREEN.replace("{id}", templateId),
        throwError: true,
        skipBodyParsing: true,
    });
}