import React from "react";
import Tooltip from "@material-ui/core/Tooltip";
import AddIcon from "@material-ui/icons/Add";
import { withStyles } from "@material-ui/core/styles";

import Fab from "@material-ui/core/Fab";
import DialogAddProductV2 from "./dialogs/AddProductV2/DialogAddProductV2";

const defaultToolbarStyles = {
  iconButton: {
    margin: "0px 0px 20px 15px"
  }
};

class CustomToolbar extends React.Component {

  state = { openV2: false };

  handleClick = () => {
    this.setState({ openV2: true });
  };
  handleClose = (productNumber) => {
    this.setState({ openV2: false });
    if (productNumber)
      this.props.onProductCreated(productNumber);
  };

  render() {
    const { classes } = this.props;
    return (
      <>
        <Tooltip title={"Tilføj nyt produkt"}>
          <Fab
            size="small"
            color="primary"
            className={classes.iconButton}
            onClick={this.handleClick}
            name="buttonProductAdd"
          >
            <AddIcon />
          </Fab>
        </Tooltip>
        {this.state.openV2 !== true ? undefined : <DialogAddProductV2 handleClose={this.handleClose} />}
      </>
    );
  }
}

export default withStyles(defaultToolbarStyles, { name: "CustomToolbar" })(
  CustomToolbar
);
