import { jobNumberCustomerRelations } from "../constants/enums";
import { apiGetParamsReplace } from "../constants/services.constants";
import { addToastMessage } from "../redux/actions/toasts.actions";
import store from "../redux/store";

export var groupBy = function (xs, key) {
  return xs.reduce(function (rv, x) {
    (rv[x[key]] = rv[x[key]] || []).push(x);
    return rv;
  });
};
export var groupBy2 = function (xs, key) {
  return xs.reduce(function (rv, x) {
    (rv[x[key]] = rv[x[key]] || []).push(x);
    return rv;
  }, {});
};

export var groupBy2Indexes = function (groups) {
  let arr = [];
  for (var val in groups) {
    arr.push(val)
  }
  return arr;
}

export var getCorrectionDate = function (dateString) {
  const date = !dateString ? new Date() : new Date(dateString);
  const day = (date.getDate()).toString().padStart(2, '0');
  const month = (date.getMonth() + 1).toString().padStart(2, '0');
  const year = date.getFullYear();
  const hours = (date.getHours()).toString().padStart(2, '0');
  const minutes = (date.getMinutes()).toString().padStart(2, '0');
  return day + "." + month + "." + year + " " + hours + ":" + minutes;
};

export var getCorrectionFileDate = function (dateString) {
  const date = !dateString ? new Date() : new Date(dateString);
  const day = (date.getDate()).toString().padStart(2, '0');
  const month = (date.getMonth() + 1).toString().padStart(2, '0');
  const year = date.getFullYear();
  const hours = (date.getHours()).toString().padStart(2, '0');
  const minutes = (date.getMinutes()).toString().padStart(2, '0');
  return day + "." + month + "." + year + " " + hours + "." + minutes;
};

export var getShortDate = function (dateString) {
  const date = !dateString ? new Date() : new Date(dateString);
  const day = (date.getDate()).toString().padStart(2, '0');
  const month = (date.getMonth() + 1).toString().padStart(2, '0');
  const year = date.getFullYear();
  return day + "/" + month + "-" + year;
};
export var getLongDate = function (dateString) {
  const date = new Date(dateString);
  const day = (date.getDate()).toString().padStart(2, '0');
  const month = (date.getMonth() + 1).toString().padStart(2, '0');
  const year = date.getFullYear();
  const hours = (date.getHours()).toString().padStart(2, '0');
  const minutes = (date.getMinutes()).toString().padStart(2, '0');
  return day + "/" + month + "-" + year + " " + hours + ":" + minutes;
};
export var momentLocal = function () {
  return {
    months: [
      "Januar",
      "Februar",
      "Marts",
      "April",
      "Maj",
      "Juni",
      "Juli",
      "August",
      "September",
      "Oktober",
      "November",
      "December"
    ],
    monthsShort: [
      "jan",
      "feb",
      "mar",
      "apr",
      "maj",
      "juni",
      "juli",
      "aug",
      "sep",
      "okt",
      "nov",
      "dec"
    ],
    weekdays: [
      "søndag",
      "mandag",
      "tirsdag",
      "onsdag",
      "torsdag",
      "fredag",
      "lørdag"
    ],
    weekdaysShort: ["søn", "man", "tir", "ons", "tor", "fre", "lør"],
    weekdaysMin: ["sø", "ma", "ti", "on", "to", "fr", "lø"],
    week: {
      dow: 1, // First day of week is Monday
      doy: 4 // First week of year must contain 4 January (7 + 1 - 4)
    }
  };
}

export function getExtension(filename) {
  var parts = filename.split(".");
  return parts[parts.length - 1];
}


export function isImage(filename) {
  var ext = getExtension(filename);
  switch (ext.toLowerCase()) {
    case "jpg":
      return true;
    case "jpeg":
      return true;
    case "png":
      return true;
    case "jp2":
      return true;
    case "jpx":
      return true;
    default:
      return false;
  }
}

export function getBase64(file) {
  return new Promise((resolve, reject) => {
    if (file == null) {
      resolve(null);
    } else {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = error => reject(error);
    }
  });
}

export function getMasterImage(product, endpoint) {

  let masterid = getMasterId(product);
  if (masterid == null) {
    return "http://via.placeholder.com/200";
  }
  return apiGetParamsReplace(endpoint, [masterid]);
}

export function getMasterId(product) {
  let masterid = "";
  let array = product.multiProductPhotos;
  for (let index = 0; index < array.length; index++) {
    if (array[index].isDefaultImage) {
      masterid = array[index].multiPhotoId;
      break;
    }
  }
  if (masterid === "") {
    return null;
  }

  return masterid;
}

export function getMasterProduct(product) {
  let array = product.multiProductPhotos;
  for (let index = 0; index < array.length; index++) {
    if (array[index].isMasterImage) {
      return array[index];
    }
  }
  return null;
}

export function tryParseJSON(jsonString) {
  try {
    var o = JSON.parse(jsonString);
    if (o && typeof o === "object") {
      return o;
    }
  } catch (e) { }

  return false;
}

export var isAO = val =>
  val instanceof Array || val instanceof Object ? true : false;

export function getMotivId(multiPhotoId) {
  if (multiPhotoId == null) return "";

  multiPhotoId = multiPhotoId.toString();
  let pad = 7 - multiPhotoId.length;

  while (pad > 0) {
    multiPhotoId = '0' + multiPhotoId;
    pad--;
  }

  let position = 3;
  return multiPhotoId.substring(0, position) + '-' + multiPhotoId.substring(position);
}

export function testIfUndefined(t) {
  //defining a function
  if (t === undefined) {
    //if t=undefined, call tt
    console.log(t); //call t
  }
  return t;
}

export function remove(str, startIndex, count) {
  return str.substr(0, startIndex) + str.substr(startIndex + count);
}
export function truncate(str, num) {
  if (str.length > num) {
    return str.slice(0, num) + "...";
  }
  else {
    return str;
  }
}


export function isEqual(value, other) {
  // Get the value type
  var type = Object.prototype.toString.call(value);

  // If the two objects are not the same type, return false
  if (type !== Object.prototype.toString.call(other)) return false;

  // If items are not an object or array, return false
  if (['[object Array]', '[object Object]'].indexOf(type) < 0) return false;

  // Compare the length of the length of the two items
  var valueLen = type === '[object Array]' ? value.length : Object.keys(value).length;
  var otherLen = type === '[object Array]' ? other.length : Object.keys(other).length;
  if (valueLen !== otherLen) return false;

  // Compare two items
  var compare = function (item1, item2) {

    // Get the object type
    var itemType = Object.prototype.toString.call(item1);

    // If an object or array, compare recursively
    if (['[object Array]', '[object Object]'].indexOf(itemType) >= 0) {
      if (!isEqual(item1, item2)) return false;
    }

    // Otherwise, do a simple comparison
    else {

      // If the two items are not the same type, return false
      if (itemType !== Object.prototype.toString.call(item2)) return false;

      // Else if it's a function, convert to a string and compare
      // Otherwise, just compare
      if (itemType === '[object Function]') {
        if (item1.toString() !== item2.toString()) return false;
      } else {
        if (item1 !== item2) return false;
      }

    }
  };

  // Compare properties
  if (type === '[object Array]') {
    for (var i = 0; i < valueLen; i++) {
      if (compare(value[i], other[i]) === false) return false;
    }
  } else {
    for (var key in value) {
      if (value.hasOwnProperty(key)) {
        if (compare(value[key], other[key]) === false) return false;
      }
    }
  }

  // If nothing failed, return true
  return true;

}

export function isEmpty(obj) {
  for (var key in obj) {
    if (obj.hasOwnProperty(key))
      return false;
  }
  return true;
}

export function compareStrings(a, b) {
  // Assuming you want case-insensitive comparison
  a = a.toLowerCase();
  b = b.toLowerCase();

  return (a < b) ? -1 : (a > b) ? 1 : 0;
}


export function getNumberOfWeek(date) {
  const today = date == null ? new Date() : date;
  // today.setDate(today.getDate() - 7);
  const firstDayOfYear = new Date(today.getFullYear(), 0, 1);
  const pastDaysOfYear = (today - firstDayOfYear) / 86400000;
  let r = Math.ceil((pastDaysOfYear + firstDayOfYear.getDay() + 1) / 7);
  return [2021,2022].includes(date.getFullYear())  ? r - 1 : r;
}

export function pointsToPx(points) {
  return points * 1.333333;
}
export function pxToPoints(px) {
  return px * 0.75;
}
export function pointsToMM(points) {
  return points * 0.3527777778;
}
export function mmToPx(mm, dpi) {
  return (mm * dpi) / 25.4;
}
export function pxToMM(px, dpi) {
  return (px * 25.4) / dpi;
}

export function toJsonLocal(momentDate) {
  function addZ(n) {
    return (n < 10 ? '0' : '') + n;
  }
  return momentDate._d.getFullYear() + '-' +
    addZ(momentDate._d.getMonth() + 1) + '-' +
    addZ(momentDate._d.getDate());
}

export function validateUrl(value) {
  const expression = /(ftp|http|https):\/\/(\w+:{0,1}\w*@)?(\S+)(:[0-9]+)?(\/|\/([\w#!:.?+=&%@!\-\/]))?/;
  var regexp = new RegExp(expression);
  return regexp.test(value);
}

export function datetimeToFormat1(datetimeVal) {
  var d = new Date(datetimeVal);

  if (d == null) return null;
  // d. 15/4 kl. 15:16
  var r = "d. ";
  r += d.getDate();
  r += "/";
  r += d.getMonth()+1;
  r += " kl. ";
  r += d.getHours();
  r += ":";
  r += d.getMinutes();

  return r;

}

export function combineQueryParameters(idsArr) {
  if (!Array.isArray(idsArr)) {
    return;
  }

  idsArr = idsArr.map((id, index) => {
    if (index === 0) {
      return `p=${id}`;
    } else {
      return `&p=${id}`;
    }
  });
  idsArr = idsArr.toString().replace(/,/g, "");

  return idsArr;
}

export function stringIsEmpty(string) {
  if (!(/\S/.test(string))) {
    return true;
  } else {
    return false;
  }
}

export function getEANS(array) {
  return array.map(x => x.substr(0, x.indexOf("(")));
}

export function removeDuplicatesFromString(string, regex) {
  return string.replace(regex, "");
}

export function debounce(func, wait, immediate) {
  let timeout;
  return function () {
    const context = this, args = arguments;
    const later = function () {
      timeout = null;
      if (!immediate) func.apply(context, args);
    };
    const callNow = immediate && !timeout;
    clearTimeout(timeout);
    timeout = setTimeout(later, wait);
    if (callNow) func.apply(context, args);
  };
};

export function showSuccessNotification(message) {
  store.dispatch(
    addToastMessage({
      message: message,
      type: "success"
    })
  )
}

export function showWarningNotification(message) {
  store.dispatch(
    addToastMessage({
      message: message,
      type: "warning"
    })
  )
}

export function showErrorNotification(message) {
  store.dispatch(
    addToastMessage({
      message: message,
      type: "error"
    })
  )
}

export function getDate(date) {
  return new Date(date).toJSON().split("T")[0];
}

export function getDateDatePicker(date) {
  return new Date(date.setHours(date.getHours() + 2)).toJSON().split("T")[0];
}


export function receiveFile(data, item) {
  return {
    Filename: item.name,
    File: data
  };
}

export async function getFileBase(files) {
  return await Promise.all(
    files.map(async item => {
      const data = await getBase64(item);
      return receiveFile(data, item);
    })
  )
}

export function isActive(dateFrom, dateTo) {
  let dateNow = new Date();
  dateNow.setHours(0, 0, 0, 0);
  const dateFromFormatted = getDateFromString(dateFrom);
  const dateToFormatted = getDateFromString(dateTo);

  return dateNow >= dateFromFormatted && dateToFormatted >= dateNow;
};

function getDateFromString(dateString) {
  const date = dateString ? new Date(dateString) : new Date();
  return date.setHours(0, 0, 0, 0);
};

export function activePhotoFutureDate(validFrom) {

  return !validFrom || new Date() > Date.parse(validFrom) ? null : validFrom
};

export async function urltoFile(url, filename, mimeType) {
  return (fetch(url)
    .then(function (res) { return res.arrayBuffer(); })
    .then(function (buf) { return new File([buf], filename, { type: mimeType }); })
  );
}

export function getFirstTwoDigitsFromJob(jobNumber) {
  const re = /^.{0,2}/g;
  return parseInt(jobNumber.toString().match(re)[0]);
}

export function validateSearchString(str) {
  let isValid = true;

  if(str === null)
    str = "";

  if (typeof (str) === "object" || typeof (str) === "number") {
    isValid = false;
  }

  // check if string does not contain not allowed characters, first line fix improve later
  const match1 = str.match("{");
  const match2 = str.match("}");
  const match3 = str.match("\\[");
  const match4 = str.match("\\]");

  if (match1 || match2 || match3 || match4) {
    isValid = false;
  }

  return isValid;
}

export function sortArrayAlphabetically(array) {
  return array.sort((a, b) => a.name > b.name ? 1 : -1);
}

export function findDUplicatesInArray(array) {
  return array.filter((item, index) => array.indexOf(item) !== index);
}

