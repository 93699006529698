import {
  endpoints,
  apiGetParamsReplace
} from "../../../constants/services.constants";
import {
  fetchGetWT,
  fetchPatchWT,
  fetchPutWT
} from "../../../helpers/HttpHelper";


// OPEN EDIT FORM
export const EDIT_OPEN_BEGIN = "PRODUCT_EDIT_OPEN_BEGIN";
export const EDIT_CLOSE_BEGIN = "PRODUCT_EDIT_CLOSE_BEGIN";

export const editDialogOpenBegin = id => ({
  type: EDIT_OPEN_BEGIN,
  payload: { id }
});

export const editDialogCloseBegin = () => ({
  type: EDIT_CLOSE_BEGIN
});

export function editDialogOpen(id) {
  return dispatch => {
    dispatch(editDialogOpenBegin(id));
  };
}
export function editDialogClose() {
  return dispatch => {
    dispatch(editDialogCloseBegin());
  };
}

// GET BY ID
export const GET_PRODUCT_BEGIN = "PRODUCT_GET_PRODUCT_BEGIN";
export const GET_PRODUCT_SUCCESS = "PRODUCT_GET_PRODUCT_SUCCESS";
export const GET_PRODUCT_FAILURE = "PRODUCT_GET_PRODUCT_FAILURE";

export const getProductBegin = () => ({
  type: GET_PRODUCT_BEGIN
});

export const getProductSuccess = product => ({
  type: GET_PRODUCT_SUCCESS,
  payload: { product }
});

export const getProductFailure = error => ({
  type: GET_PRODUCT_FAILURE,
  payload: { error }
});

export function getProduct(productId) {
  const endpoint = apiGetParamsReplace(endpoints.PRODUCT_SINGLE, [productId]);
  return dispatch => {
    dispatch(getProductBegin());
    return fetchGetWT(
      endpoint,
      json => dispatch(getProductSuccess(json)),
      error => dispatch(getProductFailure(error))
    );
  };
}

// UPDATE MASTER
export const PATCH_MASTER_BEGIN = "PRODUCT_PATCH_MASTER_BEGIN";
export const PATCH_MASTER_SUCCESS = "PRODUCT_PATCH_MASTER_SUCCESS";
export const PATCH_MASTER_FAILURE = "PRODUCT_PATCH_MASTER_FAILURE";

export const patchMasterBegin = () => ({
  type: PATCH_MASTER_BEGIN
});

export const patchMasterSuccess = productPhotos => ({
  type: PATCH_MASTER_SUCCESS,
  payload: { productPhotos }
});

export const patchMasterFailure = error => ({
  type: PATCH_MASTER_FAILURE,
  payload: { error }
});

export function patchMaster(multiProductId, multiProductPhotoId) {
  const endpoint = apiGetParamsReplace(
    endpoints.PRODUCT_SET_MASTER,
    ...[multiProductId, multiProductPhotoId]
  );
  return dispatch => {
    dispatch(patchMasterBegin());
    return fetchPatchWT(
      endpoint,
      null,
      json => dispatch(patchMasterSuccess(json)),
      error => dispatch(patchMasterFailure(error))
    );
  };
}

// UPDATE PRODUCT
export const UPDATE_PRODUCT_BEGIN = "PRODUCT_UPDATE_PRODUCT_BEGIN";
export const UPDATE_PRODUCT_SUCCESS = "PRODUCT_UPDATE_PRODUCT_SUCCESS";
export const UPDATE_PRODUCT_FAILURE = "PRODUCT_UPDATE_PRODUCT_FAILURE";

export const updateProductBegin = () => ({
  type: UPDATE_PRODUCT_BEGIN
});

export const updateProductSuccess = () => ({
  type: UPDATE_PRODUCT_SUCCESS
});

export const updateProductFailure = error => ({
  type: UPDATE_PRODUCT_FAILURE,
  payload: { error }
});

export function updateProduct(product, id, callback) {
  const endpoint = apiGetParamsReplace(endpoints.PRODUCT_EDIT, ...[id]);
  return dispatch => {
    dispatch(updateProductBegin());
    return fetchPutWT(
      endpoint,
      JSON.stringify(product),
      json => {
        dispatch(updateProductSuccess(json));
        callback();
      },
      error => dispatch(updateProductFailure(error))
    );
  };
}
