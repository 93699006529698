import React, { Component } from "react";

import { connect } from "react-redux";
import { withStyles } from "@material-ui/core/styles";

import classnames from "classnames";
import Card from "@material-ui/core/Card";
import CardMedia from "@material-ui/core/CardMedia";
import CardContent from "@material-ui/core/CardContent";
import CardActions from "@material-ui/core/CardActions";
import IconButton from "@material-ui/core/IconButton";

import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import Typography from "@material-ui/core/Typography";

import { Grow } from "@material-ui/core";
import OfferProduct from "./OfferProduct";

const styles = theme => ({
  childOffer: {
    backgroundColor: "#e3f2fd !important"
  },
  offerContent: {
    height: 322,
    float: "left",
    width: 250,
    paddingBottom: 20,
    marginBottom: 25,
    borderRight: "1px solid white",
    backgroundColor: "#ffffff",
    boxShadow: "0px 1px 5px 0px rgba(0, 0, 0, 0.2),0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 3px 1px -2px rgba(0, 0, 0, 0.12)"
  },
  textCardTable: {
    fontSize: 10
  },
  inlineCard: {
    padding: 0, // '0 20px',
    float: "left",
    width: 200,
    boxShadow: "none"
  },
  offerCard: {
    padding: 0,
    float: "left",
    width: 175,
    boxShadow: "none !important",
    overflow: "visible !important",
  },
  backgroundHeadline: {
    backgroundColor: "#D8D8D8",
    width: 250,
    height: 38
  },
  backgroundHeadlineSammixManus: {
    backgroundColor: "#3ea832",
    width: 250,
    height: 38
  },
  cardcontent: {
    height: "120px"
  },
  metadata: {
    fontSize: "0.675rem"
  },
  media: {
    backgroundSize: "contain",
    height: 0,
    paddingTop: "56.25%" // 16:9
  },
  actions: {
    display: "flex"
  },
  expand: {
    transform: "rotate(270deg)",
    marginLeft: "auto",
    transition: theme.transitions.create("transform", {
      duration: theme.transitions.duration.shortest
    })
  },
  expandOpen: {
    transform: "rotate(90deg)"
  }
});

class OfferChild extends Component {
  constructor(props) {
    super(props);

    this.state = {
      expanded: false,
      anchorEl: null
    };
  }

  handleExpandClick = () => {
    this.setState(state => ({ expanded: !state.expanded }));
  };

  handleAnchorClick = event => {
    this.setState({ anchorEl: event.currentTarget });
  };

  handleAnchorClose = () => {
    this.setState({ anchorEl: null });
  };

  render() {
    const { classes, offer, index, totalChilds, isMixMatch, isSammixManus, imageUrl, deactivatedPhotos } = this.props;

    let lastChild = false;
    if (totalChilds === index + 1) {
      lastChild = true;
    }
    return (
      <>
        <Grow in={this.props.expanded} mountOnEnter unmountOnExit>
          <div>
            <div
              className={classes.offerContent}
              style={
                totalChilds === index + 1
                  ? { clipPath: "inset(-5px -5px -5px 0px)" }
                  : { clipPath: "inset(-5px 0px -5px 0px)" }
              }
            >
              <div className={isSammixManus ? classes.backgroundHeadlineSammixManus : classes.backgroundHeadline}></div>
              <div className="cardTypographyFill" />
              <Card className={classes.offerCard}>
                <CardMedia
                  className={classes.media}
                  onClick={() => console.log("offer", offer)}
                  // Get image from photo combination service by sending offer id
                  image={imageUrl}
                />
                <CardContent className={classes.cardcontent}>
                  <Typography
                    variant="subtitle2"
                    gutterBottom
                    className="max2lines"
                    style={{
                      fontSize: "0.875rem",
                      fontWeight: "bold",
                      height: "64px"
                    }}
                  >
                    {offer.description}
                  </Typography>
                  {offer.prices.price ?
                    (<table className={"textCardTable"}>
                      <tbody>
                        <tr>
                          <th>Pris:</th>
                          <td>{offer.prices.price} kr.</td>
                        </tr>
                      </tbody>
                    </table>) : undefined
                  }
                </CardContent>
                <CardActions className={classes.actions} disableActionSpacing>

                </CardActions>
              </Card>
              <div className="OfferDivider">
                {
                  offer.products.length > 0 ? (
                    <IconButton
                      className={classnames(classes.expand, {
                        [classes.expandOpen]: this.state.expanded
                      })}
                      onClick={this.handleExpandClick}
                      aria-expanded={this.state.expanded}
                      aria-label="Show more"
                      style={{ visible: "hidden" }}
                    >
                      <ExpandMoreIcon style={{ visible: "hidden" }} />
                    </IconButton>) : undefined
                }
              </div>
            </div>
            {offer.products.map((product, index) => (
              <Grow
                key={index}
                in={this.state.expanded}
                style={{ transformOrigin: "0 0 0" }}
                {...(this.state.expanded ? { timeout: 1000 } : {})}
              >
                <OfferProduct
                  onProductChange={() => this.props.onOfferChange(offer.parentOfferId, false)}
                  product={product}
                  expanded={this.state.expanded}
                  index={index}
                  offer={offer}
                  totalProducts={offer.products.length}
                  isLastChild={lastChild}
                  isMixMatch={isMixMatch}
                  isSammixManus={isSammixManus}
                  categories={this.props.categories}
                  deactivatedPhotos={this.props.deactivatedPhotos}
                />
              </Grow>
            ))}
          </div>
        </Grow>
      </>
    );
  }
}

export default connect(state => state)(withStyles(styles)(OfferChild));
