import { apiGetParamsReplace, endpoints } from "../../../constants/services.constants";
import { fetchPostWT, _request } from "../../../helpers/HttpHelper";


export async function sendToOca(publicationId, offerId, customerId) {
  const body = {
    publicationId: publicationId,
    offerId: offerId,
    customerId: customerId
  };
  return _request({
    method: "POST",
    endpoint: endpoints.PHOTO_SEND_TO_OCA,
    body: JSON.stringify(body),
    skipBodyParsing: true,
    throwError: true
  });
}

export async function getOffersForPublication(publicationId) {
  return _request({
    method: "GET",
    endpoint: apiGetParamsReplace(endpoints.OFFER_GET_FOR_PUBLICATION, publicationId, true, true, false)
  });
}

export async function getOffer(offerId) {
  return _request({
    method: "GET",
    endpoint: apiGetParamsReplace(endpoints.OFFER_SINGLE_V2, offerId)
  });
}

export async function getPublication(publicationId) {
  return _request({
    method: "GET",
    endpoint: apiGetParamsReplace(endpoints.PUBLICATION_SINGLE_V2, publicationId)
  });
}

export async function getPhoto(photoId) {
  return _request({
    method: "GET",
    endpoint: apiGetParamsReplace(endpoints.PHOTO_INFO_SINGLE, photoId)
  });
}

export async function getPhotos(photoIds) {
  return _request({
    method: "POST",
    endpoint: endpoints.PHOTO_INFO_MULTIPLE,
    body: JSON.stringify(photoIds)
  });
}

export async function clipPhoto(photoId, jobId) {
  return _request({
    method: "POST",
    endpoint: apiGetParamsReplace(endpoints.PHOTOCLIPPING_ADD_PHOTO, `?jobId=${jobId}&photoId=${photoId}`)
  });
}