import { apiGetParamsReplace, endpoints } from "../../../constants/services.constants";
import { fetchGetWT, headersJsonToken, _request } from "../../../helpers/HttpHelper";

export const getProductName = async (productNumber) => {
    const endpoint = apiGetParamsReplace(endpoints.PRODUCT_GET_BY_EAN, productNumber);
    return fetchGetWT(
        endpoint,
        response => {
            return response;
        }
    );
}

export async function getOfferProducts(offerProductIds) {
    return _request({
        method: "POST",
        endpoint: apiGetParamsReplace(endpoints.OFFER_PRODUCT_MULTIPLE_V2, false, false),
        body: JSON.stringify(offerProductIds)
    });
}

export async function getPhotoOrderList(orderIds) {
    return _request({
        method: "POST",
        endpoint: endpoints.PHOTO_FETCH_LISTE,
        body: JSON.stringify(orderIds)
    });
}

export async function sendPhotoOrderList(list) {
    return _request({
        method: "POST",
        endpoint: endpoints.PHOTO_SET_LISTE,
        body: JSON.stringify(list),
        skipBodyParsing: true,
        throwError: true
    });
}

export const validateEAN = async (EAN) => {
    return _request({
        method: "GET",
        endpoint: apiGetParamsReplace(endpoints.PRODUCT_IS_EAN_VALID, EAN),
    });
}

export async function searchPhotoOrders(searchRequest) {
    return _request({
        method: "POST",
        endpoint: endpoints.SEARCH_PHOTO_ORDERS,
        body: JSON.stringify(searchRequest),
        throwError: true
    });
}

export async function updatePhotoOrderStatus(photoOrderId, statusId) {
    return _request({
        method: "PATCH",
        endpoint: apiGetParamsReplace(endpoints.PHOTO_ORDER_SET_STATUS, photoOrderId, statusId),
        throwError: true,
        skipBodyParsing: true
    });
}

export async function updatePhotoValidToDate(photoId, validDate) {
    return _request({
        method: "PATCH",
        endpoint: apiGetParamsReplace(endpoints.PHOTO_UPDATE_V2_VALIDDATE, photoId,validDate),
        throwError: true,
        skipBodyParsing: true
    });
}

export const fetchPhotoOrderV2 = async (multiPhotoOrderId) => {
    const endpoint = apiGetParamsReplace(endpoints.PHOTO_FOTOTRACKER_SINGLE_V2, [
        multiPhotoOrderId,
    ]);
    return await _request({
        method: "GET",
        endpoint: endpoint
    });
}

export const fetchUser = async (userId) => {
    return await _request({
        method: "GET",
        endpoint: apiGetParamsReplace(endpoints.USER_SINGLE, userId),
    });
}

export const fetchPhoto = async (photoId) => {
    return await _request({
        method: "GET",
        endpoint: apiGetParamsReplace(endpoints.PHOTO_INFO_SINGLE, photoId),
    });
}

export async function createBrief(photoOrderId, instructions) {
    return await _request({
        method: "POST",
        endpoint: apiGetParamsReplace(endpoints.PHOTO_ORDER_ADD_BRIEF, photoOrderId),
        body: JSON.stringify(instructions)
    });
}

export async function deletePhotoOrder(photoOrderId) {
    return _request({
        method: "DELETE",
        endpoint: apiGetParamsReplace(endpoints.PHOTO_FOTOTRACKER_DELETE_ORDER, photoOrderId),
        throwError: true,
        skipBodyParsing: true
    });
}

export async function recoverPhotoOrder(photoId) {
    return _request({
        method: "PATCH",
        endpoint: apiGetParamsReplace(endpoints.PHOTO_FOTOTRACKER_RECOVER, photoId),
        throwError: true,
        skipBodyParsing: true
    });
}


export async function getDeactivatedOfferPhotos(publicationId, offerId){
    return _request({
        method: "GET",
        endpoint: apiGetParamsReplace(endpoints.PUBLICATION_OFFER_GET_DEACTIVATED_PHOTOS, publicationId, offerId),
        throwError: true,
    });
}

export async function setPhotoIsDeactivated(publicationId, photoId, offerId, isDeactivated) {
    return _request({
        method: "PATCH",
        endpoint: apiGetParamsReplace(endpoints.PUBLICATION_SET_PHOTO_IS_DEACTIVATED, publicationId),
        throwError: true,
        body: JSON.stringify({PhotoId: photoId, OfferId: offerId, IsDeactivated: isDeactivated}),
        skipBodyParsing: true
    });
}

export async function changeOrderStatusForPhoto(currentOrderStatus, photoId, photoType, newStatus) {
    return _request({
        method: "PATCH",
        endpoint: apiGetParamsReplace(endpoints.PHOTO_FOTOTRACKER_SET_STATUS_FOR_PHOTO, currentOrderStatus, photoId, photoType, newStatus),
        throwError: true,
        skipBodyParsing: true
    });
}


export async function getSinglePhotoOrderFromSolr(photoOrderId) {
    return _request({
        method: "GET",
        endpoint: apiGetParamsReplace(endpoints.SEARCH_PHOTO_ORDER_SINGLE, photoOrderId),
        throwError: true,
        skipBodyParsing: false
    });
}

export async function getPhotoOrderTags() {
    return _request({
        method: "GET",
        endpoint: apiGetParamsReplace(endpoints.PHOTO_ORDER_TAGS)
    });
}

export async function getPhotoTags() {
    return _request({
        method: "GET",
        endpoint: apiGetParamsReplace(endpoints.PHOTO_TAGS_GET_ALL)
    });
}
export async function getProductTags() {
    return _request({
        method: "GET",
        endpoint: apiGetParamsReplace(endpoints.PRODUCT_TAGS_GET_ALL)
    });
}
