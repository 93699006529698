import React from "react";
import ExclusiveIcon from "../components/ExclusiveIcon";
export default function ExclusiveImageIdentification({ exclusiveForCustomerIdsArray }) {
    return (
        <div style={{
            display: "flex",
            justifyContent: "end",
            alignItems: "center",
            height: 30
        }}>
            <ExclusiveIcon
                exclusiveForCustomerIds={exclusiveForCustomerIdsArray}
            />
        </div>
    )
}