import React from 'react';
import {styles} from "../shared";
import { Typography, Button } from '@material-ui/core';


export default function SectionHeader({
    section,
    tilbage,
    defaultText,
    primaryText
}) {
    return(
        <div style={{ 
            width: '100%',
            display: 'inline-flex', 
            justifyContent: 'space-between' 
        }}>
            <Typography 
                variant="h4" 
                style={styles.viewText}
            >
                {!section ? defaultText : primaryText}
            </Typography>
            <Button variant="text" color="primary" size="large" style={styles.viewButton}
                onClick={tilbage}
            >
                {"<"} Tilbage
            </Button>
        </div>
    )
}