
// ADD PRODUCT TO ARRAY
export const ADD_PRODUCT_BEGIN = "OFFER_ADD_LOGO_BEGIN";

export const addProductBegin = product => ({
  type: ADD_PRODUCT_BEGIN,
  payload: { product }
});

export function addProduct(product) {
  return addProductBegin(product);
}

// REMOVE PRODUCT FROM ARRAY
export const REMOVE_PRODUCT_BEGIN = "OFFER_REMOVE_PRODUCT_BEGIN";

export const removeProductBegin = product => ({
  type: REMOVE_PRODUCT_BEGIN,
  payload: { product }
});

export function removeProduct(product) {
  return removeProductBegin(product);
}

// CLEAN EVERYTHING
export const OFFER_CLEAN = "OFFER_CLEAN";

export const cleanOfferSuccess = () => ({
  type: OFFER_CLEAN
});

export function cleanOffer() {
  return dispatch => dispatch(cleanOfferSuccess());
}


