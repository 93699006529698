export class PhotoCombinationPolice {

    constructor(photoCombination) {
        this.photoCombination = photoCombination;
        this.result = [];
    }

    defaultEvaluation() {
        let normalizedObjects = this.normalizedObjects();
        // let jsonTemplate = this.photoCombination.getJsonTemplate();

        // console.log( this.photoCombination.getJsonTemplate());
        // console.log(jsonTemplate);
        // console.log(jsonObjects);
        this.isOutboundAtHeight(
            this.photoCombination.canvasHeight,
            normalizedObjects);

        this.is50PercentHeightUsed(
            this.photoCombination.canvasHeight,
            normalizedObjects);
        this.is60PercentHeightUsed(
            this.photoCombination.canvasHeight,
            normalizedObjects);
        this.is70PercentHeightUsed(
            this.photoCombination.canvasHeight,
            normalizedObjects);
            this.is80PercentHeightUsed(
                this.photoCombination.canvasHeight,
                normalizedObjects);
        return this.result;
    }


    normalizedObjects() {
        let j = this.photoCombination.getJsonTemplate();
        // console.log(j.objects)
        j.objects.forEach(element => {
            element.width = element.width * element.scaleX;
            element.scaleX = 1;
            element.height = element.height * element.scaleY;
            element.scaleY = 1;
        });
        // console.log(j)

        return j.objects;
    }

    isOutboundAtHeight(canvasHeight, canvasObjects) {
        // console.log(canvasObjects);
        let tolerance = 10;
        canvasObjects.forEach(element => {
            let sum = element.height + element.top - tolerance;
            if (sum > canvasHeight) return this.result.push("OutboundAtHeight");
        });
    }

    
    is50PercentHeightUsed(canvasHeight, canvasObjects) {
        let any = false
        canvasObjects.forEach(element => {
            let sum = element.height + element.top;
            let percentage = sum / canvasHeight;
            // console.log(percentage);
            if (percentage >= 0.5) {
                any = true;
            }
        });
        if (!any)
            this.result.push("50PercentHeightUsed");
    }
    is60PercentHeightUsed(canvasHeight, canvasObjects) {
        let any = false
        canvasObjects.forEach(element => {
            let sum = element.height + element.top;
            let percentage = sum / canvasHeight;
            // console.log(percentage);
            if (percentage >= 0.6) {
                any = true;
            }
        });
        if (!any)
            this.result.push("60PercentHeightUsed");
    }
    is70PercentHeightUsed(canvasHeight, canvasObjects) {
        let any = false
        canvasObjects.forEach(element => {
            let sum = element.height + element.top;
            let percentage = sum / canvasHeight;
            // console.log(percentage);
            if (percentage >= 0.7) {
                any = true;
            }
        });
        if (!any)
            this.result.push("70PercentHeightUsed");
    }
    is80PercentHeightUsed(canvasHeight, canvasObjects) {
        let any = false
        canvasObjects.forEach(element => {
            let sum = element.height + element.top;
            let percentage = sum / canvasHeight;
            // console.log(percentage);
            if (percentage >= 0.8) {
                any = true;
            }
        });
        if (!any)
            this.result.push("70PercentHeightUsed");
    }
}