import { Dialog, DialogContent, DialogTitle, Typography, withStyles } from '@material-ui/core';
import React, { useEffect, useState } from 'react'
import { photoAngleEnum } from '../../../../../constants/enums';
import { getBase64,sortArrayAlphabetically } from '../../../../../helpers/UtilHelper';
import { LoadingLogo } from '../../../../shared/Loading';
import { createProduct, getCategories, getCustomers } from '../../Requests';
import StepConfirmation from './StepConfirmation';
import StepInformation from './StepInformation';
import StepMetadata from './StepMetadata';
import StepperAddV2 from './StepperAddV2';
import StepPhoto from './StepPhoto';



import { getProductTags } from "../../../../sections/fototracker/SharedRequests";

const styles = {
  dialogPaper: {
    minHeight: '80vh',
    maxHeight: '80vh',
  },
  center: {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: `translate(-50%,-50%)`
  }
};

function DialogAddProductV2(props) {

  const { classes, handleClose } = props;
  const [information, setInformation] = useState(null);
  const [metadata, setMetadata] = useState(null);
  const [photosData, setPhotosData] = useState(null);
  const [stepReady, setStepReady] = useState(true);
  const [customers, setCustomers] = useState(null);
  const [categories, setCategories] = useState(null);
  const [saving, setSaving] = useState(false);
  const [saveResult, setSaveResult] = useState(null);
  const [finished, setFinished] = useState(false);
  const [tags, setTags] = useState(null);

  useEffect(() => {
    init();
  }, []);

  async function init() {
    const categories = await getCategories();
    const customers = await getCustomers();
    setCustomers(customers);
    setCategories(categories);

      let tags = await getProductTags();
      tags = sortArrayAlphabetically(tags);
      setTags(tags);
  }


  async function handleSave() {
    setSaving(true);
    setSaveResult(null);

    if (!information || !metadata || !photosData) {
      setSaving(false);
      return;
    }

    const originalLeverandor = information.leverandor.replace("("+information.gs1InformationProvider+")", "");
    const fieldValues = metadata.map(x => ({ name: x.fieldName, value: x.fieldValue }));
    fieldValues.push({ name: "Leverandørnavn", value: originalLeverandor.trim() });

    const product = {
      productNumber: information.productNumber,
      name: information.name,
      description: information.description,
      categoryId: information.category.id,
      fieldValues: fieldValues,
      importGs1Data: false,
      tags:information.tags
    };

    // add photo info
    switch (photosData.source) {
      case "gs1":
        product.importGs1Data = true;
        product.customerId = photosData.customerId;
        product.photoInfo = {
          imageUrl: photosData.url,
          photoType: photosData.photoType
        };
        break;
      case "upload":
        product.customerId = photosData.customerId;
        product.photoInfo = {
          imageDataBase64: await getBase64(photosData.photo),
          photoType: photosData.photoType
        };
        break;
      case "order":
        product.customerId = photosData.customerId;
        product.photoOrderInfo = {
          jobId: photosData.jobNumber,
          validFrom: photosData.validFrom,
          validTo: photosData.validTo,
          angle: photoAngleEnum[photosData.angle].id,
          clipPhoto: photosData.clipPhoto
        };
        break;
      case "no photo":
        break;
      default:
        throw Error("Unsupported type");
    }

    try {
      const productId = await createProduct(product);
      setSaveResult("Produktet er oprettet med id: " + productId);
      setFinished(true);
    } catch (e) {
      setSaveResult(e.message);
    } finally {
      setSaving(false);
    }
  };

  function getStepContent(step) {
    switch (step) {
      case 0:
        return <StepInformation
          startObject={information}
          returnFinalObject={setInformation}
          stepReady={stepReady}
          setStepReady={setStepReady}
          categories={categories}
          tags={tags}
        />;
      case 1:
        return information == null ?
          <Typography>Henter information...</Typography> :
          <StepMetadata
            startObject={metadata}
            information={information}
            returnFinalObject={setMetadata}
            stepReady={stepReady}
            setStepReady={setStepReady}
          />;
      case 2:
        return <StepPhoto
          startObject={photosData}
          returnFinalObject={setPhotosData}
          stepReady={stepReady}
          setStepReady={setStepReady}
          customers={customers}
          information={information}
        />;
      case 3:
        return <StepConfirmation
          information={information}
          photosData={photosData}
          saving={saving}
          saveResult={saveResult}
        />
      default:
        return 'Wrong way :D';
    }
  }

  function getDialogContent() {
    if (!customers || !categories) {
      return <DialogContent className={classes.center}>
        <LoadingLogo style={{ width: "auto", height: 150 }} />
      </DialogContent>;
    }

    return <StepperAddV2
      steps={['Information', 'Yderligere felter', 'Foto', 'Bekræftelse']}
      blockNext={!stepReady}
      blockSave={saving === true}
      processFinished={finished}
      handleClose={() => finished !== true ? props.handleClose() : props.handleClose(information.productNumber)}
      handleSave={handleSave}
      getStepContent={getStepContent}
      // defaultStep={2}
      isStepOptional={(step) => {
        return step === 1 || step === 2;
      }}
    />
  }

  return (
    <Dialog
      classes={{ paper: classes.dialogPaper }}
      onClose={handleClose}
      open={true}
      maxWidth={"lg"}
      fullWidth={true}
      disableBackdropClick
    >
      <DialogTitle >Opret nyt produkt</DialogTitle>
      {getDialogContent()}
    </Dialog>
  );
}

export default withStyles(styles)(DialogAddProductV2);

