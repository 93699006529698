import React from "react";

export default function SliderProgressBar({ currentPage, pageCount }) {
    return (
        <div
            style={{
                position: 'relative',
                bottom: 0,
                backgroundColor: 'rgba(34, 39, 43, 0.6)',
                width: '100%',
                height: 16,
                lineHeight: 1.3
            }}
        >
            <span style={{
                fontWeight: 500,
                fontSize: 14,
                margin: 'auto',
                position: 'absolute',
                transform: 'translate(-50%, -48%)',
                left: '50%',
                top: '52%',
                zIndex: 2
            }}>{currentPage} / {pageCount}</span>
            <div style={{
                height: '100%',
                backgroundColor: 'rgb(97, 102, 105)',
                transition: 'transform 0.2s ease 0s',
                willChange: 'transform',
                transformOrigin: '0px 0px',
                transform: `scaleX(${currentPage / pageCount})`,
            }}></div>
        </div>
    )
}