import React, { useState, useEffect } from 'react';
import Button from '@material-ui/core/Button';
import DialogTitle from '@material-ui/core/DialogTitle';
import Dialog from '@material-ui/core/Dialog';
import { connect } from 'react-redux';
import { DialogContent, LinearProgress, DialogActions } from '@material-ui/core';
import { deleteDialogClose } from '../../../redux/actions/users/delete.users.actions';
import { fetchUsers } from '../../../redux/actions/users/users.actions';
import { fetchGetWT, fetchDeleteWT } from '../../../helpers/HttpHelper';
import { endpoints, apiGetParamsReplace } from '../../../constants/services.constants';
import { addToastMessage } from '../../../redux/actions/toasts.actions';


function DialogDelete(props) {
    // console.log(props)
    const [user, setUser] = useState(null);
    const [deleting, setDeleting] = useState(null);
    const [error, setError] = useState(null);

    useEffect(() => {
        if (props.usersReducer.deleteId != null) {
            const endpoint = apiGetParamsReplace(endpoints.USER_SINGLE, [props.usersReducer.deleteId]);

            fetchGetWT(
                endpoint,
                json => {
                    setUser(json);
                }
                ,
                callError => {
                    setUser({});

                    setErrorStringify(callError);

                    // props.dispatch(getUserFailure(error))
                });
        }
        return () => {
            // setUser(null);
        };

    }, [])
    function handleClose() {
        props.dispatch(deleteDialogClose());
    }

    function handleDelete() {
        const endpoint = apiGetParamsReplace(endpoints.USER_DELETE);

        setDeleting(true);
        fetchDeleteWT(
            endpoint,
            JSON.stringify({ id: user.id }),
            json => {
                setDeleting(false);
                if (json === "OK") {
                    props.dispatch(fetchUsers(() => handleClose()));
                    props.dispatch(addToastMessage({
                        message: "The user " + user.userName + " has been deleted.",
                        type: "success",
                        details: null
                    }))

                }
                else setErrorStringify(json)

                // setUser(json);
            }
            ,
            callError => {
                setDeleting(false);
                setErrorStringify(callError);
                // console.log(error)
                // props.dispatch(getUserFailure(error))
            });
        // props.dispatch(deleteDialogClose());
    }
    function setErrorStringify(msg) {
        setError(typeof msg === "string" ? msg : JSON.stringify(msg));
    }

    return (
        <Dialog open={true} onClose={handleClose} aria-labelledby="simple-dialog-title">
            <DialogTitle id="simple-dialog-title">Delete User</DialogTitle>
            {
                error != null ? <DialogContent>An error has been produced: {error}</DialogContent>
                    : user == null ? <LinearProgress />
                        : <>
                            <DialogContent>
                                Fullname: {user.fullname}<br />
                                Email: {user.email}
                            </DialogContent>
                            <DialogContent >Are you sure that you want to delete this user ?
                            <br />This action cannot be undone.</DialogContent>
                            <DialogActions>

                                <Button onClick={handleClose} disabled={deleting === true} color="primary" variant="contained">
                                    Go back
                              </Button>
                                <Button onClick={handleDelete} disabled={deleting === true || error != null} color="secondary" autoFocus variant="contained">
                                    Delete
                              </Button>
                            </DialogActions>
                        </>
            }
        </Dialog>
    );
}

export default connect(state => state)(DialogDelete);