import React, { useState, useContext } from 'react';
import { withStyles, TextField, Tooltip, Fab, Menu, MenuItem, Button } from '@material-ui/core';
import { FeedsContext } from '../../../contexts/FeedsContext';
import FilterProperty from './FilterProperty';
import AddIcon from "@material-ui/icons/Add";
import { getFilterRequest, createNewFilterRequest, patchFilterRequest, feedApplyFilter } from '../../Logic/requests';

const styles = () => ({
    operator: {
        textAlign: "center",
        marginTop: 20
    },
    addButton: {
        display: "block",
        margin: "0 auto",
        marginTop: 20
    },
    feedName: {
        marginTop: 35,
        width: "100%"
    },
});


function FeedProperties(props) {
    const {
        feeds: feedsState,
        selectedFeed: selectedFeedState,
        filterMaxLength: filterMaxLengthState,
        filterWasCreated: filterWasCreatedState,
        filterChild: filterChildState,
        selectedFilter: selectedFilterState,
        filterExists: filterExistsState,
        filterData: filterDataState,
        selectedOptions: selectedOptionsState,
        lastConnectorIndex: lastConnectorIndexState,
        filterStatistics: filterStatisticsState,
    } = useContext(FeedsContext);

    const [feeds] = feedsState;
    const [selectedIndex,] = selectedFeedState;
    const [filterChild,] = filterChildState;
    const [selectedFilter, setSelectedFilter] = selectedFilterState;
    const [filterProperties, setFilterProperties] = useState([]);
    const [anchorEl, setAnchorEl] = useState(undefined);
    const [selectedOptions, setSelectedOptions] = selectedOptionsState;
    const [lastFilterValue, setLastFilterValue] = useState([]);
    const [filterExists,] = filterExistsState;
    const [filterWasCreated, setFilterWasCreated] = filterWasCreatedState;
    const [filterData,] = filterDataState;
    const [filterMaxLength,] = filterMaxLengthState;
    const [lastConnectorIndex,] = lastConnectorIndexState;
    const [filterStatistics, setFilterStatistics] = filterStatisticsState;

    const { classes } = props;

    const maxSize = filterMaxLength;

    const openMenu = (e) => {
        setAnchorEl(e.currentTarget);
    }

    const closeMenu = () => {
        setAnchorEl(undefined);
    }

    const handleSelectOption = async (index, option) => {
        // Handle selection for a new filter and for existing
        !filterExists ? index = index - 1 : index = filterChild.length - 1;
        closeMenu();

        setSelectedOptions([...selectedOptions, option]);
        addNewFilterProperty(index);
    }

    const addNewFilterProperty = (index) => {
        let array = [];
        let isEdit = undefined;
        filterExists ? isEdit = true : isEdit = undefined;
        array.push(
            <>
                {index <= selectedOptions.length ? <div>{selectedOptions[index]}</div> : null}
                <FilterProperty isEdit={isEdit} />
            </>
        );
        setFilterProperties([...filterProperties, ...array]);
    }

    const generateFilterProperty = (value, virtualizedIndex) => {
        let text;
        switch (value) {
            case "and":
                text = "Og";
                break;
            case "or":
                text = "Eller";
                break;
            default:
                text = "None";
                break;
        }
        return (
            <>
                <div className={classes.operator}>{text}</div>
                <FilterProperty index={virtualizedIndex} />

                {/* Provide values from saved filter here*/}
            </>

        )
    }

    const generateNewPropertyEditFilter = (value, virtualizedIndex) => {
        let isEdit = undefined;
        filterExists ? isEdit = true : isEdit = undefined;
        let text;
        switch (value) {
            case "and":
                text = "Og";
                break;
            case "or":
                text = "Eller";
                break;
            default:
                text = "None";
                break;
        }
        return (
            <>
                {filterData.statements.length >= virtualizedIndex ? (
                    // Populate data on existing filter
                    <>
                        <FilterProperty statement={filterData.statements[virtualizedIndex - 1]} isEdit={true} filterStatementIndex={virtualizedIndex - 1} />
                        {virtualizedIndex < filterData.statements.length ? <div className={classes.operator}>{translateConnector(filterData.statements[virtualizedIndex - 1].connector)}</div> : undefined}
                    </>
                )
                    : (
                        // Create a new filter
                        <>
                            <div className={classes.operator}>{text}</div>
                            <FilterProperty index={virtualizedIndex} isEdit={isEdit} />
                        </>
                    )}
                {/* Provide values from saved filter here*/}
            </>

        )
    }

    const translateConnector = (value) => {
        let text;
        switch (value) {
            case "and":
                text = "Og";
                break;
            case "or":
                text = "Eller";
                break;
            default:
                text = "None";
                break;
        }

        return text;
    }


    const createFilter = async (_filterChild) => {
        let statements = _filterChild;
        if (_filterChild) {
            statements = { ..._filterChild[0], connector: "and" };
        }

        let feed = feeds[selectedIndex];
        const filter = {
            filterId: feed.filterId,
            filterType: "multibasen",
            statements: [statements]
        }

        // If this breaks, should probably use getFilterRequest() instead.
        let filterCreated = await createNewFilterRequest(filter)
        if (!filterCreated) {
            throw Error("Could not create filter");
        }

        setFilterWasCreated(true);
        setSelectedFilter(await getFilterRequest(feed.filterId));

        // Get data for the statistics
        let statistics = await feedApplyFilter(feed.feedId);
        setFilterStatistics(statistics);
    }

    const patchFilter = async (_filterChild) => {
        if (selectedFilter.filterId === undefined) {
            return; // The filter was not created yet.
        }

        if (_filterChild.length === 0) {
            // None of the filter properties has been populated yet.
            return;
        }

        const invalidOperators = _filterChild.filter(x => isNaN(parseInt(x.operation.id, 10)))
        if (invalidOperators.length !== 0) {
            return; // One of the operators is not pulated yet.
        }


        const filter = {
            filterType: "multibasen",
            statements: [..._filterChild]
        }

        if (!filterExists) {
            for (let i = 0; i < selectedOptions.length; i++) {
                // Set connector to filter.
                filter.statements[i].connector = selectedOptions[i];
            }
        } else {
            for (let i = 0; i < selectedOptions.length; i++) {
                // Set connector to filter but skip the one from last filter property from fetch
                if (i >= lastConnectorIndex) {
                    filter.statements[i].connector = selectedOptions[i + 1];
                } else {
                    filter.statements[i].connector = selectedOptions[i];
                }
            }
        }
        // Set the last one to "and"
        filter.statements[filter.statements.length - 1].connector = "and";

        await patchFilterRequest(selectedFilter.filterId, filter);

        let statistics = await feedApplyFilter(selectedFilter.filterId);

        setFilterStatistics(statistics);
    }

    const generateAddButton = () => (
        <>
            <Tooltip title={"Add"}>
                <Fab
                    size="small"
                    color="primary"
                    onClick={(e) => openMenu(e)}
                    className={classes.addButton}
                >
                    <AddIcon style={{ marginTop: 4 }} />
                </Fab>
            </Tooltip>
            <Menu
                id="simple-menu"
                anchorEl={anchorEl}
                open={Boolean(anchorEl)}
                onClose={closeMenu}
            >
                <MenuItem onClick={() => handleSelectOption(filterProperties.length, "or")}>
                    Eller
            </MenuItem>
                <MenuItem onClick={() => handleSelectOption(filterProperties.length, "and")}>
                    Og
            </MenuItem>
            </Menu>
        </>)

    if (filterChild.length !== 0 && lastFilterValue !== filterChild) {
        setLastFilterValue(filterChild)

        if (filterWasCreated) {
            patchFilter(filterChild);
        } else if (!filterExists && !filterWasCreated) {
            createFilter(filterChild);
        }
    }

    return (
        <>
            {filterExists ? (
                <>
                    <TextField
                        autoFocus
                        margin="dense"
                        id="folder-name"
                        label="Navn"
                        value={feeds[selectedIndex].feedName.name}
                        type="text"
                        className={classes.feedName}
                        InputProps={{
                            readOnly: true,
                        }}
                    />
                    {/* {filterData.statements.map((statement, index) => (
                        <>
                            <FilterProperty statement={statement} isEdit={true} filterStatementIndex={index} />
                            {index + 1 < filterData.statements.length ? <div className={classes.operator}>{translateConnector(statement.connector)}</div> : undefined}
                        </>
                    ))} */}

                    {/* {selectedOptions.length === 0 ? generateFilterProperty(undefined, 1) : selectedOptions.map((x, index) => generateFilterProperty(x, index + 1))} */}

                    {selectedOptions.map((x, index) => generateNewPropertyEditFilter(x, index + 1))}
                    {generateAddButton()}

                </>) :
                (
                    <>
                        <TextField
                            autoFocus
                            margin="dense"
                            id="folder-name"
                            label="Navn"
                            value={feeds[selectedIndex].feedName.name}
                            type="text"
                            className={classes.feedName}
                            InputProps={{
                                readOnly: true,
                            }}
                        />

                        <FilterProperty index={0} />

                        {selectedOptions.map((x, index) => generateFilterProperty(x, index + 1))}

                        {selectedOptions.length + 1 < maxSize ? generateAddButton() : null}
                    </>
                )
            }

        </>
    )
}

export default withStyles(styles)(FeedProperties)