import {
  configurations as endpointConfigs
} from "../../constants/services.constants";
import { appConstants } from "../../constants/app.constants";
import { changePassWord, getIdentityToken, resetCodeRequest, validateToken } from "./Requests";
import {
  getTokenFailMessageDefault, loginErrorTranslations,
  tokenDeatails, tokenExpiredMessage
} from "./AuthHelperConstants";

import { Buffer } from 'buffer';


export function setUserAndTokenInStorage(
  identityUserResponse,
  username,
) {
  localStorage.setItem("id_token", identityUserResponse.id_token);
  localStorage.setItem("access_token", identityUserResponse.access_token);
  localStorage.setItem("username", username);
}

export function deleteUserAndTokenInStorage() {
  localStorage.removeItem("access_token");
  localStorage.removeItem("id_token");
  localStorage.removeItem("username");
}

export function getUsername() {
  return localStorage.getItem("username");
}

export function getToken() {
  return localStorage.getItem("access_token");
}

export async function getSessionToken(userName, passWord) {
  const details = tokenDeatails(userName, passWord);
  let result = {};
  try {
    const response = await getIdentityToken(details);
    const tokenResponse = await response.json();
    result = handleTokenResponse(tokenResponse, userName);
  } catch (e) {
    result.resultLogin = false;
    result.errorMsg = getTokenFailMessageDefault + e;
  }

  return result;
}

function handleTokenResponse(tokenResponse, userName) {
  let result = {};

  if (tokenResponse.error) {
    const errorMessage = loginErrorTranslations(tokenResponse.error);
    result.resultLogin = false;
    result.errorMsg = errorMessage;
  } else {
    result.resultLogin = true;
    result.errorMsg = "";
    setUserAndTokenInStorage(tokenResponse, userName);
  }

  return result;
}

export function formatBodyParamsPOST(params) {
  let formBody = [];

  for (let property in params) {
    const encodedKey = encodeURIComponent(property);
    const encodedValue = encodeURIComponent(params[property]);

    formBody = [...formBody, encodedKey + "=" + encodedValue];
  }

  return formBody.join("&");
}

export async function requestResetCode(userName, callbackSucceed, callbackError) {
  const response = await resetCodeRequest(userName, callbackSucceed, callbackError);
  return response;
}

export async function changePasswordWithCode(userName, passWord, resetCode, callbackSucced, callbackError) {
  const requestBody = {
    "userName": userName,
    "resetCode": resetCode,
    "password": passWord
  };

  const response = await changePassWord(requestBody, callbackSucced, callbackError);
  return response;
}

export async function checkSessionToken() {

  let token = localStorage.getItem("access_token");

  if (!token) {
    return false;
  };

  const requestBody = {
    token: token
  };

  const authHeaders = composeRequestHeaders();
  const response = await validateToken(requestBody, authHeaders);

  const validationResult = validateTokenCheckResponse(response);

  return validationResult;
}

function composeRequestHeaders() {
  let authHeaders = new Headers();
  authHeaders.set(
    "Authorization", "Basic " + Buffer.from(endpointConfigs.IDENTITY_RESOURCE +
      ":" + endpointConfigs.IDENTITY_CLIENT_SECRET).toString("base64")
  );
  authHeaders.set("Content-Type", "application/x-www-form-urlencoded");

  return authHeaders;
}

function validateTokenCheckResponse(response) {
  let result = {};
  if (!response || !response.active) {
    result.tokenIsValid = false;
    result.errorMsg = tokenExpiredMessage;
    deleteUserAndTokenInStorage();
  } else {
    checkTokenExp(response.exp, response.nbf);
    result.tokenIsValid = true;
    result.errorMsg = "";
  }

  return result;
}

export function checkTokenExp(exp, nbf = null) {
  const d = new Date().getTime() / 1000;
  const o = exp - appConstants.TOKEN_PREV_EXP_LOGOUT;
  console.log(
    "Token expiration: " +
    exp +
    ", App will logout at: " +
    o +
    ", current time is: " +
    d +
    " (seconds)"
  );
  if (exp == null || o < d) {
    cleanAndRefresh();
  }
  return false;
}

export function cleanAndRefresh() {
  deleteUserAndTokenInStorage();
  window.location.reload();
}

