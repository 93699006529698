import { createMuiTheme } from "@material-ui/core";
import { appThemeConstants } from "./constants/app.constants";

const defaultFont = { fontFamily: 'Noto Sans' };
const boldFont = { ...defaultFont, fontWeight: 'bold' };
const bold2Font = { ...defaultFont, fontWeight: 600 };
const fontPrimaryColor = { color: '#000000' };
const buttonTextPrimaryColor = { color: "#3f51b5" };
const buttonTextSecondaryColor = { color: "#f50057" };

const defaultTheme = {
    palette: {
        primary: {
            main: appThemeConstants.PRIMARY_1,
            contrastText: appThemeConstants.PRIMARY_TEXT,
        }
    },
    typography: {
        ...defaultFont,
        useNextVariants: true,
        h1: boldFont,
        h2: boldFont,
        h3: boldFont,
        h4: {
            fontFamily: 'Noto Sans',
            fontWeight: 'bold',
            marginTop: '0.5em',
            marginBottom: '1em'
        }
        ,
        h5: boldFont
    },
    overrides: {
        MuiTableCell: {
            root: {
                ...defaultFont, padding: 20,
            },
            body: defaultFont,
            head: bold2Font,
            paddingCheckbox: {
                paddingRight: 1,
                paddingLeft: 1,
                paddingTop: 1,
                paddingBottom: 1,
            }
        },
        MuiPaper: {
            root: {
                overflowX: "auto"
            }

        },
        MuiTablePagination: {
            select: {
                paddingRight: 20
            },
            input: defaultFont
        },
        MUIDataTableSelectCell: {
            fixedHeaderCommon: {
                backgroundColor: "none",
                position: "unset"
            },
            expandDisabled: {
                // hide expand buttons on row when expand is disabled
                visibility: 'hidden',
            },
        },
        MuiMenuItem: {
            root: defaultFont
        },
        MuiInputBase: {
            root: defaultFont
        },
        MuiButtonBase: {
            root: defaultFont
        },
        MuiButton: {
            textPrimary: buttonTextPrimaryColor,
            textSecondary: buttonTextSecondaryColor
        },
        MuiIconButton: {
            colorPrimary: fontPrimaryColor
        },
        MuiTypography: {
            body1: defaultFont,
            body2: defaultFont
        }
    }
};


export const RepublicaMuiTheme = createMuiTheme(defaultTheme);

export const RepublicaDatePickerMuiTheme = createMuiTheme({
    palette: {
        primary: {
            main: appThemeConstants.PRIMARY_1,
            contrastText: appThemeConstants.PRIMARY_TEXT,
        }
    }
});

const photoTrackerTheme2 = JSON.parse(JSON.stringify(defaultTheme));
photoTrackerTheme2.overrides.MuiTableCell.root = {
    ...defaultFont, padding: 0,
};

photoTrackerTheme2.overrides.MUIDataTableBodyCell = {
    root: {
        minWidth: 75,
    }
};

// temporary solution until review. merge with defaultTheme
export const PhotosTheme = theme => createMuiTheme({
    palette: {
        primary: {
            main: appThemeConstants.PRIMARY_1,
            contrastText: appThemeConstants.PRIMARY_TEXT,
        }
    },
    typography: {
        ...defaultFont,
        useNextVariants: true,
        h1: boldFont,
        h2: boldFont,
        h3: boldFont,
        h4: {
            fontFamily: 'Noto Sans',
            fontWeight: 'bold',
            marginTop: '0.5em',
            marginBottom: '1em'
        }
        ,
        h5: boldFont
    },
    overrides: {
        MuiTableCell: {
            root: {
                ...defaultFont,
                [theme.breakpoints.down("md")]: {
                    padding: 0
                },
                [theme.breakpoints.down("sm")]: {
                    padding: "20px 10px"
                },
            },
            body: defaultFont,
            head: bold2Font,
            paddingCheckbox: {
                paddingRight: 1,
                paddingLeft: 1,
                paddingTop: 1,
                paddingBottom: 1,
            }
        },
        MUIDataTableBodyCell: {
            stackedCommon: {
                [theme.breakpoints.down("sm")]: {
                    height: 100
                },
            }
        },
        MuiPaper: {
            root: {
                overflowX: "auto"
            }
        },
        MuiTablePagination: {
            select: {
                paddingRight: 20
            },
            input: defaultFont
        },
        MUIDataTableSelectCell: {
            fixedHeaderCommon: {
                backgroundColor: "none",
                position: "unset"
            },
            expandDisabled: {
                visibility: 'hidden',
            },
        },
        MuiMenuItem: {
            root: defaultFont
        },
        MuiInputBase: {
            root: defaultFont
        },
        MuiButtonBase: {
            root: defaultFont
        },
        MuiButton: {
            textPrimary: buttonTextPrimaryColor,
            textSecondary: buttonTextSecondaryColor
        },
        MuiIconButton: {
            colorPrimary: fontPrimaryColor
        },
        MuiTypography: {
            body1: defaultFont,
            body2: defaultFont
        }
    }
})

export const PhototrackerTheme = createMuiTheme(photoTrackerTheme2);
