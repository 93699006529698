import React, { useEffect, useState } from "react";

// Redux imports
import { connect } from "react-redux";

import CheckIcon from "@material-ui/icons/Check";

// Material
import InputLabel from "@material-ui/core/InputLabel";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import { withStyles } from "@material-ui/core/styles";
import PropTypes from "prop-types";
import { LoadingLogo } from "../../../shared/Loading";
// Dropzone new
import { registerPlugin } from "react-filepond";
//import "filepond/dist/filepond.min.css";
import FilePondPluginImagePreview from "filepond-plugin-image-preview";
import "filepond-plugin-image-preview/dist/filepond-plugin-image-preview.min.css";
import FilePondPluginFileValidateType from "filepond-plugin-file-validate-type";
import ChipInput from "material-ui-chip-input";

import { photoTypeEnum } from "../../../../constants/enums";
import AutoSuggestionWithChipApi from "../AutoSuggestionWithChipApi ";
import ImageEditable from "../../../shared/photoThumbnail/ImageEditable";
import {
  getProductPhotosForPhoto,
  getProducts,
} from "../../produkstyring/Requests";
import {
  addProductPhoto,
  deleteProductPhoto,
  getPhoto,
  updatePhoto,
  updatePhotoValidToDate,
  getEarliestExpirationDate
} from "../Requests";

import createDialog from "../../../../DialogRenderer";
import { showErrorNotification } from "../../../../helpers/UtilHelper";
import { handleValidationOnSetExclusive } from "../../fototracker/Dialogs/DialogAddPhotoOrder/BusinessLogic/Logic";
import ExpirePhotoForm from "./ExpirePhotoForm";
import Resources from "../../../../Resources.json"
import {GetResource} from "../../../../helpers/ResourceHelper"
import { IsFeatureEnabled, featureNames } from "../../../../helpers/FeaturesHelper";

import { getDeletePermissions } from "../../../../helpers/CredentialsHelper";

registerPlugin(FilePondPluginImagePreview, FilePondPluginFileValidateType);

const styles = (theme) => ({
  closeButton: {
    position: "absolute",
    right: theme.spacing(0.5),
    top: theme.spacing(0.5),
    color: theme.palette.grey[500],
  },
  row: {
    display: "grid",
    gridTemplateColumns: "1fr 1fr",
    gridGap: "1rem",
    marginBottom: 30,
  },
  rowFullWidth: {
    display: "grid",
    gridTemplateColumns: "1fr",
    marginBottom: 55,
  },
  headerRow: {
    display: "grid",
    gridTemplateColumns: "1fr",
  },
  chipInput: {
    marginTop: -8,
  },
  selectInput: {
    padding: 0,
    marginTop: 16,
  },
  actions: {
    display: "grid",
    gridTemplateColumns: "1fr 1fr 1fr 1fr 1fr",
    gridGap: "1rem",
    marginBottom: 30,
  },
  actionButton: {
    width: 110,
    justifySelf: "end",
    marginRight: 10,
  },
  positionGroup: {
    gridColumn: 4,
  },
  positionSingle: {
    gridColumn: 5,
  },
  title: {
    color: "rgba(0, 0, 0, 0.87)",
    "& h6": {
      fontWeight: 900,
    },
  },
  dropdownStyle: {
    maxHeight: 385,
  },
  autoSuggestionField: {
    marginTop: 40,
  },
  imageHolder: {
    position: "relative",
    "&:hover": {},
  },
  imageBlock: {
    display: "grid",
    gridTemplateColumns: "200px auto",
    gridColumnGap: 10,
  },
  dialogContent: {
    minHeight: 375,
  },
});

export async function openDialogEditPhoto(photoId, onEditImage, reloadSearch) {
  return createDialog(({ show, onSubmit, onDismiss }) => (
    <DialogEditPhotoStyled
      photoId={photoId}
      onEditImage={onEditImage}
      open={show}
      onCancel={onDismiss}
      onConfirm={onSubmit}
      reloadSearch={reloadSearch}
    />
  ));
}

function DialogEditPhoto({ photoId, onEditImage, open, onCancel, onConfirm, utilsReducer, classes, reloadSearch }) {
  const [randomNumber, setRandomNumbe] = useState(Math.floor(Math.random() * 10000));
  const [loading, setLoading] = useState(true);
  const [name, setName] = useState(undefined);
  const [description, setDescription] = useState(undefined);
  const [photoTypeId, setPhotoTypeId] = useState(undefined);
  const [customerId, setCustomerId] = useState(undefined);
  const [tags, setTags] = useState([]);
  const [productNumbers, setProductNumbers] = useState([]);
  const [exclusiveForCustomerIds, setExclusiveForCustomerIds] = useState([]);
  const [photoObject, setPhotoObject] = useState({})
  const [photoValidTo, setPhotoValidTo] = useState(undefined)
  let [earliestDate, setEarliestDate] = useState(undefined)

  useEffect(() => {
    init();
  }, []);

  const init = async () => {
    try {
      setLoading(true);

      const photo = await getPhoto(photoId);
      await getRelatedEans(photoId);
      setEarliestDate(await getEarliestExpirationDate(photoId));
      setName(photo.name);
      setDescription(photo.description);
      setPhotoTypeId(photo.type ? photoTypeEnum[photo.type].id : undefined);
      setCustomerId(photo.customerId);
      setTags(photo.tags);
      setExclusiveForCustomerIds(photo.exclusiveForCustomerIds);
      setPhotoObject(photo)
    } finally {
      setLoading(false);
    }
  }

  const handleAddEans = (newEan) => {
    setProductNumbers([...productNumbers, newEan])
  };

  const handleDeleteEans = (productNumber) => {
    const cloneGtins = [...productNumbers];

    const gtiN = cloneGtins.filter((m) => m.productNumber == productNumber);

    const index = cloneGtins.indexOf(gtiN[0]);
    cloneGtins[index] = { ...gtiN[0] };
    cloneGtins[index].IsDeleted = true;

    setProductNumbers(cloneGtins);
  };

  const getRelatedEans = async (photoId) => {
    if (productNumbers.length === 0) {
      const allProductPhotos = await getProductPhotosForPhoto(photoId);
      const productIds = allProductPhotos.map((x) => x.multiProductId);
      const allProducts = await getProducts(productIds, true, false);

      let eans = [];
      for (const product of allProducts) {
        const productPhoto = allProductPhotos.find(
          (x) => x.multiProductId === product.id
        );

        let productFormatted = {
          IsNewAdded: false,
          name: product.name ?? "",
          productId: product.id,
          productNumber: product.productNumber,
          photoId: photoId,
          isDefault: productPhoto.isDefaultImage,
          IsDeleted: false,
          id: productPhoto.id,
          noOfPhotoWithProduct: product.photoIds.length,
        };

        eans = [...eans, productFormatted];

      }
      setProductNumbers(eans);
    }
  };

  const handleAddTag = (tag) => {
    setTags([...tags, tag]);
  };

  const handleDeleteTag = (tag) => {
    setTags(tags.filter(x => x !== tag));
  };

  const handleSave = async () => {
    try {
      setLoading(true);
      const dto = {
        name: name,
        description: description,
        type: photoTypeId,
        customerId: customerId,
        tags: tags,
        exclusiveForCustomerIds: exclusiveForCustomerIds
      };
      await updatePhoto(photoId, dto);
      await updateProductPhotos();
      if (IsFeatureEnabled(featureNames.Photos_Edit_ExpireData_Enabled)){
        UpdateValidDateOnPhoto()
      }
      onConfirm();
    } catch (e) {
      showErrorNotification("Noget fejlede");
    } finally {
      setLoading(false);
    }
  };

  const updateProductPhotos = async () => {
    // add new photos
    const photosToAdd = productNumbers.filter(
      (x) => x.IsNewAdded && !x.IsDeleted
    );
    for (const photoToAdd of photosToAdd) {
      let productPhoto = {
        MultiProductId: photoToAdd.productId,
        MultiPhotoId: photoToAdd.photoId,
        IsDefaultImage: photoToAdd.isDefault ?? false,
      };

      await addProductPhoto(photoToAdd.productId, productPhoto);
    }

    // remove old photos
    const photosToRemove = productNumbers.filter(
      (x) => !x.IsNewAdded && x.IsDeleted
    );
    for (const photoToRemove of photosToRemove) {
      await deleteProductPhoto(photoToRemove.id);
    }
  }

  function UpdateValidDateOnPhoto() {
    if (photoValidTo == null) {
      updatePhotoValidToDate(photoObject.id, "")
    }
    else {
      updatePhotoValidToDate(photoObject.id, photoValidTo)
    }
  }

  const handleExclusiveCustomerIds = (ids, selectedCustomer) => {
    if (!handleValidationOnSetExclusive(ids, selectedCustomer)) {
      return;
    }

    setExclusiveForCustomerIds(ids);
  }

  return (
    <Dialog
      open={open}
      onClose={onCancel}
      aria-labelledby="form-dialog-title"
      fullWidth={true}
      maxWidth="md"
    >
      <DialogTitle id="form-dialog-title" className={classes.title}>
        Rediger foto
      </DialogTitle>
      <DialogContent className={classes.dialogContent}>
        {loading ? (
          <LoadingLogo />
        ) : (
          <>
            <div className={classes.imageBlock}>
              <ImageEditable
                randomNumber={randomNumber}
                photoId={photoId}
                handleEditImage={() => onEditImage(photoId)}
              />
              <div>
                <div className="dialogFormRow">
                  <TextField
                    margin="normal"
                    label="Navn"
                    type="text"
                    name="name"
                    id="textfieldPhotoEditName"
                    value={name || ""}
                    onChange={e => setName(e.target.value)}
                    fullWidth
                  />
                  {IsFeatureEnabled(featureNames.Photos_Type_Enabled) ?
                    <FormControl className={classes.selectInput}>
                      <InputLabel htmlFor="multiPhotoTypeId-id">
                        Fototype
                      </InputLabel>
                      <Select
                        fullWidth
                        value={photoTypeId}
                        onChange={e => setPhotoTypeId(e.target.value)}
                        inputProps={{
                          name: "multiPhotoTypeId",
                          id: "multiPhotoTypeId-id",
                        }}
                      >
                        <MenuItem key={0} value={photoTypeEnum.Pack.id}>
                          {photoTypeEnum.Pack.name}
                        </MenuItem>
                        <MenuItem
                          key={1}
                          value={photoTypeEnum.Environment.id}
                        >
                          {photoTypeEnum.Environment.name}
                        </MenuItem>
                      </Select>
                    </FormControl> : undefined
                  }
                </div>
                <div className="dialogFormRow">
                  <FormControl>
                    <InputLabel htmlFor="multiCustomerId">{GetResource(Resources.Photos_Edit_Customer_Title_Label)}</InputLabel>
                    <Select
                      fullWidth
                      id="selectorPhotoOrderAddCustomer"
                      value={customerId}
                      onChange={e => setCustomerId(e.target.value)}
                      inputProps={{
                        name: "multiCustomerId",
                        id: "multiCustomerId",
                      }}
                    >
                      {utilsReducer.customers.map((item) => (
                        <MenuItem key={item.id} value={item.id} className='selectorPhotoOrderAddCustomerItems'>
                          {item.name}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>

                  <FormControl>
                    <ChipInput
                      className={classes.chipInput}
                      label="Tags"
                      // inputValue={"Hejsa"}
                      value={tags}
                      onAdd={handleAddTag}
                      onDelete={handleDeleteTag}
                      helperText={"tryk enter for hvert tag"}
                      fullWidth
                      id="textfieldPhotoEditTags"
                    />
                  </FormControl>
                </div>
              </div>
            </div>

            {/* <div className={classes.rowFullWidth}> */}
            <TextField
              label="Beskrivelse"
              style={{ marginBottom: 30 }}
              type="text"
              name="description"
              id="textfieldPhotoEditDescription"
              value={description || ""}
              onChange={e => setDescription(e.target.value)}
              InputProps={{
                multiline: true,
              }}
              fullWidth
            />

            {/* </div> */}
            {/* <PhotoRestrictionField
                selectedCustomerIds={exclusiveForCustomerIds}
                onSelectedCustomerIdsChange={
                  ids => handleExclusiveCustomerIds(ids, customerId)
                }
              /> */}
            {IsFeatureEnabled(featureNames.Photos_Products_Relation_Enabled) ?
              <div className={classes.autoSuggestionField}>
                <AutoSuggestionWithChipApi
                  classes={classes}
                  MotiveId={photoId}
                  handleAddEans={handleAddEans}
                  handleDeleteEans={handleDeleteEans}
                  gtiNs={productNumbers}
                />
              </div> : undefined
            }
            {IsFeatureEnabled(featureNames.Photos_Edit_ExpireData_Enabled) && getDeletePermissions("Fotos").includes(customerId) ?
            <div>
              <br />
              <ExpirePhotoForm
                earliestDate={earliestDate}
                photo={photoObject}
                photoValidTo={photoValidTo}
                setPhotoValidToDate={setPhotoValidTo}
                ></ExpirePhotoForm> 
              </div>: undefined
            }
          </>
        )}
      </DialogContent>
      <DialogActions>
        <Button onClick={onCancel}>
          Annuller
        </Button>

        <Button onClick={handleSave} color="primary" disabled={loading} name="buttonPhotoEditSave">
          Gem
        </Button>
      </DialogActions>
    </Dialog >
  );
}

DialogEditPhoto.propTypes = {
  classes: PropTypes.object.isRequired,
};

const DialogEditPhotoStyled = connect((state) => state)(
  withStyles(styles)(DialogEditPhoto)
);
