import React from 'react'
import EditOfferField from './EditOfferField';


export default function OfferFields({ offerFieldsValues, handleChangeValue, updatePosterState }) {

    return (
        Array.from(offerFieldsValues).map(([key, value]) => {
            return (
                <EditOfferField
                    className={value.className}
                    placeHolder=""
                    onChange={(newValue) => handleChangeValue(newValue, `${key}`)}
                    fieldValue={value.value}
                    onKeyPress={(e) => updatePosterState(e)}
                />
            );
        }
        )

    )
}