export const CLEAN_FOTO_HAANDTERING = 'CLEAN_FOTO_HAANDTERING';
export const cleanFotoHaandtering = () => ({
  type: CLEAN_FOTO_HAANDTERING
});

export const SET_PUBLICATION = 'SET_PUBLICATION';
export const setPublication = (publication) => ({
  type: SET_PUBLICATION,
  payload: { publication }
});

export const TOGGLE_SELECTED_OFFER = 'TOGGLE_SELECTED_OFFER';
export const toggleSelectedOffer = (offerId) => ({
  type: TOGGLE_SELECTED_OFFER,
  payload: { offerId }
});

export const SET_SELECTED_OFFERS = 'SET_SELECTED_OFFERS';
export const setSelectedOffers = (offerIds) => ({
  type: SET_SELECTED_OFFERS,
  payload: { offerIds }
});
