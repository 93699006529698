import React, { useState } from 'react';
import { InputField } from '../logIn/components/InputField';
import StyledButtonLogIn from '../logIn/components/StyledButton';

export default function ChangePassWordForm({
    newPassWord, setNewPassWord, newPassWordRepeat,
    setNewPassWordRepeat, loading, handleChangePassWord
}) {


    return (
        <form>
            <InputField
                labelText="Nyt password"
                name="newPassWord"
                type="password"
                placeHolder="Indtast ny adgangskode"
                value={newPassWord}
                handleInputChange={(e) => setNewPassWord(e.target.value)}
            />
            <InputField
                labelText="Gentag nyt password"
                name="newPassWordRepeat"
                type="password"
                placeHolder="Gentag nyt adgangskode"
                value={newPassWordRepeat}
                handleInputChange={(e) => setNewPassWordRepeat(e.target.value)}
            />
            <StyledButtonLogIn
                fullWidth
                variant="contained"
                color="primary"
                disabled={loading}
                buttonText={loading ? "Loading..." : "Skift password"}
                handleOnClick={() => loading ?
                    undefined :
                    handleChangePassWord()}
            />
        </form>
    )
}