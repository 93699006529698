//import { SET_PUBLIKATION } from '../types/notifications';
import {
    FETCH_CREDENTIALS_BEGIN,
    FETCH_CREDENTIALS_SUCCESS,
    FETCH_CREDENTIALS_FAILURE
} from '../actions/credentials.actions';

const initState = {
    me: null
}
export default (state = initState, action) => {
    switch (action.type) {

        //---------------------------------------------------
        case FETCH_CREDENTIALS_BEGIN:
            return {
                ...state,
                loading: true,
                error: null,
            };

        case FETCH_CREDENTIALS_SUCCESS:
            return {
                ...state,
                loading: false,
                me: action.payload.credentials
            };

        case FETCH_CREDENTIALS_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.payload.error,
                me: null
            };

        default:
            return state;
    }
}