import { Tooltip, Typography } from '@material-ui/core';
import { ToggleButton, ToggleButtonGroup } from '@material-ui/lab';
import { withStyles } from '@material-ui/styles';
import React from 'react';
import Info from '@material-ui/icons/Info';
import { applications } from '../../../constants/app.constants';
import { canReadApplication, canReadApplicationForCustomer } from '../../../helpers/CredentialsHelper';
import { exclusiveCustomersOrganization, exclusiveCustomersCoop } from "../../../constants/enums";
import Resources from "../../../Resources.json";

import { IsFeatureEnabled, featureNames } from "../../../helpers/FeaturesHelper";

const styles = (theme) => ({
    label: {
        paddingBottom: 5,
        color: "rgba(0,0,0,0.54)",
        display: "flex",
        alignItems: "center"
    }
})

export default withStyles(styles)(PhotoRestrictionField);
function PhotoRestrictionField({ selectedCustomerIds, onSelectedCustomerIdsChange, classes }) {

    if(!IsFeatureEnabled(featureNames.Photos_Exclusivity_Enabled))return null;

    const canEdit = (customerId) => {
        return canReadApplicationForCustomer(applications.EDIT_EXCLUSIVE_PHOTOS, customerId);
    }
    if(!canReadApplication(applications.VIEW_EXCLUSIVE_PHOTOS)){
        return null;
    }
    return <>
        <div>
            <div className={classes.label}>
                <Typography variant="caption" style={{marginRight: 2}} >
                    Ønsker du eksklusivitet for billedet, vælg da kæde nedenfor
                </Typography>
                <Tooltip title={"Eksklusive fotos kan kun bruges i publikationer for bestemte kunder"}>
                    <Info fontSize="small"/>
                </Tooltip>
            </div>

            <ToggleButtonGroup
                style={{ height: 35 }}
                color="error"
                value={selectedCustomerIds}
                onChange={(e, values) => onSelectedCustomerIdsChange(values)}>

                    {exclusiveCustomersOrganization.find(e=>e.organization == Resources.Organization).customers.map((item, index) =>
                    (
                        <ToggleButton key={index} value={item.id} disabled={!canEdit(item.id)}>
                            {item.name}
                        </ToggleButton>
                    ))}

            </ToggleButtonGroup>
        </div>
    </>
}