import React from "react";
import Grid from "@material-ui/core/Grid";
import { Typography, Card, CardContent } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import { blueGrey } from "@material-ui/core/colors";
import { isEqual } from "../../../helpers/UtilHelper";
import SpreadPage from "./pageSliderComponents/SpreadPage";
import PageNumber from "./pageSliderComponents/PageNumber";

const styles = () => ({
  root: {
  },
  pages: {
    overflowX: "auto",
    overflowY: "hidden",
    whiteSpace: "nowrap",
    minHeight: 115,
    width: "100%"
  },
  page: {
    display: "inline-block",
    whiteSpace: "nowrap"
  },
  pageImg: {
    display: "inline-block",
    whiteSpace: "normal"
  },
  pagesTable: {
    borderCollapse: "collapse",
    borderSpacing: 0,
    boxShadow: "0px 4px 10px 0px rgba(0,0,0,0.5)",
    margin: 10
  },
  pageCell: {
    border: "1px solid black",
    width: "50px",
    height: 75,
    marginRigth: 0,
    marginLeft: 0,
    "&:hover": {
      cursor: "pointer"
    },
    "& h4": {
      fontSize: 20
    },
  },
  pageNumber: {
    marginBottom: "0.75em"
  },
  selectedPages: {
    backgroundColor: blueGrey[600],
    "& h4": {
      color: blueGrey[50]
    },
    "&:hover": {
      backgroundColor: blueGrey[400]
    }
  },
  unselectedPages: {
    backgroundColor: blueGrey[50],
    "& h4": {
      color: blueGrey[900]
    },
    "&:hover": {
      backgroundColor: blueGrey[100]
    }
  },
  pagesImages: {
    overflowX: "auto",
    overflowY: "hidden",
    whiteSpace: "nowrap",
    minHeight: 200,
    width: "100%",
    padding: 10
  },
  activePage: {
    textDecoration: "underline"
  }
});

class PagesSlider0 extends React.Component {
  state = {
    galleryItems: [],
    selectedPages: []
  };

  componentDidMount() {
    !this.props.productSlider ? this.setGalleryItems() : this.setGalleryImages();
  }
  componentDidUpdate() {
    if (this.props.selectedPages.toString() !== this.state.selectedPages.toString()) {
      !this.props.productSlider ? this.setGalleryItems() : this.setGalleryImages();
    }
  }

  isSelectedSpread = (spead) => {
    return this.props.selectedPages.indexOf(spead[0]) > -1 ||
      this.props.selectedPages.indexOf(spead[1]) > -1;
  }

  getPageIndex = (spread, selectedPageNumber) => {
    const totalPages = this.props.publicationPagesNumber;
    let index = 0;
    if (selectedPageNumber === totalPages) {
      index = selectedPageNumber - 1;
    }
    else if (selectedPageNumber === 1) {
      index = 0;
    }
    else {
      // if selected is any other page then return index of the first page in spread
      index = spread[0] - 1;
    }

    return index
  }

  setGalleryItems() {
    //Need to get the index of the selectedPages. Pass that to the start index.

    let startingIndex = this.props.items.findIndex(i => isEqual(i, this.props.selectedPages));
    const { publicationId, existingPdfs } = this.props;

    const existingPdfsSet = new Set(existingPdfs);
    this.setState({
      startingIndex: startingIndex,
      selectedPages: this.props.selectedPages,
      galleryItems: this.props.items.map((i, index) => (
        <div
          key={index}
          style={{
            display: "flex",
            margin: 10
          }}
        >
          <div>
            <SpreadPage
              publicationId={publicationId}
              item={i[0]}
              onClick={() => this.props.handleOpenPreviewPublicationDialog(this.getPageIndex(i, i[0]))}
              existingPdfs={existingPdfsSet}
            />
            <PageNumber
              activePage={this.props.classes.activePage}
              item={i[0]}
              selectedSpread={this.isSelectedSpread(i)}
              onClick={() => this.props.loadOffers(i[0], i[1])}
            />
          </div>
          <div>
            <SpreadPage
              publicationId={publicationId}
              item={i[1]}
              onClick={() => this.props.handleOpenPreviewPublicationDialog(this.getPageIndex(i, i[1]))} // a hack to get an index of the first page of the spread
              existingPdfs={existingPdfsSet}
            />
            <PageNumber
              activePage={this.props.classes.activePage}
              item={i[1]}
              selectedSpread={this.isSelectedSpread(i)}
              onClick={() => this.props.loadOffers(i[0], i[1])}
            />
          </div>
        </div>
      ))
    });
  }

  setGalleryImages() {
    let startingIndex = this.props.items.findIndex(i => isEqual(i, this.props.selectedPages));

    this.setState({
      startingIndex: startingIndex,
      selectedPages: this.props.selectedPages
    });
  }
  responsive = {
    0: { items: 2 },
    600: {
      items: 2
    },
    950: {
      items: 4
    },
    1300: {
      items: 6
    },
    1620: {
      items: 7
    },
    2400: {
      items: 10
    }
  };

  stagePadding = {
    paddingLeft: 20, // in pixels
    paddingRight: 20
  };


  render() {
    return (
      <>{this.props.productSlider ? (<Grid className={this.props.classes.pagesImages}>
        {this.props.items.map((item, index) => {
          return (<div className={this.props.classes.pageImg} key={index}>{item}</div>)
        })}
      </Grid>) : (
          <Grid className={this.props.classes.pages}>
            {this.state.galleryItems.map((item, index) => {
              return (<div className={this.props.classes.page} key={index}>{item}</div>);
            })}
          </Grid>
        )}
      </>
    );
  }
}

const PagesSlider = withStyles(styles)(PagesSlider0);
export default PagesSlider;
