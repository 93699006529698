import React from 'react';
import CorrectorWeek from './CorrectorWeek';
import CorrectorSegment from './CorrectorSegment';
import CorrectorMonth from './CorrectorMonth';
import { canReadApplication } from '../../../helpers/CredentialsHelper';
import { applications } from '../../../constants/app.constants';

export default function Corrector(props) {
    const params = props.match.params;
    if (params.customer != null && params.year != null && params.week != null && params.segment != null) {
        return <CorrectorSegment customer={params.customer} year={params.year} week={params.week} segment={params.segment} />
    }
    else if (params.customer != null && params.month != null) {
        return <CorrectorSegment customer={params.customer} month={params.month} />
    }
    else
        return (
            <div>
                <CorrectorWeek initYear={params.year} initWeek={params.week} />

                {canReadApplication(applications.CORRECTOR) ?
                    <><hr style={{ marginTop: 50, marginBottom: 50 }} />
                        <CorrectorMonth />
                    </> 
                    : undefined}
            </div>
        )
}
