import React, { Component } from "react";
import { connect } from "react-redux";
import OfferMix from "./OfferMix";
import OfferParent from "./OfferParent";
import { openDialogConfirmDelete } from "../../../shared/dialogs/DialogConfirm";
import { showSuccessNotification } from "../../../../helpers/UtilHelper";
import { deleteOffer } from "../Requests";

class Offer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      expanded: true,
      isExpandable: false,
      anchorEl: null,
      currentId: null,
      dialogPictureSelectorV3: null,
    };
  }

  handleExpandClick = () => {
    this.setState(state => ({ expanded: !state.expanded }));
  };

  handleAnchorClick = event => {
    this.setState({
      anchorEl: event.currentTarget
    });
  };

  handleAnchorClose = () => {
    this.setState({ anchorEl: null });
  };

  handleEdit = id => {
    this.handleAnchorClose();
    this.props.handleEditOffer(id);
  };

  handleDeleteOffer = async () => {
    const { offer, page } = this.props;
    const message = `Ønsker du at slette tilbuddet: ${offer.description}?`
    const confirmed = await openDialogConfirmDelete("Slet tilbud", message);

    if (confirmed) {
      try {
        await deleteOffer(offer.id, page.id);
        showSuccessNotification("Tilbuddet er blevet slettet");
        this.props.onOfferChange(offer.id, true);
      } catch (e) { }
    }
  }

  render() {
    if (this.props.offer.childOffers.length > 0) {
      return <OfferParent
        offer={this.props.offer}
        handleEdit={(id) => this.handleEdit(id)}
        handleDeleteOffer={() => this.handleDeleteOffer()}
        handleCreatePhotoOrderOffer={(offer) => this.props.handleCreatePhotoOrderOffer(offer)}
        onOfferChange={this.props.onOfferChange}
        expanded={this.state.expanded}
        categories={this.props.categories}
        deactivatedPhotos={this.props.deactivatedPhotos}
        updateDeactivatedPhotos={this.props.updateDeactivatedPhotos}
      />
    } else {
      return <OfferMix
        refreshOffersPage={this.props.refreshOffersPage}
        pageNumber={this.props.page.pageNumber}
        onOfferChange={this.props.onOfferChange}
        expanded={this.state.expanded}
        totalProducts={this.props.offer.products}
        isLastChild={true}
        // Offer Card pops
        offer={this.props.offer}
        handlePhotoClickOpen={this.handlePhotoClickOpen}
        cardColor={this.props.cardColor}
        handlePhotoOrderClicked={this.props.handlePhotoOrderClicked}
        handleAnchorClick={this.handleAnchorClick}
        anchorEl={this.state.anchorEl}
        handleAnchorClose={this.handleAnchorClose}
        handleEdit={(id) => this.handleEdit(id)}
        isExpandable={this.props.isExpandable}
        handleExpandClick={this.handleExpandClick}
        handleDeleteOffer={() => this.handleDeleteOffer()}
        handleCreatePhotoOrderOffer={(offer) => this.props.handleCreatePhotoOrderOffer(offer)}
        categories={this.props.categories}
        deactivatedPhotos={this.props.deactivatedPhotos}
        updateDeactivatedPhotos={this.props.updateDeactivatedPhotos}
      />;
    }
  }
}

export default connect(state => state)(Offer);
