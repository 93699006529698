import React from "react";
import HomeContainer from "../components/others/Home";
import Publikationstyring from "../components/sections/publikationstyring/Publikationstyring";
import DigitalMedias from "../components/sections/digitalmedias/DigitalMedias";
import Fototracker from "../components/sections/fototracker/Fototracker";
import SogFotos from "../components/sections/photos/Photos";
import FotoHaandtering from "../components/sections/fotohaandtering/FotoHaandtering";
import Tilbudsstyring from "../components/sections/tilbudsstyring/Tilbudsstyring";
import Produktstyring from "../components/sections/produkstyring/Produktstyring";
import LogoSection from "../components/sections/logos/LogoSection";
import Brugerstyring from "../components/sections/brugerstyring/Brugerstyring";
import Support from "../components/sections/support/Support.js";

import Switch from "react-router-transition-switch";
import Fader from "react-fader";
import { Route } from "react-router-dom";

import Resources from "../Resources.json";
import {GetResource} from "../helpers/ResourceHelper";

import Permits from "../components/sections/brugerstyring/Permits";
import Elektronisk from "../components/sections/digitalmediamanagement/Elektronisk";
import Corrector from "../components/sections/corrector/Corrector";
import Collections from "../components/sections/dco/collections/Collections";
import Feeds from "../components/sections/dco/feeds/Feeds";
import Media from "../components/sections/dco/media/Media"
import FritlagtMain from "../components/sections/fotoClipping/FritlagtMain";
import PriceCheckUpload from "../components/sections/pristjek/PriceCheckUpload";
import PosterGenerator from "../components/sections/PosterGenerator/PosterGenerator";
// import CreateTemplateStep1 from "../components/sections/FilmMaker/templates/Step1";
// import CreateTemplateStep2 from "../components/sections/FilmMaker/templates/Step2";
// import CreateTemplateStep3 from "../components/sections/FilmMaker/templates/Step3";
// import CreateTemplateDelete from "../components/sections/FilmMaker/templates/DeleteTemplate";
// import RenderTasks from "../components/sections/FilmMaker/templates/RenderTasks";
// import CreateRendering from "../components/sections/FilmMaker/templates/CreateRendering";
// import TemplatesTable from "../components/sections/FilmMaker/templates/TemplatesTable";
// import RendersQueue from "../components/sections/FilmMaker/templates/RendersQueue";
// import OffersRendering from "../components/sections/FilmMaker/offers/OffersRendering";
import FilmMakerApp from './../components/sections/FilmMaker/app/FilmMakerApp';
import Hyperlinks from './../components/sections/HelpPage/Hyperlinks';
// TODO research to pass title via props to all types of components
const pageTitles = {
  home: "Hjem",
  publications: GetResource(Resources.MainMenu_Publications_Publications_Title),
  digitalMedia: "Digitale Medier",
  photos: "Fotos",
  photoClip: "Mine Fritlægninger",
  photoTracker: "Fototracker",
  offers: "Tilbud",
  logos: "Logoer",
  products: "Produkter",
  users: "Brugere",
  photoCombinations: "Billed Kombinationer",
  priceCheck: "Pristjek",
  filmMaker: "Film Maker"
}

const AppRoutes = ({ location }) => {
  return (
    // <Switch>
    <Switch component={Fader}>
      <Route path="/" component={() => <HomeContainer title={pageTitles.home} />} exact={true} />
      <Route path="/home" component={() => <HomeContainer title={pageTitles.home} />} />
      <Route path="/publikationer" render={() => <Publikationstyring title={pageTitles.publications} />} />
      <Route path="/elektronisk" render={() => <DigitalMedias title={pageTitles.digitalMedia} />} exact />
      <Route path="/fototracker/search/:photoOrderId" component={Fototracker} />
      <Route path="/fototracker" component={Fototracker} />
      <Route path="/fotos" component={SogFotos} />
      <Route path="/minefritlægninger" component={FritlagtMain} />
      <Route path="/tilbud" component={Tilbudsstyring} />
      <Route path="/produkter" component={Produktstyring} />
      <Route path="/collections" component={Collections} />
      <Route path="/feeds" component={Feeds} />
      <Route path="/media" component={Media} />
      <Route path="/brugers" component={Brugerstyring} />
      <Route path="/logos" component={LogoSection} />
      <Route path="/publikation/:jobId" component={FotoHaandtering} />
      <Route path="/elektronisk/:selectedPublicationId/page/:pageNr" component={Elektronisk} />
      <Route path="/elektronisk/:selectedPublicationId" component={Elektronisk} />
      <Route path="/support" component={Support} />
      <Route path="/corrector" component={Corrector} exact />
      <Route path="/corrector/week" component={Corrector} exact />
      <Route path="/corrector/week/:year/:week" component={Corrector} exact />
      <Route path="/corrector/segment/:customer/:year/:week/:segment" component={Corrector} exact />
      <Route path="/corrector/month/:customer/:month" component={Corrector} exact />
      <Route path="/userpermits/:selectedUserId" component={Permits} />
      <Route path="/værktøjer" component={PriceCheckUpload} />
      {/* <Route path="/FilmMaker/templates" component={TemplatesTable} exact/>
      <Route path="/FilmMaker/Offers" component={OffersRendering} exact/>
      <Route path="/FilmMaker/createTemplate" component={CreateTemplateStep1} exact/>
      <Route path="/FilmMaker/createTemplate/:templateId" component={CreateTemplateStep2} exact/>
      <Route path="/FilmMaker/createTemplate/:templateId/test" component={CreateTemplateStep3} exact/>
      <Route path="/FilmMaker/createTemplate/:templateId/delete" component={CreateTemplateDelete} exact/>
      <Route path="/FilmMaker/templates/:templateId/renders" component={RenderTasks} exact/>
      <Route path="/FilmMaker/templates/:templateId/createRendering" component={CreateRendering} exact/>
      <Route path="/FilmMaker/renders/queue" component={RendersQueue} exact/> */}
      {/* <Route path="/FilmMaker" component={FilmMakerApp} exact/> */}
      <Route path="/FilmMaker/:section" component={FilmMakerApp} exact/>
      <Route path="/FilmMaker/:section/:customer" component={FilmMakerApp} exact/>
      <Route path="/FilmMaker/:section/:id" component={FilmMakerApp} exact/>
      <Route path="/PosterGenerator" component={PosterGenerator} />
      <Route path="/hjælp" component={Hyperlinks}/>

      <Route
        render={() => {
          return <h1>No match!</h1>;
        }}
      />
    </Switch>
  );
};
export default AppRoutes;
 