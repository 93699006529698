import React, { useEffect, useState, useRef} from 'react';
import { Typography} from '@material-ui/core';
import SectionHeader from "../sharedComponents/SecrtionHeader";
import { ActionButton } from '../offerComponents/views/editOffer';
import {useStyles} from "../Screen";
import {uploadFile, getBackgrounds} from "../SharedRequests";
import {endpoints} from "../../../../../constants/services.constants";
import BackgroundCarousel from "../backgroundComponent/BackgroundCarousel";

export default function BackgroundLibrary({
    backgroundSection,
    tilbage,
    customer,
    mediaSelection
}) {
    const [backgrounds, setBackgrounds] = useState(undefined);
    const [file, setFile] = useState(undefined);
    const [loading, setLoading] = useState(undefined);
    const inputFile = useRef(null);
    const classes = useStyles();

    useEffect(() => {
        getData();
        const interval = setInterval(() => {
            getData();
        }, 5000);
        return () => {
            clearInterval(interval);
        }
    }, []);

    const getData = async() => {
        const response = await getBackgrounds(customer);
        setBackgrounds(response);
    }

    const uploadBackground = async (fileToUpload, customerId) => {
        if (!fileToUpload) {
            return;
        }
        const endpoint = endpoints.FILMMAKER_BACKGROUND_POST;
        const formData = new FormData();
        formData.append("file", fileToUpload);
        formData.append("customerId", customerId);

        await uploadFile(formData, endpoint);
        setFile(undefined);
    }

    const onChangeFile = async (event) => {
        const _file = event.target.files[0];
        setFile(_file);
        await uploadBackground(_file, customer);
    };

    const onButtonClick = () => {
        inputFile.current.click();
    };
    
    return(
       <div>
        <SectionHeader
            section={backgroundSection}
            tilbage={tilbage}
            defaultText="Tilføj baggrund"
            primaryText="Baggrundbibliotek"
        />
        {                
            !backgrounds && loading? <Typography align="center">Fetching...</Typography>
                : typeof (loading) === "string" ? <Typography>error: {loading}</Typography>
                    : 
                    <BackgroundCarousel 
                        backgrounds={backgrounds}
                        mediaSelection={mediaSelection} 
                    />
        }
         <div
                style={{ marginTop: 40 }}
            >
                <ActionButton
                    text="Upload medie"
                    onClick={() => onButtonClick()}
                    className={classes.buttonMain}
                    disabled={file}


                />
            </div>
            <input 
                onChange={(e) => onChangeFile(e)}  
                accept="image/jpg, image/jpeg" 
                type='file' 
                id='file' 
                ref={inputFile} 
                style={{ display: 'none' }} 
            />
        </div>
    )
}