import { _request } from "../../../../helpers/HttpHelper";
import { apiGetParamsReplace, endpoints } from "../../../../constants/services.constants";
import { getToken } from "../../../../helpers/AuthHelper";
import Resources from "../../../../Resources.json";
export async function uploadPhoto(file) {
  const formData = new FormData();
    formData.append("file", file);

    return _request({
        method: "POST",
        endpoint: apiGetParamsReplace(endpoints.HELPPAGE_Post_Single_PictureFile),
        body: formData,
        throwError: true,
        headers: {
          Authorization: "Bearer " + getToken(),
          Accept: "image/jpeg, image/png",
          "x-organization-id": Resources.Organization
      }
      });
}

export async function getHelpPage(id) {
    return _request({
        method: "GET",
        endpoint: apiGetParamsReplace(endpoints.HELPPAGE_Get_Single_Page, id),
        skipBodyParsing: true,
        throwError: true
      });
}

export async function getHelpPages() {
  return _request({
      method: "GET",
      endpoint: apiGetParamsReplace(endpoints.HELPPAGE_Assets_Pages)
    });
}

export async function getHelpPageFile(filename) {
    const endpoint = apiGetParamsReplace(endpoints.HELPPAGE_Get_Single_PictureFile, filename);
    let reader = new FileReader();
    const response = await fetch(endpoint);
    const resBlob = await response.blob();
    let dataUrl = undefined;
    reader.onload = function () {
     dataUrl = reader.result;
    } 
    reader.readAsDataURL(resBlob);
    return dataUrl;
}

export async function createHelpPage(formdata) {
  return await _request({
      method: "POST",
      endpoint: apiGetParamsReplace(endpoints.HELPPAGE_Assets_Pages),
      body: JSON.stringify(formdata)
  });
}

export async function updateHelpPage(formdata) {
  return await _request({
      method: "PUT",
      endpoint: apiGetParamsReplace(endpoints.HELPPAGE_Assets_Pages),
      body: JSON.stringify(formdata)
  });
}

export async function deleteHelpPage(id) {
  return await _request({
      method: "DELETE",
      endpoint: apiGetParamsReplace(endpoints.HELPPAGE_Get_Single_Page, id),
  });
}