import React from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import Snackbar from "@material-ui/core/Snackbar";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import { green, amber, red } from "@material-ui/core/colors";
import { connect } from "react-redux";
import { appConstants } from "../../constants/app.constants";

const styles1 = theme => ({
  close: {
    padding: theme.spacing(0.5)
  },
  success: {
    backgroundColor: green[600]
  },
  error: {
    backgroundColor: red[900]
  },
  info: {
    backgroundColor: theme.palette.primary.dark
  },
  warning: {
    backgroundColor: amber[700]
  },
  icon: {
    fontSize: 20
  },
  iconVariant: {
    opacity: 0.9,
    marginRight: theme.spacing(1)
  },
  message: {
    display: "inline-block",
    wordWrap: "break-word",
    wordBreak: "break-word"
  }
});

class AppSanckbar0 extends React.Component {
  state = {
    open: false,
    msg: null,
    type: ""
  };

  componentDidUpdate() {
    this.nextMsg();
  }

  nextMsg = () => {
    if (this.props.toastsReducer.messages.length > 0 && !this.open) {
      let c = this.props.toastsReducer.messages.shift();
      this.setState({ msg: c.message });
      this.setState({ open: true });
      this.setState({ type: c.type });
    }
  };

  handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    this.setState({ open: false });
  };

  render() {
    const { classes } = this.props;
    return (
      <div>
        <Snackbar
          variant={this.state.type}
          ContentProps={{
            classes: {
              root:
                this.state.type === "error"
                  ? classes.error
                  : this.state.type === "success"
                    ? classes.success
                    : classes.info
            },
            "aria-describedby": "message-id"
          }}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "right"
          }}
          open={this.state.open}
          autoHideDuration={appConstants.TOAST_DURATION}
          onClose={this.handleClose}
          onExited={this.nextMsg}
          message={
            <span id="message-id" className={classes.message}>
              {this.state.msg}
            </span>
          }
          action={[
            <IconButton
              key="close"
              aria-label="Close"
              color="inherit"
              className={classes.close}
              onClick={this.handleClose}
            >
              <CloseIcon />
            </IconButton>
          ]}
        />
      </div>
    );
  }
}

AppSanckbar0.propTypes = {
  classes: PropTypes.object.isRequired
};

const AppSanckbar = connect(state => state)(AppSanckbar0);
export default withStyles(styles1)(AppSanckbar);
