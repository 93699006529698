import React, { useState } from 'react';
import FormHelpPage from './FormHelpPage';
import Grid from '@material-ui/core/Grid';

function AddHelpPageContent(props) {
    const { helpPageObject, modalOpen, setHelppages } = props;

    return (
        <div>
            <Grid container>
                <Grid item xs={1}></Grid>
                <Grid item xs={10}>
                    <div align="center">
                        <h2 id="simple-modal-title">Indsæt/Redigere en hjælpe side</h2>
                        <p id="simple-modal-description">
                            For at oprette en hjælpe side, skal der laves trin. De hver trin laves i en tekst og hvis der skal visual format (billede på max 5 mb.)
                            til trinets forståelse, indsættes det i Billede. Når trinet er færdig, trykker man på "tilføj trin", hvor der kan skrives det næste trin.
                            Når hele hjælpesiden skal indsættes skal der trykkes på knappen "Indsæt".
                        </p>
                    </div>
                </Grid>
            </Grid >
            <div align="center">
                <FormHelpPage helpPageObject={helpPageObject} modalOpen={modalOpen} setHelppages={setHelppages} />
            </div>
        </div>
    );
}

export default AddHelpPageContent;