import React, { useContext, useEffect } from 'react';
import MUIDataTable from 'mui-datatables';
import { MediaContext } from '../contexts/MediaContext';
import { FeedsContext } from '../contexts/FeedsContext';
import ActionButtons from './ActionButtons';

function MediaTable(props) {
    const { media: mediaState, templates: templatesState } = useContext(MediaContext);
    const [media,] = mediaState;
    const { feeds: feedsState } = useContext(FeedsContext);
    const [feeds,] = feedsState;
    const [templates,] = templatesState;

    useEffect(() => {
        getDataTable();
    }, [])

    const columns = [
        {
            name: "Id",
            options: {
                filter: false,
                sort: false,
                display: false,
            }
        },
        {
            name: "Navn",
            options: {
                filter: false,
                sort: false,
                display: true,
            }
        },
        {
            name: "Transport",
            options: {
                filter: false,
                sort: false,
                display: true,
            }
        },
        {
            name: "Feed",
            options: {
                filter: false,
                sort: false,
                display: true,
            }
        },
        {
            name: "Skabelon",
            options: {
                filter: false,
                sort: false,
                display: true,
            }
        },
        {
            name: "",
            options: {
                filter: false,
                sort: false,
                display: true,
            }
        },
    ]

    const options = {
        textLabels: {
            body: {
                noMatch: "",
                toolTip: "Sorter"
            },
            pagination: {
                next: "Næste side",
                previous: "Forrige side",
                rowsPerPage: "Collections per side:",
                displayRows: "af"
            },
            selectedRows: {
                text: "valgt række(r)",
            }
        },
        filter: false,
        filterType: "checkbox",
        rowsPerPage: 15,
        print: false,
        responsive: "scrollFullHeight",
        download: false,
        viewColumns: false,
        search: false,
        selectableRows: true,
        expandableRows: false,
    }

    const getFeed = (item) => {
        let feed = feeds.find(x => item.feed.id === x.feedId);
        if (!feed) {
            return "";
        } else {
            return feed.feedName.name;
        }
    }

    const getTemplate = (item) => {
        let template = templates.find(x => x.id === item.mediaTemplate);
        if (!template) {
            return "";
        } else {
            return template.name;
        }
    }

    const getDataTable = () => {
        if (!media) {
            return;
        }

        return media.map(item => [
            item.mediaDefinitionId,
            item.mediaName ? item.mediaName.name : "",
            item.mediaTransport ? item.mediaTransport.displayName : "",
            getFeed(item),
            getTemplate(item),
            <ActionButtons item={item} />
        ]
        )
    }

    return (
        <>
            <MUIDataTable
                data={getDataTable()}
                columns={columns}
                options={options}
            />
        </>
    )
}
export default MediaTable