import { Typography } from "@material-ui/core";
import React from "react"
import { apiGetParamsReplace, endpoints } from "../../../../../constants/services.constants";


export function DialogHeader(props) {
    const { description, code, selectedImage } = props;

    return <>
        {selectedImage ? (
            <img
                src={apiGetParamsReplace(endpoints.PHOTO_SINGLE_200, selectedImage)}
                className="productImage"
                alt={description}
            />
        ) : undefined}
        <div className="productHeadline">
            <Typography variant="h6" gutterBottom>
                {description}
            </Typography>
        </div>
        <div className="productHeadline">
            <Typography variant="caption" gutterBottom>
                Kode: {code}
            </Typography>
        </div>
    </>
}