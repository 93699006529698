import React, { useEffect, useState } from "react";
import { Typography, Grid, Button } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import { connect } from "react-redux";
import { LoadingLogo } from "../../shared/Loading";
import PaginationWithStyles from "material-ui-flat-pagination";
import history from "../../../routes/history";
import SearchBar from "../../shared/components/searchBar/SearchBar";
import { getOffer, getOffersForPublication, getPhotos, getPhoto, getPublication } from "./Requests";
import OffersContent from "./offercards/OffersContent";
import { Helmet } from "react-helmet";
import { canReadApplication, canReadApplicationForCustomer } from "../../../helpers/CredentialsHelper";
import { applications } from "../../../constants/app.constants";

const styles = theme => ({
  root: {
    heigth: "100%",
    maxWidth: "500px",
    [theme.breakpoints.up("sm")]: {
      maxWidth: "600px"
    },
    [theme.breakpoints.up("md")]: {
      maxWidth: "900px"
    },
    [theme.breakpoints.up("lg")]: {
      maxWidth: "1300px"
    },
    [theme.breakpoints.up("xl")]: {
      maxWidth: "1800px"
    }
  },
  paper: {
    textAlign: "center",
    padding: "20px",
    marginBottom: "20px"
  },
  backButton: {
    marginTop: 20
  }
});

const ColorButton = withStyles(() => ({
  root: {
    marginRight: "10px",
    backgroundColor: "#263238",
    color: "#ffffff",
    '&:hover': {
      backgroundColor: "#11171A",
      color: "#ffffff"
    },
  },
}))(Button);

function Elektronisk({ match, classes }) {
  const [loading, setLoading] = useState(true);
  const [searchCount, setSearchCount] = useState(0);
  const [allOffers, setAllOffers] = useState(null);
  const [visibleOffers, setVisibleOffers] = useState(null);
  const [publication, setPublication] = useState([]);
  const [page, setPage] = useState(1);
  const [searchText, setSearchText] = useState("");
  const pageSize = 40;

  // inital load of data
  useEffect(() => {
    const pageNr = match.params.pageNr;
    if (pageNr > 0)
      setPage(pageNr);
    const publicationId = match.params.selectedPublicationId;
    loadInitialData(publicationId);
  }, []);

  // everytime the search text or page changes we make a new search
  useEffect(() => {
    if (allOffers != null) {
      handleSearch();
      setLoading(false);
    }

  }, [allOffers, page, searchText]);

  const loadInitialData = async (publicationId) => {
    setLoading(true);
    const offersPromise = getOffersForPublication(publicationId);
    const publication = await getPublication(publicationId);
    setPublication(publication);
    let offersForPublication = await offersPromise;

    const photoIds = offersForPublication
      .filter(x => x.products.length > 0 && x.products[0].selectedPhotoId > 0)
      .map(x => x.products[0].selectedPhotoId)
    const photos = await getPhotos(photoIds);

    // sort by name
    offersForPublication = offersForPublication.sort((a, b) => {
      if (a.description < b.description) { return -1; }
      if (a.description > b.description) { return 1; }
      return 0;
    })
      .map(x => {
        if (x.products.length > 0) {
          const photo = photos.find(p => p.id === x.products[0].selectedPhotoId);
          x.selectedPhoto = photo ? photo : null;
        }
        return x;
      });
    setAllOffers(offersForPublication);
  }

  const handleSearchTextChange = (text) => {
    if (text !== searchText) {
      setSearchText(text);
      setPage(1);
    }
  }

  const handleSearch = () => {
    const loweredText = searchText.toLowerCase();
    const startIndex = (page - 1) * pageSize;
    const searchResult = allOffers.filter(x => {
      if (!searchText) {
        return true;
      }

      // search product number or name
      if (x.products.some(p => p.name.toLowerCase().includes(loweredText) || p.productNumber === loweredText)) {
        return true;
      }
      // search offer name
      if (x.description.toLowerCase().includes(loweredText)) {
        return true;
      }
      return false;
    });
    setSearchCount(searchResult.length);
    setVisibleOffers(searchResult.slice(startIndex, startIndex + pageSize));
  }

  const handleOfferUpdated = async (offerId) => {
    // refresh the offer
    const offer = await getOffer(offerId);
    const photo = await getPhoto(offer.products[0].selectedPhotoId);
    offer.selectedPhoto = photo;
    const index = allOffers.findIndex(x => x.id === offerId);
    allOffers[index] = offer;

    handleSearch();
  }

  const handleGoBack = () => {
    history.push("/elektronisk");
  }
  if(publication.multiCustomerId != null){
    if (!canReadApplicationForCustomer(applications.DIGITALEMEDIER,publication.multiCustomerId)) {
      return (
        <Typography variant="subtitle1" align="center">
          Du har ikke adgang til denne side. Kontakt support
          (mbsupport@republica.dk), hvis dette er en fejl.
        </Typography>
      );
    }
  }
  

  return (
    <>
      <Helmet>
        <title>{`Elektronisk-${publication.jobId}`}</title>
      </Helmet>
      <Grid container>
        <Grid item xs={1}>
          <ColorButton className={classes.backButton} onClick={() => handleGoBack()} color="primary">
            Tilbage
          </ColorButton>
        </Grid>
        <Grid item xs={11}>
          <div className={classes.root}
          // onKeyDown={this.handleKeyDown}
          >
            <Typography variant="h4" name="textDigitalPublicationName">
              JobID: {publication.jobId} - {publication.name}
            </Typography>

            <SearchBar onSearch={(searchText) => handleSearchTextChange(searchText)} />
            {loading ?
              <LoadingLogo /> :
              <>
                <OffersContent
                  offers={visibleOffers}
                  publication={publication}
                  onOfferUpdated={id => handleOfferUpdated(id)}
                />
                <PaginationWithStyles
                  limit={pageSize}
                  offset={(page - 1) * pageSize}
                  total={searchCount}
                  onClick={(e, offset) => { setPage((offset / pageSize) + 1) }}
                  centerRipple={true}
                  currentPageColor="secondary"
                  otherPageColor="default"
                  style={{ textAlign: "center" }}
                />
              </>
            }
          </div>
        </Grid>
      </Grid>
    </>
  );
}

export default connect(state => state)(withStyles(styles)(Elektronisk));
