import { Checkbox, IconButton, Menu, MenuItem, withStyles } from '@material-ui/core';
import React, { useState } from 'react';
import MenuIcon from '@material-ui/icons/Menu';
import store from '../../../../redux/store';
import { toggleSelectedOffer } from '../../../../redux/actions/fotohaandtering/fotohaandtering.actions';
import { getPrisStyrke } from './SharedFunctions';
import { IsFeatureEnabled, featureNames } from "../../../../helpers/FeaturesHelper";
import { applications } from '../../../../constants/app.constants';
import { canDeleteApplicationForCustomer } from '../../../../helpers/CredentialsHelper';
const styles = theme => ({
    contentHolder: {
        position: "absolute"
    }
})

function OfferHeader({ headingContainerClass, anchorEl,
    setAnchorEl, handleCreatePhotoOrderOffer, handleEdit, color,
    handleDeleteOffer, offer, classes, refe, width
}) {
    const reducer = store.getState().fotohaandteringReducer;
    const price = offer.prices && offer.prices.price ? `${offer.prices.price}kr.` : "";

    const isReadOnlyMode = reducer.publication == null ? true : !canDeleteApplicationForCustomer(applications.FOTOHAANDTERING, reducer.publication.multiCustomerId)

    return (
        <div ref={refe} className={classes.contentHolder}>
            <div
                className={headingContainerClass}
                style={{ width: width, color: color }}
            >
                {isReadOnlyMode ? undefined :
                (
                    <IconButton
                        className="float-left smaller_icon"
                        aria-owns={anchorEl ? "simple-menu" : undefined}
                        aria-haspopup="true"
                        onClick={(e) => setAnchorEl(e.currentTarget)}
                        style={{ marginTop: 4, color: color }}
                    >
                        <MenuIcon />
                    </IconButton>
                )}
                <Menu
                    anchorEl={anchorEl}
                    open={Boolean(anchorEl)}
                    onClose={() => setAnchorEl(null)}
                >
                    {!IsFeatureEnabled(featureNames.PhotoOrders_Enabled) ? undefined :
                        <MenuItem onClick={() => handleCreatePhotoOrderOffer(offer)}>
                            Bestil foto
                        </MenuItem>
                    }
                    <MenuItem onClick={() => handleEdit(offer.id)}>
                        Rediger tilbud
                    </MenuItem>
                    <MenuItem onClick={() => handleDeleteOffer()}>
                        Slet tilbud
                    </MenuItem>
                </Menu>
                {isReadOnlyMode ? undefined :
                (
                    <Checkbox
                        checked={reducer.selectedOfferIds.includes(offer.id)}
                        onChange={() => store.dispatch(toggleSelectedOffer(offer.id))}
                        value="checked"
                        style={{ paddingLeft: 5, paddingRight: 5, color: color }}
                    />
                )}
                {offer.description}, {getPrisStyrke(offer)}, {price}
                
            </div>
        </div>
    )
}

export default withStyles(styles)(OfferHeader)