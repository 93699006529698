import { blueGrey } from "@material-ui/core/colors";

export const appConstants = {
    COMPANY_NAME: 'Republica',
    COMPANY_URL: 'http://republica.dk/',
    YEAR: '2019',
    TOKEN_CHECKING: 300000,//ms
    TOKEN_PREV_EXP_LOGOUT: 3600, //s,
    TOAST_DURATION: 10000,
    NOTIFICATIONS_INTERVAL: 400000//ms

};

export const appThemeConstants = {
    PRIMARY_1: blueGrey[900],
    PRIMARY_2: blueGrey[800],
    PRIMARY_3: blueGrey[700],
    PRIMARY_4: blueGrey[600],
    PRIMARY_TEXT: blueGrey[50],
    DRAWER_WIDTH: 230
}

export const applications = {
    FOTOHAANDTERING: "22460e2a-298c-4989-b596-a32a7c40c35d",
    POSTER: "23982e0b-d97f-4459-af92-13e1b81a79b3",
    BANNER: "c6a60d61-bcea-4fb8-a0cf-35e51e2b6004",
    PUBLICATION: "d0769103-cce1-4eae-b0a1-643b1f804f21",
    DIGITALEMEDIER: "53830f35-862d-4f27-9ae6-062e25470135",
    FOTOS: "53830f35-862d-4f27-9ae6-062e25400114",
    FOTOGRAF: "53830f35-862d-4f27-9ae6-062e25470124",
    FOTOTRACKER: "53830f35-862d-4f27-9ae6-062e25400124",
    FOTOTRACKETSTATUS: "99988888-862d-4f27-9ae6-888888888999",
    TILBUDSSTYRING: "53830f35-862d-4f27-9ae6-062e25400134",
    PRODUKTER: "53830f35-862d-4f27-9ae6-062e25400144",
    BRUGERSTYRING: "53830f35-862d-4f27-9ae6-062e25400154",
    LOGOSTYRING: "53830f35-862d-4f27-9ae6-062e25400164",
    WORKFLOWS: "53830f35-862d-4f27-9ae6-062e25400174",
    CORRECTOR: "88888888-862D-4F27-9AE6-888888888888",
    DCO: "66688659-862f-5f27-9ae6-888228488999",
    MINEFRITLÆGNINGER: "66698660-862F-5F27-9AE6-878228488994",
    KVITTERINGSGENERATOR: "0109bd67-760b-4cbc-ad2a-0f01f113d5cf",
    REDIGERTILBUD: "32560E2A-298B-4989-B596-B72A7C40C354",
    ERSTATFOTO: "53830A56-862D-4F27-9AE6-062E25400114",
    REDIGERTILBUDSPRIS: "939eb13d-3e5e-4375-8dab-06689ad2a022",
    VIEW_EXCLUSIVE_PHOTOS: "193f469e-69d1-42b3-b1fb-7eb42594f714",
    EDIT_EXCLUSIVE_PHOTOS: "c7cfca6f-f8d7-4a1e-94e5-2a877143f3bc",
    MÅNEDENS_KUP: "e3a8c6db-29e5-48e3-9478-b519f757d500",
    HELP_PAGE: "ce3736b4-7bec-47e2-b849-4f2735aebb33",
}

export const permits = {
    CanRead: "CanRead",
    CanDelete: "CanDelete",
    IsActive: "IsActive",
}

export const customers = [
    { "id": 10, "name": "fakta", "renderName": "Fakta" },
    { "id": 11, "name": "superbrugsen", "renderName": "SuperBrugsen" },
    { "id": 17, "name": "daglibrugsen", "renderName": "DagliBrugsen" },
    { "id": 20, "name": "kvickly", "renderName": "Kvickly" },
    { "id": 30, "name": "irma", "renderName": "Irma" },
    { "id": 51, "name": "coop365", "renderName": "Coop 365" },

    { "id": 51, "name": "Jem&Fix Danmark", "renderName": "Jem&Fix Danmark" },
    { "id": 52, "name": "Jem&Fix Sverige", "renderName": "Jem&Fix Sverige" },
    { "id": 56, "name": "Jem&Fix Norge", "renderName": "Jem&Fix Norge" },


];

export const userDepartments = [
    { "id": "6e9908de-caab-48d4-940a-9211906b30c3", "name": "Coop", "description": "Coop medarbejdere", "multibaseDomainUrl": "coop.multibasen.dk" },
    { "id": "7c55a3b5-f352-402e-8902-b1acb5c0134f", "name": "Coop butikker", "description": "Butiksansatte", "multibaseDomainUrl": "coop.multibasen.dk" },
    { "id": "f9cd8b3c-aab1-4077-a714-3529c8ee196a", "name": "Ekstern", "description": "Eksterne brugere", "multibaseDomainUrl": "coop.multibasen.dk" }, 
    { "id": "b1494ee1-f1d5-4623-ba73-cbf825b3c84b", "name": "Republica", "description": "Republica medarbejdere", "multibaseDomainUrl": "coop.multibasen.dk" }
];

// export function getCustomerName(customerId){
//     let c = customers.find(x => x.id === customerId);
//     if (c == null) return customerId;
//     else return c.name;

// }