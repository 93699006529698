import React, { useState } from 'react';
import { Grow, withStyles } from '@material-ui/core';
import OfferChild from './OfferChild';
import OfferHeader from './OfferHeader';
import { offerType } from '../../../../constants/enums';
import { apiGetParamsReplace, endpoints } from '../../../../constants/services.constants';
import OfferProduct from './OfferProduct';

const styles = theme => ({
    offerContent: {
        height: 322,
        float: "left",
        marginBottom: 25,
        marginLeft: 0,
        backgroundColor: "#ffffff",
        boxShadow: "0px 1px 5px 0px rgba(0, 0, 0, 0.2),0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 3px 1px -2px rgba(0, 0, 0, 0.12)",
    },
    cardHolder: {
        marginRight: 10,
    },
    offerType: {
        color: "#bbb"
    },
    headlineExtended: {
        width: "100%",
        position: "relative",
        fontSize: "1rem",
        zIndex: 1,
        fontWeight: 500,
        paddingLeft: 5,
        color: "#ffffff"
    },
    checkboxColor: {
        color: "#ffffff"
    }
});
function OfferParent(props) {
    const { offer, classes, handleEdit, handleDeleteOffer, handleCreatePhotoOrderOffer, 
        onOfferChange, expanded, categories, deactivatedPhotos } = props;
    const [anchorEl, setAnchorEl] = useState(null);

   
    return (
        <>
            <div className={classes.offerType}>{offerType[offer.type].name}</div>
            <div className={classes.cardHolder}>
                <div className={classes.offerContent}>
                    <OfferHeader
                        headingContainerClass={classes.headlineExtended}
                        offer={offer}
                        color={"#FFFFFF"}
                        anchorEl={anchorEl}
                        setAnchorEl={(value) => setAnchorEl(value)}
                        handleCreatePhotoOrderOffer={(offer) => handleCreatePhotoOrderOffer(offer)}
                        handleEdit={() => handleEdit(offer.id)}
                        handleDeleteOffer={() => handleDeleteOffer()}
                    />
                </div>
                {/* Mapping childoffers. before that though we need products under the parent. */}
                {offer.products.map((product, index) => (
                    <>
                        <OfferProduct
                            onProductChange={() => onOfferChange(offer.id, false)}
                            product={product}
                            expanded={true}
                            index={index}
                            offer={offer}
                            totalProducts={offer.products.length}
                            isLastChild={false}
                            isSammixManus={true}
                            categories={categories}
                            deactivatedPhotos={props.deactivatedPhotos}
                        />
                    </>
                ))}

                {offer.childOffers.map((childOffer, internalIndex) => {
                    const imageUrl = apiGetParamsReplace(endpoints.PHOTOCOMBINATION_OFFERS_GET_PHOTO_COMBINATION, childOffer.id);
                    return (
                        <Grow
                            key={internalIndex}
                            in={expanded}
                            style={{ transformOrigin: "0 0 0" }}
                        >
                            <OfferChild
                                offer={childOffer}
                                expanded={expanded}
                                index={internalIndex}
                                totalChilds={offer.childOffers.length}
                                imageUrl={imageUrl}
                                isSamMix={offer.type === "CombinedMix"}
                                isSammixManus={true}
                                onOfferChange={(offerParentId, deleted) => onOfferChange(offerParentId, deleted)}
                                categories={categories}
                                deactivatedPhotos={props.deactivatedPhotos}
                            />
                        </Grow>
                    )
                })
                }
            </div>
        </>
    )
}
export default withStyles(styles)(OfferParent)