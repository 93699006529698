import React, { useEffect, useState } from "react";
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import Image from 'material-ui-image';
import { DialogTitle } from '@material-ui/core';
import { apiGetParamsReplace, endpoints } from '../../../constants/services.constants';
import { getMotivId } from '../../../helpers/UtilHelper';
import createDialog from '../../../DialogRenderer';
import { getPhotoOrderSingleV2, getPhotoOrdersByPhotoIds} from "../../sections/fotohaandtering/Requests";
import { getUser } from "../../../redux/actions/users/users.actions";
import { getPhotoSingle, getUserById } from '../Requests';

import { useSelector } from 'react-redux';

export async function openDialogFullsizePhoto(photoId) {



  return createDialog(({ show, onSubmit, onDismiss }) => (
    <DialogFullsizePhoto photoId={photoId}
      open={show}
      onCancel={onDismiss}
      onConfirm={onSubmit}
    />
  ));
}






function DialogFullsizePhoto({ photoId, open, onCancel }) {

  const [imageLoaded, setImageLoaded] = useState(false); // State to hold the data
  const [user, setUser] = useState(null); // State to hold the data
  const [showUser, setShowUser] = useState(false); // State to hold the data
  const [userInitialized, setUserInitialized] = useState(false); // State to hold the data

  const photoUrl = apiGetParamsReplace(endpoints.PHOTO_SINGLE_800, [photoId]);
  const motiveId = getMotivId(photoId);

  const reducer = useSelector(state => state.credentialsReducer);

  useEffect(() => {
    const fetchData = async () => {
      
      var userId = undefined;

      //get logged-in user id from first permission available
      var currentUserId = reducer.me[0].multiUserId;
      var currentUser = await getUserById(currentUserId);

      //this is a database defined id - hardcoded for performance reasons to avoid getting it from db
      const republicaDepartmentId = 'b1494ee1-f1d5-4623-ba73-cbf825b3c84b';

      //only Republica users can see who uploaded the photo
      if(currentUser.aspNetDepartmentId !== republicaDepartmentId){        
        setShowUser(false);
        return;
      }

      setShowUser(true);

      var order = await getPhotoOrdersByPhotoIds([photoId]);
  
      //user from order
      if(order !== undefined && order.length == 1 && order[0].ExecutedByUserId != undefined){
          userId = order.ExecutedByUserId;
      }

      //user from photo
      else{  
        var photo = await getPhotoSingle(photoId);

        if(photo !== undefined && photo.createdByUser !== undefined){
          userId = photo.createdByUser;
        }
      }

      //set user
      if(userId !== undefined){        
        var photoUser = await getUserById(userId);
        setUser(photoUser.fullname + " ("+photoUser.email+")");
        setShowUser(true);
      }      
      else{
        setShowUser(false);
      }

      setUserInitialized(true);
    };  
    fetchData();  
  }, []);
  const handleImageLoad = () => {
    setImageLoaded(true);
  }
  return (
    <Dialog
      open={open}
      onClose={onCancel}
      aria-labelledby="form-dialog-title"
      fullWidth={true}
      maxWidth="sm"
    >
      <DialogTitle>{motiveId}</DialogTitle>
      <DialogContent>
        <Image
           onLoad={handleImageLoad}
           style={{ "position": "unset", "paddingTop": "unset", }}
           imageStyle={{
             position: "unset",
             maxHeight: 800,
             width: "auto",
             height: "auto",
             maxWidth: "100%",
             display:"block",
             marginLeft: "auto",
             marginRight: "auto"   
           }}
           
          disableTransition={true}
          src={photoUrl}
        />
        { (!showUser || !imageLoaded || !userInitialized) ? 
          <></> :
          <div><p/>{"Billedet er tilføjet af: " + (!user ? "(ukendt bruger)" : user)}</div>
        }
      </DialogContent>
      <DialogActions>
        <Button onClick={onCancel}>Luk</Button>
      </DialogActions>
    </Dialog>
  );

}
