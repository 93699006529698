import { Component } from 'react';
import { fabric } from 'fabric';
import { endpoints } from '../../../../../constants/services.constants';
import { getMotiv300 } from '../../../../../helpers/ImageHelper';
import { getMotiv1000 } from './../../../../../helpers/ImageHelper';
export class PhotoCombinationBase extends Component {

  constructor(props) {
    super(props);
    this.canvas = null;
    this.renderCanvas = this.renderCanvas.bind(this)
    this.canvasImage = this.canvasImage.bind(this);
    // this.removeGrid = this.removeGrid.bind(this);    
    this.addGrid = this.addGrid.bind(this);
    this.takeSizeMutationsHandler = this.takeSizeMutationsHandler.bind(this);
    this.takeSizeMutations = this.takeSizeMutations.bind(this);
    this.disableSelectionSideGrowingHandler = this.disableSelectionSideGrowingHandler.bind(this);
    this.endpoints = endpoints;
    this.defaultCanvasWidth = 1000;
    this.defaultCanvasHeight = 750;


    this.customProperties = [
      "motiveId",
      "url",
      "previewUrl",
      "src",
      "zIndex",
      "boundLeft",
      "boundTop",
      "boundHeight",
      "boundWidth",
      // "aCoords","oCoords",
      "rotate",
      "priority",
      "zIndex",
      "eaNnr",
      "name",
      "motivType",
      "dimensionsHeight",
      "dimensionsHeightUnit",
      "dimensionsWidth",
      "dimensionsWidthUnit",
      "dimensionsDepth",
      "dimensionsDepthUnit",
      "dimensionsNetWeight",
      "dimensionsNetWeightUnit",
      "dimensionsGrossWeight",
      "dimensionsGrossWeightUnit"];
  }

  get grid() {
    return this.props.grid !== undefined ? this.props.grid : 50;
  }

  get canvasWidth() {
    if (this.props.combinedImageWidth === undefined && this.state.template !== undefined) { return this.state.template.canvasWidth } else { return this.defaultCanvasWidth }
  }

  get canvasHeight() {
    if (this.props.combinedImageHeight === undefined &&
      this.state.template !== undefined) {
      return this.state.template.canvasHeight
    } else if (this.props.combinedImageHeight !== undefined &&
      this.props.combinedImageWidth !== undefined
      // && this.props.combinedImageWidth !== this.defaultCanvasWidth
    ) {
      let r = (this.defaultCanvasWidth * this.props.combinedImageHeight) / this.props.combinedImageWidth
      return parseInt(r)
    } else {
      return this.defaultCanvasWidth
    }
  }

  get combinedImageWidth() {
    return this.props.combinedImageWidth !== undefined ? this.props.combinedImageWidth
      : this.defaultCanvasWidth
  }

  get combinedImageHeight() {
    return this.props.combinedImageHeight !== undefined ? this.props.combinedImageHeight
      : this.defaultCanvasHeight
  }

  addKeydownEventListener() {
    var canvasWrapper = document.getElementById(this.props.canvasId + '-wrapper')
    canvasWrapper.tabIndex = Math.random() * (1000 - 10) + 10
    canvasWrapper.addEventListener('keydown', this.handleKeydown.bind(this), false)
  }

  removeKeydownEventListener() {
    var canvasWrapper = document.getElementById(this.props.canvasId + '-wrapper')
    canvasWrapper.tabIndex = 0
    canvasWrapper.removeEventListener('keydown', this.handleKeydown.bind(this), false)
  }

  handleKeydown(e) {
    var key = e.keyCode || e.charCode

    if (key === 67) { this.cloneImage() }
    if (key === 8 || key === 46) { this.deleteCanvasImage() }
  }

  cloneImage() {
    var c = this.canvas;
    let o = c.getActiveObject()
    if (o != null && o.get('type') === 'image') {
      o.clone(function (clone) {
        c.add(clone.set({
          left: o.left + 10,
          top: o.top + 10
        }));
      }, this.customProperties);
    }
  }

  deleteCanvasImage() {
    let o = this.canvas.getActiveObject()
    if (o != null && o.get('type') === 'image') {
      this.canvas.remove(o)
    }
  }

  addDoubleClickEventListener() {
    this.canvas.on('mouse:dblclick', this.getSelectedImageToFront.bind(this))
  }

  removeDoubleClickEventListener() {
    this.canvas.off('mouse:dbclick', () => alert('bye'))
  }

  getSelectedImageToFront(e) {
    let o = this.canvas.getActiveObject()
    if (o != null && o.get('type') === 'image') {
      this.canvas.bringToFront(o)
    }
  }

  addRightClickEventListener() {

    this.canvas.on('mouse:down', this.triggerRightClickBehavior.bind(this))
  }

  removeRightClickEventListener() {
    this.canvas.off('mouse:down', () => console.log('bye bye event right click :D'))
  }

  sortByZIndex(canvas) {
    let obs = canvas.getObjects()
    obs = obs.filter(function (o) {
      return o.type && o.type == "image";
    });
    obs = obs.sort((a, b) => (a.zIndex < b.zIndex ? -1 : 1) * (true ? 1 : -1));
    if (obs != null && obs.length != null) {
      for (var i = 0; i < obs.length; i++) {
        canvas.bringToFront(obs[i]);
      }
    }

    canvas.renderAll();
  }

  canvasImage(image) {
    // console.log(image)
    let previewUrl = getMotiv300(image.motiveId, image.rotate);
    let url = getMotiv1000(image.motiveId, image.rotate)

    this.imageLoadStarts(previewUrl)
    let imageLoadComplete = this.imageLoadComplete;
    let canvas = this.canvas;
    let sortByIndex = this.sortByZIndex;
    let doesTakeSizeMutations = this.props.takeSizeMutations !== undefined;
    let takeSizeMutations = this.takeSizeMutations;
    var photo = new Image()
    photo.crossOrigin = 'Anonymous'
    photo.onload = function (ev) {
      // console.log(photo.width, photo.height);
      // let proportionalHeight = (photo.height * width) / photo.width;
      // let scaleX = image.width / image.width;
      // let scaleY = proportionalHeight / photo.height;
      let options = {
        // angle: 45,
        rotate: image.rotate === undefined ? 0 : image.rotate,
        // width: image.width,
        // height: image.height,
        angle: image.angle,
        left: image.left,
        top: image.top,
        scaleX: image.scaleX, // width/photo.width,
        scaleY: image.scaleY,
        crossOrigin: 'anonymous',
        motiveId: image.motiveId,
        previewUrl: previewUrl,
        src: previewUrl,
        url: url,
        lockRotation: true,
        hasRotatingPoint: false,
        priority: image.priority,
        zIndex: image.zIndex,
        motivType: image.motivType,
        name: image.name,
        eaNnr: image.eaNnr,
        dimensionsHeight: image.dimensionsHeight,
        dimensionsHeightUnit: image.dimensionsHeightUnit,
        dimensionsWidth: image.dimensionsWidth,
        dimensionsWidthUnit: image.dimensionsWidthUnit,
        dimensionsDepth: image.dimensionsDepth,
        dimensionsDepthUnit: image.dimensionsDepthUnit,
        dimensionsNetWeight: image.dimensionsNetWeight,
        dimensionsNetWeightUnit: image.dimensionsNetWeightUnit,
        dimensionsGrossWeight: image.dimensionsGrossWeight,
        dimensionsGrossWeightUnit: image.dimensionsGrossWeightUnit
      }

      var p = new fabric.Image(photo, options)
      p.scaleToHeight(image.height)
      p.scaleToWidth(image.width)
      p.set({
        transparentCorners: false,
        borderColor: '#ff00ff',
        cornerColor: '#ff0000'
      });
      p.setControlsVisibility({
        mt: false,
        mb: false,
        ml: false,
        mr: false,
        bl: true,
        br: true,
        tl: true,
        tr: true,
        mtr: false,
      });
      // p.width= image.width;
      // p.height= image.height;
      console.log(p)
      canvas.add(p)
      sortByIndex(canvas);
      imageLoadComplete(previewUrl);

      if (doesTakeSizeMutations) takeSizeMutations();
    }
    photo.onerror = (data) => {
      // console.log(data)
      let msg = this.state.loadingImagesError
      if (msg == null) { msg = 'Error: ' + previewUrl } else { msg.concat(', ' + previewUrl) }

      this.setState({ loadingImagesError: msg })
    }
    photo.src = previewUrl
    this.imgRefs.push(photo)
  }



  canvasGrid() {
    let biggest = this.canvasWidth > this.canvasHeight ? this.canvasWidth : this.canvasHeight;

    for (var i = 0; i < (biggest / this.grid); i++) {
      let h = new fabric.Line([i * this.grid, 0, i * this.grid, this.canvasHeight], {
        stroke: '#ccc',
        selectable: false
      });
      let v = new fabric.Line([0, i * this.grid, this.canvasWidth, i * this.grid], {
        stroke: '#ccc',
        selectable: false
      });
      this.canvas.add(h);
      this.canvas.add(v);
      this.canvas.sendToBack(h);
      this.canvas.sendToBack(v);
    }
  }


  removeGrid() {
    var lines = this.canvas.getObjects('line');
    for (let i in lines) {
      this.canvas.remove(lines[i]);
    }
  }

  addGrid() {
    var lines = this.canvas.getObjects('line');
    if (this.props.grid !== undefined && lines.length === 0) {
      // this.canvasGrid();
      this.canvasScaleGridHandler(this.props.grid);
      this.canvasGridHandler(this.props.grid);
    }
  }


  handleGetJsonTemplate() {
    // this.removeGrid();
    let r = this.getJsonTemplate();
    // console.log(r);
    return r;
  }

  getJsonTemplate() {
    // this.removeGrid();
    var images = this.canvas.getObjects('image');
    for (let i in images) {
      let bounds = images[i].getBoundingRect();
      images[i].boundLeft = bounds.left;
      images[i].boundTop = bounds.top;
      images[i].boundWidth = bounds.width;
      images[i].boundHeight = bounds.height;
    }
    // console.log(images);
    let r = this.canvas.toJSON(this.customProperties);
    this.addGrid();
    return r;
  }

  parseTemplateObjects(t) {
    return typeof (t) === 'string' ? JSON.parse(this.state.template.jsonTemplate).objects : this.state.template.jsonTemplate.objects
  }

  getImage64Template() {
    // this.removeGrid();
    let r = this.canvas.toDataURL({
      format: 'png',
      quality: 0.3
    });
    this.addGrid();
    return r;
  }


  canvasGridHandler(grid) {
    this.canvas.on('object:moving', function (options) {
      options.target.set({
        left: Math.round(options.target.left / grid) * grid,
        top: Math.round(options.target.top / grid) * grid
      });
    });
  }
  addBlockOutboundHandler() {
    this.canvas.on('object:moving', function (options) {
      let objL = options.target.left;
      let objW = options.target.width * options.target.scaleX;
      let canvasW = options.target.canvas.width;
      let objSumLW = objL + objW;
      let difL = canvasW - objW;


      let objT = options.target.top;
      let objH = options.target.height * options.target.scaleY;
      let canvasH = options.target.canvas.height;
      let objSumTH = objT + objH;
      let difT = canvasH - objH;

      options.target.set({
        left:
          options.target.left < 0 ? 0
            : objSumLW >= canvasW ? difL
              : objL,
        top: options.target.top < 0 ? 0
          : objSumTH >= canvasH ? difT
            : options.target.top
      });
    });
  }


  removeBlockOutbound() {
    this.canvas.off('object:moving', () => alert('bye'))
  }

  canvasScaleGridHandler(grid) {
    // let grid = 50;
    this.canvas.on('object:scaling', options => {
      var target = options.target,
        w = target.width * target.scaleX,
        h = target.height * target.scaleY,
        snap = { // Closest snapping points
          top: Math.round(target.top / grid) * grid,
          left: Math.round(target.left / grid) * grid,
          bottom: Math.round((target.top + h) / grid) * grid,
          right: Math.round((target.left + w) / grid) * grid
        },
        threshold = grid,
        dist = { // Distance from snapping points
          top: Math.abs(snap.top - target.top),
          left: Math.abs(snap.left - target.left),
          bottom: Math.abs(snap.bottom - target.top - h),
          right: Math.abs(snap.right - target.left - w)
        },
        attrs = {
          scaleX: target.scaleX,
          scaleY: target.scaleY,
          top: target.top,
          left: target.left
        };
      if (target.flipX == true || target.flipY == true) {
        target.flipX = false;
        target.flipY = false
      }
      switch (target.__corner) {
        case 'tl':
          if (dist.left < dist.top && dist.left < threshold) {
            attrs.scaleX = (w - (snap.left - target.left)) / target.width;
            attrs.scaleY = (attrs.scaleX / target.scaleX) * target.scaleY;
            attrs.top = target.top + (h - target.height * attrs.scaleY);
            attrs.left = snap.left;
          } else if (dist.top < threshold) {
            attrs.scaleY = (h - (snap.top - target.top)) / target.height;
            attrs.scaleX = (attrs.scaleY / target.scaleY) * target.scaleX;
            attrs.left = attrs.left + (w - target.width * attrs.scaleX);
            attrs.top = snap.top;
          }
          break;
        case 'mt':
          if (dist.top < threshold) {
            attrs.scaleY = (h - (snap.top - target.top)) / target.height;
            attrs.top = snap.top;
          }
          break;
        case 'tr':
          if (dist.right < dist.top && dist.right < threshold) {
            attrs.scaleX = (snap.right - target.left) / target.width;
            attrs.scaleY = (attrs.scaleX / target.scaleX) * target.scaleY;
            attrs.top = target.top + (h - target.height * attrs.scaleY);
          } else if (dist.top < threshold) {
            attrs.scaleY = (h - (snap.top - target.top)) / target.height;
            attrs.scaleX = (attrs.scaleY / target.scaleY) * target.scaleX;
            attrs.top = snap.top;
          }
          break;
        case 'ml':
          if (dist.left < threshold) {
            attrs.scaleX = (w - (snap.left - target.left)) / target.width;
            attrs.left = snap.left;
          }
          break;
        case 'mr':
          if (dist.right < threshold) attrs.scaleX = (snap.right - target.left) / target.width;
          break;
        case 'bl':
          if (dist.left < dist.bottom && dist.left < threshold) {
            attrs.scaleX = (w - (snap.left - target.left)) / target.width;
            attrs.scaleY = (attrs.scaleX / target.scaleX) * target.scaleY;
            attrs.left = snap.left;
          } else if (dist.bottom < threshold) {
            attrs.scaleY = (snap.bottom - target.top) / target.height;
            attrs.scaleX = (attrs.scaleY / target.scaleY) * target.scaleX;
            attrs.left = attrs.left + (w - target.width * attrs.scaleX);
          }
          break;
        case 'mb':
          if (dist.bottom < threshold) attrs.scaleY = (snap.bottom - target.top) / target.height;
          break;
        case 'br':
          if (dist.right < dist.bottom && dist.right < threshold) {
            attrs.scaleX = (snap.right - target.left) / target.width;
            attrs.scaleY = (attrs.scaleX / target.scaleX) * target.scaleY;
          } else if (dist.bottom < threshold) {
            attrs.scaleY = (snap.bottom - target.top) / target.height;
            attrs.scaleX = (attrs.scaleY / target.scaleY) * target.scaleX;
          }
          break;
        default: break;
      }
      target.set(attrs);
    });
  }
  takeSizeMutationsHandler() {
    this.canvas.on('object:scaling', this.takeSizeMutations);
  }


  takeSizeMutations() {
    var images = this.canvas.getObjects('image');
    // var bigger = images.find()
    let biggest = images.sort((a, b) => b.dimensionsWidth - a.dimensionsWidth)[0];

    var images2 = images.map(x => {
      let z = {
        rotate: x.rotate,
        scaleX: 1,//x.scaleX,
        scaleY: 1,//x.scaleY,
        width: x.width * x.scaleX,//x.width,
        height: x.height * x.scaleY,
        dimensionsWidth: x.dimensionsWidth,
        dimensionsHeight: x.dimensionsHeight,
        motiveId: x.motiveId,
        motivType: x.motivType,
        biggestWidth: biggest.width * biggest.scaleX,
        biggestHeight: biggest.height * biggest.scaleY,
        biggestDimensionsWidth: biggest.dimensionsWidth,
        biggestDimensionsHeight: biggest.dimensionsHeight,
      }
      if (z.dimensionsWidth != null) {
        z.proportionDimensionsWidth = z.dimensionsWidth / z.biggestDimensionsWidth;
        z.proportionDimensionsHeight = z.dimensionsHeight / z.biggestDimensionsHeight;
        z.proportionalToBiggestWidth = z.proportionDimensionsWidth * z.biggestWidth;
        z.proportionalWidth = z.width / z.proportionalToBiggestWidth;

      }
      return z;
    });
    this.props.takeSizeMutations(images2);
  }

  disableSelectionSideGrowingHandler() {
    this.canvas.on('selection:created', (event) => {
      // console.log(event)
      event.target.lockUniScaling = true;
      event.target.lockRotation = true;

      event.target.set({
        transparentCorners: false,
        borderColor: '#ff00ff',
        cornerColor: '#ff0000'
      });
    });
  }
}
