import {
  endpoints,
  apiGetParamsReplace
} from "../../../constants/services.constants";
import { fetchGetWT, fetchPostWT, fetchDeleteWT, fetchPatchWT, fetchPutWT } from "../../../helpers/HttpHelper";

// OPEN CREATE FORM
export const CREATE_OPEN_BEGIN = "USER_CREATE_OPEN_BEGIN";
export const CREATE_CLOSE_BEGIN = "USER_CREATE_CLOSE_BEGIN";

export const createDialogOpenBegin = () => ({
  type: CREATE_OPEN_BEGIN
});

export const createDialogCloseBegin = () => ({
  type: CREATE_CLOSE_BEGIN
});

export function createDialogOpen() {
  return dispatch => {
    dispatch(createDialogOpenBegin());
  };
}
export function createDialogClose() {
  return dispatch => {
    dispatch(createDialogCloseBegin());
  };
}

// GET ALL
export const FETCH_USERS_BEGIN = "USER_FETCH_USERS_BEGIN";
export const FETCH_USERS_SUCCESS = "USER_FETCH_USERS_SUCCESS";
export const FETCH_USERS_FAILURE = "USER_FETCH_USERS_FAILURE";

export const fetchUsersBegin = () => ({
  type: FETCH_USERS_BEGIN
});

export const fetchUsersSuccess = users => ({
  type: FETCH_USERS_SUCCESS,
  payload: { users }
});

export const fetchUsersFailure = error => ({
  type: FETCH_USERS_FAILURE,
  payload: { error }
});

export function fetchUsers(callback) {
  const endpoint = endpoints.USER_ROOT;
  return dispatch => {
    dispatch(fetchUsersBegin());
    return fetchGetWT(
      endpoint,
      json => {
        dispatch(fetchUsersSuccess(json));
        if (callback != null) callback();
      },
      error => dispatch(fetchUsersFailure(error))
    );
  };
}

// GET BY ID
export const GET_USER_BEGIN = "USER_GET_USER_BEGIN";
export const GET_USER_SUCCESS = "USER_GET_USER_SUCCESS";
export const GET_USER_FAILURE = "USER_GET_USER_FAILURE";

export const getUserBegin = () => ({
  type: GET_USER_BEGIN
});

export const getUserSuccess = user => ({
  type: GET_USER_SUCCESS,
  payload: { user }
});

export const getUserFailure = error => ({
  type: GET_USER_FAILURE,
  payload: { error }
});

export function getUser(userId, callback) {
  const endpoint = apiGetParamsReplace(endpoints.USER_SINGLE, [userId]);
  return dispatch => {
    dispatch(getUserBegin());
    return fetchGetWT(
      endpoint,
      json => {
        dispatch(getUserSuccess(json));
        if (callback !== undefined) callback(json);
      },
      error => dispatch(getUserFailure(error))
    );
  };
}

// GET CUSTOMER ROLES BY USER ID
export const GET_USER_CUSTOMER_ROLE_BEGIN = "USER_CUSTOMER_ROLE_BEGIN";
export const GET_USER_CUSTOMER_ROLE_SUCCESS = "USER_CUSTOMER_ROLE_SUCCESS";
export const GET_USER_CUSTOMER_ROLE_FAILURE = "USER_CUSTOMER_ROLE_FAILURE";

export const applyCustomerRoleBegin = () => ({
  type: GET_USER_CUSTOMER_ROLE_BEGIN
});

export const applyCustomerRoleSuccess = user => ({
  type: GET_USER_CUSTOMER_ROLE_SUCCESS,
  payload: { user }
});

export const applyCustomerRoleFailure = error => ({
  type: GET_USER_CUSTOMER_ROLE_FAILURE,
  payload: { error }
});


export function applyCustomerRole(userId, customerId, roleId, callback) {
  const endpoint = apiGetParamsReplace(endpoints.USER_CUSTOMER_ROLE, userId, customerId, roleId);
  return dispatch => {
    dispatch(applyCustomerRoleBegin());
    return fetchPatchWT(
      endpoint,
      null,
      json => {
        dispatch(applyCustomerRoleSuccess(json));
        if (callback !== undefined)
        { 
          callback(json);
        }
      }
    );
  };
}


// GET ROLES
export const GET_USER_ROLES_BEGIN = "USER_ROLES_BEGIN";
export const GET_USER_ROLES_SUCCESS = "USER_ROLES_SUCCESS";
export const GET_USER_ROLES_FAILURE = "USER_ROLES_FAILURE";

export const getUserRolesBegin = () => ({
  type: GET_USER_ROLES_BEGIN
});

export const getUserRolesSuccess = user => ({
  type: GET_USER_ROLES_SUCCESS,
  payload: { user }
});

export const getUserRolesFailure = error => ({
  type: GET_USER_ROLES_FAILURE,
  payload: { error }
});


export function getUserRoles(callback) {
  const endpoint = endpoints.USER_ROLES;
  return dispatch => {
    dispatch(getUserRolesBegin());
    return fetchGetWT(
      endpoint,
      json => {
        dispatch(getUserRolesSuccess(json));
        if (callback !== undefined) callback(json);
      },
      error => dispatch(getUserRolesFailure(error))
    );
  };
}


// SEARCH
export const SEARCH_USERS_BEGIN = "USER_SEARCH_USERS_BEGIN";
export const SEARCH_USERS_SUCCESS = "USER_SEARCH_USERS_SUCCESS";
export const SEARCH_USERS_FAILURE = "USER_SEARCH_USERS_FAILURE";

export const searchUsersBegin = () => ({
  type: SEARCH_USERS_BEGIN
});

export const searchUsersSuccess = (queryString, users) => ({
  type: SEARCH_USERS_SUCCESS,
  payload: { queryString, users }
});

export const searchUsersFailure = error => ({
  type: SEARCH_USERS_FAILURE,
  payload: { error }
});

export function searchUsers(queryString) {
  return dispatch => {
    const endpoint = apiGetParamsReplace(
      endpoints.USERS_SEARCH,
      ...[queryString]
    );
    dispatch(searchUsersBegin());
    return fetchGetWT(
      endpoint,
      json => {
        dispatch(searchUsersSuccess(queryString, json));
      },
      error => {
        dispatch(searchUsersFailure(error));
      }
    );
  };
}

// CLEAN EVERYTHING
export const CLEAN_USERS = "USER_CLEAN_USERS";

export const cleanUsersSuccess = () => ({
  type: CLEAN_USERS
});

export function cleanUsers() {
  return dispatch => dispatch(cleanUsersSuccess());
}



// Add customer permits
export const ADD_CUSTOMER_PERMITS_BEGIN = "USER_ADD_CUSTOMER_PERMITS_BEGIN";
export const ADD_CUSTOMER_PERMITS_SUCCESS = "USER_ADD_CUSTOMER_PERMITS_SUCCESS";
export const ADD_CUSTOMER_PERMITS_FAILURE = "USER_ADD_CUSTOMER_PERMITS_FAILURE";

export const addCustomerPermitsBegin = () => ({
  type: ADD_CUSTOMER_PERMITS_BEGIN
});

export const addCustomerPermitsSuccess = user => ({
  type: ADD_CUSTOMER_PERMITS_SUCCESS,
  payload: { user }
});

export const addCustomerPermitsFailure = error => ({
  type: ADD_CUSTOMER_PERMITS_FAILURE,
  payload: { error }
});

export function addCustomerPermits(userId, customerId, callback) {
  const endpoint = endpoints.USER_ADD_CUSTOMER_PERMITS;
  const json =
  {
    "multiUserId": userId,
    "multiCustomerId": customerId
  }
    ;

  return dispatch => {
    dispatch(addCustomerPermitsBegin());
    return fetchPostWT(
      endpoint,
      JSON.stringify(json),
      json => {
        dispatch(addCustomerPermitsSuccess(json));
        callback();
      },
      error => dispatch(addCustomerPermitsFailure(error))
    );
  };
}



// remove customer permits
export const REMOVE_CUSTOMER_PERMITS_BEGIN = "USER_REMOVE_CUSTOMER_PERMITS_BEGIN";
export const REMOVE_CUSTOMER_PERMITS_SUCCESS = "USER_REMOVE_CUSTOMER_PERMITS_SUCCESS";
export const REMOVE_CUSTOMER_PERMITS_FAILURE = "USER_REMOVE_CUSTOMER_PERMITS_FAILURE";

export const removeCustomerPermitsBegin = () => ({
  type: REMOVE_CUSTOMER_PERMITS_BEGIN
});

export const removeCustomerPermitsSuccess = user => ({
  type: REMOVE_CUSTOMER_PERMITS_SUCCESS,
  payload: { user }
});

export const removeCustomerPermitsFailure = error => ({
  type: REMOVE_CUSTOMER_PERMITS_FAILURE,
  payload: { error }
});

export function removeCustomerPermits(userId, customerId) {
  const endpoint = endpoints.USER_REMOVE_CUSTOMER_PERMITS;
  const json =
  {
    "multiUserId": userId,
    "multiCustomerId": customerId
  }
    ;

  return dispatch => {
    dispatch(removeCustomerPermitsBegin());
    return fetchDeleteWT(
      endpoint,
      JSON.stringify(json),
      json => dispatch(removeCustomerPermitsSuccess(json)),
      error => dispatch(removeCustomerPermitsFailure(error))
    );
  };
}






// Add customer permits
export const ADD_PERMITS_BEGIN = "USER_ADD_PERMITS_BEGIN";
export const ADD_PERMITS_SUCCESS = "USER_ADD_PERMITS_SUCCESS";
export const ADD_PERMITS_FAILURE = "USER_ADD_PERMITS_FAILURE";

export const addPermitsBegin = () => ({
  type: ADD_PERMITS_BEGIN
});

export const addPermitsSuccess = user => ({
  type: ADD_PERMITS_SUCCESS,
  payload: { user }
});

export const addPermitsFailure = error => ({
  type: ADD_PERMITS_FAILURE,
  payload: { error }
});

export function addPermits(userId, customerId, permitsId, permit, callback, callbackError) {
  const endpoint = endpoints.USER_ADD_PERMITS;
  const json =
  {
    "multiUserId": userId,
    "multiCustomerId": customerId,
    "permitsId": permitsId,
    "permit": permit
  }
    ;

  return dispatch => {
    dispatch(addPermitsBegin());
    return fetchPatchWT(
      endpoint,
      JSON.stringify(json),
      json => {
        dispatch(addPermitsSuccess(json));
        callback();
      },
      error => {
        dispatch(addPermitsFailure(error));
        callbackError();

      });
  };
}



// remove customer permits
export const REMOVE_PERMITS_BEGIN = "USER_REMOVE_PERMITS_BEGIN";
export const REMOVE_PERMITS_SUCCESS = "USER_REMOVE_PERMITS_SUCCESS";
export const REMOVE_PERMITS_FAILURE = "USER_REMOVE_PERMITS_FAILURE";

export const removePermitsBegin = () => ({
  type: REMOVE_PERMITS_BEGIN
});

export const removePermitsSuccess = user => ({
  type: REMOVE_PERMITS_SUCCESS,
  payload: { user }
});

export const removePermitsFailure = error => ({
  type: REMOVE_PERMITS_FAILURE,
  payload: { error }
});

export function removePermits(userId, customerId, permitsId, permit, callback, callbackError) {
  const endpoint = endpoints.USER_REMOVE_PERMITS;
  const json =
  {
    "multiUserId": userId,
    "multiCustomerId": customerId,
    "permitsId": permitsId,
    "permit": permit
  }
    ;

  return dispatch => {
    dispatch(removePermitsBegin());
    return fetchDeleteWT(
      endpoint,
      JSON.stringify(json),
      json => {
        dispatch(removePermitsSuccess(json));
        callback();
      },
      error => {
        dispatch(removePermitsFailure(error));
        callbackError();
      }
    );
  };

}