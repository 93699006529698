import React from 'react';
import { Typography } from '@material-ui/core';

import CircularProgress from '@material-ui/core/CircularProgress';
import LinearProgress from '@material-ui/core/LinearProgress';

export const LoadingLinear = (props) => {
    return <div>
        <LinearProgress />
    </div>;
}

export const LoadingCircular = (props) => {
    return <div>
        <Typography variant="caption" align="center" ><CircularProgress size={60} /></Typography>
    </div>;
}
export const LoadingLogo = (props) => {
    return <div>
        <Typography variant="caption" align={props.align == null ? 'center' : props.align} style={{ display: "block" }}><img src="/images/mb2-logo2.svg" className="loadingRotation" alt="logo" style={props.style} /></Typography>
    </div>;

}