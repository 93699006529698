import React, { Component } from "react";
import {
  CardMedia,
  CardContent,
  Card,
  Divider,
  Button,
  CardActions,
  IconButton,
  Tooltip,
} from "@material-ui/core";
import DeleteIcon from "@material-ui/icons/Delete";
import LinkOffIcon from "@material-ui/icons/LinkOff";
import {
  apiGetParamsReplace,
  endpoints,
} from "../../../../constants/services.constants";
import { getMotivId, getShortDate } from "../../../../helpers/UtilHelper";
import { getPhoto } from "../../photos/Requests";
import { photoTypeEnum } from "../../../../constants/enums";
import { orange } from "@material-ui/core/colors";
import { openDialogUnlinkPhoto } from "../dialogs/DialogUnlinkPhoto";
import { photoStatusEnum } from "../../../../constants/enums";
import { IsFeatureEnabled, featureNames } from "../../../../helpers/FeaturesHelper";
export default class CardPictureItem extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: false,
      photo: null,
    };
  }

  componentDidMount() {
    if (this.props.photo) {
      this.loadData();
    }
  }

  async loadData() {
    this.setState({ loading: true });

    try {
      const p = await getPhoto(this.props.photo.multiPhotoId);
      this.setState({
        photo: p,
        loading: false,
      });

    } catch (error) {
      this.setState({ error, loading: false });
    }
  }

  isExclusive (multiPhotos, multiProductPhotos) {  
    const match = multiPhotos.find(photo => photo.id === multiProductPhotos.multiPhotoId);
    return match && match.exclusiveForCustomerIds.length > 0;
  }
  async handleUnlinkPhotoClick(photoId) {
    const confirmed = await openDialogUnlinkPhoto(photoId, this.props.multiProductPhotos.multiProductId);
    if (confirmed) {
      this.props.unlinkPhoto(photoId);
    }
  }

  render() {
    const {
      handleSetMaster,
      photo,
      loadingSetmaster,
      multiProductPhotos,
      deletePhoto,
      unlinkPhoto,
      multiPhotos
    } = this.props;

    return (
      <Card
        style={{
          maxWidth: "200px",
          marginTop: "10px",
          position: "relative",
          overflow: "visible",
        }}
      >
        {multiProductPhotos.isDefaultImage ? undefined : (
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              position: "absolute",
              right: 3,
              top: 3,
            }}
          >
            {IsFeatureEnabled(featureNames.Products_Edit_UnLink_Photo_Enabled) ?
              <Tooltip title="Unlink foto fra produkt">
                <IconButton
                  color="secondary"
                  size="small"
                  onClick={() => this.handleUnlinkPhotoClick(photo.multiPhotoId)}
                >
                  <LinkOffIcon />
                </IconButton>
              </Tooltip>:undefined
            }
            {IsFeatureEnabled(featureNames.Products_Delete_Enabled) ?
              <Tooltip title="Slet foto permanent!">
                <IconButton
                  color="secondary"
                  size="small"
                  onClick={() => deletePhoto(photo.multiPhotoId)}
                  id="buttonProductEditPhotoDelete"
                >
                  <DeleteIcon />
                </IconButton>
              </Tooltip> : undefined
            }
          </div>
        )}
        <CardMedia
          style={{
            height: "120px",
            backgroundSize: "contain",
            marginTop: "20px",
          }}
          image={apiGetParamsReplace(endpoints.PHOTO_SINGLE_200, photo.multiPhotoId)}
          title=""
        />
        <CardContent style={{ height: "80px" }}>
          <table className={"textCardTable"}>
            <tbody>
              <tr>
                <th>Foto type:</th>
                <td>
                  {this.state.loading ||
                  !this.state.photo ||
                  !this.state.photo.type
                    ? undefined
                    : photoTypeEnum[this.state.photo.type].name}
                </td>
              </tr>
              <tr>
                <th>Motiv-Id:</th>
                <Tooltip title={"Oprettet: "+getShortDate(photo.created)}><td>{getMotivId(photo.multiPhotoId)}</td></Tooltip>
              </tr>
            </tbody>
          </table>

          {this.state.photo && this.state.photo.validTo != null && this.state.photo.status === photoStatusEnum.Active.key ? (
            <div style={{ color: orange[500] }}>
              Slettes: {getShortDate(this.state.photo.validTo)}
            </div>
          ) : null}
        </CardContent>
        <Divider />
        <CardActions
          style={{
            display: "inherit",
            textAlign: "center",
            paddingTop: "0px",
            paddingBottom: "0px",
            marginBottom: 5,
            marginTop: 5,
          }}
        >
          {(loadingSetmaster && !multiProductPhotos.isDefaultImage) ||
          (loadingSetmaster == null && !multiProductPhotos.isDefaultImage) ? (
            <Button disabled color="primary" name="buttonProductPhotoIsActive">
              Gør aktiv
            </Button>
          ) : multiProductPhotos && multiProductPhotos.isDefaultImage ? (
            <Button
              disabled
              color="primary"
              style={{
                backgroundColor: "green",
                color: "white",
              }}
            >
              Aktiv
            </Button>
          ) : this.isExclusive(multiPhotos, multiProductPhotos) ? 
            (<Button disabled>LÅST AF KUNDE</Button>) : 
            (<Button name="buttonProductPhotoIsActive" onClick={handleSetMaster}>Gør aktiv</Button>)}
        </CardActions>
      </Card>
    );
  }
}
