import {
  endpoints,
  apiGetParamsReplace
} from "../../../constants/services.constants";
import { fetchGetWT, fetchPutWT, fetchPostWT, fetchDeleteWT, fetchPostWTNoBodyResponse, fetchDeleteWTNoBodyResponse } from "../../../helpers/HttpHelper";

export const CLEAN_SEARCH_PHOTOS = "PHOTO_CLEAN_SEARCH_PHOTOS";


export const cleanSearchPhotosSuccess = () => ({
  type: CLEAN_SEARCH_PHOTOS
});

// OPEN Delete FORM
export const DELETE_OPEN_BEGIN = "PHOTO_DELETE_OPEN_BEGIN";
export const DELETE_CLOSE_BEGIN = "PHOTO_DELETE_CLOSE_BEGIN";

export const deleteDialogOpenBegin = photoIds => ({
  type: DELETE_OPEN_BEGIN,
  payload: { photoIds }
});

export const deleteDialogCloseBegin = () => ({
  type: DELETE_CLOSE_BEGIN
});

export function deleteDialogOpen(photoIds) {
  return dispatch => {
    dispatch(deleteDialogOpenBegin(photoIds));
  };
}
export function deleteDialogClose() {
  return dispatch => {
    dispatch(deleteDialogCloseBegin());
  };
}


// DELETE PHOTOS
export const DELETE_PHOTOS_BEGIN = "PHOTOS_DELETE_BEGIN";
export const DELETE_PHOTOS_BEGIN_SUCCESS = "PHOTOS_DELETE_SUCCESS";
export const DELETE_PHOTOS_BEGIN_FAILURE = "PHOTOS_DELETE_FAILURE";

export const deletePhotosBegin = () => ({
  type: DELETE_PHOTOS_BEGIN
});

export const deletePhotosSuccess = photoIds => ({
  type: DELETE_PHOTOS_BEGIN_SUCCESS,
  payload: { photoIds }

});

export const deletePhotosFailure = error => ({
  type: DELETE_PHOTOS_BEGIN_FAILURE,
  payload: { error }
});

export function deletePhotos(deleteDto, callback) {
  const endpoint = apiGetParamsReplace(
    endpoints.PHOTOS_DELETE
  );

  return dispatch => {
    dispatch(deletePhotosBegin());
    return fetchDeleteWTNoBodyResponse(
      endpoint,
      JSON.stringify(deleteDto),
      () => {
        dispatch(deletePhotosSuccess(deleteDto.photoIds));
        callback();
      },
      error => dispatch(deletePhotosFailure(error))
    );
  };
}