import React from 'react';
import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles({

    iframe: {
        boxSizing: "border-box",
        display: "inline-block",
        transformOrigin: "0 0",
        zIndex: -1,
    },
});

export default function PosterContainer({
    iframeRef, url }) {
    const classes = useStyles();

    return (
        <iframe
            // ref={iframeRef}
            title="filmmakerIframe"
            width={1080}
            height={1920}
            style={{
                WebkitTransform: `scale(0.31)`,
                MozTranform: `scale(0.31)`,
                marginLeft: 50
            }}
            className={classes.iframe}
            src={url}
        ></iframe>
    )
}