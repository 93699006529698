import React from "react";
import { connect } from "react-redux";
import Typography from "@material-ui/core/Typography";
import { LoadingLinear } from "../../shared/Loading";
import { getUser, cleanUsers } from "../../../redux/actions/users/users.actions";
import PermitControls from "./PermitControls";
import { checkCustomerState } from "../../../helpers/UtilsReducerHelper";
import { fetchCustomers } from "../../../redux/actions/utils/customers.utils.actions";
import { Grid } from "@material-ui/core";
import GoBackButton from "../../shared/components/GoBackButton";
import history from "../../../routes/history";

class DialogPermits extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
        };
    }

    componentDidMount() {
        this.props.dispatch(fetchCustomers());
        this.props.dispatch(getUser(this.props.match.params.selectedUserId));
    }

    componentWillUnmount(){
        this.props.dispatch(cleanUsers());
    }

    handleGoBack = () => {
        history.push("/brugers");
    }
    render() {
        let preloadState = checkCustomerState(this.props.utilsReducer);
        if (preloadState === "Loading.") return <LoadingLinear />;
        else if (preloadState != null)
          return (
            <Typography variant="subtitle1" align="center">
              {preloadState}
            </Typography>
          );
          
        let user = this.props.usersReducer.user;
        if (this.props.usersReducer.errorUser != null) return (
            <Typography component={"span"}>
                {this.props.usersReducer.errorUser}
            </Typography>);
        else if (this.props.usersReducer.loadingUser 
            //  || this.props.usersReducer.loadingAddCustomerPermits
        ) return (
            <>
                {/* <Typography variant="caption">Loading user data...</Typography> */}
                <LoadingLinear />
            </>
        );
        else if (user == null && this.props.usersReducer.loadingUser!== true) return <Typography variant="caption">Noget gik galt..</Typography>

        return (
            <Grid container>
                <Grid item xs={1}>
                    <GoBackButton
                        onClick={() => this.handleGoBack()}

                    />
                </Grid>
                <Grid item xs={11}>

                <Typography variant="h4">Vælg rettigheder: {user.userName}</Typography>
                <PermitControls user={this.props.usersReducer.user}
                    customers={this.props.utilsReducer.customers}
                    // addCustomerPermits={(u, c,b) => this.props.dispatch(addCustomerPermits(u, c,b))}
                    // removeCustomerPermits={(u, c) => this.props.dispatch(removeCustomerPermits(u, c))}
                    // loadingAddCustomerPermits={this.props.usersReducer.loadingAddCustomerPermits}
                    // loadingRemoveCustomerPermits={this.props.usersReducer.loadingRemoveCustomerPermits}
                />
                </Grid>
            </Grid>

        );
    }
}

export default connect(state => state)(DialogPermits);
