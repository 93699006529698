export const SET_MESSAGE = 'SET_MESSAGE';
export const setMessage = (message) => {
  return dispatch => {
    dispatch({
       type: SET_MESSAGE,
       payload: {
        message
       } 
    })
  }
}