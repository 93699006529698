export const handleChangeState = (state, setState, event) => {
    switch (event.target.name) {
        case 'offerDefinitionId':
            setState({ ...state, offerDefinitionId: event.target.value });
            break;
        case 'description':
            setState({ ...state, description: event.target.value });
            break;
        case 'tags':
            setState({ ...state, tags: event.target.value });
            break;
        case 'normalPrice':
            setState({ ...state, normalPrice: event.target.value });
            break;
        case 'offerPrice':
            setState({ ...state, offerPrice: event.target.value });
            break;
        case 'price':
            setState({ ...state, price: event.target.value });
            break;
        default:
            console.log(`You are not allowed to edit ${event.target.name} field.`);
    }
};

export const handleChangeOfferScores = (state, setState, target) => {
    if (!(target instanceof HTMLInputElement)) {
        return;
    }
    const { id, value } = target;
    const offerScores = state.offerRankings;

    if(id == "rest_ranking"){
        let parsedScore = parseInt(value, 10);
        const score = isNaN(parsedScore) ? value : parsedScore;
        offerScores.restScore = score;

        setState({...state, offerRankings: offerScores});
    }
}

export const handleDateChangeFrom = (state, setState, date) => {
    setState({ ...state, validFrom: date });
};

export const handleDateChangeTo = (state, setState, date) => {
    setState({ ...state, validTo: date });
};

export const handleUpdateInputTag = (state, setState, e) => {
    setState({ ...state, inputValueTag: e.target.value })
}

export const handleOfferFieldValueItemsChange = (state, setState, event) => {
    const { name, value } = event.target;
    
    const index = state.fieldValues.findIndex(x => x.name === name);
    let fieldValuesModified = [...state.fieldValues];
    fieldValuesModified[index].value = value;

    setState({
        ...state,
        fieldValues: fieldValuesModified
    });
};

export const handleClose = (store, props, editDialogClose) => {
    store.dispatch(editDialogClose());
};