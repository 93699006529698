import React, { useEffect } from "react";
import { useState } from "react";
import { subscribeToNotifications } from "../../../../helpers/notificationManager"

export const MainContext = React.createContext({
    notificationManager: undefined,
    messageManager: undefined
});

const Index = ({ children }) => {
    const [notificationManager, setNotificationManager] = useState(undefined);
    const [messageManager, setMessageManager] = useState(undefined);
    const [userManager, setUserManager] = useState(undefined);

    useEffect(() => {
        const runEffect = async () => {
            // Subscribe
            const [_notificationManager, _messageManager, _userManager] = await subscribeToNotifications();
            setNotificationManager(_notificationManager);
            setMessageManager(_messageManager);
            setUserManager(_userManager);
        };
        runEffect();
    }, []);


    return (
        <MainContext.Provider value={{
            notificationManager: notificationManager,
            messageManager: messageManager,
            userManager: userManager,
        }}>
            {children}
        </MainContext.Provider>
    )
}

export default Index;