import React, { Component } from "react";
import { Button, Tooltip, Card, CardActionArea, CardMedia, CardContent, Typography } from "@material-ui/core";
import { apiGetParamsReplace, endpoints } from "../../../constants/services.constants";
import { activePhotoFutureDate, getMotivId, getShortDate } from "../../../helpers/UtilHelper";
import { openDialogFullsizePhoto } from "../dialogs/DialogFullsizePhoto";
import { orange } from "@material-ui/core/colors";
import { allowUsingExclusivePhoto } from "../SharedFunctions";
import ExclusiveImageIdentification from "./ExclusiveImageIdentification";
import { photoStatusEnum } from "../../../constants/enums";

export default class TabRelatedSliderItem extends Component {

  render() {
    const { classes, handleAddOfferPicture, img, isLoading, existingOptions, customerId, selectedImage } = this.props;
    const futurePhotoDate = img.validFrom && activePhotoFutureDate(img.validFrom);

    const created = selectedImage === undefined ? new Date() : selectedImage.created;


    const buttonDisabled = isLoading === true
      || (existingOptions !== null && existingOptions.includes(img.photoId))
      || futurePhotoDate !== null ||
      !allowUsingExclusivePhoto(img.exclusiveForCustomerIds, customerId);

    return (
      <>
        {img.exclusiveForCustomerIds && img.exclusiveForCustomerIds.length > 0 ?
          <ExclusiveImageIdentification
            exclusiveForCustomerIdsArray={img.exclusiveForCustomerIds}
          />
          : undefined}
        <Card className={classes.card} style={{width: 180}} key={img.id}>
          <CardActionArea>
            <CardMedia
              component="img"
              className={classes.media}
              style={{
                width: "180px",
                height: "170px",
                backgroundS: "contain"
              }}
              id={img.id}
              image={apiGetParamsReplace(endpoints.PHOTO_SINGLE_200, [img.photoId])}
              onClick={() => openDialogFullsizePhoto(img.photoId)}
            />
          </CardActionArea>
          <CardContent style={{ height: "65px" }}>
            <Tooltip title={"Oprettet: "+getShortDate(created)}>
              <Typography
                variant="subtitle2"
                gutterBottom
                className="max3lines"
                style={{ fontSize: "0.8rem" }}
              >
                <strong>Motiv-Id:</strong> {getMotivId(img.photoId)}
              </Typography>
            </Tooltip>
            {img.deleteDate && img.status === photoStatusEnum.Active ?
                <div style={{ color: orange[500] }}>
                  Slettes: {getShortDate(img.deleteDate)}
                </div>
              : undefined
            }

            {futurePhotoDate !== null ?
              <div style={{ color: orange[500] }}>
                Gyldig fra {getShortDate(img.validFrom)}
              </div>
              : undefined
            }
          </CardContent>

          <Button
            disabled={buttonDisabled}
            color="primary"
            variant="outlined"
            onClick={() => handleAddOfferPicture(img.photoId)}
          >
            Tilføj
          </Button>
        </Card>
      </>
    );
  }
}

