import React from 'react';
import { Button } from '@material-ui/core';
import { Typography } from '@material-ui/core';
import { useState, useRef } from 'react';
import { useEffect } from 'react';
import {  fetchGetWT, fetchPostFormDataWT } from '../../../../helpers/HttpHelper';
import { MediaLibraryCarousel } from './MediaLibraryCarousel';
import { styles } from './shared';
import { endpoints, apiGetParamsReplace } from '../../../../constants/services.constants';
import { ActionButton } from './offerComponents/views/editOffer';
import { useStyles } from './Screen';
import SectionHeader from "./sharedComponents/SecrtionHeader";

export default function MediaLibrary({ 
    tilbage, 
    mediaSelection, 
    customer 
}) {
    const inputFile = useRef(null)
    const [assets, setAssets] = useState(null);
    const [file, setFile] = useState(null);
    const [loading, setLoading] = useState(true);
    const classes = useStyles();

    useEffect(() => {
        const formData = new FormData();
        formData.append("file", file);
        formData.append("customerId", customer);
        fetchPostFormDataWT(
            endpoints.FILMMAKER_ASSETS,
            formData,
            r => {
                getData();
                setFile(null);
            },
            e => {
                console.error(e);
                alert("Error uploading file.");
                setFile(null);
            });
        return () => {
        }
    }, [file]);

    const MINUTE_MS = 5000;
    useEffect(() => {
        getData();
        const interval = setInterval(() => {
            getData();
        }, MINUTE_MS);
        return () => {
            clearInterval(interval);

        }
    }, []);

    function getData() {
        const endpoint = apiGetParamsReplace(endpoints.FILMMAKER_ASSETS_BYSTATES, customer);
        setLoading(true);
        fetchGetWT(endpoint, (r) => {
            setAssets(r);
            setLoading(false);
        }, (e) => {
            setLoading(e);
        });
    };

    const onButtonClick = () => {
        inputFile.current.click();
    };

    function onChangeFile(event) {
        event.stopPropagation();
        event.preventDefault();
        var _file = event.target.files[0];
        setFile(_file);
    };

    return (
        <div>
            <SectionHeader
                 section={mediaSelection}
                 tilbage={tilbage}
                 defaultText="Tilføj medie"
                 primaryText="Mediebibliotek"
            />
            {                
                assets == null && loading === true ? <Typography align="center">Fetching...</Typography>
                    : typeof (loading) === "string" ? <Typography>error: {loading}</Typography>
                        : <MediaLibraryCarousel items={assets.imported} mediaSelection={mediaSelection} refresh={getData}  />
            }
            <div
                style={{ marginTop: 40 }}
            >
                <ActionButton
                    text="Upload medie"
                    onClick={() => onButtonClick()}
                    className={classes.buttonMain}
                    disabled={file != null}


                />
            </div>
            <input onChange={onChangeFile}  accept="video/mp4" type='file' id='file' ref={inputFile} style={{ display: 'none' }} />
            {assets == null ? undefined :
                <div style={{ marginTop: 20 }}>
                    {assets.importing == null ? undefined : assets.importing.map(h => <Typography key={h.id}>{h.name}: importing...</Typography>)}
                    {assets.pendings == null ? undefined : assets.pendings.map(h => <Typography key={h.id}>{h.name}: pending import</Typography>)}
                    {assets.errors == null ? undefined : assets.errors.map(h => <Typography key={h.id}>{h.name}: error</Typography>)}
                </div>}
        </div>
    )
}
