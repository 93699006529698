import { Button, Divider, Grid, IconButton, List, ListItem, ListItemText, TextField, Typography } from '@material-ui/core'
import React, { useEffect, useRef, useState } from 'react';

import DeleteIcon from "@material-ui/icons/Delete";


export default function StepMetadata(props) {
  const [fieldName, setFieldName] = useState("");
  const [fieldValue, setFieldValue] = useState("");
  const [metadata, setMetadata] = useState([]);

  const finalObjectRef = useRef(null);
  finalObjectRef.current = getFinalObj;


  function setStartObj(startObj) {
    setMetadata(startObj);

  };

  function getFinalObj() {
    return metadata
  };

  useEffect(() => {
    if (props.startObject != null) {
      setStartObj(props.startObject);
    } else if (props.information.gs1Metadata != null) {
      console.log(props.information.gs1Metadata)
      setStartObj(props.information.gs1Metadata)
    }
    return function () {
      if (props.returnFinalObject != null) {
        let finalObj = finalObjectRef.current();
        console.log("Disposing StepMetadata, returning data...");
        props.returnFinalObject(finalObj);
      }
    }
  }, []);

  useEffect(() => {
    evaluateStepReady();
  });

  function fieldNameValidation() {
    if (fieldName == null || fieldName.length == 0) return "Felt navn skal være udfyldt";
    if (fieldName.trim().length < 1) return "Felt navn skal være på minimum 1 tegn";
    return null;
  }
  function fieldValueValidation() {
    if (fieldValue == null || fieldValue.length == 0) return "Felt værdi skal være udfyldt";
    if (fieldValue.trim().length < 1) return "Felt værdi skal være på minimum 1 tegn";
    return null;
  }

  function evaluateStepReady() {
    let anyValidation =
      true
      ;
    props.setStepReady(anyValidation);
  }

  function addMetadata() {
    setMetadata([...metadata, { fieldName: fieldName, fieldValue: fieldValue }]);
    setFieldName("");
    setFieldValue("");
  }
  function deleteMetadata(item) {
    setMetadata(metadata.filter(x => x !== item));

  }

  return (
    <>

      <Grid container
        direction="row"
        justify="center"
        alignItems="flex-end"
        spacing={3}>
        <Grid item xs={4}>
          <TextField
            margin="normal"
            label="Felt navn"
            type="text"
            name="fieldName"
            id="textfieldAddProductAddMetaDataKey"
            value={fieldName}
            onChange={
              e => setFieldName(e.target.value)}
            // error={fieldNameError}
            fullWidth
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            margin="normal"
            label="Felt værdi"
            type="text"
            id="textfieldAddProductAddMetaDataValue"
            name="fieldValue"
            value={fieldValue}
            onChange={e => setFieldValue(e.target.value)}
            // error={fieldValueError}
            fullWidth

          />
        </Grid>
        <Grid item xs={2}>
          <Button onClick={addMetadata} color="primary"
            disabled={fieldValueValidation() != null || fieldNameValidation() != null}
            fullWidth
            variant="outlined"
          >
            Tilføj
      </Button>
        </Grid>
      </Grid>
      <br />



      {metadata.length == 0 ? undefined :
        <>
          <Typography variant="body1" style={{ paddingTop: "25px" }}>
            Yderligere felter:
          </Typography>
          <List>

            {metadata.map((x, i) =>
              <div key={i}>
                <ListItem button >
                  <ListItemText
                    primary={x.fieldName}
                    secondary={x.fieldValue}
                  />
                  <IconButton
                    aria-label="Delete"
                    onClick={() => deleteMetadata(x)}
                  >
                    <DeleteIcon />
                  </IconButton>
                </ListItem>
                <Divider light />
              </div>

            )

            }
          </List>
        </>
      }

    </>
  )
}
