import React, { useState } from 'react';
import {featureNames, getFeatureOrDefault} from "../../helpers/FeaturesHelper";
import Typography from '@material-ui/core/Typography';
import {Box} from '@material-ui/core';
import { appThemeConstants } from '../../constants/app.constants';
import { RepublicaMuiTheme } from '../../RepublicaMuiTheme';
import WarningIcon from '@material-ui/icons/Warning';

const HeaderCss = {
    fontFamily: RepublicaMuiTheme.defaultFont,
    fontSize:"0.875rem",
    fontWeight:"normal",
    
  }
const BoxCss = {
    backgroundColor: appThemeConstants.PRIMARY_3, 
    color: "rgba(255,255,255,0.8)"
  }
const WarningIconCss = {
  color:"yellow", 
  verticalAlign: "middle",
  padding:"4px"
}


function GetFeatureValue() {
  return getFeatureOrDefault(featureNames.System_Maintenance_Message,"");
}
function SystemMaintenanceHeader() {
  const [warningMessage, setWarningMessage] = useState(GetFeatureValue());

  return (<>
    {warningMessage.length > 1 ? 
    <Box style={BoxCss}>
      <Typography style={HeaderCss} align='center'>
       <WarningIcon style={WarningIconCss}/>
        {warningMessage}
      </Typography>
    </Box> : undefined}
  </>
  );
}

export default SystemMaintenanceHeader;


export function SystemMaintenancePaddingTop (){
  const NavTopPadding = 20;
  return GetFeatureValue().length > 1 ? NavTopPadding : 0;
}