import { TextField, MuiThemeProvider, Checkbox } from "@material-ui/core";
import React from 'react';
import { Typography, FormControl, InputLabel, Select, MenuItem, FormHelperText } from '@material-ui/core';
import { MuiPickersUtilsProvider, DatePicker } from "@material-ui/pickers";
import MomentUtils from "@date-io/moment";
import moment from "moment";
import { RepublicaDatePickerMuiTheme } from "../../../../../../RepublicaMuiTheme";


export function RenderValueField({ validPropertyType, valueError, filterProperty, handleSelection, handleNumberSelection, isEdit, handleDateSelection }) {
    switch (validPropertyType) {
        case "String":
            return <TextField
                error={!!valueError}
                helperText={valueError}
                fullWidth
                margin="dense"
                id="filterValue"
                label="Value"
                value={filterProperty.value}
                onBlur={(e) => handleSelection({ value: e.target.value, validPropertyType, final: true })}
                onChange={(e) => handleSelection({ value: e.target.value, validPropertyType })}
                type="text"
                InputLabelProps={{ shrink: true }}
            />

        case "Number":
            return <TextField
                error={!!valueError}
                helperText={valueError}
                fullWidth
                margin="dense"
                id="filterValue"
                label="Value"
                value={filterProperty.value}
                onBlur={(e) => handleNumberSelection({ value: e.target.value, validPropertyType, final: true })}
                onChange={(e) => handleNumberSelection({ value: e.target.value, validPropertyType })}
                type="text"
                InputLabelProps={{ shrink: true }}
            />
        case "Date":
            let dateValue;
            let isDate = filterProperty.value instanceof Date;
            dateValue = isEdit && filterProperty.value !== "" ? new Date(filterProperty.value) : (!isEdit && isDate) ? filterProperty.value : new Date();
            return <MuiPickersUtilsProvider utils={MomentUtils} moment={moment}>
                <MuiThemeProvider theme={RepublicaDatePickerMuiTheme}>
                    <DatePicker
                        clearable
                        margin="normal"
                        label="Value"
                        onChange={(date) => handleDateSelection(date)}
                        style={{ float: "left", width: "100%", marginTop: 5 }}
                        value={dateValue}
                        format="Do[.] MMM"
                    />
                </MuiThemeProvider>
            </MuiPickersUtilsProvider>
        case "Boolean":
            return <Checkbox
                checked={filterProperty.value}
                onChange={(e) => handleSelection({ value: e.target.checked, validPropertyType, final: true })}
                inputProps={{
                    name: "fritlaegges",
                    id: "fritlaegges-id"
                }}
            />
        default:
            return <TextField
                error={!!valueError}
                helperText={valueError}
                fullWidth
                margin="dense"
                id="filterValue"
                label="Value"
                value={filterProperty.value}
                onBlur={(e) => handleSelection({ value: e.target.value, validPropertyType, final: true })}
                onChange={(e) => handleSelection({ value: e.target.value, validPropertyType })}
                type="text"
                InputLabelProps={{ shrink: true }}
            />
    }

}

export function RenderValue2Field({ validPropertyType, value2Error, filterProperty, handleSelection, handleNumberSelection, isEdit, handleDateSelection }) {
    switch (validPropertyType) {
        case "String":
            return <TextField
                error={!!value2Error}
                helperText={value2Error}
                fullWidth
                margin="dense"
                id="filterValue"
                label="Value2"
                value={filterProperty.value2}
                onBlur={(e) => handleSelection({ value2: e.target.value, validPropertyType, final: true })}
                onChange={(e) => handleSelection({ value2: e.target.value, validPropertyType })}
                type="text"
                InputLabelProps={{ shrink: true }}
            />

        case "Number":
            return <TextField
                error={!!value2Error}
                helperText={value2Error}
                fullWidth
                margin="dense"
                id="filterValue"
                label="Value2"
                value={filterProperty.value2}
                onBlur={(e) => handleNumberSelection({ value2: e.target.value, validPropertyType, final: true })}
                onChange={(e) => handleNumberSelection({ value2: e.target.value, validPropertyType })}
                type="text"
                InputLabelProps={{ shrink: true }}

            />
        case "Date":
            let value2 = true;
            let dateValue;
            let isDate = filterProperty.value2 instanceof Date;
            dateValue = isEdit && filterProperty.value2 ? new Date(filterProperty.value2) : (!isEdit && isDate) ? filterProperty.value2 : undefined;
            return <MuiPickersUtilsProvider utils={MomentUtils} moment={moment}>
                <MuiThemeProvider theme={RepublicaDatePickerMuiTheme}>
                    <DatePicker
                        clearable
                        margin="normal"
                        label="Value2"
                        onChange={(date) => handleDateSelection(date, value2)}
                        style={{ float: "left", width: "100%", marginTop: 5 }}
                        value={dateValue}
                        format="Do[.] MMM"
                    />
                </MuiThemeProvider>
            </MuiPickersUtilsProvider>
        case "Boolean":
            return <Checkbox
                checked={filterProperty.value2}
                onChange={(e) => handleSelection({ value2: e.target.checked, validPropertyType, final: true })}
                inputProps={{
                    name: "fritlaegges",
                    id: "fritlaegges-id"
                }}
            />
        default:
            return <TextField
                error={!!value2Error}
                helperText={value2Error}
                fullWidth
                margin="dense"
                id="filterValue"
                label="Value"
                value={filterProperty.value2}
                onBlur={(e) => handleSelection({ value2: e.target.value, validPropertyType, final: true })}
                onChange={(e) => handleSelection({ value2: e.target.value, validPropertyType })}
                type="text"
                InputLabelProps={{ shrink: true }}
            />
    }
}