import { 
  Button, 
  FormControl, 
  TextField, 
  Typography,
  InputLabel,
  Select,
  MenuItem,
  FormHelperText } from '@material-ui/core';
import React, { useEffect, useRef, useState } from 'react';
import AutoSuggestion from '../../../../shared/AutoSuggestions';
import { validateEAN } from '../../../fototracker/SharedRequests';
import { getGs1Data } from '../../Requests';
import {mapGs1DataForLeverandorSelection} from "../../Helpers";
import {stringIsEmpty} from "../../../../../helpers/UtilHelper";
import AutoSuggestionWithChip from "../../../../shared/components/autoSuggestionComponent/AutoSuggestionWithChip";

import Resources from "../../../../../Resources.json"
import {GetResource} from "../../../../../helpers/ResourceHelper" 

export default function StepInformation(props) {
  const [tags, setTags] = useState([]);
  const [name, setName] = useState("");
  const [description, setDescription] = useState("");
  const [leverandor, setLeverandor] = useState("");
  const [productNumber, setProductNumber] = useState("");
  const [category, setCategory] = useState(null);
  const [productNumberExists, setProductNumberExists] = useState(null);
  const [gs1InformationProvider, setGs1InformationProvider] = useState("");
  const [gs1Metadata, setGs1Metadata] = useState(null);
  
  const [gs1Data, setGs1Data] = useState([]);
  const [gs1DataFetchState, setGs1DataFetchState] = useState("");
  const [gs1DataAutoSuggest, setGs1DataAutoSuggest] = useState([]);
  const [gs1Loading, setgs1Loading] = useState(false);

  const finalObjectRef = useRef(null);


  
  finalObjectRef.current = getFinalObj;
  
  function resetGs1Info() {
    setGs1Metadata(null);
    setGs1Data([]);
    setGs1DataAutoSuggest([]);
    setName("");
    setDescription("");
    setLeverandor("");
  }

  function setStartObj(startObj) {
    setTags(startObj.tags)
    setName(startObj.name);    
    setDescription(startObj.description);
    setLeverandor(startObj.leverandor);
    setProductNumber(startObj.productNumber);
    setProductNumberExists(startObj.productNumberExists);
    setCategory(startObj.category);
  };

  function getFinalObj() {
    return {
      name,
      description,
      productNumber,
      productNumberExists,
      leverandor,
      category,
      gs1Metadata,
      gs1InformationProvider,
      tags

    }
  };

  useEffect(() => {
    if (props.startObject != null) {
      setStartObj(props.startObject);
    }
    return function () {
      if (props.returnFinalObject != null) {
        let finalObj = finalObjectRef.current();
        props.returnFinalObject(finalObj);
      }
    }
  }, []);

  useEffect(() => {
    if (name && name.startsWith(" ")) setName(name.trim());
    if (description && description.startsWith(" ")) setDescription(description.trim());
    if ((productNumber && productNumber.startsWith(" ")) || productNumber.endsWith(" ")) setProductNumber(productNumber.trim());
    let productNumberFiletered = productNumber.replace(/\D/g, '');
    if (productNumberFiletered !== productNumber) setProductNumber(productNumberFiletered);
    evaluateStepReady();
  });

  useEffect(() => {
    setProductNumberExists(null)
    return () => {

    }
  }, [productNumber])

  function nameValidation() {
    if (name == null || name.length === 0) return "Produkt navn skal være udfyldt";
    if (name.trim().length < 5) return "Produkt navn skal være på minimum 5 tegn";
    return null;
  }
  function leverandorValidation() {
    if (stringIsEmpty(leverandor) && gs1Data.length < 2) return "Leverandør skal være udfyldt";
    if (stringIsEmpty(leverandor) && gs1Data.length > 1) return "Vælg leverandør";
    // if (leverandor.trim().length < 5) return "Leverandør skal på minimum 5 tegn";
    return null;
  }
  function productNumberValidation() {
    if (productNumber == null || productNumber.length === 0) return "Produktnummer skal være udfyldt";
    if (productNumber.length < 6) return "Produktnummer skal være på minimum 6 tegn";
    if (productNumber.length > 14) return "Produktnummer skal være på maximum 14 tegn";
    if (productNumberExists === true) return "Produkt med angivet EAN nummer eksisterer allerede";
    if (productNumberExists == null) return "...";
    if (productNumberExists !== false) return "Error: " + productNumberExists;
    return null;
  }
  async function productNumberExistenceValidation() {
    setProductNumberExists(null);
    if (productNumber == null || productNumber.length === 0 || productNumber.length < 6)
      return;

    const productNumberExist = await validateEAN(productNumber);
    setProductNumberExists(productNumberExist);
  }
  function categoryValidation() {
    if (category == null) return "Kategori skal være udfyldt";
    return null;
  }

  function evaluateStepReady() {
    let anyValidation =
      nameValidation() == null &&
      leverandorValidation() == null &&
      productNumberValidation() == null &&
      categoryValidation() == null;
    props.setStepReady(anyValidation);
  }

  async function handleGetGS1Information() {
    try {
      resetGs1Info();
      setGs1DataFetchState("");
      setgs1Loading(true);
      const gs1Data = await getGs1Data(productNumber);

      if (!gs1Data) {
        setgs1Loading(false);
        setGs1DataFetchState("Ingen info");
        return;
      }

      // single option
      if (gs1Data.length === 1) {
        pullDataFromGs1Selection(gs1Data[0]);
        setgs1Loading(false);
        return;
      }

      // Construct data for selection
      setGs1DataFetchState("Flere end én Leverandør blev fundet, vælg venligst én");
      const gs1DataMapped = mapGs1DataForLeverandorSelection(gs1Data);

      setGs1DataAutoSuggest(gs1DataMapped); 
      setGs1Data(gs1Data);
      setgs1Loading(false);
      } catch (e){
        setGs1DataFetchState("Error fetching GS1: " + e);
        console.error(e);
      }
  };

  function selectLeverandor(newSelection) {
    if (gs1Data.length === 0) {
      return;
    };
    
    const selectedItem = gs1DataAutoSuggest.find(x => x.name === newSelection);
    if (!selectedItem) {
      return;
    }
    let originalSelectedItem = {};
    const index = selectedItem.id;
    originalSelectedItem = gs1Data[index];
    pullDataFromGs1Selection(originalSelectedItem);
  }


  function pullDataFromGs1Selection(originalSelectedItem) {

      // get name
      let name = "";
      let description = "";
      if (originalSelectedItem.multiProductAdapter) {
        name = originalSelectedItem.multiProductAdapter.shortDescription ?? "";
        description = originalSelectedItem.multiProductAdapter.marketingMessage ?? "";

      }
      // get leverandor
      const delievery = originalSelectedItem.multiProductManufacturer[0].name;

      const informationProvider = originalSelectedItem.informationProviderOfTradeItem;
      // get meta data
      let metadata = null;
      try {
        if (originalSelectedItem.multiProductAdapter) {
          metadata = [];
          Object.keys(originalSelectedItem.multiProductAdapter).forEach(function (key) {
            let val = originalSelectedItem.multiProductAdapter[key];
            if (val != null && val != 0 && key != "created" && key != "lastUpdated" && val != "[]") {
              metadata = [
                ...metadata,
                { fieldName: key, fieldValue: typeof val==='string'?val: JSON.stringify( val) },
              ];
            }
          });
        }
      } catch (e){
        console.error(e);
      }

      populateStateWithGs1Info(name, description, metadata, delievery,originalSelectedItem, informationProvider);
  }

  function populateStateWithGs1Info(gs1Name, gs1Description, gs1Metadata, delievery,originalSelectedItem, informationProvider ) {
    const leverandor = delievery + " ("+originalSelectedItem.informationProviderOfTradeItem+")";
    setLeverandor(leverandor);
    setName(gs1Name);
    setDescription(gs1Description);
    setGs1InformationProvider(informationProvider);
    if (Array.isArray(gs1Metadata) && gs1Metadata.length > 0) {
      setGs1Metadata(gs1Metadata);
    }
  }

  return (
    <>
      <TextField
        margin="normal"
        label="Produkt GTIN (EAN)"
        type="text"
        name="productNumber"
        id='textFieldProductAddProductGTIN'
        helperText={
          productNumberValidation()
        }
        value={productNumber}
        onChange={e => setProductNumber(e.target.value)}
        onBlur={productNumberExistenceValidation}
        style={{ width: "85%" }}
        //fullWidth
        autoFocus
      />

      <Button
        name="buttonProductAddProductGTIN"
        tabIndex="-1"
        variant="contained"
        color="primary"
        style={{ marginTop: 25, width: "15%" }}
        onClick={handleGetGS1Information}
        disabled={
          productNumber.length < 6 || gs1Loading}
          >
        {gs1Loading ? "Loading..." : "Hent GS1"}
      </Button>
      <Typography>
        {gs1DataFetchState}
      </Typography>
      {gs1DataAutoSuggest.length > 0 ? (
        <>
          <FormControl style={{ marginBottom: 20, marginTop: 20 }} fullWidth>
          <InputLabel htmlFor="name">
            Leverandør
          </InputLabel>
          <Select
            fullWidth
            value={leverandor}
            onChange={(e) => selectLeverandor(e.target.value)}
            inputProps={{
              name: "name",
              id: "id",
            }}
          >
            {gs1DataAutoSuggest.map((item) => (
              <MenuItem key={item.id} value={item.name}>
                {item.name}
              </MenuItem>
            ))}
          </Select>
            <FormHelperText>
            {leverandorValidation()}
            </FormHelperText>
        </FormControl>
        </>
      ) : (
        <TextField
        margin="normal"
        label="Leverandør"
        type="text"
        id='textfieldAddProductAddLeverandor'
        name="leverandor"
        helperText={
          leverandorValidation()
        }
        value={leverandor}
        onChange={e => setLeverandor(e.target.value)}
        fullWidth
      />
      )}
      <TextField
        // error={nameValidation()}
        margin="normal"
        label={GetResource(Resources.Product_Edit_Information_Name)}
        type="text"
        name="name"
        id='textfieldAddProductAddName'
        helperText={
          nameValidation()
        }
        value={name}
        onChange={e => setName(e.target.value)}
        fullWidth
      />

      <TextField
        margin="normal"
        label={GetResource(Resources.Product_Edit_Information_Description)}
        type="text"
        id='textfieldAddProductAddDescription'
        name="description"
        value={description}
        onChange={e => setDescription(e.target.value)
        }
        InputProps={{
          multiline: true,
        }}
        fullWidth
      />

      {props.categories == null ? <Typography>Henter kategorier...</Typography> :
        <FormControl fullWidth
        // error={categoryValidation}
        >
          <AutoSuggestion
            dataSource={props.categories}
            placeholder="Kategori"
            inputtitle="Kategori"
            displayIdField="id"
            displayTitleField="name"
            onChange={x => setCategory(x)}
            value={category}
            name="category"
            getOptionSelected={(x) => x.id}
            // error={categoryValidation}
            helperText={
              categoryValidation()

            }
          />
        </FormControl>



      }

      {props.tags == null ? <Typography>Waiting for tags...</Typography> :
        <FormControl fullWidth>
          <AutoSuggestionWithChip
            autoSuggestData={props.tags}
            tags={tags}
            setTags={(newTags) => setTags(newTags)}
            allowAddNew={true}
            fullWidth
          />
        </FormControl>
}
    </>
  )
}
