import React from "react";
import MUIDataTable from "mui-datatables";
import ActionButtons from "./ActionButtons";
import { connect } from "react-redux";
import {
  Button,
  createMuiTheme,
  MuiThemeProvider,
  Tooltip,
} from "@material-ui/core";
import {
  apiGetParamsReplace,
  endpoints,
} from "../../../constants/services.constants";
import { orange } from "@material-ui/core/colors";
import { getCustomerPhoto } from "../../../helpers/ImageHelper";
import { deleteDialogOpen } from "../../../redux/actions/photos/search.photos.actions";
import { openDialogFullsizePhoto } from "../../shared/dialogs/DialogFullsizePhoto";
import { openDialogPhotoUsages } from "../../shared/dialogs/DialogPhotoUsages";
import { photoDeleteReason, photoStatusEnum, photoTypeEnum } from "../../../constants/enums";
import { getShortDate } from "../../../helpers/UtilHelper";
import { canReadApplicationForCustomer, getDeletePermissions } from "../../../helpers/CredentialsHelper";
import { PhotosTheme } from "../../../RepublicaMuiTheme";
import DataRowImage from "./DataRowImage";
import { applications } from "../../../constants/app.constants";
import PhotoTableActionButtons from "./actionButtons/PhotoTableActionButtons";
import Resources from "../../../Resources.json";
import { GetResource } from "../../../helpers/ResourceHelper";
import { IsFeatureEnabled, featureNames } from "../../../helpers/FeaturesHelper";

class PhotosDataTable extends React.Component {
  state = {
    tabledata: [],
    query: "",
    deletePermissions: [],
    dialogWherePhotoUsedInMotiveId: null,

  };
  tableRef = React.createRef();

  getCustomerName(customerId) {
    const customer = this.props.utilsReducer.customers.find(x => x.id === customerId);
    return customer == null ? "" : customer.name;
  }

  setDataTable(data) {
    return data.map(photo => {

      const customerName = this.getCustomerName(photo.customerId);
      const status = photoStatusEnum[photo.status];
      const deletePopupText = this.getDeleteTooltip(photo.deleteInfo);
      const showActionButtons = photo.exclusiveForCustomerIds.length === 0 ||
        photo.exclusiveForCustomerIds.some(x => canReadApplicationForCustomer(applications.EDIT_EXCLUSIVE_PHOTOS, x));

      let supportedFormats = { jpg: "jpg",  jpeg: "jpeg",  png: "png",  psd: "psd",  };
      let supportedFormat = photo.format in supportedFormats;

      let allowFullView = supportedFormat && photo.status !== "Deleted";

      return [
        <span style={{ paddingLeft: 20 }} />,
        <DataRowImage photoId={photo.id} exclusiveForCustomerIds={photo.exclusiveForCustomerIds} allowFullView={allowFullView} />,
        photoTypeEnum[photo.type].name,
        <Tooltip title={"Oprettet: " + getShortDate(photo.created)}>
          <Button onClick={() => openDialogPhotoUsages(photo.id)}>
            {photo.motiveId}
          </Button>
        </Tooltip>,
        customerName,
        <img
          width="50px"
          alt={customerName}
          src={getCustomerPhoto(customerName)}
        />,

        photo.products.map(product => {
          return (
            <Tooltip title={product.name}>
              <span className="nLine" key={product.id}>
                {product.name && product.name.length > 15
                  ? product.name.substring(0, 15) + "..."
                  : product.name}
                <br />
              </span>
            </Tooltip>
          );
        }),
        photo.format == null ? "" : photo.format,
        photo.width + "x" + photo.height,
        photo.isClipped ? "Ja" : "Nej",

        <Tooltip title={deletePopupText}>
          <span style={{ color: status.color }}>
            {status === photoStatusEnum.Active && photo.validTo != null ?
              <div>Aktiv<br/><div style={{color:orange[500]}}>Slettes: {getShortDate(photo.validTo)}</div></div> :
              status.name}
          </span></Tooltip>,

        showActionButtons ?
          <PhotoTableActionButtons
            key={photo.id}
            reloadSearch={this.props.reloadSearch}
            photo={photo}
            canDelete={this.state.deletePermissions.includes(photo.customerId)}
            onEditImage={this.props.onEditImage}
            onPhotoChanged={this.props.onPhotoChanged}
            handleDelete={(item) => this.props.handleDelete(item)}
          /> : undefined
      ];
    });
  }

  getDeleteTooltip(deleteInfo) {
    if (!deleteInfo) {
      return "";
    }
    return <>
      <div>{deleteInfo.date ? getShortDate(deleteInfo.date) : "Ukendt dato"}</div>
      <div>{deleteInfo.user}</div>

      <div style={{ marginTop: 10 }}>Grund:</div>
      <div>{photoDeleteReason[deleteInfo.reason] ?? "Ukendt"}</div>
      <div>{deleteInfo.reasonText}</div>
    </>
  }

  componentDidMount() {
    const deletePermissions = getDeletePermissions("Fotos");
    this.setState({ deletePermissions: deletePermissions });

    this.setState({ query: this.props.searchPhotosReducer.queryString });
  }

  handleMultiDeleteClick = (photoIds) => {
    //Needs to open the dialog to provide a reason for deleting fotos.

    this.props.dispatch(deleteDialogOpen(photoIds));
  };

  checkPermitions = (dataIndex) => {
    let photo = this.props.searchPhotosReducer.photos[dataIndex];
    let permission = this.props.credentialsReducer.me.find(
      (x) =>
        x.multiCustomerId === photo.multiCustomerId &&
        x.permissionName === "Fotos"
    );
    if (permission) {
      return permission.canDelete;
    }
    return false;
  };

  changePage(page) {
    this.props.onSearchRowOffsetChanged(page * this.props.rowsPerPage)
  }

  render() {
    const columns = [
      {
        name: "",
        options: {
          sort: false,
        },
      },
      {
        name: "Motiv",
        options: {
          sort: false,
        },
      },
      {
        name: "Fototype",
        options: {
          sort: true,
          display: IsFeatureEnabled(featureNames.Photos_Type_Enabled),
        },
      },
      {
        name: "Motiv-ID",
        options: {
          sort: true,
        },
      },
      {
        name: GetResource(Resources.Photos_DataTable_Customer_Title),
        options: {
          sort: true,
          display: false,
        },
      },
      {
        name: GetResource(Resources.Photos_DataTable_Customer_Title),
        options: {
          sort: true,
        },
      },
      {
        name: "Produkt",
        options: {
          sort: true,
          display: IsFeatureEnabled(featureNames.Photos_Products_Relation_Enabled)
        },
      },
      {
        name: "Format",
        options: {
          sort: true,
        },
      },
      {
        name: "Billedstørrelse",
        options: {
          sort: true,
        },
      },
      {
        name: "Fritlagt",
        options: {
          sort: true,
          display: IsFeatureEnabled(featureNames.Photos_Clipping_Enabled)
        },
      },
      {
        name: "Status",
        options: {
          sort: true,
          display: IsFeatureEnabled(featureNames.Photos_Status_Enabled),
        },
      },
      {
        name: "",
        options: {
          sort: false,
        },
      },
    ];

    const options = {
      textLabels: {
        body: {
          noMatch: "",
          toolTip: "Sortér",
        },
        pagination: {
          next: "Næste side",
          previous: "Forrige side",
          rowsPerPage: "Fotos per side:",
          displayRows: "af",
        },
        selectedRows: {
          text: "valgt række(r)",
        },
      },
      print: false,
      download: false,
      viewColumns: false,
      search: false,
      pagination: false,
      selectableRows: false,
      rowsPerPageOptions: [10, 50, 100],
      filter: false,
      count: this.props.searchResult.totalRows,
    };


    const data = this.setDataTable(this.props.searchResult.rows);


    return (
      <>
        <MuiThemeProvider theme={PhotosTheme}>
          <div ref={this.tableRef}>
            <MUIDataTable
              data={data}
              tableBodyMaxHeight={"200px"}
              columns={columns}
              options={options}
            />
          </div>
        </MuiThemeProvider>
      </>
    );
  }
}

export default connect((state) => state)(PhotosDataTable);
