import React, { useState, useEffect } from 'react';
import { Dialog, Button } from '@material-ui/core';
import RemoveCircleIcon from '@material-ui/icons/RemoveCircle';
import { fetchDeleteWT } from '../../../../helpers/HttpHelper';
import { endpoints } from '../../../../constants/services.constants';
import { openDialogConfirmDelete } from '../../../shared/dialogs/DialogConfirm';
import CustomArrow from './sharedComponents/CustomArrow';
import { styles } from './shared';
import Asset from "./Asset";

export function MediaLibraryCarousel(props) {
    let { items, isImage }=props;
    const [videoDialog, setVideoDialog] = useState(null)
    const [visibleIndex, setVisibleIndex] = useState(0);
    const take = 6;

    useEffect(() => {
        // setVisibleIndex(0);
    }, [items]);

    const onArrowClick = (direction) => {
        const increment = direction === 'left' ? -1 : 1;
        const newIndex = (visibleIndex + increment + items.length) % items.length;

        if (
            newIndex >= 0
            && newIndex + take <= items.length
        )
            setVisibleIndex(newIndex);

        setTimeout(() => {
        }, 0);
    };


    function renderVideoDialog() {
        return (
            <Dialog
                onClose={() => setVideoDialog(null)} open={true}>
                <video height={700} src={videoDialog} autoPlay controls loop>
                    Your browser does not support the video tag.
                </video>

            </Dialog>
        );
    }


    if (!items || items.length === 0) {
        return (<div style={{ width: 160 }}>empty</div>);
    }
    const itemsToRender = items.length < take + 1 ? items
        : items.slice(visibleIndex, visibleIndex + take);



    return (
        <>
            <div className='carouselContainer'>

                {/* Left arrow */}
                {visibleIndex > 0 && items && items.length > 1 &&
                    <div style={{ cursor: "pointer" }} onClick={() => onArrowClick('left')}>
                    <CustomArrow
                        className={styles.customArrowBack}
                    />
                    </div>
                }


                <div style={{ width: '100%', display: 'inline-flex', justifyContent: 'center' }}>
                    {itemsToRender.map((z, i) =>
                        <Asset 
                            item={z} 
                            key={z.id} 
                            {...props} 
                            setPreviewDialog={setVideoDialog}
                            setPreviewEndpoint={endpoints.STORAGE_PUBLIC_FILMMAKER.replace("{path}", z.urlRelative)}
                            deleteEndpoint={endpoints.FILMMAKER_ASSETS_ID.replace("{id}", z.id)}
                        />
                    )
                    }
                </div>

                {/* Right arrow */}
                {items && visibleIndex + take < items.length &&
                    <div style={{ cursor: "pointer" }} onClick={() => onArrowClick('right')}>
                    <CustomArrow />
                    </div>
                }
            </div>

            {videoDialog == null ? undefined : renderVideoDialog()}
        </>
    );
}
