import { Button } from '@material-ui/core';
import React from 'react';
import { styles } from '../shared';

export default function BackButton({ tilbage }) {
    return (
        <Button
            variant="text"
            color="primary"
            size="large"
            style={styles.viewButton}
            onClick={tilbage}
        >
            {"<"} Tilbage
        </Button>
    )
}