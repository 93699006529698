import React from "react";
import { MenuItem, Select } from "@material-ui/core";

function SelectTemplate({
    handleSelectionChanged, spread,
    value, selectedPages, availableTemplates
}) {
    return (
        <Select
            onChange={event => handleSelectionChanged(event)}
            inputProps={{
                name: spread ? spread : "templateSelect",
                id: 'templateSelect',
            }}
            MenuProps={{
                getContentAnchorEl: null
            }}
            label="Template"
            value={value}
            style={{ width: 150 }}
            disabled={availableTemplates.length === 0}

        >
            {availableTemplates.map((x, index) =>
                <MenuItem
                    value={x.fileName}
                    key={index}
                >
                    {x.fileName}
                </MenuItem>
            )}
        </Select>
    )
}

export default SelectTemplate;