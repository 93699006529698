import { Typography } from '@material-ui/core';
import React, { useState } from 'react';
import history from '../../../routes/history';
import { LogInHeader } from '../logIn/components/LogInHeader';
import StyledButtonLogIn from '../logIn/components/StyledButton';

export default function PasswordWasChanged({ validationMessage }) {
    const headerText = ["Færdig!"];
    return (
        <>
            <LogInHeader
                text={headerText}
            />
            <div style={{ marginTop: 24 }}>
                <Typography
                    variant="body1"
                >
                    {validationMessage}
                </Typography>
                <StyledButtonLogIn
                    fullWidth
                    variant="contained"
                    color="primary"
                    buttonText="Tilbage"
                    handleOnClick={() => { history.push("/"); }}
                />
            </div>

        </>
    )
} 