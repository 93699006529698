import React, { useState, useContext } from 'react';
import { Typography, Tooltip, Fab, withStyles, LinearProgress } from '@material-ui/core';
import AddIcon from "@material-ui/icons/Add";
import FotoClippingContextProvider, { FotoClippingContext } from '../fotoClipping/FotoClippingContext';
import { openDialogAddPhotoClipping } from './dialogs/DialogAddPhotoClipping';
import PhotoClippingOrdersTable from './DataTable';
import SearchBar from './SearchBar';
import { searchPhotoClippingOrders } from '../fotoClipping/Requests';
import { Helmet } from 'react-helmet';

export function FritlagtMain(props) {
    return (
        <FotoClippingContextProvider>
            <FritlagtMainBase {...props} />
        </FotoClippingContextProvider>
    )
}

const styles = {
    searchBar: {
        width: "100%",
        alignItems: "center",
        display: "grid",
        gridTemplateColumns: "auto 60px",
        marginBottom: 20
    },
    iconButton: {
        marginLeft: 10,
        marginTop: 5
    }
}

function FritlagtMainBase(props) {
    const { classes } = props;
    const {
        fotoClippingOrders: fotoClippingOrdersState,
        loading: loadingState,
    } = useContext(FotoClippingContext);
    const [fotoClippingOrders, setFotoClippingOrders] = fotoClippingOrdersState;
    const [loading, setLoading] = loadingState;

    const [queryString, setQueryString] = useState("");

    const openDialogAdd = async () => {
        const confirmed = await openDialogAddPhotoClipping();
        if (confirmed) {
            performSearch();
        }
    }

    const performSearch = async () => {
        setLoading(true);
        let orders = await searchPhotoClippingOrders(queryString);
        setFotoClippingOrders(orders);
        setLoading(false);
    }

    const keyPress = (e) => {
        if (e.keyCode === 13) {
            performSearch();
        }
    }

    return (
        <>
            <Helmet>
                <title>Mine Fritlægninger</title>
            </Helmet>
            <Typography variant="h4">Mine Fritlægninger</Typography>
            <div className={classes.searchBar}>
                <SearchBar
                    queryString={queryString}
                    changeQueryString={(e) => setQueryString(e.target.value)}
                    performSearch={performSearch}
                    keyPress={(e) => keyPress(e)}
                />
                <Tooltip title={"Bestil fritlægning"}>
                    <Fab
                        size="small"
                        color="primary"
                        className={classes.iconButton}
                        onClick={() => openDialogAdd()}
                    >
                        <AddIcon />
                    </Fab>
                </Tooltip>
            </div>
            {loading ? <LinearProgress /> : fotoClippingOrders.length !== 0 ? <PhotoClippingOrdersTable /> : undefined}
        </>
    )
}

export default withStyles(styles)(FritlagtMain)