import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { getMotivId } from "../../../../../helpers/UtilHelper";
import { fetchCustomers } from "../../../../../redux/actions/utils/customers.utils.actions";
import store from "../../../../../redux/store";
import { LoadingLogo } from "../../../../shared/Loading";
import TabSearchOffer from "../../../../shared/PhotoSelectionComponents/TabSearchOffer";
import { addMotivPhotoCombinations } from "./Requests";

export default function DialogAddPhotoToCombination(props) {
    const { handleClose, correctionIds, refreshCombinations } = props;
    const [photoId, setPhotoId] = useState(undefined);
    const [loading, setloading] = useState(false);

    useEffect(() => {
        store.dispatch(fetchCustomers());
    }, []);

    const addNewPhoto = async (photoId) => {
        setloading(true);
        setPhotoId(photoId);
        const motivId = getMotivId(photoId);
        const requestBody = {
            ids: correctionIds,
            addMotives: [motivId]
        }

        const response = await addMotivPhotoCombinations(requestBody);
        refreshCombinations(response, correctionIds);
        setloading(false);
        handleClose();
    }

    return (
        <>
            <Dialog
                open={true}
                onClose={handleClose}
                aria-labelledby="form-dialog-title"
                fullWidth
                maxWidth="md"
            >
                <div>
                    <DialogTitle id="form-dialog-title">
                        Tilføjer foto
                    </DialogTitle>
                </div>
                <DialogContent>
                    <div style={{ minHeight: 500 }}>
                        {loading ? <LoadingLogo /> : 
                        <TabSearchOffer
                            handleAddOfferPicture={(photoId) => addNewPhoto(photoId)}
                            isLoading={false}
                            onlyPack={false}
                            customerId={undefined}
                        />
                        }
                    </div>

                </DialogContent>
                <DialogActions>
                    <Button
                        onClick={handleClose}
                        disabled={loading}
                    >
                        {!loading ? "Luk" : "Loading..."}
                    </Button>
                </DialogActions>
            </Dialog>
        </>

    )
}