import {
  endpoints,
  configurations as endpointConfigs
} from "../constants/services.constants";
import { appConstants } from "../constants/app.constants";
import { headersJsonToken } from "./HttpHelper";
import { handleAPIErrors } from "../redux/Handlers/ErrorHandlers";
import { Buffer } from 'buffer';
import Resources from "../Resources.json";

export function setUserAndTokenInStorage(
  identityUserResponse,
  username,
  password
) {
  localStorage.setItem("id_token", identityUserResponse.id_token);
  localStorage.setItem("access_token", identityUserResponse.access_token);
  localStorage.setItem("username", username);
  // localStorage.setItem("password", password);
 localStorage.removeItem("passowrd");

}
export function deleteUserAndTokenInStorage() {
  localStorage.removeItem("access_token");
  localStorage.removeItem("id_token");
  localStorage.removeItem("username");
  localStorage.removeItem("passowrd");
}

export function getUsername() {
  return localStorage.getItem("username");
}

export function getToken() {
  return localStorage.getItem("access_token");
}

//https://stackoverflow.com/questions/38552003/how-to-decode-jwt-token-in-javascript-without-using-a-library
// obsolete, not used anywhere
export function parseJwt(token) {
  var base64Url = token.split('.')[1];
  var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
  var jsonPayload = decodeURIComponent(atob(base64).split('').map(function (c) {
    return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
  }).join(''));

  return JSON.parse(jsonPayload);
};

export async function getSessionToken(username, password) {
  var details = {
    username: username,
    password: password,
    grant_type: endpointConfigs.IDENTITY_GRANT_TYPE,
    client_id: endpointConfigs.IDENTITY_CLIENT_ID,
    client_secret: endpointConfigs.IDENTITY_CLIENT_SECRET,
    scope: endpointConfigs.IDENTITY_SCOPE
  };

  let result = {};

  let f = fetch(endpoints.IDENTTITY_GET_TOKEN, {
    method: "POST",
    body: formatBodyParamsPOST(details),
    headers: { 
      "Content-Type": "application/x-www-form-urlencoded",
      "x-organization-id": Resources.Organization
    }
  })
    .then(response => response.json().then(user => ({ user, response })))
    .then(({ user, response }) => {
      console.log(user);
      console.log(response);
      if (!response.ok) {
        console.log(user);
        if (user.error_description === "invalid_username_or_password") {
          result.resultLogin = false;
          result.errorMsg = "Forkert brugernavn eller kodeord.";
          return result;
        }
        else {
          result.resultLogin = false;
          result.errorMsg = "Der er en fejl under login: " + user.error;
          return result;
        }
      } else {
        result.resultLogin = true;
        result.errorMsg = "";
        setUserAndTokenInStorage(user, username, password);
        return result;
      }
    })
    .catch(err => {
      console.error(err);
      result.resultLogin = false;
      result.errorMsg = "Der skete en fejl ved log ind. Fejl: " + err;
      return result;
    });

  return await f;
}

export async function requestResetCode(username, callbackSucced, callbackError) {
  return fetch(endpoints.IDENTTITY_REQUEST_RESETCODE, {
    method: "POST",
    headers: headersJsonToken,
    body: JSON.stringify({ userName: username })
  })
    .then(handleAPIErrors)
    .then(res => res.json())
    .then(json => {
      // if (json == null) throw Error(messageNullResponse);
      callbackSucced(json);
      // return json;
    })
    .catch(error => {
      // handleAPIErrorNotification(endpoint, error);
      callbackError(error);
    });

}

export async function changePasswordWithCode(username, password, resetCode, callbackSucced, callbackError) {
  var bodyObj = {
    "userName": username,
    "resetCode": resetCode,
    "password": password
  };

  return fetch(endpoints.IDENTTITY_RESETPASSWORD_WITHCODE, {
    method: "POST",
    headers: headersJsonToken,
    body: JSON.stringify(bodyObj)
  })
    .then(handleAPIErrors)
    .then(res => res.json())
    .then(json => {
      // if (json == null) throw Error(messageNullResponse);
      callbackSucced(json);
      // return json;
    })
    .catch(error => {
      // handleAPIErrorNotification(endpoint, error);
      callbackError(error);
    });

}

export async function checkSessionToken() {
  let result = {};

  let token = localStorage.getItem("access_token");

  if (token === null) {
    return false;
  }
  var details = {
    token: token
  };

  let authHeaders = new Headers();
  authHeaders.set(
    "Authorization",
    "Basic " +
    new Buffer(
      endpointConfigs.IDENTITY_RESOURCE +
      ":" +
      endpointConfigs.IDENTITY_CLIENT_SECRET
    ).toString("base64")
  );
  authHeaders.set("Content-Type", "application/x-www-form-urlencoded");
  authHeaders.set("x-organization-id", Resources.Organization);
  let f = fetch(endpoints.IDENTTITY_VALIDATE_TOKEN, {
    method: "POST",
    body: formatBodyParamsPOST(details),
    headers: authHeaders
  })
    .then(response => response.json().then(user => ({ user, response })))
    .then(({ user, response }) => {
      if (!response.ok || !user.active) {
        //TODO: refresh
        if (user.error !== null) {
          result.tokenIsValid = false;
          result.errorMsg = "Du er blevet logget ud pga. inaktivitet. Log venligst på igen.";
        }
        deleteUserAndTokenInStorage();
        return result;
      } else {
        checkTokenExp(user.exp, user.nbf);
        result.tokenIsValid = true;
        result.errorMsg = "";
        return result;
      }
    })
    .catch(err => {
      console.error(err);
      result.tokenIsValid = false;
      result.errorMsg = "Der opstod et problem. Log venligst ind igen.";
      cleanAndRefresh();
      return result;
    });
  return await f;
}

// obsolete
export function refreshToken(exp, nbf = null) {
  if (exp == null || exp < Date.now().getSeconds() + appConstants.TOKEN_PREV_EXP_LOGOUT) {
    return getSessionToken(
      localStorage.getItem("username"),
      // localStorage.getItem("password")
    );
  }
  return false;
}

export function checkTokenExp(exp, nbf = null) {
  let d = new Date().getTime() / 1000;
  let o = exp - appConstants.TOKEN_PREV_EXP_LOGOUT;
  console.log(
    "Token expiration: " +
    exp +
    ", App will logout at: " +
    o +
    ", current time is: " +
    d +
    " (seconds)"
  );
  if (exp == null || o < d) {
    cleanAndRefresh();
  }
  return false;
}

export function formatBodyParamsPOST(params) {
  var formBody = [];
  for (var property in params) {
    var encodedKey = encodeURIComponent(property);

    var encodedValue = encodeURIComponent(params[property]);
    formBody.push(encodedKey + "=" + encodedValue);
  }
  formBody = formBody.join("&");
  return formBody;
}

export function cleanAndRefresh() {
  deleteUserAndTokenInStorage();
  window.location.reload();
}
