import React, { useEffect, useState } from "react";
import { apiGetParamsReplace, endpoints } from "../../../../../constants/services.constants";
import DialogPreviewPublicationUI from "./DialogPreviewPublicationUI";

function DialogPreviewPublication({ handleClose, publicationId, publicationPages, selectedImageIndex }) {

    const pubId = parseInt(publicationId);
    const pages = parseInt(publicationPages);

    const [images, setImages] = useState([]);
    const [imageIndex, setImageIndex] = useState(0);
    const [pageMenuPosition, setPageMenuPosition] = useState('top');

    useEffect(() => {
        let urls = [];
        if (selectedImageIndex) {
            setImageIndex(selectedImageIndex);
        }

        for (let i = 1; i <= pages; i++) {
            let pageEndpoint = apiGetParamsReplace(endpoints.PUBLICATION_PREVIEW_CATALOG, pubId, i);
            urls = [...urls, pageEndpoint];
        }
        setImages(urls);
    }, []);

    return (
        <DialogPreviewPublicationUI
            handleClose={() => handleClose()}
            pageMenuPosition={pageMenuPosition}
            imageIndex={imageIndex}
            images={images}
            setImageIndex={setImageIndex}
        />
    );
}

export default DialogPreviewPublication;
