import { bool } from "prop-types";
import { getReadPermissions } from "../../../helpers/CredentialsHelper";
import { IsFeatureEnabled, featureNames } from "../../../helpers/FeaturesHelper";
import Resources from "../../../Resources.json"
import {GetResource} from "../../../helpers/ResourceHelper" 

export function getPhotoFiltersForUi(allCustomers) {
    // get default search filters
    const filters = [];
    if (IsFeatureEnabled(featureNames.Photos_Type_Enabled)) {
        // types
        filters.push({
            key: "type",
            displayName: "Fototype",
            options: [
                { key: "Pack", displayName: "Packshot", selected: false },
                { key: "Environment", displayName: "Miljø", selected: false }
            ]
        });
    }
    // customers
    const visibleCustomerIds = getReadPermissions("Fotos");
    const customers = allCustomers.filter(x => visibleCustomerIds.includes(x.id));

    if(customers.length > 1)
    {
        filters.push({
            key: "customer",
            displayName: GetResource(Resources.Photos_Search_Customer_Title),
            options: customers.map(x => {
                return { key: x.id, displayName: x.name, selected: false }
            })
        });
    }

    if (IsFeatureEnabled(featureNames.Photos_Clipping_Enabled)) {
        // clipped
        filters.push({
            key: "clipped",
            displayName: "Fritlagt",
            options: [
                { key: "true", displayName: "Ja", selected: false },
                { key: "false", displayName: "Nej", selected: false }
            ]
        });
    }

    if (IsFeatureEnabled(featureNames.Photos_Status_Enabled)) {
        // status
        filters.push({
            key: "status",
            displayName: "Status",
            options: [
                { key: "Active", displayName: "Aktiv", selected: true, default: true },
                { key: "PhotoBeingTaken", displayName: "Foto på vej", selected: true, default: true },
                { key: "PhotoBeingClipped", displayName: "Afventer fritlægning", selected: true, default: true },
                { key: "Deleted", displayName: "Slettet", selected: false }
            ]
        });
    }
    if (IsFeatureEnabled(featureNames.Products_Categories_Enabled)) {
            //TODO: Get relevant Organization categories from an endpoint
            // category
            filters.push({
                key: "category",
                displayName: "Kategori",
                options: [
                    { key: 13, displayName: "Bolig", selected: false },
                    { key: 3, displayName: "Brød og mejeri", selected: false },
                    { key: 14, displayName: "Elektronik", selected: false },
                    { key: 2, displayName: "Frugt, grønt og blomster", selected: false },
                    { key: 9, displayName: "Hushold pers pleje og helsekost", selected: false },
                    { key: 7, displayName: "Kolonial og Dybfrost", selected: false },
                    { key: 1, displayName: "Kød, fisk og viktualier", selected: false },
                    { key: 6, displayName: "Nydelsesmidler og kioskvarer", selected: false },
                    { key: 11, displayName: "Tekstil", selected: false },
                    { key: 8, displayName: "Vin og spiritus", selected: false }
                ]
            });
    }
    return filters;
}

export function GetSearchCustomers(allCustomers, uiFilters){
    const visibleCustomerIds = getReadPermissions("Fotos");
    const customers = allCustomers.filter(x => visibleCustomerIds.includes(x.id));

    if(customers.length > 1)
    {
        return uiFilters.find(x => x.key === "customer").options;
    }
    return customers;
}

export function getPhotoFiltersForRequest(uiFilters, tagArray, allFilters, customers) {
    const typeOptions = IsFeatureEnabled(featureNames.Photos_Type_Enabled) ? uiFilters.find(x => x.key === "type").options : [];
    const customerOptions = GetSearchCustomers(customers, uiFilters);
    const clippedOptions = IsFeatureEnabled(featureNames.Photos_Clipping_Enabled) ? uiFilters.find(x => x.key === "clipped").options : [];
    const statusOptions = IsFeatureEnabled(featureNames.Photos_Status_Enabled) ? uiFilters.find(x => x.key === "status").options : [];


    const categoryOptions = IsFeatureEnabled(featureNames.Products_Categories_Enabled) ? uiFilters.find(x => x.key === "category").options : [];
    let isClipped = null;
    if (clippedOptions !== []){
        const clippedTrue = clippedOptions.find(x => x.key === "true").selected;
        const clippedFalse = clippedOptions.find(x => x.key === "false").selected;
        if (clippedTrue && !clippedFalse) {
            isClipped = true;
        }
        if (!clippedTrue && clippedFalse) {
            isClipped = false;
        }
    }

    return {
        types: typeOptions.filter(x => x.selected).map(x => x.key),
        customerIds: customerOptions.filter(x => x.selected).map(x => x.key),
        isClipped: isClipped,
        statuses: statusOptions.filter(x => x.selected).map(x => x.key),
        mainCategoryIds: categoryOptions.filter(x => x.selected).map(x => x.key),
        tags: tagArray
    };
}