import { getEANS, getFileBase, showErrorNotification } from "../../../../../../helpers/UtilHelper";
import { closeAddPhotoOrderDialog } from "../../../../../../redux/actions/fotohaandtering/photoorder.fotohaandtering.actions";
import store from "../../../../../../redux/store";
import { stringIsEmpty } from "../../../../../../helpers/UtilHelper";
import { checkJobMatchSelectedCustomer } from "./Logic";
import { savePhotoOrder } from "../../../../../../redux/actions/photos/fototracker.photos.actions";

export function handleClose() {
    store.dispatch(closeAddPhotoOrderDialog());
}

export async function saveNewPhotoOrder(order, state, referenceFiles, layoutFiles, setState) {
    // validate if required fields are filled in
    if (!orderIsValid(order, state, setState)) {
        return false;
    }
    // validate if jobNo belongs to the selected customer
    if (!checkJobMatchSelectedCustomer(order.jobId, order.customerId)) {
        showErrorNotification("Job nummer skal være fra den samme kunde");
        return false;
    }
    const _layoutFiles = await getFileBase(layoutFiles);
    const _refFiles = await getFileBase(referenceFiles);
    const baseOrder = {
        jobId: order.jobId,
        customerId: order.customerId,
        instructions: order.instructions,
        type: order.type, // photo type id
        validFrom: order.validFrom,
        validTo: order.validTo,
        angle: parseInt(order.angle),
        location: order.location,
        categoryId: order.productMainCategoryId,
        comment: order.comment,
        priority: order.priority,
        origin: order.origin,
        clipPhoto: order.clipPhoto,
        digitalPack: order.digitalPack,
        omskud: order.omskud,
        productNumbers: getEANS(order.productNumbers),
        tags: order.tags,
        photoTags: state.photoTags,
        exclusiveForCustomerIds: state.exclusiveForCustomerIds,
        referenceFiles: _refFiles,
        layoutFiles: _layoutFiles,
    }

    return await store.dispatch(savePhotoOrder(baseOrder));
}

export function orderIsValid(order, state, setState) {
    let isValid = true;
    let _validation = { ...state.validation };
    // check job number
    if (!order.jobId || order.jobId === 0 || order.jobId === "") {
        _validation.workbookJodIdErrorMessage = "Indtast et jobId";
        isValid = false;
    } else {
        _validation.workbookJodIdErrorMessage = "";
    }
    // check customer
    if (!order.customerId || order.customerId === "") {
        _validation.kundeErrorMessage = "Vælg venligst en kunde";
        isValid = false;
    } else {
        _validation.kundeErrorMessage = "";
    }
    // product numbers
    if (order.productNumbers.length === 0) {
        _validation.gtinsErrorMessage = "Indtast mindst et Ean / GTIN-nummer.";
        isValid = false;
    } else {
        _validation.gtinsErrorMessage = "";
    }
    // photo briefing
    if (stringIsEmpty(order.instructions)) {
        _validation.photoBriefErrorMessage = "Fotobrief skal være udfyldt.";
        isValid = false;
    } else {
        _validation.photoBriefErrorMessage = "";
    }
    // category
    if (!order.productMainCategoryId) {
        _validation.categoryErrorMessage = "Kategori skal være udfyldt.";
        isValid = false;
    } else {
        _validation.categoryErrorMessage = "";
    }

    setState({ ...state, validation: _validation })
    return isValid;
}