import React from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import EditIcon from "@material-ui/icons/Edit";
import PhotoLibraryIcon from "@material-ui/icons/PhotoLibrary";
import { connect } from "react-redux";
import history from "../../../routes/history";
import IconButton from "@material-ui/core/IconButton";
import { Tooltip, MenuItem } from "@material-ui/core";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import Menu from "@material-ui/core/Menu";
import { endpoints, apiGetParamsReplace } from "../../../constants/services.constants";
import { addToastMessage } from "../../../redux/actions/toasts.actions";
import { getToken } from "../../../helpers/AuthHelper";
import { openDialogConfirm } from "../../shared/dialogs/DialogConfirm";
import { createCorrectionReport, generatePublicationReport, sendToNewIndesign } from "./Requests";
import { fetchPostWT } from "../../../helpers/HttpHelper";
import { openDialogEditPublication } from "./dialogs/DialogEditPublication";
import { showSuccessNotification, showWarningNotification } from "../../../helpers/UtilHelper";
import VisibilityIcon from '@material-ui/icons/Visibility';
import { getPublication } from "../fotohaandtering/Requests";
import { getCorrectionDate } from "../../../helpers/UtilHelper";
import { getCorrectionFileDate } from "../../../helpers/UtilHelper";
import { subscribeUserToPublicationNotifications } from "./logic/BusinessLogic";
import { notificationChannelsEnum } from "../../../constants/enums";
import { isUserSubscribedToChannelRecord, unsubscribeUserFromNotifications } from "../../layout/businessLogic/Requests";
import {IsFeatureEnabled, featureNames, getFeatureOrDefault} from "../../../helpers/FeaturesHelper";
import Resources from "../../../Resources.json";
import { canDeleteApplicationForCustomer, canReadApplicationForCustomer } from "../../../helpers/CredentialsHelper";
import { appConstants, applications } from "../../../constants/app.constants";
const styles = theme => ({
  button: {
  },
  extendedIcon: {
    marginRight: theme.spacing(1)
  }
});

class Btns extends React.Component {
  state = {
    anchorEl: null,
    isSubscribedToNotifications: false
  };

  async componentDidMount() {
    // check if user is subscribed to notifications on this publication
    const isSubscribed = await isUserSubscribedToChannelRecord(notificationChannelsEnum["PublicationUpdated"], parseInt(this.props.publication.jobNumber));

    this.setState({
      isSubscribedToNotifications: isSubscribed
    });
  }

  handleClickPopper = event => {
    this.setState({ anchorEl: event.currentTarget });
  };

  handleClosePopper = () => {
    this.setState({ anchorEl: null });
  };

  isStatusOpen(status) {
    let openStatuses = ["Active", "Closed"];
    return openStatuses.includes(status);
  }

  handleEdit = (publicationId) => {
    openDialogEditPublication(publicationId, this.props.fetchAutoSuggestTags);
  };

  // Send to new indesign all the pages
  handleSendAllToIndesignGenerator = async (publicationId) => {
    // Get pages of the publlication

    const publication = await getPublication(publicationId);
    const pageInfos = publication.pages.map(function(key,index){
      return {
        PageNumber : publication.pages[index].pageNumber,
        TemplateName : "standard",
        Spread: publication.pages[index].spreadNumber
      }
    });
    const body = {
      workbookId: publication.jobId,
      pageInfos: pageInfos
    };

    sendToNewIndesign(body);
    this.handleClosePopper();
    this.props.dispatch(
      addToastMessage({
        message: "Publikation er blevet sendt til Indesign generator",
        type: "success"
      })
    );
  };

  showPopupMenu = event => {
    this.setState({ anchorEl: event.currentTarget });
  };

  getPublicationReport = async (publicationId) => {
    generatePublicationReport(publicationId);
    showSuccessNotification("Rapporten bliver genereret. Du vil modtage den i en mail inden længe.");
    this.handleClosePopper();
  }

  downloadCorrectionReport = (endpoint, reportName) => {
    fetch(endpoint, {
      method: "GET",
      headers: { 
        "Authorization": "Bearer " + getToken(),
        "x-organization-id": Resources.Organization
      }
    }).then(response => response.blob()).then(blob => {
      let url = window.URL.createObjectURL(blob);
      let a = document.createElement("a");
      a.href = url;
      a.download = reportName;
      a.click();
    });
    this.handleClosePopper();
  }

  openTilbud = (id, name, jobId) => {
    history.push('/tilbud', {
      publicationId: id,
      publicationName: name,
      jobNumber: jobId
    })
  }


  
  generateCorrectionLinks = (id, jobId) => {
    let correctionMenuItems = [];

    const correctionsSorted = this.props.corrections.sort((a, b) => (a.id > b.id) ? 1 : -1);

    for (let i = 0; i < correctionsSorted.length; i++) {

      const currentCorrection = correctionsSorted[i];

      // let correction = i + 1;
      const downloadFileName = `${jobId}_korrekturrapport ${getCorrectionFileDate(currentCorrection.generationDate)}.pdf`;
      correctionMenuItems.push(
        <MenuItem key={currentCorrection.id}
          index={i}
          onClick={() => this.downloadCorrectionReport(apiGetParamsReplace(endpoints.PUBLICATION_CORRECTION_BY_ID, currentCorrection.id, id), downloadFileName)}>
          Korrektur {(i+1)} per. <div style={{ fontWeight: 600}}>{getCorrectionDate(currentCorrection.generationDate)}</div>
        </MenuItem>
      );
    }
    return correctionMenuItems;
  }

  async handleCreateCorrectionReport(publicationId) {
    this.handleClosePopper();
    const confirmed = await openDialogConfirm("Opret korrekturrapport", "Er du sikker på at du vil oprette en korrekturrapport nu?");
    if (confirmed) {
      const response = await createCorrectionReport(publicationId);
      if (!response) {
        showWarningNotification("Ingen ændringer siden sidst");
      } else {
        showSuccessNotification("Korrekturrapporten er blevet oprettet");
        this.props.updateDataTable(publicationId);
      }
    }
  }

  async handleSubscriptionStatus(jobNumber) {
    const func = this.state.isSubscribedToNotifications ? unsubscribeUserFromNotifications : subscribeUserToPublicationNotifications;
    await func(parseInt(jobNumber));

    this.setState({
      isSubscribedToNotifications: !this.state.isSubscribedToNotifications
    });
  }

  canShowMenuItem = (feature) =>{

    if(!IsFeatureEnabled(feature)){
      return false;
    }

    const canDelete = canDeleteApplicationForCustomer(applications.PUBLICATION,this.props.publication.customerId);
    if(!canDelete)
    {
      return false;
    }

    return true;
      
  }

  render() {
    const { publication, handleView } = this.props;
    const canRead = canReadApplicationForCustomer(applications.PUBLICATION,publication.customerId);
    const canDelete = canDeleteApplicationForCustomer(applications.PUBLICATION,publication.customerId);

    const canReadFotoHandtering = canReadApplicationForCustomer(applications.FOTOHAANDTERING,publication.customerId);

    return (
      <div style={{ width: 128 }}>
        {canReadFotoHandtering ?
        <Tooltip title={"Fotohåndtering"}>
          <IconButton
            disabled={!this.isStatusOpen(publication.status)}
            color="primary"
            className="float-left smaller_icon"
            name="buttonPublicationPhotoHandling"
            onClick={() => handleView()}>
            <PhotoLibraryIcon />
          </IconButton>
        </Tooltip>
        : ""
        }
        { canDelete ? 
        (<Tooltip title={"Rediger publikation"}>
          <IconButton
            disabled={!this.isStatusOpen(publication.status)}
            color="primary"
            className="float-left smaller_icon"
            onClick={() => this.handleEdit(publication.id)}>
            <EditIcon></EditIcon>
          </IconButton>
        </Tooltip>) : ""
        }
        <Tooltip title={"Flere muligheder"}>
          <IconButton
            className="float-left smaller_icon"
            color="primary"
            onClick={this.showPopupMenu}>
            <MoreVertIcon />
          </IconButton>
        </Tooltip>

        <Menu
          id="simple-menu"
          anchorEl={this.state.anchorEl}
          open={Boolean(this.state.anchorEl)}
          onClose={this.handleClosePopper}
        >

          {!this.canShowMenuItem(featureNames.Indesign_Enabled) ? undefined :  <MenuItem onClick={() => this.handleSendAllToIndesignGenerator(publication.id)}>Send til Indesign</MenuItem>}
          {!this.canShowMenuItem(featureNames.Publications_Monstringsark_Enabled) ? undefined :  <MenuItem onClick={() => this.getPublicationReport(publication.id)}>Hent mønstringsark​</MenuItem>}
          {!this.canShowMenuItem(featureNames.Publications_Corrections_Enabled) ? undefined : this.generateCorrectionLinks(publication.id, publication.jobNumber)}
          {!this.canShowMenuItem(featureNames.Publications_Corrections_Enabled) ? undefined :  <MenuItem onClick={() => this.handleCreateCorrectionReport(publication.id)}>Opret korrekturrapport</MenuItem>}
          <MenuItem onClick={() => this.openTilbud(publication.id, publication.name, publication.jobNumber)}>Se alle tilbud</MenuItem>
          <MenuItem onClick={() => this.handleSubscriptionStatus(publication.jobNumber)}>
            {!this.state.isSubscribedToNotifications ? "Tilmeld notifikationer" : "Afmeld notifikationer"}
          </MenuItem>
        </Menu>
      </div>
    );
  }
}

Btns.propTypes = {
  classes: PropTypes.object.isRequired
};

const ActionButtons = withStyles(styles)(Btns);

export default connect(state => state)(ActionButtons);
