import React, { useRef, useEffect } from 'react';
import { Button, Typography, LinearProgress, Paper } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import { PhotoCombination } from './canvasLib/PhotoCombination';
import ProductMediaCard from './ProductMediaCard';
import { useFetch } from './CanvasCorrectorAppHooks';
import { endpoints } from '../../../../constants/services.constants';
import { formatMotiv, getMotiv300, getMotiv1000 } from './../../../../helpers/ImageHelper';

const useStyles = makeStyles(theme => ({
    buttonsContainer: {
        flexGrow: 1,
    },
    button: {
        // margin: theme.spacing(1),
        // zoom:2
    }
}));

export default function CorrectorApp(props) {
    const photoCombinationRef = useRef();
    const [data, offer, loading, loadData, imagesLoaded, imagesTotal, error, setData] = useFetch(
        props.combinationId);
    const [saving, setSaving] = React.useState(false);
    const [reloadCanvas, setReloadCanvas] = React.useState(false);

    const [sizeMutations, setSizeMutations] = React.useState(null);

    const classes = useStyles();


    useEffect(() => {
        loadData();
    }, []);


    useEffect(() => {
        if (reloadCanvas) {

            setTimeout(function () {
                setReloadCanvas(false);

            }, 1000);
        }
    }, [reloadCanvas]);
    const reloadData = () => {
        loadData();
    }



    const handleClickSave = () => {
        setSaving(true);
        photoCombinationRef.current.sendCombinationTemplate(250, false,
            (data) => {
                setSaving(false);
                console.log(data)
                props.setCorrectionResult(data);
            }
        );

    }

    function addProductToCanvas(p) {
        fetch(getMotiv1000(p), {
            method: 'HEAD'
        }).then
            (
                function (response) {
                    let w = response.headers.get("width");
                    let h = response.headers.get("height");

                    let d = { ...data }


                    d.images.push({
                        // eaNnr: "5701975302445"
                        width: 200,
                        height: (h*200)/w,
                        normalizedWidth: 1000,
                        normalizedHeight:(h*1000)/w,
                        left: 10,
                        motiveId: p,
                        // name: "SKYR MED JORDB 400 G"
                        // normalizedProportion: 0.7751937984496124
                        // originalHeight: null
                        // originalWidth: null
                        // policeDetection: null
                        previewUrl: getMotiv300(p),
                        // priority: null,
                        angle: 0,
                        rotate: 0,
                        scaleX: 1,
                        scaleY: 1,
                        text: null,
                        top: 10,
                        url: getMotiv1000(p),
                        zIndex: d.images.length
                    })
                    d.jsonTemplate = JSON.stringify({ objects: d.images });
            
                    setData(d);
            
                    setTimeout(function () {
                        setReloadCanvas(true);
            
                    }, 100);



                }
            );



    }

    const renderProductImages = () => {
        let productsCombination = typeof (data.jsonTemplate) === 'string' ? JSON.parse(data.jsonTemplate).objects : data.jsonTemplate.objects;
        let products = offer.products;
        return products.map((x, i) =>
            <Grid item xs key={i} >
                <ProductMediaCard addProductToCanvas={addProductToCanvas} item={productsCombination.find(z => z.motiveId === formatMotiv(x.selectedPhotoId))} product={x} sizeMutations={sizeMutations} />
            </Grid>);//.sort((a, b) => (a.priority < b.priority ? -1 : 1) * (false ? 1 : -1));

    }



    function takeSizeMutations(images) {
        setSizeMutations(images);
    }


    return (
        <div style={{ width: 1000 }}>

            {
                loading ? (<div><LinearProgress size={100} /><Typography variant="caption">Henter data...</Typography></div>)
                    : imagesLoaded !== imagesTotal ? (<div><LinearProgress size={100} /><Typography variant="caption">Henter billeder... ({imagesLoaded + "/" + imagesTotal})</Typography></div>)
                        : data == null ?
                            <div>
                                <Typography variant="subtitle1" >{error != null ? error : "Data could not be set"}</Typography>
                                <Button variant="contained" color="secondary" className={classes.button}
                                    onClick={reloadData}
                                >
                                    Reload
                                </Button>
                            </div>
                            : <div>
                                <Grid
                                    direction="row"
                                    justify="center"
                                    alignItems="flex-start"
                                    container
                                    // className={classes.root}
                                    spacing={2}>
                                    {renderProductImages()}
                                </Grid>

                                <br />
                                <br />
                                {reloadCanvas ? <Typography>Henter data...</Typography> :

                                    <>
                                        <PhotoCombination
                                            ref={photoCombinationRef}
                                            canvasId={'c'}
                                            enpoints={endpoints}
                                            template={data}
                                            combinedImageWidth={data.combinedImageWidth}
                                            combinedImageHeight={data.combinedImageHeight}
                                            offerId={data.offerId}
                                            // grid={10}
                                            takeSizeMutations={takeSizeMutations}
                                            correctionId={data.correctionId}
                                            excludeMotives={data.excludeMotives}
                                            maxMotives={data.maxMotives}
                                        />
                                        <Grid container spacing={2}
                                            direction="row"
                                            justify="center"
                                            alignItems="center">
                                            <Grid item xs={12} md={4}>
                                                <Button fullWidth={true} variant="contained"
                                                    color="primary" className={classes.button}
                                                    disabled={saving || loading || imagesLoaded !== imagesTotal}
                                                    onClick={handleClickSave}
                                                >
                                                    Gem og opdatér
                                                </Button>
                                            </Grid>

                                        </Grid>

                                        <Paper style={{ margin: '60px auto 20px auto', padding: 20, width: 340 }} variant="outlined" elevation={0} >
                                            <Typography variant="h5" >Genveje</Typography>
                                            <Typography variant="body1" >Dobbeltklik: Sæt billede forest</Typography>
                                            <Typography variant="body1" >Højreklik: Rotation</Typography>
                                            <Typography variant="body1" >"Del": Slet billede</Typography>
                                            <Typography variant="body1" >"C": Duplikerer billede</Typography>
                                        </Paper>
                                    </>
                                }
                            </div>
            }
        </div>
    )

}
