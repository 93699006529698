import {
  endpoints,
  apiGetParamsReplace
} from "../../../constants/services.constants";
import { getToken } from "../../../helpers/AuthHelper";
import {
  fetchPostFormDataWT,
  fetchPatchWT,
  fetchPostWTNoBodyResponse,
  fetchPostMSingleFileMultipartDataWT,
  _request
} from "../../../helpers/HttpHelper";

import { handleApiSuccessNotificaiton } from "../../Handlers/SuccessHandlers";

import Resources from "../../../Resources.json";

// OPEN UPLOAD
export const UPLOAD_OPEN_BEGIN = "PHOTO_UPLOAD_OPEN_BEGIN";
export const UPLOAD_OPEN_SUCCESS = "PHOTO_UPLOAD_OPEN_SUCCESS";
export const UPLOAD_CLOSE_BEGIN = "PHOTO_UPLOAD_CLOSE_BEGIN";

export const uploadDialogOpenBegin = (id, customerId) => ({
  type: UPLOAD_OPEN_BEGIN,
  payload: { id, customerId }
});

export const uploadDialogOpenSuccess = () => ({
  type: UPLOAD_OPEN_SUCCESS
});

export const uploadDialogCloseBegin = () => ({
  type: UPLOAD_CLOSE_BEGIN
});

export function uploadDialogOpen(id, customerId) {
  return dispatch => {
    dispatch(uploadDialogOpenBegin(id, customerId));
  };
}

export function uploadDialogRemoveId() {
  return dispatch => {
    dispatch(uploadDialogOpenSuccess());
  };
}

export function uploadDialogClose() {
  return dispatch => {
    dispatch(uploadDialogCloseBegin());
  };
}

// UPLOAD FILE
export const UPLOAD_PHOTO_BEGIN = "UPLOAD_PHOTO_FOTOTRACKERS_MESSAGE_BEGIN";
export const UPLOAD_PHOTO_SUCCESS = "UPLOAD_PHOTO_FOTOTRACKERS_MESSAGE_SUCCESS";
export const UPLOAD_PHOTO_FAILURE = "UPLOAD_PHOTO_FOTOTRACKERS_MESSAGE_FAILURE";
export const UPLOAD_PHOTO_LOWRESOLUTION = "UPLOAD_PHOTO_FOTOTRACKERS_MESSAGE_LOWRESOLUTION";

export const uploadPhotoeBegin = () => ({
  type: UPLOAD_PHOTO_BEGIN
});

export const suploadPhotoSuccess = messages => ({
  type: UPLOAD_PHOTO_SUCCESS,
  payload: { messages }
});

export const uploadPhotoeFailure = error => ({
  type: UPLOAD_PHOTO_FAILURE,
  payload: { error }
});

export const uploadPhotoResolution = error => ({
  type: UPLOAD_PHOTO_LOWRESOLUTION,
  payload: { error }
});


export function uploadImagePhotoOrder(id, statusId, overrideResolutionRequirements, form, callback) {
  return dispatch => {
    const endpoint = apiGetParamsReplace(endpoints.PHOTO_TRACKER_UPLOADPHOTO_V2,
      id, statusId, overrideResolutionRequirements);
    dispatch(uploadPhotoeBegin());

    let options = {
      Accept: "application/json, application/xml, text/plain, text/html, *.*",
      Authorization: "Bearer " + getToken(),
      "x-organization-id": Resources.Organization
    };

    return _request({
      endpoint: endpoint,
      method: "POST",
      headers: options,
      body: form,
      skipBodyParsing: true,
      sendError: false,
      throwError: false,
      callbackSucceed: () => {
        callback();
        dispatch(suploadPhotoSuccess());
      },
      callbackError: error =>{
        const str = String(error);
        if(str.includes("opløsning"))
          dispatch(uploadPhotoResolution(error));
        else
          dispatch(uploadPhotoeFailure(error));
      } 
    });
  };
}


// UPLOAD GS1 PHOTO URL
export const PHOTO_ORDER_ADD_GS1_BEGIN = "PHOTO_ORDER_ADD_GS1_MESSAGE_BEGIN";
export const PHOTO_ORDER_ADD_GS1_SUCCESS = "PHOTO_ORDER_ADD_GS1_MESSAGE_SUCCESS";
export const PHOTO_ORDER_ADD_GS1_FAILURE = "PHOTO_ORDER_ADD_GS1_MESSAGE_FAILURE";

export const uploadGs1PhotoUrlBegin = () => ({
  type: PHOTO_ORDER_ADD_GS1_BEGIN
});

export const uploadGs1PhotoUrlSuccess = messages => ({
  type: PHOTO_ORDER_ADD_GS1_SUCCESS,
  payload: { messages }
});

export const uploadGs1PhotoUrlFailure = error => ({
  type: PHOTO_ORDER_ADD_GS1_FAILURE,
  payload: { error }
});

export function updoadGs1PhotoUrl(id, url, callback) {
  return dispatch => {
    const endpoint = apiGetParamsReplace(endpoints.PHOTO_ORDER_ADD_GS1,
      id);
    dispatch(uploadGs1PhotoUrlBegin());

    let options = {
      Accept: "application/json, application/xml, text/plain, text/html, *.*",      
      "Content-Type": "application/json",
      Authorization: "Bearer " + getToken(),
      "x-organization-id": Resources.Organization
    };

    return _request({
      method: "PATCH",
      headers: options,
      endpoint: endpoint,
      skipBodyParsing: true,
      body: JSON.stringify(url),
      callbackSucceed: () => {
        callback();
        dispatch(uploadGs1PhotoUrlSuccess());
      },
      callbackError: error =>{
          dispatch(uploadGs1PhotoUrlFailure(error));
      } 
    });
  };
}