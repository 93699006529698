
import { jobNumberCustomerRelations } from "../../../../../../constants/enums";
import { getFirstTwoDigitsFromJob, showErrorNotification } from "../../../../../../helpers/UtilHelper";

export default function validateExclusiveCustomer(exclusiveCustomerIds, selectedJobNumber, selectedCustomer) {
    // Check job number starts with 
    const jobStartsWith = getFirstTwoDigitsFromJob(selectedJobNumber);

    const customerFromJobNumber = jobNumberCustomerRelations[jobStartsWith];
    let selectionIsValid = true;
    let errorMsg = "";

    if (exclusiveCustomerIds.length === 0) {
        selectionIsValid = true;
    } else if (!customerFromJobNumber) {
        errorMsg = "Valgt kunde eksisterer ikke";
        selectionIsValid = false;
    } else if (parseInt(selectedCustomer) !== customerFromJobNumber.id) {
        errorMsg = "Job nummer skal være fra den samme kunde";
        selectionIsValid = false;
    } else if (!isAllowedSetExclusiveCustomer(exclusiveCustomerIds, customerFromJobNumber.id)) {
        errorMsg = "Original kunde skal vælges som eksklusivt";
        selectionIsValid = false;
    }

    return {
        selectionIsValid, errorMsg
    }
}

export function checkJobMatchSelectedCustomer(jobNo, customerId) {
    let isMatch = true;
    const jobStartsWith = getFirstTwoDigitsFromJob(jobNo);
    const customerFromJobNumber = jobNumberCustomerRelations[jobStartsWith];

    if (customerFromJobNumber) {
        isMatch = customerFromJobNumber.id !== parseInt(customerId) ? false : true;
    }
    return isMatch;
}

export function isAllowedSetExclusiveCustomer(exclusiveCustomerIds, customerId) {
    return exclusiveCustomerIds.includes(customerId);
}

export function handleValidationOnSetExclusive(ids, selectedCustomer) {
    let isValid = true;
    const selectionIsValid = isAllowedSetExclusiveCustomer(ids, selectedCustomer);
    if (!selectedCustomer || selectedCustomer === "") {
        isValid = false;
        showErrorNotification("Du skal først vælge kunde");
    } else if (ids.length === 0) {
        isValid = true;
    } else if (!selectionIsValid) {
        isValid = false;
        showErrorNotification("Du må ikke tilføje billedet til denne publikation");
    }
    return isValid;
}