import React, { Component } from "react";

import { connect } from "react-redux";

import Card from "@material-ui/core/Card";
import CardMedia from "@material-ui/core/CardMedia";

import {
  endpoints,
  apiGetParamsReplace
} from "../../../../constants/services.constants";

import{
  getMotivId, getShortDate
} from "../../../../helpers/UtilHelper";
import {
  CardContent,
  Typography,
  CardActions,
  Divider,
  Button
} from "@material-ui/core";
import { withStyles } from "@material-ui/styles";
import { orange } from "@material-ui/core/colors";

const styles = theme => ({
  card: {
    width: 150,
    maxWidt: 150,
    minWidth: 150,
    marginLeft: 15,
    marginRight: 15,
    boxShadow: "none",
    float: "left"
  },
  media: {
    objectFit: "contain",
    backgroundSize: "contain",
    height: 150,
    width: 150,
    margin: "0 auto"
  },
  searchHeight: {
    height: 265
  },
  cardList: {
    maxHeight: 280,
    overflow: "auto"
  },
  cardContent: {
    minHeight: 138,
    textAlign: "center"
  }
});
class ProductItem extends Component {
  render() {
    const { product, classes } = this.props;
    const { selectedproduct } = this.props.fototrackerReducer;

    return (
      <>
        <Card className={classes.card} key={product.id}>
          <CardMedia
            component="img"
            className={classes.media}
            image={apiGetParamsReplace(endpoints.PHOTO_SINGLE_200, [
              product.multiPhotoId
            ])}
          />
          <CardContent className={classes.cardContent}>
            <Typography
              variant="subtitle2"
              gutterBottom
              className="max3lines"
              style={{ fontSize: "0.875rem", fontWeight: "bold" }}
            >
              {product.name}
            </Typography>
            {product.futurePhotoDate ?
              <div style={{ color: orange[500] }}>
                Gyldig fra {getShortDate(product.futurePhotoDate)}
              </div>
              : undefined
            }
            <Typography variant="caption" style={{ fontSize: "0.575rem" }}>
              <div style={{ fontWeight: "600" }}>Produkt nummer:</div>
              <div>
                {product.productNumber}
              </div>
            </Typography>

            <Typography variant="caption" style={{ fontSize: "0.575rem" }}>
              <div style={{ fontWeight: "600" }}>Motiv-ID:</div>
              <div>
                {getMotivId(product.multiPhotoId)}
              </div>
            </Typography>
          </CardContent>

          {/* <Divider /> */}
          <CardActions
            style={{
              display: "inherit",
              textAlign: "center",
              paddingTop: "0px",
              paddingBottom: "0px"
            }}
          >
            {selectedproduct && product && selectedproduct.multiPhotoId === product.multiPhotoId ? (
                <Button
                color="secondary"
                  variant="contained"
                disabled={!product.futurePhotoDate ? false : true}
              >
                Vælg billede
              </Button>
            ) : (
              <Button
                color="primary"
                variant="contained"
                onClick={() => this.props.handleAddProduct(product)}
                  disabled={!product.futurePhotoDate ? false : true}
              >
                Vælg billede
              </Button>
            )}
          </CardActions>
        </Card>
      </>
    );
  }
}

export default connect(state => state)(withStyles(styles)(ProductItem));
