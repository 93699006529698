import React from 'react'
import { Button } from '@material-ui/core';
import { Typography } from '@material-ui/core';
import { useState } from 'react';
import { useEffect } from 'react';
import { searchOffers, searchOffersForTemplate, validateRequiredFields } from '../businessLogic/SearchOffers';
import { styles } from '../../shared';
import SearchOfferField from './SearchOfferField';
import DataTable from './DataTable';
import { LoadingLogo } from "../../../../../shared/Loading";
import ActionButton from './button/ActionButton';
import { useStyles } from '../../Screen';
import { showErrorNotification } from '../../../../../../helpers/UtilHelper';
import BackButton from '../../sharedComponents/BackButton';


export default function AddOfferMain({ 
    tilbage, 
    offerSelection, 
    onSelectOffer, 
    customer 
}) {
    const [searchText, setSearchText] = useState("");
    const [pageNumbers, setPageNumbers] = useState("");
    const [offers, setOffers] = useState([]);
    const [loading, setLoading] = useState(false);
    const classes = useStyles();

    useEffect(() => {
    }, []);

    const onSearch = async () => {
        if (!validateRequiredFields(searchText, pageNumbers)) {
            showErrorNotification("Job nummer og side være udfyldt.");
            return;
        }
        setLoading(true);
        const data = {
            searchText: searchText,
            pageNumbers: pageNumbers
        }
        const searchResult = await searchOffersForTemplate(data);
        const offers = searchResult.rows;
        setOffers(offers);
        setLoading(false);
    }

    return (
        <div>
            <div style={{ width: '100%', display: 'inline-flex', justifyContent: 'space-between' }}>
                <Typography variant="h4" style={styles.viewText}>Tilføj tilbud</Typography>
                <BackButton
                    tilbage={()=>tilbage()}
                />
            </div>
            <div style={{
                display: "grid",
                gridTemplateColumns: "repeat(5, 1fr)",
                gridColumnGap: "4em"
            }}>
                <SearchOfferField
                    placeHolder="Jobnummer"
                    onChange={(value) => setSearchText(value)}
                    value={searchText}
                    onKeyDown={(e) => e.key === 'Enter' ? onSearch() : undefined}
                />
                <SearchOfferField
                    placeHolder="Sidetal"
                    onChange={(value) => setPageNumbers(value)}
                    value={pageNumbers}
                    onKeyDown={(e) => e.key === 'Enter' ? onSearch() : undefined}
                />
                <div>
                    <ActionButton
                        text="Søg"
                        onClick={() => onSearch()}
                        className={classes.buttonSearch}

                    />
                </div>
            </div>
            {offers.length === 0 && !loading ?
                <div
                    style={{
                        color: "#6b8778",
                        marginTop: 30
                    }}>
                    Ingen tilbud ...
                </div> : loading ?
                <LoadingLogo /> :
                <DataTable
                    offers={offers}
                    onAdd={(offerId) => onSelectOffer(offerId)}
                />}

        </div>
    )
}
