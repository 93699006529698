import React, { useEffect, useState } from "react";
import { withStyles } from "@material-ui/core/styles";
import { Grid, InputBase, Paper } from "@material-ui/core";
import TabSearchOfferSlider from "./TabSearchOfferSlider";
import { searchPhotosWithoutFilters } from "../../sections/photos/Requests";
import { activePhotoFutureDate } from "../../../helpers/UtilHelper";
import Pagination from "material-ui-flat-pagination";
import SearchBarWithAutoSuggest from "../components/searchBarTags/searchBar/SearchBarWithAutoSuggest";

const styles = {
  root: {
    padding: "2px 4px",
    display: "flex",
    alignItems: "center",
    width: "100%",
    marginBottom: 20
  },
  input: {
    marginLeft: 20,
    marginRight: 20,
    flex: 1
  },
  iconButton: {
    padding: 10
  },
  divider: {
    width: 1,
    height: 28,
    margin: 4
  }
};

function TabSearchOffer(props) {
  const { classes, handleAddOfferPicture, customerId, isLoading, photoTagsAutoSuggest } = props;
  const rowsPerPage = 100;
  const [loading, setLoading] = useState(false);
  const [searchText, setSearchText] = useState("");
  const [tagsSearchArray, setTagsSearchArray] = useState([]);
  const [searchRowOffset, setSearchRowOffset] = useState(0);
  const [totalRows, setTotalRows] = useState(0);
  const [data, setData] = useState([]);

  useEffect(() => {
    performSearch();
  }, [tagsSearchArray]);

  const keyPress = e => {
    if (e.keyCode === 13) {
      performSearch(0);
    }
  };

  const handleChangeTagsText = (value) => {
    setTagsSearchArray(value);
  };

  const performSearch = async (offset) => {
    if (!searchText && !tagsSearchArray) return;
    setLoading(true);
    const searchResult = await searchPhotosWithoutFilters(searchText, tagsSearchArray, rowsPerPage, offset);
    const data = searchResult.rows.map(x => {
      const product = x.products[0];
      return {
        productName: product ? product.name : "-",
        productNumber: product ? product.productNumber : "-",
        futurePhotoDate: activePhotoFutureDate(x.validFrom),
        deleteDate: x.validTo != null ? x.validTo : null,
        photoId: x.id,
        status: x.status,
        validTo: x.validTo,
        photoCreated: x.created,
        exclusiveForCustomerIds: x.exclusiveForCustomerIds
      }
    });

    setLoading(false);
    setData(data);
    setSearchRowOffset(offset);
    setTotalRows(searchResult.totalRows);
  };

  return (
    <>
      <div
        style={{
          margin: "20px 0px",
          display: "grid",
          gridTemplateColumns: "1fr 1fr",
          columnGap: 10
        }}
      >
        <Paper className={classes.root} elevation={1}>
          <br />
          <br />
              <InputBase
                autoFocus
                className={classes.input}
                placeholder="Søg billede..."
                onKeyDown={keyPress}
                onChange={e => setSearchText(e.target.value)}
                value={searchText}
              />
        </Paper>
        <SearchBarWithAutoSuggest
          autoSuggestTags={photoTagsAutoSuggest}
          queryArray={tagsSearchArray}
          handleChangeTagsSearchArray={(value) => handleChangeTagsText(value)}
        />
      </div>
      {totalRows > rowsPerPage ?
        <Pagination
          limit={rowsPerPage}
          offset={searchRowOffset}
          total={Math.min(totalRows, 100 * rowsPerPage)}
          onClick={(event, offset) => performSearch(offset)}
          centerRipple={true}
          style={{ textAlign: "center" }}
        /> : undefined
      }


      <TabSearchOfferSlider
        handleAddOfferPicture={handleAddOfferPicture}
        data={data}
        isLoadingSearch={loading}
        isLoading={loading || isLoading}
        customerId={customerId}
      />
    </>
  );
}

export default withStyles(styles)(TabSearchOffer);
