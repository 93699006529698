import React from 'react';
import PropTypes from 'prop-types';
import CssBaseline from '@material-ui/core/CssBaseline';
import { withStyles } from '@material-ui/core/styles';
import AppRoutes from '../../routes/AppRoutes';
import { Router } from 'react-router-dom';
// import {  BrowserRouter as Router,} from 'react-router-dom';
import { deleteUserAndTokenInStorage } from '../../helpers/AuthHelper';
import ContentErrorBoundary from '../ErrorBoundaries/ContentErrorBoundary';
import AppSnackbar from './AppSnackbar';
import AppBarMenu from './AppBarMenu';
import AppNavDrawer from './AppNavDrawer';
import AppLayoutCss from './AppLayoutCss';
import AppBarNotifications from './AppBarNotifications';
import { connect } from 'react-redux';
import { fetchNotifications } from '../../redux/actions/notifications.actions';
import { appConstants } from '../../constants/app.constants';
import AppBarParent from './AppBarParent';
import history from '../../routes/history';
import DialogAllNotifications from './dialogs/DialogAllNotifications';

class AppLayout extends React.Component {
  state = {
    mobileOpen: false,
    anchorEl: null,
    anchorEl2: null,
    dialogAllNotifications: false
  };

  async componentDidMount() {
    this.props.dispatch(fetchNotifications());
    // This code is commented out for testing purposes
    // try {
    //   setInterval(async () => {
    //     if (this.props.notificationsReducer.error == null)
    //       this.props.dispatch(fetchNotifications());
    //   }, appConstants.NOTIFICATIONS_INTERVAL);
    // } catch (e) {
    //   console.log(e);
    // }
  }

  handleDrawerToggle = () => {
    this.setState(state => ({ mobileOpen: !state.mobileOpen }));
  };

  handleMenuOpen = event => {
    this.setState({ anchorEl: event.currentTarget });
  };

  handleMenuClose = () => {
    this.setState({ anchorEl: null });
  };

  handleNotificationsOpen = event => {
    this.setState({ anchorEl2: event.currentTarget });
  };

  handleNotificationsClose = () => {
    this.setState({ anchorEl2: null });
  };

  handleMenuLogout = () => {
    deleteUserAndTokenInStorage();
    window.location.reload();
  };

  handleMenuSupport = () => {
    //the link is being taken care of in the component, it however doesn't close the menu.
    this.handleMenuClose();
  }

  render() {
    const { classes, theme } = this.props;
    const { anchorEl, anchorEl2 } = this.state;
    const isMenuOpen = Boolean(anchorEl);
    const isNotificationsOpen = Boolean(anchorEl2);
    const { error, loading, notifications } = this.props.notificationsReducer;

    return (
      <>
      <Router history={history}>
        <div className={classes.root}>
          <CssBaseline />
          <AppBarParent
            classes={classes}
            isNotificationsOpen={isNotificationsOpen}
            loading={loading}
              notifications={notifications}
            isMenuOpen={isMenuOpen}
            handleMenuOpen={this.handleMenuOpen}
            handleDrawerToggle={this.handleDrawerToggle}
            handleNotificationsOpen={this.handleNotificationsOpen}
          />
          <AppBarMenu
            anchorEl={anchorEl}
            open={isMenuOpen}
            handleMenuClose={this.handleMenuClose}
            handleMenuLogout={this.handleMenuLogout}
            handleMenuSupport={this.handleMenuSupport} />

          <AppBarNotifications
            anchorEl={anchorEl2}
            open={isNotificationsOpen}
            handleNotificationsClose={this.handleNotificationsClose}
              notifications={notifications.slice(0, 10)}
            error={error}
            loading={loading}
              openDialogAllNotifications={() => this.setState({ dialogAllNotifications: true, })}
          />

          <AppNavDrawer
            classes={classes}
            container={this.props.container}
            mobileOpen={this.state.mobileOpen}
            handleDrawerToggle={this.handleDrawerToggle}
            theme={theme} />


          <main className={classes.content}>
            <div className={classes.toolbar} />
            <ContentErrorBoundary>
              <AppRoutes />
            </ContentErrorBoundary>
            <AppSnackbar />
          </main>

        </div>
      </Router>
        {this.state.dialogAllNotifications ?
          <DialogAllNotifications
            notifications={notifications}
            handleClose={() => this.setState({ dialogAllNotifications: false })}
          /> : undefined}
      </>
    );
  }
}

AppLayout.propTypes = {
  classes: PropTypes.object.isRequired,
  theme: PropTypes.object.isRequired,
};

AppLayout = connect(state => state)(AppLayout);
export default withStyles(AppLayoutCss, { withTheme: true })(AppLayout);