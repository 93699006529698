import { getPublicationByOfferIds } from "../../../Requests";
import {
    addProduct
} from "../../../../../../redux/actions/offer/offers.actions";
import { canReadApplication } from "../../../../../../helpers/CredentialsHelper";
import { applications } from "../../../../../../constants/app.constants";
import { offerDefinitionsEnum, offerType } from "../../../../../../constants/enums";

export async function unwrapOfferToState(offer, publicationName, setState, state, store, offerSegmentsResponse, offerRankingsResponse) {

    const price = offer.prices.price ?? 0;
    const normalPrice = offer.prices.normalPrice ?? 0;
    const offerPriceField = offer.offerPriceQuantityDescription === "Pris" ? "price" : "normalPrice";

    if (!offer.fieldValues.some(x => x.name === "layoutbemaerkning")) {
        offer.fieldValues.push({
            id: 0,
            name: "layoutbemaerkning",
            value: "",
        });
    }

    const _selectedPhoto = offer.products.length > 0 ? offer.products[0].selectedPhotoId : offer.childOffers.length > 0 ?
        offer.childOffers[0].selectedPhotoId : undefined;

    setState({
        ...state,
        offerId: offer.id,
        description: offer.description,
        validFrom: offer.validFrom,
        validTo: offer.validTo,
        normalPrice: normalPrice,
        offerPriceField: offerPriceField,
        price: price,
        fieldValues: offer.fieldValues,
        offerTypeId: offer.type ? offerType[offer.type].id : null,
        offerDefinitionId: offer.definition ? offerDefinitionsEnum[offer.definition].id : null,
        selectedImage: _selectedPhoto,
        customer: offer.customerId,
        tags: offer.tags,
        offerPublicationName: publicationName,
        canEditPrices: canReadApplication(applications.REDIGERTILBUDSPRIS),
        offerSegments: offerSegmentsResponse,
        offerRankings: offerRankingsResponse
    });
}