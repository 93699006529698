import React from 'react';
import { Typography, FormControl, InputLabel, Select, MenuItem, FormHelperText, Checkbox } from '@material-ui/core';
import { photoTypeEnum, photoAngleEnum, photoOrderTypeEnum } from '../../../constants/enums';
import { filterEnumOnValues } from '../../../helpers/EnumHelper';


// Currently just using the most complex form inputs.


export function CustomerSelect(props) {
  if (props.utilsReducer) {
    if (props.utilsReducer.customers) {
      let credentials = props.credentialsReducer.me;
      var phototrackerCred = credentials.filter(credential => credential.permissionName === 'Fototracker').map(c => c.multiCustomerId);
    }
  }

  return (props.credentialsReducer.error != null ? (
    <Typography variant="p">
      {props.credentialsReducer.error}
    </Typography>
  ) : (props.credentialsReducer.loading || props.utilsReducer.loadingCustomers) ||
    (props.credentialsReducer.loading == null || props.utilsReducer.loadingCustomers == null) ? (
    <Typography variant="p">Loading</Typography>
  ) : (
    // input
    <FormControl fullWidth>
      <InputLabel
        htmlFor="multiCustomerId-id"
        shrink={props.multiCustomerId !== undefined && props.multiCustomerId.length !== 0}>
        Kunde
            </InputLabel>
      <Select
        fullWidth
        value={props.multiCustomerId}
        id="selectorPhotoOrderAddCustomer"
        onChange={props.onChange}
            disabled={props.disabled}
        inputProps={{
          name: "multiCustomerId",
          id: "multiCustomerId-id"
        }}
            error={props.validation.kundeErrorMessage && props.validation.kundeErrorMessage.length > 0}
      >
        {
          props.utilsReducer.customers.filter(f => phototrackerCred.includes(f.id)).map(
            (item) => (
              <MenuItem key={item.id} value={item.id} className='selectorPhotoOrderAddCustomerItems'>
                {item.name}
              </MenuItem>
            )
          )

        }
      </Select>
          <FormHelperText error={props.validation.kundeErrorMessage && props.validation.kundeErrorMessage.length > 0}>
        {props.validation.kundeErrorMessage}
      </FormHelperText>
    </FormControl>
  ));
}

export function PhotoAngleSelect(props) {
  const angles = ["Front", "Left", "Top", "Back", "Right", "Bottom",];
  return <>
    <FormControl fullWidth>
      <InputLabel htmlFor="multiPhotoTypeId-id">
        Fotovinkel
            </InputLabel>
      <Select
        fullWidth
        disabled={props.disabled}
        value={props.multiPhotoAngleId}
        id="selectPhotoOrderAnglePhotoAngle"
        onChange={(e) => props.handleSelectAngle(e.target.value)}
        inputProps={{
          name: "multiPhotoAngleId",
          id: "multiPhotoAngleId-id"
        }}
      >
        {
          angles.map(x => (
            <MenuItem key={photoAngleEnum[x].id} value={photoAngleEnum[x].id} className='selectPhotoOrderAnglePhotoAngles'>
              {photoAngleEnum[x].name}
            </MenuItem>
          )
          )
        }
      </Select>
      <FormHelperText error={false}>
      </FormHelperText>
    </FormControl>
  </>
}

export function PhotoTypeSelect(props) {
const {onChange, multiPhotoParentTypeId, validation, disabled} = props;
  return (
    // input
    <FormControl fullWidth>
      <InputLabel htmlFor="multiPhotoTypeId-id">
        Foto type
      </InputLabel>
      <Select
        fullWidth
        value={multiPhotoParentTypeId}
        onChange={onChange}
        id="selectorPhotoOrderPhotoType"
        inputProps={{
          name: "multiPhotoTypeId",
          id: "multiPhotoTypeId-id"
        }}
        error={validation.photoTypeErrorMessage && validation.photoTypeErrorMessage.length > 0}
        helperText={validation.photoTypeErrorMessage}
        disabled={disabled}
      >
        <MenuItem value={photoTypeEnum.Pack.id} className='selectorPhotoOrderPhotoTypes'>
          {photoTypeEnum.Pack.name}
        </MenuItem>
        <MenuItem value={photoTypeEnum.Environment.id} className='selectorPhotoOrderPhotoTypes'>
          {photoTypeEnum.Environment.name}
        </MenuItem>
      </Select>
    </FormControl>
  )
}

export function PhotoOrderTypeSelect(props) {
  const { onChange, photoParentTypeId, photoOrderTypeId, disabled } = props;
  const types = filterEnumOnValues(photoOrderTypeEnum, x => x.photoType.id === photoParentTypeId);


  return <FormControl fullWidth>
    <InputLabel htmlFor="multiPhotoTypeId-id">
      Foto definition
            </InputLabel>
    <Select
      fullWidth
      value={photoOrderTypeId}
      onChange={onChange}
      id="selectorPhotoOrderPhotoDefinition"
      inputProps={{
        name: "multiPhotoTypeId",
        id: "multiPhotoTypeId-id"
      }}
      displayEmpty={true}
      disabled={disabled}
    >
      {
        types.map(x =>
          <MenuItem key={photoOrderTypeEnum[x].id} value={photoOrderTypeEnum[x].id} className='selectorPhotoOrderPhotoDefinitions'>
            {photoOrderTypeEnum[x].name}
          </MenuItem>
        )
      }
    </Select >
  </FormControl >
}

export function PhotoPrioritySelect(props) {

  return (
    <FormControl fullWidth>
      <InputLabel htmlFor="prioprity">
        Prioritet
            </InputLabel>
      <Select
        fullWidth
        value={props.priority}
        onChange={props.onChange}
        disabled={props.disabled}
      >
        <MenuItem value={0}>
          Normal
        </MenuItem>
        <MenuItem value={1}>
          Haster
        </MenuItem>
        <MenuItem value={2}>
          Super haster
        </MenuItem>

      </Select>
    </FormControl>
  );
}

export function CategorySelect({ categories, handleSelectCategory, selectedCategory, disabled, validation }) {
  return (
    <FormControl fullWidth>
      <InputLabel htmlFor="prioprity">
        Kategori
        </InputLabel>
      <Select
        fullWidth
        value={selectedCategory}
        onChange={(e) => handleSelectCategory(e)}
        disabled={disabled}
        id="selectorPhotoOrderPhotoCategory"
      >
        {!categories ? undefined :
          categories.filter(category => category !== null && category !== "").map(category =>
          (
            <MenuItem key={category.id} value={category.id} className='selectorPhotoOrderPhotoCategories'>
              {category.name}
            </MenuItem>
          ))
        }
      </Select>
      <FormHelperText error={validation.categoryErrorMessage && validation.categoryErrorMessage.length > 0}>
        {validation.categoryErrorMessage}
      </FormHelperText>
    </FormControl>
  )
}

export function Clipping({ fritlaegges, handleFritlaeggesChange }) {
  return (
    <>
      <Checkbox
        checked={fritlaegges}
        onChange={handleFritlaeggesChange}
        inputProps={{
          name: "fritlaegges",
          id: "fritlaegges-id",
        }}
      />
      <InputLabel htmlFor="fritlaegges-id" shrink={true}>
        Fritlægges
              </InputLabel>
    </>
  )
}

export function MakeDigitalPack({ createDigitalPackOrder, handleDigipackChange }) {
  return (
    <>
      <Checkbox
        checked={createDigitalPackOrder}
        onChange={handleDigipackChange}
        inputProps={{
          name: "createDigiPackId",
          id: "createDigiPack-id",
        }}
      />
      <InputLabel htmlFor="createDigiPack-id" shrink={true}>
        Lav Digital Pack
  </InputLabel>
    </>
  )
}