import React, { useEffect, useState } from 'react'
import { Dialog, DialogTitle, DialogContent, DialogActions, Button, LinearProgress, Typography } from '@material-ui/core'
import { getOffersUsingPhoto, getPublicationsForOffers } from '../Requests';
import createDialog from '../../../DialogRenderer';
import { getMotivId } from '../../../helpers/UtilHelper';

export async function openDialogPhotoUsages(photoId) {
  var result = createDialog(({ show, onSubmit, onDismiss }) => (
    <DialogPhotoUsages photoId={photoId}
      open={show}
      onCancel={onDismiss}
      onConfirm={onSubmit}
    />
  ));
  return result;
}

export default function DialogPhotoUsages({ photoId, open, onCancel, onConfirm }) {
  const [data, setData] = useState(null);
  const [dataLoading, setDataLoading] = useState(false);
  const [dataError, setDataError] = useState(null);

  useEffect(() => {
    loadData();
  }, [])

  const loadData = async () => {
    try {
      setDataLoading(true);
      let publications = [];
      const offers = await getOffersUsingPhoto(photoId);
      if (offers.length > 0) {
        publications = await getPublicationsForOffers(offers.map(x => x.id));
      }
      setData(publications);
    } catch (error) {
      setDataError(error);
    } finally {
      setDataLoading(false);
    }
  }

  return (
    <Dialog open={open} onClose={onCancel}>
      <DialogTitle>
        {getMotivId(photoId)} har været brugt i
      </DialogTitle>
      <DialogContent
        style={{ maxHeight: 700, minHeight: 600, width: 500 }}
      >
        {dataLoading ? <LinearProgress />
          : dataError != null ? <Typography>Der opstod en fejl : {dataError}</Typography>
            : data == null || data.length === 0 ? <Typography>Ingen publikationer fundet</Typography>
              : <table><tbody>
                {data.map(d => {
                  return (<tr key={d.jobId}>
                    <td>{d.jobId}</td>
                    <td>-</td>
                    <td>{d.name}</td>
                  </tr>)
                })}
              </tbody></table>
        }
      </DialogContent>
      <DialogActions>
        <Button onClick={onCancel}> Luk </Button>
      </DialogActions>
    </Dialog>
  )
}
