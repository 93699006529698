
import React from 'react';
import { services } from '../../../../constants/services.constants';

export default function SpreadPage({ publicationId, item, onClick, existingPdfs }) {
    const pdfExists = existingPdfs.has(item);
    const result = pdfExists ?
        pdfPagePreview(publicationId, item, onClick)
        : pfdPagePreviewPlaceholder()

    return result;

}

const pdfPagePreview = (publicationId, item, onClick) => {
    return (
        <div
            style={{
                width: 55,
                height: 80,
                backgroundImage: `url(${services.PUBLICATION_PUBLIC}/publications/${publicationId}/pdfs/${item}.png?key=loxnp2wy32fgreda4gxehhchzdjbcdrw6nb&th=true)`,
                backgroundPosition: "center",
                backgroundSize: "cover",
                backgroundRepeat: "no-repeat",
                backgroundColor: "#eceff1"
            }}
            onClick={onClick}></div>
    )

}

const pfdPagePreviewPlaceholder = () => {
    return (
        <div
            style={{
                width: 55,
                height: 80,
                backgroundColor: "#eceff1",
                border: "1px solid #000000"
            }}></div>
    )
}