import React, { useState } from "react";
import Chip from "@material-ui/core/Chip";
import TextField from "@material-ui/core/TextField";
import Autocomplete from "@material-ui/lab/Autocomplete";


import { handleApiSuccessNotificaiton } from "../../../redux/Handlers/SuccessHandlers";
import { searchProducts } from "../produkstyring/Requests";

export default function AutoSuggestionWithChipApi(props) {
  const filterData = props.gtiNs.filter((m) => m.IsDeleted == false);
  const [value, SetValue] = React.useState([...props.gtiNs]);
  const [search, SetSearch] = useState("");
  const [suggestions, setSuggestions] = React.useState([]);

  const getSuggestions = async (searchText) => {
    if (search !== null) {

      const searchRequest = {
        searchText: searchText,
        startRow: 0,
        numberOfRows: 20
      };
      const searchResult = await searchProducts(searchRequest);
      setSuggestions(searchResult.rows.map(x => {
        return {
          id: x.id,
          name: x.name,
          productNumber: x.productNumber,
          numberOfPhotos: x.photoIds.length
        }
      }));
    }
  };

  const handleAddTag = async (selectedProducts) => {

    const newProduct = selectedProducts.find(x => !value.includes(x));

    const newEan = {
      name: newProduct.name ?? "",
      productId: newProduct.id,
      productNumber: newProduct.productNumber,
      photoId: props.MotiveId,
      isDefault: false,
      IsNewAdded: true,
      IsDeleted: false,
      noOfPhotoWithProduct: newProduct.numberOfPhotos
    };
    await props.handleAddEans(newEan);
    SetValue([...props.gtiNs, newEan]);
  }


  const handleDeleteGTIN = async (product) => {
    if (product.IsNewAdded === false) {
      product.noOfPhotoWithProduct > 1
        ? await props.handleDeleteEans(product.productNumber)
        : handleApiSuccessNotificaiton(
          "Det er ikke muligt at slette fotoet, da det bruges som standardbillede på et produkt"
        );
    } else {
      await props.handleDeleteEans(product.productNumber);
    }
  };

  return (
    <>
      <Autocomplete
        closeIcon={false}
        multiple
        id="fixed-tags-demo"
        value={value}
        onChange={(event, newTag) => handleAddTag(newTag)}
        options={suggestions}
        getOptionLabel={(suggestion) =>
          suggestion.name + "(" + suggestion.productNumber + ")"
        }
        onInputChange={(event, newInputValue) => {
          SetSearch(newInputValue);
          if (newInputValue.length > 3)
            getSuggestions(newInputValue);
          else
            setSuggestions([]);
        }}
        renderTags={(tagValue, getTagProps) =>
          filterData.map((option, index) => (
            <>
              {option.IsDeleted !== null || option.IsDeleted === false ? (
                <Chip
                  label={option.name + " " + "(" + option.productNumber + ")"}
                  {...getTagProps({ index })}
                  disabled={filterData.length === 1}
                  onDelete={() =>
                    handleDeleteGTIN(
                      option,
                      option.mProNumber,
                      option.noOfPhotoWithProduct
                    )
                  }
                />
              ) : (
                ""
              )}
            </>
          ))
        }
        //style={{ width: 500 }}
        renderInput={(params) => (
          <TextField
            {...params}
            label="EAN (GTIN)"
            variant="outlined"
            placeholder="Søg Produkt"
          />
        )}
      />
    </>
  );
}
