import Autocomplete from "@material-ui/lab/Autocomplete";
import TextField from "@material-ui/core/TextField";
import React from "react";
const AutoSuggestion = ({
  dataSource,
  displayIdField = null,
  displayTitleField,

  multiple = false,
  inputtitle = "",
  placeholder,
  variant = "standard",
  onChange,
  error,
  helperText,
value,
getOptionSelected,
  margin = "normal",
}) => {
  //Set Value or Id to your Main Method(Selected Value Set to Callers Class Properties)
  const onhandleChange = (event, data) => {
    data !== null ? onChange(data) : onChange("");
  };

  return (
    <>
      <Autocomplete
      getOptionSelected={getOptionSelected}
        multiple={multiple}
        options={dataSource}
        getOptionLabel={(option) =>
          displayIdField == null
            ? option[displayTitleField]
            : option[displayIdField] + " " + option[displayTitleField]
        }
        className="selectionGenericAutoSuggestions"
        onChange={onhandleChange}
        value={value}
        renderInput={(params) => (
          <TextField
            {...params}
            variant={variant}
            label={inputtitle}
            // placeholder={placeholder}
            margin={margin}
            fullWidth
            type="text"
            error={error}
            helperText={helperText}
          />
        )}
      />
    </>
  );
};

export default AutoSuggestion;
