import { Button } from '@material-ui/core';
import React, { useState } from 'react';
import { doLogIn } from './businessLogic/Logic';
import { LogInError } from './components/LogInError';
import { LogInForm } from './components/LogInForm';
import { LogInHeader } from './components/LogInHeader';
import StyledButtonLogIn from './components/StyledButton';
import styled from "styled-components";

const ForgotPasswordButton = styled(Button)`
margin-top: 24px;
text-transform: none
`;

function LogInLayout(props) {
    const { setForgotPassWord } = props;
    const [error, setError] = useState(undefined);
    const [loading, setLoading] = useState(false);
    const [userName, setUserName] = useState("");
    const [passWord, setPassWord] = useState("");

    const loginText = ["Velkommen til", "MultiBasen"];
    function onLogin(event) {
        event.preventDefault();
        doLogIn(userName, passWord, setError, setLoading)
    }

    function onKeyPress(e) {
        if (e.keyCode === 13) {
            doLogIn(userName, passWord, setError, setLoading);
        }
    }

    return (
        <>
            <LogInHeader
                text={loginText}
            />
            {error ?
                <LogInError
                    errorText={error}
                /> : undefined
            }
            <LogInForm
                classes={props.classes}
                userName={userName}
                passWord={passWord}
                setUserName={(e) => setUserName(e.target.value)}
                setPassWord={(e) => setPassWord(e.target.value)}
                onKeyPress={(e) => onKeyPress(e)}

            />
            {loading ?
                (
                    <StyledButtonLogIn
                        fullWidth
                        variant="contained"
                        color="primary"
                        disabled={true}
                        buttonText=" Øjeblik..."
                    />
                ) : (
                    <>
                        <StyledButtonLogIn
                            type="submit"
                            fullWidth
                            variant="contained"
                            color="primary"
                            handleOnClick={(e) => onLogin(e)}
                            buttonText="Log ind"
                        />
                        <ForgotPasswordButton
                            variant="text"
                            onClick={() => setForgotPassWord(true)}

                            >
                                Har du glemt dit kodeord?
                        </ForgotPasswordButton>
                    </>
                )}
        </>
    )
}

export default LogInLayout;