import React, { useState, useEffect } from "react";
import { fetchCollections, collectionCountFeeds } from "../collections/Logic/requests";

export const CollectionsContext = React.createContext({ collections: [], confirmDelete: false, selectedCollections: [], loading: false, collectionFeeds: new Map() });

const Index = ({ children }) => {
    const [collections, setCollections] = useState([]);
    const [confirmDelete, setConfirmDelete] = useState(false);
    const [selectedCollections, setSelectedCollections] = useState([]);
    const [loading, setLoading] = useState(false);
    const [collectionFeeds, setCollectionFeeds] = useState(new Map());

    useEffect(() => {
        const runEffect = async () => {
            setLoading(true);

            const collectionsArr = await fetchCollections();

            if (!collectionsArr) {
                setLoading(false);
                setCollections([]);
                return;
            }

            setCollections(collectionsArr);

            let collectionFeedsMap = await getCollectionFeeds(collectionsArr);
            setCollectionFeeds(collectionFeedsMap);
            setLoading(false);

        };
        runEffect();
    }, []);

    const getCollectionFeeds = async (collectionsArr) => {
        let collectionFeedsMap = new Map();

        await Promise.all(collectionsArr.map(async (x) => {
            let feedsNumber = await collectionCountFeeds(x.collectionId);
            collectionFeedsMap.set(x.collectionId, feedsNumber)
        }
        ));

        return collectionFeedsMap;
    }

    return (
        <CollectionsContext.Provider
            value={{
                collections: [collections, setCollections],
                confirmDelete: [confirmDelete, setConfirmDelete],
                selectedCollections: [selectedCollections, setSelectedCollections],
                loading: [loading, setLoading],
                collectionFeeds: [collectionFeeds, setCollectionFeeds],
            }}>
            {children}
        </CollectionsContext.Provider>
    )
}

export default Index;