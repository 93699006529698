import React, { useState } from 'react';
import { LogInHeader } from '../logIn/components/LogInHeader';
import { handleChangePassWord, renderValidationMessage } from './BusinessLogic';
import ChangePassWordForm from './ChangePassWordForm';
import PasswordWasChanged from './PasswordWasChanged';

export default function ResetPassWordV2(props) {
    const [newPassWord, setNewPassWord] = useState("");
    const [newPassWordRepeat, setNewPassWordRepeat] = useState("");

    const [loading, setLoading] = useState(false);
    const [validationSuccess, setValidationSuccess] = useState(false);
    const [validationMessage, setValidationMessage] = useState(undefined);
    const { code, username } = props.match.params;

    const headerText = ["Skift password"];

    return (
        <>
            {!validationSuccess ? (
                <>
                    <LogInHeader
                        text={headerText}
                    />
                    <ChangePassWordForm
                        newPassWord={newPassWord}
                        setNewPassWord={(value) => setNewPassWord(value)}
                        newPassWordRepeat={newPassWordRepeat}
                        setNewPassWordRepeat={(value) => setNewPassWordRepeat(value)}
                        loading={loading}
                        handleChangePassWord={() =>
                            handleChangePassWord(newPassWord, newPassWordRepeat,
                                setValidationMessage, setValidationSuccess, username, code, setLoading)}
                    />
                    {renderValidationMessage(validationMessage)}
                </>

            ) : <PasswordWasChanged
                validationMessage={validationMessage}
            />}
        </>
    )
}