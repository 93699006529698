// OPEN PERMITS FORM
export const PERMITS_OPEN = "USER_PERMITS_OPEN";
export const PERMITS_CLOSE = "USER_PERMITS_CLOSE";

export const permitsDialogOpen = id => ({
  type: PERMITS_OPEN,
  payload: { id }
});

export const permitsDialogClose = () => ({
  type: PERMITS_CLOSE
});

// export function permitsDialogOpen(id) {
//   return dispatch => {
//     dispatch(permitsDialogOpen(id));
//   };
// }
// export function permitsDialogClose() {
//   return dispatch => {
//     dispatch(permitsDialogClose());
//   };
// }
