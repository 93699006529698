import { fetchGetWT, fetchPutWT } from "../../../helpers/HttpHelper";
import {
  apiGetParamsReplace,
  endpoints
} from "../../../constants/services.constants";

// OPEN EDIT FORM
export const EDIT_OPEN_BEGIN = "OFFER_EDIT_OPEN_BEGIN";
export const EDIT_CLOSE_BEGIN = "OFFER_EDIT_CLOSE_BEGIN";

export const editDialogOpenBegin = (id, publicationName) => ({
  type: EDIT_OPEN_BEGIN,
  payload: { id, publicationName }
});

export const editDialogCloseBegin = () => ({
  type: EDIT_CLOSE_BEGIN
});

export function editDialogOpen(id, publicationName) {
  return dispatch => {
    dispatch(editDialogOpenBegin(id, publicationName));
  };
}
export function editDialogClose() {
  return dispatch => {
    dispatch(editDialogCloseBegin());
  };
}

// UPDATE OFFER
export const UPDATE_OFFER_BEGIN = "OFFER_UUPDATE_OFFER_BEGIN";
export const UPDATE_OFFER_SUCCESS = "OFFER_UUPDATE_OFFER_SUCCESS";
export const UPDATE_OFFER_FAILURE = "OFFER_UUPDATE_OFFER_FAILURE";

export const updateOfferBegin = () => ({
  type: UPDATE_OFFER_BEGIN
});

export const updateOfferSuccess = () => ({
  type: UPDATE_OFFER_SUCCESS
});

export const updateOfferFailure = error => ({
  type: UPDATE_OFFER_FAILURE,
  payload: { error }
});

export function updateOffer(offer, id, callback) {
  const endpoint = apiGetParamsReplace(endpoints.OFFER_EDIT_DTO, ...[id]);
  return dispatch => {
    dispatch(updateOfferBegin());
    return fetchPutWT(
      endpoint,
      JSON.stringify(offer),
      json => {
        dispatch(updateOfferSuccess(json));
        callback();
      },
      error => dispatch(updateOfferFailure(error))
    );
  };
}
