
// reload photo tab in edit product dialog after photo was deleted

export const RELOAD_PRODUCT_PHOTOS = "RELOAD_PRODUCT_PHOTOS_TAB"
export const reloadPhotoTabProduct = (reload) => ({
  type: RELOAD_PRODUCT_PHOTOS,
  payload: { reload }
});

export function setReloadPhotoTabProduct(reload) {
  return dispatch => dispatch(reloadPhotoTabProduct(reload));
}

// CLEAN EVERYTHING
export const CLEAN_SEARCH_PRODUCTS = "PRODUCT_CLEAN_SEARCH_PRODUCTS";

export const cleanSearchProductsSuccess = () => ({
  type: CLEAN_SEARCH_PRODUCTS,
});

export function cleanProductSearch() {
  return (dispatch) => dispatch(cleanSearchProductsSuccess());
}
