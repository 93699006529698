import { endpoints } from '../../../constants/services.constants';
import { fetchGetWT } from '../../../helpers/HttpHelper';

export const FETCH_FEATURES_BEGIN = 'UTILS_FETCH_FEATURES_BEGIN';
export const FETCH_FEATURES_SUCCESS = 'UTILS_FETCH_FEATURES_SUCCESS';
export const FETCH_FEATURES_FAILURE = 'UTILS_FETCH_FEATURES_FAILURE';

export const fetchFeaturesBegin = () => ({
  type: FETCH_FEATURES_BEGIN
});

export const fetchFeaturesSuccess = (features) => ({
  type: FETCH_FEATURES_SUCCESS,
  payload: {features}
});

export const fetchFeaturesFailure = error => ({
  type: FETCH_FEATURES_FAILURE,
  payload: { error }
});

export const fetchFeatures = () => {
  return dispatch => {
    const endpoint = endpoints.FEATURES;
    dispatch(fetchFeaturesBegin());
    return fetchGetWT(endpoint,
      (json) => {
        dispatch(fetchFeaturesSuccess(json));
      }
      ,
      (error) => {
        dispatch(fetchFeaturesFailure(error));
      }
    )
  }
}