
import React from 'react';
import { useState } from 'react';
import CustomArrow from '../sharedComponents/CustomArrow';
import { styles } from '../shared';


export default function Carousel({ items }) {
    const [visibleIndex, setVisibleIndex] = useState(0);
    //TODO: we will need some container size breaks here to increase or reduce the qty of takens 
    const take = 6;

    const onArrowClick = (direction) => {
        const increment = direction === 'left' ? -1 : 1;
        const newIndex = (visibleIndex + increment + items.length) % items.length;

        if (
            newIndex >= 0
            && newIndex + take <= items.length
        )
            setVisibleIndex(newIndex);

        setTimeout(() => {
        }, 0);
    };

    const itemsToRender = items.length < take + 1 ? items
        : items.slice(visibleIndex, visibleIndex + take);

    return (
        <div className='carouselContainer'>

            {/* Left arrow */}
            {visibleIndex > 0 && items && items.length > 1 &&
                <div style={{ cursor: "pointer" }} onClick={() => onArrowClick('left')}>
                <CustomArrow
                    className={styles.customArrowBack}
                />
                </div>
            }
            <div style={{ width: '100%', display: 'inline-flex', justifyContent: 'center' }}>
                {itemsToRender}
            </div>
            {/* Right arrow */}
            {items && visibleIndex + take < items.length &&
                <div style={{ cursor: "pointer" }} onClick={() => onArrowClick('right')}>
                <CustomArrow />
                </div>
            }
        </div>
    )
}
