import { endpoints } from "../../constants/services.constants";
import { _request } from "../HttpHelper";
import { formatBodyParamsPOST } from "./AuthHelperV2";
import Resources from "../../Resources.json";

export async function getIdentityToken(details) {
    return fetch(endpoints.IDENTTITY_GET_TOKEN, {
        method: "POST",
        body: formatBodyParamsPOST(details),
        headers: { 
            "Content-Type": "application/x-www-form-urlencoded",
            "x-organization-id" : Resources.Organization        
        }
    })
}

export async function resetCodeRequest(userName, callbackSucceed, callbackError) {
    return _request({
        method: "POST",
        endpoint: endpoints.IDENTTITY_REQUEST_RESETCODE,
        body: JSON.stringify({ userName: userName }),
        throwError: true,
        callbackSucceed: callbackSucceed,
        callbackError: callbackError
    });
}

export async function changePassWord(requestBody, callbackSucceed, callbackError) {
    return _request({
        method: "POST",
        endpoint: endpoints.IDENTTITY_RESETPASSWORD_WITHCODE,
        body: JSON.stringify(requestBody),
        throwError: true,
        callbackSucceed: callbackSucceed,
        callbackError: callbackError
    });
}

export async function validateToken(requestBody, authHeaders) {
    return _request({
        method: "POST",
        endpoint: endpoints.IDENTTITY_VALIDATE_TOKEN,
        body: formatBodyParamsPOST(requestBody),
        throwError: true,
        headers: authHeaders
    });
}