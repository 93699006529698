import React from "react";
import { withStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import { Chip, Grid, TextField } from "@material-ui/core";
import { Autocomplete } from "@material-ui/lab";
import { addTagChip, deleteTagChip } from "./EventHandlerTags";

const styles = {
    root: {
        padding: "2px 4px",
        display: "flex",
        alignItems: "center",
        width: "100%",
        marginBottom: 20
    },
    input: {
        marginLeft: 20,
        marginRight: 20,
        flex: 1
    },
    iconButton: {
        padding: 10
    },
    divider: {
        width: 1,
        height: 28,
        margin: 4
    }
};

function SearchBarWithAutoSuggest({ handleChangeTagsSearchArray, queryArray, classes, autoSuggestTags }) {

    return (
        <Grid container>
            <Paper className={classes.root} elevation={1}>
                <Autocomplete
                    style={{
                        width: "100%",
                        padding: "0px 20px"
                    }}
                    
                    closeIcon={false}
                    freeSolo={false}
                    multiple
                    id="tags"
                    value={queryArray}
                    onChange={(event, newTag) => addTagChip(newTag, queryArray, handleChangeTagsSearchArray)}
                    options={autoSuggestTags.map((option) => option.name)}
                    renderTags={(tagValue, getTagProps) =>
                        tagValue.map((option, index) => (
                            <Chip
                                label={option}
                                {...getTagProps({ index })}
                                onDelete={() => deleteTagChip(option, queryArray, handleChangeTagsSearchArray)}
                            />
                        ))
                    }
                    noOptionsText="Ingen eksisterende"
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            label=""
                            InputProps={{
                                ...params.InputProps,
                                type: 'search',
                                disableUnderline: true
                            }}
                            placeholder="Tryk Enter efter hvert tag"
                        />
                    )}
                />
            </Paper>
        </Grid>
    );
}

export default withStyles(styles)(SearchBarWithAutoSuggest);
