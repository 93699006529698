import { Button } from "@material-ui/core";
import React from 'react';
import styled from "styled-components";

export default function StyledButtonLogIn(props) {
    const { type, variant, color, handleOnClick, buttonText, disabled } = props;

    const StyledButton = styled(Button)`
        margin-top: 24px
    `;
    return (
        <StyledButton
            type={type ? type : undefined}
            fullWidth
            variant={variant}
            color={color}
            onClick={(e) => handleOnClick ? handleOnClick(e) : undefined}
            disabled={disabled}
            name="buttonLogin"
        >
            {buttonText}
        </StyledButton>
    )
}