import React from "react";
import Tooltip from "@material-ui/core/Tooltip";
import AddIcon from "@material-ui/icons/Add";
import { withStyles } from "@material-ui/core/styles";
import Fab from "@material-ui/core/Fab";
import { connect } from "react-redux";
import store from "../../../redux/store";
import { openAddPhotoOrderDialog } from "../../../redux/actions/fotohaandtering/photoorder.fotohaandtering.actions";
import DialogAddPhotoOrderV2 from "./Dialogs/DialogAddPhotoOrder/DialogAddPhotoOrderV2";
import { photoOrderOrigin } from "../../../constants/enums";

const defaultToolbarStyles = {
  iconButton: {
    marginLeft: 10,
    marginTop: 5,
  },
};

class CustomToolbar extends React.Component {
  handleClick = () => {
    store.dispatch(openAddPhotoOrderDialog([]));
  };

  handlePhotoOrderCreated = () => {
    // todo should probably update the list
  };

  render() {
    const { classes } = this.props;

    return (
      <>
        <Tooltip title={"Tilføj fotobestilling"}>
          <Fab
            size="small"
            color="primary"
            name="buttonPhototrackerAdd"
            className={classes.iconButton}
            onClick={this.handleClick}
          >
            <AddIcon />
          </Fab>
        </Tooltip>
        {this.props.fotohaandteringReducer.dialogAddPhotoOrderOpen ? (
          <DialogAddPhotoOrderV2
            onPhotoOrderCreated={() => this.handlePhotoOrderCreated()}
            categories={this.props.categories}
            orderOrigin={parseInt(photoOrderOrigin.PhotoTracker)}
          />
        ) : undefined}
      </>
    );
  }
}

const DialogAddStyled = withStyles(defaultToolbarStyles, {
  name: "CustomToolbar",
})(CustomToolbar);
export default connect((state) => state)(DialogAddStyled);
