import React, { useState, useContext } from 'react';
import { withStyles, Dialog, DialogTitle, DialogContent, DialogActions, TextField, Button, FormControl, InputLabel, Select, MenuItem, FormHelperText } from '@material-ui/core';
import clsx from 'clsx';
import { FeedsContext } from '../../contexts/FeedsContext';
import { MediaContext } from '../../contexts/MediaContext';
import { createNewMediaDefinitionRequest, fetchMediaDefinitions } from '../Logic/Requests';
import { stringIsEmpty } from "../../../../../helpers/UtilHelper";

const styles = theme => ({
    input: {
        marginBottom: 35
    },
    actions: {
        display: "grid",
        gridTemplateColumns: "1fr 1fr 1fr 1fr 1fr",
        gridGap: "1rem",
        marginBottom: 30
    },
    actionButton: {
        width: 110,
        justifySelf: "end",
    },
    positionGroup: {
        gridColumn: 4
    },
    error: {
        color: "#f44336"
    },
    dropdownStyle: {
        maxHeight: 385
    }
});

const ColorButton = withStyles(theme => ({
    root: {
        marginRight: 10,
        backgroundColor: "#263238",
        color: "#ffffff",
        '&:hover': {
            backgroundColor: "#11171A",
            color: "#ffffff"
        },
    },
}))(Button);

function DialogAdd(props) {
    const [mediaName, setMediaName] = useState("");
    const [feedId, setFeedId] = useState("");
    const [mediaTemplate, setMediaTemplate] = useState("");
    const [mediaTransport, setMediaTransport] = useState("");
    const [mediaType, setMediaType] = useState("");

    const { feeds: feedsState } = useContext(FeedsContext);
    const [feeds,] = feedsState;
    const { templates: templatesState, mediaTransports: mediaTransportsState, mediaTypes: mediaTypesState, media: mediaState } = useContext(MediaContext);
    const [templates,] = templatesState;
    const [, setMedia] = mediaState;
    const [mediaTransports,] = mediaTransportsState;
    const [mediaTypes,] = mediaTypesState;

    // Set validation errors
    const [mediaNameError, setMediaNameError] = useState("");
    const [feedIdError, setFeedIdError] = useState("");
    const [mediaTransportError, setMediaTransportError] = useState("");
    const [mediaTypeError, setMediaTypeError] = useState("");

    const { classes, handleClose } = props;

    const error = "Field is required";

    const selectMediaTransport = (transportId) => {
        if (!transportId) {
            return;
        }
        setMediaTransport(transportId);
    }

    const selectMediaType = (typeId) => {
        if (!typeId) {
            return;
        }
        setMediaType(typeId);
    }


    const handleSaveMedia = async () => {
        // Validate fields
        if (stringIsEmpty(mediaName)) {
            setMediaNameError(error);
            return;
        } else {
            setMediaNameError("");
        }

        if (stringIsEmpty(feedId)) {
            setFeedIdError(error);
            return;
        } else {
            setFeedIdError("");
        }

        if (stringIsEmpty(mediaTransport)) {
            setMediaTransportError(error);
            return;
        } else {
            setMediaTransportError("");
        }

        if (stringIsEmpty(mediaType)) {
            setMediaTypeError(error);
            return;
        } else {
            setMediaTypeError("");
        }

        handleClose();
        let mediaObject = {
            mediaName: {
                name: mediaName
            },
            feed: {
                id: feedId
            },
            mediaType: { Id: mediaType },
            mediaTransport: { Id: mediaTransport },
            dates: {}
        }

        if (mediaTemplate !== "") {
            mediaObject.mediaTemplate = mediaTemplate;
        }

        await createNewMediaDefinitionRequest(mediaObject);
        let mediaUpdated = await fetchMediaDefinitions();

        setMedia(mediaUpdated)
    }

    return (
        <>
            <Dialog
                open={props.open}
                onClose={handleClose}
            >
                <DialogTitle id="form-dialog-title">Opret </DialogTitle>
                <DialogContent>
                    <TextField
                        error={!!mediaNameError}
                        helperText={mediaNameError}
                        className={classes.input}
                        margin="dense"
                        label="Navn"
                        type="text"
                        name="name"
                        value={mediaName}
                        InputProps={{
                            multiline: true
                        }}
                        onChange={(e) => setMediaName(e.target.value)}
                        fullWidth

                    />
                    <FormControl className={classes.input} fullWidth>
                        <InputLabel shrink htmlFor="feedId">Feed</InputLabel>
                        <Select
                            fullWidth
                            value={feedId ? feedId : ""}
                            onChange={(e) => setFeedId(e.target.value)}
                            MenuProps={{ classes: { paper: classes.dropdownStyle } }}
                            inputProps={{
                                name: "feedId",
                                id: "feedId"
                            }}
                        >
                            {feeds.map((feed, index) => (
                                <MenuItem key={index} value={feed.feedId}>
                                    {feed.feedName.name}
                                </MenuItem>
                            ))}

                        </Select>
                        {!!feedIdError && <FormHelperText className={classes.error}>{feedIdError}</FormHelperText>}
                    </FormControl>
                    {templates.length === 0 ? undefined :
                        <FormControl className={classes.input} fullWidth>
                            <InputLabel shrink htmlFor="templateId">Skabelon</InputLabel>
                            <Select
                                fullWidth
                                value={mediaTemplate ? mediaTemplate : ""}
                                onChange={(e) => setMediaTemplate(e.target.value)}
                                MenuProps={{ classes: { paper: classes.dropdownStyle } }}
                                inputProps={{
                                    name: "templateId",
                                    id: "templateId"
                                }}
                            >
                                {templates.map((template, index) => (
                                    <MenuItem key={index} value={template.id}>
                                        {template.name}
                                    </MenuItem>
                                ))}

                            </Select>
                        </FormControl>
                    }
                    <FormControl className={classes.input} fullWidth>
                        <InputLabel shrink htmlFor="transportId">Transport</InputLabel>
                        <Select
                            fullWidth
                            value={mediaTransport ? mediaTransport : ""}
                            onChange={(e) => selectMediaTransport(e.target.value)}
                            MenuProps={{ classes: { paper: classes.dropdownStyle } }}
                            inputProps={{
                                name: "transportId",
                                id: "transportId"
                            }}
                        >
                            {mediaTransports.map((item, index) => (
                                <MenuItem key={index} value={item.Id}>
                                    {item.DisplayName}
                                </MenuItem>
                            ))}

                        </Select>
                        {!!mediaTransportError && <FormHelperText className={classes.error}>{mediaTransportError}</FormHelperText>}
                    </FormControl>
                    <FormControl className={classes.input} fullWidth>
                        <InputLabel shrink htmlFor="typeId">Type</InputLabel>
                        <Select
                            fullWidth
                            value={mediaType ? mediaType : ""}
                            onChange={(e) => selectMediaType(e.target.value)}
                            MenuProps={{ classes: { paper: classes.dropdownStyle } }}
                            inputProps={{
                                name: "typeId",
                                id: "typeId"
                            }}
                        >
                            {mediaTypes.map((media, index) => (
                                <MenuItem key={index} value={media.Id}>
                                    {media.DisplayName}
                                </MenuItem>
                            ))}

                        </Select>
                        {!!mediaTypeError && <FormHelperText className={classes.error}>{mediaTypeError}</FormHelperText>}
                    </FormControl>
                </DialogContent>
                <DialogActions>
                    <div className={classes.actions}>
                        <ColorButton className={clsx(classes.actionButton, classes.positionGroup)} onClick={handleClose} color="primary">
                            Afbryd
                        </ColorButton>

                        <ColorButton className={classes.actionButton} variant="contained" onClick={() => handleSaveMedia()} color="primary">
                            Opret
                        </ColorButton>
                    </div>
                </DialogActions>
            </Dialog>
        </>
    )

}

export default withStyles(styles)(DialogAdd)