import {
  CLEAN_FOTO_HAANDTERING,
  SET_PUBLICATION,
  SET_SELECTED_OFFERS,
  TOGGLE_SELECTED_OFFER,
} from "../actions/fotohaandtering/fotohaandtering.actions";
import {
  OPEN_ADD_PHOTO_ORDER_DIALOG,
  CLOSE_ADD_PHOTO_ORDER_DIALOG,
} from "../actions/fotohaandtering/photoorder.fotohaandtering.actions";

const initState = {
  publication: null,
  selectedOfferIds: [],
  dialogAddPhotoOrderOfferProductIds: [],
  dialogAddPhotoOrderOpen: false,

  id: null,
  selectedOffers: [],
  multiOfferId: null,
  publicationPageOfferId: null,
  pages: [],

  updateLoading: false,
  offers: null,
  loadingDeleteOffer: false,
  selectImageProductOpen: false,
  dialogProductOrderOpen: false,
  formOfferOpen: false,
  formProductOpen: false,
  selectedPages: [],
  deleteDialog: false,
  confirmSendToBanglaDialogOpen: false,
  selectedPublicationId: null,
  loadingSaveMainImage: false,
  selectedProduct: null,
  selectedProductLoading: false,
  offerId: null,
  publicationpageid: null,
  setMasterLoading: false,
  mainImageDialogOpen: false,
  parameters: null,
  updateOfferImageId: null,
  offerProduct: null,
  relatedProductImages: [],
  isFotoOrder: false,
};
export default (state = initState, action) => {
  switch (action.type) {
    //-------------------------------------------
    case SET_PUBLICATION:
      return {
        ...state,
        publication: action.payload.publication
      };
    //-------------------------------------------
    case TOGGLE_SELECTED_OFFER:

      let selectedOfferIds = [...state.selectedOfferIds];
      const offerId = action.payload.offerId;
      if (selectedOfferIds.includes(offerId)) {
        selectedOfferIds = selectedOfferIds.filter(x => x !== offerId);
      } else {
        selectedOfferIds.push(offerId);
      }

      return {
        ...state,
        selectedOfferIds: selectedOfferIds
      };
    case SET_SELECTED_OFFERS:
      return {
        ...state,
        selectedOfferIds: action.payload.offerIds
      };
    //-------------------------------------------
    case OPEN_ADD_PHOTO_ORDER_DIALOG:
      return {
        ...state,
        dialogAddPhotoOrderOfferProductIds: action.payload.offerProductIds,
        dialogAddPhotoOrderOpen: true
      };
    //-------------------------------------------
    case CLOSE_ADD_PHOTO_ORDER_DIALOG:
      return {
        ...state,
        dialogAddPhotoOrderOpen: false
      };

    //------------------------------------------------------------

    case CLEAN_FOTO_HAANDTERING:
      return {
        ...state,

        loading: false,
        loadingOffers: false,
        loadingOffers2: false,
        error: null,
        errorOffers: null,
        pages: [],
        spreads: [],
        offers: null,
        publication: null,
        // selectedPublicationId: null,
        selectedPages: [],
        statusFormOffer: null,
        loadingFormOffer: null,
        errorFormOffer: null,
        formOfferEntity: null,
        statusFormProduct: null,
        loadingFormProduct: null,
        errorFormProduct: null,
        formProductProduct: null,
        formProductOfferId: null,
        statusDeleteOffer: null,
        loadingDeleteOffer: false,
        errorDeleteOffer: null,
        statusDeleteProduct: null,
        loadingDeleteProduct: null,
        errorDeleteProduct: null,
        statusSaveMainImage: null,
        loadingSaveMainImage: false,
        errorSaveMainImage: null,
        selectImageProductOpen: false,
        selectImageProduct: null,
        loadingSelectImageProduct: false,
        errorsSelectImageProduct: null,
        selectImageProductCollection: [],
        offerProduct: null,
      };
    default:
      return state;
  }
};
