import { Button, Checkbox, FormControlLabel, MuiThemeProvider, TextField, Typography } from '@material-ui/core';
import { DatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import { RepublicaDatePickerMuiTheme } from "../../../../../src/RepublicaMuiTheme";
import React, { useEffect, useState } from 'react';
import MomentUtils from "@date-io/moment";
import moment from "moment";
import { showErrorNotification } from "../../../../helpers/UtilHelper";

function ExpirePhotoForm(props) {
    //Photo is the object in which the ID is from multiPhoto in database
    const { photo, photoValidTo, setPhotoValidToDate, earliestDate} = props
    const [validToDateCheckBox, setValidToDateCheckBox] = useState(false)
    let todayDate = new Date()

    useEffect(() => {
            var date = photo.validTo
            if (photo.validTo && photo.validTo.length > 20) {
                date = photo.validTo.slice(0, 19)
            }
            if (date) {
                setValidToDateCheckBox(true)
            }
            setPhotoValidToDate(date) 
    }, [null]) //do not update

    function handleCheckboxChange () {
        setValidToDateCheckBox(!validToDateCheckBox);
        if (validToDateCheckBox) {
            setPhotoValidToDate(null)
            return 
        }
        ShowValidDateInput()
    };

    

    function ShowValidDateInput() {
        todayDate.setMonth(todayDate.getMonth() + 3) // assuring for validFrom date and End Date isn't the same.
        todayDate = todayDate.toISOString().slice(0, 19)
        if (photo != undefined && photo.validTo != null && photoValidTo) { // Fetch objects have happen
            return
        }
        setPhotoValidToDate(todayDate)
    }

  

    function handleDateChangeTo(newDate) {
        ShowValidDateInput()
        if (newDate != null) {
            if (Date.parse(newDate._d.toISOString()) < new Date()) { //handles wrong inputs
                showErrorNotification("Udløbsdatoen skal være efter dags dato")
                return
            }
            setPhotoValidToDate(newDate._d.toISOString())
        } else {
            setPhotoValidToDate(null)
            setValidToDateCheckBox(!validToDateCheckBox)
        }
    }

    return (
        <MuiPickersUtilsProvider utils={MomentUtils} moment={moment}>
            <MuiThemeProvider theme={RepublicaDatePickerMuiTheme}>
                <div>
              
                    <FormControlLabel
                        control={
                            <>
                                <Checkbox
                                name="checkboxPhotoEditExpireDate"
                                    checked={validToDateCheckBox}
                                    onChange={()=> handleCheckboxChange()}
                                    />
                                <Typography style={{marginRight:"4%"}}>Udløbsdato</Typography>
                                <div>

                                    <DatePicker
                                        onChange={(date) => handleDateChangeTo(date)}
                                        minDate={earliestDate}
                                        label="Tilgængeligt til:"
                                        value={photoValidTo}
                                        clearable
                                        style={{ paddingRight: "2rem" }}
                                        format="Do[/]MM-YY"
                                        disabled={!validToDateCheckBox}
                                    />
                                </div>
                            </>
                        }
                    />
              
                </div>
            </MuiThemeProvider>
        </MuiPickersUtilsProvider>
    );
}

export default ExpirePhotoForm;