import { notificationChannelsEnum } from "../../../constants/enums";
import { apiGetParamsReplace, endpoints } from "../../../constants/services.constants";
import { _request } from "../../../helpers/HttpHelper";

export async function setNotificationAsRead(notificationIds) {
    return _request({
        method: "PATCH",
        endpoint: endpoints.NOTIFICATION_SET_AS_READ,
        body: JSON.stringify(notificationIds),
        throwError: true,
        skipBodyParsing: true,
    });
}

export async function isUserSubscribedToChannelRecord(channel, recordId) {
    const endpoint = apiGetParamsReplace(endpoints.IS_USER_SUBSCRIBED_TO_CAHNNEL_RECORD, channel, recordId);
    return _request({
        method: "GET",
        endpoint: endpoint
    });
}

export async function unsubscribeUserFromNotifications(jobNumber) {
    const _channel = notificationChannelsEnum["PublicationUpdated"];
    const requestBody = {
        userId: "c89f4f02-03e3-4f3b-948e-698929e9e0c9", // replace it with a legit user id, right now this value is replaced in the service
        channel: _channel,
        recordId: parseInt(jobNumber)
    };

    return _request({
        method: "POST",
        endpoint: endpoints.USUBSCRIBE_USER_FROM_CHANNEL_RECORD,
        body: JSON.stringify(requestBody),
        skipBodyParsing: true,
    });
}

export async function subscribeUserToNotificationChannelRecord(requestBody) {
    // Record can be a job number for now
    return _request({
        method: "POST",
        endpoint: endpoints.SUBSCRIBE_USER_TO_CHANNEL_RECORD,
        body: JSON.stringify(requestBody),
        skipBodyParsing: true,
    });
}
