import React from "react";
import MUIDataTable from "mui-datatables";
import ActionButtons from "./ActionButtons";
import { connect } from "react-redux";
import { Tooltip, IconButton } from "@material-ui/core";
import LoopIcon from "@material-ui/icons/Loop";
import { applications } from "../../../constants/app.constants";
import { canDeleteApplication } from "../../../helpers/CredentialsHelper";
import { apiGetParamsReplace, endpoints } from "../../../constants/services.constants";
import ImageWithPlaceholder from "../../shared/components/ImageWithPlaceholder";

class DataTable extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      columns: [],
    };
  }

  componentDidMount() {
    this.setState({ columns: this.columns() });
  }

  columns() {
    return [
      {
        name: "Motiv",
        options: {
          filter: false,
          sort: true
        }
      },
      {
        name: "Navn",
        options: {
          filter: false,
          sort: true,
          display: true,
        }
      },
      {
        name: "Filnavn",
        options: {
          filter: false,
          sort: true,
          display: true
        }
      },
      {
        name: "Mappings",
        options: {
          filter: false,
          sort: false
        }
      },
      {
        name: "Format",
        options: {
          filter: false,
          sort: true
        }
      },
      {
        name: "Billedstørrelse",
        options: {
          filter: false,
          sort: false
        }
      },
      {
        name: "",
        options: {
          sort: false,
        },
      },]
  }



  getDataTable(logos) {

    const canDelete = canDeleteApplication(applications.LOGOSTYRING);
    if (logos == null) return null;

    return logos.map(logo => {

      return [
        <ImageWithPlaceholder key={logo.id} imageUrl={apiGetParamsReplace(endpoints.LOGO_FILE_PREVIEW, logo.id)}
          placeholder="/images/placeholders/no_preview.png" width={75} height={75} />,
        logo.name,
        logo.fileName,
        logo.mappings.map(text => {
          return <Tooltip title={text}>
            <span className="nLine" key={text}>
              {text.length > 15
                ? text.substring(0, 15) + "..."
                : text}
              <br />
            </span>
          </Tooltip>
        }),
        logo.format,
        logo.width ? `${logo.width}x${logo.height}` : "Ikke tilgængeligt",
        <ActionButtons
          onDataChanged={() => this.props.onDataChanged()}
          logo={logo}
          canDelete={canDelete}
        />
      ];
    });
  }

  render() {
    const data = this.getDataTable(this.props.data);
    const options = {
      print: false,
      download: false,
      filter: false,
      viewColumns: false,
      selectableRows: false,
      rowsPerPageOptions: [10, 50, 100],
      search: false,
      textLabels: {
        body: {
          noMatch: "",
          toolTip: "Sorter"
        },
        pagination: {
          next: "Næste side",
          previous: "Forrige side",
          rowsPerPage: "Logoer per side:",
          displayRows: "af"
        },
        selectedRows: {
          text: "valgt række(r)",
        }
      }
    };

    return (
      <>
        {/* Div with 4px so the page doesn't jump after loading. */}
        <div style={{ height: 4 }}></div>
        <MUIDataTable
          data={data}
          columns={this.state.columns}
          options={options}
        />
      </>
    );
  }
}

export default connect(state => state)(DataTable);
