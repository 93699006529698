import React, { useState, useEffect } from 'react';
import CarouselSlide from './CarouselSlide';
import ArrowBackIosRoundedIcon from '@material-ui/icons/ArrowBackIosRounded';
import ArrowForwardIosRoundedIcon from '@material-ui/icons/ArrowForwardIosRounded';

export function PhotoCarousel({ photoIds, onVisiblePhotoIdChanged, refreshOfferProductPhoto }) {
    const [visibleIndex, setVisibleIndex] = useState(0);

    useEffect(() => {
        setVisibleIndex(0);
    }, [photoIds]);

    const onArrowClick = (direction) => {
        const increment = direction === 'left' ? -1 : 1;
        const newIndex = (visibleIndex + increment + photoIds.length) % photoIds.length;
        setVisibleIndex(newIndex);

        setTimeout(() => {
            onVisiblePhotoIdChanged(photoIds[newIndex]);
        }, 0);
    };

    if (!photoIds || photoIds.length === 0) {
        return (<div style={{ width: 160 }}></div>);
    }

    const visiblePhotoId = photoIds[visibleIndex];


    return (
        <div className='carouselContainer'>

            {/* Left arrow */}
            { photoIds && photoIds.length > 1 &&
                <div style={{cursor: "pointer"}} onClick={() => onArrowClick('left')}>
                    <ArrowBackIosRoundedIcon />
                </div>
            }
                <div style={{ width: 160, height: 88, cursor: "pointer" }}>
                <CarouselSlide
                    photoId={visiblePhotoId}
                    slideWidth={160}
                    refreshOfferProductPhoto={refreshOfferProductPhoto}
                />
                </div>


            {/* Right arrow */}
            { photoIds && photoIds.length > 1 &&
                <div style={{cursor: "pointer"}} onClick={() => onArrowClick('right')}>
                    <ArrowForwardIosRoundedIcon />
                </div>
            }
        </div>
    );
}