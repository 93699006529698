import React, { useRef, useEffect } from 'react';
import { makeStyles } from '@material-ui/core';
import clsx from 'clsx';
import PosterContainer from './PosterContainer';

const useStyles = makeStyles({
    iframeContainer: {
        marginTop: "400px",
        height: "500px",
        zIndex: 1,
    },
})

export default function EditOfferPoster({ url, classes: classesIn }) {
    const containerRef = useRef();
    const iframeRef = useRef();
    const classes = useStyles();

    let applyScaling = scaledWrapper => {

        // Get the scaled content, and reset its scaling for an instant
        let scaledContent = iframeRef.current;
        if (!scaledContent) {
            return;
        }

        scaledContent.style.transform = 'scale(1, 1)';

        let { width: cw, height: ch } = scaledContent.getBoundingClientRect();
        let { width: ww, height: wh } = scaledWrapper.getBoundingClientRect();

        let newWh = ww * 1.7778; // Change the height of the div to fit 16:9 ratio
        let newww = ww;

        if (newWh > 700) {
            newWh = 400;
            newww = newWh * 0.5625;
            scaledWrapper.style.width = `${newww}px`;
        } else {
            scaledWrapper.style.width = '';
        }

        scaledWrapper.style.height = `${newWh}px`;

        let scaleAmtX = ww / cw;
        let scaleAmtY = wh / ch;

        scaledContent.style.transform = `scale(${scaleAmtX}, ${scaleAmtY})`;
    };

    applyScaling(containerRef.current);

    useEffect(() => {
        const onResize = () => {
            if (!containerRef.current || !iframeRef.current) {
                return;
            }
            applyScaling(containerRef.current)
        }

        window.addEventListener("resize", onResize)

        return () => window.removeEventListener("resize", onResize);
    }, []);

    return (<div
        ref={containerRef}
        className={clsx([...classesIn, classes.iframeContainer])}
    >
        <PosterContainer
            iframeRef={iframeRef}
            url={url}
        />
    </div>)
}