import React, {  } from "react";
import { Button } from "@material-ui/core";

export default function ActiveButton({ photoId, isExclusive, isLoading, isActive, onSetActive }) {


    const isButtonDisabled = () => {

        if(!isActive && !isLoading && !isExclusive){
            return false;
        }
        return true;
    }
    const buttonText = () => {
        let buttonText = "";

        if(isLoading){
            buttonText = "...";
        }
        else if(isExclusive){
            buttonText = "Låst"
        }
        else if(isActive){
            buttonText = "Digital"
        }
        else if(!isActive){
            buttonText = "Gør Digital"
        }
        
        return buttonText;
    }

    const buttonStyle = () => {

        if(isActive && !isLoading){
            return { 
                backgroundColor: "#008E00",
                color: "white"
            };
        }
    }


    return (
        <Button 
            disabled={isButtonDisabled()}
            onClick={() => onSetActive(photoId)}
            style={buttonStyle()}
        >
            {buttonText()}
        </Button>
    )
}
