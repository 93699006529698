import React, { useEffect, useState } from "react";

// Redux imports
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import TextField from "@material-ui/core/TextField";
import createDialog from "../../../../DialogRenderer";
import { LoadingLogo } from "../../../shared/Loading";
import {
  getCurrentUser,
} from "../../../../helpers/CredentialsHelper";
import { findDUplicatesInArray, showSuccessNotification } from "../../../../helpers/UtilHelper";
import {
  CategoryFilterSimple,
  StatusFilter,
} from "../PhotoTrackerFilter/PhotoTrackerFilterProperties";
import ChipInput from "material-ui-chip-input";
import getProductCategories from "../../produkstyring/Requests";
import { sendPhotoOrderList } from "../SharedRequests";

export async function openDialogSendPhotoOrderListe(
  statuses,
  categories,
  jobNumbers
) {
  return createDialog(({ show, onSubmit, onDismiss }) => (
    <DialogSendPhotoOrderList
      statuses={statuses}
      categories={categories}
      jobNumbers={jobNumbers}
      open={show}
      onCancel={onDismiss}
      onConfirm={onSubmit}
    />
  ));
}

function DialogSendPhotoOrderList(props) {
  const [categoryOptions, setCategoryOptions] = useState([]);
  const [statuses, setStatuses] = useState(props.statuses);
  const [selectedCategories, setSelectedCategories] = useState([]);
  const [jobNumbers, setJobNumbers] = useState(props.jobNumbers);
  const [loading, setLoading] = useState(true);
  const [emailErrorMessage, setEmailErrorMessage] = useState(undefined);
  const [jobNumberErrorMessage, setJobNumberErrorMessage] = useState(undefined);
  const [fromEmail, setFromEmail] = useState("");
  const [toEmail, setToEmail] = useState("");
  const [subject, setSubject] = useState("Liste over fotobestillinger");
  const [content, setContent] = useState("");

  useEffect(() => {
    init();
  }, []);

  const init = async () => {
    const currentUser = await getCurrentUser();
    const categories = await getProductCategories();
    const sortedCategories = categories.sort(function(a, b) {
      if(a.name < b.name) return -1;
      if(a.name > b.name) return 1;
      return 0;
     });
    setSelectedCategories([...props.categories]);
    setCategoryOptions(sortedCategories);
    setFromEmail(currentUser.email);
    setContent(getDefaultMessage(currentUser.fullname));
    setLoading(false);
  };

  const getDefaultMessage = (name) => {
    return `
Hej, 

Mail med udvalgte fotobestillinger. 


Med venlig hilsen 
${name}

- Vedhæftet excel liste over valgte fotobestillinger.
`;
  };

  const validate = () => {
    let isValid = true;
    if (!toEmail) {
      setEmailErrorMessage("Indtast venligst en gyldig e-mailadresse");
      isValid = false;
    }
    if (jobNumbers.length === 0) {
      setJobNumberErrorMessage("Indtast venligst et eller flere jobnumre");
      isValid = false;
    }
    return isValid;
  }

  const handleSave = async () => {
    if (validate()) {
      const reportDto = {
        jobNumbers: jobNumbers,
        mainCategoryIds: selectedCategories.map(x => x.id),
        statuses: statuses,
        emailAddress: toEmail,
        subject: subject,
        body: content,
      }

      try {
        await sendPhotoOrderList(reportDto);
        showSuccessNotification("En liste med de valgte fotobestillinger er ved at blive skabt. Du vil modtage en email når processen er færdig.");
        props.onConfirm();
      } catch { }
    }
  };

  const handleRemoveJobNumber = (jobNumber) => {
    setJobNumbers(jobNumbers.filter(x => x !== jobNumber));
  }

  const handleAddJobNumber = (jobNumber) => {
    setJobNumbers([...jobNumbers, jobNumber]);
    setJobNumberErrorMessage(undefined);
  }

  const handleChangeCategory = (selection) => {
    const newSelection = selection.value;

    // A checkbox component does not return property "checked" as it is a form, 
    // so we need to remove duplicates of selections coming from props
    const newSelectionIds = newSelection.map(x => x.id);
    const duplicates = findDUplicatesInArray(newSelectionIds);
    const filteredSelection = newSelection.filter(x => !duplicates.includes(x.id));
    setSelectedCategories(filteredSelection);
  }

  return (
    <>
      <Dialog
        open={props.open}
        onClose={props.handleClose}
        aria-labelledby="form-dialog-title"
        fullWidth={true}
        maxWidth="md"
        disableBackdropClick={false}
      >
        <DialogTitle id="alert-dialog-title">Send liste</DialogTitle>

        {!loading ? (
          <DialogContent className="customDialogContent">
            
            
            <ChipInput
                label="Jobnummer"
                value={jobNumbers}
                onAdd={handleAddJobNumber}
                onDelete={handleRemoveJobNumber}
                error={jobNumberErrorMessage}
                helperText={
                  jobNumberErrorMessage ?? "tryk enter for hvert jobnummer"
                }
                fullWidth
              />
            <div
              style={{
                marginBottom: 50,
                marginTop: 40,
                display: "grid",
                gridTemplateColumns: "1fr 1fr",
                gap: 20,
              }}
            >
              
              <StatusFilter
                selectedStatuses={statuses}
                handleChange={(e) => setStatuses(e.target.value)}
              />

              <CategoryFilterSimple
                categories={categoryOptions}
                selectedCategories={selectedCategories}
                handleChangeCategories={(e) => handleChangeCategory(e.target)}
              />
            </div>

            <TextField
              margin="dense"
              label="fra"
              type="email"
              name="fromAddress"
              value={fromEmail}
              fullWidth
              disabled
            />
            <TextField
              margin="dense"
              label="til"
              type="email"
              name="toAddress"
              value={toEmail}
              onChange={e => setToEmail(e.target.value)}
              error={emailErrorMessage}
              helperText={emailErrorMessage}
              fullWidth
            />
            <TextField
              margin="dense"
              label="Emne"
              type="text"
              name="subject"
              value={subject}
              onChange={e => setSubject(e.target.value)}
              fullWidth
            />
            <TextField
              margin="dense"
              label="Indhold"
              type="text"
              name="emailBody"
              value={content}
              onChange={e => setContent(e.target.value)}
              fullWidth
              InputProps={{
                multiline: true,
              }}
              rows="16"
            />
          </DialogContent>
        ) : (
          <DialogContent className="customDialogContent">
            <LoadingLogo />
          </DialogContent>
        )}

        <DialogActions>
          <Button onClick={() => props.onCancel()} autoFocus>
            Annuller
          </Button>
          <Button onClick={handleSave} color="primary">
            Send
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
