import React from 'react';
import withStyles from '@material-ui/core/styles/withStyles';
import PropTypes from 'prop-types';
import { Router } from 'react-router-dom';
import history from '../../routes/history';
import CssBaseline from '@material-ui/core/CssBaseline';
import { Paper } from '@material-ui/core';
import AppLoginRoutes from '../../routes/AppLoginRoutes';

const styles = theme => ({
  main: {
    width: 'auto',
    display: 'block', // Fix IE 11 issue.
    marginLeft: theme.spacing(3),
    marginRight: theme.spacing(3),
    [theme.breakpoints.up(400 + theme.spacing(3) * 2)]: {
      width: 400,
      marginLeft: 'auto',
      marginRight: 'auto',
    },
  },
  paper: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: `${theme.spacing(2)}px ${theme.spacing(3)}px ${theme.spacing(3)}px`,
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  }
});


class AppLoginLayout extends React.Component {

  async componentDidMount() {
  }
  render() {
    const { classes } = this.props;

    return (
      <Router history={history}>
        <div className={classes.root}>
          <main className={classes.main}>
            <CssBaseline />
            <Paper className={classes.paper}>
              <img
                src="/images/mb2-logo2.png"
                alt="MultibasenLogo"
                style={{ width: 40 }}
              // {classes.logo}
              />
              <AppLoginRoutes {...this.props} />
            </Paper>
          </main>
        </div>
      </Router>
    );
  }
}

AppLoginLayout.propTypes = {
  classes: PropTypes.object.isRequired,
  // theme: PropTypes.object.isRequired,
};
export default withStyles(styles)(AppLoginLayout);
