import React from "react";

import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import MenuIcon from "@material-ui/icons/Menu";
import IconButton from "@material-ui/core/IconButton";
import Badge from "@material-ui/core/Badge";
import AccountCircle from "@material-ui/icons/AccountCircle";
import NotificationsIcon from "@material-ui/icons/Notifications";
import Maintenance from "./SystemMaintenanceHeader";
export default props => {
  let barColor = {};
  
  if(process.env.REACT_APP_ENVIRONMENT_NAME === "DEVELOPMENT")
    barColor = {backgroundColor : "#997A8D"};
  else if(process.env.REACT_APP_ENVIRONMENT_NAME === "TESTING")
    barColor = {backgroundColor : "#F9A825"};
  else
    barColor = null;

  
  return (
    
    <AppBar position="fixed" className={props.classes.appBar} style={barColor}>
      <Toolbar>
        <IconButton
          color="inherit"
          aria-label="Open drawer"
          onClick={props.handleDrawerToggle}
          className={props.classes.menuButton}
          >
          <MenuIcon />
        </IconButton>
        <img
          src="/images/mb2-logo2.png"
          className="logo"
          alt="MultibasenLogo"
          // {classes.logo}
        />
        <span className="headerText">
          Multibasen
        </span>

        <div className={props.classes.grow} />
        <div className={props.classes.sectionDesktop}>
          <IconButton
            aria-owns={
              props.isNotificationsOpen ? "material-appbar" : undefined
            }
            aria-haspopup="true"
            onClick={
              !props.loading && props.notifications.length > 0
              ? props.handleNotificationsOpen
              : undefined
            }
            color="inherit"
            >
            {props.notifications.filter(x => !x.isRead).length > 0 ? (
              <Badge
              badgeContent={props.notifications.filter(x => !x.isRead).length}
              color="secondary"
              >
                <NotificationsIcon />
              </Badge>
            ) : (
              <NotificationsIcon />
              )}
          </IconButton>
          <IconButton
            aria-owns={props.isMenuOpen ? "material-appbar" : undefined}
            aria-haspopup="true"
            onClick={props.handleMenuOpen}
            color="inherit"
            >
            <AccountCircle />
          </IconButton>
        </div>
      </Toolbar>
      <Maintenance/>
    </AppBar>
  );
};
