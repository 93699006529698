import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core';
import { apiGetParamsReplace, endpoints } from '../../../../constants/services.constants';
import { openDialogFullsizePhoto } from '../../dialogs/DialogFullsizePhoto';

export default function CarouselSlide(props) {
    const { photoId, slideWidth, refreshOfferProductPhoto } = props;

    const [randomNumber, setRandomNumber] = useState(0);

    useEffect(() => {
        setRandomNumber(Math.floor(Math.random() * 10000) + 1);
    }, [refreshOfferProductPhoto])


    const useStyles = makeStyles(() => ({
        card: {
            borderRadius: 5,
            margin: '0px 25px',
            display: 'flex',
            justifyContent: 'center',
        }
    }));

    const classes = useStyles();

    return (
        <div className={classes.card}>
            <div style={{
                height: 88,
                backgroundImage: `url(${apiGetParamsReplace(endpoints.PHOTO_SINGLE_200, photoId)}?randomNumber=${randomNumber})`,
                width: `${slideWidth}px`,
                backgroundSize: "contain",
                backgroundRepeat: "no-repeat",
                backgroundPosition: "center"
            }}
            onClick={() => openDialogFullsizePhoto(photoId)}
            ></div>
        </div>
    );
}