import { FormHelperText, TextField } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import { photoTypeEnum } from "../../../../.././constants/enums";

export function TextFieldComponent({ name, setValue, value }) {
    return (
        <TextField
            margin="normal"
            label={name}
            type="text"
            name={name}
            value={value || ""}
            onChange={e => setValue(e)}
            fullWidth
            id={"textfieldGenericComponent"+name}
        // className={classes.field}
        />
    )
}

export function SelectComponent({ value, handleSelection, name, dataArray, validationError }) {
    return (
        <FormControl
            style={{
                padding: 0,
                marginTop: 16,
            }}
        >
            <InputLabel
                htmlFor={name}>
                {name}
            </InputLabel>
            <Select
                fullWidth
                value={value}
                onChange={e => handleSelection(e)}
                id={"selectorGenericComponent"+name}
                inputProps={{
                    name: name,
                    id: name,
                }}
            >
                {dataArray.map(item => (
                    <MenuItem
                        key={item.id}
                        value={item.id}
                        className={"selectorGenericComponentItems"+name}
                        >
                        {item.name}
                    </MenuItem>
                ))}
            </Select>
            <FormHelperText
                error={validationError && validationError !== ""}>
                {validationError}
            </FormHelperText>
        </FormControl>
    )
}