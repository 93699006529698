import React from "react";
import MUIDataTable from "mui-datatables";
import ActionButtons from "./ActionButtons";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import { connect } from "react-redux";
import {
  CircularProgress,
  Button,
  Tooltip,
  TableRow,
  TableCell,
  withStyles,
  IconButton
} from "@material-ui/core";
import {
  getShortDate,
  removeDuplicatesFromString,
} from "../../../helpers/UtilHelper";
import { getLongDate, stringIsEmpty } from "../../../helpers/UtilHelper";
import { getCustomerPhoto } from "../../../helpers/ImageHelper";

import UnfoldMoreIcon from "@material-ui/icons/UnfoldMore";
import UnfoldLessIcon from "@material-ui/icons/UnfoldLess";
import { getPermissions } from "../../../helpers/CredentialsHelper";
import { canReadApplication } from "../../../helpers/CredentialsHelper";
import { applications } from "../../../constants/app.constants";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { addToastMessage } from "../../../redux/actions/toasts.actions";
import { MuiThemeProvider } from "@material-ui/core/styles";
import { PhototrackerTheme } from "../../../RepublicaMuiTheme";
import SmsIcon from "@material-ui/icons/Sms";
import { photoOrderStatus, photoOrderTypeEnum } from "../../../constants/enums";
import { updatePhotoOrderStatus } from "./SharedRequests";
import PhotoTrackerActionButtons from "./actionButtons/PhotoTrackerActionButtons";

const styles = {
  input: {
    fontSize: "0.8125rem",
    fontWeight: 400,
  },
  instructions: {
    padding: "10px 60px 10px 60px",
    display: "grid",
    gridTemplateColumns: "85px auto",
  },
  commentActive: {
    color: "#71af1e",
    marginTop: 6,
  },
};

export const CustomTooltip = withStyles((theme) => ({
  tooltip: {
    backgroundColor: "#f5f5f9",
    color: "rgba(0, 0, 0, 0.87)",
    fontSize: "0.9rem",
    border: "1px solid #dadde9",
    cursor: "pointer"
  },
}))(Tooltip);

class MUITable extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      statusOverrides: {},
      columns: [],
      expandedItems: [],
    };
  }

  componentDidMount() {
    this.setState({ columns: this.columns() });
  }

  componentDidUpdate(prevProps) {
    if (prevProps.dataRows !== this.props.dataRows) {
      this.setState({ statusOverrides: {} });
    }
    if (this.props.photoOrder) {
      this.getDataTable([this.props.photoOrder]);
    }
  }

  canUpdateStatus = () => {
    const permits = getPermissions("Fototracker.Status");
    if (permits.canRead !== undefined) {
      return permits.canRead;
    } else {
      this.props.dispatch(
        addToastMessage({
          message: "Kunne ikke tjekke tilladelsen til at opdatere foto status.",
          type: "error",
        })
      );
      return;
    }
  };

  async handleChangeStatus(status, photoOrder) {
    this.state.statusOverrides[photoOrder.id] = "loading";
    this.setState({ statusOverrides: this.state.statusOverrides });

    await updatePhotoOrderStatus(photoOrder.id, status.id);

    this.state.statusOverrides[photoOrder.id] = status.key;
    this.setState({ statusOverrides: this.state.statusOverrides });
  }

  getUsagesTooltip(usages) {
    const uniqueUsages = [];
    for (const usage of usages) {
      if (
        !uniqueUsages.some(
          (x) =>
            x.jobNumber === usage.jobNumber && x.pageNumber === usage.pageNumber
        )
      )
        uniqueUsages.push(usage);
    }

    if (uniqueUsages.length === 0) {
      return ["Ikke i brug"];
    }

    const usageTableRows = uniqueUsages
      .sort((a, b) => {
        if (a.publicationStartDate < b.publicationStartDate) return 1;
        if (a.publicationStartDate > b.publicationStartDate) return -1;

        if (a.pageNumber < b.pageNumber) return -1;
        if (a.pageNumber > b.pageNumber) return 1;
        return 0;
      })
      .slice(0, 10)
      .map((x) => {
        return (
          <tr key={x.offerProductId}>
            <td>{x.jobNumber}</td>
            <td>- side {x.pageNumber}</td>
          </tr>
        );
      });

    const tooltip = [
      "Bruges i",
      <table style={{ marginTop: 10 }}>{usageTableRows}</table>,
      uniqueUsages.length > 10 ? (
        <div style={{ color: "grey", fontSize: "10px" }}>
          + {uniqueUsages.length - 1} flere steder
        </div>
      ) : null,
    ];

    return tooltip;
  }

  generateCommentAndTagTooltip = (comment, tags) => {
    let commentFormatted = "";
    let tagsString = "";

    if (comment && !stringIsEmpty(comment)) {
      commentFormatted = comment;
    }

    if (tags.length > 0) {
      tagsString = `${tags.join()} - `;
    }
    return `${tagsString}${commentFormatted}`;
  }

  getDataTable(rows) {
    const { classes, utilsReducer, getPhotoOrderToEdit, categories } =
      this.props;

    return rows.map((photoOrder) => {
      const customer = utilsReducer.customers.find(
        (a) => a.id === photoOrder.customerId
      );
      const customerName = customer == null ? "Ukendt" : customer.name;
      let category = categories.find((x) => x.id == photoOrder.categoryId);
      const regex = /GENBRUG. MotivId valgt som genbrug: [\d-]+\./g;

      const comment = photoOrder.comment
        ? removeDuplicatesFromString(photoOrder.comment, regex)
        : "";

      const commentAndTags = (!stringIsEmpty(comment) || photoOrder.tags.length > 0)
        ? this.generateCommentAndTagTooltip(comment, photoOrder.tags) : "";

      const usageTooltip = this.getUsagesTooltip(photoOrder.usages);
      const usage = photoOrder.usages.find(
        (x) => x.jobNumber === photoOrder.createdOnJobNumber
      );

      // return columns for each row
      return [
        photoOrder.id,
        <img
          style={{ width: 50 }}
          src={getCustomerPhoto(customerName)}
          alt={customerName}
        ></img>,

        <div>
          <div style={{ fontWeight: 700 }}>
            {category ? category.name.toUpperCase() : ""}
          </div>
          {photoOrder.products.map((x) => (
            <div key={x.id}>{x.productNumber}</div>
          ))}
        </div>,
        <div>
          {photoOrder.products.map((x) => (
            <div key={x.id}>{x.name}</div>
          ))}
        </div>,
        <CustomTooltip title={usageTooltip}>
          <CopyToClipboard
            text={photoOrder.createdOnJobNumber + "_" + photoOrder.motiveId}
            onCopy={() =>
              this.props.dispatch(
                addToastMessage({
                  message:
                    photoOrder.createdOnJobNumber +
                    "_" +
                    photoOrder.motiveId +
                    " er kopieret.",
                  type: "success",
                  details: null,
                })
              )
            }
          >
            <Button
              color="secondary"
              variant="text"
              style={{ textTransform: "none", color: "rgba(0, 0, 0, 0.87)" }}
            >
              {photoOrder.createdOnJobNumber}
            </Button>
          </CopyToClipboard>
        </CustomTooltip>,
        usage ? usage.pageNumber : "-",
        <Tooltip title={"Oprettet: "+getShortDate(photoOrder.creationDate)}>
          <span>{photoOrder.motiveId}</span>
        </Tooltip>,

        getShortDate(photoOrder.creationDate),
        photoOrder.createdBy,
        photoOrder.type ? photoOrderTypeEnum[photoOrder.type].name : "Ikke udfyldt",
        <CustomTooltip title={commentAndTags}>
          <SmsIcon
            className={
              !stringIsEmpty(comment) || photoOrder.tags.length > 0 ? classes.commentActive : undefined
            }
            onClick={() => this.props.setShowCommentDialog(photoOrder.id, true)}
          />
        </CustomTooltip>,
        // <ActionButtons
        //   photoOrder={photoOrder}
        //   getPhotoOrderToEdit={(id) => getPhotoOrderToEdit(id)}
        // />,
        <PhotoTrackerActionButtons
          photoOrder={photoOrder}
          getPhotoOrderToEdit={(id) => getPhotoOrderToEdit(id)}
        />,
        <div>
          {this.statusForm(photoOrder)}
          <div style={{ textTransform: "none", color: "rgba(40, 40, 255, 1)" }}>
            {photoOrder.readyForPhotoDate
              ? getLongDate(photoOrder.readyForPhotoDate)
              : null}
          </div>
        </div>,
      ];
    });
  }

  statusForm(photoOrder) {
    const isStatusBlocked =
      photoOrder.status === "DeleteOrder" ||
      photoOrder.status === "Deleted" ||
      photoOrder.status === "ReUse" ||
      !canReadApplication(applications.FOTOTRACKER) ||
      !this.canUpdateStatus();

    // check if the status has been manually changed
    const statusOverride = this.state.statusOverrides[photoOrder.id];

    if (!photoOrder.status) {
      return "Ingen status";
    } else if (statusOverride === "loading") {
      return (
        <CircularProgress
          size={24}
          style={{ marginLeft: 35, position: "relative", top: 4 }}
        />
      );
    } else {
      let status = statusOverride
        ? photoOrderStatus[statusOverride]
        : photoOrderStatus[photoOrder.status];

      if (status === photoOrderStatus.Deleted)
        status = photoOrderStatus.DeleteOrder;

      return (
        <>
          <FormControl style={{ width: 125 }}>
            <Select
              value={status}
              onChange={(e) =>
                this.handleChangeStatus(e.target.value, photoOrder)
              }
              name="status"
              style={{ fontSize: "13px" }}
              disabled={isStatusBlocked} // Check if user permits allow edit on fototracker and change status of the photoorder
            >
              <MenuItem
                key={photoOrderStatus.ProductNotReceived.id}
                value={photoOrderStatus.ProductNotReceived}
              >
                {photoOrderStatus.ProductNotReceived.name}
              </MenuItem>
              <MenuItem
                key={photoOrderStatus.ReadyForPhotography.id}
                value={photoOrderStatus.ReadyForPhotography}
              >
                {photoOrderStatus.ReadyForPhotography.name}
              </MenuItem>
              <MenuItem
                key={photoOrderStatus.ProductPhotographed.id}
                value={photoOrderStatus.ProductPhotographed}
              >
                {photoOrderStatus.ProductPhotographed.name}
              </MenuItem>
              <MenuItem
                key={photoOrderStatus.ReUse.id}
                value={photoOrderStatus.ReUse}
                disabled={true}
              >
                {photoOrderStatus.ReUse.name}
              </MenuItem>
              <MenuItem
                key={photoOrderStatus.ProductReceived.id}
                value={photoOrderStatus.ProductReceived}
              >
                {photoOrderStatus.ProductReceived.name}
              </MenuItem>
              <MenuItem
                key={photoOrderStatus.DeleteOrder.id}
                value={photoOrderStatus.DeleteOrder}
              >
                {photoOrderStatus.DeleteOrder.name}
              </MenuItem>
              <MenuItem
                key={photoOrderStatus.PhotoTrackerUpload.id}
                value={photoOrderStatus.PhotoTrackerUpload}
              >
                {photoOrderStatus.PhotoTrackerUpload.name}
              </MenuItem>
              <MenuItem
                key={photoOrderStatus.ReShoot.id}
                value={photoOrderStatus.ReShoot}
              >
                {photoOrderStatus.ReShoot.name}
              </MenuItem>
              <MenuItem
                key={photoOrderStatus.Inactive.id}
                value={photoOrderStatus.Inactive}
              >
                {photoOrderStatus.Inactive.name}
              </MenuItem>
            </Select>
          </FormControl>
        </>
      );
    }
  }

  columns() {
    return [{
      name: "Id",
      options: {
        display: false,
      },
    }, "Kunde", "EAN (GTIN)", "Varer", "Job nr.", "Side nr.", "Motiv", "Oprettet", "Oprettet af", "Fototype", "Kommentar", "", "Status"];
  }

  render() {
    const { classes, dataRows } = this.props;

    const data = this.getDataTable(dataRows);

    const options = {
      sort: false,
      filter: false,
      pagination: false,
      print: true,
      responsive: "scrollFullHeight",
      download: false,
      viewColumns: false,
      search: false,
      selectableRows: false,
      expandableRows: true,
      rowsExpanded: this.state.expandedItems,
      setRowProps: row => {
        const photoOrder = dataRows.find(x => x.id === row[0]);
        if (photoOrder.priority === "Urgent") {
          return { style: { background: "#FFF000" } }
        }
        if (photoOrder.priority === "SuperUrgent") {
          return { style: { background: "#FF0000" } }
        }
      },
      renderExpandableRow: (rowData) => {
        const photoOrder = dataRows.find((x) => x.id === rowData[0]);
        const colSpan = rowData.length + 1;
        const instr = photoOrder.instructions;
        const comment = photoOrder.comment;
        return (
          <TableRow>
            <TableCell colSpan={colSpan}>
              {instr !== null && !stringIsEmpty(instr) ? (
                <div className={classes.instructions}>
                  <p style={{ fontWeight: 600 }}>Fotobrief: </p>
                  <p>{instr}</p>
                </div>
              ) : undefined}
              {comment !== null && !stringIsEmpty(comment) ? (
                <div className={classes.instructions}>
                  <p style={{ fontWeight: 600 }}>Kommentar: </p>
                  <p>{comment}</p>
                </div>
              ) : undefined}
            </TableCell>
          </TableRow>
        );
      },
      customToolbar: () => {
        return (
          <>
            {this.state.expandedItems.length === 0 ? (
              <Tooltip title={"Fold alle ud"}>
                <IconButton
                  onClick={() =>
                    this.setState({ expandedItems: data.map((x, i) => i) })
                  }
                >
                  <UnfoldMoreIcon />
                </IconButton>
              </Tooltip>
            ) : (
              <Tooltip title={"Fold alle sammen"}>
                <IconButton
                  onClick={() => this.setState({ expandedItems: [] })}
                >
                  <UnfoldLessIcon />
                </IconButton>
              </Tooltip>
            )}
          </>
        );
      }
    };

    return (
      <>
        <MuiThemeProvider theme={PhototrackerTheme}>
          <MUIDataTable
            data={data}
            columns={this.columns()}
            options={options}
          />
        </MuiThemeProvider>
      </>
    );
  }
}
export default connect((state) => state)(withStyles(styles)(MUITable));
