import React, { Component } from 'react'
import { Table, TableHead, TableRow, TableCell, TableBody, Switch, Typography, Button, Grid, withStyles, CardContent, CardActions, Card, MuiThemeProvider } from '@material-ui/core';
import { groupBy2Indexes, groupBy2, compareStrings } from '../../../helpers/UtilHelper';
import { LoadingLinear } from '../../shared/Loading';
import { connect } from "react-redux";
import { removeCustomerPermits, addCustomerPermits, addPermits, removePermits, applyCustomerRole, getUserRoles } from '../../../redux/actions/users/users.actions';
import { PhototrackerTheme } from '../../../RepublicaMuiTheme';
import { UnfoldLess } from '@material-ui/icons';

const styles = theme => ({
    root: {
        width: "100%",
        paddingTop: "5px"
    },
    actionButtons: {
        paddingRight: 20,
        paddingBottom: 20
    },
    paddingRight0: {
        paddingRight: "0 !important"
    },
    colorSwitchBase: {
        color: "#e61919",
        '&$colorChecked': {
            color: "ForestGreen",
        }
    },
    colorBar: {},
    colorChecked: {},
});

const aspNetPermissionsCanDelete = {
    "BilledKombinationer": false, 
    "Brugerstyring": true, 
    "DigitalSigns": true, 
    "Fotos": true, 
    "Fotos.EksklusiveFotos": false, 
    "Fotos.ErstatFoto": false, 
    "Fotos.MineFritlægninger": false, 
    "Fotos.RedigerEksklusiveFotos": false, 
    "Fotos.UploadFoto": false, 
    "Fototracker": true, 
    "Fototracker.Fotograf": false, 
    "Fototracker.Status": false, 
    "HelpPage": true, 
    "Logoer": false, 
    "Produkter": true, 
    "Publikationer": true, 
    "Publikationer.AlmUdgivelser": false, 
    "Publikationer.DigitaleMedier": false, 
    "Publikationer.Fotohåndtering": true, 
    "Publikationer.Fotohåndtering.RedigerTilbud": false, 
    "Tilbud": true, 
    "Tilbud.RedigerPris": false, 
    "Værktøjer.FilmGenerator": false, 
    "Værktøjer.KvitteringsGenerator": false, 
  };

class PermitControls extends Component {
    constructor(props) {
        super(props);
        this.findCustomerById = this.findCustomerById.bind(this);
        this.orderByCustomer = this.orderByCustomer.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.handleAddAllPermits = this.handleAddAllPermits.bind(this);
        this.handleRemoveAllPermits = this.handleRemoveAllPermits.bind(this);
        this.state = {
            roles: null,
        };

    }
    async componentDidMount() {

        var allRoles = (await this.props.dispatch(getUserRoles()));
        this.setState({...this.state, roles:allRoles});
    }

    handleSwitchChange = (event, row) => {

        let target = event.currentTarget;
        target.disabled = true;
        target.parentNode.classList.add('switch-loading');

        let origin = this;
        if (!row[target.name]) {
            row[target.name] = true;
            this.addPermit(
                row.multiUserId,
                row.multiCustomerId,
                row.id,
                target.name,
                () => {
                    target.disabled = false;
                    target.parentNode.classList.remove('switch-loading');
                    origin.setState({});
                },
                () => {
                    target.disabled = false;
                    row[target.name] = false;
                    origin.setState({});
                }

            );
        }
        else {
            row[target.name] = false;
            this.removePermit(
                row.multiUserId,
                row.multiCustomerId,
                row.id,
                target.name,
                () => {
                    target.disabled = false;
                    target.parentNode.classList.remove('switch-loading');
                    origin.setState({});
                },
                () => {
                    target.disabled = false;
                    row[target.name] = true;
                    origin.setState({});
                }

            );
        }

        origin.setState({});

    };


    addPermit = (userId, customerId, permitsId, permit, callback, callbackError) => {
        this.props.dispatch(
            addPermits(
                userId, customerId, permitsId, permit, callback, callbackError));
    };

    removePermit = (userId, customerId, permitsId, permit, callback, callbackError) => {
        this.props.dispatch(
            removePermits(
                userId, customerId, permitsId, permit, callback, callbackError));
    };
    handleAddAllPermits = (userId, customerId) => {
        this.props.dispatch(
            addPermits(
                userId, customerId, null, "all",
                () => { },
                () => { }
            ));

    };

    handleRemoveAllPermits = (userId, customerId) => {
        this.props.dispatch(
            removePermits(
                userId, customerId, null, "all",
                () => { },
                () => { }
            ));
    };


    applyRole(event, customer, role){

        var roleid = role === null ? "00000000-0000-0000-0000-000000000000" : role.id;

        let target = event.currentTarget.parentNode;
        target.disabled = true;

        this.props.dispatch(
            applyCustomerRole(this.props.user.id, customer.id, roleid, 
            (json) => {

                //remove old role
                var newrole = json.customerRoles.find(r=>r.customerId === customer.id);
                var index = this.props.user.customerRoles.findIndex(r=>r.customerId === customer.id);
                if(index !== -1){
                    this.props.user.customerRoles.splice(index, 1);
                }

                //add new role
                if(newrole !== undefined)
                {
                    this.props.user.customerRoles.push(newrole);
                }

                //update permissions
                this.props.user.aspNetUserPermissions.forEach(p=>{

                    if(p.multiCustomerId === customer.id)
                    {
                        var newPermission = json.aspNetUserPermissions.find(n=>n.aspNetPermissionId === p.aspNetPermissionId && n.multiCustomerId === customer.id);

                        if(p.canRead !== newPermission.canRead)
                        {
                            p.canRead = newPermission.canRead;
                        }
                        
                        if(p.canDelete !== newPermission.canDelete)
                        {
                            p.canDelete = newPermission.canDelete;
                        }                        
                    }
                });
            
                target.disabled = false;

                this.setState({});
            }
        ));
    }

    handleAddCustomerPermits = (userId, customerId) => {
        this.props.dispatch(addCustomerPermits(
            userId,
            customerId,
            () => this.setState({ currentCustomer: Number(customerId) })
        ));
    };

    handleRemoveCustomerPermits = (userId, customerId) => {
        this.props.dispatch(removeCustomerPermits(userId, customerId));
    };

    orderByCustomer = (data) => {
        let f = this.findCustomerById.bind(this);
        data.sort(function (a, b) {
            let a2 = f(a);
            let b2 = f(b);
            return a2.name.localeCompare(b2.name);
        });
        return data;
    }
    findCustomerById(customerId) {
        return this.props.customers.find(
            a => a.id === Number(customerId)
        );
    }

    handleChange(customerId) {
        if (this.state.currentCustomer !== Number(customerId)) {
            this.setState({ currentCustomer: Number(customerId) });
        } else {
            this.setState({ currentCustomer: null });
        }
    }

    loadingCustomer(customerId) {
        return this.state.currentCustomer === customerId &&
            (this.props.usersReducer.loadingAddPermits
                || this.props.usersReducer.loadingRemovePermits);
    }

    hasRole(customer){
        if(this.props.user.customerRoles.length === 0)return false;
        return this.props.user.customerRoles.find(c => c.customerId === customer.id) !== undefined;
    }

    isCustomerRole(customer, role){
        if(this.props.user.customerRoles === undefined)return false;
        if(this.props.user.customerRoles.length === 0)return role === null;

        var roleid = role === null ? null : role.id;

        var customerRoleById = this.props.user.customerRoles.find(c => c.customerId === customer.id);

        if(customerRoleById === undefined && role === null){

            return true;
        }

        var result = customerRoleById !== undefined && customerRoleById.roleId === roleid;

        return result;
    }



    renderAccordion() {

        const { classes } = this.props;
        let customerPermits = this.props.user.aspNetUserPermissions.length === 0 ? [] : groupBy2(this.props.user.aspNetUserPermissions, "multiCustomerId");
        let customerIndexes = groupBy2Indexes(customerPermits);
        customerIndexes = this.orderByCustomer(customerIndexes);

        let customerNotAsigned = JSON.parse(JSON.stringify(this.props.customers));

        const noRoles = this.state.roles === null || this.state.roles.length === 0;

        customerIndexes.forEach(e => {
            let i = customerNotAsigned.findIndex(x => x.id === Number(e));
            if (i > -1) {
                customerNotAsigned.splice(i, 1);
            }
        });

        if(this.state.customerRoles !== null)
        {
            this.props.user.customerRoles.forEach(e=>
                {
                    let i = customerNotAsigned.findIndex(x => x.id === Number(e));
                    if (i > -1) {
                        customerNotAsigned.splice(i, 1);
                    }
                });
        }



        return (<>
            <div>
                <br />
                <Typography variant="h6">Tilføj brugerrettigheder</Typography>
                <br />
                {
                    this.props.usersReducer.loadingAddCustomerPermits ?
                        <>
                            <Typography variant="caption">Tilføjer kunde rettigheder til bruger...</Typography>
                            <LoadingLinear />
                        </> :
                        <>
                            {
                                customerNotAsigned.map((customer, index) => {
                                    return (
                                        <Button onClick={() => this.handleAddCustomerPermits(this.props.user.id, customer.id)} variant="contained" color={customer.id === 99 ? "secondary" : "primary"} className="generalMarginSpace5" key={customer.name}>
                                            {customer.name}
                                        </Button>)
                                })
                            }
                        </>
                }
            </div>

            <div className={classes.root}>
                <br />
                <Grid container spacing={2}>
                    {customerIndexes === null ? undefined :
                        customerIndexes.map((customerId, index) => {
                            let customer = this.findCustomerById(customerId);
                            return (
                                <Grid key={index} item xs={12} md={6} lg={4}>
                                    <Card className={classes.card}>
                                        <CardContent>
                                            <Typography variant="h6"> {customer.name}</Typography>

                                            {
                                                this.props.usersReducer.loadingRemoveCustomerPermits
                                                    && this.state.currentCustomer === Number(customerId) ?
                                                    <>
                                                        <Typography variant="caption">Sletter bruger rettigheder...</Typography>
                                                        <LoadingLinear />
                                                    </> :




                                                    <MuiThemeProvider theme={PhototrackerTheme}>

                                                        {
                                                            noRoles  ? <></> :
                                                            <Button  disabled={this.isCustomerRole(customer, null)} onClick={(event) => {this.applyRole(event, customer, null)}} variant="contained" color={customer.id === 99 ? "secondary" : "primary"} className="generalMarginSpace5" key={null}>
                                                                    INGEN
                                                            </Button>
                                                        }           
                                                        {
                                                        
                                                        
                                                            noRoles ? <></> : this.state.roles.map((role, index)=>{
                                                                return (
                                                                    <Button disabled={this.isCustomerRole(customer, role)} onClick={(event) => {this.applyRole(event, customer, role)}} variant="contained" color={customer.id === 99 ? "secondary" : "primary"} className="generalMarginSpace5" key={role.id}>
                                                                        {role.name}
                                                                    </Button>)
                                                            })
                                                        }
                                                    

                                                        <Table >
                                                            <TableHead>
                                                                <TableRow style={{ height: "auto" }}>
                                                                    <TableCell ></TableCell>
                                                                    <TableCell align="center" className={classes.paddingRight0}>Adgang</TableCell>
                                                                    <TableCell align="center" className={classes.paddingRight0}>Slette</TableCell>
                                                                </TableRow>
                                                            </TableHead>
                                                            <TableBody>
                                                                {customerPermits[customerId].sort((a, b) => compareStrings(a.aspNetPermission.name, b.aspNetPermission.name)).map((row, index) => {

                                                                    let allowDelete = aspNetPermissionsCanDelete[row.aspNetPermission.name];
                                                                    if(allowDelete === undefined){
                                                                        throw Error("Missing permission mapping "+row.aspNetPermission.name);
                                                                    }


                                                                    return (

                                                                        <TableRow key={row.id}>
                                                                            <TableCell component="th" scope="row">
                                                                                {row.aspNetPermission.name.replace(".", " " + String.fromCharCode(8594) + " ")}
                                                                            </TableCell>
                                                                            <TableCell align="center" className={classes.paddingRight0}>
                                                                                <Switch
                                                                                    checked={row.canRead}
                                                                                    value={row.canRead}
                                                                                    // onChange={this.handleChange}
                                                                                    disabled={this.hasRole(customer)}
                                                                                    name="canRead"
                                                                                    onChange={event =>
                                                                                        this.handleSwitchChange(event, row)
                                                                                    }
                                                                                    color="primary"
                                                                                    classes={{
                                                                                        switchBase: classes.colorSwitchBase,
                                                                                        checked: classes.colorChecked,
                                                                                        // bar: classes.colorBar,
                                                                                    }}
                                                                                />
                                                                            </TableCell>
                                                                            {allowDelete ?                                                                                
                                                                                <TableCell align="center" className={classes.paddingRight0}>
                                                                                    <Switch
                                                                                        checked={row.canDelete}
                                                                                        value={row.canDelete}
                                                                                        name="canDelete"
                                                                                        disabled={this.hasRole(customer)}
                                                                                        onChange={event =>
                                                                                            this.handleSwitchChange(event, row)
                                                                                        }
                                                                                        color="primary"
                                                                                        classes={{
                                                                                            switchBase: classes.colorSwitchBase,
                                                                                            checked: classes.colorChecked,
                                                                                            // bar: classes.colorBar,
                                                                                        }}
                                                                                    />
                                                                                </TableCell> :
                                                                                <TableCell align="left"></TableCell>
                                                                            }

                                                                        </TableRow>
                                                                    )
                                                                })}
                                                            </TableBody>
                                                        </Table>
                                                    </MuiThemeProvider>
                                            }

                                        </CardContent>
                                        <CardActions className={classes.actionButtons}>
                                            <Grid
                                                justify="flex-end" // Add it here :)
                                                container
                                                spacing={2}
                                            >

                                                <Grid item>
                                                    <Button variant="contained" color="default"
                                                        disabled={this.loadingCustomer(customer.id)}
                                                        onClick={() => this.handleRemoveAllPermits(this.props.user.id, customer.id)} >
                                                        Fravælg alt
                                        </Button>
                                                </Grid>
                                                <Grid item>
                                                    <Button variant="contained" color="default"
                                                        disabled={this.loadingCustomer(customer.id)}
                                                        onClick={() => this.handleAddAllPermits(this.props.user.id, customer.id)}>
                                                        Vælg alt
                                        </Button>
                                                </Grid>
                                                <Grid item>
                                                    <Button variant="contained" color="secondary"
                                                        disabled={this.loadingCustomer(customer.id)}
                                                        onClick={() => this.handleRemoveCustomerPermits(this.props.user.id, customer.id)}>
                                                        Slet
                                        </Button>
                                                </Grid>
                                            </Grid>
                                        </CardActions>
                                    </Card>

                                </Grid>
                            )
                        })
                    }
                </Grid>
            </div>
        </>);
    }

    render() {
        return (
                <>
                    {this.renderAccordion()}            
                </>

        )
    }
}

export default connect(state => state)(withStyles(styles)(PermitControls));