import React from 'react';

export default function EditGreenlandOfferContainer({ children }) {
    return (
        <div style={{
            display: "grid",
            gridTemplateColumns: "repeat(3, minmax(200px, 2fr))",
            gridColumnGap: 10,
            gridAutoRows: "100px",
            alignItems: "center",
            gridTemplateAreas: `
        'OfferDescription picture .'
        '. picture .'
        '. picture .'
        'SavingAmount picture .'
        '. picture .'
        'OfferPrice picture Seconds'
        '. AddBackground .'
        '. Save .'
        `
            ,
        }}>
            {children}
        </div>
    )
}