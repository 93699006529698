import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from "@material-ui/core";
import { FormatColorReset } from "@material-ui/icons";
import React, { useEffect, useState } from "react";
import { photoOrderOrigin, photoOrderTypeEnum, photoTypeEnum } from "../../../../../constants/enums";
import { fetchCredentialsBegin } from "../../../../../redux/actions/credentials.actions";
import { handleApiSuccessNotificaiton } from "../../../../../redux/Handlers/SuccessHandlers";
import store from "../../../../../redux/store";
import { getProducts } from "../../../produkstyring/Requests";
import { fetchPhotoOrderV2, getOfferProducts, getPhotoOrderTags, getPhotoTags } from "../../SharedRequests";
import DialogSuccesfulOrder from "../DialogSuccesfulOrder";
import { handleClose, saveNewPhotoOrder } from "./BusinessLogic/EventHandler";
import { addProductNumber, changeFieldValue, handleChangeDate, handleDeleteGTIN, handlePhotoTypeChange, handleUpdateInputGTIN, setExclusiveCustomerIds, setJobId } from "./BusinessLogic/UpdateFieldsLogic";
import PhotoOrderDialogFields from "./DialogAddPhotoOrderUI/PhotoOrderDialogFields";

export default function DialogAddPhotoOrderV2(props) {
    const { categories, orderOrigin, productId, deletePhotoAfterNewWasUploaded, onPhotoOrderCreated } = props;
    const fotohaandteringReducer = store.getState().fotohaandteringReducer;
    const [referenceFiles, setReferenceFiles] = useState([]);
    const [layoutFiles, setLayoutFiles] = useState([]);
    const [newMotivId, setNewMotivId] = useState(undefined);

    const [loading, setLoading] = useState(false);
    const [state, setState] = useState({
        inputValueGTIN: "",
        multiPhotoParentTypeId: "",

        validation: {
            workbookJodIdErrorMessage: "",
            gtinsErrorMessage: "",
            photoTypeErrorMessage: "",
            kundeErrorMessage: "",
            photoBriefErrorMessage: "",
            categoryErrorMessage: ""
        },
        successDialogOpen: false,
        product: undefined,
        categories: [],
        autoSuggestTags: [],
        autoSuggestPhotoTags: [],
        exclusiveForCustomerIds: [],
        photoTags: [],
        disableJobNumber: false,

        refFiles: [],
        layouts: [],
    });

    const [order, setOrder] = useState({
        jobId: "",
        customerId: "",
        instructions: "",
        type: "", // photo type id
        validFrom: null,
        validTo: null,
        angle: "1",
        location: "",
        productMainCategoryId: undefined,
        comment: "",
        priority: 0,
        origin: orderOrigin ? orderOrigin : 1, // if origin is not sent via props it is bgc
        clipPhoto: true,
        digitalPack: false,
        omskud: false,
        productNumbers: [],
        tags: [],
    });

    useEffect(() => {
        async function getData() {
            const autoSuggestTags = getPhotoOrderTags();
            const autoSuggestPhotoTags = getPhotoTags();
            const [_tags, _photoTags] = await Promise.all([autoSuggestTags, autoSuggestPhotoTags]);

            const firstPhotoType = photoTypeEnum.Pack;
            const subType = photoOrderTypeEnum.Pack;

            const newState = { ...state };
            newState.autoSuggestTags = _tags;
            newState.autoSuggestPhotoTags = _photoTags;
            newState.multiPhotoParentTypeId = firstPhotoType.id;
            setState(newState);


            // Disable job number 
            //------
            // Populate state if photo order is created from publication on offer product 
            // or when deleting last product photo 
            // photo order is created on offer products
            const updatedOrder = { ...order };
            updatedOrder.type = subType.id;

            if (fotohaandteringReducer.dialogAddPhotoOrderOfferProductIds.length > 0 & !productId) {
                // Create from publication
                const offerProducts = await getOfferProducts(fotohaandteringReducer.dialogAddPhotoOrderOfferProductIds);
                const publication = fotohaandteringReducer.publication;

                updatedOrder.customerId = publication ? publication.multiCustomerId : "";
                updatedOrder.productNumbers = offerProducts.map(x => `${x.productNumber}(${x.name})`);
                updatedOrder.jobId = publication ? publication.jobId : "";
            }
            // if photo order is created when deleting last product photo
            else if (productId) {
                const products = await getProducts([productId], false, false);
                if (products) {
                    updatedOrder.productNumbers = products.map(x => `${x.productNumber}(${x.name})`);
                }
            }
            setOrder(updatedOrder);
        }
        getData();
    }, []);

    const handleSave = async () => {
        setLoading(true);

        const newPhotoOrderId = await saveNewPhotoOrder(order, state, referenceFiles, layoutFiles, setState);
        // If newPhotoOrderId is false it means validation failed
        if (!newPhotoOrderId) {
            setLoading(false);
            return;
        }

        //From PhotoTracker 
        if (orderOrigin === parseInt(photoOrderOrigin.PhotoTracker)) {
            // Used in Photos to trigger delete old photo after a new one was ordered
            if (deletePhotoAfterNewWasUploaded) {
                deletePhotoAfterNewWasUploaded(newPhotoOrderId);
            } else {
                handleApiSuccessNotificaiton("Fotobestillingen er oprettet");
                const photoOrder = await fetchPhotoOrderV2(newPhotoOrderId);
                setNewMotivId(photoOrder.photoId);
                handleSuccesfulDialogOpen();
            }

        } else if (orderOrigin === parseInt(photoOrderOrigin.Publication)) {
            //Single Publication Photo  && Group  Publication Photos

            handleClose();

            if (onPhotoOrderCreated) {
                onPhotoOrderCreated();
            }

            handleApiSuccessNotificaiton("Fotobestillingen er oprettet");
        } else {
            console.log("baseOrder.origin", orderOrigin);
        }
        setLoading(false);
    };

    const handleSuccesfulDialogOpen = () => {
        setState({ ...state, successDialogOpen: true });
    };

    const handleCloseDialog = () => {
        setState({ ...state, successDialogOpen: false });
        handleClose();
    };

    return (
        <>
        <Dialog
            open={true}
            onClose={handleClose}
            aria-labelledby="form-dialog-title"
            fullWidth
            maxWidth="md"
        >
            <div>
                <DialogTitle id="form-dialog-title">
                    Opret fotobestilling
                </DialogTitle>
            </div>
            <DialogContent>
                <PhotoOrderDialogFields
                    utilsReducer={store.getState().utilsReducer}
                    credentialsReducer={store.getState().credentialsReducer}
                    // Product numbers field
                    inputValueGTIN={state.inputValueGTIN}
                    handleAddGTIN={(GTIN) => addProductNumber(order, setOrder, state, setState, GTIN)}
                    handleUpdateInputGTIN={(e) => handleUpdateInputGTIN(state, setState, e)}
                    handleDeleteGTIN={(deleteGTIN) => handleDeleteGTIN(order, setOrder, deleteGTIN)}
                    // // Job number field
                    jobNumberDisabled={state.disableJobNumber}
                    handleJobNumberChange={(value) => setJobId(order, setOrder, value)}
                    // // Photo type field
                    phototrackerReducer={store.getState().fototrackerReducer}
                    handlePhotoTypeChange={(e) => handlePhotoTypeChange(order, setOrder, state, setState, state.multiPhotoParentTypeId, e, "ParentType")}
                    multiPhotoParentTypeId={state.multiPhotoParentTypeId}
                    // PhotoSub Type (offerDefinition)
                    handlePhotoSubTypeChange={(e) => handlePhotoTypeChange(order, setOrder, state, setState, state.multiPhotoParentTypeId, e, "SubType")}
                    // // Category
                    categories={categories}
                    // // Comment
                    canComment={true}
                    comment={order.comment}
                    allDisabled={false}
                    // // Layouts 
                    layouts={state.layouts}
                    handleDeleteLayout={(item) => { }} // not used in add photo order dialog
                    layoutFiles={layoutFiles}
                    updateFiles={(files) => setLayoutFiles(files.map(contractFile => contractFile.file))}
                    // //Ref files
                    refFiles={state.refFiles}
                    handleDeleteRefFile={(item) => { }} // not used in add photo order dialog
                    newReferenceFiles={referenceFiles}
                    setNewReferenceFiles={(fileItems) => setReferenceFiles(fileItems.map(contractFile => contractFile.file))}
                    // // dates valid from/to get updated on the photo
                    validFrom={order.validFrom}
                    validTo={order.validTo}
                    handleDateChangeFrom={(date) => handleChangeDate(order, setOrder, date, "validFrom")}
                    handleDateChangeTo={(date) => handleChangeDate(order, setOrder, date, "validTo")}
                    omskud={false}
                    order={order}
                    autoSuggestTags={state.autoSuggestTags}
                    exclusiveForCustomerIds={state.exclusiveForCustomerIds}
                    onExclusiveForCustomerIdsChange={ids => setExclusiveCustomerIds(ids, order.jobId, order.customerId, state, setState)}
                    autoSuggestPhotoTags={state.autoSuggestPhotoTags}
                    photoTags={state.photoTags}
                    setPhotoTags={(newTags) => setState({ ...state, photoTags: newTags })}
                    changeFieldValue={(value, fieldName) => changeFieldValue(order, setOrder, value, fieldName)}
                        origin={orderOrigin}
                        validation={state.validation}

                />
            </DialogContent>
            <DialogActions>
                <Button
                    onClick={handleClose}>
                    Annuller
                </Button>

                <Button
                        onClick={() => handleSave(order, state, referenceFiles, layoutFiles, setState)}
                    color="primary"
                        disabled={loading}
                        name="buttonPhotoOrderCreateOrder"
                        >
                        Opret
                </Button>
            </DialogActions>
        </Dialog>
            {state.successDialogOpen ? (
                <DialogSuccesfulOrder
                    motiv={newMotivId}
                    closeDialog={handleCloseDialog}
                />
            ) : undefined}
        </>

    )
}