import { apiGetParamsReplace, endpoints,features } from "../../../constants/services.constants";
import { headersJsonToken, _request } from "../../../helpers/HttpHelper";

export async function searchPublications(searchRequest) {
  return _request({
    method: "POST",
    endpoint: endpoints.SEARCH_PUBLICATIONS,
    body: JSON.stringify(searchRequest),
    throwError: true
  });
}


export async function getCorrections(publicationIds) {
  return _request({
    method: "POST",
    endpoint: endpoints.PUBLICATION_CORRECTIONS_GET,
    body: JSON.stringify(publicationIds),
  });
}
export async function createCorrectionReport(publicationId) {
  return _request({
    method: "POST",
    endpoint: apiGetParamsReplace(endpoints.PUBLICATION_CORRECTION_ADD, publicationId)
  });
}

export async function generatePublicationReport(publicationId) {
  return _request({
    method: "GET",
    skipBodyParsing: true,
    endpoint: apiGetParamsReplace(endpoints.MONSTRING_ARK, publicationId)
  });
}

export function sendToNewIndesign(requestData){
  return _request({
    method:"POST",
    endpoint : endpoints.INDESIGN_GENERATE_NEW,
    skipBodyParsing: true,
    body:  JSON.stringify(requestData)
  })
}

export async function patchPublicationV2(publicationId, requestData) {
  return _request({
    method: "PUT",
    endpoint: apiGetParamsReplace(endpoints.PUBLICATION_PATCH, publicationId),
    body: JSON.stringify(requestData),
  });
}

export async function getPublicationTags() {
  return _request({
    method: "GET",
    endpoint: apiGetParamsReplace(endpoints.PUBLICATION_TAGS)
  });
}

export function generatePoster(requestData) {
  return _request({
    method: "POST",
    endpoint: endpoints.INDESIGN_GENERATE_POSTERS_FOR_PAGES,
    skipBodyParsing: true,
    body: JSON.stringify(requestData)
  })
}
