import React, { useState, useEffect } from 'react';
import ArrowBackIosRoundedIcon from '@material-ui/icons/ArrowBackIosRounded';
import ArrowForwardIosRoundedIcon from '@material-ui/icons/ArrowForwardIosRounded';
import AddScreenItem from './sharedComponents/AddScreenItem';
import { Scene } from './Scene';
import { endpoints } from '../../../../constants/services.constants';

export default function ScreenCarousel(props) {
    let { items, openFindMedia, blocked, openFindOffer, createFromTemplate } = props;
    const [visibleIndex, setVisibleIndex] = useState(0);
    const take = 5;

    useEffect(() => {
        setVisibleIndex(0);
    }, [items]);

    const onArrowClick = (direction) => {
        const increment = direction === 'left' ? -1 : 1;
        const newIndex = (visibleIndex + increment + items.length) % items.length;

        if (
            newIndex >= 0
            && newIndex + take <= items.length
        )
            setVisibleIndex(newIndex);

        setTimeout(() => {
        }, 0);
    };

    const itemsToRender = items.length < take + 1 ? items
        : items.slice(visibleIndex, visibleIndex + take);

    return (
        <>
            <div className='carouselContainer'>

                {/* Left arrow */}
                {visibleIndex > 0 && items && items.length > 1 &&
                    <div style={{ cursor: "pointer" }} onClick={() => onArrowClick('left')}>
                        <ArrowBackIosRoundedIcon />
                    </div>
                }
                <div style={{ width: '100%', display: 'inline-flex', justifyContent: 'center' }}>
                    {itemsToRender == null ? undefined : itemsToRender.map((z, i) => {
                        return z.state === "add" ?
                            <AddScreenItem
                                {...props}
                                swapActivated
                                item={z}
                                key={Math.floor(Math.random() * 100)}
                                itemIndex={i}
                            /> :
                            <Scene
                                item={z}
                                key={endpoints.STORAGE_PUBLIC_FILMMAKER.replace("{path}",z.urlRelative)}
                                itemIndex={i}
                                {...props}
                            />
                    }
                    )
                    }
                    {createFromTemplate ? undefined :
                        <AddScreenItem
                            {...props}
                        />
                    }
                </div>

                {/* Right arrow */}
                {items && visibleIndex + take < items.length &&
                    <div style={{ cursor: "pointer" }} onClick={() => onArrowClick('right')}>
                        <ArrowForwardIosRoundedIcon />
                    </div>
                }
            </div>

        </>);
}