import { apiGetParamsReplace, endpoints } from "../../../../../constants/services.constants";
import { fetchPostWT, fetchPutWT } from "../../../../../helpers/HttpHelper";


export function fetchRotationRecalculation(bodyObj, callback) {
    fetchPutWT(endpoints.PHOTOCOMBINATION_ROTATIONRECALCULATION,
        JSON.stringify(bodyObj),
        data => {
            callback(data);
        }
        );
}
//TODO: WF
export function saveCombinationTemplate(origin, bodyObj, callback) {
    let endpoint = origin != 250 ?
        apiGetParamsReplace(endpoints.PHOTOCOMBINATION_SAVE, origin)
        : endpoints.PHOTOCOMBINATION_CORRECTIONS;

    fetchPostWT(endpoint, JSON.stringify(bodyObj),
        data => {
            callback(data);
        }
    );
}
export function generateCombination(bodyObj, callback) {
    fetchPostWT(
        endpoints.PHOTO_PHOTOCOMBINATION,
        JSON.stringify(bodyObj),
        data => {
            callback(data);
        }
    );
}
