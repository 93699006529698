import { apiGetParamsReplace, endpoints } from "../../constants/services.constants";
import { getToken } from "../../helpers/AuthHelper";
import { _request, _requestImage, imageHeaders } from "../../helpers/HttpHelper";
import { isPhotoLastProductPhoto } from "../sections/photos/Requests";

import Resources from "../../Resources.json";

export async function getOffersUsingPhoto(photoId) {
    return _request({
        method: "GET",
        endpoint: apiGetParamsReplace(endpoints.OFFER_RELATED_TO_PHOTO, photoId)
    });
}

export async function getPublicationsForOffers(offerIds) {
    return _request({
        method: "POST",
        body: JSON.stringify(offerIds),
        endpoint: endpoints.PUBLICATION_INCLUDES_OFFERS
    });
}

export function getPhotosForProducts(productNumbers, customerId) {
    var customerIdString = customerId == null ||customerId == undefined ? "" : customerId;
    return _request({
        method: "POST",
        body: JSON.stringify(productNumbers),
        endpoint: apiGetParamsReplace(endpoints.PRODUCT_GET_RELATED_TO_OFFER, customerIdString)
    });
}

export async function getPhotoSingle(photoId) {
    return _request({
      method: "GET",
      endpoint: apiGetParamsReplace(endpoints.PHOTO_INFO_SINGLE, photoId)
    });
  }
  export async function getUserById(userId) {
    return _request({
      method: "GET",
      endpoint: apiGetParamsReplace(endpoints.USER_SINGLE, userId)
    });
  }

export async function getPhoto(endpoint) {
    var result = await _requestImage({
      method: "GET",
      endpoint: endpoint,
      throwError: true,
      headers: imageHeaders,
    });
    return result;
  }
export async function getOffer(offerId) {
    return _request({
        method: "GET",
        endpoint: apiGetParamsReplace(endpoints.OFFER_SINGLE_V2, offerId)
    });
}


export async function getOfferProduct(offerProductId) {
    return _request({
        method: "GET",
        endpoint: apiGetParamsReplace(endpoints.OFFER_PRODUCT_SINGLE_V2, offerProductId)
    });
}

export function addPhotoToOfferProduct(offerProductId, photoId) {
    return _request({
        method: "POST",
        endpoint: apiGetParamsReplace(endpoints.OFFER_PRODUCT_PHOTO_ADD_V2, offerProductId, photoId),
        skipBodyParsing: true,
    });
}

export function removePhotoFromOfferProduct(offerProductId, photoId) {
    return _request({
        method: "DELETE",
        endpoint: apiGetParamsReplace(endpoints.OFFER_PRODUCT_PHOTO_REMOVE_V2, offerProductId, photoId),
        skipBodyParsing: true
    });
}

export function setActiveOfferProductPhoto(offerProductId, photoId) {
    return _request({
        method: "PATCH",
        endpoint: apiGetParamsReplace(endpoints.OFFER_PRODUCT_PHOTO_SET_ACTIVE_V2, offerProductId, photoId),
        skipBodyParsing: true
    });
}

export function setOfferProductPhotoAsVariant(offerProductId, photoId, isVariant) {
    return _request({
        method: "PATCH",
        endpoint: apiGetParamsReplace(endpoints.OFFER_PRODUCT_PHOTO_SET_VARIANT_V2, offerProductId, photoId, isVariant),
        skipBodyParsing: true
    });
}

export function getOfferProductPhotosWithPhotoId(photoId) {
    return _request({
        method: "GET",
        endpoint: apiGetParamsReplace(endpoints.OFFER_PRODUCT_PHOTO_WITH_PHOTO_ID, photoId)
    });
}

export function createPhotoForOfferProduct(offerProductIds, file, customerId, imageType, exclusiveForCustomerIds) {
    const formData = new FormData();
    formData.append("file", file);
    formData.append("customerId", customerId);
    formData.append("Type", imageType);

    if (exclusiveForCustomerIds && exclusiveForCustomerIds.length > 0) {
        exclusiveForCustomerIds.forEach((x, index) => formData.append(`exclusiveForCustomerIds[${index}]`, x));
    }

    offerProductIds.forEach((x, index) => formData.append(`RelatedOfferProductIds[${index}]`, x));

    const headers = {
        Accept: "application/json, application/xml, text/plain, text/html, *.*",
        Authorization: "Bearer " + getToken(),
        "x-organization-id" : Resources.Organization
    };

    return _request({
        method: "POST",
        body: formData,
        endpoint: endpoints.PHOTO_UPLOAD,
        headers: headers,
        skipBodyParsing: true
    });
}

export async function getLatestValidToOfferDatePhotoIsUsed(motivId) {
    return _request({
        method: "GET",
        endpoint: apiGetParamsReplace(endpoints.OFFER_LATEST_VALID_TO_DATE_PHOTO_USED, motivId),
        acceptNull: true
    });
}

export async function getOffersTags() {
    const endpoint = endpoints.OFFER_GET_TAGS;
    return _request({
        method: "GET",
        endpoint: endpoint,
        acceptNull: false
    });
}

export async function checkIfPhotoIsLastProductPhoto(photoId) {
    const productIds = await isPhotoLastProductPhoto(photoId);
    let isLastPhoto = false;
    if (productIds.length > 0) {
        isLastPhoto = true;
    }

    return { isLastPhoto, productIds };
}