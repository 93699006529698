import React, { useEffect, useState } from 'react';
import { Dialog, DialogTitle, DialogContent, TextField, DialogActions, Button, LinearProgress, InputAdornment } from '@material-ui/core';
import { FilePond } from 'react-filepond';
import { showSuccessNotification } from '../../../../helpers/UtilHelper';
import createDialog from '../../../../DialogRenderer';
import { getLogo, updateLogo } from '../Requests';
import ChipInput from 'material-ui-chip-input';
import { apiGetParamsReplace, endpoints } from '../../../../constants/services.constants';
import ImageWithPlaceholder from '../../../shared/components/ImageWithPlaceholder';

export async function openDialogEditLogo(logoId) {
    return createDialog(({ show, onSubmit, onDismiss }) => (
        <DialogEditLogo logoId={logoId}
            open={show}
            onCancel={onDismiss}
            onConfirm={onSubmit}
        />
    ));
}

function DialogEditLogo(props) {
    const { logoId, open, onCancel, onConfirm } = props;

    // generate random number to avoid caching of image
    const [randomCacheNumber] = useState(Math.random());
    const [loading, setLoading] = useState(true);
    const [name, setName] = useState("");
    const [mappings, setMappings] = useState([]);
    const [tempMappingsText, setTempMappingsText] = useState("");
    const [showPreview, setShowPreview] = useState(true);
    const [file, setFile] = useState(null);
    const [width, setWidth] = useState(0);
    const [height, setHeight] = useState(0);

    useEffect(() => {
        init();
    }, []);

    const init = async () => {
        try {
            const logo = await getLogo(logoId);
            setName(logo.name);
            setMappings(logo.mappings);
            setWidth(logo.indesignWidth);
            setHeight(logo.indesignHeight);
        } catch (e) {

        } finally {
            setLoading(false);
        }
    }

    const handleSaveClicked = async () => {
        setLoading(true);
        try {
            await updateLogo(logoId, name, mappings, width, height, file);
            showSuccessNotification("Logoet er blevet opdateret");
            onConfirm();
        } catch (e) {
            // showErrorNotification("Logoet kunne ikke blive oprettet");
        } finally {
            setLoading(false);
        }
    }

    const handleFilesChanged = files => {
        if (files && files.length > 0) {
            setFile(files[0].file);
        } else {
            setFile(null);
        }
    }

    const handleAddMapping = text => {
        setTempMappingsText("");
        setMappings([...mappings, text]);
    }

    return (
        <>
            <Dialog
                fullWidth
                maxWidth="sm"
                open={open}
                onClose={onCancel}
            >
                <DialogTitle>Rediger logo</DialogTitle>
                <DialogContent>
                    {loading ? <LinearProgress /> : null}
                    <TextField
                        margin="normal"
                        label="Navn"
                        type="text"
                        value={name}
                        fullWidth
                        onChange={(e) => setName(e.target.value)}
                    />
                    <ChipInput
                        style={{ marginBottom: 20 }}
                        label="Mappings"
                        value={mappings}
                        onAdd={x => handleAddMapping(x)}
                        inputValue={tempMappingsText}
                        onDelete={tag => setMappings(mappings.filter(x => tag !== x))}
                        onUpdateInput={e => setTempMappingsText(e.target.value)}
                        helperText={"tryk enter for hver mapping"}
                        fullWidth
                    />
                    <div>
                        <TextField
                            margin="normal"
                            label="Bredde"
                            type="number"
                            value={width}
                            style={{ marginRight: 20, width: 110 }}
                            onChange={(e) => setWidth(e.target.value)}
                            InputProps={{
                                endAdornment: <InputAdornment position="end">mm</InputAdornment>,
                            }}
                        />
                        <TextField
                            margin="normal"
                            label="Højde"
                            type="number"
                            value={height}
                            style={{ marginRight: 20, width: 110 }}
                            onChange={(e) => setHeight(e.target.value)}
                            disabled
                            InputProps={{
                                endAdornment: <InputAdornment position="end">mm</InputAdornment>,
                            }}
                        />
                    </div>

                    {showPreview ?
                        <div style={{ margin: 20 }}>
                            <ImageWithPlaceholder imageUrl={`${apiGetParamsReplace(endpoints.LOGO_FILE_PREVIEW, logoId)}?${randomCacheNumber}`}
                                placeholder="/images/placeholders/no_preview.png" width={200} height={200} />
                            <Button onClick={() => setShowPreview(false)}>
                                Skift billede
                            </Button>
                        </div>
                        :
                        <div className="filePond" style={{ marginTop: 20 }}>
                            <FilePond
                                allowImagePreview={true}
                                onupdatefiles={files => handleFilesChanged(files)}
                                allowMultiple={false}
                                imagePreviewHeight={300}
                                labelIdle={
                                    'Træk og slip din billedfil eller <span class="filepond--label-action">Gennemse</span>'
                                }
                            />
                        </div>
                    }

                </DialogContent>
                <DialogActions>
                    <Button onClick={onCancel}>
                        Annuller
                    </Button>
                    <Button onClick={handleSaveClicked} color="primary" disabled={loading || !name}>
                        Gem
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    )
}

export default DialogEditLogo