import {
  endpoints,
  apiGetParamsReplace
} from "../../../constants/services.constants";
import { fetchPostWT } from "../../../helpers/HttpHelper";

export const FETCH_LISTE_BEGIN = "FETCH_LISTE_BEGIN";
export const FETCH_LISTE_SUCCESS = "FETCH_LISTE_SUCCESS";
export const FETCH_LISTE_FAILURE = "FETCH_LISTE_FAILURE";

export const fetchListeBegin = () => ({
  type: FETCH_LISTE_BEGIN
});

export const fetchListeSuccess = liste => ({
  type: FETCH_LISTE_SUCCESS,
  payload: { liste }
});

export const fetchListeFailure = error => ({
  type: FETCH_LISTE_FAILURE,
  payload: { error }
});

export function fetchListe(orderIds, callback) {
  const endpoint = endpoints.PHOTO_FETCH_LISTE;

  return dispatch => {
    dispatch(fetchListeBegin());
    return fetchPostWT(
      endpoint,
      JSON.stringify(orderIds),
      (json) => {
        dispatch(fetchListeSuccess(json))
        callback(json);
      },
      (error) => {
        dispatch(fetchListeFailure(error))
      }
    );
  };
}

export const SAVE_LISTE_BEGIN = "SAVE_LISTE_BEGIN";
export const SAVE_LISTE_SUCCESS = "SAVE_LISTE_SUCCESS";
export const SAVE_LISTE_FAILURE = "SAVE_LISTE_FAILURE";

export const saveListeBegin = () => ({
  type: SAVE_LISTE_BEGIN
});

export const saveListeSuccess = liste => ({
  type: SAVE_LISTE_SUCCESS,
  payload: { liste }
});

export const saveListeFailure = error => ({
  type: SAVE_LISTE_FAILURE,
  payload: { error }
});


export function saveListe(liste, callback) {
  const endpoint = endpoints.PHOTO_SET_LISTE;
  let listeBody = Object.assign({}, liste);
  return dispatch => {
    dispatch(saveListeBegin());
    return fetchPostWT(
      endpoint,
      JSON.stringify(listeBody),
      json => {
        dispatch(saveListeSuccess(json));
      },
      error => dispatch(saveListeFailure(error)),
      true
    );
  };
}


