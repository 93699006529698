import { handleApiSuccessNotificaiton } from "../../Handlers/SuccessHandlers";

// OPEN MESSAGE FORM
export const DELETE_OPEN_BEGIN = "USER_DELETE_OPEN_BEGIN";
export const DELETE_CLOSE_BEGIN = "USER_DELETE_CLOSE_BEGIN";

export const deleteDialogOpenBegin = id => ({
  type: DELETE_OPEN_BEGIN,
  payload: { id }
});

export const deleteDialogCloseBegin = () => ({
  type: DELETE_CLOSE_BEGIN
});

export function deleteDialogOpen(id) {
  return dispatch => {
    dispatch(deleteDialogOpenBegin(id));
  };
}
export function deleteDialogClose() {
  return dispatch => {
    dispatch(deleteDialogCloseBegin());
  };
}

// DELETE USER
export const DELETE_BEGIN = "USER_DELETE_BEGIN";
export const DELETE_SUCCESS = "USER_DELETE_SUCCESS";
export const DELETE_FAILURE = "USER_DELETE_FAILURE";

export const deleteUserBegin = () => ({
  type: DELETE_BEGIN
});

export const deleteUserSuccess = () => ({
  type: DELETE_SUCCESS
});

export const deleteUserFailure = error => ({
  type: DELETE_FAILURE,
  payload: { error }
});

export function deleteUser(userId, username) {
  return dispatch => {
    dispatch(deleteUserBegin());
    dispatch(deleteUserSuccess());
    handleApiSuccessNotificaiton("Brugeren " + username + " er slettet");
    dispatch(deleteUserFailure(null));
  };
}
