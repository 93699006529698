import {  endpoints } from "../../../../../../constants/services.constants";
import { _request } from "../../../../../../helpers/HttpHelper";

export async function generatePngFromIframe(url, videoDuration, customerId) {
    return _request({
        method: "POST",
        endpoint: endpoints.FILMMAKER_POSTER_TO_PNG,
        body: JSON.stringify(
            {
                Url: url,
                VideoDuration: parseFloat(videoDuration),
                customerId: customerId
            }
        ),
        throwError: true
    })
}