import React from "react";

// Redux imports
import { connect } from "react-redux";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";

import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";

import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import { LoadingLogo, LoadingLinear } from "../../../shared/Loading";
import { Divider } from "@material-ui/core";
import { createBrief, fetchPhotoOrderV2, fetchUser } from "../SharedRequests";
import createDialog from "../../../../DialogRenderer";
import { getLongDate } from "../../../../helpers/UtilHelper";

export async function openDialogPhotoOrderBriefs(photoOrderId) {
  return createDialog(({ show, onSubmit, onDismiss }) => (
    <DialogPhotoOrderBriefs photoOrderId={photoOrderId}
      open={show}
      onCancel={onDismiss}
      onConfirm={onSubmit}
    />
  ));
}

class DialogPhotoOrderBriefs extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: true,
      briefs: [],
      users: [],
      message: "",
      storageKeyText: "brief_text",
      buttonText: "Send"
    };
  }

  componentDidMount() {
    this.init();
  }

  async init() {
    const photoOrder = await fetchPhotoOrderV2(this.props.photoOrderId);
    const userIds = new Set(photoOrder.briefs.map(x => x.createdByUserId));
    const users = [];
    for (const userId of userIds) {
      try {
        const user = await fetchUser(userId);
        users.push(user);
      } catch {

      }
    }

    // Load message from cache
    let cachedMessage = localStorage.getItem(this.state.storageKeyText);
    if (!cachedMessage) {
      cachedMessage = "";
    }

    this.setState({
      briefs: photoOrder.briefs,
      users: users,
      loading: false,
      message: cachedMessage
    });
  }

  handleChangeSate = event => {
    this.setState({ [event.target.name]: event.target.value });
  };

  handleSendClicked = async () => {
    this.setState({
      buttonText: "Sender..."
    });
    await createBrief(this.props.photoOrderId, this.state.message);
    // clear cached message
    localStorage.removeItem(this.state.storageKeyText);
    this.setState({
      buttonText: "Send"
    });
    this.props.onConfirm();
  };

  isTina(userId) {
    return userId === "ac05086b-964f-4392-9fd0-a12fc992d97b";
  }

  handleClose() {
    // cache message
    localStorage.setItem(this.state.storageKeyText, this.state.message);
    this.props.onCancel();
  }

  render() {

    return (
      <>
        <Dialog
          open={this.props.open}
          onClose={() => this.handleClose()}
          aria-labelledby="form-dialog-title"
          fullWidth={true}
          maxWidth="md"
          disableBackdropClick={false}
        >
          <DialogTitle id="alert-dialog-title">Beskeder</DialogTitle>
          <DialogContent className="customDialogContent">
            <div className="chatContent">


              {this.state.loading ? (
                <LoadingLogo />
              ) : this.state.briefs.length === 0 ? (
                <Typography component={"span"}>Ingen beskeder</Typography>
              ) : (
                <>
                  {this.state.briefs.map(brief => {
                    const user = this.state.users.find(x => x.id === brief.createdByUserId);
                    const userName = user ? user.fullname : "Ukendt";
                    return (
                      <div key={brief.id} className={this.isTina(brief.createdByUserId) ? "cardTina" : "cardOthers"}>
                        <Card className={this.isTina(brief.createdByUserId) ? "cardColorTina" : "cardColorOthers"}>
                          <CardContent className="nLine">
                            <Typography style={{fontSize: 12, marginBottom: 15}}>
                              {getLongDate(brief.created) + "   " + userName}
                            </Typography>

                            <Typography component={"span"} color="inherit">
                              {brief.instructions}
                            </Typography>
                          </CardContent>
                        </Card>
                        <br />
                        <br />
                      </div>
                    )
                  })}
                </>
              )}

              <br />
              <br />
              <br />
            </div>

            <Divider />

            <TextField
              autoFocus
              margin="dense"
              id="message"
              label="Besked"
              rows="4"
              type="text"
              name="message"
              value={this.state.message}
              onChange={this.handleChangeSate}
              InputProps={{
                multiline: true
              }}
              fullWidth
            />
          </DialogContent>

          <DialogActions>
            <Button onClick={() => this.handleClose()} autoFocus>
              Annuller
            </Button>
            <Button onClick={this.handleSendClicked} color="primary" disabled={!this.state.message || !this.state.message.trim()}>
              {this.state.buttonText}
            </Button>
          </DialogActions>
        </Dialog>
      </>
    );
  }
}
export default connect(state => state)(DialogPhotoOrderBriefs);
