import React from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import InputBase from "@material-ui/core/InputBase";


///THIS IS MEANT TO BE TEMPORARY
const styles = {
  root: {
    padding: "2px 4px",
    display: "flex",
    alignItems: "center",
    width: "100%",
  },
  input: {
    marginLeft: 20,
    marginRight: 20,
    flex: 1
  },
  iconButton: {
    padding: 10
  },
  divider: {
    width: 1,
    height: 28,
    margin: 4
  }
};

function LastEanSearchBar(props) {
  const { classes } = props;

  return (
    <Paper className={classes.root} elevation={1}>
      <InputBase
        autoFocus
        className={classes.input}
        placeholder="Ean..."
        onKeyDown={props.keyPress}
        onChange={props.handleChangeQueryString}
        value={props.queryString}
        disabled={props.disabled}
      />
    </Paper>
  );
}

LastEanSearchBar.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withStyles(styles)(LastEanSearchBar);
