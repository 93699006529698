import React from 'react';
import {connect} from 'react-redux';
import {setMessage} from '../../redux/actions/message';
import {getToken} from '../../helpers/AuthHelper';
import { Helmet } from 'react-helmet';

class HomeContainer extends React.Component {
    _onChange = (value) => {
        this.props.dispatch(setMessage(value))
      }
      componentDidMount() {
        
      }
      
 render () {
   return (
     <>
       <Helmet>
         <title>{this.props.title}</title>
       </Helmet>

  { <div style={{
    textAlign : "center",
    position: "absolute",
    top: "50%",
    left: "50%",
    height: 360,
    width: 300,
    transform: `translateY(-50%)`
  }}>
    <img src="/images/mb2-logo2.svg" alt="Multibasen logo" style={{width: "300px",}}></img>
    <h1>Multibasen 2.0</h1>
         <p style={{ width: 450, wordWrap: 'break-word', visibility: "hidden" }}>{getToken()}</p>
  </div> }
   </>
    )
  }
}

export default connect(state => state)(HomeContainer);