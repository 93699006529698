// load and apply active filters from cache
export function loadFiltersFromStorage(searchFilters, storageKey) {
    try {
        const cachedSearchFilters = JSON.parse(localStorage.getItem(storageKey));
        const clone = [...searchFilters];
        for (const filter of searchFilters) {
            const cachedFilter = cachedSearchFilters.find(x => x.key === filter.key);
            const selectedCacheOptions = cachedFilter.options.filter(x => x.selected).map(x => x.key);
            for (const option of filter.options) {
                option.selected = selectedCacheOptions.includes(option.key);
            }
        }
        return clone;
    } catch {
        return searchFilters;
    }
}