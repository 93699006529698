import React from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import DeleteIcon from "@material-ui/icons/Delete";

import DownloadIcon from "@material-ui/icons/CloudDownload";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import EditIcon from "@material-ui/icons/Edit";

import PhotoSizeSelectLargeIcon from "@material-ui/icons/PhotoSizeSelectLarge";
import { IconButton, Tooltip } from "@material-ui/core";
import {
  editDialogOpen,
} from "../../../redux/actions/photos/search.photos.actions";
import { connect } from "react-redux";
import {
  apiGetParamsReplace,
  endpoints,
} from "../../../constants/services.constants";

import { openConfirmClipPhotoDialog } from "./dialogs/DialogConfirmClipPhoto";
import { downloadImage, transformPhoto } from "./Requests";
import PhotoClippingButton from "../../shared/components/PhotoClippingButton";
import { getMotivId, showSuccessNotification } from "../../../helpers/UtilHelper";
import { openDialogEditPhoto } from "./dialogs/DialogEditPhoto";


const styles = (theme) => ({
  extendedIcon: {
    marginRight: theme.spacing(1),
  },
});

// Delete this file after new rewrite was tested
class Btns extends React.Component {
  state = {
    anchorEl: null,
  };

  handleEditPhoto = async (photoId) => {
    const confirmed = await openDialogEditPhoto(photoId, this.props.onEditImage);
    if (confirmed) {
      this.props.onPhotoChanged(photoId);
    }
  };

  handleClickPopper = (event) => {
    this.setState({ anchorEl: event.currentTarget });
  };

  handleClosePopper = () => {
    this.setState({ anchorEl: null });
  };

  isActive = (dateFrom, dateTo) => {
    const dateNow = new Date();
    return dateNow > Date.parse(dateFrom) && dateNow < Date.parse(dateTo);
  };

  async handleClipPhoto() {
    const success = await openConfirmClipPhotoDialog(this.props.photo.motiveId);
    if (success) {
      // todo bjørn- we should update the status of the photo
    }
  }

  handleTransformImage = async motiveId => {
    showSuccessNotification(motiveId + " konverteres...");
    await transformPhoto(motiveId);
    showSuccessNotification(motiveId + " er blevet konvertet");
  }

  render() {
    const { anchorEl } = this.state;
    const { photo } = this.props;

    return (
      <>
        {/*EDIT BUTTON. do not show on deleted*/}
        {photo.status === "Deleted" ? undefined :
          (<Tooltip title="Rediger">
          <IconButton
            className="float-left smaller_icon"
            color="primary"
            aria-label="edit"
            onClick={() => this.handleEditPhoto(photo.id)}
          >
            <EditIcon>edit_icon</EditIcon>
          </IconButton>
          </Tooltip>)
        }

        {/*DOWNLOAD BUTTON */}
        <Tooltip title="Hent">
          <IconButton
            className="float-left smaller_icon"
            color="primary"
            aria-owns={anchorEl ? "simple-menu" : undefined}
            aria-haspopup="true"
            onClick={this.handleClickPopper}
          >
            <DownloadIcon />
          </IconButton>
        </Tooltip>

        {/*DOWNLOAD MENU */}
        <Menu
          anchorEl={anchorEl}
          open={Boolean(anchorEl)}
          onClose={this.handleClosePopper}
        >
          <MenuItem
            onClick={() =>
              downloadImage(
                apiGetParamsReplace(endpoints.PHOTO_SINGLE_200, [
                  photo.id,
                ]),
                photo.id + "-200"
              )
            }
          >
            200px
          </MenuItem>
          <MenuItem
            onClick={() =>
              downloadImage(
                apiGetParamsReplace(endpoints.PHOTO_SINGLE_800, [
                  photo.id,
                ]),
                photo.id + "-800"
              )
            }
          >
            800px
          </MenuItem>
          <MenuItem
            onClick={() =>
              downloadImage(
                apiGetParamsReplace(endpoints.PHOTO_SINGLE_ORG, [
                  photo.id,
                ]),
                photo.id
              )
            }
          >
            Fuld Størrelse
          </MenuItem>
        </Menu>

        <PhotoClippingButton
          photoStatus={photo.status}
          isClipped={photo.isClipped}
          onClick={() => this.handleClipPhoto()}
        />

        {!this.props.canDelete ? (
          ""
        ) : (
          <Tooltip title="Konvertér">
            <IconButton
              className="float-left smaller_icon"
              color="primary"
              aria-label="Tranform"
              onClick={() => this.handleTransformImage(photo.motiveId)}
            >
              <PhotoSizeSelectLargeIcon />
            </IconButton>
          </Tooltip>
        )}

        {/* Will want to display this menu item if we have the appropriate permissions. */}
        {this.props.canDelete && photo.status !== "Deleted" && photo.validTo === null ? (
          <Tooltip title="slet">
            <IconButton
              aria-label="delete"
              onClick={() => this.props.handleDelete(photo)}
              className="float-left smaller_icon"
              color="primary"
              id="buttonPhotoDelete"
            >
              <DeleteIcon />
            </IconButton>
          </Tooltip>
        ) : null}
      </>
    );
  }
}

Btns.propTypes = {
  classes: PropTypes.object.isRequired,
};

const ActionButtons = withStyles(styles)(Btns);

export default connect((state) => state)(ActionButtons);
