import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import InputBase from '@material-ui/core/InputBase';
import IconButton from '@material-ui/core/IconButton';
import SearchIcon from '@material-ui/icons/Search';
import FilterList from '@material-ui/icons/FilterList';
import Grid from '@material-ui/core/Grid';
import SearchFilterPopupV2 from './SearchFilterPopupV2';
import { PagesTwoTone } from '@material-ui/icons';

const styles = {
  root: {
    padding: '2px 4px',
    display: 'flex',
    alignItems: 'center',
    width: '100%',
    marginBottom: 20
  },
  input: {
    marginLeft: 20,
    marginRight: 20,
    flex: 1,
  },
  iconButton: {
    padding: 10,
  },
  divider: {
    width: 1,
    height: 28,
    margin: 4,
  },
  filterIcon: {
    color: "#f02805"
  },
  wrapper: {
    zIndex: 101,
    display: "grid",
    gridTemplateColumns: "100%",
    position: "absolute",
    top: 60,
    width: "100%",
    justifyItems: "end"
  },
  mainContainer: {
    position: "relative"
  }
};


function SearchBarV2(props) {
  const { classes, onSearch, onSearchTextChanged, onSearchFiltersChange,
    searchFilters, searchText, searchPagesFilter, fromTilbud } = props;
  const [showSearchFilterPopover, setShowSearchFilterPopover] = useState(false);

  const handleConfirmPopup = (newFilters, pages) => {
    onSearchFiltersChange(newFilters, pages);
    setShowSearchFilterPopover(false);
  }

  const hasActiveFilter = () => {
    let isActive = false;
    if (searchPagesFilter && searchPagesFilter.length > 0) {
      isActive = true;
    } else {
      for (const category of searchFilters) {
        if (category.options.some(x => x.selected))
          isActive = true;
      }
    }
    return isActive;
  }

  const onKeyDown = e => {
    if (e.keyCode === 13) { // enter
      handleSearch();
    }
  }

  const handleSearch = () => {
    onSearch();
  }


  return (
    <Grid container className={classes.mainContainer}>

      {/* Search bar */}
      <Grid item xs={12}>
        <Paper className={classes.root} elevation={1} >
          <InputBase autoFocus className={classes.input} placeholder="Søg..."
            onKeyDown={onKeyDown}
            onChange={e => onSearchTextChanged(e.target.value)}
            value={searchText} name="textFieldProductSearch"/>
            
          <IconButton className={classes.iconButton}
            onClick={() => onSearch(searchText, searchFilters)} 
            name="buttonProductSearch"
            >
            <SearchIcon />
          </IconButton>

          {(searchFilters && searchFilters.length > 0) || fromTilbud ?
            <IconButton onClick={() => setShowSearchFilterPopover(true)}>
              <FilterList className={hasActiveFilter() ? classes.filterIcon : undefined} />
            </IconButton>
            : undefined
          }

        </Paper>
      </Grid>

      {/* Popover */}
      {((searchFilters.length || fromTilbud) > 0 && showSearchFilterPopover) ?
        <div className={classes.wrapper}>
          <SearchFilterPopupV2
            filters={searchFilters}
            searchPagesFilter={searchPagesFilter}
            onCancel={() => setShowSearchFilterPopover(false)}
            fromTilbud={fromTilbud}
            onSubmit={handleConfirmPopup} />
        </div>
        : undefined}

    </Grid>
  );
}

SearchBarV2.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(SearchBarV2);

