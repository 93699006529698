import React, { useContext } from "react";
import { Tooltip, IconButton } from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import BuildIcon from '@material-ui/icons/Build';
import { FeedsContext } from "../contexts/FeedsContext";

function ActionButtons(props) {
    const { showFeedBuilder: showFeedBuilderState } = useContext(FeedsContext);
    const [, setShowFeedBuilder] = showFeedBuilderState;
    const { item } = props;

    return (
        <>
            {/* Edit feed  */}
            {/* <Tooltip title="Ret feed" placement="top-start">
                <span>
                    <IconButton
                        className="float-left smaller_icon"
                        color="primary"
                        aria-label="overflow button"
                        onClick={() => console.log("edit")}>
                        <EditIcon />
                    </IconButton>
                </span>
            </Tooltip>
            {/* Delete feed  */}
            {/* <Tooltip title="Slet feed" placement="top-start">
                <span>
                    <IconButton
                        className="float-left smaller_icon"
                        color="primary"
                        aria-label="overflow button"
                        onClick={() => console.log("delete")}>
                        <DeleteIcon />
                    </IconButton>
                </span>
            </Tooltip> */}
            {/* Build feed  */}
            <Tooltip title="Feed bygger" placement="top-start">
                <span>
                    <IconButton
                        className="float-left smaller_icon"
                        color="primary"
                        aria-label="overflow button"
                        onClick={() => props.handleOpenFeedBuilder(item.feedId)}>
                        <BuildIcon />
                    </IconButton>
                </span>
            </Tooltip>
        </>
    )
}

export default ActionButtons