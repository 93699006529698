import { MuiThemeProvider, TextField, Typography, Checkbox, InputLabel } from "@material-ui/core";
import { DatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import { withStyles } from "@material-ui/styles";
import React, { useEffect, useState } from "react";
import store from "../../../../redux/store";
import MomentUtils from "@date-io/moment";
import moment from "moment";
import { RepublicaDatePickerMuiTheme } from "../../../../RepublicaMuiTheme";
import GetAutoCompleteSuggestions from "../../fototracker/Dialogs/DialogAdd/GetAutoCompleteSuggestions";
import { CustomerSelect } from "../../fototracker/FototrackerFormInputs";

const styles = (theme) => ({
    row: {
        display: "grid",
        gridTemplateColumns: "1fr 1fr",
        gridGap: "1rem",
    },
    rowFullWidth: {
        display: "grid",
        gridTemplateColumns: "1fr",
    },
    title: {
        marginTop: 40,
        marginBottom: 20,
        display: "flex"
    }
});

function PhotoOrderTab(props) {
    const { classes, productNumber, categoryName,
        customerId, setCustomerId, jobNumber, setJobNumber, validFrom, setValidFrom, validTo, setValidTo, clipPhoto, setClipped} = props;

    return <>
        <div className={classes.title}>
            <Typography variant="h6">Opret fotobestilling</Typography>
        </div>

        <div>
            <Checkbox
                checked={clipPhoto}
                onChange={(e, value) => {setClipped(value)}}
                inputProps={{
                    name: "fritlaegges",
                    id: "fritlaegges-id",
                }}
            />
            <InputLabel htmlFor="fritlaegges-id">
                Fritlægges
            </InputLabel>
        </div>

        <div className={classes.row} style={{ marginTop: 10 }}>
            <TextField
                disabled
                margin="dense"
                label="EAN"
                type="text"
                value={productNumber}
                fullWidth
            />
            <TextField
                disabled
                margin="dense"
                label="Kategori"
                type="text"
                value={categoryName}
                fullWidth
            />
        </div>
        <div className={classes.row} style={{ marginTop: 10 }}>
            <GetAutoCompleteSuggestions
                setJobId={setJobNumber}
                error={!jobNumber}
                helperText={!jobNumber ? "Skal være udfyldt" : null}
            />

            <CustomerSelect
                validation={{ kundeErrorMessage: customerId ? null : "Skal være udfyldt" }}
                utilsReducer={store.getState().utilsReducer}
                credentialsReducer={store.getState().credentialsReducer}
                multiCustomerId={customerId}
                onChange={e => setCustomerId(e.target.value)}
                disabled={false}
            />
        </div>

        <div className={classes.row}>
            <MuiPickersUtilsProvider utils={MomentUtils} moment={moment}>
                <MuiThemeProvider theme={RepublicaDatePickerMuiTheme}>
                    <DatePicker
                        clearable
                        margin="normal"
                        label="Tilgængeligt fra"
                        onChange={setValidFrom}
                        // style={{ float: "left", width: "49%" }}
                        value={validFrom}
                        format="Do[.] MMM"
                    />
                    <DatePicker
                        clearable
                        margin="normal"
                        label="Tilgængeligt til"
                        onChange={setValidTo}
                        // style={{ float: "right", width: "49%" }}
                        value={validTo}
                        format="Do[.] MMM"
                    />
                </MuiThemeProvider>
            </MuiPickersUtilsProvider>
        </div>
    </>
}
export default withStyles(styles)(PhotoOrderTab)