/* eslint-disable react/react-in-jsx-scope */
import SliderProgressBar from '../sliderProgressBar'
import React from "react";
import { IconButton } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import { grey } from '@material-ui/core/colors';

export default function SliderPageMenu({ currentPage, pageCount, handleClose }) {
    return (
        <div style={{
            height: '70px',
            paddingTop: 10,
            marginBottom: 10
        }}>
            <div style={{
                width: 200,
                borderRadius: 20,
                boxShadow: 'rgba(0, 0, 0, 0.4) 0px 5px 16px, rgba(0, 0, 0, 0.2) 0px 0px 1px',
                color: 'rgba(255, 255, 255, 0.9)',
                backgroundColor: 'rgb(45, 52, 57)',
                overflow: 'hidden',
                userSelect: 'none',
                left: '50%',
                transform: 'translate(-50%, 0px)',
                position: 'fixed',
            }}>
                <div style={{ flex: '1 1 auto', textAlign: 'center', height: 50, lineHeight: 3 }}>
                    <span style={{
                        position: "relative",
                        top: 8,
                        left: 83
                    }}>
                        <IconButton
                            className="float-left smaller_icon"
                            color="primary"
                            onClick={() => handleClose()}>
                            <CloseIcon
                                style={{ color: grey[50] }} />
                        </IconButton>
                    </span>
                </div>
                <SliderProgressBar currentPage={currentPage} pageCount={pageCount} />
            </div>
        </div>
    )
}