import React from 'react';
import {  ListItem, ListItemText, Popover, List } from '@material-ui/core';
import { setNotificationAsRead } from './businessLogic/Requests';
import { handleSetNotificationAsRead } from './businessLogic/BusinessLogic';

const style1 = {
  borderBottom: "1px solid #000000",
  padding: 10,
  backgroundColor: "#E0E0E0",
};

const style2 = {
  borderBottom: "1px solid #000000",
  padding: 10,
}

export default (props)=> {
 return   (
 
    <Popover
    id="notificationsPopover"
    open={props.open}
    anchorEl={props.anchorEl}
    onClose={props.handleNotificationsClose}
          anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
          transformOrigin={{ vertical: 'top', horizontal: 'right' }}
  >
     <div>
       <div style={{
         backgroundColor: "#455a64",
         color: "#ffffff",
         height: 50,
         fontSize: "1.2em",
         textDecoration: "underline",
         padding: "10px 10px",
         display: "flex",
         justifyContent: "space-between"
       }}>
         <span
           onClick={() => props.openDialogAllNotifications()}
           style={{ cursor: "pointer" }}
         >
           Se alle
         </span>
         <span
           style={{ cursor: "pointer" }}
           onClick={() => handleSetNotificationAsRead(props.notifications.filter(x => x.isRead === false).map(y => y.id))}
         >
           Ryd alle
         </span>
       </div>
             {props.notifications.map((n,i) => {     
           return (
             <div
               style={n.isRead ? style2 : style1}
               key={i}
               onClick={() => {
                 window.open(`${n.refUrl}`, 'mywindow');
                 if (!n.isRead) {
                   handleSetNotificationAsRead([n.id]);
                 }
               }
               }
             >
                   <h2>
                     {n.header}
                   </h2>
               <p>{n.message}</p>
                 </div>
               )
             })}
     </div>

  </Popover>
    )
}
  