// OPEN PHOTOORDER DIALOG
export const OPEN_ADD_PHOTO_ORDER_DIALOG = "OPEN_ADD_PHOTO_ORDER_DIALOG";
export const openAddPhotoOrderDialog = (offerProductIds) => ({
  type: OPEN_ADD_PHOTO_ORDER_DIALOG,
  payload: { offerProductIds },
});

export const CLOSE_ADD_PHOTO_ORDER_DIALOG = "CLOSE_ADD_PHOTO_ORDER_DIALOG";
export const closeAddPhotoOrderDialog = () => ({
  type: CLOSE_ADD_PHOTO_ORDER_DIALOG,
  payload: null,
});
