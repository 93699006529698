import React, { useEffect, useState } from 'react'
import { Tab, Tabs, withStyles, Typography, LinearProgress } from '@material-ui/core';
import TabRelatedSlider from './TabRelatedSlider';
import TabSearchOffer from './TabSearchOffer';
import TabUpload from './TabUpload';
import { IsFeatureEnabled, featureNames } from '../../../helpers/FeaturesHelper';
const styles = () => ({
  tabsRoot: {
    borderBottom: "1px solid #e8e8e8",
  },
  tabsIndicator: {
    backgroundColor: "#1890ff"
  }
});

function TabsContainer(props) {
  const { onAddPhoto, onUploadFile, allPhotoItems, selectedPhotoIds, loading,
    startEnvironmental, onlyPack, classes, offerProducts, fromPublication,
    customerId, photoTagsAutoSuggest, selectedPhotos } = props;
  const [tabNumber, setTabNumber] = useState(0);

  const [tabs] = useState(new Map());

  const handleChange = (event, value) => {
    setTabNumber(value);
  };

  useEffect(()=>{
  
    //setup tabs
    var index = 0;
    tabs.set(index++, "selected-photos");
    if(IsFeatureEnabled(featureNames.Photos_Create_Enabled)){
      tabs.set(index++, "upload-photo");
    }
    tabs.set(index++, "search-photos");   

  });


  return (
    <>
      <Tabs
        value={tabNumber}
        onChange={handleChange}
        classes={{
          root: classes.tabsRoot,
          indicator: classes.tabsIndicator
        }}
      >
        <Tab
          disableRipple
          classes={{
            root: classes.tabRoot,
            selected: classes.tabSelected
          }}
          label="Udvalgte billeder"
        />
        {! IsFeatureEnabled(featureNames.Photos_Create_Enabled) ? undefined : 
          <Tab
            disableRipple
            classes={{
              root: classes.tabRoot,
              selected: classes.tabSelected
            }}
            label="Upload billede"
          ></Tab>
        }
        <Tab
          disableRipple
          classes={{
            root: classes.tabRoot,
            selected: classes.tabSelected
          }}
          label="Søg billeder"
        />

      </Tabs>
      {tabs.get(tabNumber) === "selected-photos" && (
        loading === true ? <LinearProgress />
          : <TabRelatedSlider
            relatedImages={allPhotoItems}
            selectedPhotos={selectedPhotos}
            handleAddOfferPicture={onAddPhoto}
            isLoading={loading}
            existingOptions={selectedPhotoIds}
            startEnvironmental={startEnvironmental}
            isElectronic={false}
            onlyPack={onlyPack}
            customerId={customerId}
          />

      )}
      {tabs.get(tabNumber) === "upload-photo" && (
        <TabUpload
          onUploadFile={onUploadFile}
          onlyPack={onlyPack}
          startEnvironmental={startEnvironmental}
          offerProducts={offerProducts}
          fromPublication={fromPublication}
          customerId={customerId}
        />
      )}
      {tabs.get(tabNumber) === "search-photos" && (
        <TabSearchOffer
          handleAddOfferPicture={onAddPhoto}
          isLoading={loading}
          onlyPack={onlyPack}
          customerId={customerId}
          photoTagsAutoSuggest={photoTagsAutoSuggest}
        />
      )}
      {loading === true ? <Typography style={{ marginTop: 10 }}>Loading...</Typography> : undefined}
    </>
  )
}

export default withStyles(styles)(TabsContainer);
