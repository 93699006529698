
import React from 'react'
import { useState } from 'react';
import { useEffect } from 'react';
import TemplateItemCarousel from './TemplateItemCarousel';
import Carousel from './Carousel';
import { getTemplates } from '../SharedRequests';
import { openDialogConfirmDelete } from '../../../../shared/dialogs/DialogConfirm';
import { deleteTemplate } from './businessLogic/Requests';
import { Typography } from '@material-ui/core';
import BackButton from './../sharedComponents/BackButton';
import { styles } from '../shared';


export default function Template(
    { setSection,tilbage, customer }
    // { openFindMedia, selectTemplate,
    // items, removeItem }
) {
    const [templates, setTemplates] = useState([]);

    const removeTemplate = async (item) => {
        const message = `Ønsker du at slette skabelon?`;
        const confirmed = await openDialogConfirmDelete("Slet skabelon", message);
        if (confirmed) {
            await deleteTemplate(item.id);
            await fetchAllTemplates();
        }
    };

    useEffect(() => {
        const runEffect = async () => {
            await fetchAllTemplates();
        };
        runEffect();
    }, []);

    const fetchAllTemplates = async () => {
        let _templates = await getTemplates(customer);
        _templates = _templates.map((item, index) =>
            <TemplateItemCarousel
                key={`template_${index}`}
                item={item}
                selectTemplate={(selectedTemplateId) => setSection("screen/createFromTemplate/" + selectedTemplateId)}
                deleteTemplate={(item) => removeTemplate(item)}
            />)
        setTemplates(_templates);
    }

    return (
        <div>         
            <div style={{ width: '100%', display: 'inline-flex', justifyContent: 'space-between' }}>
                <Typography variant="h4" style={styles.viewText}>Vælg skabelon</Typography>
                <BackButton
                    tilbage={()=>tilbage()}
                />
            </div>
            <Carousel
                items={templates}
            />
        </div>
    )
}
