import React from 'react'
import { Paper } from '@material-ui/core';
import { Typography } from '@material-ui/core';
import MainMenu from './MainMenu';
import MediaLibrary from './MediaLibrary';
import { useState } from 'react';
import { styles } from './shared';
import Screen from './Screen';
import Templates from './templates/Templates';
import {customersDictionary} from "../../../../constants/enums";
import BackgroundLibrary from './backgroundComponent/BckgroundLibrary';

export default function FilmMakerApp({ match }) {
    const [section, setSection] = useState(
        match.params.section == null
            || match.params.section.trim() === "" ? "home"
            : match.params.section.toLowerCase());
            const [customer, ] = useState(match.params.customer);
    
    const renderSection = () => {
        if (section == null) return <Typography variant="h5" align="center">Url not found</Typography>;
        if (section === "home") {
            return <MainMenu 
            setSection={setSection} 
            customer={customersDictionary[customer].id}
            />
        }
        if (section === "media") {
            return (
                <MediaLibrary
                    customer={customersDictionary[customer].id}
                    tilbage={() => setSection( "home")}
                />
            )
        }
        if (section === "screen") {
            return (
                <Screen 
                    customer={customersDictionary[customer].id}
                    tilbage={(to) => { setSection(to || "home") }} />
            ) 
        }
        if (section.startsWith("screen/createFromTemplate/")) {
            return (
                <Screen
                    customer={customersDictionary[customer].id}
                    createFromTemplate={section.split("/").at(-1)}
                    tilbage={(to) => { setSection(to || "home") }} />
            )
        }
        if (section.startsWith("screen/")) {
            return (
                <Screen
                    customer={customersDictionary[customer].id}
                    selection={section.split("/").at(-1)}
                    tilbage={(to) => setSection(to || "home")} />
            )
        }
        if (section === "templates") {
            return (
                <Templates
                    customer={customersDictionary[customer].id}
                    tilbage={() => setSection( "home")}
                    setSection={setSection}
                />
            )
        }
        if (section === "background") {
            return (
                <BackgroundLibrary
                    customer={customersDictionary[customer].id}
                    tilbage={() => setSection( "home")}
                    setSection={setSection}
                />
            )
        }

        return <Typography variant="h5" align="center">Url not found</Typography>;
    }
    return (
        <div>
            <Paper elevation={0} style={styles.titlePaper}>
                <Typography
                    variant="h3"
                    style={styles.titleText}>
                    Skærm automatisering
                </Typography>
            </Paper>

            <Paper elevation={0} style={{ ...styles.viewPaper, overflowX: 'hidden' }}>
                {renderSection()}
            </Paper>
        </div>
    )
}
