import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import InputBase from '@material-ui/core/InputBase';
import IconButton from '@material-ui/core/IconButton';
import SearchIcon from '@material-ui/icons/Search';

const styles = {
    root: {
        padding: '2px 4px',
        display: 'flex',
        alignItems: 'center',
        width: '100%',
    },
    input: {
        marginLeft: 20,
        marginRight: 20,
        flex: 1,
    },
    iconButton: {
        padding: 10,
    },
    divider: {
        width: 1,
        height: 28,
        margin: 4,
    },
    filterIcon: {
        color: "#568BAD"
    },
    wrapper: {
        zIndex: 101,
        display: "grid",
        gridTemplateColumns: "100%",
        position: "absolute",
        top: 60,
        width: "100%",
        justifyItems: "end"
    },
    mainContainer: {
        position: "relative"
    }
};

function CustomizedInputBase(props) {
    const { classes, filterOptions, filterIsActive, savedQueryParameters } = props;
    const [searchIsOpen, setSearchIsOpen] = useState(false);
    const [isActive, setIsActive] = useState(false)

    return (
        <Paper className={classes.root} elevation={1} >
            <InputBase autoFocus className={classes.input} placeholder="Søg fritlægninger"
                onKeyDown={props.keyPress}
                onChange={props.changeQueryString}
                value={props.queryString} />
            <IconButton className={classes.iconButton} aria-label="Search"
                onClick={props.performSearch} >
                <SearchIcon />
            </IconButton>
        </Paper>

    );
}

CustomizedInputBase.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(CustomizedInputBase);