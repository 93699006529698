import React from 'react';
import { withStyles, Button } from '@material-ui/core';


const FilmMakerButton = withStyles(theme => ({
    root: {
        width: 350,
        backgroundColor: "#6b8778",
        fontWeight: 600,
        color: "#ffffff",
        borderRadius: "2em",
        padding: 12,
        fontSize: 20,
        textTransform: "none"
    },
}))(Button);

export default function ActionButton({ text, className, onClick, disabled }) {
    return (
        <FilmMakerButton
            className={className}
            onClick={onClick}
            disabled={disabled}
        >
            {text}
        </FilmMakerButton>
    )

}