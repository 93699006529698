import { appThemeConstants } from "../../constants/app.constants";

const drawerWidth = appThemeConstants.DRAWER_WIDTH;
export default theme => ({
  root: {
    display: "flex"
  },
  drawer: {
    [theme.breakpoints.up("md")]: {
      width: drawerWidth,
      flexShrink: 0
    }
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1
  },
  menuButton: {
    marginRight: 20,
    [theme.breakpoints.up("md")]: {
      display: "none"
    }
  },
  toolbar: theme.mixins.toolbar,
  drawerPaper: {
    width: drawerWidth,
    background: appThemeConstants.PRIMARY_3 //'#3c3c3c',
  },
  content: {
    flexGrow: 1,
    padding: 30,
    maxWidth: 1500,
    margin: "0 auto",
    minWidth: 0
  },
  grow: {
    flexGrow: 1
  },
  sectionDesktop: {
    display: "none",
    [theme.breakpoints.up("md")]: {
      display: "flex"
    }
  },
  builtWithLove: {
    padding: theme.spacing(2),
    fontSize: "0.70rem"
  },
  footer: {
    padding: "5px 30px",
    // borderTop: '1px solid rgba(0,0,0,.05)',
    fontSize: "11px",
    lineHeight: "17px",
    zIndex: -1,
    position: "absolute",
    left: 0,
    right: 0,
    bottom: 0,
    [theme.breakpoints.up("md")]: {
      left: drawerWidth
    }
  }
});
