import React, { useEffect } from "react";
import { useState } from "react";
import { fetchFeedsRequest } from "../feeds/Logic/requests";
import { fetchTemplatesRequest, fetchMediaTransportsRequest, fetchMediaTypesRequest, fetchMediaDefinitions } from "../media/Logic/Requests";

export const MediaContext = React.createContext({ media: [], templates: [], mediaTransports: [], mediaTypes: [], selectedMedia: {}, confirmDelete: [], loading: false, openCopyLinkDialog: false });

const Index = ({ children }) => {
    useEffect(() => {
        const runEffect = async () => {
            // Fetch media definitions
            setLoading(true);
            const mediaData = await fetchMediaDefinitions();
            if (!mediaData) {
                setLoading(false);
                setMedia([]);
            } else {
                setLoading(false);
                setMedia(mediaData);
            }

            // Fetch media templates
            const templatesData = await fetchTemplatesRequest();
            if (!templatesData) {
                setTemplates([]);
            } else {
                setTemplates(templatesData);
            }

            // Fetch media templates
            const mediaTransportsData = await fetchMediaTransportsRequest();
            if (!mediaTransportsData) {
                setMediaTransports([]);
            } else {
                setMediaTransports(mediaTransportsData);
            }

            // Fetch media types
            const mediaTypesData = await fetchMediaTypesRequest();
            if (!mediaTypesData) {
                setMediaTypes([]);
            } else {
                setMediaTypes(mediaTypesData);
            }
        };
        runEffect();
        
    }, []);

    const [media, setMedia] = useState([]);
    const [templates, setTemplates] = useState([]);
    const [mediaTransports, setMediaTransports] = useState([]);
    const [mediaTypes, setMediaTypes] = useState([]);
    const [confirmDelete, setConfirmDelete] = useState(false);
    const [selectedMedia, setSelectedMedia] = useState({});
    const [loading, setLoading] = useState(false);
    const [openCopyLinkDialog, setOpenCopyLinkDialog] = useState(false);
    return (
        <MediaContext.Provider value={{
            media: [media, setMedia],
            templates: [templates, setTemplates],
            mediaTransports: [mediaTransports, setMediaTransports],
            mediaTypes: [mediaTypes, setMediaTypes],
            confirmDelete: [confirmDelete, setConfirmDelete],
            selectedMedia: [selectedMedia, setSelectedMedia],
            loading: [loading, setLoading],
            openCopyLinkDialog: [openCopyLinkDialog, setOpenCopyLinkDialog],
        }}>
            {children}
        </MediaContext.Provider>
    )
}

export default Index;