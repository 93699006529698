import React, { useEffect, useState } from "react";
import { getMotivId, showSuccessNotification } from "../../../../helpers/UtilHelper";
import { ActionButtonSimple } from "../../../shared/components/tableActionButton/ActionButtonSimple";
import { TooltipTexts } from "../../../../constants/enums";
import { AddPhotoIconComponent, ImageIconComponent, MessageIconComponent, MoreVertIconComponent } from "../../../../constants/IconsExport";
import { DropdownMenu } from "../../../shared/components/tableActionButton/DropdownMenu";
import { handleUploadClickOpen } from "./EventHandler";
import { openDialogFullsizePhoto } from "../../../shared/dialogs/DialogFullsizePhoto";
import { checkPhotoUploadIsAllowed } from "./BusinessLogic";
import { openDialogPhotoOrderBriefs } from "../Dialogs/DialogPhotoOrderBriefs";
import { withStyles } from "@material-ui/styles";
import { canDeleteApplication, canReadApplication } from "../../../../helpers/CredentialsHelper";
import { applications } from "../../../../constants/app.constants";
import store from "../../../../redux/store";
import { phototrackerDialogOpen } from "../../../../redux/actions/photos/fototracker.photos.actions";
import { openDialogLayout } from "../Dialogs/DialogLayout";
import { openDialogReference } from "../Dialogs/DialogReference";
import { openDialogConfirmDelete } from "../../../shared/dialogs/DialogConfirm";
import { deletePhotoOrder } from "../SharedRequests";

const styles = {
    hasMessages: {
        color: "#71af1e"
    }
};

function PhotoTrackerActionButtons(props) {
    const { photoOrder, getPhotoOrderToEdit } = props;
    const [anchorEl, setAnchorEl] = useState(null);
    const [dropdownMenuData, setDropdownMenuData] = useState([]);

    useEffect(() => {
        const data = dropdownMenuBuilder(photoOrder);
        setDropdownMenuData(data);
    }, []);

    const handleEditClickOpen = id => {
        store.dispatch(phototrackerDialogOpen(id));
        getPhotoOrderToEdit(id);
        setAnchorEl(null);
    };

    const handleSkitseClickOpen = photoOrderId => {
        openDialogLayout(photoOrderId)
        setAnchorEl(null);
    };

    const handleReferenceClickOpen = photoOrderId => {
        openDialogReference(photoOrderId);
        setAnchorEl(null);
    };

    const handleDeleteClick = async () => {
        const confirmed = await openDialogConfirmDelete("Slet fotobestilling", `Vil du slette fotobestilling med motiv-ID: ${getMotivId(photoOrder.photoId)} permanent?`);
        if (confirmed) {
            await deletePhotoOrder(photoOrder.id);
            showSuccessNotification("Fotobestillingen er blevet slettet");
        };
        setAnchorEl(null);
    };

    // Data for the dropdown menu items, todo think about better way to do it
    const dropdownMenuBuilder = (photoOrder) => {
        let dropdownMenuData = [];

        const isActive = photoOrder.status !== 'Inactive'

        if (isActive && canReadApplication(applications.FOTOTRACKER)) {
            dropdownMenuData.push(
                {
                    "func": handleEditClickOpen,
                    "arguments": [photoOrder.id],
                    "name": "Rediger bestilling"
                }
            );
        };
        if (photoOrder.hasLayoutFiles) {
            dropdownMenuData.push(
                {
                    "func": handleSkitseClickOpen,
                    "arguments": [photoOrder.id],
                    "name": "Se skitser"
                }
            );
        };
        if (photoOrder.hasReferenceFiles) {
            dropdownMenuData.push(
                {
                    "func": handleReferenceClickOpen,
                    "arguments": [photoOrder.id],
                    "name": "Se reference filer"
                }
            );
        };
        if (canDeleteApplication(applications.FOTOTRACKER)) {
            dropdownMenuData.push(
                {
                    "func": handleDeleteClick,
                    "arguments": [photoOrder.id],
                    "name": "Slet permanent"
                }
            );
        }

        return dropdownMenuData;
    }

    const showMoreOptionsMenu = 
        !canReadApplication(applications.FOTOTRACKER) && 
        !canDeleteApplication(applications.FOTOTRACKER) &&
        !photoOrder.hasLayoutFiles && 
        !photoOrder.hasReferenceFiles;

        const isActive = photoOrder.status !== 'Inactive'
        
        const photoUploadDisabled = 
            !isActive || 
            !checkPhotoUploadIsAllowed(photoOrder);

    return (
        <>
            <div style={{ width: 150 }}>
                {/* SHOW PHOTO BUTTON */}
                <ActionButtonSimple
                    toolTipTitle={TooltipTexts.ShowPhoto}
                    onClick={() => openDialogFullsizePhoto(!photoOrder.reusePhotoId ? photoOrder.photoId : photoOrder.reusePhotoId)}
                    icon={ImageIconComponent}
                />
                {/*ADD PHOTO BUTTON */}
                <ActionButtonSimple
                    toolTipTitle={TooltipTexts.UploadPhoto}
                    onClick={() => handleUploadClickOpen(photoOrder.id, photoOrder.customerId)}
                    icon={AddPhotoIconComponent}
                    disabled={photoUploadDisabled}
                />

                {/* MESSAGE BUTTON */}
                <ActionButtonSimple
                    toolTipTitle={TooltipTexts.Chat}
                    onClick={() => openDialogPhotoOrderBriefs(photoOrder.id)}
                    icon={<MessageIconComponent className={photoOrder.hasBriefs ? props.classes.hasMessages : undefined} />}
                />
            </div>
            {showMoreOptionsMenu ?
                undefined :
                <ActionButtonSimple
                    toolTipTitle={TooltipTexts.MoreOptions}
                    onClick={(e) => setAnchorEl(e.currentTarget)}
                    showMenu={anchorEl ? "simple-menu" : undefined}
                    icon={MoreVertIconComponent}
                />
            }
            {/*DOWNLOAD MENU */}
            <DropdownMenu
                anchorEl={anchorEl}
                handleClosePopper={() => setAnchorEl(null)}
                menuItemData={dropdownMenuData}
            />

        </>
    )
}

export default (withStyles(styles)(PhotoTrackerActionButtons));