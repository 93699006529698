import { getReadPermissions } from "../../../helpers/CredentialsHelper";
import Resources from "../../../Resources.json"
import {GetResource} from "../../../helpers/ResourceHelper" 
import { IsFeatureEnabled, featureNames } from "../../../helpers/FeaturesHelper";
export function getProductFiltersForUi(allCustomers) {
    const filters = [];

    if(IsFeatureEnabled(featureNames.Products_Categories_Enabled))
    {   
        // category
        filters.push({
            key: "category",
            displayName: "Kategori",
            options: [
                { key: 13, displayName: "Bolig", selected: false },
                { key: 3, displayName: "Brød og mejeri", selected: false },
                { key: 14, displayName: "Elektronik", selected: false },
                { key: 2, displayName: "Frugt, grønt og blomster", selected: false },
                { key: 9, displayName: "Hushold pers pleje og helsekost", selected: false },
                { key: 7, displayName: "Kolonial og Dybfrost", selected: false },
                { key: 1, displayName: "Kød, fisk og viktualier", selected: false },
                { key: 6, displayName: "Nydelsesmidler og kioskvarer", selected: false },
                { key: 11, displayName: "Tekstil", selected: false },
                { key: 8, displayName: "Vin og spiritus", selected: false }
            ]
        });
    }

    if(IsFeatureEnabled(featureNames.Products_Customer_Enabled))
    {       
        // customers
        const visibleCustomerIds = getReadPermissions("Produkter");
        const customers = allCustomers.filter(x => visibleCustomerIds.includes(x.id));
        filters.push({
            key: "customer",
            displayName: GetResource(Resources.Photos_Search_Customer_Title),
            options: customers.map(x => {
                return { key: x.id, displayName: x.name, selected: false }
            })
        });
    }

    return filters;
}

export function getProductFiltersForRequest(uiFilters) {

   
    const categoryOptions = IsFeatureEnabled(featureNames.Products_Categories_Enabled) ? uiFilters.find(x => x.key === "category").options : [];
    const customerOptions = IsFeatureEnabled(featureNames.Products_Customer_Enabled) ? uiFilters.find(x => x.key === "customer").options : [];
    return {
        mainCategoryIds: categoryOptions.filter(x => x.selected).map(x => x.key),
        customerIds: customerOptions.filter(x => x.selected).map(x => x.key),
    };
}