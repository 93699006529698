import React from 'react';
import { Dialog, DialogContent, DialogTitle } from '@material-ui/core';
import { LoadingLogo } from '../Loading';


export default function DialogLongProcessProgress({ message }) {
    return (
        <Dialog open={true}
        >
            <DialogTitle>{message}</DialogTitle>
            <DialogContent>
                <div style={{ width: 500, height: 200 }}>
                    <LoadingLogo />
                </div>
            </DialogContent>
        </Dialog>
    )
}
