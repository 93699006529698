import React, { useEffect, useState, useContext } from 'react';
import { Typography, withStyles, Fab, LinearProgress, Tooltip } from '@material-ui/core';
import FeedsContextProvider, { FeedsContext } from '../contexts/FeedsContext';
import AddIcon from "@material-ui/icons/Add";
import FeedsTable from './SortingSelecting';
import DialogAdd from '../feeds/Dialogs/DialogAdd';
import CollectionsContextProvider, { CollectionsContext } from '../contexts/CollectionsContext';
import DialogFeedBuilder from './Dialogs/FeedBuilder/DialogFeedBuilder';
import { fetchFilterPropertiesRequest, getFilterRequest, filterExistsRequest, feedApplyFilter } from './Logic/requests';
import SearchBar from '../../../shared/components/searchBar/SearchBar';

const styles = theme => ({
    root: {
        display: "grid",
        gridTemplateColumns: "auto 65px"
    },
    iconButton: {
        margin: "0px 0px 20px 15px"
    }
});

export function Feeds(props) {
    return (
        <FeedsContextProvider>
            <CollectionsContextProvider>
                <FeedsBase {...props} />
            </CollectionsContextProvider>
        </FeedsContextProvider>
    )
}

function FeedsBase(props) {
    const { classes } = props;
    const {
        feeds: feedsState,
        showFeedBuilder: showFeedBuilderState,
        filterProperties: filterPropertiesState,
        filterMaxLength: filterMaxLengthState,
        filterChild: filterChildState,
        selectedFeed: selectedFeedState,
        selectedFilter: selectedFilterState,
        filterExists: filterExistsState,
        filterData: filterDataState,
        loading: loadingState,
        selectedOptions: selectedOptionsState,
        lastConnectorIndex: lastConnectorIndexState,
        filterStatistics: filterStatisticsState,
    } = useContext(FeedsContext);

    const [feeds,] = feedsState;
    const [showFeedBuilder, setShowFeedBuilder] = showFeedBuilderState;
    const [filterProperties, setFilterProperties] = filterPropertiesState;
    const [, setSelectedIndex] = selectedFeedState;
    const [, setSelectedFilter] = selectedFilterState;
    const { collections: collectionsState } = useContext(CollectionsContext);
    const [collections,] = collectionsState;
    const [showAddDialog, setShowAddDialog] = useState(false);
    const [filterEdit,] = useState(false);
    const [, setFilterExists] = filterExistsState;
    const [, setFilterData] = filterDataState;
    const [loading,] = loadingState;
    const [, setFilterMaxLength] = filterMaxLengthState;
    const [, setFilterChild] = filterChildState;
    const [selectedOptions, setSelectedOptions] = selectedOptionsState;
    const [lastConnectorIndex, setLastConnectorIndex] = lastConnectorIndexState;
    const [filterStatistics, setFilterStatistics] = filterStatisticsState;

    const handleClose = () => {
        setShowAddDialog(false);
    }

    const getFilter = async (id) => {
        let filter = await getFilterRequest(id);
        // check if filter id exists 
        setSelectedFilter(filter);

        return filter;
    }

    const handleOpenFeedBuilder = async (feedId) => {
        let type = "multibasen";
        setSelectedIndex(feeds.findIndex(x => x.feedId === feedId));
        let propertiesArr = await fetchFilterPropertiesRequest(type);
        setFilterProperties(propertiesArr);
        setFilterMaxLength(propertiesArr.length);

        // Check there is filter exists on selected feed
        let filterExists = await filterExistsRequest(feedId); // filterId === feedId
        setFilterExists(filterExists);
        let filterSelect = {};

        // Get data for the statistics
        let statistics = await feedApplyFilter(feedId);
        setFilterStatistics(statistics);

        // Get filter 
        if (filterExists) {
            filterSelect = await getFilter(feedId);

            let connectors = filterSelect.statements.map(x => x.connector);

            setFilterData(filterSelect);
            setFilterChild(filterSelect.statements);
            setLastConnectorIndex(filterSelect.statements.length - 1);
            setSelectedOptions(connectors);
        }

        setShowFeedBuilder(true);
    }

    return (
        <>
            <Typography variant="h4">Feeds</Typography>
            <div className={classes.root}>
                <div style={{ visibility: "hidden" }}>
                    <SearchBar />
                </div>
                <Tooltip title={"Opret feed"}>
                    <Fab
                        size="small"
                        color="primary"
                        className={classes.iconButton}
                        onClick={() => setShowAddDialog(true)}
                    >
                        <AddIcon />
                    </Fab>
                </Tooltip>
            </div>
            {feeds && feeds.length !== 0 && !loading && collections.length !== 0 ? <FeedsTable feeds={feeds} handleOpenFeedBuilder={(id) => handleOpenFeedBuilder(id)} /> : feeds && feeds.length === 0 && !loading ? <Typography style={{ textAlign: "center" }} variant="h6">Ingen feeds er fundet</Typography> : <LinearProgress />}
            {showAddDialog ?
                <DialogAdd
                    open={showAddDialog}
                    handleClose={handleClose}
                    collections={collections}
                /> : undefined}
            {showFeedBuilder && filterProperties.length !== 0 ? <DialogFeedBuilder open={true} filterEdit={filterEdit} /> : undefined}
        </>
    )
}

export default withStyles(styles)(Feeds)
