import React from "react";

// Redux imports
import { connect } from "react-redux";

import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";


// actions
import { editDialogClose } from "../../../redux/actions/users/edit.users.actions";
import { getUser, getUserFailure, fetchUsers } from "../../../redux/actions/users/users.actions";
import { endpoints } from "../../../constants/services.constants";
import { fetchPutWT, fetchGetWT } from "../../../helpers/HttpHelper";
import { addToastMessage } from "../../../redux/actions/toasts.actions";
import { LinearProgress, FormControl, InputLabel, Select, MenuItem } from "@material-ui/core";

class DialogEdit extends React.Component {
  constructor(props) {
    super(props);
    this.saveUser = this.saveUser.bind(this);
    this.state = {
      fullWidth: true,
      maxWidth: "md",
      edit: false,
      editingUser: null,
      loading: null,
      error: null,
      init: null,
      aspNetDepartmentId: null,
    };
  }


  componentDidMount() {
    if (this.props.usersReducer.id != null) {

      this.setState({
        aspNetDepartmentId: "",
        init: false
      }, () => {
        this.loadDepartments(
          () => this.props.dispatch(
            getUser(this.props.usersReducer.id,
              (data) => this.setState(
                {
                  editingUser: data,
                  aspNetDepartmentId: data.aspNetDepartmentId == null ? "" : data.aspNetDepartmentId
                }
              )
            )
          )
        );
      }
      );
    }
  }

  loadDepartments(callback) {
    this.setState({ loading: true, error: null }, () => {
      fetchGetWT(
        endpoints.DEPARTMENTS_ROOT,
        json => {
          console.log(json);
          if (json != null && typeof json === "object") {
            var cb = callback != null ? callback : () => { };
            this.setState({ init: true, loading: false, error: null, departments: json }, cb);
          }
          else {
            this.setState({ init: false, loading: false, error: json });
          }
        },
        error => {
          this.setState({ init: false, loading: false, error: error });
          this.props.dispatch(getUserFailure(error))
        }
      );
    });
  }

  handleUpdate = () => {

    this.saveUser();

    // this.handleClose();
  };

  saveUser = () => {
    let obj = {
      id: this.state.editingUser.id,
      email: this.state.editingUser.email,
      firstname: this.state.editingUser.firstname,
      lastname: this.state.editingUser.lastname,
      userName: this.state.editingUser.userName,
      aspNetDepartmentId: this.state.aspNetDepartmentId === "" ? null : this.state.aspNetDepartmentId
    }
    console.log(obj);


    this.setState({ loading: true, error: null }, () => {

      fetchPutWT(
        endpoints.USER_ROOT,
        JSON.stringify(obj),
        json => {
          console.log(json);
          if (json === "OK") {
            this.setState({ loading: false, error: null });
            this.props.dispatch(
              addToastMessage({
                message: "User " + obj.userName + " edited",
                type: "success",
                details: null
              })
            );
            this.props.dispatch(fetchUsers());
            this.handleClose();
          }
          else {
            this.setState({ loading: false, error: json });
          }
        },
        error => this.props.dispatch(getUserFailure(error))
      );
    });
  }

  handleClose = () => {
    this.props.dispatch(editDialogClose());
  };

  handleChange = event => {
    let eUser = this.state.editingUser;
    eUser[event.target.name] = event.target.value;
    this.setState({ editingUser: eUser });
  };

  handleChangeDeparment = event => {
    this.setState({
      aspNetDepartmentId: event.target.value,
    });
  };
  render() {

    return (
      <div>
        <Dialog
          open={this.props.usersReducer.editDialog}
          onClose={this.handleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          fullWidth={this.state.fullWidth}
          maxWidth={this.state.maxWidth}
        >
          <DialogTitle id="alert-dialog-title">Ret bruger</DialogTitle>
          <DialogContent>
            {
              // input
              this.state.init == null ? "Init..."
                : this.state.init === false && this.state.loading === true ? <LinearProgress />
                  : this.state.init === false && this.state.error !== null ? <Typography variant="caption">Problems retrieving organizations... Please try later.</Typography>
                    : this.state.editingUser == null ? <LinearProgress />
                      : this.state.init !== true ? <Typography variant="caption">Hi :)</Typography>
                        : this.props.usersReducer.errorUser != null ? (
                          <Typography component={"span"}>
                            {this.props.usersReducer.errorUser}
                          </Typography>
                        ) : this.props.usersReducer.loadingUser ||
                          this.props.usersReducer.loadingUser == null
                          || this.state.editingUser == null ? (
                              <LinearProgress />
                            ) : (
                              <>
                                <TextField
                                  margin="dense"
                                  label="Brugernavn"
                                  type="text"
                                  value={this.state.editingUser.userName}
                                  disabled
                                  fullWidth
                                />

                                <br />
                                <TextField
                                  margin="dense"
                                  label="E-mail"
                                  type="text"
                                  name="email"
                                  value={this.state.editingUser.email}
                                  onChange={this.handleChange}
                                  fullWidth
                                />
                                <br />
                                <TextField
                                  margin="dense"
                                  label="Fornavn"
                                  name="firstname"
                                  value={this.state.editingUser.firstname}
                                  onChange={this.handleChange}
                                  type="text"
                                  fullWidth
                                />
                                <br />
                                <TextField
                                  margin="dense"
                                  label="Efternavn"
                                  name="lastname"
                                  value={this.state.editingUser.lastname}
                                  onChange={this.handleChange}
                                  type="text"
                                  fullWidth
                                />
                                <br />
                                <FormControl fullWidth>
                                  <InputLabel>Organization</InputLabel>
                                  <Select
                                    value={this.state.aspNetDepartmentId}
                                    onChange={this.handleChangeDeparment}
                                    inputProps={{
                                      name: 'name',
                                      id: 'id',
                                    }}
                                  >
                                    <MenuItem value="">
                                      <em>None</em>
                                    </MenuItem>
                                    {this.state.departments == null ? undefined : this.state.departments.map(x => <MenuItem key={x.id} value={x.id}>{x.name}</MenuItem>)}
                                  </Select>
                                </FormControl>
                              </>
                            )}
            <br />
            <Typography variant="body1" style={{ color: "red" }}>{this.state.error}</Typography>

          </DialogContent>
          <DialogActions>
            <Button onClick={this.handleClose} color="primary" disabled={this.state.loading === true}>
              Fortryd
            </Button>
            <Button onClick={this.handleUpdate} color="secondary" disabled={this.state.loading === true}>
              Opdater
            </Button>
          </DialogActions>
        </Dialog>
      </div >
    );
  }
}

export default connect(state => state)(DialogEdit);
