import React, { useState, useEffect } from "react";
import { searchPhotoClippingOrders } from './Requests';

export const FotoClippingContext = React.createContext({
    openClippingOrderDialog: false,
    fotoClippingOrders: [],
    loading: false
})


const Index = ({ children }) => {

    useEffect(() => {
        const runEffect = async () => {
            setLoading(true);
            const orders = await searchPhotoClippingOrders();
            if (!orders) {
                setLoading(false);
                setFotoClippingOrders([]);
            } else {
                setLoading(false);
                setFotoClippingOrders(orders);
            }
            
        };
        runEffect();
    }, []);

    const [openClippingOrderDialog, setOpenClippingOrderDialog] = useState(false);
    const [fotoClippingOrders, setFotoClippingOrders] = useState([]);
    const [loading, setLoading] = useState(false);

    return (
        <FotoClippingContext.Provider
            value={{
                fotoClippingOrders: [fotoClippingOrders, setFotoClippingOrders],
                openClippingOrderDialog: [openClippingOrderDialog, setOpenClippingOrderDialog],
                loading: [loading, setLoading],
            }}
        >
            {children}
        </FotoClippingContext.Provider>
    )
}

export default Index