import { fetchUser } from "../components/sections/fototracker/SharedRequests";
import store from "../redux/store";

export async function getCurrentUser() {
  var currentUserId = credentials()[0].multiUserId;
  return fetchUser(currentUserId);
}

export function credentials() {
  return store.getState().credentialsReducer.me;
}

export function getReadPermissions(permissionName) {
  return credentials()
    .filter(x => x.permissionName === permissionName && x.canRead)
    .map(x => x.multiCustomerId);
}

export function getDeletePermissions(permissionName) {
  return credentials()
    .filter(x => x.permissionName === permissionName && x.canDelete)
    .map(x => x.multiCustomerId);
}

export function getPermissions(permissionName) {
  let creds = credentials();
  if (creds !== null) {
    return creds.find(x => x.permissionName.toUpperCase() === permissionName.toUpperCase());
  } else {
    return null;
  }
}

export function canReadApplication(application) {
  const creds = credentials();
  if (!creds) return false;

  const permission = creds.find(x => x.permissionId.toUpperCase() === application.toUpperCase() && x.canRead);
  return permission !== undefined;
}

export function canReadApplicationForCustomer(application, customerId) {
  const creds = credentials();
  if (!creds) return false;

  const permission = creds.find(x => x.permissionId.toUpperCase() === application.toUpperCase() && x.canRead && x.multiCustomerId === customerId);
  return permission !== undefined;
}

export function canDeleteApplication(application) {
  const creds = credentials();
  if (!creds) return false;

  const permission = creds.find(x => x.permissionId.toUpperCase() === application.toUpperCase() && x.canDelete);
  return permission !== undefined;
}

export function canDeleteApplicationForCustomer(application, customerId) {
  const creds = credentials();
  if (!creds) return false;

  const permission = creds.find(x => x.permissionId.toUpperCase() === application.toUpperCase() && x.canDelete && x.multiCustomerId === customerId);
  return permission !== undefined;
}
