import React from "react";

// Redux imports
import { connect } from "react-redux";

import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";

// actions
import { getUserFailure, fetchUsers, createDialogClose } from "../../../redux/actions/users/users.actions";
import { endpoints } from "../../../constants/services.constants";
import { fetchPostWT, fetchGetWT } from "../../../helpers/HttpHelper";
import { LinearProgress, InputLabel, MenuItem, Select, FormControl } from "@material-ui/core";
import { addToastMessage } from "../../../redux/actions/toasts.actions";

class DialogCreate extends React.Component {
  constructor(props) {
    super(props);
    this.saveUser = this.saveUser.bind(this);
    this.state = {
      fullWidth: true,
      maxWidth: "md",
      creatingUser: null,
      loading: false,
      error: null,
      init: null,
      departments: null,
      aspNetDepartmentId: null
    };
  }


  componentDidMount() {
    let emptyUser = {
      email: "",
      firstname: "",
      lastname: "",
      userName: "",
      aspNetDepartmentId: ""
    }
    this.setState({
      creatingUser: emptyUser,
      aspNetDepartmentId: "",
      init: false
    }, this.loadDepartments());

  }

  loadDepartments() {
    this.setState({ loading: true, error: null }, () => {
      fetchGetWT(
        endpoints.DEPARTMENTS_ROOT,
        json => {
          console.log(json);
          if (json != null && typeof json === "object") {
            this.setState({ init: true, loading: false, error: null, departments: json });
          }
          else {
            this.setState({ loading: false, error: json });
          }
        },
        error => {
          this.setState({ init: false, loading: false, error: error });
          this.props.dispatch(getUserFailure(error))
        }
      );
    });
  }

  handleUpdate = () => {

    this.saveUser();

    // this.handleClose();
  };

  saveUser = () => {
    let obj = {
      email: this.state.creatingUser.email,
      firstname: this.state.creatingUser.firstname,
      lastname: this.state.creatingUser.lastname,
      userName: this.state.creatingUser.userName,
      aspNetDepartmentId: this.state.aspNetDepartmentId === "" ? null : this.state.aspNetDepartmentId
    }
    console.log(obj);


    this.setState({ loading: true, error: null }, () => {

      fetchPostWT(
        endpoints.USER_ROOT,
        JSON.stringify(obj),
        json => {
          console.log(json);
          if (typeof json === "object" && json.id != null) {
            this.setState({ loading: false, error: null });
            this.props.dispatch(
              addToastMessage({
                message: "Bruger " + json.userName + " oprettet.",
                type: "success",
                details: null
              })
            );
            this.props.dispatch(fetchUsers());
            this.handleClose();
          }
          else {
            this.setState({ loading: false, error: json });
          }
        },
        error => this.props.dispatch(getUserFailure(error))
      );
    });
  }

  handleClose = () => {
    this.props.dispatch(createDialogClose());
  };

  handleChange = event => {
    let eUser = this.state.creatingUser;
    eUser[event.target.name] = event.target.value;
    this.setState({ creatingUser: eUser });
  };


  handleChangeDeparment = event => {
    this.setState({
      aspNetDepartmentId: event.target.value,
    });
  };

  render() {

    return (
      <div>
        <Dialog
          open={this.props.usersReducer.createDialog}
          onClose={this.handleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          fullWidth={this.state.fullWidth}
          maxWidth={this.state.maxWidth}
        >
          <DialogTitle id="alert-dialog-title">Opret bruger</DialogTitle>
          <DialogContent>
            {this.state.init == null ? "Init..."
              : this.state.init === false && this.state.loading === true ? <LinearProgress />
                : this.state.init === false && this.state.error !== null ? <Typography variant="caption">Problems retrieving organizations... Please try later.</Typography>
                  : this.state.creatingUser == null ? <LinearProgress />
                    : this.state.init !== true ? <Typography variant="caption">Hi :)</Typography>
                      : <>
                        <TextField
                          margin="dense"
                          label="Brugernavn"
                          type="text"
                          name="userName"
                          value={this.state.creatingUser.userName}
                          onChange={this.handleChange}
                          fullWidth
                        />
                        <br />
                        <TextField
                          margin="dense"
                          label="E-mail"
                          type="text"
                          name="email"
                          value={this.state.creatingUser.email}
                          onChange={this.handleChange}
                          fullWidth
                        />
                        <br />
                        <TextField
                          margin="dense"
                          label="Fornavn"
                          name="firstname"
                          value={this.state.creatingUser.firstname}
                          onChange={this.handleChange}
                          type="text"
                          fullWidth
                        />
                        <br />
                        <TextField
                          margin="dense"
                          label="Efternavn"
                          name="lastname"
                          value={this.state.creatingUser.lastname}
                          onChange={this.handleChange}
                          type="text"
                          fullWidth
                        />
                        <br />
                        <FormControl fullWidth>
                          <InputLabel>Organization</InputLabel>
                          <Select
                            value={this.state.aspNetDepartmentId}
                            onChange={this.handleChangeDeparment}
                            inputProps={{
                              name: 'name',
                              id: 'id',
                            }}
                          >
                            <MenuItem value="">
                              <em>None</em>
                            </MenuItem>
                            {this.state.departments == null ? undefined : this.state.departments.map(x => <MenuItem key={x.id} value={x.id}>{x.name}</MenuItem>)}
                          </Select>
                        </FormControl>
                      </>
            }
            <br />
            <Typography variant="body1" style={{ color: "red" }}>{this.state.error}</Typography>
          </DialogContent>
          <DialogActions>
            <Button onClick={this.handleClose} color="primary" disabled={this.state.loading === true}>
              Fortryd
            </Button>
            <Button onClick={this.handleUpdate} color="secondary" disabled={this.state.loading === true}>
              Opdater
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    );
  }
}

export default connect(state => state)(DialogCreate);
