export function mapGs1DataForLeverandorSelection(gs1Data) {
    if (!gs1Data) {
        return [];
    };

    const gs1DataMapped = gs1Data.map((x, index) => {
        const item = x.multiProductManufacturer;
        const infoProvider = x.informationProviderOfTradeItem;
        return (
          {
            id: index,
            infoProvider: infoProvider,
            name: item[0].name + " ("+infoProvider+")"
          }
        )
      });

      return gs1DataMapped;
}