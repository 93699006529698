import React, { Component } from "react";

import { connect } from "react-redux";
import { withStyles } from "@material-ui/core/styles";

import CardMedia from "@material-ui/core/CardMedia";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import CardActions from "@material-ui/core/CardActions";
import IconButton from "@material-ui/core/IconButton";
import AddAPhotoIcon from "@material-ui/icons/AddAPhoto";
import { orange } from "@material-ui/core/colors";
import Typography from "@material-ui/core/Typography";
import Grow from "@material-ui/core/Grow";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import Badge from "@material-ui/core/Badge";
import { Tooltip, Button } from "@material-ui/core";
import FilterIcon from "@material-ui/icons/Filter";
import { openDialogConfirm, openDialogConfirmDelete, openDialogYesNoCancel } from "../../../shared/dialogs/DialogConfirm";
import { clipPhoto, deleteOfferProduct, getBase64FromBlob, getPhotoOrdersByPhotoIds, getPhotoOrderSingleV2 } from "../Requests";
import { getMotivId, getShortDate, showErrorNotification, showSuccessNotification } from "../../../../helpers/UtilHelper";
import { openAddPhotoOrderDialog } from "../../../../redux/actions/fotohaandtering/photoorder.fotohaandtering.actions";
import { apiGetParamsReplace, endpoints } from "../../../../constants/services.constants";
import { openDialogPhotoUsages } from "../../../shared/dialogs/DialogPhotoUsages";
import PhotoClippingButton from "../../../shared/components/PhotoClippingButton";
import { canReadApplication, canDeleteApplicationForCustomer } from "../../../../helpers/CredentialsHelper";
import { applications } from "../../../../constants/app.constants";
import { downloadImage, transformPhoto } from "../../photos/Requests";
import DialogOfferProductPhotoSelector from "../../../shared/dialogs/DialogOfferProductPhotoSelector";
import { openDialogEditOfferProduct } from "../dialogs/dialogEditOfferProduct/DialogEditOfferProduct";
import { PhotoCarousel } from "../../../shared/components/photoCarousel/PhotoCarousel";
import DialogPreReplacePhoto from "../../photos/dialogs/DialogPreReplacePhoto";
import DialogReplacePhoto from "../../photos/dialogs/DialogReplacePhoto";
import CompareIcon from '@material-ui/icons/Compare';
import DialogEditPhotoOrder from "../../fototracker/Dialogs/DialogEditPhotoOrder/DialogEditPhotoOrder";
import { photoOrderOrigin, photoOrderStatus } from "../../../../constants/enums";
import { handleApiErrorNotification } from "../../../../redux/Handlers/SuccessHandlers";
import { updatePhotoOrderStatus } from "../../fototracker/SharedRequests";
import { setActiveOfferProductPhoto, setOfferProductPhotoAsVariant } from "../../../shared/Requests";
import DialogLongProcessProgress from "../../../shared/dialogs/LongProcess";
import { CustomTooltip } from "../../fototracker/SortingSelecting";
import { IsFeatureEnabled, featureNames } from "../../../../helpers/FeaturesHelper";

const styles = theme => ({
  textCardTable: {
    fontSize: 10
  },
  expand: {
    transform: "rotate(270deg)",
    marginLeft: "auto",
    transition: theme.transitions.create("transform", {
      duration: theme.transitions.duration.shortest
    })
  },
  expandOpen: {
    transform: "rotate(90deg)"
  },
  badgeContainer: {
    display: "flex",
    height: 5
  },
  customBadge: {
    backgroundColor: "#00008e",
    color: "ffffff",
    right: 23
  }
});

class OfferProduct extends Component {
  constructor(props) {
    super(props);

    this.state = {
      anchorEl: null,
      anchorPhotoOrder: null,
      dialogPictureSelectorV3: null,
      photoOrderStatus: undefined,
      photoOrderCreated: undefined,
      visiblePhotoId: 0,
      dialogWherePhotoUsed: false,
      dialogReplacePhoto: false,
      refreshOfferProductPhoto: false,
      allowEditPhotoOrder: false,
      allowOmskud: false,
      photoOrder: undefined,
      openPhotoOrderDialog: false,
      loading: false,
      referenceFiles: [],
      omskud: false,
      availablePhotoIds: [],
      indesignPhotoIds: []
    };
    this.handleDialogPhotoSelectorV3 = this.handleDialogPhotoSelectorV3.bind(this);
    this.handleDialogPhotoSelectorV3Close = this.handleDialogPhotoSelectorV3Close.bind(this);
  }

  componentDidMount() {
    this.refreshPhotos();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.product !== this.props.product) {
      this.refreshPhotos();
    }
  }

  refreshPhotos() {
    const product = this.props.product;
    const offer = this.props.offer;


    //filter irrelevant 
    const deactivatedPhotoIds = this.props.deactivatedPhotos.filter(x=> x.offerId == offer.id).map(x => x.photoId);
    
    //Filtering out selectedPhotoID purely so we can place it first later.
    let availablePhotoIds = product.photos
      .filter(x=> !deactivatedPhotoIds.includes(x.photoId) && x.photoId !== product.selectedPhotoId)
      .map(x=> x.photoId);
    
    let indesignPhotoIds = product.photos
      .filter(x => x.isVariant && (x.photoId !== product.selectedPhotoId))
      .filter(x=> !deactivatedPhotoIds.includes(x.photoId))
      .map(x=> x.photoId);
    
  

    if (product.selectedPhotoId) {
      indesignPhotoIds = [product.selectedPhotoId, ...indesignPhotoIds];
      availablePhotoIds = [product.selectedPhotoId, ...availablePhotoIds];
    }


    this.setState({ visiblePhotoId: indesignPhotoIds[0],availablePhotoIds: availablePhotoIds,indesignPhotoIds: indesignPhotoIds });
    this.editPhotoOrder(indesignPhotoIds[0]);
  }

  handleChangeVisiblePhoto = (photoId) => {
    this.setState({ visiblePhotoId: photoId });
    this.editPhotoOrder(photoId);
  }

  handleAnchorClick = (event, element) => {
    event.stopPropagation();
    this.setState({ [element]: event.currentTarget });
  };

  handleAnchorClose = (element) => {
    this.setState({ [element]: null });
  };

  handleDeleteProduct = async () => {
    this.handleAnchorClose();
    const message = `Ønsker du at slette produktet: ${this.props.product.name} med EAN nummeret ${this.props.product.productNumber}?`;
    const confirmed = await openDialogConfirmDelete("Slet produkt", message);
    if (confirmed) {
      try {
        await deleteOfferProduct(this.props.product.id);
        showSuccessNotification("Produktet er blevet slettet");
        this.props.onProductChange();
      } catch (e) { }
    }
  };

  handlePhotoOrderClickOpen = () => {
    this.setState({
      anchorPhotoOrder: null
    });
    this.props.dispatch(openAddPhotoOrderDialog([this.props.product.id]));
  };

  handleDialogPhotoSelectorV3 = item => {
    this.handleAnchorClose();
    this.setState({ dialogPictureSelectorV3: item });
  };

  handleDialogPhotoSelectorV3Close = (hasChanges) => {
    this.setState({ dialogPictureSelectorV3: null });
    if (hasChanges) {
      this.props.onProductChange();
    }
  };

  handleTransformImage = async photoId => {
    this.handleAnchorClose();
    if (!photoId) {
      showErrorNotification("Der er ikke valgt et billede til produktet. Vælg et billede og gør det aktivt før du kan konvertere det.");
      return;
    } else {
      const motivId = getMotivId(photoId);
      showSuccessNotification(motivId + " konverteres...");
      await transformPhoto(motivId);
      showSuccessNotification(motivId + " er blevet konvertet");
    }
  }

  handleEdit = async (offerProductId, offerId) => {
    this.handleAnchorClose();
    const confirmed = await openDialogEditOfferProduct(offerProductId);
    if (confirmed) {
      this.props.onProductChange();
    }
  };

  getPriority = (product) => {
    const item = product.fieldValues.find(fieldValue => fieldValue.name === "prioritet");
    return item ? item.value : "";
  };

  //number of days before which the photo will be deleted relative to offer validTo date. Negative number if photo expires after.
  expireDays = (visiblePhoto) =>
  {
    //no date on offer or photo
    if(this.props.offer == undefined || visiblePhoto == undefined || this.props.offer.validTo == undefined || visiblePhoto.validTo == undefined)
    {
      return -1;
    }
    const timeDifference = new Date(this.props.offer.validTo).getTime() - new Date(visiblePhoto.validTo).getTime();

    var inDays = timeDifference / (1000 * 3600 * 24);

    if(inDays > 0)
    {
        //round up
        var diff = Math.ceil(inDays);
        return diff;
    }
    return 0;
  };

  handleClipPhoto = async (photoId) => {

    var forceWithShadow = await openDialogYesNoCancel(
      "Send til fritlægning", 
      `Vil du sende ${getMotivId(photoId)} til fritlægning?`, 
      "Send med skygge", true,
      "Send", false);

    if(forceWithShadow === undefined){
      return;
    }
    try {
      await clipPhoto(photoId, this.props.fotohaandteringReducer.publication.jobId, forceWithShadow);
      showSuccessNotification(`${getMotivId(photoId)} er blevet sendt til fritlægning`);
      this.props.onProductChange();
    } catch (e) { }

  };

  handleCloseDialogWherePhotoUsed = () => {
    this.setState({
      dialogWherePhotoUsed: false
    })
  }

  confirmReplacePhoto = () => {
    this.setState({
      dialogReplacePhoto: true,
      dialogWherePhotoUsed: false
    })
  }

  closeDialogReplacePhoto = () => {
    this.setState({
      dialogReplacePhoto: false,
    })
  }

  openPhotoPreReplaceDialog = () => {
    this.setState({
      dialogWherePhotoUsed: true
    })
  }

  forceUpdate = () => {
    this.setState({
      refreshOfferProductPhoto: !this.state.refreshOfferProductPhoto
    });

  }

  editPhotoOrder = (visiblePhotoId) => {
    const omskudAllowStatus = new Set(["Active", "PhotoBeingClipped", "PhotoClipped"]);

    const { product } = this.props;
    const photo = product.photoInfos.find(x => x.id === visiblePhotoId);
    if (photo) {
      const allowEditPhotoOrder = photo.status === "PhotoBeingTaken" ? true : false;
      const allowOmskud = omskudAllowStatus.has(photo.status) ? true : false;
      this.setState({
        allowEditPhotoOrder: allowEditPhotoOrder,
        allowOmskud: allowOmskud
      });
    }
  }

  openEditPhotoOrderDialog = () => {
    this.getPhotoOrderByPhotoId();
    this.setState({
      openPhotoOrderDialog: true,
      anchorPhotoOrder: null
    });
  }

  getPhotoOrderByPhotoId = async () => {
    const photoOrder = await getPhotoOrdersByPhotoIds([this.state.visiblePhotoId]);
    this.setState({
      photoOrder: photoOrder.shift()
    });
  }

  closeEditPhotoOrderDialog = () => {
    this.setState({
      openPhotoOrderDialog: false,
      anchorPhotoOrder: null,
      photoOrder: undefined,
      omskud: false
    });
  }

  startOmskudProcess = async () => {
    // get old photo order
    const response = await getPhotoOrdersByPhotoIds([this.state.visiblePhotoId]);

    // check if photo order exists
    if (response.length === 0) {
      handleApiErrorNotification("Dette foto har ikke fotobestiling");
      this.setState({
        anchorPhotoOrder: null,
        loading: false
      });
      return;
    }

    this.setState({
      // loading: true,
      anchorPhotoOrder: null
    });
    const photoOrder = response[0];
    const referenceFiles = await getBase64FromBlob(photoOrder.referenceFiles, endpoints.REFERENCE_PHOTO_GET_ONE);
    const layouts = await getBase64FromBlob(photoOrder.layouts, endpoints.LAYOUT_GET_ONE);

    setTimeout(() => {
      // create a new photo order with the info copied from the old one
      const comment = `Omskud dømt fra motiv id ${getMotivId(photoOrder.photoId)}`;
      const publication = this.props.fotohaandteringReducer.publication;

      if (photoOrder) {
        const photoOrderData = {
          jobId: parseInt(publication.jobId),
          customerId: publication.multiCustomerId,
          productNumbers: [this.props.product.productNumber],

          instructions: photoOrder.instructions,
          type: photoOrder.type,
          location: photoOrder.location,

          angle: photoOrder.angle,

          productMainCategoryId: photoOrder.productMainCategoryId,
          comment: comment,
          priority: photoOrder.priority,

          origin: photoOrderOrigin.Publication,
          clipPhoto: photoOrder.fritlaegges,
          digitalPack: false,
          name: "Fra Publication",

          photoId: photoOrder.photoId,
          tags: []

        }

        const orderAdditionalProps = {
          layouts: layouts,
          referenceFiles: referenceFiles,
        }

        const requestData = { photoOrderData, ...orderAdditionalProps };

        this.setState({
          photoOrder: requestData,
          openPhotoOrderDialog: true,
          loading: false,
          omskud: true
        });
      }
    }, 0);
  }

  omskudCallback = async (photoOrderId) => {
    this.setState({
      loading: true
    });
    //set new photo order status "Omskud"
    updatePhotoOrderStatus(photoOrderId, photoOrderStatus["ReShoot"].id);

    const omskudPhotoOrder = await getPhotoOrderSingleV2([photoOrderId]);

    if (!omskudPhotoOrder) {
      handleApiErrorNotification(`Failed getting  photo order ${photoOrderId}`);
      return;
    }

    // remove active "Cacidi" button from the old photo
    await setOfferProductPhotoAsVariant(this.props.product.id, this.state.visiblePhotoId, false);

    // set photo from a new photo order as active if the old one was active
    if (this.props.product.selectedPhotoId === this.state.visiblePhotoId) {
      await setActiveOfferProductPhoto(this.props.product.id, omskudPhotoOrder.photoId)
    }

    this.props.onProductChange();

    // Set initial state
    this.setState({
      photoOrder: undefined,
      openPhotoOrderDialog: false,
      loading: false,
      omskud: false
    });
  }
 
  render() {
    const {
      classes, product, index, offer, totalProducts,
      isLastChild, isSammix, isMixMatch, isSammixManus, offerHeaderElement, offerProducts } = this.props;
    const { anchorEl, anchorPhotoOrder, visiblePhotoId,indesignPhotoIds,availablePhotoIds } = this.state;

    let backGroundClass = undefined;

    if (totalProducts === 1 && !isMixMatch && !isSammixManus) {
      backGroundClass = isSammix ? "backgroundHeadlineSingleProductSammix" : "backgroundHeadlineSingleProduct";
    } else if (isSammixManus) {
      backGroundClass = "backgroundHeadlineSammixManus";
    } else {
      backGroundClass = isSammix ? "backgroundHeadlineSammix" : "backgroundHeadline";
    }

    const visiblePhoto = product.photoInfos ? product.photoInfos.find(x => x.id === visiblePhotoId) : product.photos.find(x => x.id === visiblePhotoId);

    const isReadOnlyMode = offer  == null ? true : !canDeleteApplicationForCustomer(applications.FOTOHAANDTERING, offer.customerId)

    const expireDays = this.expireDays(visiblePhoto);
  
    //on init, visible photo can be undefined
    const created = visiblePhoto === undefined ? new Date() : visiblePhoto.created;

    return (
      <>
        <Grow in={this.props.expanded} mountOnEnter unmountOnExit>
          <div
            className={"offerProductCard"}
            style={
              index + 1 === totalProducts && isLastChild
                ? { clipPath: "inset(-5px -5px -5px 0px)" }
                : { clipPath: "inset(-5px 0px -5px 0px)" }
            }
          >
            <div
              className={backGroundClass}
              style={offerHeaderElement && offerHeaderElement.current ?
                { height: offerHeaderElement.current.clientHeight } : undefined}>
            </div>
            <div className="cardTypographyFill" />
            <Card className="offerCard " >
              {indesignPhotoIds.length === 0 || indesignPhotoIds === undefined ?
                        <CardMedia
                        title={product.name}
                        style={{
                          height: 88,
                          backgroundSize: "contain",
                          marginTop: 20,
                          backgroundRepeat: "no-repeat",
                          backgroundPosition: "center"
                        }}
                        image={"/images/placeholders/no_photo_placeholder.png"} 
                      /> :
                <PhotoCarousel
                  photoIds={indesignPhotoIds}
                  onVisiblePhotoIdChanged={(photoId) => this.handleChangeVisiblePhoto(photoId)}
                  refreshOfferProductPhoto={this.state.refreshOfferProductPhoto}
                />
              }
              <CardContent className="cardContent">
                <div className={classes.badgeContainer}>
                  <div style={{ width: "100%" }}></div>
                  <Tooltip title={"Udvalgt"}>
                    <Badge
                      color="primary"
                      badgeContent={availablePhotoIds.length}
                    >
                    </Badge>
                  </Tooltip>
                  <Tooltip title={"Indesign"}>
                    <Badge
                      color="secondary"
                      badgeContent={indesignPhotoIds.length}
                      classes={{ badge: classes.customBadge }}
                    >
                    </Badge>
                  </Tooltip>
                </div>
                <Typography
                  variant="subtitle2"
                  gutterBottom
                  style={{ fontSize: "0.875rem", fontWeight: "bold" }}
                >
                  <CustomTooltip title={`${product.name}`}>
                    <div className="max1line">
                      {product.name}
                    </div>
                  </CustomTooltip>
                </Typography>
                <table className={"textCardTable"}>
                  <tbody>
                    <tr>
                      <th>Motiv-Id:</th>
                      <td>
                        <Tooltip title={"Oprettet "+getShortDate(created)}>
                        <Button
                          onClick={() => openDialogPhotoUsages(visiblePhotoId)}
                          className="tdslimButton">
                          {getMotivId(visiblePhotoId)}
                        </Button>
                        </Tooltip>
                      </td>
                    </tr>
                    <tr>
                      <th>EAN (GTIN):</th>
                      <td>
                        {product.productNumber == null
                          ? "ukendt"
                          : product.productNumber}
                      </td>
                    </tr>
                    <tr>
                      <th>Prioritet:</th>
                      <td>{this.getPriority(product)}</td>
                    </tr>
                  </tbody>
                </table>
              </CardContent>
              <CardActions className="actions" disableActionSpacing>
                {/* User can edit photo orders/*/}
                {!isReadOnlyMode && IsFeatureEnabled(featureNames.PhotoOrders_Enabled)  && (this.state.allowEditPhotoOrder || this.state.allowOmskud) ? (
                    <>
                      <Tooltip title={""}>
                        <IconButton
                          disabled={product.productNumber == null}
                          onClick={(e) => this.handleAnchorClick(e, "anchorPhotoOrder")}
                          className="float-left smaller_icon"
                        >
                          <AddAPhotoIcon />
                        </IconButton>
                      </Tooltip>
                    </>
                  ) : undefined
                } 
                {/* User can not edit photo orders/*/}
                {IsFeatureEnabled(featureNames.PhotoOrders_Enabled)  && !(this.state.allowEditPhotoOrder || this.state.allowOmskud) ?
                  (
                    <Tooltip title={"Bestil produktfoto"}>
                      <IconButton
                        disabled={product.productNumber == null}
                        onClick={() => this.handlePhotoOrderClickOpen()}
                        className="float-left smaller_icon"
                      >
                        <AddAPhotoIcon />
                      </IconButton>
                    </Tooltip>
                  ) : undefined
                }
                {!IsFeatureEnabled(featureNames.Photos_Status_Enabled) ? undefined :
                  (
                    !isReadOnlyMode ?
                    (
                      <PhotoClippingButton photoStatus={visiblePhoto ? visiblePhoto.status : null}
                        isClipped={visiblePhoto ? visiblePhoto.attributes.includes("Clipped") : null}
                        onClick={() => this.handleClipPhoto(visiblePhotoId)}
                      />
                    )
                    : 
                    <PhotoClippingButton photoStatus={visiblePhoto ? visiblePhoto.status : null}
                    isClipped={visiblePhoto ? visiblePhoto.attributes.includes("Clipped") : null}
                    onClick={() => {}}
                    />
                  )
                }
                {
                  expireDays <= 0 ? undefined :
                    <Tooltip title={"Slettes " + expireDays + " dage inden udløb ("+getShortDate(new Date(this.props.offer.validTo))+")"}>
                      <Typography       style={{ color: orange[500], fontSize:24, fontWeight: "bold", marginLeft: "-5px" }}>!</Typography>
                    </Tooltip>
                }

                {isReadOnlyMode ? undefined :
                  <Tooltip title={"Udvalgt billede"}>
                    <IconButton
                      className="float-left smaller_icon"
                      color="primary"
                      onClick={() => this.handleDialogPhotoSelectorV3(
                        {
                          type: "offerproduct",
                          value: product
                        })}
                    >
                      <FilterIcon />
                    </IconButton>
                  </Tooltip>
                }
                {isReadOnlyMode || !canReadApplication(applications.ERSTATFOTO) ? undefined :
                  <Tooltip title={"Erstat foto"}>
                    <IconButton
                      className="float-left smaller_icon"
                      color="primary"
                      onClick={() => this.openPhotoPreReplaceDialog()}
                    >
                      <CompareIcon />
                    </IconButton>
                  </Tooltip>
                }
                <Tooltip title={"Flere muligheder"}>
                  <IconButton onClick={(e) => this.handleAnchorClick(e, "anchorEl")} className="float-left smaller_icon">
                    <MoreVertIcon />
                  </IconButton>
                </Tooltip>
              </CardActions>

              <Menu
                id="simple-menu"
                anchorEl={anchorEl}
                open={Boolean(anchorEl)}
                onClose={() => this.handleAnchorClose("anchorEl")}
              >
                {isReadOnlyMode || !canReadApplication(applications.REDIGERTILBUD) ? undefined :
                 
                    <MenuItem onClick={() => this.handleEdit(product.id, product.multiOfferId)}>
                      Rediger
                    </MenuItem>
                }
                {isReadOnlyMode ||!canReadApplication(applications.REDIGERTILBUD) || !IsFeatureEnabled(featureNames.Photos_Delete_Enabled) ? undefined :
                    <MenuItem onClick={this.handleDeleteProduct}>
                      Slet
                    </MenuItem>
                }
                <MenuItem onClick={() => downloadImage(
                  apiGetParamsReplace(endpoints.PHOTO_SINGLE_ORG, [visiblePhotoId]),
                  visiblePhotoId
                )}>
                  Hent billede
                </MenuItem>
                {isReadOnlyMode ? undefined :
                  <MenuItem
                    onClick={() => this.handleTransformImage(visiblePhotoId)}
                  >
                    Konvertér billede
                  </MenuItem>
                }

              </Menu>

              <Menu
                id="simple-menu"
                anchorEl={anchorPhotoOrder}
                open={Boolean(anchorPhotoOrder)}
                onClose={() => this.handleAnchorClose("anchorPhotoOrder")}
              >
                <MenuItem
                  onClick={() => this.handlePhotoOrderClickOpen()}
                >
                  Opret fotoordre
                </MenuItem>
                {this.state.allowEditPhotoOrder ? (
                  <MenuItem
                    onClick={() => this.openEditPhotoOrderDialog()}
                  >
                    Rediger fotoordre
                  </MenuItem>
                ) : undefined}
                {this.state.allowOmskud ? (
                  <MenuItem
                    onClick={() => this.startOmskudProcess()}
                  >
                    Omskud
                  </MenuItem>
                ) : undefined
                }
              </Menu>
            </Card>
            {this.state.dialogPictureSelectorV3 == null ? undefined :
              <DialogOfferProductPhotoSelector
                electronic={false}
                offerProductId={product.id}
                onClose={this.handleDialogPhotoSelectorV3Close}
                customerId={this.props.fotohaandteringReducer.publication.multiCustomerId}
                offerProducts={offerProducts}
                fromPublication={true}
                offerId={offer.id}
                deactivatePhotos={this.props.deactivatedPhotos}
                updateDeactivatedPhotos={this.props.updateDeactivatedPhotos}
              />}
            {!this.state.dialogWherePhotoUsed ? undefined :
              <DialogPreReplacePhoto
                open={true}
                motiveId={visiblePhotoId}
                handleClose={this.handleCloseDialogWherePhotoUsed}
                renderActionButtons={true}
                confirmReplacePhoto={this.confirmReplacePhoto}
                messageReplacePhoto="Du er ved at slette motiv-ID og erstatte med følgende billede."
                messageConfirmReplacePhoto="Vil du fortsat ændre billedet?"
              />
            }
            {this.state.dialogReplacePhoto ?
              <DialogReplacePhoto
                motivId={visiblePhotoId}
                handleCancel={this.closeDialogReplacePhoto}
                refreshPhoto={() => this.forceUpdate()}
              /> : undefined
            }
            {!this.state.openPhotoOrderDialog ? undefined :
              <DialogEditPhotoOrder
                categories={this.props.categories}
                photoOrder={this.state.photoOrder}
                handleClose={() => this.closeEditPhotoOrderDialog()}
                calledBy="publication"
                omskud={this.state.omskud}
                omskudCallback={(photoOrderId) => this.omskudCallback(photoOrderId)}
              />
            }
            {this.state.loading ?
              <DialogLongProcessProgress
                message={"Opretter omskud fotobestillingen..."}
              /> : undefined}
          </div>
        </Grow>
      </>
    );
  }
}

export default connect(state => state)(withStyles(styles)(OfferProduct));
