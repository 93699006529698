import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import Typography from '@material-ui/core/Typography';
import { getMotiv300 } from '../../../../helpers/ImageHelper';
import { formatMotiv } from './../../../../helpers/ImageHelper';
import { Button } from '@material-ui/core';

const useStyles = makeStyles({
  card: {
    maxWidth: 350,
  },
  media: {
    height: 200,
    backgroundSize: 'contain',
    backgroundRepeat: 'no-repeat',
    margin: '10px'
  },
});

export default function ProductMediaCard(props) {
  const classes = useStyles();

  let item = props.item;
  let product = props.product;
  let motiv = formatMotiv(product.selectedPhotoId);
  // console.log(props.sizeMutations);

  let itemMutation = item == null || props.sizeMutations == null ? null
    : props.sizeMutations.find(o => Object.entries(o)
      .find(([k, value]) => k === 'motiveId' && value === item.motiveId) !== undefined);

  let dimensions = item == null || item.dimensionsWidth == null ? "Ingen dimensioner" :
    "Størrelse: " + ": " + item.dimensionsWidth + " mm x " + item.dimensionsHeight + " mm";

  let sizes = itemMutation == null ? undefined :
    "Px: " + itemMutation.width.toFixed(2) + "x" + itemMutation.height.toFixed(2);

  let scales = itemMutation == null ? undefined :
    "ScaleX: " + itemMutation.scaleX.toFixed(2);// + "x" + itemMutation.scaleY.toFixed(2);


  let proportionalToBiggestWidth = itemMutation == null || itemMutation.proportionDimensionsWidth == null ? undefined :
    "Px now/prop: " + itemMutation.width.toFixed(2) + "/" + itemMutation.proportionalToBiggestWidth.toFixed(2);//+ "x" + itemMutation.dimensionsHeightProportion.toFixed(2);

  let proportionPixels = itemMutation == null || itemMutation.proportionDimensionsWidth == null ? undefined :
    "Px PropToBiggest: " + itemMutation.proportionalWidth.toFixed(2);//+ "x" + itemMutation.dimensionsHeightProportion.toFixed(2);

  let proportions = itemMutation == null || itemMutation.proportionDimensionsWidth == null ? undefined :
    "Real PropToBiggest: " + itemMutation.proportionDimensionsWidth.toFixed(2);//+ "x" + itemMutation.dimensionsHeightProportion.toFixed(2);



  // console.log(item)
  return (
    <Card className={classes.card}>
      {/* <CardActionArea> */}
      <CardMedia
        className={classes.media}
        image={getMotiv300(motiv)}
        title={motiv}
        onLoad={props.pushImgLoads}
      />
      <CardContent>
        <Typography variant="subtitle1">Motiv-ID: {motiv}</Typography>
        <Typography gutterBottom variant="subtitle1">
          Prioritet: {item == null || item.priority == null ? 'Ingen' : item.priority}
        </Typography>
        {/* <Typography variant="subtitle1">{sizes}</Typography> */}
        {/* <Typography variant="subtitle1">{scales}</Typography> */}
        <Typography variant="subtitle1">{dimensions}</Typography>
        {/* <Typography variant="subtitle1">{proportions}</Typography>
        <Typography variant="subtitle1">{proportionalToBiggestWidth}</Typography>
        <Typography variant="subtitle1">{proportionPixels}</Typography> */}

        {/* <Typography variant="body2" color="textSecondary" component="p">
            {props.comments}
          </Typography> */}
          {item!=null?undefined:<Button onClick={()=>props.addProductToCanvas(motiv)} variant="contained" color="primary" fullWidth>Add image</Button>}
      </CardContent>
    </Card>
  );
}