import { Box, Grid, LinearProgress, Typography } from '@material-ui/core'
import React, { useEffect, useState } from 'react';
import { getBase64 } from '../../../../../helpers/UtilHelper';


export default function StepConfirmation(props) {
  const { information, saving, saveResult, photosData } = props;
  const [fileData, setFileData] = useState("");

  async function loadFileData() {
    const base64 = await getBase64(photosData.photo);
    setFileData(base64);
  }

  useEffect(() => {
    if (photosData && photosData.source === "upload") {
      loadFileData();
    }
  }, [photosData]);

  function renderPhotoInfo() {
    switch (photosData.source) {
      case "gs1":
        return <>
          <Box marginBottom="10px">GS1 foto</Box>
          <img height="200px" src={photosData.url} />
        </>
      case "upload":
        return <>
          <div>Uploadet nyt foto</div>
          {fileData === "" ?
            <div>loading...</div> :
            <img height="200px" src={fileData} />}
        </>
      case "order":
        return <>
          <div>Opretter ny fotobestilling på jobnummeret: {photosData.jobNumber}</div>
        </>
      case "no photo":
        return <>
          <div>Produktet bliver oprettet uden noget foto</div>
        </>
    }
  }

  if (!photosData) return null;

  return (
    <>
      <Box display="flex" margin="10px 50px">
        {/* Product info */}
        <Box flex="1 1 0px">
          <Typography variant="h5" style={{ marginBottom: 20 }}>
            Produkt
          </Typography>
          <Grid container spacing={2} >
            {/* EAN */}
            <Grid item xs={2}>
              <b>GTIN (EAN)</b>
            </Grid>
            <Grid item xs={10}>
              {information.productNumber}
            </Grid>
            {/* Name */}
            <Grid item xs={2}>
              <b>Navn</b>
            </Grid>
            <Grid item xs={10}>
              {information.name}
            </Grid>
            {/* Description */}
            <Grid item xs={2}>
              <b>Beskrivelse</b>
            </Grid>
            <Grid item xs={10}>
              {information.description}
            </Grid>
            {/* Supplier */}
            <Grid item xs={2}>
              <b>Leverandør</b>
            </Grid>
            <Grid item xs={10}>
              {information.leverandor}
            </Grid>
            {/* Category */}
            <Grid item xs={2}>
              <b>Kategori</b>
            </Grid>
            <Grid item xs={10}>
              {information.category.name}
            </Grid>
            {/* Tags */}
            <Grid item xs={2}>
              <b>Tags</b>
            </Grid>
            <Grid item xs={10}>
              {information.tags.join(', ')}
            </Grid>
            {/* Photo angle only if created from photo order */}
            {!photosData.angle ? undefined :
              (
                <>
                  <Grid item xs={2}>
                    <b>Fotovinkel</b>
                  </Grid>
                  <Grid item xs={10}>
                    {photosData.angle}
                  </Grid>
                </>
              )
            }

            {/* Clip Photo */}
            <Grid item xs={2}>
              <b>Fritlægges</b>
            </Grid>
            <Grid item xs={10}>
              {photosData.clipPhoto ? "Ja" : "Nej"}
            </Grid>

          </Grid>
        </Box>

        {/* Photo info */}
        <Box flex="1 1 0px">
          <Typography variant="h5" style={{ marginBottom: 20 }}>
            Foto
          </Typography>
          {renderPhotoInfo()}
        </Box>
      </Box>

      {saving ? <Box margin="40px 200px">
        <Typography>Opretter...</Typography>
        <LinearProgress />
      </Box> : undefined}

      {saveResult ? <Box margin="10px 50px" >
        <Typography variant="h5" style={{ marginBottom: 20 }}>Status</Typography>
        <Typography>{saveResult}</Typography>
      </Box> : undefined}
    </>
  )
}
