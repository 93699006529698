import store from "../store";
import { addToastMessage } from "../actions/toasts.actions";

// It will be used in case we decide to get correlation id from header
const getCorrelationId = (response) => {
  const correlationId = response.headers.get("X-Correlation-Id");
  return correlationId == null ? undefined : correlationId;
};

// Handle HTTP errors since fetch won't.
export async function handleAPIErrors(response) {

  if (response.ok) {
    return response;
  }

  let bodyResponse = await response.text();
  let customErrorMessage = bodyResponse ? bodyResponse : "";

  let errorMessage = "";

  switch (response.status) {
    case 400:
      errorMessage = customErrorMessage;
      break;
    case 401:
      errorMessage = "Du har ikke tilstrækkelige rettigheder til denne funktion";
      break;
    case 409:
    case 412:
    case 435:
      errorMessage = customErrorMessage;
      break;
    case 500:
      errorMessage = `Noget fejlede i servicen (${customErrorMessage})`;
      break;
    default:
      errorMessage = "Noget fejlede i servicen";
      break;
  }

  throw Error(errorMessage);
}

const messageNullResponse = "Serveren kunne ikke finde data.";

export async function handleNullResponse(res, acceptNull) {
  // 204 = No Content
  if (res.status === 204 && acceptNull) {
    return null;
  }
  
  const json = await res.json();
  if (json == null && acceptNull !== true) {
    throw Error(`${messageNullResponse}`);
  }
  return json;
}

export function handleAPIErrorNotification(endpoint, error, skipErrorNotification) {
  let message = "";
  message += String(error);

  if (!skipErrorNotification) {
    store.dispatch(
      addToastMessage({
        message: message,
        type: "error",
        endpoint: endpoint,
        details: null
      })
    );
  }
}
