import { SET_MESSAGE } from "../actions/message";

const initState = {
 message: 'hi!'
}
export default (state = initState, action) => {
switch(action.type) {
 case SET_MESSAGE :
 return {...state, message: action.payload.message}
 default :
 return state
 }
}
