import { Button, Typography } from '@material-ui/core';
import React, { useState } from 'react';
import { InputField } from './InputField';

export function LogInForm(props) {
    const { userName, passWord,
        setUserName, setPassWord, onKeyPress } = props;

    return (
        <>
            <Typography
                variant="subtitle1"
                style={{
                    textAlign: "center", paddingTop: 20
                }}
            >
                Log ind
            </Typography>
            <form>
                <InputField
                    labelText="Brugernavn"
                    name="username"
                    type="text"
                    placeHolder="Indtast brugernavn"
                    value={userName}
                    handleInputChange={(e) => setUserName(e)}
                    onKeyPress={(e) => onKeyPress(e)}
                />
                <InputField
                    labelText="Kodeord"
                    name="password"
                    type="password"
                    placeHolder="Indtast kodeord"
                    value={passWord}
                    handleInputChange={(e) => setPassWord(e)}
                    onKeyPress={(e) => onKeyPress(e)}
                />
            </form>
        </>
    )
} 