import React from 'react'
import { InputBase } from '@material-ui/core';
import { Paper } from '@material-ui/core';
import { withStyles } from '@material-ui/styles';

export const StyledInput = withStyles(theme => ({
    root: {
        fontSize: 20,
        color: "#6b8778",
        fontWeight: 600,
        width: "100%",
    },
    input: {
        textAlign: "center"
    }
}))(InputBase);

export default function EditOfferField({ className, placeHolder, onChange, fieldValue, onKeyPress }) {
    return (
        <div
            className={className}
            style={{
                display: "flex",
                justifyContent: "center"
            }}
        >
            <Paper
                elevation={1}
                style={{
                    borderRadius: 40,
                    padding: 8,
                    width: 350
                }}
            >
                <StyledInput autoFocus placeholder={placeHolder}
                    onChange={e => onChange(e.target.value)}
                    onKeyDown={(e) => onKeyPress(e)}
                    value={fieldValue}
                    multiline />
            </Paper>

        </div>
    )
}