
export const styles = {
    titlePaper: {
        padding: "18px 60px",
        backgroundColor: "#133f27",
        borderRadius: 30,
        marginBottom: 20,
        height: 80

    },
    titleText: {
        color: "white",
        fontSize: 32
    },

    viewPaper: {
        padding: "30px 60px",
        backgroundColor: "#c4cfc9",
        borderRadius: 30,
        minHeight:600

    },
    viewText: {
        color: "#133f27",
        fontSize: 20
    },
    viewButton: {
        color: "#6b8778",
        margin: "0 20px",
        fontWeight: 600
    },
    underlineButton: {
        textDecoration: "underline"
    },
    boldButton: {
        fontWeight: "bold"
    },
    itemDescription: {
        color: "rgb(19, 63, 39)",
        fontSize: 12
    },
    itemDescriptionAction: {
        color: "rgb(19, 63, 39)",
        fontSize: 12,
        cursor: "pointer",
        textDecoration: "underline"
    },
    customArrowBack: {
        webkitTransform: "scaleX(-1)",
        transform: "scaleX(-1)"
    }
}