import React from "react";

import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Typography,
} from "@material-ui/core";

const OpenDialogWithInformation = ({
  title,
  handleClose,
  error,
  data,
  open,
  keyVal,
  valueToDipplay,
  msgRecordnotfound,
}) => (
  <>
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title"> {title} </DialogTitle>
      <DialogContent>
        {error !== "" ? (
          <Typography> Der opstod en fejl : {error}</Typography>
        ) : data == null ? (
          <Typography>Not valid response from service</Typography>
        ) : data.length === 0 ? (
          <Typography>{msgRecordnotfound}</Typography>
        ) : (
          <table>
            <tbody>
              {data.map((d) => {
                return (
                  <tr key={d[keyVal]}>
                    <td>{d[valueToDipplay]}</td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        )}
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="primary" autoFocus>
          Luk
        </Button>
      </DialogActions>
    </Dialog>
  </>
);

export default OpenDialogWithInformation;
