import React, { useEffect, useState } from "react";

import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import {
  apiGetParamsReplace,
  endpoints
} from "../../../../constants/services.constants";

import { Link } from "@material-ui/core";
import { getToken } from "../../../../helpers/AuthHelper";
import createDialog from "../../../../DialogRenderer";
import { fetchPhotoOrderV2 } from "../SharedRequests";
import { LoadingLogo } from "../../../shared/Loading";
import Resources from "../../../../Resources.json";


export async function openDialogLayout(photoOrderId) {
  return createDialog(({ show, onSubmit, onDismiss }) => (
    <DialogLayout photoOrderId={photoOrderId}
      open={show}
      onCancel={onDismiss}
      onConfirm={onSubmit}
    />
  ));
}

function DialogLayout(props) {
  const [loading, setLoading] = useState(true);
  const [layoutFiles, setLayoutFiles] = useState([]);

  useEffect(() => {
    init();
  }, []);

  const init = async () => {
    try {
      const photoOrder = await fetchPhotoOrderV2(props.photoOrderId);
      setLayoutFiles(photoOrder.layouts);
    } finally {
      setLoading(false);
    }
  }

  const downloadLayoutFile = (layoutFileId, filename) => {
    const endpoint = apiGetParamsReplace(endpoints.LAYOUT_GET_ONE, [
      layoutFileId
    ]);
    fetch(endpoint, {
      method: "GET",
      headers: { 
        "Authorization": "Bearer " + getToken(),
        "x-organization-id": Resources.Organization
      }
    }).then(response => response.blob()).then(blob => {
      let url = window.URL.createObjectURL(blob);
      let a = document.createElement("a");
      a.href = url;
      a.download = `${filename}`;
      a.click();
    });
  }

  return (
    <>
      <Dialog
        open={props.open}
        onClose={() => props.onCancel()}
        fullWidth={true}
        maxWidth="md"
        disableBackdropClick={false}
      >
        <DialogTitle>Skitser</DialogTitle>
        <DialogContent className="customDialogContent">

          {loading ? <LoadingLogo /> :
            layoutFiles.map(layout =>
              <Link href="#"
                onClick={() => downloadLayoutFile(layout.id, layout.originalFileName)}
                style={{ display: "inherit", marginBottom: 20 }}
              >
                {layout.originalFileName}
              </Link>
            )}
        </DialogContent>
        <DialogActions>
          <Button onClick={() => props.onCancel()} autoFocus>
            Luk
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
