import { Menu, MenuItem } from '@material-ui/core';
import React from 'react';

export const DropdownMenu = (props) => {
    const { anchorEl, handleClosePopper, menuItemData } = props;

    return (
        <Menu
            anchorEl={anchorEl}
            open={Boolean(anchorEl)}
            onClose={handleClosePopper}
        >
            {menuItemData.map((x, index) => (
                <MenuItem
                    key={index}
                    disabled={x.disabled}
                    onClick={() => x.func(...x.arguments)}
                >
                    {x.name}
                </MenuItem>
            )

            )}
        </Menu>
    );
}