import React from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import PhotoLibraryIcon from "@material-ui/icons/PhotoLibrary";
import { connect } from "react-redux";
import IconButton from "@material-ui/core/IconButton";
import { Tooltip } from "@material-ui/core";
import { handleApiSuccessNotificaiton } from "../../../redux/Handlers/SuccessHandlers";
import { openDialogConfirm } from "../../shared/dialogs/DialogConfirm";
import { sendToOca } from "../digitalmediamanagement/Requests";


function ActionButtons(props) {
  const { publication, handleView, handleReload } = props;


  const isStatusOpen = (status) => {
    let openStatuses = ["Active", "Closed"];
    return openStatuses.includes(status);
  }

  const handleSendToOca = async () => {
    const confirmed = await openDialogConfirm("Send til OCA", `Ønsker du at sende billederne for ${publication.name} til OCA?`);
    if (confirmed) {
      await sendToOca(publication.id, null, publication.customerId);
      handleApiSuccessNotificaiton("Billederne sendes til OCA");
      handleReload();
    }
  };

  return (
    <div style={{ width: 128 }}>
      <Tooltip title={"Fotohåndtering"}>
        <IconButton
          disabled={!isStatusOpen(publication.status)}
          color="primary"
          name="buttonDigitalPublicationPhotoHandling"
          className="float-left smaller_icon"
          onClick={() => handleView()}>
          <PhotoLibraryIcon />
        </IconButton>
      </Tooltip>

      <Tooltip title={"Send til OCA"}>
        <IconButton
          disabled={publication.status === "Closed"}
          color="primary"
          className="float-left smaller_icon"
          onClick={() => handleSendToOca()}>
          <ExitToAppIcon />
        </IconButton>
      </Tooltip>
    </div>
  );
}

const styles = theme => ({
  button: {
  },
  extendedIcon: {
    marginRight: theme.spacing(1)
  }
});
ActionButtons.propTypes = {
  classes: PropTypes.object.isRequired
};


export default connect(state => state)(withStyles(styles)(ActionButtons));
