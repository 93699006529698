import React, { useState, useEffect, useReducer } from "react";
import { connect } from "react-redux";
import {
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  FormHelperText,
} from "@material-ui/core";

import {allowedPhotoFormatArray} from  "../../../../constants/enums";

import {
  endpoints,
} from "../../../../constants/services.constants";
import { fetchGetWT } from "../../../../helpers/HttpHelper";
import { handleAPIErrorNotification } from "../../../../redux/Handlers/ErrorHandlers";

import Grid from "@material-ui/core/Grid";

import { Button, Typography } from "@material-ui/core";
import { LoadingLogo } from "../../../shared/Loading";

// Dropzone new
import { FilePond, registerPlugin } from "react-filepond";
import "filepond/dist/filepond.min.css";
import FilePondPluginImagePreview from "filepond-plugin-image-preview";
import "filepond-plugin-image-preview/dist/filepond-plugin-image-preview.min.css";
import FilePondPluginFileValidateType from "filepond-plugin-file-validate-type";
import { photoTypeEnum } from "../../../../constants/enums";
import {validateFileExtentionOnUpload} from "../../../shared/SharedFunctions";

// Register the plugins
registerPlugin(FilePondPluginImagePreview, FilePondPluginFileValidateType);

const UploadPhotoTab = (props) => {
  const [customers, setCustomers] = useState([]);
  const [photoTypeId, setphotoTypeId] = useState(null);
  const [multiCustomerId, setmultiCustomerId] = useState(null);
  const [loading, setloading] = useState(false);

  useEffect(() => {
    async function fetchData() {
      await getCustomers();
    }
    fetchData();
  }, []);

  const getCustomers = async () => {
    const endpoint = endpoints.CUSTOMER_ALL_ALLOWED;
    setloading(true);
    await fetchGetWT(
      endpoint,
      (response) => {
        if (response != null) {
          setCustomers(response);
          setloading(false);
        } else {
          setloading(false);
          throw Error("Null response");
        }
      },
      (error) => {
        setloading(false);
        handleAPIErrorNotification(endpoint, error);
      }
    );
  };

  return (
    <>
      {loading ? (
        <LoadingLogo />
      ) : (
        <>
          <FormControl style={{ marginBottom: 20, marginTop: 20 }} fullWidth>
            <InputLabel htmlFor="name">Kunde</InputLabel>
            <Select
              fullWidth
              id="selectorProductEditCustomer"
              value={multiCustomerId ? multiCustomerId : ""}
              onChange={(e) => {
                setmultiCustomerId(e.target.value);
                props.handerCustomer(e.target.value);
              }}
              inputProps={{
                name: "name",
                id: "id",
              }}
            >
              {customers.map((item) => (
                <MenuItem key={item.id} value={item.id} className="selectorProductEditCustomerItems">
                  {item.name}
                </MenuItem>
              ))}
            </Select>
            {!multiCustomerId && (
              <FormHelperText style={{ color: "#e61c1c" }}>
                Skal være udfyldt
              </FormHelperText>
            )}
          </FormControl>

            <FormControl style={{ marginBottom: 5 }} fullWidth>
            <InputLabel htmlFor="multiPhotoTypeId-id">Foto type</InputLabel>
            <Select
              fullWidth
              id="selectorProductEditPhotoType"
              value={photoTypeId ? photoTypeId : ""}
              onChange={(e) => {
                setphotoTypeId(e.target.value);
                props.handerPhotoType(e.target.value);
              }}
              inputProps={{
                name: "multiPhotoTypeId",
                id: "multiPhotoTypeId-id",
              }}
            >
              <MenuItem value={photoTypeEnum.Pack.id} className="selectorProductEditPhotoType">
                {photoTypeEnum.Pack.name}
              </MenuItem>
              <MenuItem value={photoTypeEnum.Environment.id} className="selectorProductEditPhotoType">
                {photoTypeEnum.Environment.name}
              </MenuItem>
            </Select>
            {!photoTypeId && (
              <FormHelperText style={{ color: "#e61c1c" }}>
                Skal være udfyldt
              </FormHelperText>
            )}
            </FormControl>
            <Grid style={{ textAlign: "center", marginBottom: 10 }}>
              {props.files.length === 0 || props.hideUploadButton ? undefined : props.uploading === true ? (
              <Button disabled color="primary">
                Uploading...
              </Button>
            ) : props.uploading == null || props.uploading === false ? (
              <Button
                onClick={props.addImagesToProduct}
                color="primary"
                variant="outlined"
                name="buttonProductTapUploadPhoto"
              >
                Upload billede
              </Button>
            ) : (
              <>
                <Button
                  onClick={props.addImagesToProduct}
                  color="primary"
                  variant="outlined"
                  name="buttonProductTapUploadPhoto"
                >
                  Upload billede
                </Button>
                <Typography style={{ textAlign: "center" }}>
                  Error uploading: {JSON.stringify(props.uploading)}
                </Typography>
              </>
            )}
          </Grid>
            <div
              style={{ height: 382, overflow: "auto" }}>
              <FilePond
                allowImagePreview={true}
                files={props.files}
                onupdatefiles={props.setFile}
                allowMultiple={false}
                maxFiles={1}
                imagePreviewHeight={500}
                beforeAddFile={(item) => validateFileExtentionOnUpload(item.file)}
                labelIdle={
                  'Træk og slip din billedfil eller <span class="filepond--label-action">Gennemse</span>'
                }
              />
            </div>
        </>
      )}
    </>
  );
};
export default connect((state) => state)(UploadPhotoTab);
