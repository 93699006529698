import React from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import InputBase from "@material-ui/core/InputBase";
import { Grid } from "@material-ui/core";


///THIS IS MEANT TO BE TEMPORARY
const styles = {
    root: {
        padding: "2px 4px",
        display: "flex",
        alignItems: "center",
        width: "100%",
        marginBottom: 20
    },
    input: {
        marginLeft: 20,
        marginRight: 20,
        flex: 1
    },
    iconButton: {
        padding: 10
    },
    divider: {
        width: 1,
        height: 28,
        margin: 4
    }
};

function CustomSearchBar({ placeholder, keyPress, handleChangeQueryString, queryString, disabled, classes }) {
    return (
        <Grid container>
            <Paper className={classes.root} elevation={1}>
                <InputBase
                    autoFocus
                    className={classes.input}
                    placeholder={placeholder}
                    onKeyDown={keyPress}
                    onChange={handleChangeQueryString}
                    value={queryString}
                    disabled={disabled}
                />
            </Paper>
        </Grid>
    );
}

export default withStyles(styles)(CustomSearchBar);
