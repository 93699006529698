import React, { useRef, useState } from 'react';
import { withStyles } from '@material-ui/core';
import clsx from 'clsx';
import OfferProduct from './OfferProduct';
import EmptyProduct from './EmptyProduct';
import OfferHeader from './OfferHeader';
import { offerType } from '../../../../constants/enums';

const styles = theme => ({
    cardHolder: {
        marginRight: 10,
    },
    headline: {
        position: "relative",
        fontSize: "1rem",
        zIndex: 1,
        fontWeight: 500,
        paddingLeft: 5
    },
    offerType: {
        color: "#bbb"
    },
    colorSammix: {
        color: "#ffffff"
    }
});

const OfferMix = function OfferMix(props) {

    const { offer, classes } = props;
    const [anchorEl, setAnchorEl] = useState(null);

    const isSammix = offer.type && offerType[offer.type].id === 7 && offer.childOffers.length === 0
        ? true : false; // sammix PMR
    const isSammixManus = offer.type && offerType[offer.type].id === 7 && offer.childOffers.length > 0
        ? true : false; // sammix Manus

    let headingContainerClass = undefined;

    var isOneOrZeroProducts = offer.products.length === 1  || offer.products.length == 0;

    // A temporary solution until we remove offer card
    // if (offer.products.length === 1) {
    if(isOneOrZeroProducts){
        headingContainerClass = isSammix ? clsx(classes.headline, classes.colorSammix) : classes.headline;
    } else {
        headingContainerClass = isSammix ? clsx(classes.headlineExtended, classes.colorSammix) : classes.headlineExtended;
    }

    const textHeaderHeight = useRef(null);
    const headerWidth = isOneOrZeroProducts ? 250 : offer.products.length * 250; // fit header text into header background

    return (
        <>
            <div className={classes.offerType}>
                {isSammix ? "Sammix" : undefined}
            </div>
            <div className={classes.cardHolder}>
                <OfferHeader
                    headingContainerClass={classes.headline}
                    offer={offer}
                    color={isSammix || isSammixManus ? "#FFFFFF" : undefined}
                    refe={textHeaderHeight}
                    width={headerWidth}w
                    anchorEl={anchorEl}
                    setAnchorEl={(value) => setAnchorEl(value)}
                    handleCreatePhotoOrderOffer={(offer) => props.handleCreatePhotoOrderOffer(offer)}
                    handleEdit={() => props.handleEdit(offer.id)}
                    handleDeleteOffer={() => props.handleDeleteOffer()}
                />
                {offer.products.length == 0 ? <EmptyProduct
                    expanded={props.expanded}
                /> : ""}
                {offer.products.map((product, index) => (
                    <>
                        <OfferProduct
                            onProductChange={() => props.onOfferChange(offer.id, false)}
                            product={product}
                            expanded={props.expanded}
                            index={index}
                            totalProducts={offer.products.length}
                            isLastChild={true}
                            offer={offer}
                            pageNumber={offer.multiPublicationPageNumber}
                            reloadOfferVariants={() => props.reloadOfferVariants()}
                            isSammix={isSammix}
                            isSammixManus={isSammixManus}
                            offerHeaderElement={textHeaderHeight}
                            offerProducts={offer.products}
                            categories={props.categories}
                            deactivatedPhotos={props.deactivatedPhotos}
                            updateDeactivatedPhotos={props.updateDeactivatedPhotos}
                        />
                    </>
                ))}
            </div>
        </>
    )
}

export default withStyles(styles)(OfferMix)