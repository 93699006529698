import React from 'react'
import { Grid, InputBase } from '@material-ui/core';
import { Paper } from '@material-ui/core';
import SortingItem from '../../../../tilbudsstyring/SortingItem';


export default function DataTable({ offers, onAdd }) {
    return (
        <Grid container
            style={{ marginTop: 50 }}>
            {offers.map(offer => (
                <SortingItem
                    offer={offer}
                    key={offer.id}
                    onAdd={(offerId) => onAdd(offerId)}
                />
            ))}
        </Grid>
    )
}