import React, { useState, useEffect } from 'react';
import { Typography, Button } from '@material-ui/core';
import RemoveCircleIcon from '@material-ui/icons/RemoveCircle';
import SwapHorizontalCircleRoundedIcon from '@material-ui/icons/SwapHorizontalCircleRounded';
import { endpoints } from '../../../../constants/services.constants';

export function Scene({ item, itemIndex, removeItem, blocked, swapLeft }) {
    const [isOnHover, setIsOnHover] = useState(false);
    return (<div

        onMouseEnter={() => blocked ? setIsOnHover(false) : setIsOnHover(true)}
        onMouseLeave={() => setIsOnHover(false)}
        style={{
            width: 180,
            margin: "0 5px"
        }}
    >
        <div style={{ position: "relative" }}>
            <RemoveCircleIcon
                onClick={() => removeItem(item)}
                style={{
                    fontSize: 30,
                    cursor: "pointer",
                    // color: "white",
                    position: "absolute", top: -8, right: -8
                }} />
            {itemIndex === 0 ? undefined :
                <SwapHorizontalCircleRoundedIcon
                    onClick={() => swapLeft(item)}
                    style={{
                        fontSize: 30,
                        cursor: "pointer",
                        // color: "white",
                        position: "absolute", top: 150, left: -20,
                        zIndex: 100

                    }} />
            }
            {/* {
                !isOnHover
                    // false 
                    ? undefined :
                    <>
                       
                        <SwapHorizontalCircleRoundedIcon
                            // onClick={() => removeItem(item)}
                            style={{
                                fontSize: 30,
                                cursor: "pointer",
                                // color: "white",
                                position: "absolute", top: 150, right: -20,
                                zIndex:100

                            }} />
                             <SwapHorizontalCircleRoundedIcon
                            // onClick={() => removeItem(item)}
                            style={{
                                fontSize: 30,
                                cursor: "pointer",
                                // color: "white",
                                position: "absolute", top: 150, left: -20,
                                zIndex:100
                            }} />
                    </>
            } */}
            <img
                style={{ display: "block" }}
                width="180"
                height="320"
                src={endpoints.STORAGE_PUBLIC_FILMMAKER.replace("{path}",item.urlThumbnailRelative)}
                alt={item.name}
            />
        </div>
        <br />
        <Typography style={{ wordBreak: "break-all" }}>
            <strong>Scene {itemIndex + 1}</strong><br />
            {item.name}
            {/* <br />
            {z.duration} sek. - {z.size} MB */}
        </Typography>
    </div>
    )
}
