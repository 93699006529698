import { offerDefinitionsEnum } from "../../../constants/enums";
import { getReadPermissions } from "../../../helpers/CredentialsHelper";
import { IsFeatureEnabled, featureNames } from "../../../helpers/FeaturesHelper";
export function getOfferFiltersForUi(allCustomers) {
  let offerDefinitions = Object.entries(offerDefinitionsEnum);
  offerDefinitions = [["0", { id: 0, name: "Ingen type"}], ...offerDefinitions];
  let filters = [];

    if (IsFeatureEnabled(featureNames.Offers_Definitions_Enabled)) {
    
      filters.push({
        key: "offerType",
        displayName: "Tilbudstype",
        options: offerDefinitions.map(([key, item]) => ({
          key: key,
          displayName: item.name,
          selected: false
        }))
      });
    }

    if (IsFeatureEnabled(featureNames.Offers_Customer_Enabled)) {
      // customers
      const visibleCustomerIds = getReadPermissions("Tilbud");
      const customers = allCustomers.filter(x => visibleCustomerIds.includes(x.id));
      filters.push({
        key: "customer",
        displayName: "Kunde",
        options: customers.map(x => {
          return {
            key: x.id,
            displayName: x.name,
            selected: false
          }
        })
      });
    }

    if (IsFeatureEnabled(featureNames.Offers_Search_Expired_Enabled)) {
      // include deleted
      filters.push({
        key: "includeOld",
        displayName: "",
        options: [
          {
            key: null,
            displayName: "Søg i gamle tilbud",  //means expired offer (after valid-to date)
            selected: false
          }
        ]
      });
    }

    return filters;
}

export function getOfferFiltersForRequest(uiFilters, pages) {
  const typeOptions = IsFeatureEnabled(featureNames.Offers_Definitions_Enabled) ? uiFilters.find(x => x.key === "offerType").options : [];
  const customerOptions = IsFeatureEnabled(featureNames.Offers_Customer_Enabled) ? uiFilters.find(x => x.key === "customer").options : [];
  const includeOldOption = IsFeatureEnabled(featureNames.Offers_Search_Expired_Enabled) ? uiFilters.find(x => x.key === "includeOld").options[0] : [];
  let pagesFilter = [];
  if (pages.length > 0) {
    pagesFilter = pages.split(",").map(x => parseInt(x)).filter(y => !isNaN(y));
  }
    return {
      definitions: typeOptions.filter(x => x.selected).map(x => x.key),
      customerIds: customerOptions.filter(x => x.selected).map(x => x.key),
      IncludeOldOffers: includeOldOption.selected,
      pageNumbers: pagesFilter
    };
}