import React, { useEffect, useState } from "react";
import { apiGetParamsReplace, endpoints } from "../../../constants/services.constants";
import { openDialogFullsizePhoto } from "../../shared/dialogs/DialogFullsizePhoto";
import store from "../../../redux/store";
import ExclusiveIcon from "../../shared/components/ExclusiveIcon";
import { Tooltip } from "@material-ui/core";

export default function DataRowImage({ photoId, exclusiveForCustomerIds, allowFullView }) {

    const [tooltip, setTooltip] = useState("");

    useEffect(() => {

        if (isExclusive()) {
            const customerNames = store.getState().utilsReducer.customers
                .filter(x => exclusiveForCustomerIds.includes(x.id))
                .map(x => x.name);
            const tooltip = "Dette foto er eksklusivt for " + customerNames.join(", ")
            setTooltip(tooltip);
        }

    }, [exclusiveForCustomerIds]);

    const isExclusive = () => {
        return exclusiveForCustomerIds && exclusiveForCustomerIds.length > 0;
    }
    
    return (
        <div style={{ width: 75, height: 75, position: "relative" }}>

            {!allowFullView ?
                <Tooltip title="Preview er ikke understøttet">
                    <img
                        src={apiGetParamsReplace(endpoints.PHOTO_SINGLE_200, photoId)}
                        alt="sample"
                        style={{ width: 75, height: 75, objectFit: "contain" }}
                    />
                </Tooltip>
                :
                <img
                    src={apiGetParamsReplace(endpoints.PHOTO_SINGLE_200, photoId)}
                    alt="sample"
                    className="hoverClickPointer"
                    onClick={() => openDialogFullsizePhoto(photoId)}
                    style={{ width: 75, height: 75, objectFit: "contain" }}
                /> 
            }
            
            {isExclusive() ?
                <div style={{ position: "absolute", top: 0, right: 0, padding: 2}}>
                    <ExclusiveIcon exclusiveForCustomerIds={exclusiveForCustomerIds}/>
                </div> : undefined
            }

        </div>            
);
}