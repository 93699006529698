//import { SET_PUBLIKATION } from '../types/notifications';
import {
  FETCH_NOTIFICATIONS_BEGIN,
  FETCH_NOTIFICATIONS_SUCCESS,
  FETCH_NOTIFICATIONS_FAILURE
} from '../actions/notifications.actions';

const initState = {
  notifications: 
  [
    // {
    //  'name': '17Kædeavis uge 20', 
    //    'weekNumber': '19/20', 
    //    'multiCustomerId': 'Superbrugsen1', 
    //    'pageAmount':'24', 
    //    'description': '24/48 cm 4 farver',
    //    'batchWorkbookId': '32551',
    //    'multiPublicationStatusId':'Igang'
      // }

      // ['67Kædeavis uge 20', '19/20', 'Superbrugsen4', '24', '24/48 cm 4 farver','Igang'],
     ]
}
export default (state = initState, action) => {
  switch(action.type) {
    case FETCH_NOTIFICATIONS_BEGIN:
      // Mark the state as "loading" so we can show a spinner or something
      // Also, reset any errors. We're starting fresh.
      return {
        ...state,
        loading: true,
        error: null
      };

    case FETCH_NOTIFICATIONS_SUCCESS:
      // All done: set loading "false".
      // Also, replace the items with the ones from the server
      
      return {
        ...state,
        loading: false,
        notifications: action.payload.notifications
      };

    case FETCH_NOTIFICATIONS_FAILURE:
      // The request failed. It's done. So set loading to "false".
      // Save the error, so we can display it somewhere.
      // Since it failed, we don't have items to display anymore, so set `items` empty.
      //
      // This is all up to you and your app though:
      // maybe you want to keep the items around!
      // Do whatever seems right for your use case.
      //alert("Error: "+action.payload.error);
      // console.log(action.payload.error);
      return {
        ...state,
        loading: false,
        error: action.payload.error,
        notifications: []
      };

    default:
      // ALWAYS have a default case in a reducer
      return state;
  }
}