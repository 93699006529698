import React, { useState } from 'react';
import { Button, Card, CardContent, IconButton, MenuItem, Tooltip, Typography, withStyles } from '@material-ui/core';
import CardActions from "@material-ui/core/CardActions";
import Menu from "@material-ui/core/Menu";
import { addToastMessage } from '../../../../redux/actions/toasts.actions';
import { apiGetParamsReplace, endpoints } from '../../../../constants/services.constants';
import { getMotivId, showErrorNotification, showSuccessNotification } from '../../../../helpers/UtilHelper';
import { downloadImage, transformPhoto } from '../../photos/Requests';
import MoreVertIcon from "@material-ui/icons/MoreVert";
import FilterIcon from "@material-ui/icons/Filter";
import store from '../../../../redux/store';
import { openDialogFullsizePhoto } from '../../../shared/dialogs/DialogFullsizePhoto';
import PhotoClippingButton from '../../../shared/components/PhotoClippingButton';
import { clipPhoto, sendToOca } from '../Requests';
import { openDialogConfirm } from '../../../shared/dialogs/DialogConfirm';
import { openDialogPhotoUsages } from '../../../shared/dialogs/DialogPhotoUsages';
import DialogOfferProductPhotoSelector from '../../../shared/dialogs/DialogOfferProductPhotoSelector';
import { CustomTooltip } from '../../fototracker/SortingSelecting';

const styles = theme => ({
    content: {
        height: 300,
        width: "fit-content",
        float: "left",
        padding: "5px",
        marginRight: 20,
        marginBottom: 20
    },
    card: {
        padding: 0,
        float: "left",
        width: 200,
        boxShadow: "none"
    },
    metadata: {
        fontSize: "0.675rem"
    },
    mediaMaster: {
        height: 0,
        paddingTop: "56.25%" // 16:9
    },
    offerContent: {
        height: 260,
        float: "left",
        paddingTop: 5,
        paddingLeft: 5,
        paddingRight: 5,
        paddingBottom: 20,
        marginBottom: 25,
        marginLeft: 0,
        backgroundColor: "white",
        boxShadow: "0px 1px 5px 0px rgba(0, 0, 0, 0.2),0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 3px 1px -2px rgba(0, 0, 0, 0.12)",
        marginRight: 10
    },
    offerSeperator: {
        width: 15,
        float: "left",
        height: 1
    },
    cardHolder: {
        marginRight: 10,
    },
    headline: {
        paddingLeft: 5,
        paddingRight: 5,
        lineHeight: 1.6,
        whiteSpace: "nowrap",
        overflow: "hidden",
        textOverflow: "ellipsis",
        maxWidth: 200
    },
    backgroundHeadline: {
        backgroundColor: "#D8D8D8",
        width: 200,
        height: 27
    },
    offerCard: {
        padding: 0,
        float: "left",
        width: 200,
        boxShadow: "none !important",
        overflow: "visible !important",
        backgroundColor: "white"
    },
    media: {
        backgroundSize: "contain",
        height: 85,
        cursor: "pointer",
    },
    cardContent: {
        height: 70,
        padding: 5
    },
    textCardTable: {
        fontSize: 10,
        textAlign: "left",
        marginTop: 10,
        lineHeight: "0.65rem"
    },
    tdslim: {
        paddingBottom: 0,
        paddingTop: 0,
        paddingLeft: 10
    },
    tdslimButton: {
        paddingBottom: 0,
        paddingTop: 0,
        paddingLeft: 0,
        paddingRight: 0,
        fontSize: 10
    },
    thslim: {
        paddingBottom: 0,
        paddingTop: 0
    },
    actions: {
        display: "flex"
    },
    expand: {
        transform: "rotate(270deg)",
        marginLeft: "auto",
        transition: theme.transitions.create("transform", {
            duration: theme.transitions.duration.shortest
        })
    },
    expandOpen: {
        transform: "rotate(90deg)"
    },
});

const EanComponent = React.forwardRef(function EanComponent(props, ref) {
    //  Spread the props to the underlying DOM element.
    const productNumbers = props.eans.map(x => x.productNumber);
    return <div {...props} ref={ref}>{props.eans.length <= 1 ? productNumbers[0] :
        productNumbers[0].toString().concat("...")}</div>
});

function ElectronicOfferCard(props) {
    const { offer, classes, publication, onOfferUpdated } = props;
    const [anchorEl, setAnchorEl] = useState(null);
    const [sendToOcaLoading, setSendToOcaLoading] = useState(false);
    const [showOfferPhotoSelector, setShowOfferPhotoSelector] = useState(false);

    const handleTransformImage = async photoId => {
        handleAnchorClose();
        if (!photoId) {
            showErrorNotification("Der er ikke valgt et billede til produktet. Vælg et billede og gør det aktivt før du kan konvertere det.");
        } else {
            const motivId = getMotivId(photoId);
            showSuccessNotification(motivId + " konverteres...");
            await transformPhoto(motivId);
            showSuccessNotification(motivId + " er blevet konvertet");
        }
    }


    const handleSendToOca = async () => {
        const motiveId = getMotivId(offer.products[0].selectedPhotoId);
        try {
            handleAnchorClose();
            setSendToOcaLoading(true);
            await sendToOca(publication.id, offer.id, publication.multiCustomerId);
            showSuccessNotification(`${motiveId} er blevet sendt til OCA`);
        } catch (e) {
            showErrorNotification(`Kunne ikke sende ${motiveId} til OCA`);
        } finally {
            setSendToOcaLoading(false);
        }
    };


    const handleAnchorClick = event => {
        setAnchorEl(event.currentTarget);
    };

    const handleAnchorClose = () => {
        setAnchorEl(null);
    };

    const handleDialogPhotoSelectorV3 = async offerId => {
        handleAnchorClose();
        setShowOfferPhotoSelector(true);
    };

    const handleDialogPhotoSelectorV3Close = (hasChanges) => {
        if (hasChanges) {
            onOfferUpdated(offer.id);
        }
        setShowOfferPhotoSelector(false);
    };

    const handleClipPhoto = async (photoId, jobNumber) => {
        const confirmed = await openDialogConfirm("Send til fritlægning", `Vil du sende ${getMotivId(photoId)} til fritlægning?`, "Send");
        if (confirmed) {
            await clipPhoto(photoId, jobNumber);
            store.dispatch(
                addToastMessage({
                    message: `${getMotivId(photoId)} er blevet sendt til fritlægning`,
                    type: "success",
                })
            );
            onOfferUpdated(offer.id);
        }
    };

    const offerProduct = offer.products[0];
    const selectedPhotoId = offerProduct ? offerProduct.selectedPhotoId : null;    
    const photoPreviewUrl = selectedPhotoId === null || selectedPhotoId === undefined ? "/images/placeholders/no_photo_placeholder.png" : apiGetParamsReplace(endpoints.PHOTO_SINGLE_200, selectedPhotoId);

    return (
        <>
            <div className={classes.cardHolder}>
                <div className={classes.offerContent}>
                    <Typography variant="subtitle1" title={offer.description} className={classes.headline} gutterBottom>
                        {offer.description}
                    </Typography>

                    <Card className={classes.offerCard} >

                        {/* Photo */}
                        <div className="mainContainer">
                            <div
                                onClick={() => openDialogFullsizePhoto(selectedPhotoId)}
                                className="cardImage"
                                style={{
                                    backgroundImage: `url(${photoPreviewUrl})`,
                                }}
                            >
                            </div>
                        </div>

                        {/* Content */}
                        <CardContent className={classes.cardContent}>
                            <>
                                <table className={classes.textCardTable}>
                                    <tbody>
                                        <tr>
                                            <th className={classes.thslim}>Tilbudsstyrke: </th>
                                            <td className={classes.tdslim}>{offer.fieldValues.find(x => x.name === "prisstyrke").value}</td>
                                        </tr>
                                        <tr>
                                            <th className={classes.thslim}>Pris:</th>
                                            <td className={classes.tdslim}>{offer.prices.price} kr.</td>
                                        </tr>
                                        <tr>
                                            <th className={classes.thslim}>Motiv-Id:</th>
                                            <td className={classes.tdslim} style={{ paddingLeft: 0 }}>
                                                <Button
                                                    onClick={() => openDialogPhotoUsages(selectedPhotoId)}
                                                    className={classes.tdslimButton}>
                                                    {getMotivId(selectedPhotoId)}
                                                </Button>
                                            </td>
                                        </tr>
                                        <tr>
                                            <th className={classes.thslim}>EAN nr:</th>
                                            <td className={classes.tdslim}>
                                                {offer.products.length <= 1 ?
                                                    offer.products.map(x => <div>{x.productNumber}</div>) : (
                                                        <CustomTooltip title={offer.products.map(x => <div>{x.productNumber}</div>)}>
                                                            <EanComponent eans={offer.products} />
                                                        </CustomTooltip>
                                                    )}
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </>
                        </CardContent>

                        {/* Action buttons */}
                        <CardActions className={classes.actions}>
                            <PhotoClippingButton photoStatus={offer.selectedPhoto ? offer.selectedPhoto.status : null}
                                isClipped={offer.selectedPhoto ? offer.selectedPhoto.attributes.includes("Clipped") : null}
                                onClick={() => handleClipPhoto(selectedPhotoId, publication.jobId)} />

                            <Tooltip title={"Udvalgt billede"}>
                                <IconButton
                                    disabled={offer.products.length === 0}
                                    className="float-left smaller_icon"
                                    color="primary"
                                    onClick={() => handleDialogPhotoSelectorV3(offer.id)}
                                >
                                    <FilterIcon />
                                </IconButton>
                            </Tooltip>

                            <Tooltip title={"Flere muligheder"}>
                                <IconButton onClick={handleAnchorClick} className="float-left smaller_icon">
                                    <MoreVertIcon />
                                </IconButton>
                            </Tooltip>
                        </CardActions>
                    </Card>
                    <Menu
                        id="simple-menu"
                        anchorEl={anchorEl}
                        open={Boolean(anchorEl)}
                        onClose={handleAnchorClose}
                    >
                        {selectedPhotoId === undefined ? undefined : (
                            [<MenuItem key="1" onClick={() => downloadImage(
                                apiGetParamsReplace(endpoints.PHOTO_SINGLE_ORG, [selectedPhotoId]),
                                selectedPhotoId)}>
                                Hent billede
                            </MenuItem>,
                            <MenuItem key="2" onClick={() => handleTransformImage(selectedPhotoId)}>
                                Konvertér billede
                            </MenuItem>,
                            <MenuItem key="3"
                                onClick={() => handleSendToOca()}>
                                {sendToOcaLoading ? "Sender..." : "Send til OCA"}
                            </MenuItem>]
                        )}
                    </Menu>
                </div>
            </div>

            {!showOfferPhotoSelector || offer.products.length === 0 ? undefined :
                <DialogOfferProductPhotoSelector
                    electronic={true}
                    offerProductId={offer.products[0].id}
                    onClose={handleDialogPhotoSelectorV3Close}
                    customerId={publication.multiCustomerId}
                    offerProducts={offer.products}
                    offerId={offer.products[0].offerId}
                />
            }
        </>
    )
}

export default withStyles(styles)(ElectronicOfferCard)