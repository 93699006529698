import { MuiThemeProvider } from '@material-ui/core';
import React from 'react';
import { render, unmountComponentAtNode } from 'react-dom';
import store from './redux/store';
import { RepublicaMuiTheme } from './RepublicaMuiTheme';
import { Provider } from 'react-redux';

// creates a dialog and return promise
// function is based on the project: https://github.com/prezly/react-promise-modal
// due to issues with mui styling we had to modify the code
export default function createDialog(renderDialog) {
    const container = document.getElementById('dialog-container');

    function getDialog({ onSubmit, onDismiss, show }) {
        return (
            <Provider store={store}>
                <MuiThemeProvider theme={RepublicaMuiTheme}>
                    {renderDialog({ onSubmit, onDismiss, show })}
                </MuiThemeProvider>
            </Provider>
        )
    }

    function displayModal({ onSubmit, onDismiss }) {
        render(getDialog({ onSubmit, onDismiss, show: true }), container);
    }

    function hideModal({ onSubmit, onDismiss }, callback) {
        render(getDialog({ onSubmit, onDismiss, show: false }), container, callback);
    }

    function destroyModal() {
        unmountComponentAtNode(container);
    }

    const confirmation = new Promise((resolve) => {
        const onSubmit = (value = true) => resolve(value);
        const onDismiss = () => resolve(undefined);
        displayModal({ onSubmit, onDismiss });
    });

    return confirmation.finally(() => {
        const onSubmit = () => { };
        const onDismiss = () => { };
        hideModal({ onSubmit, onDismiss }, () => {
            setTimeout(destroyModal, 300);
        });
    });
}