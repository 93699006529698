import {
  UPLOAD_OPEN_BEGIN,
  UPLOAD_CLOSE_BEGIN,
  UPLOAD_PHOTO_BEGIN,
  UPLOAD_PHOTO_SUCCESS,
  UPLOAD_PHOTO_FAILURE,
  UPLOAD_PHOTO_LOWRESOLUTION,
  UPLOAD_OPEN_SUCCESS
} from "../actions/photos/uploads.fototrackers.actions";

const initState = {
  uploadDialog: false,
  id: null,
  customerId: null,
  file: null,
  error: null,
  uploadingFile: false,
  lowResolution: false,
  lowResolutionMessage: null,
};
export default (state = initState, action) => {
  switch (action.type) {
    //-------------------------------------------
    case UPLOAD_OPEN_BEGIN:
      return {
        ...state,
        uploadDialog: true,
        id: action.payload.id,
        customerId: action.payload.customerId,
        lowResolution: false,
      };
    case UPLOAD_OPEN_SUCCESS:
      return {
        ...state,
        id: null,
        lowResolution: false,
      };
    case UPLOAD_CLOSE_BEGIN:
      return {
        ...state,
        uploadDialog: false,
        id: null,
        lowResolution: false,
      };
    //-------------------------------------------
    case UPLOAD_PHOTO_BEGIN:
      return {
        ...state,
        uploadingFile: true,
        error: null,
        lowResolution: false
      };
    case UPLOAD_PHOTO_SUCCESS:
      return {
        ...state,
        uploadingFile: false,
        lowResolution: false,
      };
    case UPLOAD_PHOTO_FAILURE:
      return {
        ...state,
        uploadingFile: false,
        error: action.payload.error,
        lowResolution: false,
      };
      
      case UPLOAD_PHOTO_LOWRESOLUTION:
        return {
          ...state,
          uploadingFile: false,
          error: action.payload.error,
          lowResolution: true,
          lowResolutionMessage: action.payload.error.message.replace(/"/g, '')
        };
    //-------------------------------------------
    default:
      return state;
  }
};
