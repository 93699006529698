import React from 'react';

export default function PageNumber({ activePage, item, selectedSpread, onClick }) {
    return (
        <div style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            fontSize: "1.5em",
            fontWeight: 600,
            cursor: "pointer"
        }}>
            <div className={selectedSpread
                ? activePage
                : undefined}
                onClick={onClick}>
                {item}
            </div>
        </div>
    )
}