import React, { useState } from 'react';
import { Button } from '@material-ui/core';
import RemoveCircleIcon from '@material-ui/icons/RemoveCircle';
import { fetchDeleteWTNoBodyResponse } from '../../../../helpers/HttpHelper';
import { endpoints } from '../../../../constants/services.constants';
import { openDialogConfirmDelete } from '../../../shared/dialogs/DialogConfirm';

export default function Asset({ 
    item, 
    refresh, 
    blocked, 
    setPreviewDialog, 
    mediaSelection, 
    isImage,
    setPreviewEndpoint,
    deleteEndpoint
}) {
    const [isOnHover, setIsOnHover] = useState(false);
    const [deleting, setDeleting] = useState(false);

    async function removeItem(item) {
        const message = `Ønsker du at slette medie?`;
        const confirmed = await openDialogConfirmDelete("Slet medie", message);
        if (confirmed) {
            setDeleting(true);
            fetchDeleteWTNoBodyResponse(deleteEndpoint, null, refresh, (e) => setDeleting(false));
        }
    }
    return (<div

        onMouseEnter={() => blocked ? setIsOnHover(false) : setIsOnHover(true)}
        onMouseLeave={() => setIsOnHover(false)}
        style={{
            width: 180,
            margin: "0 5px"
        }}
    >
        <div style={{ position: "relative" }}>
            {
                // !isOnHover 
                mediaSelection != null ? undefined :
                    <RemoveCircleIcon
                        onClick={() => removeItem(item)}
                        style={{
                            fontSize: 30,
                            cursor: "pointer",
                            // color: "white",
                            position: "absolute", top: -8, right: -8
                        }} />
            }
            <img
                onClick={() => setPreviewDialog(setPreviewEndpoint)}
                style={{ display: "block",cursor: "pointer"  }}
                width="180"
                height="320"
                src={endpoints.STORAGE_PUBLIC_FILMMAKER.replace("{path}",item.urlThumbnailRelative)}
                alt={item.name}
            />
        </div>
        <br />
        <div
            style={{
                wordBreak: "break-all",
                color: "rgb(19, 63, 39)",
                height: 90,
                overflow: "auto",
                fontSize: 12
            }}>
            <p>
                {item.name}

            </p>
            {isImage ?  undefined : (
                <p>
                    {item.durationSeconds} sek. - {item.sizeMB} MB
                </p>
            )
            }
        </div>
        {mediaSelection == null ? undefined :
            <Button
                variant="contained"
                onClick={() => mediaSelection(item)}
                style={{
                    boxShadow: "none",
                    borderRadius: 20,
                    backgroundColor: "#6b8778",
                    width: "50%",
                    color: "#ffffff",
                    marginTop: 18
                }}
            >
                Select
            </Button>
        }
    </div>
    )
}