import React, { useState } from "react";

import { Typography, Button } from "@material-ui/core";

// Dropzone new
import { FilePond, registerPlugin } from "react-filepond";
import "filepond/dist/filepond.min.css";
import FilePondPluginImagePreview from "filepond-plugin-image-preview";
import "filepond-plugin-image-preview/dist/filepond-plugin-image-preview.min.css";
import FilePondPluginFileValidateType from "filepond-plugin-file-validate-type";
import SelectPhotoTypes from "../PhotoSelectionComponents/SelectPhotoType";
import { allowedPhotoFormatArray, photoTypeEnum } from "../../../constants/enums";
import PhotoRestrictionField from "../components/PhotoRestrictionField";
import { handleValidationOnSetExclusive } from "../../sections/fototracker/Dialogs/DialogAddPhotoOrder/BusinessLogic/Logic";
import {validateFileExtentionOnUpload} from "../../shared/SharedFunctions";
// Register the plugins
registerPlugin(FilePondPluginImagePreview, FilePondPluginFileValidateType);

export default function TabUpload(props) {
    const { onUploadFile, onlyPack, startEnvironmental,
        offerProducts, fromPublication, customerId } = props;
    const [selection, setSelection] = useState(!onlyPack && startEnvironmental ? photoTypeEnum.Environment.id : photoTypeEnum.Pack.id);
    const [files, setFiles] = useState([]);
    const [uploading, setUploading] = useState(null);
    const [exclusiveForCustomerIds, setExclusiveForCustomerIds] = useState([]);

    const handleSetFile = (fileItems) => {
        setFiles(fileItems.map(fileItem => fileItem.file));
    };

    const handleUpload = async () => {
        setUploading(true);
        await onUploadFile(files[0], selection, exclusiveForCustomerIds);
        setFiles([]);
        setUploading(false);
        setExclusiveForCustomerIds([]);
    };

    const photoTypes = [{ id: 1, description: "Packshot" }];
    if (!onlyPack) {
        photoTypes.push({ id: 2, description: "Miljø" });
    }

    const handleExclusiveCustomerIds = (ids, selectedCustomer) => {
        if (!handleValidationOnSetExclusive(ids, selectedCustomer)) {
            return;
        }

        setExclusiveForCustomerIds(ids);
    }

    return (
        <>
            <div style={{ marginTop: 20, overflow: "auto", height: 503 }}>
                {!fromPublication ? undefined : (
                    <div style={{ marginBottom: 20 }}>
                        <PhotoRestrictionField
                            selectedCustomerIds={exclusiveForCustomerIds}
                            onSelectedCustomerIdsChange={ids => handleExclusiveCustomerIds(ids, customerId)}
                        />
                    </div>
                )}
            <SelectPhotoTypes
                photoTypes={photoTypes}
                selection={selection}
                handleSelectPhotoType={(photoType) => setSelection(photoType)}
            />
            {selection === 2 ? (
                <>
                    <p>Billedet tilføjes til følgende produkter:</p>
                    {
                        offerProducts.map(x => (
                            <div>
                                <p>{x.productNumber} - {x.name}</p>
                            </div>
                        ))
                    }
                </>
            ) : undefined}
            <br />
            <FilePond

                allowImagePreview={true}
                files={files}
                onupdatefiles={handleSetFile}
                allowMultiple={false}
                maxFiles={1}
                beforeAddFile={(item) => validateFileExtentionOnUpload(item.file)}
                imagePreviewHeight={400}
                    labelIdle={
                    'Træk og slip din billedfil eller <span class="filepond--label-action">Gennemse</span>'
                }
            />
            <br />
            </div>
            {files.length === 0 ? undefined :
                uploading ?
                    <Button disabled color="primary">
                        Uploading...
                    </Button>
                    : uploading == null || uploading === false ? <Button onClick={handleUpload} color="primary" variant="outlined">
                        Upload billede
                        </Button>
                        : <>
                            <Button onClick={handleUpload} color="primary" variant="outlined">
                                Upload billede
                            </Button>
                        </>
            }
        </>
    );
}