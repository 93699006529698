import React, { useState } from "react";
import { Grid, Checkbox, Button, CardContent, CardActions, Card, TextField } from "@material-ui/core";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import { withStyles } from "@material-ui/core/styles";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import DialogTitle from "@material-ui/core/DialogTitle";
import { IsFeatureEnabled, featureNames } from "../../../../helpers/FeaturesHelper";

const styles = {
    root: {
        padding: 5,
    },
    modalContainer: {
        fontSize: 14,
    },
    cardActions: {
        justifyContent: "flex-end",
        margin: "0 0 15px 0"
    },
    column: {
        minWidth: 100,
    },
    columnItem: {
        minWidth: "100%",
        height: 25,
        fontWeight: 700
    },
    headerContainer: {
        display: "grid",
        gridTemplateColumns: "1fr 1fr"
    },
    closeButtonColumn: {
        justifySelf: "end"
    },
    heading: {
        color: "rgba(0, 0, 0, 0.87)",
        fontSize: "1.2rem",
        fontFamily: "Open Sans",
        fontWeight: 500,
        lineHeight: 1.6,

    },
    saveButton: {
        fontSize: "1.2rem",
        fontFamily: "Open Sans",
        fontWeight: 500,
        lineHeight: 1.6
    },
    lol: {
        padding: 0
    }
};

const ColorButton = withStyles(theme => ({
    root: {
        marginRight: "10px",
        backgroundColor: "#263238",
        color: "#ffffff",
        '&:hover': {
            backgroundColor: "#11171A",
            color: "#ffffff"
        },
    },
}))(Button);

const SearchFilterPopupV2 = (props) => {
    // A default options to control the state
    const [filters, setFilters] = useState(props.filters);
    const [pages, setPages] = useState(props.searchPagesFilter);
    const { classes, onCancel, onSubmit, fromTilbud } = props;
    // Defines a number of columns for the grid
    const columnsCount = 12 / (Object.keys(filters).length);
    // const columnsCount = 4;

    // Uncheck all the checkboxes
    const resetFilters = () => {
        for (const category of filters) {
            for (const option of category.options) {
                option.selected = option.default === true;
            }
        }
        setFilters([...filters]);
        setPages("");
    }

    const handleCheckboxChange = (option, checked) => {
        option.selected = checked;
        setFilters([...filters]);
    }

    return (
        <Card className={classes.root}>
            <div className={classes.headerContainer}>
                <DialogTitle
                    id="form-dialog-title"
                    style={{ padding: "16px 15px" }}
                >
                    Filter
                </DialogTitle>
                <div className={classes.closeButtonColumn}>
                    <IconButton onClick={x => onCancel()}>
                        <CloseIcon />
                    </IconButton>
                </div>
            </div>
            <CardContent>
                < div style={{
                    minWidth: 400,
                    display: "grid",
                    gridTemplateColumns: "auto",
                }} >
                    <Grid container>
                    {filters.map(filter =>
                        <Grid item xs={columnsCount} key={filter.key} className={classes.column}>
                            {/* header */}
                            <Grid item xs={columnsCount} className={classes.columnItem}>{filter.displayName ?? "ft&sup3;"}</Grid>

                            {/* options */}
                            {filter.options.map(option =>
                                <div key={option.key} >
                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                checked={option.selected}
                                                onChange={e => handleCheckboxChange(option, e.target.checked)}
                                                value={option.displayName}
                                                color="primary"
                                            />
                                        }
                                        label={option.displayName}
                                    />
                                </div>
                            )}

                        </Grid>
                    )}
                    </Grid>
                    {(!fromTilbud || !IsFeatureEnabled(featureNames.Offers_Search_Pages_Enabled)) ? undefined : (
                    <div>
                        <span style={{
                            height: 25,
                            minWidth: "100%",
                            fontWeight: 700
                        }}>Side</span>
                        <TextField
                            key={`pageNumber`}
                            id={`pageNumber`}
                            margin="normal"
                            label=""
                            type="text"
                            value={pages}
                            onChange={(e) => setPages(e.target.value)}
                            fullWidth
                            inputProps={{ className: classes.lol }}
                        />
                    </div>
                    )}
                </div >

            </CardContent>
            <CardActions className={classes.cardActions}>
                <Button onClick={() => resetFilters()}>
                    Nulstil
                </Button>
                <ColorButton
                    type="submit"
                    variant="contained"
                    size="small"
                    onClick={() => onSubmit(filters, pages)}
                    className={classes.SaveButton}>Anvend</ColorButton>
            </CardActions>

        </Card >
    )
}
export default withStyles(styles)(SearchFilterPopupV2)