import { endpoints, apiGetParamsReplace } from '../../../constants/services.constants';
import { fetchGetWT } from '../../../helpers/HttpHelper';

export const FETCH_CUSTOMERS_BEGIN = 'UTILS_FETCH_CUSTOMERS_BEGIN';
export const FETCH_CUSTOMERS_SUCCESS = 'UTILS_FETCH_CUSTOMERS_SUCCESS';
export const FETCH_CUSTOMERS_FAILURE = 'UTILS_FETCH_CUSTOMERS_FAILURE';

export const FETCH_CUSTOMER_BEGIN = 'UTILS_FETCH_CUSTOMER_BEGIN';
export const FETCH_CUSTOMER_SUCCESS = 'UTILS_FETCH_CUSTOMER_SUCCESS';
export const FETCH_CUSTOMER_FAILURE = 'UTILS_FETCH_CUSTOMER_FAILURE';

export const fetchCustomersBegin = () => ({
  type: FETCH_CUSTOMERS_BEGIN
});

export const fetchCustomersSuccess = (customers) => ({
  type: FETCH_CUSTOMERS_SUCCESS,
  payload: {customers}
});

export const fetchCustomersFailure = error => ({
  type: FETCH_CUSTOMERS_FAILURE,
  payload: { error }
});

export const fetchCustomers = () => {
  return dispatch => {
    const endpoint = endpoints.CUSTOMER_ALL;
    dispatch(fetchCustomersBegin());
    return fetchGetWT(endpoint,
      (json) => {
        // Replace "coop365" with "coop 365" it was risky to do it directly in db as it was used a lot of places
        const index = json.findIndex(x => x.id === 100);

        if(index != -1)
        {
          json[index].name = "Coop 365";
          
        }
        dispatch(fetchCustomersSuccess(json));
      }
      ,
      (error) => {
        dispatch(fetchCustomersFailure(error));
      }
    )
  }
}


export const fetchCustomerBegin = () => ({
  type: FETCH_CUSTOMER_BEGIN
});

export const fetchCustomerSuccess = (customer) => ({
  type: FETCH_CUSTOMER_SUCCESS,
  payload: {customer }
});

export const fetchCustomerFailure = error => ({
  type: FETCH_CUSTOMER_FAILURE,
  payload: { error }
});

export const fetchCustomer = (customerId,callback) => {
  return dispatch => {

    const endpoint = apiGetParamsReplace(
      endpoints.CUSTOMER_SINGLE,
      ...[customerId]
    );

    dispatch(fetchCustomerBegin());
    return fetchGetWT(endpoint,
      (json) => {
        // let customerSet = new Set();
        // json.map(x=>customerSet.add([x.id,x.name]))
        dispatch(fetchCustomerSuccess(json));

        if(callback)
          callback(json);
      }
      ,
      (error) => {
        dispatch(fetchCustomerFailure(error));
      }
    )
  }
}