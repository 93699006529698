import { apiGetParamsReplace, endpoints } from "../../../constants/services.constants";
import { _request } from "../../../helpers/HttpHelper";

export async function searchProducts(searchRequest) {
  return _request({
    method: "POST",
    endpoint: endpoints.SEARCH_PRODUCTS,
    body: JSON.stringify(searchRequest),
    throwError: true
  });
}

export default async function getProductCategories() {
  return _request({
    method: "GET",
    endpoint: endpoints.PRODUCT_MAIN_CATEGORIES,
    throwError: true
  });
};

export function getProductPhotosForPhoto(photoId) {
  return _request({
    method: "GET",
    endpoint: apiGetParamsReplace(endpoints.PRODUCT_PHOTOSBYMOTIVID, photoId),
    throwError: true
  });
}

export function getCustomers() {
  return _request({
    method: "GET",
    endpoint: endpoints.CUSTOMER_ALL_ALLOWED,
    throwError: true
  });
}

export function getGs1Data(productNumber) {
  return _request({
    method: "GET",
    endpoint: apiGetParamsReplace(endpoints.PRODUCT_GET_GS1_IMAGES, productNumber),
    throwError: true,
    acceptNull: true,
  });
}

export function getCategories() {
  return _request({
    method: "GET",
    endpoint: endpoints.PRODUCT_CATEGORIES,
    throwError: true
  });
}

export function getProduct(productId) {
  return _request({
    method: "GET",
    endpoint: apiGetParamsReplace(endpoints.PRODUCT_SINGLE_V2, productId),
    throwError: true
  });
}

export function createProduct(productDto) {
  return _request({
    method: "POST",
    endpoint: endpoints.PRODUCT_ADD,
    body: JSON.stringify(productDto),
    throwError: true
  });
};


export function getProductPhotosForPhotoId(photoId) {
  return _request({
    method: "GET",
    endpoint: apiGetParamsReplace(endpoints.PRODUCT_PHOTOS_FOR_PHOTO_ID, photoId),
    throwError: true
  });
}

export function createProductPhotos(productPhotos) {
  return _request({
    method: "POST",
    endpoint: endpoints.PRODUCT_PHOTOS_ADD,
    body: JSON.stringify(productPhotos),
    skipBodyParsing: true
  });
}

export function deleteProductPhotos(productPhotoIds) {
  return _request({
    method: "DELETE",
    endpoint: endpoints.PRODUCT_PHOTOS_DELETE,
    body: JSON.stringify(productPhotoIds),
    skipBodyParsing: true
  });
}

export function getProducts(productIds, includePhotos, includeFieldValues) {
  const queryParams = `includePhotos=${includePhotos}&includeFieldValues=${includeFieldValues}`;
  return _request({
    method: "POST",
    endpoint: apiGetParamsReplace(endpoints.PRODUCT_MULTIPLE, queryParams),
    body: JSON.stringify(productIds),
    throwError: true
  });
}

export function getOffersWithProductNumber(productNumber) {
  return _request({
    method: "GET",
    endpoint: apiGetParamsReplace(endpoints.OFFER_GET_OFFERS_WITH_PRODUCTNUMBER, productNumber),
    throwError: true
  });
}


export function getActivePublicationsWithOffers(offerIds) {
  return _request({
    method: "POST",
    endpoint: endpoints.GET_ALL_ACTIVE_PUBLICATION_BY_OFFERS,
    body: JSON.stringify(offerIds),
    throwError: true
  });
}


export function deleteProduct(productId) {
  return _request({
    method: "DELETE",
    endpoint: apiGetParamsReplace(endpoints.PRODUCT_DELETE, productId),
    skipBodyParsing: true,
    throwError: true
  });
}