import React, { useState, useEffect } from "react";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import { withStyles, LinearProgress, CircularProgress } from "@material-ui/core";
import { getPhotosForProducts, getOfferProduct, setActiveOfferProductPhoto, setOfferProductPhotoAsVariant, createPhotoForOfferProduct, removePhotoFromOfferProduct, addPhotoToOfferProduct, getOfferProductPhotosWithPhotoId } from "../Requests";
import GridPhotos from "../PhotoSelectionComponents/GridPhotos";
import TabsContainer from "../PhotoSelectionComponents/TabsContainer";
import { getPhotoOrdersByPhotoIds, getPhotos } from "../../sections/fotohaandtering/Requests";
import { getMotivId, showErrorNotification, sortArrayAlphabetically } from "../../../helpers/UtilHelper";
import { openDialogEditPhoto } from "../../sections/photos/dialogs/DialogEditPhoto";
import DialogPreReplacePhoto from "../../sections/photos/dialogs/DialogPreReplacePhoto";
import DialogReplacePhoto from "../../sections/photos/dialogs/DialogReplacePhoto";
import { photoStatusEnum, photoOrderStatus, photoTypeEnum } from "../../../constants/enums";
import { openDialogConfirm } from "./DialogConfirm";
import { changeOrderStatusForPhoto, getPhotoTags, recoverPhotoOrder, getDeactivatedOfferPhotos, setPhotoIsDeactivated } from "../../sections/fototracker/SharedRequests";
import store from '../../../redux/store';

const styles = () => ({
  dialogMain: {
    height: 1050,
    paddingButtom: 10
  },
  buttonsSection: {
    margin: "10px 13px 24px"
  }
});

function DialogOfferProductPhotoSelector(props) {
  const { offerProductId, customerId, onClose, classes, offerProducts, electronic, fromPublication, offerId,deactivatePhotos,updateDeactivatedPhotos } = props;
  const [loading, setLoading] = useState(true);
  const [offerProduct, setOfferProduct] = useState(null);
  const [selectedPhotoItems, setSelectedPhotoItems] = useState([]);
  const [selectedOrderItems, setSelectedOrderItems] = useState([]);
  const [deactivatedOfferPhotos, setDeactivatedOfferPhotos] = useState([]);
  const [productPhotoItems, setProductPhotoItems] = useState([]);
  const [hasChanges, setHasChanges] = useState(false);

  // DialogReplacePhoto state
  const [replacePhotoId, setReplacePhotoId] = useState(undefined);
  const [showDialogPreReplacePhoto, setShowDialogPreReplacePhoto] = useState(false);
  const [showDialogReplacePhoto, setShowDialogReplacePhoto] = useState(false);
  const [photoTagsAutoSuggest, setPhotoTagsAutoSuggest] = useState([]);

  useEffect(() => {
    loadData();
  }, []);

  const loadData = async () => {
    try {
      setLoading(true);
      const offerProduct = await getOfferProduct(offerProductId);
      setOfferProduct(offerProduct);

      // load photo data
      let photosInfos = [];
      const photoIds = offerProduct.photos.map(x => x.photoId);
      if (photoIds.length > 0) {
        photosInfos = await getPhotos(photoIds);
      }

      let orderInfos = [];
      if (photoIds.length > 0) {
        orderInfos = await getPhotoOrdersByPhotoIds(photoIds);
      }

      // get data for GridPhotos
      const photoItems = offerProduct.photos.map(x => {
        const photoInfo = photosInfos.find(info => info.id === x.photoId);
        return {
          offerProductPhotoId: x.id,
          selectedOfferProductPhotoId : offerProduct.selectedPhotoId,
          photoId: x.photoId,
          deleteDate: photoInfo ? photoInfo.validTo : null,
          exclusiveForCustomerIds: photoInfo.exclusiveForCustomerIds,
          customerId: photoInfo.customerId,
          status: photoInfo.status,
          isVariant: x.isVariant,
          isActive: x.photoId === offerProduct.selectedPhotoId,
          validTo: x.validTo,
          created: photoInfo.created,
          type: photoInfo.type
        }
      });
      const orderItems = offerProduct.photos.map(x => {
        const orderInfo = orderInfos.find(info => info.photoId === x.photoId);
        return orderInfo === undefined ? null :
        {
          photoId: orderInfo.photoId,
          status: orderInfo.status,
        }
      });

      const deactivatedPhotos =  !fromPublication ? [] :  await getDeactivatedOfferPhotos(store.getState().fotohaandteringReducer.publication.id, offerId);

      setSelectedPhotoItems(photoItems);
      setSelectedOrderItems(orderItems);
      setDeactivatedOfferPhotos(deactivatedPhotos);

      // get data for TabContainer
      const photoInfo = await getPhotosForProducts([offerProduct.productNumber], customerId);
      setProductPhotoItems(photoInfo);

      // Load photo tags auto suggest data
      let photoTags = await getPhotoTags();
      photoTags = sortArrayAlphabetically(photoTags);
      setPhotoTagsAutoSuggest(photoTags);
    } finally {
      setLoading(false);
    }
  }



  const handleOrderStatus = async (photoId, isDeactivated) => {
      
    try {
      setLoading(true);
      const publicationId = store.getState().fotohaandteringReducer.publication.id;
      
      
      var deactivatedPhoto = {
        photoId: photoId,
        offerId: offerId,
        publicationId: publicationId
      };

      props.updateDeactivatedPhotos(deactivatedPhoto,isDeactivated);
      

      await setPhotoIsDeactivated(publicationId, photoId, offerId, isDeactivated)
      await loadData();
      
      setHasChanges(true);
    } finally {
      setLoading(false);
    }
  }


  const handleSetActive = async (photoId) => {
    try {
      setLoading(true);
      await setActiveOfferProductPhoto(offerProduct.id, photoId);
      await loadData();
      setHasChanges(true);
    } finally {
      setLoading(false);
    }
  }

  const handleSetVariant = async (photoId, isVariant) => {
    try {
      setLoading(true);
      await setOfferProductPhotoAsVariant(offerProduct.id, photoId, isVariant);
      await loadData();
      setHasChanges(true);
    } finally {
      setLoading(false);
    }
  }

  const handleUploadFile = async (file, imageType, exclusiveForCustomerIds) => {
    let relatedOfferProductIds = [offerProduct.id];

    // If uploaded photo is miljø, add this photo to all offer products
    if (imageType === 2) {
      relatedOfferProductIds = offerProducts.map(x => x.id);
    }

    try {
      setLoading(true);
      await createPhotoForOfferProduct(relatedOfferProductIds, file, customerId, imageType, exclusiveForCustomerIds);
      await loadData();
      setHasChanges(true);
    } finally {
      setLoading(false);
    }
  };

  const handleEdit = async (photoId) => {
    const confirmed = await openDialogEditPhoto(photoId, handleShowDialogPreReplacePhoto);
    if (confirmed) {
      await loadData();
    }
  };

  const confirmDelete = async (photoId, status) => {
    // show confirm dialog if the photo is foto på vej and would be cleaned up
    if (status !== photoStatusEnum.PhotoBeingTaken.key) {
      return true;
    }

    const offerProductPhotos = await getOfferProductPhotosWithPhotoId(photoId);
    if (offerProductPhotos.length <= 1) {
      const message = "Fotoet vil blive slettet da det ikke bruges andre steder.";
      return openDialogConfirm("Er du sikker?", message, "Ja, Slet det", "secondary");
    } else {
      return true;
    }
  }

  const handleDelete = async (photoId, status) => {
    try {
      setLoading(true);
      const confirmed = await confirmDelete(photoId, status);
      if (confirmed) {
        await removePhotoFromOfferProduct(offerProduct.id, photoId);
        await loadData();
        setHasChanges(true);
      }
    } finally {
      setLoading(false);
    }
  };

  const handleAddPhoto = async (photoId) => {
    try {
      const photoExists = offerProduct.photos.find(x => x.photoId === parseInt(photoId));
      if (photoExists) {
        showErrorNotification(`Motiv-id ${getMotivId(photoId)} er allerede valgt`);
        return;
      }
      setLoading(true);
      await addPhotoToOfferProduct(offerProduct.id, photoId);
      await loadData();
      setHasChanges(true);
    } finally {
      setLoading(false);
    }
  };

  const handleShowDialogPreReplacePhoto = (photoId) => {
    setReplacePhotoId(photoId);
    setShowDialogPreReplacePhoto(true);
  }

  const handleCloseDialogPreReplacePhoto = () => {
    setReplacePhotoId(undefined);
    setShowDialogPreReplacePhoto(false);
  }

  const confirmReplacePhoto = () => {
    setShowDialogPreReplacePhoto(false);
    setShowDialogReplacePhoto(true);
  }

  return (<>
    <Dialog
      open={true}
      fullWidth={true}
      maxWidth={"md"}
      disableBackdropClick={false}
      classes={{ paper: classes.dialogMain }}
    >
      <div style={{ height: 5 }}>
        {loading === true ? <LinearProgress /> : undefined}
      </div>
      <DialogTitle>
        Billeder for tilbudsprodukt
      </DialogTitle>
      <DialogContent align="center">
        {loading && !offerProduct ? <CircularProgress size={100} style={{ marginTop: 100 }} />
          : <>
            <GridPhotos
              items={selectedPhotoItems}
              orderItems={selectedOrderItems}
              showVariant={!electronic}
              onSetVariant={handleSetVariant}
              onSetActive={handleSetActive}
              onDelete={handleDelete}
              onOrderStatus={handleOrderStatus}
              onEdit={handleEdit}
              loading={loading}
              deactivatedOfferPhotos={deactivatedOfferPhotos}
            />

            <br />
            <TabsContainer
              onAddPhoto={handleAddPhoto}
              onUploadFile={handleUploadFile}
              allPhotoItems={productPhotoItems}
              selectedPhotoIds={selectedPhotoItems.map(x => x.photoId)}
              selectedPhotos={selectedPhotoItems}
              loading={loading}
              onlyPack={false}
              startEnvironmental={false}
              offerProducts={offerProducts}
              fromPublication={fromPublication}
              customerId={customerId}
              photoTagsAutoSuggest={photoTagsAutoSuggest}
            />
          </>
        }
      </DialogContent>
      <DialogActions>
        <Button onClick={() => onClose(hasChanges)}>Luk</Button>
      </DialogActions>
    </Dialog>

    {!showDialogPreReplacePhoto ? undefined :
      <DialogPreReplacePhoto
        open={true}
        motiveId={replacePhotoId}
        handleClose={handleCloseDialogPreReplacePhoto}
        renderActionButtons={true}
        confirmReplacePhoto={confirmReplacePhoto}
        messageReplacePhoto="Du er ved at slette motiv-ID og erstatte med følgende billede."
        messageConfirmReplacePhoto="Vil du fortsat ændre billedet?"
      />
    }
    {showDialogReplacePhoto ?
      <DialogReplacePhoto
        motivId={replacePhotoId}
        handleCancel={() => setShowDialogReplacePhoto(false)}
        refreshPhoto={() => loadData()}
        performSearch={() => { }}
      /> : undefined
    }

  </>)
};

export default withStyles(styles)(DialogOfferProductPhotoSelector);
