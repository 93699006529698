import { stringIsEmpty } from "../../../../../../helpers/UtilHelper";
import { searchOffers } from "../../../../tilbudsstyring/Requests";

export async function searchOffersForTemplate(data) {
    const searchRequest = generateSearchRequestOffers(data);
    const searchResult = await searchOffers(searchRequest);
    return searchResult;
}

function generateSearchRequestOffers(data) {
    const pages = convertStringToArray(data.pageNumbers);
    const filters = {
        includeOldOffers: true,
        customerIds: [],
        definitions: [],
        pageNumbers: pages,
        tags: []
    }

    return {
        filters,
        numberOfRows: 50,
        searchText: data.searchText,
        startRow: 0
    }
}

function convertStringToArray(str) {
    let arr = [];

    if (str.length > 0) {
        arr = str.split(",").map(x => parseInt(x)).filter(y => !isNaN(y));
    }
    return arr;
}

export function validateRequiredFields(searchText, pageNumbers) {
    return !stringIsEmpty(searchText) && !stringIsEmpty(pageNumbers)
}