import { apiGetParamsReplace, endpoints } from "../../../../../constants/services.constants";
import { _request } from "../../../../../helpers/HttpHelper";

export async function addMotivPhotoCombinations(body) {
    return _request({
        method: "POST",
        endpoint: endpoints.PHOTOCOMBINATION_ADD_MOTIVES,
        body: JSON.stringify(body),
        throwError: true
    });
}

export async function getInfoCombination(offerId, combinationId) {
    const endpoint = apiGetParamsReplace(endpoints.PHOTOCOMBINATION_OFFERS_GET_GENERATION_INFO,
        offerId, combinationId
    );

    return await _request({
        method: "GET",
        endpoint: endpoint
    });
}