export function handleCheckPageChangePublication(event, selectedPages, setSelectedPages, spread) {

    const containsSelectedPages = spread.some(page => selectedPages.includes(page));
    if (containsSelectedPages) {
         const [leftPage, rightPage] = spread;
        
        var bothSelected = selectedPages.includes(leftPage) && selectedPages.includes(rightPage);

        if (bothSelected) {
            // Remove spread from selected pages
            setSelectedPages(selectedPages.filter(page => page !== leftPage && page !== rightPage));
        } 
        else{
            //Add them if not null
           AddIfMissing(leftPage, selectedPages, setSelectedPages);
           AddIfMissing(rightPage, selectedPages, setSelectedPages);
        }

    }
    else {
        setSelectedPages(selectedPages.concat(spread));
    }
};

function AddIfMissing(page, selectedPages, setSelectedPages){
    const isPageNotIn = page !== null && !selectedPages.includes(page)
            if (isPageNotIn) {
                setSelectedPages([...selectedPages, page]);
            }

}

export function handleCheckPageChangePoster(event, selectedPages, setSelectedPages) {
    if (selectedPages.includes(Number(event.target.name))) {
        setSelectedPages(selectedPages.filter(page => page !== Number(event.target.name)));
    }
    else {
        setSelectedPages(selectedPages.concat([Number(event.target.name)]));
    }
};

export function handleTemplateChange(e, selectedTemplates, setSelectedTemplates) {
    const page = e.target.name;
    const name = e.target.value;

    if (selectedTemplates.some(x => x.page === page)) {
        const newSelectedTemplates = [...selectedTemplates];
        const index = newSelectedTemplates.findIndex(x => x.page === page);
        newSelectedTemplates[index] = { page: page, name: name };
        setSelectedTemplates(newSelectedTemplates);
    }
    else {
        setSelectedTemplates(selectedTemplates.concat({ page: page, name: name }));
    }
}

export function selectPageOnFirstTemplateSelection(e, selectedPages, setSelectedPages) {
    if (!selectedPages.includes(Number(e.target.name))) {
        setSelectedPages(selectedPages.concat([Number(e.target.name)]));
    }
}