import LogInLayout from "./LogInLayout";
import React, { useState } from 'react';
import ResetPassWord from "./components/ResetPassWord";

function Login(props) {
    const [forgotPassword, setForgotPassword] = useState(false);
    return (
        <>
            {forgotPassword ?
                <ResetPassWord
                    classes={props.classes}
                    setForgotPassword={(value) => setForgotPassword(value)}
                /> :
                <LogInLayout
                    {...props}
                    setForgotPassWord={(value) => setForgotPassword(value)}
                    classes={props.calsses}
                />

            }
        </>
    )
}

export default Login;


