import React from "react";
import { connect } from "react-redux";
import EditIcon from "@material-ui/icons/Edit";
import IconButton from "@material-ui/core/IconButton";

class ActionButtons extends React.Component {
  render() {
    const { onEdit } = this.props;
    return (
      <>
            <IconButton
              color="primary"
              aria-label="edit"
              name="buttonOfferEditor"
          onClick={() => onEdit(this.props.offer.id)}
        >
              <EditIcon />
        </IconButton>
      </>
    );
  }
}

export default connect(state => state)(ActionButtons);
