import React, { useEffect, useState, useContext } from 'react';
import { Typography, withStyles, Fab, LinearProgress, Tooltip, Button } from '@material-ui/core';
import CollectionsTable from './SortingSelecting';
import AddIcon from "@material-ui/icons/Add";
import DialogAdd from './Dialogs/DialogAdd';
import { fetchGetWT } from '../../../../helpers/HttpHelper';
import { endpoints } from '../../../../constants/services.constants';
import { getToken } from '../../../../helpers/AuthHelper';
import { handleAPIErrorNotification } from '../../../../redux/Handlers/ErrorHandlers';
import { addToastMessage } from '../../../../redux/actions/toasts.actions';
import store from '../../../../redux/store';
import { isEmpty } from '../../../../helpers/UtilHelper';
import { credentials } from '../../../../helpers/CredentialsHelper';
import CollectionsContextProvider, { CollectionsContext } from '../contexts/CollectionsContext';
import DialogConfirmDelete from './Dialogs/DialogConfirmDelete';
import { createNewCollectionReq, checkCollectionStatus, fetchCollections } from './Logic/requests';
import SearchBar from '../../../shared/components/searchBar/SearchBar';

const styles = theme => ({
    root: {
        display: "grid",
        gridTemplateColumns: "auto 65px"
    },
    iconButton: {
        margin: "0px 0px 20px 15px"
    }
});

export function Collections(props) {
    return (
        <CollectionsContextProvider>
            <CollectionsBase {...props} />
        </CollectionsContextProvider>
    )
}

function CollectionsBase(props) {
    const { collections: collectionsState, confirmDelete: confirmDeleteState, loading: loadingState, collectionFeeds: collectionFeedsState } = useContext(CollectionsContext);
    const [collections, setCollections] = collectionsState;
    const [confirmDelete,] = confirmDeleteState;
    const [loading, setLoading] = loadingState;
    const [collectionFeeds,] = collectionFeedsState;

    const [showAddDialog, setShowAddDialog] = useState(false);
    const [allowedCustomers, setAllowedCustomers] = useState([]);
    const { classes } = props;
    const [res, setRes] = useState(null);

    useEffect(() => {
        getAllowedCustomers();
    }, []);

    const getAllowedCustomers = async () => {
        let filteredCustomers = [];

        let permissions = credentials();
        const endpoint = endpoints.CUSTOMER_ALL_ALLOWED;
        let customersAll = await fetchGetWT(
            endpoint,
            response => {
                if (response != null) {
                    return response;
                }
                else {
                    throw Error("Null response");
                }
            },
            error => {
                handleAPIErrorNotification(endpoint, error);
                console.log(new Error(error.message))
            }
        );

        customersAll.forEach(y => {
            let customer = permissions.find(x => x.permissionName === "Dco" && x.multiCustomerId === y.id);
            if (customer && customer.canRead) {
                filteredCustomers.push(y);
            }
        });

        setAllowedCustomers(filteredCustomers);
    }

    const openAddDialog = () => {
        setShowAddDialog(true);
    }

    const handleClose = () => {
        setShowAddDialog(false);
    }

    const createNewCollection = async (collection) => {
        if (isEmpty(collection)) {
            store.dispatch(
                addToastMessage({
                    message: "Kollektion kan ikke skabes uden indhold",
                    type: "error",
                    details: null
                })
            );
            return;
        }
        let response = await createNewCollectionReq(collection);
        if (response) {
            setRes(response)
            let newCollections = await fetchCollections();
            newCollections = newCollections.map(x => {
                if (x.collectionId === response) {
                    x.dates.lastUpdated = "Accepted"
                }
                return x;
            })

            updateStatus(response, newCollections);

            setCollections(newCollections);
        }
    }

    const updateStatus = async (response, newCollections) => {
        const interval = setInterval(async () => {

            let resStatus = await checkCollectionStatus(response);
            if (resStatus === "Completed" || resStatus === "Faulted") {
                clearInterval(interval);
            }

            checkStatus(response, newCollections)

        }, 1000);
    }

    const checkStatus = async (res, collectionUpdated) => {
        let resStatus = await checkCollectionStatus(res);
        let newCollection = collectionUpdated.find(x => x.collectionId === res);
        if (resStatus === "BeingCreated") {
            if (newCollection.dates.lastUpdated !== resStatus) {
                collectionUpdated.find(x => x.collectionId === res).dates.lastUpdated = resStatus;
                setCollections([...collectionUpdated])
            }
        }
        if (resStatus === "Faulted") {
            if (newCollection.dates.lastUpdated !== resStatus) {
                collectionUpdated.find(x => x.collectionId === res).dates.lastUpdated = resStatus;
                setCollections([...collectionUpdated])
            }
        }

        if (resStatus === "Completed") {
            store.dispatch(
                addToastMessage({
                    message: "Ny kollektion er nu tilføjet",
                    type: "success",
                    details: null
                })
            );
            const collectionsFinishedUpdate = await fetchCollections();

            setCollections(collectionsFinishedUpdate);
            return;
        }
    }
    return (
        <>
            <Typography variant="h4">Collections</Typography>
            <div className={classes.root}>
                <div style={{ visibility: "hidden" }}>
                    <SearchBar />
                </div>
                <Tooltip title={"Opret kollektion"}>
                    <Fab
                        size="small"
                        color="primary"
                        className={classes.iconButton}
                        onClick={() => openAddDialog()}
                    >
                        <AddIcon />
                    </Fab>
                </Tooltip>
            </div>
            {collections && collections.length !== 0 && !loading ? <CollectionsTable /> : collections && collections.length === 0 && !loading ? <Typography style={{ textAlign: "center" }} variant="h6">Ingen collections er fundet</Typography> : <LinearProgress />}

            {showAddDialog ?
                <DialogAdd
                    open={showAddDialog}
                    handleClose={handleClose}
                    createNewCollection={(collection) => createNewCollection(collection)}
                    allowedCustomers={allowedCustomers}
                /> : undefined}
            {confirmDelete ? <DialogConfirmDelete open={true} /> : undefined}
        </>
    )
}

export default withStyles(styles)(Collections)
