import React, { useEffect, useState } from 'react';
import {
    Dialog, DialogContent, DialogTitle, TextField,
    DialogActions, Button
} from '@material-ui/core';
import { LoadingLogo } from '../../../../shared/Loading';
import AutoSuggestionWithChip from '../../../../shared/components/autoSuggestionComponent/AutoSuggestionWithChip';
import { getPhotoOrderTags } from '../../SharedRequests';
import store from '../../../../../redux/store';
import { patchPhototracker } from '../../../../../redux/actions/photos/fototracker.photos.actions';
import { photoAngleEnum, photoOrderPriorityEnum, photoOrderTypeEnum } from '../../../../../constants/enums';
import { getPhoto } from '../../../photos/Requests';


export default function DialogCommentTags({ photoOrder, setShowCommentDialog, handleSetNewTags, updateDataTable }) {
    const [tagsAutosuggest, setTagsAutoSuggest] = useState([]);
    const [order, setOrder] = useState(undefined);
    const [validFromDate, setValidFromDate] = useState(undefined);
    const [validToDate, setValidToDate] = useState(undefined);
    const [exclusiveCustomerIds, setExclusiveCustomerIds] = useState([]);
    const [loading, setLoading] = useState(true);
    const [photoTags, setPhotoTags] = useState([]);

    useEffect(() => {
        if (!photoOrder) {
            return;
        }
        async function loadData() {
            setLoading(true);
            const autoSuggestTags = await getPhotoOrderTags();
            const photo = await getPhoto(photoOrder.photoId);
            setTagsAutoSuggest(autoSuggestTags);
            setOrder(photoOrder);
            setValidFromDate(photo.validFrom);
            setValidToDate(photo.validTo);
            setExclusiveCustomerIds(photo.exclusiveForCustomerIds);
            setLoading(false);
            setPhotoTags(photo.tags);
        }

        loadData();

    }, [photoOrder]);

    const patchProperty = (value, propName) => {
        let newOrder = { ...order };
        newOrder[propName] = value;
        setOrder(newOrder);
    }

    const updatePhotoOrder = () => {
        setLoading(true);
        let newOrder = { ...order };
        newOrder.angle = newOrder.angle ? photoAngleEnum[newOrder.angle].id : newOrder.angle;
        newOrder.priority = newOrder.priority ? photoOrderPriorityEnum[newOrder.priority].id : newOrder.priority;
        newOrder.type = newOrder.type ? photoOrderTypeEnum[newOrder.type].id : newOrder.type;
        const patchDto = {
            photoOrder: newOrder,
            validFrom: validFromDate,
            validTo: validToDate, referenceFiles: [],
            layouts: [],
            exclusiveForCustomerIds: exclusiveCustomerIds,
            photoTags: photoTags
        }

        store.dispatch(patchPhototracker(order.id, patchDto));

        if (updateDataTable) {
            updateDataTable(photoOrder.id);
        }
        setShowCommentDialog(undefined, false);
    }

    return (
        <Dialog
            open={true}
            onClose={() => setShowCommentDialog(undefined, false)}
            aria-labelledby="form-dialog-title"
            fullWidth={true}
            maxWidth="md"
            disableBackdropClick={false}
        >
            <DialogTitle id="alert-dialog-title">Kommentar</DialogTitle>
            <DialogContent style={{ height: 300 }}>
                {!photoOrder ? <LoadingLogo /> : (
                    <>
                        <div style={{ margin: "40px 0px" }}>
                            <AutoSuggestionWithChip
                                autoSuggestData={tagsAutosuggest}
                                tags={order ? order.tags : []}
                                setTags={(newTags) => patchProperty(newTags, "tags")}
                                allowAddNew={false}
                                label="Fotokoordinator-tags"
                            />
                        </div>
                        <TextField
                            autoFocus
                            margin="dense"
                            id="message"
                            label="Kommentar"
                            rows="4"
                            type="text"
                            name="message"
                            value={order ? order.comment : ""}
                            onChange={(e) => patchProperty(e.target.value, "comment")}
                            InputProps={{
                                multiline: true
                            }}
                            fullWidth
                        />
                    </>
                )}

            </DialogContent>

            <DialogActions>
                <Button onClick={() => setShowCommentDialog(undefined, false)} autoFocus>
                    Annuller
                </Button>
                <Button color="primary" onClick={() => updatePhotoOrder()} disabled={loading}>
                    Gem
                </Button>
            </DialogActions>
        </Dialog >
    )
}