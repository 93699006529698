import React, { useContext } from 'react';
import { withStyles, Dialog, AppBar, Toolbar, IconButton, Typography } from '@material-ui/core';
import { FeedsContext } from '../../../contexts/FeedsContext';
import FeedProperties from './FeedProperties';
import CloseIcon from "@material-ui/icons/Close";
import StatisticsChart from '../../StatisticsChart';
import { fetchFeedsRequest } from '../../Logic/requests';

const styles = theme => ({
    contentHolder: {
        display: "grid",
        gridTemplateColumns: "2fr 1fr",
        height: "100%",
        padding: 10,
        margin: "0 auto",
        maxWidth: 1500

    },
    title: {
        marginLeft: 10,
        flex: 1,
        '& h6': {
            color: "ffffff"
        }
    },
    appBar: {
        backgroundColor: "#263238"
    },
    wrapper: {
        width: "100%",
        height: "100%"
    }
});

const CustomAppBar = withStyles(theme => ({
    root: {
        position: "relative",
        backgroundColor: "#1976d2",
    },
}))(AppBar);

function DialogFeedBuilder(props) {
    const { open, classes } = props;
    const {
        showFeedBuilder: showFeedBuilderState,
        filterWasCreated: filterWasCreatedState,
        selectedFilter: selectedFilterState,
        filterChild: filterChildState,
        selectedOptions: selectedOptionsState,
        lastConnectorIndex: lastConnectorIndexState,
        filterStatistics: filterStatisticsState,
        feeds: feedsState,
    } = useContext(FeedsContext);
    const [, setShowFeedBuilder] = showFeedBuilderState;
    const [, setSelectedFilter] = selectedFilterState;
    const [, setFilterChild] = filterChildState;
    const [, setFilterWasCreated] = filterWasCreatedState;
    const [, setSelectedOptions] = selectedOptionsState;
    const [, setLastConnectorIndex] = lastConnectorIndexState;
    const [, setFilterStatistics] = filterStatisticsState;
    const [, setFeeds] = feedsState;

    const closeFeedBuilderDialog = async () => {
        setSelectedFilter({});
        setFilterChild([]);
        setShowFeedBuilder(false);
        setFilterWasCreated(false);
        setSelectedOptions([]);
        setLastConnectorIndex([]);
        setFilterStatistics({
            numFilteredOffers: 0,
            numOffersOnCollection: 0
        });
        let updatedFeeds = await fetchFeedsRequest();
        setFeeds(updatedFeeds);
    }

    return (
        <>
            <Dialog
                open={open}
                fullWidth
                fullScreen
            >
                <CustomAppBar className={classes.appBar}>
                    <Toolbar>
                        <Typography variant="h6" className={classes.title} style={{ color: "#ffffff" }}>
                            Feed bygger
                        </Typography>
                        <IconButton edge="start" color="inherit" onClick={() => closeFeedBuilderDialog()} aria-label="close">
                            <CloseIcon />
                        </IconButton>
                    </Toolbar>
                </CustomAppBar>
                <div className={classes.contentHolder}>
                    <div className={classes.wrapper}>
                        <FeedProperties />
                    </div>
                    <div className={classes.wrapper}>
                        <StatisticsChart />
                    </div>
                </div>

            </Dialog>
        </>
    )

}

export default withStyles(styles)(DialogFeedBuilder)