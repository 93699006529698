import { Checkbox, FormControl, InputAdornment, InputLabel, ListItemText, MenuItem, Select, TextField, Tooltip } from '@material-ui/core';
import React from 'react';
import Info from '@material-ui/icons/Info';
import { getReadPermissions } from '../../../../helpers/CredentialsHelper';
import { photoOrderStatus, photoOrderTypeEnum, photoTypeEnum } from '../../../../constants/enums';
import { ENVIRONMENT_GROUP, PACK_GROUP } from '../Fototracker';

const BoldTextInDropdown = ({text}) => {
    return (
        <p style={{fontWeight: 800}}>
            {text}
        </p>
    )
}
export function CustomerFilter({ allCustomers, selectedCustomerIds, handleChange }) {


    return (
        <FormControl>
            <InputLabel
                htmlFor="filterCustomer"
            >
                Kunde
            </InputLabel>
            <Select
                multiple
                value={selectedCustomerIds}
                onChange={handleChange}
                inputProps={{
                    name: 'filterCustomer',
                    id: 'filterCustomer',
                }}
                renderValue={(selected) => {
                    return selected.map(id => allCustomers.find(c => c.id === id).name).join(', ')
                }}
                MenuProps={{
                    getContentAnchorEl: null
                }}
            >
                {allCustomers.map(x =>
                    <MenuItem key={x.id} value={x.id}>
                        <Checkbox checked={selectedCustomerIds.includes(x.id)} />
                        <ListItemText primary={x.name} />
                    </MenuItem>
                )}


            </Select>
        </FormControl>)
}

export function TagFilter({ selectedTagFilters, handleChange }) {

    const options = ["FG", "VM", "Slagter", "Bager", "Handle", "Køl", "Frys", "Kok"];

    return (
        <FormControl>
            <InputLabel
                htmlFor="filterTag"
            >
                Fotokoordinator-tags
            </InputLabel>
            <Select
                multiple
                value={selectedTagFilters}
                onChange={handleChange}
                inputProps={{
                    name: 'filterTag',
                    id: 'filterTag',
                }}
                renderValue={(selected) => {
                    return selected.join(', ')
                }}
                MenuProps={{
                    getContentAnchorEl: null
                }}
            >
                {options.map(x =>
                    <MenuItem key={x} value={x}>
                        <Checkbox checked={selectedTagFilters.includes(x)} />
                        <ListItemText primary={x} />
                    </MenuItem>
                )}
            </Select>
        </FormControl>)
}

export function PhotoTypeFilter({ filterPhotoTypes, handleChangeFotoType }) {
    const photoOrderTypes = Object.entries(photoOrderTypeEnum).map(x => x[1]);
    return (
        <FormControl>
            <InputLabel
                htmlFor="filterPhotoTypes"
            >
                Fototype
            </InputLabel>
            <Select
                multiple
                value={filterPhotoTypes}
                onChange={handleChangeFotoType}
                inputProps={{
                    name: 'filterPhotoTypes',
                    id: 'filterPhotoTypes',
                }}
                renderValue={(selected) => {
                    const displayNames = [];
                    if (selected.includes(PACK_GROUP)) {
                        displayNames.push(photoTypeEnum.Pack.name); 
                    }
                    if (selected.includes(ENVIRONMENT_GROUP)) {
                        displayNames.push(photoTypeEnum.Environment.name); 
                    }
                    const photoOrderTypes = selected.filter(x => x !== ENVIRONMENT_GROUP && x !== PACK_GROUP);
                    displayNames.push(...photoOrderTypes.map(x => photoOrderTypeEnum[x].name))
                    return displayNames.join(', ')
                }}
                MenuProps={{
                    getContentAnchorEl: null
                }}
            >
                <MenuItem key={PACK_GROUP} value={PACK_GROUP}>
                    <Checkbox checked={filterPhotoTypes.includes(PACK_GROUP)} />
                    <ListItemText primary={
                        <BoldTextInDropdown text={photoTypeEnum.Pack.name}/>
                } 
                    />
                </MenuItem>
                {photoOrderTypes.filter(y => y.photoType === photoTypeEnum.Pack).map(x =>
                    <MenuItem key={x.id} value={x.key}>
                        <Checkbox checked={filterPhotoTypes.includes(x.key)} />
                        <ListItemText primary={x.name} />
                    </MenuItem>
                )}
                <MenuItem key={ENVIRONMENT_GROUP} value={ENVIRONMENT_GROUP}>
                    <Checkbox checked={filterPhotoTypes.includes(ENVIRONMENT_GROUP)} />
                    <ListItemText 
                        primary={<BoldTextInDropdown text={photoTypeEnum.Environment.name}/>} 
                    />
                </MenuItem>
                {photoOrderTypes.filter(y => y.photoType === photoTypeEnum.Environment).map(x =>
                    <MenuItem key={x.id} value={x.key}>
                        <Checkbox checked={filterPhotoTypes.includes(x.key)} />
                        <ListItemText primary={x.name} />
                    </MenuItem>
                )}

            </Select>
        </FormControl>
    )
}

export function StatusFilter({ selectedStatuses, handleChange }) {
    const allStatuses = [
        photoOrderStatus.ProductNotReceived,
        photoOrderStatus.ProductReceived,
        photoOrderStatus.ReadyForPhotography,
        photoOrderStatus.ProductPhotographed,
        photoOrderStatus.PhotoTrackerUpload,
        photoOrderStatus.ReUse,
        photoOrderStatus.ReShoot,
        photoOrderStatus.Inactive
    ];
    return (
        <FormControl>
            <InputLabel
                htmlFor="filterStatus"
            >
                Status
            </InputLabel>
            <Select
                multiple
                value={selectedStatuses}
                onChange={handleChange}
                inputProps={{
                    name: 'filterStatus',
                    id: 'filterStatus',
                }}
                className="selectorPhotoOrderSearchFilterStatus"
                renderValue={(selected) => {
                    return selected.map(x => photoOrderStatus[x].name).join(', ')
                }}
                MenuProps={{
                    getContentAnchorEl: null
                }}
            >
                {allStatuses.map(x =>
                    <MenuItem key={x.key} value={x.key} className="selectorPhotoOrderSearchFilterStatusItem">
                        <Checkbox checked={selectedStatuses.includes(x.key)} />
                        <ListItemText primary={x.name} />
                    </MenuItem>
                )}
            </Select>
        </FormControl>
    )
}

export function PageFilter({ handleChange }) {
    const tooltip = "1-5 giver dig alle sider ml. 1-5. 1,5,7 giver dig sider uafhængige af hinanden. Du kan også kombinere 1-5,7,10";
    return (
        <TextField
            id="filterPages"
            name="filterPages"
            onChange={handleChange}
            label="Sidenr"
            InputProps={{
                endAdornment: (
                    <InputAdornment position="start">
                        <Tooltip
                            title={tooltip}>
                            <Info />
                        </Tooltip>
                    </InputAdornment>
                ),
            }} />
    )
}

export function CategotyFilter({
    selectedCategories, handleChangeCategories,
    categories, filterWithoutCategory: noCategorySelected, selectOrdersWithoutCategory }) {
    return (
        <FormControl>
            <InputLabel
                htmlFor="filterCategories"
            >
                Kategori
            </InputLabel>
            <Select
                multiple
                value={selectedCategories}
                onChange={handleChangeCategories}
                inputProps={{
                    name: 'filterCategories',
                    id: 'filterCategories',
                }}
                renderValue={(selected) => {
                    return selected.map(x => x.name).join(', ')
                }}
                MenuProps={{
                    getContentAnchorEl: null
                }}
            >
                <MenuItem key={1000} value={1000}>
                    <ListItemText primary={"Alle"} />
                </MenuItem>
                <MenuItem key={1001} value={1001}>
                    <ListItemText primary={"Alle minus Tekstil"} />
                </MenuItem>
                <MenuItem key={1002} value={1002}>
                    <ListItemText primary={"Nustil"} />
                </MenuItem>
                <MenuItem key={1003} value="Ingen kategori">
                    <Checkbox
                        checked={noCategorySelected}
                        onClick={(e) => selectOrdersWithoutCategory(e)}
                    />
                    <ListItemText primary={"Ingen kategori"} />
                </MenuItem>
                {categories.filter(x => x != null && x !== "").map(x =>
                    <MenuItem key={x.id} value={x}>
                        <Checkbox checked={selectedCategories.indexOf(x) > -1} />
                        <ListItemText primary={x.name} />
                    </MenuItem>
                )}
            </Select>
        </FormControl>
    )
}

export function CategoryFilterSimple({selectedCategories, handleChangeCategories, categories}) {
    return (
        <FormControl>
            <InputLabel
                htmlFor="filterCategories"
            >
                Kategori
            </InputLabel>
            <Select
                multiple
                value={selectedCategories}
                onChange={handleChangeCategories}
                inputProps={{
                    name: 'filterCategories',
                    id: 'filterCategories',
                }}
                renderValue={(selected) => {
                    return selected.map(x => x.name).join(', ')
                }}
                MenuProps={{
                    getContentAnchorEl: null
                }}
            >
                {categories.map(x =>
                    <MenuItem key={x.id} value={x}>
                        <Checkbox
                            checked={selectedCategories.some(y => y.id === x.id)}
                        />
                        <ListItemText primary={x.name} />
                    </MenuItem>
                )}
            </Select>
        </FormControl>
    )
}

export function DeletedFilter({ filterDeleted, handleChange }) {
    return (
        <FormControl>
            <InputLabel
                htmlFor="filterDeleted"
            >
                Slettet
            </InputLabel>
            <Select
                value={filterDeleted}
                onChange={handleChange}
                inputProps={{
                    name: 'filterDeleted',
                    id: 'filterDeleted',
                }}
                MenuProps={{
                    getContentAnchorEl: null
                }}
            >
                <MenuItem value={false} >Nej</MenuItem>
                <MenuItem value={true}>Ja</MenuItem>
            </Select>
        </FormControl>
    )
}

export function HideUnusedFilter({ filterHideUnused, handleChange }) {
    return (
        <FormControl>
            <InputLabel
                htmlFor="filterHideUnused"
            >
                Skjul ubrugte
            </InputLabel>
            <Select
                value={filterHideUnused}
                onChange={handleChange}
                inputProps={{
                    name: 'filterHideUnused',
                    id: 'filterHideUnused',
                }}
                MenuProps={{
                    getContentAnchorEl: null
                }}
            >
                <MenuItem value={false} >Nej</MenuItem>
                <MenuItem value={true}>Ja</MenuItem>
            </Select>
        </FormControl>
    )
}