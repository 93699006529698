import React, { useEffect } from 'react'
import EditIcon from '@material-ui/icons/Edit';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import Image from "react-graceful-image";
import { datetimeToFormat1 } from '../../../helpers/UtilHelper';
import RefreshIcon from '@material-ui/icons/Refresh';
import { Typography, Grid, Card, CardContent, CardHeader, IconButton, MuiThemeProvider, CircularProgress } from '@material-ui/core'
import { lightGreen } from '@material-ui/core/colors';
import { endpoints, apiGetParamsReplace } from '../../../constants/services.constants';

import { RepublicaMuiTheme } from '../../../RepublicaMuiTheme';
import { createMuiTheme } from '@material-ui/core';

export default function CorrectorSegmentOfferCombinationMediaCard(
    {
        isWeek,
        loadingMulticheck,
        combination, refreshProcessing,
        refreshProcessings, setRefreshProcessings,
        handleClickOpenCanvas, yearWeekSegmentCombinations,
        handleSaveWeekSegmentCombination, handleDeleteWeekSegmentCombination, isSelected
    }) {
    const theme = createMuiTheme(JSON.parse(JSON.stringify(RepublicaMuiTheme)));
    theme.palette.primary.main = lightGreen[800];
    theme.palette.primary.dark = lightGreen[900];


    function handleOfferGenerationRefresh(g) {
        setRefreshProcessings([g, ...refreshProcessings]);
    }
    function mediaCardTitle() {
        return (
            <div style={{ display: "flex", justifyContent: "space-between" }}>
                <div style={{ marginTop: 10, fontSize: 20 }}>
                    {combination.combinedImageWidth + "x" + combination.combinedImageHeight
                        + (combination.comments === null || combination.comments.trim() === "" ?
                            "" : " (" + combination.comments + ")")}
                </div>
                {
                    loadingMulticheck
                        || (refreshProcessing != null
                            && refreshProcessing.id === combination.id)
                        || (refreshProcessings.some(x => x.id === combination.id))
                        ?
                        <CircularProgress style={{ margin: 10 }} />
                        :
                        <div style={{ textAlign: 'right' }}>
                            <IconButton onClick={() => handleOfferGenerationRefresh(combination)} >
                                <RefreshIcon fontSize="large" color="primary" />
                            </IconButton>
                            <IconButton onClick={() => handleClickOpenCanvas(combination)} >
                                <EditIcon fontSize="large" color="primary" />
                            </IconButton>
                            {!isWeek ? undefined :
                                <MuiThemeProvider theme={theme}>
                                    {yearWeekSegmentCombinations == null ? undefined
                                        : isSelected(combination) ? <>
                                            <IconButton onClick={() => handleDeleteWeekSegmentCombination(combination.id)}>
                                                <CheckCircleIcon fontSize="large"
                                                    color="primary" />
                                            </IconButton>
                                        </>
                                            : <>
                                                <IconButton onClick={() => handleSaveWeekSegmentCombination(combination.id)}>
                                                    <CheckCircleOutlineIcon fontSize="large"
                                                        color="secondary" />
                                                </IconButton>
                                            </>}

                                </MuiThemeProvider>
                            }
                        </div>
                }


            </div>
        );

    }
    return (
        <>
            <Card
                // width={combination.combinedImageWidth+4}
                style={{
                    minWidth: 420,
                    // maxWidth:600,
                    // marginBottom: 25,
                    // marginRight: 25,
                    backgroundColor: "#bfb",
                    // boxShadow: "0px 0px 0px 0px rgba(0,0,0,0.2), 0px 0px 0px 0px rgba(0,0,0,0.14), 0px 0px 0px 0px rgba(0,0,0,0.12)"
                }}

            >
                <CardHeader
                    style={{ backgroundColor: "lightblue", padding: '15px 20px 10px 20px' }}
                    key={combination.id}
                    createdon={combination.createdOn}
                    title={mediaCardTitle(combination)}
                    subheader={(combination.correctionId != null ? (combination.userName == null ? "Rettet" : "Sidst rettet af " + combination.userName + " " + datetimeToFormat1(combination.createdOn)) : undefined)}
                />
                <CardContent
                    align="center"
                    style={{ padding: 10 }}
                >
                    <Image
                        style={{
                            maxWidth: 600,
                            border: "1px dotted"
                        }}
                        className="backgroundColorAnimation"
                        src={apiGetParamsReplace(endpoints.PHOTOCOMBINATION_OFFERS_GET_GENERATION,
                            combination.offerId, combination.id
                        )}
                        width={combination.combinedImageWidth}//"100%"
                        // height={combination.combinedImageHeight}//"100%"
                        placeholderColor="lightblue"
                        // height="300"
                        alt={"combinationOffer" + combination.offerId}
                        retry={{ count: 2, delay: 10 }}
                    />
                </CardContent>
            </Card>
        </>
    )
}



