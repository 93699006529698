import React from 'react';
import { IconButton, Tooltip } from "@material-ui/core";
import SwapHorizontalCircleIcon from "@material-ui/icons/SwapHorizontalCircle";
import { green, grey, red, yellow, orange } from "@material-ui/core/colors"

function getButtonState(photoStatus, isClipped) {
    switch (photoStatus) {
        case "1": // active
        case "Active":
        case "4": // clipped (not supported anymore)
            return {
                disabled: false,
                color: isClipped ? green[500] : grey[900],
                tooltip: isClipped ? "Fritlagt" : "Send til fritlægning"
            };
        case "2": // foto på vej
        case "PhotoBeingTaken":
            return {
                disabled: true,
                color: grey[500],
                tooltip: "Foto på vej"
            };
        case "3": // clipping in progress
        case "PhotoBeingClipped":
            return {
                disabled: true,
                color: yellow[700],
                tooltip: "Afventer fritlægning"
            };
        case "8": // does not exist
        case "DoesNotExist":
        case "9": // deleted
        case "Deleted":
            return {
                disabled: true,
                color: red[500],
                tooltip: "Foto slettet"
            };
        default:
            return {
                disabled: true,
                color: red[500],
                tooltip: "Ukendt status for foto"
            };
    }
}

export function PhotoClippingButton({ photoStatus, isClipped, onClick }) {
    let buttonState = getButtonState(photoStatus, isClipped);
    return (
        <>
            <Tooltip title={buttonState.tooltip}>
                <IconButton
                    className="float-left smaller_icon"
                    style={{ color: buttonState.color }}
                    onClick={() => {
                        // tooltips wont show on disabled buttons so we have to disable the handler instead
                        if (!buttonState.disabled) {
                            onClick()
                        }
                    }}>
                    <SwapHorizontalCircleIcon />
                </IconButton>
            </Tooltip>
        </>
    )
}

export default (PhotoClippingButton)