import Chart from 'react-apexcharts'
import React, { useEffect, useState, useContext } from 'react';
import ReactApexChart from 'react-apexcharts';
import { withStyles } from '@material-ui/core';
import { FeedsContext } from '../contexts/FeedsContext';

const styles = theme => ({
    statistic: {
        textAlign: "center",
        fontSize: "1.25em"
    }
})

function StatisticsChart(props) {
    const {
        filterStatistics: filterStatisticsState,
    } = useContext(FeedsContext);

    const [filterStatistics, setFilterStatistics] = filterStatisticsState;

    const { numFilteredOffers, numOffersOnCollection } = filterStatistics;

    const [series, setSeries] = useState([7]);
    const [options,] = useState({
        chart: {
            height: 350,
            type: "radialBar",
        },
        plotOptions: {
            radialBar: {
                hollow: {
                    size: "70%",
                }
            },
        },
        labels: ["Tilbud matcher filteret"],
    });

    useEffect(() => {
        if (numOffersOnCollection !== 0) {
            let percentage = Math.round(numFilteredOffers / numOffersOnCollection * 100);
            setSeries([percentage]);
        } else {
            setSeries([0]);
        }

    }, [numFilteredOffers, numOffersOnCollection])

    const { classes } = props;

    return (
        <>
            <div id="chart">
                <ReactApexChart options={options} series={series} type="radialBar" height={350} />
                <div className={classes.statistic}>{numFilteredOffers}/ {numOffersOnCollection}</div>
            </div>
        </>
    )

}
export default withStyles(styles)(StatisticsChart)