import { endpoints } from "../constants/services.constants";

export function getCustomerPhoto(customerName) {
  var path = "";

  switch (customerName.trim().toLowerCase()) {
    case "coop.dk":
      path = "/images/organizationLogos/COOP/Coop.gif";
      break;
    case "coop.dk shopping":
      path = "/images/organizationLogos/COOP/CoopShopping.png";
      break;
    case "irma":
      path = "/images/organizationLogos/COOP/Irma.jpg";
      break;
    case "fakta":
      path = "/images/organizationLogos/COOP/Fakta.gif";
      break;
    case "kvickly":
      path = "/images/organizationLogos/COOP/Kvickly.png";
      break;
    case "superbrugsen":
      path = "/images/organizationLogos/COOP/Superbrugsen.jpg";
      break;
    case "dagli'brugsen":
      path = "/images/organizationLogos/COOP/DagliBrugsen.png";
      break;
    case "grønland":
      path = "/images/organizationLogos/Greenland/Grønland.gif";
      break;
    case "republica it":
      path = "/images/organizationLogos/republica.png";
      break;
    case "coop 365":
      path = "/images/organizationLogos/COOP/Coop365.png";
      break;
    case "jem&fix danmark":
      path = "/images/organizationLogos/JemOgFix/JemOgFix_DK.png";
      break;
    case "jem&fix norge":
      path = "/images/organizationLogos/JemOgFix/JemOgFix_NO.png";
      break;
    case "jem&fix sverige":
      path = "/images/organizationLogos/JemOgFix/JemOgFix_SE.png";
      break;
    case "matas":
      path = "/images/organizationLogos/Matas/Matas.png";
      break;
    default:
      break;
  }
  return path;
}

export function getCustomerPhotoFromId(customerId) {
  var path = "";
  switch (customerId) {
    case 51:
      path = "/images/organizationLogos/JemOgFix/JemOgFix_DK.png";
      break;
    case 56:
      path = "/images/organizationLogos/JemOgFix/JemOgFix_NO.png";
      break;
    case 52:
      path = "/images/organizationLogos/JemOgFix/JemOgFix_SE.png";
      break;
    default:
      break;
  }
  return path;
}


export function getMotiv300(motiv, rotate) {
  return getMotivSize(motiv, 300, rotate);
}
export function getMotiv1000(motiv, rotate) {
  return getMotivSize(motiv, 1000, rotate);
}

export function getMotivSize(motiv, size, rotate) {
  var r = endpoints.TRANSFORMATION_V2_GET_MOTIV_SIZE
    .replace("{id}", formatMotiv(motiv))
    .replace("{size}", size.toString());
  if (rotate != null && rotate !== 0) {
    r += "&ro=" + rotate.toString();
  }
  return r;
}

export function getMotivBaseline(motiv) {
  return endpoints.TRANSFORMATION_GET_MOTIV_BASELINE
    .replace("{id}", formatMotiv(motiv));
}

export function formatMotiv(motiv) {
  motiv = motiv.toString();
  if (!motiv.includes("-")) {
    let cut = motiv.length - 4;
    motiv = motiv.substring(0, cut) + "-" + motiv.substring(cut);
    if (motiv.length == 7) {
      motiv = "0" + motiv;
    }
  }
  return motiv;
}