import React from "react";
import "react-alice-carousel/lib/alice-carousel.css";
import { withStyles } from "@material-ui/core/styles";
import { Typography, LinearProgress, Paper } from "@material-ui/core";
import TabSearchOfferItem from "./TabSearchOfferItem";
import PagesSlider from "../../sections/fotohaandtering/PagesSlider";

const styles = theme => ({
  card: {
    width: 150,
    maxWidt: 150,
    minWidth: 150,
    marginLeft: 15,
    marginRight: 15,
    boxShadow: "none"
  },
  media: {
    objectFit: "contain",
    backgroundSize: "contain",
    height: 150,
    width: 150,
    margin: "0 auto"
  },
  searchHeight: {
    height: 265
  },
  cardList: {
    maxHeight: 280,
    overflow: "auto"
  }
});

class TabSearchOfferSlider extends React.Component {
  renderItems(dataItems) {
    return dataItems.map(item => (
      <TabSearchOfferItem
        classes={this.props.classes}
        handleAddOfferPicture={this.props.handleAddOfferPicture}
        photoData={item}
        isLoading={this.props.isLoading === true || this.props.isLoadingSearch === true}
        customerId={this.props.customerId}
      />
    ));
  }

  getSolrValueOrNull(p) {
    if (typeof (p) === "string") return p;
    else if (p == null || p.length === 0) return "";
    else return p[0];
  }

  responsive = {
    0: { items: 2 },
    600: {
      items: 3
    },
    950: {
      items: 4
    },
    1300: {
      items: 4
    },
    1620: {
      items: 4
    },
    2400: {
      items: 4
    }
  };

  stagePadding = {
    paddingLeft: 20,
    paddingRight: 20
  };

  render() {
    const { data, classes, isLoadingSearch } = this.props;

    return (
      <>
        {
          isLoadingSearch === true ? <LinearProgress />
            : data === null ? undefined
              : data.length === 0 ? (
                <Typography className={classes.searchHeight} variant="subtitle1" align="center">
                  Ingen billede fundet...
                </Typography>
              )
                : (
                  <Paper>
                    <PagesSlider
                      items={data == null ? [] : this.renderItems(data)}
                      selectedPages={[1]}
                      productSlider
                    />
                  </Paper>
                )}
      </>
    );
  }
}

export default withStyles(styles)(TabSearchOfferSlider);
