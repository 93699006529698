import React, { useEffect, useState } from "react";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";

import {
    TextField,
    Select,
    MenuItem,
    FormControl,
    InputLabel,
    FormHelperText,
    MuiThemeProvider,
    withStyles,
    Typography,
} from "@material-ui/core";
import { DatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import MomentUtils from "@date-io/moment";
import moment from "moment";
import { getDate, getMotivId, getShortDate, isAO } from "../../../../helpers/UtilHelper";
import store from "../../../../redux/store";
import { deletePhotos } from "../../../../redux/actions/photos/search.photos.actions";
import { reloadPhotoTabProduct } from "../../../../redux/actions/product/products.actions";
import { handleApiSuccessNotificaiton } from "../../../../redux/Handlers/SuccessHandlers";
import { deleteProduct } from "../../../../redux/actions/product/delete.products.actions";
import { LoadingLogo } from "../../../shared/Loading";
import { RepublicaDatePickerMuiTheme } from "../../../../RepublicaMuiTheme";
import { photoDeleteReason } from "../../../../constants/enums";

const styles = (theme) => ({
    dialogContent: {
        width: 510,
        height: 445,
        overflow: "auto"
    },
    error: {
        color: "#eb3434"
    },
    root: {
        "& .MuiFormLabel-root": {
            color: "#000000"
        }
    }
})

function DialogDeletePhoto({
    performSearch, photoIds, handleClose,
    dateDeletePhotoAllowed, dataLoading, classes,
    productIdWithLastPhoto }) {
    const [reason, setReason] = useState("");
    const [selectedDeleteReason, setSelectedDeleteReason] = useState();
    const [multiReasonMessage, setMultiReasonMessage] = useState("");
    const [loading, setLoading] = useState(false);
    const [dateDeleteFrom, setDateDeleteFrom] = useState("");
    const [dateError, setDateError] = useState(false);

    useEffect(() => {
        // wait until date loads
        if (!dateDeletePhotoAllowed && dataLoading) {
            return;
        } else if (!dateDeletePhotoAllowed && !dataLoading) {
            // if photo is not used anywhere allow direct deletion
            setDateDeleteFrom(undefined);
        } else {
            // check if date is before today then do not show date field
            const todayDateFormatted = getDate(new Date().toString());

            const date = new Date(dateDeletePhotoAllowed);
            const dateDeleteFormatted = getDate(date.setHours(date.getHours() + 2));
            if (todayDateFormatted > dateDeleteFormatted) {
                setDateDeleteFrom(undefined);
            } else {
                // detele photo next day offer is expired
                date.setDate(new Date(date).getDate() + 1);
                setDateDeleteFrom(date);
            }
        }

    }, [dateDeletePhotoAllowed, dataLoading]);

    const handleDateSelection = (selectedDate) => {
        if (!isAO(selectedDate)) {
            setDateError("Ugyldig dato");
            return;
        } else {
            // check if selected date is further in the future than allowed date
            const { errorString, dateDeleteNew } = validateNewDeleteDate(dateDeleteFrom, selectedDate);
            if (errorString) {
                setDateError(errorString);
                return;
            } else {
                setDateError();
                setDateDeleteFrom(dateDeleteNew);
            }
        }
    }

    const validateNewDeleteDate = (oldDate, newDate) => {
        let errorString = undefined;
        const dateOld = getDate(new Date(oldDate));
        const dateDeleteNew = getDate(new Date(newDate));
        if (dateOld > dateDeleteNew) {
            errorString = "Du må ikke vælge en dato som er før tilladt dato";
        } else {
            errorString = undefined;
        }
        return { errorString, dateDeleteNew };
    }

    const handleDelete = () => {
        if (!selectedDeleteReason)
            setMultiReasonMessage("Venligst vælg en mulighed");
        else {
            let deleteDto = {
                photoIds: photoIds.map(x => parseInt(x)),
                reason: selectedDeleteReason,
                details: reason,
            };
            let notification = "Foto " + getMotivId(deleteDto.photoIds[0]) + " er blevet slettet";

            if (dateDeleteFrom) {
                //set to deleting
                deleteDto = { ...deleteDto, ...{ validTo: dateDeleteFrom } }
                notification = "Foto " + getMotivId(deleteDto.photoIds[0]) + " sendes til sletning den " + getShortDate(dateDeleteFrom);
            }

            // move this above return
            setLoading(true);
            store.dispatch(
                deletePhotos(deleteDto, async () => {
                    await store.dispatch(reloadPhotoTabProduct(true));
                    store.dispatch(reloadPhotoTabProduct(false));
                    setLoading(false);
                    handleApiSuccessNotificaiton(notification);
                    // delete product if the photo is last product photo
                    if (productIdWithLastPhoto) {
                        await store.dispatch(deleteProduct(productIdWithLastPhoto));
                    }
                    performSearch();
                    handleClose();
                })
            );
        }
    };

    return (
        <MuiPickersUtilsProvider utils={MomentUtils} moment={moment}>
            < Dialog
                open={true}
                onClose={() => handleClose()}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">Slet foto</DialogTitle>
                <DialogContent>
                    {dataLoading ? (
                        <DialogContent
                            className={classes.dialogContent}>
                            <LoadingLogo />
                        </DialogContent>
                    ) : (
                        <>
                            <DialogContent
                                className={classes.dialogContent}>
                                <DialogContentText id="alert-dialog-description">
                                    Er du sikker på at du ønsker at slette{" "}
                                    {photoIds.length > 1
                                        ? photoIds.length + " fotos"
                                        : getMotivId(photoIds[0])}?
                                </DialogContentText>
                                    <FormControl
                                        fullWidth
                                        className={classes.root}>
                                    <InputLabel
                                        htmlFor="selectedDeleteReason"
                                        shrink={
                                            selectedDeleteReason &&
                                            selectedDeleteReason.length !== 0
                                        }
                                    >
                                            Vælg hvorfor billedet skal slettes
                                    </InputLabel>
                                    <Select
                                        value={selectedDeleteReason}
                                        onChange={(e) => setSelectedDeleteReason(e.target.value)}
                                        id="selectorPhotoDeletePhoto"
                                        inputProps={{
                                            name: "selectedDeleteReason",
                                            id: "selectedDeleteReason",
                                        }}
                                        error={multiReasonMessage.length > 0}
                                    >
                                        {Object.keys(photoDeleteReason).map((value) => (
                                            <MenuItem key={value} value={value} className="selectorPhotoDeletePhotoItems">
                                                {photoDeleteReason[value]}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                    <FormHelperText error={multiReasonMessage.length > 0}>
                                        {multiReasonMessage}
                                    </FormHelperText>
                                </FormControl>
                                <TextField style={{marginBottom: 40}}
                                    margin="dense"
                                    label="detaljer"
                                    type="text"
                                    name="reason"
                                    value={reason}
                                    InputProps={{
                                        multiline: true,
                                    }}
                                    rows="2"
                                    onChange={(e) => setReason(e.target.value)}
                                    fullWidth
                                />
                                {!dateDeleteFrom ? undefined : (
                                    <>
                                        <Typography variant="subtitle1">Dette foto slettes</Typography>
                                        <MuiThemeProvider theme={RepublicaDatePickerMuiTheme}>
                                            <DatePicker style={{width: 330}}
                                                margin="normal"
                                                format="Do[.] MMM"
                                                label="Du kan vælge en senere dato i kalenderen"
                                                onChange={(date) => handleDateSelection(date)}
                                                value={dateDeleteFrom}
                                                clearable
                                            />
                                        </MuiThemeProvider>
                                        <div className={classes.error}>{dateError}</div>
                                    </>
                                )

                                }
                            </DialogContent>
                        </>)}
                    <DialogActions>
                        <Button
                            onClick={() => handleClose()}
                            autoFocus
                            disabled={loading}>
                            Annuller
                        </Button>

                        <Button
                            disabled={!selectedDeleteReason || loading}
                            onClick={() => handleDelete()}
                            color="secondary"
                            name="buttonProductDelete"
                        >
                            {loading ? "Sletter..." : "Slet"}
                        </Button>
                    </DialogActions>
                </DialogContent>
            </Dialog>
        </MuiPickersUtilsProvider>
    )
}


export default withStyles(styles)(DialogDeletePhoto)
