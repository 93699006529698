import {
  ADD_PRODUCT_BEGIN,
  REMOVE_PRODUCT_BEGIN,
  OFFER_CLEAN,
} from "../actions/offer/offers.actions";
import {
  EDIT_OPEN_BEGIN,
  EDIT_CLOSE_BEGIN,
  UPDATE_OFFER_BEGIN,
  UPDATE_OFFER_SUCCESS,
  UPDATE_OFFER_FAILURE
} from "../actions/offer/edit.offers.actions";
import {
  EDIT_OFFERPRODUCT_OPEN_BEGIN,
  EDIT_OFFERPRODUCT_CLOSE_BEGIN,
  GET_OFFERPRODUCT_BEGIN,
  GET_OFFERPRODUCT_SUCCESS,
  GET_OFFERPRODUCT_FAILURE
} from "../actions/offer/products.offers.actions";

const initState = {
  id: null,
  publicationName: null,
  products: [],
  queryString: "",
  offer: null,
  offers: null,
  totalCount: 0,
  offset: 0,
  offerId: null,
  offersPerPage: 12,
  editDialog: false,
  loadingOffer: false,
  errorOffer: null,
  loadingTotalCount: false,
  errorTotalCount: null,
  saveLoading: false,
  updateLoading: false,
  generatedImage: null,
  generatedImageLoading: false,
  generatedImageError: null,
  offerproductId: null,
  offerproductIdEditDialog: false,
  setMasterLoading: false,
  offersSearch: [],
  relatedProductsImages: []
};

export default (state = initState, action) => {
  switch (action.type) {
    //-------------------------------------------
    case EDIT_OPEN_BEGIN:
      return {
        ...state,
        id: action.payload.id,
        publicationName: action.payload.publicationName,
        editDialog: true
      };
    case EDIT_CLOSE_BEGIN:
      return {
        ...state,
        id: null,
        publicationName: null,
        editDialog: false,
        products: [],
        offer: null
      };
    //-------------------------------------------
    case EDIT_OFFERPRODUCT_OPEN_BEGIN:
      return {
        ...state,
        offerproductId: action.payload.offerproductId,
        offerId: action.payload.offerId,
        offerproductIdEditDialog: true
      };
    case EDIT_OFFERPRODUCT_CLOSE_BEGIN:
      return {
        ...state,
        offerproductId: null,
        offerId: null,
        offerproductIdEditDialog: false,
        offerproduct: null,
        editDialog: false
      };
    //-------------------------------------------
    case GET_OFFERPRODUCT_BEGIN:
      return {
        ...state,
        offerproduct: null,
        loadingOfferproduct: true,
        errorOfferproduct: null,
        offerproductId: null // set it to null, to handle infinite renders in componentDidUpdate()
      };
    case GET_OFFERPRODUCT_SUCCESS:
      return {
        ...state,
        offerproduct: action.payload.offerproduct,
        loadingOfferproduct: false
      };
    case GET_OFFERPRODUCT_FAILURE:
      return {
        ...state,
        offerproduct: null,
        loadingOfferproduct: false,
        errorOfferproduct: null
      };

    //-------------------------------------------
    case UPDATE_OFFER_BEGIN:
      return {
        ...state,
        updateLoading: true,
        error: null
      };
    case UPDATE_OFFER_SUCCESS:
      return {
        ...state,
        updateLoading: false
      };
    case UPDATE_OFFER_FAILURE:
      return {
        ...state,
        updateLoading: false,
        error: action.payload.error
      };
    //-------------------------------------------
    case ADD_PRODUCT_BEGIN:
      return {
        ...state,
        products: [...state.products, action.payload.product]
      };
    case REMOVE_PRODUCT_BEGIN:
      return {
        ...state,
        products: state.products.filter(
          product => product.id !== action.payload.product.id
        )
      };
    //-------------------------------------------
    case OFFER_CLEAN:
      return {
        ...state,
        id: null,
        products: [],
        offer: null,
        totalCount: 0,
        editDialog: false,
        loadingOffer: false,
        errorOffer: null
      };
    //-------------------------------------------
    default:
      return state;
  }
};
