

const allowPhotoUploadStatuses = new Set(["ProductNotReceived", "ReadyForPhotography", "ProductReceived", "ReShoot"]);

export function checkPhotoUploadIsAllowed(photoOrder) {
    let isAllowed = false;
    if (allowPhotoUploadStatuses.has(photoOrder.status)) {
        isAllowed = true;
    };
    return isAllowed;
};
