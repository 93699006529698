import React, { useEffect } from "react";
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import Image from 'material-ui-image';
import { DialogTitle } from '@material-ui/core';
import createDialog from '../../../DialogRenderer';

export async function openDialogPhotoOrderFiles(photoUrl, fileName) {
  return createDialog(({ show, onSubmit, onDismiss }) => (
    <DialogPhotoOrderFiles photoUrl={photoUrl}
      fileName={fileName}
      open={show}
      onCancel={onDismiss}
      onConfirm={onSubmit}
    />
  ));
}


function DialogPhotoOrderFiles({ photoUrl, fileName, open, onCancel }) {


  return (
    <Dialog
      open={open}
      onClose={onCancel}
      aria-labelledby="form-dialog-title"
      fullWidth={true}
      maxWidth="sm"
    >
      <DialogTitle>{fileName}</DialogTitle>
      <DialogContent>
        <Image
           style={{ "position": "unset", "paddingTop": "unset", }}
           imageStyle={{
             position: "unset",
             maxHeight: 800,
             width: "auto",
             height: "auto",
             maxWidth: "100%",
             display:"block",
             marginLeft: "auto",
            marginRight: "auto"
           }}
          disableTransition={true}
          src={photoUrl}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={onCancel}>Luk</Button>
      </DialogActions>
    </Dialog>
  );

}
