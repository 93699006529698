import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import { DialogActions, DialogContent } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  button: {
    marginLeft: theme.spacing(1),
  },
  step: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
}));

export default function StepperAddV2(props) {
  const { handleClose, defaultStep } = props;

  const classes = useStyles();
  const [activeStep, setActiveStep] = React.useState(defaultStep == null ? 0 : defaultStep);
  const [skipped, setSkipped] = React.useState(new Set());
  const steps = props.steps;

  
  const isStepSkipped = (step) => {
    return skipped.has(step);
  };

  const handleNext = () => {
    let newSkipped = skipped;
    if (isStepSkipped(activeStep)) {
      newSkipped = new Set(newSkipped.values());
      newSkipped.delete(activeStep);
    }

    setActiveStep((prevActiveStep) => prevActiveStep + 1);
    setSkipped(newSkipped);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  return (
    <>
      <DialogContent>

        <Stepper activeStep={activeStep}>
          {steps.map((label, index) => {
            const stepProps = {};
            const labelProps = {};
            if (props.isStepOptional(index)) {
              labelProps.optional = <Typography variant="caption">Valgfrit</Typography>;
            }
            if (isStepSkipped(index)) {
              stepProps.completed = false;
            }
            return (
              <Step key={label} {...stepProps}>
                <StepLabel {...labelProps}>{label}</StepLabel>
              </Step>
            );
          })}
        </Stepper>

        <div className={classes.step}>{props.getStepContent(activeStep)}</div>

      </DialogContent>
      <DialogActions>
        {props.processFinished === true ?
          <Button
            className={classes.button}
            onClick={handleClose}
            name="buttonAddProductCloseDialog"
          >Luk</Button>
          : <>
            <Button
              disabled={props.blockSave === true}
              onClick={handleClose} autoFocus>
              Annuller
            </Button>
            <Button
              disabled={activeStep === 0 || props.blockSave === true}
              onClick={handleBack} className={classes.button}>
              Tilbage
            </Button>
            {activeStep === steps.length - 1 ?
              <Button
                variant="contained"
                color="primary"
                name="buttonAddProductSaveProduct"
                onClick={props.handleSave}
                className={classes.button}
                disabled={props.blockSave === true}
              >Gem</Button>
              : <Button
                color="primary"
                name="buttonAddProductNextStep"
                onClick={handleNext}
                className={classes.button}
                disabled={props.blockNext === true
                  || props.blockSave === true}
              >Næste</Button>
            }
          </>

        }
      </DialogActions>
    </>

  );
}