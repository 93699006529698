import React from 'react';
import { withStyles, Button } from '@material-ui/core';


const StyledButton = withStyles(theme => ({
    root: {
        textTransform: "none",
        color: "rgb(19, 63, 39)",
        fontSize: 12
    },
}))(Button);

export default function DescriptionButton({ style, text, onClick, disabled, fullWidth }) {
    return (
        <StyledButton
            onClick={onClick}
            disabled={disabled}
            style={style}
            fullWidth={fullWidth}
        >
            {text}
        </StyledButton>
    )

}