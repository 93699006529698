import React from "react";

// Redux imports
import { connect } from "react-redux";


import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import { getMotivId } from "../../../../helpers/UtilHelper";

class DialogSuccesfulOrder extends React.Component {
  state = {
    open: false
  };

  render() {
    const { motiv } = this.props;
    return (
      <div>
        <Dialog
          open={true}
          onClose={this.props.closeDialog}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">Nyt MotivId</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
                Ordren er oprettet, dit nye motiv-ID er: {getMotivId(motiv)}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={this.props.closeDialog} color="primary" autoFocus>
                OK
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    );
  }
}

export default connect(state => state)(DialogSuccesfulOrder);
