import React, { useEffect, useState } from "react";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import {
    patchPhototracker, savePhotoOrder,
} from "../../../../../redux/actions/photos/fototracker.photos.actions";
import { photoTypeEnum, photoOrderPriorityEnum, mainPhotoTypes, photoAngleEnum, photoOrderStatus, photoOrderTypeEnum } from "../../../../../constants/enums";
import { withStyles } from "@material-ui/styles";
import store from "../../../../../redux/store";
import { LoadingLogo } from "../../../../shared/Loading";
import PhotoOrderDialogFields from "../DialogAddPhotoOrder/DialogAddPhotoOrderUI/PhotoOrderDialogFields";
import { Button, DialogActions, DialogTitle } from "@material-ui/core";
import { getPhotoOrderTags, getProductName, getPhotoTags } from "../../SharedRequests";
import { getPermissions } from "../../../../../helpers/CredentialsHelper";
import { getBase64, getDateDatePicker, getEANS, getFileBase, showErrorNotification, showSuccessNotification } from "../../../../../helpers/UtilHelper";
import { getPhoto } from "../../../photos/Requests";
import { handleApiSuccessNotificaiton } from "../../../../../redux/Handlers/SuccessHandlers";
import validateExclusiveCustomer from "../DialogAddPhotoOrder/BusinessLogic/Logic";
import { apiGetParamsReplace, endpoints } from "../../../../../constants/services.constants";
import { getToken } from "../../../../../helpers/AuthHelper";
import Resources from "../../../../../Resources.json";
import { openDialogPhotoOrderFiles } from "../../../../shared/dialogs/DialogPhotoOrderFiles";

const styles = {
    rowFullWidth: {
        display: "grid",
        gridTemplateColumns: "1fr 1fr",
        gridGap: "1rem",
    },
    title: {
        color: "rgba(0, 0, 0, 0.87)",
        "& h6": {
            fontWeight: 900,
        },
    },
}

const DialogEditPhotoOrder = (props) => {
    const { categories, photoOrder, handleClose, updateDataTable, omskud, omskudCallback } = props;
    const [order, setOrder] = useState();
    const [disableJobNumber, setDisableJobNumber] = useState(true);
    const [validFrom, setValidFrom] = useState(null);
    const [validTo, setValidTo] = useState(null);

    const [parentPhotoType, setParentPhotoType] = useState();

    const [updateDisable, setUpdateDisable] = useState(false);

    const [layouts, setLayouts] = useState([]);
    const [newLayoutFiles, setLayoutFiles] = useState([]);

    const [refFiles, setRefFiles] = useState([]);
    const [newReferenceFiles, setNewReferenceFiles] = useState([]);

    const [inputValueGTIN, setInputValueGTIN] = useState();
    const statusAllowEdit = new Set([10, 20, 110]);

    const phototrackerReducer = store.getState().fototrackerReducer;

    const [autoSuggestTags, setAutoSuggestTags] = useState([]);
    const [exclusiveForCustomerIds, setExclusiveForCustomerIds] = useState([]);

    const [autoSuggestPhotoTags, setAutoSuggestPhotoTags] = useState([]);
    const [photoTags, setPhotoTags] = useState([]);

    useEffect(() => {
        if (!photoOrder) {
            return;
        } else {
            async function getProductNames() {
                const order = !omskud ? { ...photoOrder } : { ...photoOrder.photoOrderData };
                const autoSuggestTags = getPhotoOrderTags();
                const autoSuggestPhotoTags = getPhotoTags();
                const [_tags, _photoTags] = await Promise.all([autoSuggestTags, autoSuggestPhotoTags]);
                if (order.photoId) {
                    const photo = await getPhoto(order.photoId);
                    setValidFrom(photo.validFrom);
                    setValidTo(photo.validTo);
                    setPhotoTags(photo.tags);
                    setExclusiveForCustomerIds(photo.exclusiveForCustomerIds);
                }
                order.productNumbers = await mapProductNumbersToProductName(order.productNumbers);
                order.priority = photoOrderPriorityEnum[order.priority];

                // set parent photo type and order definition (photo type)
                var enumValue = photoOrderTypeEnum[order.type];
                order.type = enumValue.id;

                const parentPhotoType = enumValue.photoType.id;
                // set photo angle
                order.angle = order.angle ? photoAngleEnum[order.angle].id : photoAngleEnum["Front"].id;

                if (omskud) {
                    // Set files
                    setNewReferenceFiles(photoOrder.referenceFiles);
                    setLayoutFiles(photoOrder.layouts);
                } else {
                    setLayouts(order.layouts.map(x => ({
                        Id: x.id,
                        FileName: x.originalFileName
                    })));

                    setRefFiles(order.referenceFiles.map(x => ({
                        Id: x.id,
                        FileName: x.originalFileName
                    })));
                }
                setDisableJobNumber(order.jobId && order.jobId !== "0");
                setParentPhotoType(parentPhotoType);
                setOrder(order);
                setAutoSuggestTags(_tags);
                setAutoSuggestPhotoTags(_photoTags);
            }
            getProductNames();
        }
    }, [photoOrder]);

    const changeFieldValue = (value, fieldName) => {
        let photoOrder = { ...order };
        photoOrder[fieldName] = value;
        setOrder(photoOrder);
    }

    const mapProductNumbersToProductName = async (productNumbers) => {
        const productArr = await Promise.all(
            productNumbers.map(async number => {
                let product = await getProductName(number);
                return `${number}(${product.name})`
            }))

        return productArr;
    }

    const addProductNumber = async (chips) => {
        const product = await getProductName(chips);
        setInputValueGTIN("");
        let photoOrder = { ...order };
        photoOrder.productNumbers = [...photoOrder.productNumbers, `${product.productNumber}(${product.name})`];
        setOrder(photoOrder);
    }

    const handleUpdateInputGTIN = e => {
        e.persist();
        if (/^[0-9]*$/.test(e.target.value)) {
            setInputValueGTIN(e.target.value);
        }
    };

    const handleDeleteGTIN = (deletedChip) => {
        let photoOrder = { ...order };
        if (photoOrder.productNumbers.length === 1) {
            showErrorNotification("Du må ikke slette det sidste produkt nummer");
            return;
        }
        const productNumbersCopy = [...photoOrder.productNumbers];
        photoOrder.productNumbers = productNumbersCopy.filter(c => c !== deletedChip);
        setOrder(photoOrder);
    }

    const handleDeleteLayout = (deletedChip) => {
        const arrayNew = layouts.filter(c => c.Id !== deletedChip.Id);
        setLayouts(arrayNew);
    }

    const handlePreviewLayout = async (chip) => {
        const endpoint = apiGetParamsReplace(endpoints.LAYOUT_GET_ONE, [
            chip.Id
          ]);
        openDialogPhotoOrderFiles(endpoint, chip.FileName);
    }

    const handlePreviewRefFile = async (chip) => {

        const endpoint = apiGetParamsReplace(endpoints.REFERENCE_PHOTO_GET_ONE, [
            chip.Id
          ]);
        openDialogPhotoOrderFiles(endpoint, chip.FileName);
    }

    const handleDeleteRefFile = (deletedChip) => {
        const arrayNew = refFiles.filter(c => c.Id !== deletedChip.Id);
        setRefFiles(arrayNew);
    }

    const handlePhotoTypeChange = (e, fieldChanged) => {
        const newStatusTypeId = e.target.value;
        let photoOrder = { ...order };
        switch (fieldChanged) {
            case "ParentType":
                //If we change parent photo type, we want to reset the multiPhotoTypeId.
                if (parentPhotoType !== newStatusTypeId) {
                    photoOrder.type = newStatusTypeId === photoTypeEnum.Pack.id ?
                        photoOrderTypeEnum.Pack.id :
                        photoOrderTypeEnum.Environment.id;

                    if (newStatusTypeId === 3) {
                        photoOrder.digitalPack = false;
                    }
                    setParentPhotoType(newStatusTypeId);
                    setOrder(photoOrder);
                }
                break;
            case "SubType":
                photoOrder.type = newStatusTypeId;
                setOrder(photoOrder);
                break;
            default:
        }
    }

    const handleJobNumberChange = (e) => {
        const updatePhotoOrder = { ...order };
        updatePhotoOrder.jobId = e.target.value;
        setOrder(updatePhotoOrder);
    }

    const handleValidFromChange = date => {
        const newDate = new Date(date);
        const dateFormatted = getDateDatePicker(newDate);
        setValidFrom(dateFormatted);
    }

    const handleValidToChange = date => {
        const newDate = new Date(date);
        const dateFormatted = getDateDatePicker(newDate);
        setValidTo(dateFormatted);
    }

    const handleSave = async () => {
        let updatePhotoOrder = { ...order };
        updatePhotoOrder.productNumbers = getEANS(updatePhotoOrder.productNumbers);
        const layoutFilesNew = await getFileBase(newLayoutFiles);
        const refFilesNew = await getFileBase(newReferenceFiles);

        //omskud case
        if (omskud) {
            const categoryId = order.productMainCategoryId;
            updatePhotoOrder.referenceFiles = refFilesNew;
            updatePhotoOrder.layoutFiles = layoutFilesNew
            updatePhotoOrder.validFrom = validFrom;
            updatePhotoOrder.validTo = validTo;
            updatePhotoOrder.categoryId = categoryId;
            updatePhotoOrder.angle = parseInt(order.angle);
            updatePhotoOrder.omskud = omskud;
            updatePhotoOrder.photoTags = photoTags;
            delete updatePhotoOrder.productMainCategoryId;
            createOmskudPhotoOrder(updatePhotoOrder);
        } else {
            updatePhotoOrder.layouts = layouts;
            updatePhotoOrder.referenceFiles = refFiles;

            let requestPayload = {
                photoOrder: {...updatePhotoOrder},
            }
            requestPayload.validFrom = validFrom;
            requestPayload.validTo = validTo;
            requestPayload.referenceFiles = refFilesNew;
            requestPayload.layouts = layoutFilesNew;
            requestPayload.exclusiveForCustomerIds = exclusiveForCustomerIds;
            requestPayload.photoTags = photoTags;
            store.dispatch(patchPhototracker(order.id, requestPayload));
        }

        // the dialog is also called from publication where we do not update the table
        if (updateDataTable) {
            updateDataTable(order.id);
        }

        handleClose();
    }

    const createOmskudPhotoOrder = async (orderDTO) => {
        showSuccessNotification("Opretter fotobestilling...");
        store.dispatch(
            savePhotoOrder(orderDTO, (photoOrderId) => {

                omskudCallback(photoOrderId);

                handleApiSuccessNotificaiton("Fotobestillingen er oprettet");
            })
        );
    }

    const setExclusiveCustomerIds = (ids, jobNo, selectedCustomer) => {
        if (!selectedCustomer || selectedCustomer === "") {
            showErrorNotification("Du skal først vælge kunde");
            return;
        };

        if (jobNo === "") {
            showErrorNotification("Du skal først vælge en job nummer");
            return;
        };

        const { selectionIsValid, errorMsg } = validateExclusiveCustomer(ids, jobNo, selectedCustomer);
        if (!selectionIsValid) {
            showErrorNotification(errorMsg);
            return;
        }

        setExclusiveForCustomerIds(ids);
    }

    return (
        <Dialog
            open={true}
            onClose={handleClose}
            aria-labelledby="form-dialog-title"
            fullWidth
            maxWidth="md"
        >
            <div className={props.classes.rowFullWidth}>
                <DialogTitle id="form-dialog-title" className={props.classes.title}>
                    {!omskud ? "Rediger fotobestilling" : "Opret omskud"}
              </DialogTitle>
            </div>
            <DialogContent>
                {!order ?
                    <LoadingLogo /> :
                    <PhotoOrderDialogFields
                        utilsReducer={store.getState().utilsReducer}
                        credentialsReducer={store.getState().credentialsReducer}
                        // Product numbers field
                        inputValueGTIN={inputValueGTIN}
                        handleAddGTIN={(GTIN) => addProductNumber(GTIN)}
                        handleUpdateInputGTIN={(e) => handleUpdateInputGTIN(e)}
                        handleDeleteGTIN={(deleteGTIN) => handleDeleteGTIN(deleteGTIN)}
                        // Job number field
                        jobNumberDisabled={disableJobNumber}
                        handleJobNumberChange={e => handleJobNumberChange(e)}
                        // Photo type field
                        phototrackerReducer={phototrackerReducer}
                        handlePhotoTypeChange={(e) => handlePhotoTypeChange(e, "ParentType")}
                        multiPhotoParentTypeId={parentPhotoType}
                        // PhotoSub Type (offerDefinition)
                        handlePhotoSubTypeChange={(e) => handlePhotoTypeChange(e, "SubType")}
                        // Category
                        categories={categories}
                        comment={order.comment}
                        // additional params
                        allDisabled={omskud || statusAllowEdit.has(photoOrderStatus[order.status].id) ? false : true}
                        // Layouts 
                        layouts={layouts}
                        handleDeleteLayout={(item) => handleDeleteLayout(item)}
                        handlePreviewLayout={(item) => handlePreviewLayout(item)}
                        handlePreviewRefFile = {(item) => handlePreviewRefFile(item)}
                        layoutFiles={newLayoutFiles}
                        updateFiles={(files) => setLayoutFiles(files.map(contractFile => contractFile.file))}
                        //Ref files
                        refFiles={refFiles}
                        handleDeleteRefFile={(item) => handleDeleteRefFile(item)}
                        newReferenceFiles={newReferenceFiles}
                        setNewReferenceFiles={(fileItems) => setNewReferenceFiles(fileItems.map(fileItem => fileItem.file))}
                        // dates valid from/to get updated on the photo
                        validFrom={validFrom}
                        validTo={validTo}
                        handleDateChangeFrom={(date) => handleValidFromChange(date)}
                        handleDateChangeTo={(date) => handleValidToChange(date)}
                        omskud={omskud}
                        order={order}
                        autoSuggestTags={autoSuggestTags}
                        // exclusive
                        exclusiveForCustomerIds={exclusiveForCustomerIds}
                        onExclusiveForCustomerIdsChange={ids => setExclusiveCustomerIds(ids, order.jobId, order.customerId)}
                        autoSuggestPhotoTags={autoSuggestPhotoTags}
                        photoTags={photoTags}
                        setPhotoTags={(newTags) => setPhotoTags(newTags)}
                        changeFieldValue={(value, fieldName) => changeFieldValue(value, fieldName)}
                        validation={{}}

                    />}
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose}>
                    Annuller
          </Button>

                <Button onClick={() => handleSave()} color="primary" disabled={updateDisable}>
                    Opdater
          </Button>
            </DialogActions>
        </Dialog>

    )
}

export default withStyles(styles)(DialogEditPhotoOrder)