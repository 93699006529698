import React from 'react'
import { Button, makeStyles, Paper, Typography } from '@material-ui/core';
import { styles } from './shared';
import ScreenCarousel from './ScreenCarousel';
import { useState } from 'react';
import MediaLibrary from './MediaLibrary';
import ScreenSaveName from './ScreenSaveName';
import { useEffect } from 'react';
import { fetchGetWT, fetchPostWT, fetchPutWT } from '../../../../helpers/HttpHelper';
import { endpoints, apiGetParamsReplace } from '../../../../constants/services.constants';
import AddOfferMain from './offerComponents/views/AddOfferMain';
import EditOfferMain from './offerComponents/views/editOffer/EditOfferMain';
import ActionButton from "../app/offerComponents/views/button/ActionButton";
import { removeOffersInTemplate } from './templates/businessLogic/BusinessLogic';
import BackButton from './sharedComponents/BackButton';
import BackgroundLibrary from './backgroundComponent/BckgroundLibrary';

export const useStyles = makeStyles({
    buttonMain: {
        width: 250,
        padding: 5
    },
    buttonSearch: {
        width: 250,
        padding: 4,
        height: 45
    }
});

export default function Screen({ 
    tilbage, 
    selection, 
    createFromTemplate,
    customer
 }) {
    //STATE CODES
    //0: new
    //1: saving
    //11: saving template
    //2: ok
    //3: modified
    //4: sendToRender
    //500: error saving
    //501: error sending to render
    const [state, setState] = useState(0);
    const [saveState, setSaveState] = useState(0);
    const [loading, setLoading] = useState(true)
    const [tool, setTool] = useState(null);
    const [id, setId] = useState(null);
    const [screenName, setScreenName] = useState("");
    const [items, setItems] = useState([]);
    const [selectedOfferId, setSelectedOfferId] = useState(undefined);
    const [workingIndex, setWorkingIndex] = useState(null); // index of the slide when creating from template
    const [posterBackground, setPosterBackground] = useState(undefined);
    const classes = useStyles();

    useEffect(() => {
        setLoading(true);
        if (selection != null) {
            fetchGetWT(endpoints.FILMMAKER_SCREENS + "/" + selection, (r) => {
                setItems(JSON.parse(r.items));
                setId(r.id);
                setScreenName(r.name);
                setState(r.state);
                setSaveState(2);
                setLoading(false);
            }, (e) => {
                setLoading(e.message);
            });
        }
        else if (createFromTemplate != null) {
            fetchGetWT(endpoints.FILMMAKER_TEMPLATES + "/" + createFromTemplate, (r) => {
                let items1 = JSON.parse(r.items);
                let items2 = removeOffersInTemplate(items1);
                setItems(items2);
                // setId(r.id);
                // setScreenName(r.name);
                // setState(r.state);
                // setSaveState(2);
                setLoading(false);
            }, (e) => {
                setLoading(e.message);
            });
        }
        else {
            setLoading(false);
        }
        return () => {

        }
    }, []);

    useEffect(() => {
        // saving a new screen, also as a template 
        if ([1, 11].includes(saveState)) {
            let isTemplate = saveState == 11;
            let f = isTemplate || id == null ? fetchPostWT : fetchPutWT;
            let endpoint = isTemplate ? endpoints.FILMMAKER_TEMPLATES
                : id == null ? endpoints.FILMMAKER_SCREENS : endpoints.FILMMAKER_SCREEN.replace("{id}", id);
            f(endpoint,
                JSON.stringify(
                    {
                        name: screenName,
                        items: JSON.stringify(items),
                        customerId: customer
                    }), (r) => {
                        setId(r.id)
                        setState(r.state);
                        setSaveState(2);
                        if (isTemplate) tilbage("templates");
                    }, (e) => {
                        setSaveState(500);
                    });
        }
        if (saveState === 4) {
            fetchPostWT(endpoints.FILMMAKER_SCREEN_RENDER.replace("{id}", id), null,
                (r) => {
                    if (r.state === 2) tilbage();
                    else console.error(r);

                },
                (e) => {
                    setSaveState(501);
                }
            )
        }
        return () => {

        }
    }, [saveState]);

    function addItem(itemToAdd) {
        let _items = [...items];
        typeof (workingIndex) === typeof (0) ? _items[workingIndex] = itemToAdd : _items.push(itemToAdd);
        setItems(_items);
        setSaveState(3);
    }
    function removeItem(itemToRemove) {
        let _items = items.filter(x => x !== itemToRemove);
        setItems(_items);
        setSaveState(3);

    }
    function swapLeft(item) {

        let _items = [...items];
        let index = _items.findIndex(x => x === item);
        var temp = _items[index];
        _items[index] = _items[index - 1];
        _items[index - 1] = temp;
        setItems(_items);
        setSaveState(3);

    }
    function saveScreenName(name) {
        setScreenName(name);
        setSaveState(1);
    }
    function saveTemplate(name) {
        setScreenName(name);
        setSaveState(11);
    }


    if (loading === true) return <></>;
    if (loading !== false) return <Typography>{loading}</Typography>;

    switch (tool) {
        case "media":
            return (
                <MediaLibrary
                    tilbage={
                        () => setTool(null)
                    }
                    mediaSelection={
                        (z) => {
                            addItem({ ...z, type: "media" });
                            setTool(null);
                        }
                    } 
                    customer={customer}
                    />
            );
        case "offer":
            return (
                <AddOfferMain
                    tilbage={
                        () => setTool(null)
                    }
                    onSelectOffer={
                        (offerId) => {
                            setSelectedOfferId(offerId);
                            setTool("edit-offer")
                        }
                    }
                    customer={customer}
                />
            );
        case "edit-offer":
            return (
                <EditOfferMain
                    tilbage={
                        () => setTool(null)
                    }
                    offerId={selectedOfferId}
                    addItem={(z) => addItem({ ...z, type: "offer" })}
                    customer={customer}
                    selectedBackground={posterBackground}
                    setTool={(tool) => setTool(tool)}
                />
            );
        case "save":
            if (screenName.trim() === "") {
                return (
                    <ScreenSaveName
                        screenName={screenName}
                        setScreenName={(v) => saveScreenName(v)}
                        tilbage={
                            () => setTool(null)
                        }
                        customer={customer}
                    />
                );
            } else {
                break;
            }
        case "saveTemplate":
            return (
                <ScreenSaveName
                    screenName={screenName}
                    setScreenName={(v) => saveTemplate(v)}
                    tilbage={
                        () => setTool(null)
                    }
                    templateMode
                    customer={customer}
                />
            );
            case "background":
                return (
                    <BackgroundLibrary
                    tilbage={
                        (tool) => setTool(tool ? tool : null)
                    }
                    mediaSelection={
                        (z) => {
                            setPosterBackground({ ...z, type: "background" });
                            setTool("edit-offer");
                        }
                    } 
                    customer={customer}
                    />
                );
        default:
            break;
        // TODO discuss default case
    };

    // const selectTemplate = async (selectedTemplateId) => {
    //     const screen = await getScreenSingle(selectedTemplateId);
    //     let parsedScreenItems = JSON.parse(screen.items);
    //     parsedScreenItems = removeOffersInTemplate(parsedScreenItems);
    //     setItems(parsedScreenItems);
    // }
    const isEmpty = items.length === 0;
    const blocked = [1].includes(saveState);
    const hasEmptySpaces = items.some(x => x.state === "add");
    return (
        <div>
            <div style={{ width: '100%', display: 'inline-flex', justifyContent: 'space-between' }}>
                <Typography variant="h4" style={styles.viewText}>{createFromTemplate ? "Opret fra skabelon" : "Opret ny skærm"}</Typography>
                <BackButton
                    tilbage={() => tilbage()}
                />
            </div>

            <ScreenCarousel
                openFindMedia={(itemIndex) => {
                    setTool("media")
                    setWorkingIndex(itemIndex);
                }}
                openFindOffer={(itemIndex) => {
                    setTool("offer")
                    setWorkingIndex(itemIndex);
                }}
                {...{ removeItem, swapLeft, blocked, items, createFromTemplate }}
            />

            <div
                style={{
                    marginTop: 50,
                    width: '100%',
                    display: 'flex',
                    justifyContent: 'center',
                    columnGap: 10
                }}>
                <ActionButton
                    text={saveState === 1 ? "Saving..." : "Gem skærm"}
                    disabled={blocked || [2, 501].includes(saveState) || isEmpty}
                    onClick={() => screenName.trim() === "" ? setTool("save") : setSaveState(1)}
                    className={classes.buttonMain}

                />
                {createFromTemplate ? undefined : (
                    <ActionButton
                        text="Gem som skabelon"
                        disabled={blocked || isEmpty}
                        onClick={() => setTool("saveTemplate")}
                        className={classes.buttonMain}

                    />)}
                <ActionButton
                    text="Lav MP4 fil"
                    disabled={blocked || isEmpty || ![2, 501].includes(saveState) || [2, 3].includes(state) || hasEmptySpaces}
                    onClick={() => setSaveState(4)}
                    className={classes.buttonMain}

                />
            </div>
            {![500].includes(saveState) ? undefined : "There was an error on save."}
            {![501].includes(saveState) ? undefined : "There was an error on sending to render."}
        </div>
    )
}
