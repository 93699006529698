import React from "react";

// Redux imports

import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";

import { TextField } from "@material-ui/core";
import { getShortDate } from "../../../../helpers/UtilHelper";
import { getLongDate } from "../../../../helpers/UtilHelper";
import { getCustomerPhoto } from "../../../../helpers/ImageHelper";
import { getPublication } from "../../fotohaandtering/Requests";
import createDialog from "../../../../DialogRenderer";
import store from "../../../../redux/store";
import { LoadingLogo } from "../../../shared/Loading";
import AutoSuggestionWithChip from "../../../shared/components/autoSuggestionComponent/AutoSuggestionWithChip";
import { getPublicationTags, patchPublicationV2 } from "../Requests";

export async function openDialogEditPublication(publicationId, fetchAutoSuggestTags) {
  return createDialog(({ show, onSubmit, onDismiss }) => (
    <DialogEditPublication
      publicationId={publicationId}
      fetchAutoSuggestTags={() => fetchAutoSuggestTags()}
      open={show}
      onCancel={onDismiss}
      onConfirm={onSubmit}
    />
  ));
}

class DialogEditPublication extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      publication: null,
      loading: true,
      tags: [],
      publicationTagsAutoSuggest: []
    };
  }

  async componentDidMount() {
    const [publication, tagsData] = await Promise.all([getPublication(this.props.publicationId), getPublicationTags()]);

    this.setState({
      publication: publication,
      loading: false,
      tags: publication.tags || [],
      publicationTagsAutoSuggest: tagsData || []
    });
  }

  handleSave = async () => {
    const requestData = {
      Id: this.state.publication.id,
      Tags: this.state.tags
    }
    await patchPublicationV2(this.state.publication.id, requestData);
    this.props.fetchAutoSuggestTags();
    this.props.onCancel();
  }

  render() {

    let customerName = "Ukendt";
    const publication = this.state.publication;

    if (!this.state.loading) {
      const customer = store.getState().utilsReducer.customers.find(x => x.id === publication.multiCustomerId);
      if (customer)
        customerName = customer.name;
    }

    return (
      <Dialog
        open={this.props.open}
        onClose={this.handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        fullWidth={true}
        maxWidth="md"
      >
        <DialogTitle id="alert-dialog-title">Rediger publikation</DialogTitle>
        <DialogContent>
          {this.state.loading ? <LoadingLogo></LoadingLogo> :
            <>
              <img
                src={getCustomerPhoto(customerName)}
                style={{ width: 50 }}
                alt={customerName}
              />
              <TextField
                margin="dense"
                label="WB jobnummer"
                type="text"
                name="workbookId"
                value={publication.jobId}
                fullWidth
                disabled
              />
              <TextField
                margin="dense"
                label="Publikationsnavn"
                type="text"
                name="name"
                value={publication.name}
                fullWidth
                disabled
              />
              <TextField
                margin="dense"
                label="	Gyldigt fra"
                type="text"
                name="startDate"
                value={getShortDate(publication.startDate)}
                fullWidth
                disabled
              />
              <TextField
                margin="dense"
                label="	Gyldigt til"
                type="text"
                name="endDate"
                value={getShortDate(publication.endDate)}
                fullWidth
                disabled
              />
              <TextField
                margin="dense"
                label="	Sider"
                type="text"
                name="pageAmount"
                value={publication.numberOfPages}
                fullWidth
                disabled
              />
              <TextField
                margin="dense"
                label="Korrektur"
                type="text"
                name="korrektur"
                value={publication.numberOfCorrections}
                fullWidth
                disabled
              />
              <TextField
                margin="dense"
                label="Format"
                type="text"
                name="description"
                value={publication.description}
                fullWidth
                disabled
              />
              <TextField
                margin="dense"
                label="År/Uge"
                type="text"
                name="weekNumber"
                value={publication.weekNumber}
                fullWidth
                disabled
              />
              <TextField
                margin="dense"
                label="Publikation oprettet"
                type="text"
                name="created"
                value={getLongDate(publication.creationDate)}
                fullWidth
                disabled
              />
              <div style={{
                margin: "16px 0px 8px 0px"
              }}>
                <AutoSuggestionWithChip
                  autoSuggestData={this.state.publicationTagsAutoSuggest}
                  tags={this.state.tags}
                  setTags={(newTags) => this.setState({ tags: newTags })}
                  allowAddNew={true}
                />
              </div>
            </>
          }
        </DialogContent>
        <DialogActions>
          <>
            <Button onClick={() => this.props.onCancel()} autoFocus>
              Luk
            </Button>
            <Button onClick={() => this.handleSave()} autoFocus>
              Gem
            </Button>
          </>
        </DialogActions>
      </Dialog >
    );
  }
}
