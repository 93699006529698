import React, { Component } from "react";
import {
  Grid,
  Card,
  CardContent,
  CardMedia,
  Typography,
  Divider,
  CardActions,
} from "@material-ui/core";
import {
  apiGetParamsReplace,
  endpoints
} from "../../../constants/services.constants";
import ActionButtons from "./ActionButtons";
import { openDialogFullsizePhoto } from "../../shared/dialogs/DialogFullsizePhoto";
import { customers, offerType } from "../../../constants/enums";
import { isActive } from "../../../helpers/UtilHelper";
import { withStyles } from "@material-ui/styles";
import { CustomTooltip } from "../fototracker/SortingSelecting";
import { editDialogOpen, getOfferWithCallback } from "../../../redux/actions/offer/edit.offers.actions";
import store from "../../../redux/store";

const styles = {
  card: {
    maxWidth: 345,
    height: 421,
    position: "relative",
    marginBottom: 20
  },
  cardmedia: {
    height: 140,
    backgroundSize: "contain",
    marginTop: 20
  },
  overlay: {
    position: "absolute",
    top: 133,
    left: 0,
    color: "black",
    backgroundColor: "rgba(93, 129, 76, 0.7)",
    width: 205,
    textAlign: "center"
  },
  activeText: {
    color: "#ffffff",
    fontWeight: "bold",
    marginTop: 3,
    marginButton: 3
  },
  cardProperty: {
    fontSize: "0.575rem",
    display: "block"
  }
};

class SortingItem extends Component {
  constructor(props) {
    super(props);

    this.state = {
      image: "",
    };
  }

  handleDate = s => {
    if (s === null) return "";
    const d = new Date(s);
    return (
      d.getDate() +
      "/" +
      (d.getMonth() + 1) + // 0 indexed month
      "-" +
      d.getFullYear()
    );
  };

  getImage(endpoint, photoId) {
    if (!photoId) {
      return "http://via.placeholder.com/200";
    } else {
      return apiGetParamsReplace(endpoint, photoId);
    }
  }

  handleEditOffer(id, publications) {
    if (this.props.onAdd === undefined) {
      // edit in tilbud
      this.props.loadOffersTags();
      
      var publicationName = publications.length > 0 ? publications[0].name : null;
      store.dispatch(editDialogOpen(id, publicationName));
    } else {
      // edit in filmmaker
      this.props.onAdd(id);
    }

  }

  getOfferPrices = () => {
    const { classes } = this.props;
    return (
      <>
        <Typography variant="caption" className={classes.cardProperty}>
          Normal pris:
          <span style={{ float: "right" }}>
            {this.props.offer.normalPrice ?? "0.00"} kr
          </span>
        </Typography>
        <Typography variant="caption" className={classes.cardProperty}>
          Tilbudspris:
          <span style={{ float: "right" }}>
            {this.props.offer.savingsMessage ?? "0.00"} kr
          </span>
        </Typography>
        <Typography variant="caption" className={classes.cardProperty}>
          Pris:
          <span style={{ float: "right" }}>
            {this.props.offer.price ?? "0.00"} kr
          </span>
        </Typography>
      </>
    )
  }



  render() {
    const { offer, classes, } = this.props;
    const productWithPhoto = offer.products.find(x => x.selectedPhotoId > 0);
    const  photoId = productWithPhoto ? productWithPhoto.selectedPhotoId : null;
    
    return (
      <>
        <Grid item xs={5} md={4} lg={3}>
          <Card style={styles.card}>
            <CardMedia
              style={styles.cardmedia}
              image={photoId === null || photoId === undefined ? "/images/placeholders/no_photo_placeholder.png" : this.getImage(endpoints.PHOTO_SINGLE_200, photoId)}
              title={offer.description}
              className={"cardMedia"}
              onClick={() => openDialogFullsizePhoto(photoId)}
            />
            {isActive(offer.validFrom, offer.validTo) ? (
              <div className="ribbon ribbon-top-right"><span><div>Aktiv</div></span></div>
            ) : (
              undefined
            )}

            <CardContent style={{ overflow: "overlay", height: 212 }}>
              <Typography
                variant="subtitle2"
                gutterBottom
                style={{ fontSize: "0.875rem", fontWeight: "bold" }}
              >
                <CustomTooltip title={`${offer.description}`}>
                  <div className="max1line">
                    {offer.description}
                  </div>
                </CustomTooltip>
              </Typography>
              <Typography variant="caption" className={classes.cardProperty}>
                Gyldig fra:
                <span style={{ float: "right" }}>
                  {this.handleDate(offer.validFrom)}
                </span>
              </Typography>
              <Typography variant="caption" className={classes.cardProperty}>
                Gyldig til:
                <span style={{ float: "right" }}>
                  {this.handleDate(offer.validTo)}
                </span>
              </Typography>
              {this.getOfferPrices()}

              <Typography variant="caption" className={classes.cardProperty}>
                Manus type:
                <span style={{ float: "right" }}>
                  {offer.type ? offerType[offer.type].name : ""}
                </span>
              </Typography>
              <Typography variant="caption" className={classes.cardProperty}>
                Kunde:
                <span style={{ float: "right" }}>
                  {customers[offer.customerId]}
                </span>
              </Typography>
              <Typography variant="caption" className={classes.cardProperty}>
                Avis:
                  <span style={{ float: "right" }}>
                    {offer.publications.length > 0 ? offer.publications[0].name : null}
                  </span>
              </Typography>
              <Typography variant="caption" className={classes.cardProperty}>
                Job nummer:
                <span style={{ float: "right" }}>
                  {offer.publications.length > 0 ? offer.publications[0].jobNumber : null}
                </span>
              </Typography>
              <Typography variant="caption" className={classes.cardProperty}>
                Kode:
                <span style={{ float: "right" }}>
                  {offer.code}
                </span>
              </Typography>
            </CardContent>
            <Divider />
            <CardActions
              style={{
                display: "inherit",
                textAlign: "center",
                paddingTop: 0,
                paddingBottom: 0
              }}
            >
              <ActionButtons
                offer={offer}
                loadOffersTags={() => this.props.loadOffersTags()}
                onEdit={(offerId) => this.handleEditOffer(offerId, offer.publications)}
              />
            </CardActions>
          </Card>
        </Grid>
      </>
    );
  }
}

export default withStyles(styles)(SortingItem)
