import React, { Component, useState } from "react";

// Redux imports
import { connect } from "react-redux";
import {
  Typography,
  Grid,
  Paper,
  InputBase,
  withStyles,
  LinearProgress
} from "@material-ui/core";
import { changeSelectedProduct, changeSelectedProductSuccess } from "../../../../redux/actions/photos/fototracker.photos.actions";
import { searchPhotosWithoutFilters } from "../../photos/Requests";
import PagesSlider from "../../fotohaandtering/PagesSlider";
import ProductItem from "../Slider/ProductItem";
import { activePhotoFutureDate } from "../../../../helpers/UtilHelper";
import Pagination from "material-ui-flat-pagination";

const styles = theme => ({
  root: {
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "space-around",
    overflow: "hidden",
    backgroundColor: theme.palette.background.paper,
    padding: 5
  },
  input: {
    marginLeft: 20,
    marginRight: 20,
    flex: 1
  },
  headline: {
    margin: "30px 0px 30px 0px"
  },
  searchProgress: {
    height: 265,
    margin: "30px 0px 30px 0px"
  },
  searchField: {
    minHeight: 400,
  },
  photosHolder: {
    marginTop: 30
  }
});

function ChooseProductPicture(props) {
  const { classes, uploadIsDisabled } = props;
  const rowsPerPage = 20;
  const [loading, setLoading] = useState(false);
  const [searchText, setSearchText] = useState("");
  const [searchTagsText, setSearchTagsText] = useState("");
  const [searchRowOffset, setSearchRowOffset] = useState(0);
  const [totalRows, setTotalRows] = useState(0);
  const [photos, setPhotos] = useState([]);

  const keyPress = e => {
    if (e.keyCode === 13) {
      performSearch();
    }
  };

  const performSearch = async (offset) => {
    if (!searchText && !searchTagsText) return;

    // searching
    // remove selected product from a previous search
    props.dispatch(changeSelectedProductSuccess(null));
    setLoading(true);

    const tags = searchTagsText ? searchTagsText.split(";").map(x => x.trim()) : [];
    const searchResult = await searchPhotosWithoutFilters(searchText, tags, rowsPerPage, offset);

    const filteredResult = searchResult.rows.filter(x => x.exclusiveForCustomerIds.length === 0)
    setLoading(false);
    setPhotos(filteredResult);
    setSearchRowOffset(offset);
    setTotalRows(searchResult.totalRows);

    setTimeout(() => {
      uploadIsDisabled(1); // send 1 as an id of the tab where endpoint will be called
    }, 0);
  };

  const handleAddProduct = async (product) => {
    props.dispatch(changeSelectedProduct(product));
    setTimeout(() => {
      uploadIsDisabled(1); // send 1 as an id of the tab where endpoint will be called
    }, 0);
  }

  const setProductItems = () => {
    const data = photos.map(photo => {
      const product = photo.products[0];
      return {
        name: product ? product.name : "",
        productNumber: product ? product.productNumber : "",
        multiPhotoId: photo.id,
        id: product ? product.id : "",
        futurePhotoDate: activePhotoFutureDate(photo.validFrom),
        status: photo.status,
        validTo: photo.validTo
      };
    }).map(x => {
      return <ProductItem
        classes={classes}
        handleAddProduct={(product) => handleAddProduct(product)}
        product={x}
      />
    });

    return data;
  }

  return (
    <>
      <div className={classes.headline}>
        <Typography variant="h6" align="center">
          Søg produktbilleder
        </Typography>
      </div>
      <div className={classes.searchField}>
        <Grid
          container
          spacing="1"
          direction="row"
          justify="center"
          alignItems="center">
          <Grid xs={6} item>
            <Paper className={classes.root} elevation={1}>
              <InputBase
                autoFocus
                className={classes.input}
                placeholder="Søg produkt..."
                onKeyDown={keyPress}
                onChange={e => setSearchText(e.target.value)}
                value={searchText}
              />
            </Paper>
          </Grid>
          <Grid xs={4} item>
            <Paper className={classes.root} elevation={1}>
              <InputBase
                autoFocus
                className={classes.input}
                placeholder="Tags"
                onKeyDown={keyPress}
                onChange={e => setSearchTagsText(e.target.value)}
                value={searchTagsText}
              />
            </Paper>
          </Grid>
        </Grid>
        {totalRows > rowsPerPage ?
          <Pagination
            limit={rowsPerPage}
            offset={searchRowOffset}
            total={Math.min(totalRows, 100 * rowsPerPage)}
            onClick={(event, offset) => performSearch(offset)}
            centerRipple={true}
            style={{ textAlign: "center" }}
          /> : undefined
        }

        {photos.length === 0 && !loading ? undefined : loading ? (
          <div className={classes.searchProgress}>
            <LinearProgress />
          </div>
        ) : (
          <div className={classes.photosHolder}>
            <Paper>
              <PagesSlider
                items={setProductItems()}
                selectedPages={[1]}
                productSlider
              />
            </Paper>
          </div>
        )}
      </div>
    </>
  );
}

export default connect(state => state)(
  withStyles(styles)(ChooseProductPicture)
);
