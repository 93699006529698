import React, { useEffect, useState } from 'react';
import Input from '@material-ui/core/Input';
import Button from '@material-ui/core/Button';
import InputLabel from '@material-ui/core/InputLabel';
import Box from '@material-ui/core/Box';
import { createHelpPage, updateHelpPage, uploadPhoto, getHelpPages } from './businessLogic/FetchHeadlines'
import { FilePond } from 'react-filepond';

function FormHelpPage(props) {
  const { helpPageObject, modalOpen, setHelppages } = props;
  const [formFields, setFormFields] = useState([])
  const baseObject = {
    id: undefined, created: undefined, lastUpdated: undefined, title: "",
    steps: [{ id: undefined, orderId: 1, text: "", fileName: "" }]
  }
  const [formTitle, setFormTitle] = useState(baseObject);

  useEffect(() => {
    if (!helpPageObject) {
      return;
    }
    setFormTitle(helpPageObject && helpPageObject);
    setFormFields(helpPageObject && helpPageObject.steps);
  }, [])

  const handleFormChange = (event, index) => {
    let data = [...formFields];
    data[index][event.target.name] = event.target.value;
    setFormFields(data);
  }

  const handleFileChange = (event, index) => {
    if (event && event.length > 0) {
      let data = [...formFields];
      data[index]['file'] = event[0].file;
      setFormFields(data);
    }
  }

  const handleTitleChange = (event) => {
    setFormTitle({ ...formTitle, [event.target.name]: event.target.value });
  }

  const submit = async (e) => {
    e.preventDefault();
    const data = [...formFields];
    formTitle.steps = data;
    for (let i = 0; i < formTitle.steps.length; i++) {
      if (formTitle.steps[i].file) {
        await uploadPhoto(formTitle.steps[i].file);
        formTitle.steps[i].fileName = formTitle.steps[i].file.name;
        delete formTitle.steps[i].file;
      }
    }
    if (formTitle.id === undefined) {
      await createHelpPage(formTitle)
    } else {
      await updateHelpPage(formTitle)
    }
    await syncHelpPages();
    modalOpen(false);
  }

  const syncHelpPages = async () => {
    const pages = await getHelpPages();
    if (!pages) {
      setHelppages([])
      return;
    }
    setHelppages(pages);
  }

  const addFields = () => {
    const object = { orderId: undefined, text: "", fileName: "" }
    setFormFields([...formFields, object]);
  }

  const removeFields = (index) => {
    let data = [...formFields];
    data.splice(index, 1)
    setFormFields(data);
  }

  return (
    <div className="App">
      <form onSubmit={submit}>
        <InputLabel>Title</InputLabel>
        <Input
          name='title'
          placeholder='Indsæt titlen for hjælpe siden'
          onChange={event => handleTitleChange(event)}
          value={formTitle && formTitle.title}
          style={{ margin: "1.5rem" }}
        />
        {formFields.map((form, index) => {
          return (
            <div key={index}>
              <input
                name='orderId'
                placeholder='Ordre Id'
                onChange={event => handleFormChange(event, index)}
                value={form.orderId = index}
                hidden
              />
              <Box width="75%">
                <InputLabel>Tekst</InputLabel>
                <Input
                  name='text'
                  placeholder='Indsæt den tekst der skal til i dette trin'
                  onChange={event => handleFormChange(event, index)}
                  value={form.text}
                  fullWidth
                  multiline
                  rows={5}
                  style={{ margin: "1rem" }}
                />
                <InputLabel>Billede</InputLabel>
                {form.fileName == "" ? undefined : <p>!Indsæt et nyt billede eller hold billede sektionen tom, for at beholde billedet fra før!</p>}
                <FilePond
                  allowImagePreview={true}
                  onupdatefiles={event => handleFileChange(event, index)}
                  allowMultiple={false}
                  imagePreviewHeight={50}
                  labelIdle={
                    'Træk og slip din billed fil eller <span class="filepond--label-action">Gennemse</span>'
                  }
                />
                <br />
                <Button style={{ margin: "1rem" }} variant="contained" color="secondary" onClick={() => removeFields(index)}>Slet Trin</Button>
              </Box>
            </div>
          )
        })}
      </form>
      <Button style={{ margin: "2rem" }} variant="contained" onClick={addFields}>Tilføj trin</Button>
      <br />
      <div align="right">
        <Button style={{ margin: "1rem", paddingRight: "1.7rem", paddingLeft: "1.7rem" }} variant="contained" color="primary" onClick={submit}>Indsæt</Button>
      </div>
    </div>
  );
}

export default FormHelpPage;