import { Typography } from '@material-ui/core';
import React, { useEffect, useRef, useState } from 'react';
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import { Button, withStyles } from '@material-ui/core';

const styles = {
    messageTxt: {
        color: "#f44336",
        fontWeight: 500,
        fontSize: "1.6em"
    },
    buttonsHolder: {
        display: "grid",
        gridTemplateColumns: "1fr 1fr",
        gridGap: 10
    }
}

function DialogDeleteLastProductPhoto({ openAddPhotoOrderDialog, classes,
    closeDialogDeleteLastProductPhoto, confirmDeleteLastProductPhoto,
    openUploadNewPhoto }) {
    return (
        <div>
            <Dialog
                open={true}
                onClose={() => closeDialogDeleteLastProductPhoto()}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">Slet foto</DialogTitle>
                <DialogContent>
                    <p className={classes.messageTxt}>
                        Du er i færd med at slette det sidste billede på dette produkt.
                        <br />
                        Sletning vil også medføre sletning af produktet fra Multibasen.
                    </p>
                    <div className={classes.buttonsHolder}>
                    <Button
                        onClick={() => openAddPhotoOrderDialog()}
                        autoFocus
                            color="primary"
                            variant="contained"
                    >
                        Bestil nyt foto
                    </Button>

                    <Button
                        onClick={() => openUploadNewPhoto()}
                            color="primary"
                            variant="contained"
                    >
                        Upload nyt foto
                    </Button>
                    </div>

                </DialogContent>
                <DialogActions>
                    <Button
                        onClick={() => closeDialogDeleteLastProductPhoto()}
                        autoFocus
                    >
                        Annuller
                    </Button>

                    <Button
                        onClick={() => confirmDeleteLastProductPhoto()}
                        color="secondary"
                    >
                        Slet produkt
                    </Button>
                </DialogActions>
            </Dialog>
        </div>

    )
}

export default withStyles(styles)(DialogDeleteLastProductPhoto);