import React, { useEffect, useState } from 'react';
import { connect } from "react-redux";

import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import MobileStepper from '@material-ui/core/MobileStepper';
import Button from '@material-ui/core/Button';
import KeyboardArrowLeft from '@material-ui/icons/KeyboardArrowLeft';
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight';
import { LinearProgress } from "@material-ui/core";

import {
  apiGetParamsReplace,
  endpoints
} from "../../../constants/services.constants";
import { getToken } from '../../../helpers/AuthHelper';
import { LoadingLogo } from '../../shared/Loading';
import Resources from "../../../Resources.json";
//this will need to be fetched from the endpoint... 

const styles = theme => ({
  root: {
    flexGrow: 1,
    height: '95%',
  },
  header: {
    display: 'flex',
    alignItems: 'center',
    height: 50,
    paddingLeft: theme.spacing(4),
    backgroundColor: theme.palette.background.default,
  },
  img: {
    overflow: 'hidden',
    display: 'block',
    height: '95%',
    margin: 'auto',
  },
});


function Carousel(props) {
  const { classes, theme, referenceFiles } = props;
  const maxSteps = referenceFiles.length;
  const [activeStep, setActiveStep] = useState(0);
  const [referenceFile, setReferenceFile] = useState(undefined);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (referenceFiles.length !== 0) {
      getReferenceFile();
    }
  }, [activeStep]);


  const getReferenceFile = async () => {
    setLoading(true);
    const endpoint = apiGetParamsReplace(endpoints.REFERENCE_PHOTO_GET_ONE, [
      referenceFiles[activeStep].id
    ]);

    const response = await fetch(endpoint, {
      method: "GET",
      headers: 
      { 
        Authorization: "Bearer " + getToken(),
        "x-organization-id": Resources.Organization
      }
    });

    const resBlob = await response.blob();

    let reader = new FileReader();
    reader.onload = function () {
      const dataUrl = reader.result;
      setReferenceFile(dataUrl);
      setLoading(false);
    };
    reader.readAsDataURL(resBlob);
  }

  return (
    <>
      {referenceFiles.length === 0 && !loading ?
        undefined : loading ?
          <div className={classes.img}>
            <LoadingLogo />
          </div> : (
          <div className={classes.root}>
              {referenceFile ? (
            <img
              className={classes.img}
                  alt={referenceFiles[activeStep].originalFilename}
                  src={referenceFile}
          />
              ) : undefined}
            <MobileStepper
              steps={maxSteps}
              position="static"
              activeStep={activeStep}
              className={classes.mobileStepper}
              nextButton={
                <Button size="small" onClick={() => setActiveStep(activeStep + 1)} disabled={activeStep === maxSteps - 1}>
                  Next
                      {theme.direction === 'rtl' ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
                </Button>
              }
              backButton={
                <Button size="small" onClick={() => setActiveStep(activeStep - 1)} disabled={activeStep === 0}>
                  {theme.direction === 'rtl' ? <KeyboardArrowRight /> : <KeyboardArrowLeft />}
                      Back
                  </Button>
              }
            />
          </div>
          )
      }
    </>
  )
}

export default connect(state => state)(withStyles(styles, { withTheme: true })(Carousel));