import { withStyles, Box, Typography, Tab, Tabs } from '@material-ui/core'
import React, { useEffect, useRef, useState } from 'react';
import ContentErrorBoundary from '../../../../ErrorBoundaries/ContentErrorBoundary';
import { photoTypeEnum } from "../../../../../constants/enums";
import { CustomerSelect } from '../../../fototracker/FototrackerFormInputs';
import SelectPhotoTypes from '../../../../shared/PhotoSelectionComponents/SelectPhotoType';
import store from '../../../../../redux/store';
import { FilePond } from "react-filepond";
import PhotoOrderTab from '../../TabContainers/PhotoOrderTab';
import {allowedPhotoFormatArray} from "../../../../../constants/enums";
import {validateFileExtentionOnUpload} from "../../../../shared/SharedFunctions";
import GS1Tab from '../../../../shared/GS1Tab';

const styles = {
  editIcon: {
    paddingTop: 7,
    paddingRight: 7,
    position: "absolute",
    bottom: 0,
    right: 0,
    display: "block",
  },
  tabsRoot: {
    borderBottom: "1px solid #e8e8e8",
  },
  tabsIndicator: {
    backgroundColor: "#1890ff",
  },
}

function StepPhoto(props) {
  const { startObject, returnFinalObject, setStepReady, classes, information } = props;

  const [tabIndex, setTabIndex] = useState(0);
  const [customerId, setCustomerId] = useState(undefined);
  const [photoType, setPhotoType] = useState(photoTypeEnum.Pack.id);
  const [photo, setPhoto] = useState(undefined);
  const [gs1Url, setGs1Url] = useState(undefined);
  const [jobNumber, setJobNumber] = useState("");
  const [validFrom, setValidFrom] = useState(new Date());
  const [validTo, setValidTo] = useState(null);
  const [clipPhoto, setClipped] = useState(true);

  useEffect(() => {

    if (startObject) {
      // in case we have to load from a previous state
      switch (startObject.source) {
        case "gs1":
          setTabIndex(0);
          setCustomerId(startObject.customerId);
          setPhotoType(startObject.photoType);
          setGs1Url(startObject.url);
          break;
        case "upload":
          setTabIndex(1);
          setCustomerId(startObject.customerId);
          setPhotoType(startObject.photoType);
          setPhoto(startObject.photo);
          break;
        case "order":
          setTabIndex(3);
          setCustomerId(startObject.customerId);
          setJobNumber(startObject.jobNumber);
          setValidFrom(startObject.validFrom);
          setValidTo(startObject.validTo);
          setClipped(startObject.clipPhoto);
          break;
        case "no photo":
          setTabIndex(4);
      };
    }

    return function () {
      if (returnFinalObject != null) {
        let finalObj = finalObjectRef.current();
        returnFinalObject(finalObj);
      }
    }
  }, []);

  useEffect(() => {
    handleTabsReset();
  }, [tabIndex]);

  useEffect(() => {
    evaluateStepReady();
  });

  function handleTabsReset() {
    setPhoto(undefined);
    setGs1Url(undefined);
    setJobNumber("");
    setValidFrom(new Date());
    setValidTo(null);
  }

  function addFileUpload(selection) {
    if (!selection || selection.length === 0) {
      setPhoto(undefined);
    } else {
      setPhoto(selection[0].file);
    }
  }

  const finalObjectRef = useRef(null);
  finalObjectRef.current = getFinalObj;

  function getFinalObj() {
    switch (tabIndex) {
      case 0:
        return {
          source: "gs1",
          customerId,
          photoType,
          url: gs1Url
        }
      case 1:
        return {
          source: "upload",
          customerId,
          photoType,
          photo: photo
        }
      case 2:
        return {
          source: "order",
          customerId,
          jobNumber,
          validFrom,
          validTo,
          clipPhoto,
          angle: "Front" // Always create photo order from the front
        }
      case 3:
        return {
          source: "no photo"
        };
    };
  };

  function evaluateStepReady() {
    switch (tabIndex) {
      case 0:
        // gs1
        setStepReady(gs1Url && customerId);
        break;
      case 1:
        // upload
        setStepReady(photo && customerId);
        break;
      case 2:
        // photo order
        setStepReady(customerId && jobNumber);
        break;
      case 3:
        // no photo
        setStepReady(true);
        break;
    }
  }

  function customerAndType() {
    return (
      <div style={{ margin: "auto", marginTop: 20, width: 400 }}>
        <CustomerSelect
          validation={{ kundeErrorMessage: customerId ? null : "Skal være udfyldt" }}
          utilsReducer={store.getState().utilsReducer}
          credentialsReducer={store.getState().credentialsReducer}
          multiCustomerId={customerId}
          onChange={e => setCustomerId(e.target.value)}
          disabled={false}
        />

        <div style={{ marginBottom: 10, marginTop: 10 }}>
          <SelectPhotoTypes
            selection={photoType}
            handleSelectPhotoType={(photoType) => setPhotoType(photoType)}
          />
        </div>
      </div>
    )
  }

  return (
    <Box align="center">
      <ContentErrorBoundary>

        <Tabs
          value={tabIndex}
          onChange={(e, value) => setTabIndex(value)}
          classes={{
            root: classes.tabsRoot,
            indicator: classes.tabsIndicator,
          }}
        >
          <Tab
            disableRipple
            classes={{
              root: classes.tabRoot,
              selected: classes.tabSelected,
            }}
            label="GS1 foto"
          />
          <Tab
            disableRipple
            classes={{
              root: classes.tabRoot,
              selected: classes.tabSelected,
            }}
            label={"Upload"}
          />
          <Tab
            disableRipple
            classes={{
              root: classes.tabRoot,
              selected: classes.tabSelected,
            }}
            label="Ny Fotobestilling"
          />
          <Tab
            disableRipple
            classes={{
              root: classes.tabRoot,
              selected: classes.tabSelected,
            }}
            name="buttonAddProductAddNoPhotoTab"
            label="Intet foto"
          />
        </Tabs>
        {tabIndex === 0 && (
          <>
            {customerAndType()}
            <GS1Tab
              productNumner={information.productNumber}
              addGs1ImagesToProduct={setGs1Url}
              title="vælg som standard"
            />
          </>
        )}
        {tabIndex === 1 && (
          <>
            {customerAndType()}
            <FilePond
              allowImagePreview={true}
              onupdatefiles={addFileUpload}
              allowMultiple={false}
              maxFiles={1}
              imagePreviewHeight={500}
              beforeAddFile={(item) => validateFileExtentionOnUpload(item.file)}
              labelIdle={
                'Træk og slip din billedfil eller <span class="filepond--label-action">Gennemse</span>'
              }
            />
          </>
        )}
        {tabIndex === 2 && (
          <div style={{ margin: "auto", maxWidth: 700 }}>
            <PhotoOrderTab
              customerId={customerId}
              setCustomerId={setCustomerId}
              jobNumber={jobNumber}
              setJobNumber={setJobNumber}
              validFrom={validFrom}
              setValidFrom={setValidFrom}
              validTo={validTo}
              setValidTo={setValidTo}
              productNumber={information.productNumber}
              categoryName={information.category.name}
              clipPhoto={clipPhoto}
              setClipped={setClipped}
            />
          </div>
        )}
        {tabIndex === 3 && (
          <div style={{ margin: "auto", maxWidth: 700, marginTop: 50 }}>
            <Typography>Produktet vil blive oprettet uden noget foto</Typography>
          </div>
        )}
      </ContentErrorBoundary>
    </Box>
  )
}


export default withStyles(styles)(StepPhoto);