export function validateValueType({ validPropertyType, value }) {
    if (!value) {
        return true;
    }

    if (validPropertyType === "String" && typeof value === "string" && value.length !== 0) {
        return true;
    }

    if (validPropertyType === "Number") {
        const isInt = !isNaN(parseInt(value, 10));

        if (isInt) {
            return true;
        }

        // Parse float doesn't work for danish formating.
        // If you need american use parseFloat() instead.
        const isFloat = value.match(/\d+[,]\d+/)

        if (isFloat) {
            return true;
        }
    }

    if (validPropertyType === "Date" && value instanceof Date) {
        return true;
    }

    if (validPropertyType === "Boolean" && typeof value === "boolean") {
        return true;
    }

    return false;
}