import store from "../redux/store";

export const featureNames = {

    Publications_Enabled: "Publications_Enabled",
    Photos_Enabled: "Photos_Enabled",
    PhotoOrders_Enabled: "PhotoOrders_Enabled",
    Offers_Enabled: "Offers_Enabled",
    Logos_Enabled: "Logos_Enabled",
    Products_Enabled: "Products_Enabled",
    PhotoCombinations_Enabled: "PhotoCombinations_Enabled",
    Dco_Enabled: "Dco_Enabled",
    Tools_Enabled: "Tools_Enabled",
    Filmmaker_Enabled: "Filmmaker_Enabled",
    Offers_Definitions_Enabled: "Offers_Definitions_Enabled",
    Offers_Customer_Enabled: "Offers_Customer_Enabled",
    Offers_Search_Expired_Enabled: "Offers_Search_Expired_Enabled",
    Products_Categories_Enabled: "Products_Categories_Enabled",
    Photos_Clipping_Enabled:"Photos_Clipping_Enabled",
    Photos_Delete_Enabled:"Photos_Delete_Enabled",
    Photos_Status_Enabled:"Photos_Status_Enabled",
    Products_Edit_GS1_Enabled: "Products_Edit_GS1_Enabled",
    Products_Upload_Photo_Enabled: "Products_Upload_Photo_Enabled",
    Products_Edit_UnLink_Photo_Enabled: "Products_Edit_UnLink_Photo_Enabled",
    Products_Delete_Enabled: "Products_Delete_Enabled",
    Products_Create_Enabled: "Products_Create_Enabled",
    Photos_Edit_RelateProducts_Enabled:"Photos_Edit_RelateProducts_Enabled",
    Photos_Products_Relation_Enabled:"Photos_Products_Relation_Enabled",
    Photos_Edit_ExpireData_Enabled: "Photos_Edit_ExpireData_Enabled",
    Photos_Type_Enabled:"Photos_Type_Enabled",
    Photos_Download_Enabled:"Photos_Download_Enabled",
    Photos_Convert_Enabled:"Photos_Convert_Enabled",
    Products_Customer_Enabled: "Products_Customer_Enabled",
    Offers_Search_Pages_Enabled: "Offers_Search_Pages_Enabled",
    Offers_Rankings_Enabled: "Offers_Rankings_Enabled",
    System_Maintenance_Message: "System_Maintenance_Message",
    Indesign_Enabled: "Indesign_Enabled",
    Publications_Monstringsark_Enabled: "Publications_Monstringsark_Enabled",
    Publications_Corrections_Enabled: "Publications_Corrections_Enabled",
    Publications_Digital_Enabled: "Publications_Digital_Enabled",
    Photos_Create_Enabled: "Photos_Create_Enabled",
    Photos_Exclusivity_Enabled: "Photos_Exclusivity_Enabled",
}

var internalGetFeature = function(featureName) {
    return store.getState()
      .utilsReducer.features
      .find(f =>f.name == featureName);
}



export var getFeatureOrDefault = function (featureName, defaultValue) {

  var feature = internalGetFeature(featureName);

  if(feature === undefined || feature === null)
      return defaultValue;
  return feature.value;
}
export var getFeature = function (featureName) {
  return internalGetFeature(featureName).value;
}
export var IsFeatureEnabled = function(featureName){
  return getFeatureOrDefault(featureName, 'false') == 'true';
}