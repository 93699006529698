import React, { Component } from "react";
import Card from "@material-ui/core/Card";
import CardMedia from "@material-ui/core/CardMedia";

import { Button, CardActions, CardContent, Typography, Tooltip } from "@material-ui/core";
import {
  apiGetParamsReplace,
  endpoints,
} from "../../../constants/services.constants";
import { getMotivId, getShortDate, showErrorNotification } from "../../../helpers/UtilHelper";
import { orange } from "@material-ui/core/colors";
import { allowUsingExclusivePhoto } from "../SharedFunctions";
import ExclusiveImageIdentification from "./ExclusiveImageIdentification";
import { photoStatusEnum } from "../../../constants/enums";

export default class TabSearchOfferItem extends Component {
  constructor(props) {
    super(props);

  }

  handleCardClick = () => {
    const { photoData, isLoading, handleAddOfferPicture } = this.props;
    if (isLoading) {
      return;
    }
    if (photoData.futurePhotoDate) {
      showErrorNotification("Dette foto er ikke aktivt endnu");
      return;
    } else {
      handleAddOfferPicture(photoData.photoId)
    }
  }

  render() {
    const { classes, photoData, customerId } = this.props;
    const { exclusiveForCustomerIds } = photoData;
    const buttonDisabled = !allowUsingExclusivePhoto(photoData.exclusiveForCustomerIds, customerId);
    return (
      <>
        {exclusiveForCustomerIds && exclusiveForCustomerIds.length > 0 ? (
          <ExclusiveImageIdentification
            exclusiveForCustomerIdsArray={exclusiveForCustomerIds}
          />
        ) : undefined}
        <Card className={classes.card} key={photoData.photoId}>
            <CardMedia
              component="img"
            className={classes.media}
              image={apiGetParamsReplace(endpoints.PHOTO_SINGLE_200, [photoData.photoId])}
            />
          <CardContent style={{ height: 180, textAlign: "center" }}>
              <Typography
                variant="subtitle2"
                gutterBottom
                className="max3lines"
                style={{
                  fontSize: "0.875rem",
                  fontWeight: "bold",
                }}
              >
                {photoData.productName}
              </Typography>

              {photoData.futurePhotoDate ?
                <div style={{ color: orange[500] }}>
                  Gyldig fra {getShortDate(photoData.futurePhotoDate)}
                </div>
                : undefined
              }
              <Typography
                variant="caption"
                style={{ fontSize: "0.575rem", textAlign: "left" }}
              >
                <p style={{ margin: "0" }}>
                  <strong>Produktnummer:</strong>
                </p>
                <p style={{ margin: "0" }}>{photoData.productNumber}</p>
              </Typography>
              <Tooltip title={"Oprettet: "+getShortDate(photoData.photoCreated)}>
                <Typography
                  variant="caption"
                  style={{ fontSize: "0.575rem", textAlign: "left" }}
                >
                  <p style={{ margin: "0" }}>
                    <strong>Motiv:</strong>
                  </p>
                  <p style={{ margin: "0" }}>{getMotivId(photoData.photoId)}</p>
                </Typography>
              </Tooltip>

              {(this.props.photoData.validTo && this.props.photoData.status === photoStatusEnum.Active.key) ? 
              (<Typography
              variant="caption"
              style={{ fontSize: "0.575rem", textAlign: "left" }}
            >
              <p style={{ margin: "0" }}>
                <strong  style={{ color: orange[500] }}>Slettes:</strong>
              </p>
              <p style={{ margin: "0", color: orange[500] }}>{getShortDate(photoData.validTo)}</p>
            
            </Typography>)
              : undefined}
             
            </CardContent>
          <CardActions
            style={{
              display: "inherit",
              textAlign: "center",
              paddingTop: "0px",
              paddingBottom: "0px"
            }}
          >
            <Button
              color="primary"
              variant="contained"
              onClick={() => this.handleCardClick()}
              disabled={this.props.isLoading || buttonDisabled}
            >
              Vælg billede
            </Button>
          </CardActions>
        </Card>
      </>
    );
  }
}
