import React from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import EditIcon from "@material-ui/icons/Edit";
import DownloadIcon from "@material-ui/icons/CloudDownload";
import RefreshIcon from "@material-ui/icons/Refresh";
import DeleteIcon from "@material-ui/icons/Delete";
import { connect } from "react-redux";
import IconButton from "@material-ui/core/IconButton";
import { Tooltip } from "@material-ui/core";
import { deleteLogo, downloadLogo, refreshLogoPreview } from "./Requests";
import { openDialogConfirmDelete } from "../../shared/dialogs/DialogConfirm";
import { showSuccessNotification } from "../../../helpers/UtilHelper";
import { openDialogEditLogo } from "./dialogs/DialogEditLogo";


function ActionButtons(props) {
  const { logo, canDelete, onDataChanged } = props;

  const handleDeleteClicked = async () => {
    const confirmed = await openDialogConfirmDelete("Slet logo", `Er du sikker på at du vil slette ${logo.name}`);
    if (confirmed) {
      deleteLogo(logo.id);
      showSuccessNotification(`${logo.name} er blevet slettet`);
      onDataChanged();
    }
  }

  const handleEditClicked = async () => {
    const confirmed = await openDialogEditLogo(logo.id);
    if (confirmed) {
      // onDataChanged();
    }
  }

  const handleRefreshClicked = async (logoId) => {
    try {
      await refreshLogoPreview(logoId);
      showSuccessNotification("Previewet er blevet opdateret")
    } catch(e) { }
  }

  return (
    <div style={{ width: 128 }}>
      {/*EDIT BUTTON */}
      {canDelete ? (
        <Tooltip title="Redigér">
          <IconButton
            className="float-left smaller_icon"
            color="primary"
            onClick={() => handleEditClicked()}
          >
            <EditIcon>edit_icon</EditIcon>
          </IconButton>
        </Tooltip>
      ) : null}

      {/*DOWNLOAD BUTTON */}
      <Tooltip title="Hent">
        <IconButton
          className="float-left smaller_icon"
          color="primary"
          onClick={() => downloadLogo(logo.id)}
        >
          <DownloadIcon />
        </IconButton>
      </Tooltip>

      {/*DOWNLOAD BUTTON */}
      <Tooltip title="Opdater preview">
        <IconButton
          className="float-left smaller_icon"
          color="primary"
          onClick={() => handleRefreshClicked(logo.id)}
        >
          <RefreshIcon />
        </IconButton>
      </Tooltip>

      {/* Will want to display this menu item if we have the appropriate permissions. */}
      {canDelete ? (
        <Tooltip title="slet">
          <IconButton
            onClick={() => handleDeleteClicked()}
            className="float-left smaller_icon"
            color="primary">
            <DeleteIcon />
          </IconButton>
        </Tooltip>
      ) : null}
    </div>
  );
}

const styles = theme => ({
  button: {
  },
  extendedIcon: {
    marginRight: theme.spacing(1)
  }
});
ActionButtons.propTypes = {
  classes: PropTypes.object.isRequired
};


export default connect(state => state)(withStyles(styles)(ActionButtons));
