import React from "react";
import "react-alice-carousel/lib/alice-carousel.css";
import {
    withStyles,
    LinearProgress,
    Typography,
} from "@material-ui/core";
import Paper from "@material-ui/core/Paper";
import TabRelatedSliderItem from "./TabRelatedSliderItem";
import PagesSlider from "../../sections/fotohaandtering/PagesSlider";
import SelectPhotoTypes from "./SelectPhotoType";
import { photoTypeEnum } from "../../../constants/enums";

const styles = theme => ({
    card: {
        width: 150,
        maxWidt: 150,
        minWidth: 150,
        marginLeft: 15,
        marginRight: 15,
        boxShadow: "none"
    },
    media: {
        objectFit: " contain",
        backgroundSize: " contain",
        height: "150px !important",
        width: "150px"
    },
    searchHeight: {
        height: 265
    },
    selectHeight: {
        height: 52,
        width: "100%",
        marginTop: 20
    },
    cardList: {
        maxHeight: 280,
        overflow: "auto"
    }
});

class TabRelatedSlider extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            selectState: !this.props.onlyPack && this.props.startEnvironmental === true ? photoTypeEnum.Environment.id : photoTypeEnum.Pack.id
        };
    }


    handleSwitchPhotoType(value) {
        this.setState({
            selectState: value
        });
    }
    getItems(data) {

        if (data === null) {
            return [];
        }
        let items = data.map(img => {
            if ((this.state.selectState === photoTypeEnum["Pack"].id && img.isPack) || (this.state.selectState === photoTypeEnum["Environment"].id && img.isEnvironment)) {
                return (
                    <>
                        <TabRelatedSliderItem
                            classes={this.props.classes}
                            handleAddOfferPicture={this.props.handleAddOfferPicture}
                            selectedImage={this.props.selectedPhotos.filter(p=>p.photoId == img.photoId)[0]}
                            img={img}
                            isLoading={this.props.isLoading}
                            existingOptions={this.props.existingOptions}
                            customerId={this.props.customerId}
                        />
                    </>
                )
            } else {
                return undefined;
            }
        }).filter(elem => elem !== undefined);


        return items;

    }

    responsive = {
        0: { items: 2 },
        600: {
            items: 3
        },
        950: {
            items: 4
        },
        1300: {
            items: 4
        },
        1620: {
            items: 4
        },
        2400: {
            items: 4
        }
    };

    stagePadding = {
        paddingLeft: 20, // in pixels
        paddingRight: 20
    };

    render() {
        const { classes, isLoading, relatedImages, selectedPhotos } = this.props;

        classes.carouselPadding = ' carousel-padding-top ';

        return (
            <>
                {isLoading ? (
                    <div className={classes.searchHeight}>
                        <LinearProgress />
                    </div>
                ) :
                    !relatedImages ? undefined
                        : relatedImages.length === 0 ? (
                            <>
                                <br />
                                <Typography className={classes.searchHeight} variant="h6" align="center">
                                    Ingen billeder fundet...
                                </Typography>
                            </>
                        )
                            : !this.props.onlyPack ? (
                                <>
                                    <div className={classes.selectHeight}
                                        style={{
                                            height: 52,
                                            width: "100%",
                                            marginTop: 20
                                        }}>
                                        <SelectPhotoTypes
                                            selection={this.state.selectState}
                                            handleSelectPhotoType={(photoType) => this.handleSwitchPhotoType(photoType)}
                                        />
                                    </div>
                                    <Paper>
                                        <PagesSlider
                                            items={this.getItems(this.props.relatedImages)}
                                            selectedPages={[1]}
                                            productSlider
                                        />
                                    </Paper>
                                </>)

                                :
                                (<Paper>
                                    <PagesSlider
                                        items={this.getItems(this.props.relatedImages)}
                                        selectedPages={[1]}
                                        productSlider
                                    />
                                </Paper>)

                }
            </>
        );
    }
}
export default withStyles(styles)(TabRelatedSlider);
