import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import {
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  FormHelperText,
} from "@material-ui/core";

import {
  endpoints,
} from "../../../../constants/services.constants";
import { fetchGetWT, fetchPostFormDataWT } from "../../../../helpers/HttpHelper";
import { handleAPIErrorNotification } from "../../../../redux/Handlers/ErrorHandlers";

import Grid from "@material-ui/core/Grid";
import { Button } from "@material-ui/core";
import { LoadingLogo } from "../../../shared/Loading";
import CloudDownloadIcon from "@material-ui/icons/CloudDownload";
import { handleApiSuccessNotificaiton } from "../../../../redux/Handlers/SuccessHandlers";
import { photoTypeEnum } from "../../../../constants/enums";
import GS1Tab from "../../../shared/GS1Tab";

const GsPhotoTab = (props) => {
  const [customers, setCustomers] = useState([]);
  const [photoTypeId, setphotoTypeId] = useState(null);

  const [multiCustomerId, setmultiCustomerId] = useState(null);
  const [loading, setloading] = useState(false);
  const [gs1Message, setGs1Message] = useState("");
  const [productNumber, setProductNumber] = useState(null);

  const [loadingGS1Images, setloadingGS1Images] = useState("");

  useEffect(() => {
    async function fetchData() {
      await getCustomers();
      setloadingGS1Images(props.productsReducer.loadingGS1Images);
    }
    fetchData();
  }, []);

  const getCustomers = async () => {
    const endpoint = endpoints.CUSTOMER_ALL_ALLOWED;
    setloading(true);
    await fetchGetWT(
      endpoint,
      (response) => {
        if (response != null) {
          setCustomers(response);
          // this.props.productsReducer.allowedCustomers = response;
          setloading(false);
        } else {
          setloading(false);
          throw Error("Null response");
        }
      },
      (error) => {
        setloading(false);
        handleAPIErrorNotification(endpoint, error);
      }
    );
  };

  const getGS1Images = () => {
    setProductNumber(props.productsReducer.product.productNumber);
  };

  const addGs1ImagesToProduct = async (url) => {
    if (!multiCustomerId || !photoTypeId) return;
    setloading(true);

    const formData = new FormData();
    formData.append("FileUrl", url);
    formData.append("customerId", multiCustomerId);
    formData.append("Type", photoTypeId);
    formData.append("RelatedProductIds[0]", props.productsReducer.product.id);

    const endpoint = endpoints.PHOTO_UPLOAD;
    await fetchPostFormDataWT(
      endpoint,
      formData,
      (json) => {
        setloading(false);
        handleApiSuccessNotificaiton("Foto tilføjet til produkt");
        //this.setState({ loading: false });
      },
      (error) => {
        setloading(false);
        console.log(error);
        //    this.setState({ loading: false });
        throw Error("Failed to create new filter");
      }
    );
  };

  return (
    <>
      {loading ? (
        <LoadingLogo />
      ) : (
        <>
          <FormControl style={{ marginBottom: 20, marginTop: 20 }} fullWidth>
            <InputLabel htmlFor="name">Kunde</InputLabel>
            <Select
              fullWidth
              id="selectorProductEditCustomer"
              value={multiCustomerId ? multiCustomerId : ""}
              onChange={(e) => setmultiCustomerId(e.target.value)}
              inputProps={{
                name: "name",
                id: "id",
              }}
            >
              {customers.map((item) => (
                <MenuItem key={item.id} value={item.id} className="selectorProductEditCustomerItems">
                  {item.name}
                </MenuItem>
              ))}
            </Select>
            {!multiCustomerId && (
              <FormHelperText style={{ color: "#e61c1c" }}>
                Skal være udfyldt
              </FormHelperText>
            )}
          </FormControl>

            <FormControl style={{ marginBottom: 20 }} fullWidth>
            <InputLabel htmlFor="multiPhotoTypeId-id">Foto type</InputLabel>
            <Select
              fullWidth
              id="selectorProductEditPhotoType"
              value={photoTypeId ? photoTypeId : ""}
              onChange={(e) => setphotoTypeId(e.target.value)}
              inputProps={{
                name: "multiPhotoTypeId",
                id: "multiPhotoTypeId-id",
              }}
            >
              <MenuItem value={photoTypeEnum.Pack.id} className="selectorProductEditPhotoType">
                {photoTypeEnum.Pack.name}
              </MenuItem>
              <MenuItem value={photoTypeEnum.Environment.id}>
                {photoTypeEnum.Environment.name}
              </MenuItem>
            </Select>
            {!photoTypeId && (
              <FormHelperText style={{ color: "#e61c1c" }}>
                Skal være udfyldt
              </FormHelperText>
            )}
          </FormControl>

          <Grid>
            {productNumber !== null ? (
              <GS1Tab
                productNumner={productNumber}
                addGs1ImagesToProduct={addGs1ImagesToProduct}
                title="Tilføj"
                  isEdit={true}
              />
            ) : (
              ""
            )}
          </Grid>

          <Grid container style={{ justifyContent: "center" }}>
            <Button
              variant="contained"
              color="default"
              style={{ marginTop: 25 }}
              onClick={getGS1Images}
            >
              <CloudDownloadIcon style={{ marginRight: 5 }} />
              Hent GS1 Billede
            </Button>
          </Grid>
          {loadingGS1Images ? <LoadingLogo /> : ""}
          {!loadingGS1Images && gs1Message !== "" ? gs1Message : ""}
        </>
      )}
    </>
  );
};
export default connect((state) => state)(GsPhotoTab);
