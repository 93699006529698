import React, { useEffect, useState } from "react";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import LayoutCarousel from "./../Carousel";
import { LoadingLogo } from "../../../shared/Loading";
import createDialog from "../../../../DialogRenderer";
import { fetchPhotoOrderV2 } from "../SharedRequests";

export async function openDialogReference(photoOrderId) {
  return createDialog(({ show, onSubmit, onDismiss }) => (
    <DialogReference photoOrderId={photoOrderId}
      open={show}
      onCancel={onDismiss}
      onConfirm={onSubmit}
    />
  ));
}

function DialogReference(props) {
  const [loading, setLoading] = useState(true);
  const [referenceFiles, setReferenceFiles] = useState([]);

  useEffect(() => {
    init();
  }, []);

  const init = async () => {
    try {
      const photoOrder = await fetchPhotoOrderV2(props.photoOrderId);
      setReferenceFiles(photoOrder.referenceFiles);
    } finally {
      setLoading(false);
    }
  }

  return (
    <>
      <Dialog
        open={props.open}
        onClose={() => props.onCancel()}
        aria-labelledby="form-dialog-title"
        fullWidth={true}
        maxWidth="md"
        disableBackdropClick={false}
      >
        <DialogTitle id="alert-dialog-title">Reference files</DialogTitle>
        <DialogContent className="customDialogContent">
          {loading ? <LoadingLogo /> :
            <LayoutCarousel
              referenceFiles={referenceFiles}>
            </LayoutCarousel>}
        </DialogContent>
        
        <DialogActions>
          <Button onClick={() => props.onCancel()} autoFocus>
            Luk
          </Button>
        </DialogActions>
      </Dialog>
    </>
  )
}
