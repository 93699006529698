import React from 'react';
import { useState } from "react";



function ImageWithPlaceholder(props) {
    const { imageUrl, placeholder, width, height } = props;

    const [src, setSrc] = useState(imageUrl);

    const onError = () => {
        setSrc(placeholder);
    }

    return <img
        src={src}
        onError={onError}
        className="hoverClickPointer"
        style={{ width: width, height: height, objectFit: "contain" }}
    />
}

export default (ImageWithPlaceholder);