import React, { useEffect, useState } from "react";
import store from "../../../../redux/store";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import { LoadingLogo } from "../../../shared/Loading";
import { withStyles } from "@material-ui/core/styles";
import {
  editDialogClose
} from "../../../../redux/actions/offer/edit.offers.actions";
import InformationTab from "../TabContainers/InformationTab";
import { customers } from "../../../../constants/enums";
import { unwrapOfferToState } from "./businessLogic/offerState";
import {
  handleChangeOfferScores,
  handleChangeState, handleClose,
  handleDateChangeFrom, handleDateChangeTo,
  handleOfferFieldValueItemsChange as handleFieldValuesChange,
  handleUpdateInputTag
} from "./businessLogic/eventHandlers";
import { DialogHeader } from "./components/DialogHeader";
import { getOffer, getOfferRankings, getOfferSegments, updateOffer, updateScores } from "../Requests";
import { isAO, toJsonLocal } from "../../../../helpers/UtilHelper";
import { getOffersTags } from "../../../shared/Requests";


const styles = theme => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper
  },
  tabsRoot: {
    borderBottom: "1px solid #e8e8e8"
  },
  tabsIndicator: {
    backgroundColor: "#1890ff"
  },
  dialog: {
    minHeight: "760px",
    maxHeight: "760px"
  },
  typography: {
    padding: theme.spacing(3)
  }
});

function DialogEditOffer(props) {
  const [state, setState] = useState({
    description: "",
    selectedImage: undefined,
    validFrom: undefined,
    validTo: undefined,
    normalPrice: 0,
    price: 0,
    offerDefinitionId: "",
    tags: [],
    fieldValues: [],
    layout: undefined,
    offerRankings: [],

    // readonly fields
    customer: undefined,
    offerPriceField: "",
    offerPublicationName: undefined,
    offerSegments: [],
    canEditPrices: false
  });

  const [loading, setLoading] = useState(false);
  const [offerRankingOriginal, setOfferRankingOriginal] = useState({});

  useEffect(() => {
    setLoading(true);
    const runEffect = async () => {
      const offer = getOffer(props.offerId);
      const offerSegments = getOfferSegments(props.offerId);
      const offerRankings = getOfferRankings(props.offerId);
      const [offerResponse, offerSegmentsResponse, offerRankingsResponse] = await Promise.all([offer, offerSegments, offerRankings]);

      var originalRankings = Object.assign({}, offerRankingsResponse);
      setOfferRankingOriginal(originalRankings);

      await unwrapOfferToState(offerResponse, props.publicationName, setState, state, store, offerSegmentsResponse, offerRankingsResponse);
      setLoading(false);
    };

    runEffect();
  }, []);

  const handleSave = async (state, props, store, offerRankingOriginal) => {

    if(state.offerRankings !== null && offerRankingOriginal.restScore != state.offerRankings.restScore){
      await updateScores(state.offerId, state.offerRankings);
    }


    const dto = {
      description: state.description,
      validFrom: state.validFrom,
      validTo: state.validTo,
      normalPrice: state.normalPrice,
      price: state.price,
      definition: state.offerDefinitionId === 0 ? null : state.offerDefinitionId,
      tags: state.tags,
      fieldValues: state.fieldValues.map(x => {
        return {
          name: x.name,
          value: x.value
        }
      })
    };

    // we need to fix dates since they otherwise will be off by 1 hour
    if (isAO(dto.validFrom)) {
      dto.validFrom = toJsonLocal(dto.validFrom);
    }
    if (isAO(dto.validTo)) {
      dto.validTo = toJsonLocal(dto.validTo);
    }

    await updateOffer(state.offerId, dto);
     loadOffersTags();
    handleClose(store, props, editDialogClose);
  };

  const loadOffersTags = async () => {
    const tags = await getOffersTags();
    if(props.offersTagsReload !== undefined){
      props.offersTagsReload(tags);

    }
  }

  return (
    <div>
      <Dialog
        open={true}
        onClose={() => handleClose(store, props, editDialogClose)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        fullWidth={true}
        maxWidth="md"
        disableBackdropClick={true}
        className={"republica-dialog"}
      >
        <DialogTitle>
          <DialogHeader
            description={state.description}
            code={"T" + props.offerId}
            selectedImage={state.selectedImage} />
        </DialogTitle>
        <DialogContent className="customDialogContent">
          {loading ? <LoadingLogo /> :
            (<>
              <InformationTab
                loading={state.loading}
                description={state.description}
                validFrom={state.validFrom}
                validTo={state.validTo}
                offerPriceField={state.offerPriceField}
                normalPrice={state.normalPrice}
                price={state.price}
                canEditPrices={state.canEditPrices}
                customer={customers[state.customer] || ""}
                tags={state.tags} // tags attached to this offer
                offerDefinitionId={state.offerDefinitionId || ""}
                offerPublicationName={props.publicationName}
                handleUpdateInputTag={(e) => handleUpdateInputTag(state, setState, e)}
                setTags={(newTags) => setState({ ...state, tags: newTags })}
                onChange={(e) => handleChangeState(state, setState, e)}
                fieldValues={state.fieldValues}
                onFieldValuesChange={(e) => handleFieldValuesChange(state, setState, e)}
                offerSegments={state.offerSegments}
                offerRankings={state.offerRankings}
                handleDateChangeFrom={(date) => handleDateChangeFrom(state, setState, date)}
                handleDateChangeTo={(date) => handleDateChangeTo(state, setState, date)}
                handleChangeOfferScores={(target) => handleChangeOfferScores(state, setState, target)}
                offersTags={props.offersTags} // all the tags for offers
              />
            </>
            )}
        </DialogContent>
        <DialogActions>
          <Button onClick={() => handleClose(store, props, editDialogClose)}>
            Annuller
          </Button >
          <Button
            onClick={() => handleSave(state, props, store, offerRankingOriginal)}
            variant="contained"
            color="primary"
            name="buttonOfferEditSave"
            disabled={loading}
            autoFocus>
            {loading ? "Opdaterer" : "Gem"}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

export default (withStyles(styles)(DialogEditOffer));