export const ADD_TOASTS_MESSAGE = "ADD_TOASTS_MESSAGE";
// export const FETCH_PUBLICATIONS_FAILURE = 'FETCH_PUBLICATIONS_FAILURE';

export const addToastMessage = message => {
  return dispatch => {
    dispatch({
      type: ADD_TOASTS_MESSAGE,
      payload: {
        message
      }
    });
  };
};
