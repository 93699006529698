import { endpoints } from '../../constants/services.constants';
import { fetchGetWT } from '../../helpers/HttpHelper';

export const FETCH_CREDENTIALS_BEGIN = 'CREDENTIALS_FETCH_CREDENTIALS_BEGIN';
export const FETCH_CREDENTIALS_SUCCESS = 'CREDENTIALS_FETCH_CREDENTIALS_SUCCESS';
export const FETCH_CREDENTIALS_FAILURE = 'CREDENTIALS_FETCH_CREDENTIALS_FAILURE';

export const fetchCredentialsBegin = () => ({
  type: FETCH_CREDENTIALS_BEGIN
});

export const fetchCredentialsSuccess = (credentials) => ({
  type: FETCH_CREDENTIALS_SUCCESS,
  payload: { credentials }
});

export const fetchCredentialsFailure = error => ({
  type: FETCH_CREDENTIALS_FAILURE,
  payload: { error }
});


export function fetchCredentials(callback,callbackError,sendError) {
  return dispatch => {
    const endpoint = endpoints.IDENTTITY_CREDENTIALS_ME;
    dispatch(fetchCredentialsBegin());
    return fetchGetWT(endpoint,
      (json) => {
        dispatch(fetchCredentialsSuccess(json));
        callback();
      },
      (error) => {
        dispatch(fetchCredentialsFailure(error.toString()));
        if(callbackError!=null)callbackError(error.toString());
      },sendError
    )
  };
}