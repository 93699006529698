import React from "react";
import {
  Typography,
  Button,
  Grid,
  Fab,
  Menu,
  MenuItem,
} from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import PagesSlider from "./PagesSlider";
import { connect } from "react-redux";
import { cleanFotoHaandtering, setPublication, setSelectedOffers } from "../../../redux/actions/fotohaandtering/fotohaandtering.actions";
import { LoadingLogo } from "../../shared/Loading";

import { canReadApplication, canReadApplicationForCustomer, canDeleteApplicationForCustomer } from "../../../helpers/CredentialsHelper";
import { applications } from "../../../constants/app.constants";
import history from "../../../routes/history";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import { addToastMessage } from "../../../redux/actions/toasts.actions";
import { isEqual, showErrorNotification } from "../../../helpers/UtilHelper";
import { debounce } from "../../../helpers/UtilHelper"
import ArrowBackIosRoundedIcon from '@material-ui/icons/ArrowBackIosRounded';
import ArrowForwardIosRoundedIcon from '@material-ui/icons/ArrowForwardIosRounded';
import { getPhotos, getOffer, getPublicationByJobId, checkExcistingPdfs, UpdatePageContentDescription, getDeactivatedPhotos } from "./Requests";
import store from "../../../redux/store";
import { openDialogIndesign } from "./dialogs/dialogIndesign/DialogIndesign";
import { openDialogCopyOffer } from "./dialogs/DialogCopyOffer";
import OffersContent from "./offercards/OffersContent";
import { openAddPhotoOrderDialog } from "../../../redux/actions/fotohaandtering/photoorder.fotohaandtering.actions";
import { getProductNumbersFromOffer } from "./offercards/SharedFunctions";
import { sendToNewIndesign } from "../publikationstyring/Requests";
import { Helmet } from "react-helmet";
import getProductCategories from "../produkstyring/Requests";
import { fetchCustomers } from "../../../redux/actions/utils/customers.utils.actions";
import { editDialogOpen, getOfferWithCallback } from "../../../redux/actions/offer/edit.offers.actions";
import { getOffers, } from "../tilbudsstyring/Requests";
import DialogEditOffer from "../tilbudsstyring/Dialogs/DialogEditOffer";
import { getOffersTags } from "../../shared/Requests";
import GoBackButton from "../../shared/components/GoBackButton";
import DialogPreviewPublication from "../publikationstyring/dialogs/DialogPreviewPublication/DialogPreviewPublication";
import DialogAddPhotoOrderV2 from "../fototracker/Dialogs/DialogAddPhotoOrder/DialogAddPhotoOrderV2";
import { photoOrderOrigin, TemplateTypes } from "../../../constants/enums";
import { IsFeatureEnabled, featureNames } from "../../../helpers/FeaturesHelper";

const styles = (theme) => ({
  root: {
    flexGrow: 1,
    maxWidth: "500px",
    [theme.breakpoints.up("sm")]: {
      maxWidth: "600px",
    },
    [theme.breakpoints.up("md")]: {
      maxWidth: "900px",
    },
    [theme.breakpoints.up("lg")]: {
      maxWidth: "1300px",
    },
    [theme.breakpoints.up("xl")]: {
      maxWidth: "1800px",
    },
  },
  wrapper: {
    display: "grid",
    gridTemplateColumns: "85% auto",
  },
  paper: {
    textAlign: "center",
    padding: "10px",
    marginBottom: "20px",
  },
  fab: {
    color: "#fff",
    backgroundColor: "#263238",
    position: "fixed",
    top: 220,
    marginLeft: 23,
    "&:hover": {
      backgroundColor: "#11171A",
      color: "#ffffff",
    },
  },
  pageNumber: {
    top: 267,
    position: "fixed",
    width: 56,
    height: 56,
    marginLeft: 24,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    fontWeight: 700,
    fontSize: 16
  },
  arrows: {
    top: 300,
    position: "fixed",
    width: 56,
    height: 56,
    marginLeft: 24,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    cursor: "pointer"
  },
  disableGoBack: {
    color: "#838485"
  },
  disableGoForward: {
    color: "#838485"
  }
});
const DefaultNoPageContentText = "[Ingen Titel]";
class FotoHaandtering extends React.Component {

  constructor(props) {
    super(props);
    this.copyOffer = React.createRef();
    this.offerPageContainers = [];
    this.fabButton = React.createRef()
    this.state = {
      isLoading: true,
      error: null,
      allOffersForPublication: [],
      pagesWithOffers: [],
      disableGoBack: false,
      disableGoForward: false,
      selectedPages: [],
      spreads: [],
      anchorEl: null,
      photoOrderCategories: [],
      offersTags: [],
      publicationPreviewDialog: false,
      existingPdfs: [], // pages that have pregenerated pdfs
      deactivatedPhotos: [],
    };
  }

  async componentDidMount() {
    if (canReadApplication(applications.FOTOHAANDTERING)) {
      this.init();
      store.dispatch(fetchCustomers());
    }

    window.addEventListener('scroll', this.handleScroll, false);

  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.handleScroll);
    this.props.dispatch(cleanFotoHaandtering());
  }


  async init() {
    this.setState({ isLoading: true });

    try {
      // load all data in parallel
      const jobId = this.props.match.params.jobId;
      const publication = await getPublicationByJobId(jobId, false);
      const offerIds = publication.pages.map(x => x.offerIds).reduce((a, b) => a.concat(b));
      const offersPromise = getOffers(offerIds, true, true, true, false);
      const categoriesPromise = getProductCategories();
      const deactivatedPhotosPromise = getDeactivatedPhotos(publication.id);
      store.dispatch(setPublication(publication));


      if (publication.pages.length === 0)
        return;

      // get spreads (a list of all the page pairs)
      let spreads = null;
      if (publication.pages.length === 1) {
        spreads = [[1, null]];
      } else {
        spreads = [[publication.pages.length, 1]];
        for (let i = 2; i < publication.pages.length - 1; i += 2) {
          spreads.push([i, i + 1]);
        }
      }

      // find intial spread
      const pageNumber = this.getPageNumberFromUrl();
      const initialSpread = spreads.find(x => x.includes(pageNumber)) ?? spreads[0];

      const categories = await categoriesPromise;
      const allOffers = await offersPromise;

      const deactivatedPhotos = await deactivatedPhotosPromise;
      const _existingPdfs = await checkExcistingPdfs(publication.id);

      await this.addPhotosToData(allOffers);

      this.setState({
        allOffersForPublication: allOffers,
        photoOrderCategories: categories,
        spreads: spreads,
        existingPdfs: _existingPdfs,
        deactivatedPhotos: deactivatedPhotos
      }, () => {
        this.changePages(...initialSpread);
      });
    } catch (e) {
      this.setState({ error: true });
    } finally {
      this.setState({ isLoading: false });
    }
  }

  getPageNumberFromUrl() {
    const urlSegments = window.location.pathname.split('/'); // pathname = /publikation/202150/side/6
    const index = urlSegments.indexOf("side") + 1;
    return index > 0 ? parseInt(urlSegments[index]) : 1;
  }

  async addPhotosToData(offers) {
    // we need to add photo data to get photo clipping status for each photo
    const allPhotoIds = [];
    for (const offer of offers) {

      
      // add product photos
      if (offer.products.length > 0) {
        for (const product of offer.products) {
          const offerProductPhotoIds = this.sortArray(product.photos, product.selectedPhotoId);
          allPhotoIds.push(...offerProductPhotoIds);
        }
      } else {
        // add product photos of nested offer types, fx sammix manus, mixmatch
        for (const childOffer of offer.childOffers) {
          for (const product of childOffer.products) {
            const offerProductPhotoIds = this.sortArray(product.photos, product.selectedPhotoId);
            allPhotoIds.push(...offerProductPhotoIds);
          }
        }

      }
    }
    const photos = await getPhotos(allPhotoIds);

    for (const offer of offers) {
      
      // add the photos to the offer product
      for (const product of offer.products) {
        const offerProductPhotoIds = this.sortArray(product.photos, product.selectedPhotoId);
        product.photoInfos = photos.filter(x => offerProductPhotoIds.includes(x.id));
        
      }
      //add product photos of nested offer types, fx sammix manus, mixmatch
      for (const childOffer of offer.childOffers) {
        for (const product of childOffer.products) {
          const offerProductPhotoIds = this.sortArray(product.photos, product.selectedPhotoId);
          product.photoInfos = photos.filter(x => offerProductPhotoIds.includes(x.id));
        }
      }
    }
  }

  sortArray(array, selectedPhotoId) {
    return array
      .filter(x => x.isVariant || x.photoId === selectedPhotoId)
      .map(x => x.photoId);
  }

 

  changePages(pageNumber1, pageNumber2) {
    // update url
    const jobId = this.props.match.params.jobId;
    history.push(`/publikation/${jobId}/side/${pageNumber1}`);

    const publication = this.props.fotohaandteringReducer.publication;
    // group offers per page
    const pagesWithOffers = [];
    for (const pageNumber of [pageNumber1, pageNumber2]) {
      const page = publication.pages.find(x => x.pageNumber === pageNumber);
      // fix for half imported publications in dev
      if (!page)
        continue;

      const offersOnPageMap = new Set([...page.offerIds]);
      const offers = this.state.allOffersForPublication.filter(x => offersOnPageMap.has(x.id));


      page.isContentFieldDisabled = true;
      page.contentDescription = this.displayPageContentValue(page.contentDescription)

      pagesWithOffers.push({
        page: page,
        offers: offers,
      });

    }
    this.setState({
      pagesWithOffers: pagesWithOffers,
      disableGoBack: pageNumber1 === publication.pages.length,
      disableGoForward: pageNumber2 === publication.pages.length - 1 || !pageNumber2,
      selectedPages: [pageNumber1, pageNumber2]
    }, () => {
      this.handleScroll();
      store.dispatch(setSelectedOffers([]));
    });
  }

  displayPageContentValue(contentDescription){
    if(contentDescription === "" || contentDescription === null || contentDescription === undefined){
      return DefaultNoPageContentText;
    }
    return contentDescription;
  }
  reloadPages() {
    this.changePages(...this.state.selectedPages);
  }

  async handleOfferUpdated(offerId, deletedOffer) {
    if (deletedOffer) {
      // remove the offer from the list
      this.setState({
        allOffersForPublication: this.state.allOffersForPublication.filter(x => x.id !== offerId)
      }, () => {
        this.reloadPages();
      });
    } else {
      // fetch new data for offer
      const offer = await getOffer(offerId);
      await this.addPhotosToData([offer]);
      const allOffers = [...this.state.allOffersForPublication];
      const index = this.state.allOffersForPublication.findIndex(x => x.id === offerId);
      allOffers[index] = offer;
      this.setState({ allOffersForPublication: allOffers }, () => {
        this.reloadPages();
      });
    }
  }

  handleOpenMenu = (event) => {
    this.setState({ anchorEl: event.currentTarget });
  };

  handleCloseMenu = () => {
    this.setState({ anchorEl: null });
  };

  handlePhotoOrderCreated = () => {
    const offerProductIds = this.props.fotohaandteringReducer.dialogAddPhotoOrderOfferProductIds;
    for (const offer of this.state.allOffersForPublication) {
      if (offer.products.some(x => offerProductIds.includes(x.id))) {
        this.handleOfferUpdated(offer.id, false);
      }
    }
  }

  // Open a dialog to make a photo order
  handleClickOpenMultipleOrder = () => {

    if (this.props.fotohaandteringReducer.selectedOfferIds.length === 0) {
      showErrorNotification("Vælg mindst ét tilbud for at oprette en fotoordre");
      this.handleCloseMenu();
      return;
    }
    const offers = this.state.allOffersForPublication.filter(x => this.props.fotohaandteringReducer.selectedOfferIds.includes(x.id));
    let offerProductIds = [];
    for (const offer of offers) {
      const productIds = getProductNumbersFromOffer(offer);
      offerProductIds = [...offerProductIds, ...productIds];
    }
    store.dispatch(openAddPhotoOrderDialog(offerProductIds));
    // this.handleCloseMenu();
  };

  handleCreatePhotoOrderOffer = (offer) => {
    const offerProductIds = getProductNumbersFromOffer(offer);
    store.dispatch(openAddPhotoOrderDialog(offerProductIds));
  }

  // Close a dialog to make a photo order
  handleCloseDialogMultipleOrder = () => {
    // reset selected offers
    // this.props.dispatch(clearOffers());
  };

  // Open dialog to send to Indesign
  handleClickOpenInDesignChoose = () => {
    this.handleCloseMenu();
    openDialogIndesign(
      this.state.spreads,
      this.props.fotohaandteringReducer.publication.jobId,
      this.props.fotohaandteringReducer.publication.multiCustomerId,
      TemplateTypes.Publication
    );
  };
  handleClickOpenInDesignPosterChoose = () => {
    this.handleCloseMenu();
    openDialogIndesign(
      this.state.spreads,
      this.props.fotohaandteringReducer.publication.jobId,
      this.props.fotohaandteringReducer.publication.multiCustomerId,
      TemplateTypes.Poster);
  };

  // Send to new indesign all the pages
  handleSendAllToIndesignGenerator = async () => {
    const pages = this.props.fotohaandteringReducer.publication.pages;

    const _pageInfos = pages.map(function(key,index){
      return {
        PageNumber : pages[index].pageNumber,
        TemplateName : "standard",
        Spread: pages[index].spreadNumber
      }
    });
    const body = {
      workbookId: this.props.fotohaandteringReducer.publication.jobId,
      pageInfos: _pageInfos
    };

    sendToNewIndesign(body);
    this.handleCloseMenu();
    this.props.dispatch(
      addToastMessage({
        message: "Publikation er blevet sendt til Indesign generator",
        type: "success"
      })
    );
  };


  handleClickCopyOffer = () => {
    this.handleCloseMenu();

    const selectedOfferIds = this.props.fotohaandteringReducer.selectedOfferIds;
    if (selectedOfferIds.length === 0) {
      showErrorNotification("Vælg venligst det tilbud som du ønsker at kopiere");
      return;
    }

    this.setState({ isOpen: true });

    openDialogCopyOffer(selectedOfferIds);
  };

  handleGoBack() {
    history.push("/publikationer");
  }

  handleScroll = debounce(() => {
    //const button = document.querySelector('#root > div > div > div > main > div:nth-child(2) > div > div > div.MuiGrid-root.MuiGrid-item.MuiGrid-grid-xs-11 > div > div:nth-child(2) > button');
    const button = this.fabButton.current;

    if (!Array.isArray(this.offerPageContainers) || this.offerPageContainers.length === 0) {
      return;
    }

    const element1 = this.offerPageContainers[0].current;
    const element2 = this.offerPageContainers[1].current;
    let closestElementToButton = undefined;
    let pageNumber = undefined;

    if (!element1 && !element2) {
      setTimeout(() => this.handleScroll(), 200);
      this.setState({ pageNumber: pageNumber });;
      return;
    }
    else if (!element1 || !element2) {
      closestElementToButton = !element1 ? element2 : element1;
    }
    else {
      closestElementToButton = button == null ? //can happen in readonly mode
        element1 : 
        (element1.getBoundingClientRect().bottom < button.getBoundingClientRect().y ? element2 : element1);
    }

    pageNumber = closestElementToButton.getAttribute("data-pagenumber");

    if (pageNumber === this.state.pageNumber) {
      return;
    }

    this.setState({ pageNumber });
  }, 150, false);

  changeSpreadsWithArrows = (direction) => {
    if ((this.state.disableGoBack && direction === "back") || (this.state.disableGoForward && direction === "forward"))
      return;

    const currentSpreadIndex = this.state.spreads.findIndex(x => isEqual(x, this.state.selectedPages));
    const nextSpread = direction === "forward" ? this.state.spreads[currentSpreadIndex + 1] : this.state.spreads[currentSpreadIndex - 1];
    this.changePages(nextSpread[0], nextSpread[1]);
  }

  handleEditOffer = id => {
    this.loadOffersTags();
    this.props.dispatch(editDialogOpen(id));
  };

  loadOffersTags = async () => {
    const tags = await getOffersTags();
    this.setState({ offersTags: tags });
  }

  openPreviewPublication = (index) => {
    this.setState({
      publicationPreviewDialog: true,
      selectedImageIndex: index
    });
  }

  closePreviewPublication = () => {
    this.setState({
      publicationPreviewDialog: false,
      selectedImageIndex: undefined
    });
  }

  setPageContentDescription = (index,value) => {
    this.updateContentDescription(index,value);
  }

  togglePageContentDisabled = (index) =>{
    const pagesWithOffers = [...this.state.pagesWithOffers];
    const pageWithOffers = {...pagesWithOffers[index]};

    pageWithOffers.page = {...pageWithOffers.page, isContentFieldDisabled: !pageWithOffers.page.isContentFieldDisabled}
    pagesWithOffers[index] = pageWithOffers;
    this.setState({pagesWithOffers : pagesWithOffers}, function(){

      let stateContentDescription = this.state.pagesWithOffers[index].page.contentDescription;
      //Setting and reseting the default value in the text box.
      if(stateContentDescription === DefaultNoPageContentText && !this.state.pagesWithOffers[index].page.isContentFieldDisabled){
        this.updateContentDescription(index, "");
      }
      else if((stateContentDescription === "" || stateContentDescription.trim().length === 0) && this.state.pagesWithOffers[index].page.isContentFieldDisabled){
        this.updateContentDescription(index, DefaultNoPageContentText);
      }
      else{
        this.updateContentDescription(index,stateContentDescription.trim());
      }
    });
  }

  savePageContentDescription = (index,page) => {
    if(page.contentDescription !== DefaultNoPageContentText){
      //Need to update the reducer.
      let publication = {...this.props.fotohaandteringReducer.publication};
      let reducerPage = publication.pages.find(x=> x.id === page.id);
      if(reducerPage){
        reducerPage.contentDescription = page.contentDescription.trim();
      }
      UpdatePageContentDescription(page.id, page.contentDescription.trim());
      store.dispatch(setPublication(publication));
    }

    this.togglePageContentDisabled(index);
  }


  updateContentDescription(index,value){

    const pagesWithOffers = [...this.state.pagesWithOffers];
    const pageWithOffers = {...pagesWithOffers[index]};

    pageWithOffers.page = {...pageWithOffers.page, contentDescription: value}
    pagesWithOffers[index] = pageWithOffers;
    this.setState({pagesWithOffers : pagesWithOffers});
  }

  updateDeactivatedPhotos(deactivatedPhotoDto,isDeactivated){
    var deactivatedPhotos = this.state.deactivatedPhotos;
    if(!isDeactivated){
      var filteredList = deactivatedPhotos.filter(x=> !(x.offerId ==  deactivatedPhotoDto.offerId && x.photoId == deactivatedPhotoDto.photoId));
      this.setState({deactivatedPhotos: filteredList});
     
    }
    else{
      deactivatedPhotos.push(deactivatedPhotoDto);
      this.setState({deactivatedPhotos: deactivatedPhotos});
    }
   
  }


  render() {
    const { classes, fotohaandteringReducer: reducer } = this.props;
    if(reducer.publication != null){
      if (!IsFeatureEnabled(featureNames.Publications_Enabled) || !canReadApplicationForCustomer(applications.FOTOHAANDTERING, reducer.publication.multiCustomerId)) {
        return (
          <Typography variant="subtitle1" align="center">
            Du har ikke adgang til denne side. Kontakt support
          (mbsupport@republica.dk), hvis dette er en fejl.
          </Typography>
        );
      }
    }

    const isReadOnlyMode = reducer.publication == null ? true : !canDeleteApplicationForCustomer(applications.FOTOHAANDTERING, reducer.publication.multiCustomerId)

    if (this.state.isLoading) {
      return <LoadingLogo />;
    }

    if (this.state.error) {
      return <h4>Der er sket en fejl. Systemet var ikke i stand til at hente data for denne publikation.</h4>;
    }
    return (
      <>
        <Helmet>
          <title>{`Fotohaandtering-${reducer.publication.jobId}`}</title>
        </Helmet>
        <Grid container>
          <Grid item xs={1}>
            <GoBackButton
              onClick={() => this.handleGoBack()}

            />
          </Grid>
          <Grid item xs={11}>
            <div className={classes.wrapper}>
              <div className={classes.root}>
                <div className={classes.headingWrap}>
                  <Typography variant="h4" name="textPublicationName">
                    {reducer.publication.name}
                  </Typography>
                </div>
                <Paper className={classes.paper}>
                  <PagesSlider
                    items={this.state.spreads}
                    loadOffers={this.changePages.bind(this)}
                    selectedPages={this.state.selectedPages}
                    publicationId={this.props.fotohaandteringReducer.publication.id}
                    handleOpenPreviewPublicationDialog={(index) => this.openPreviewPublication(index)}
                    publicationPagesNumber={this.props.fotohaandteringReducer.publication.numberOfPages}
                    existingPdfs={this.state.existingPdfs}
                  />
                </Paper>
                <OffersContent
                  onRef={(ref) => (this.copyOffer = ref)}
                  onPageContainerRefs={(ref) => this.offerPageContainers = ref}
                  onOfferChange={this.handleOfferUpdated.bind(this)}
                  handleCloseDialogMultipleOrder={this.handleCloseDialogMultipleOrder.bind(this)}
                  handleCreatePhotoOrderOffer={(offer) => this.handleCreatePhotoOrderOffer(offer)}
                  pagesWithOffers={this.state.pagesWithOffers}
                  categories={this.state.photoOrderCategories}
                  handleEditOffer={(id) => this.handleEditOffer(id)}
                  setPageContentDescription={this.setPageContentDescription}
                  togglePageContentDisabled={this.togglePageContentDisabled}
                  savePageContentDescription={this.savePageContentDescription}
                  deactivatedPhotos={this.state.deactivatedPhotos}
                  updateDeactivatedPhotos={this.updateDeactivatedPhotos.bind(this)}
                  isReadOnlyMode={isReadOnlyMode}
                />
              </div>
              <div>
              {isReadOnlyMode ? undefined : (
                  <Fab
                    color="secondary"
                    aria-label="Add"
                    className={classes.fab}
                    onClick={this.handleOpenMenu}
                    ref={this.fabButton}
                  >
                    <MoreVertIcon />
                  </Fab>
                )}
                <div className={classes.pageNumber}>
                  Side {this.state.pageNumber}
                </div>
                <div className={classes.arrows}>
                  <ArrowBackIosRoundedIcon
                    className={this.state.disableGoBack ? classes.disableGoBack : undefined}
                    onClick={() => this.changeSpreadsWithArrows("back")}
                  />
                  <ArrowForwardIosRoundedIcon
                    className={this.state.disableGoForward ? classes.disableGoForward : undefined}
                    onClick={() => this.changeSpreadsWithArrows("forward")}
                  />
                </div>
              </div>
              <Menu
                id="simple-menu"
                anchorEl={this.state.anchorEl}
                open={Boolean(this.state.anchorEl)}
                onClose={this.handleCloseMenu}
              >
                
                {!IsFeatureEnabled(featureNames.PhotoOrders_Enabled) ? undefined :
                  <MenuItem onClick={() => this.handleClickOpenMultipleOrder()}>
                    Opret fotobestilling
                  </MenuItem>
                }
                 {!IsFeatureEnabled(featureNames.Indesign_Enabled) ? undefined :
                  <MenuItem onClick={this.handleClickOpenInDesignChoose}>
                    Send til Indesign
                  </MenuItem>
                }
                 {!IsFeatureEnabled(featureNames.Indesign_Enabled) ? undefined :
                  <MenuItem onClick={this.handleClickOpenInDesignPosterChoose}>
                    Send til Indesign Poster
                  </MenuItem>
                }
                {!IsFeatureEnabled(featureNames.Indesign_Enabled) ? undefined :
                  <MenuItem onClick={this.handleSendAllToIndesignGenerator}>
                    Send alle til Indesign
                  </MenuItem>
                }
                {!canReadApplication(applications.REDIGERTILBUD) ? undefined :
                  <MenuItem onClick={this.handleClickCopyOffer}>
                    Kopier tilbud
                  </MenuItem>
                }
              </Menu>
            </div>
            {this.props.offersReducer.editDialog && !this.state.loading ?
              <DialogEditOffer
                offerId={this.props.offersReducer.id}
                callback={(id) => this.handleOfferUpdated(id)}
                offersTags={this.state.offersTags}
              /> : undefined
            }
            {this.props.fotohaandteringReducer.dialogAddPhotoOrderOpen ? (
              <DialogAddPhotoOrderV2
                onPhotoOrderCreated={() => this.handlePhotoOrderCreated()}
                categories={this.state.photoOrderCategories}
                orderOrigin={parseInt(photoOrderOrigin.Publication)}
              />
            ) : undefined}
            {this.state.publicationPreviewDialog ?
              <DialogPreviewPublication
                handleClose={() => this.closePreviewPublication()}
                publicationId={this.props.fotohaandteringReducer.publication.id}
                publicationPages={this.props.fotohaandteringReducer.publication.numberOfPages}
                selectedImageIndex={this.state.selectedImageIndex}
              /> : undefined}

          </Grid>
        </Grid>
      </>
    );
  }
}

FotoHaandtering = connect((state) => state)(FotoHaandtering);
const FotoHaandteringStyled = withStyles(styles)(FotoHaandtering);
export default FotoHaandteringStyled;
