import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { getShortDate } from "../../../helpers/UtilHelper";

export default function DialogAllNotifications(props) {
    const { handleClose, notifications } = props;
    const [loading, setloading] = useState(false);

    useEffect(() => {
    }, []);

    return (
        <>
            <Dialog
                open={true}
                onClose={handleClose}
                aria-labelledby="form-dialog-title"
                fullWidth
                maxWidth="md"
            >
                <div>
                    <DialogTitle id="form-dialog-title">
                        Alle notifikationer
                    </DialogTitle>
                </div>
                <DialogContent>
                    <div style={{ minHeight: 500 }}>
                        {notifications.map((notification, i) =>
                            <div
                                key={i}
                                style={{
                                    borderBottom: "1px solid #455a64"
                                }}>
                                <h3>
                                    {getShortDate(notification.created)}
                                </h3>
                                <p>{notification.header}</p>
                                <p>{notification.message}</p>
                            </div>
                        )}

                    </div>

                </DialogContent>
                <DialogActions>
                    <Button
                        onClick={handleClose}
                        disabled={loading}
                    >
                        Luk
                    </Button>
                </DialogActions>
            </Dialog>
        </>

    )
}