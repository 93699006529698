export function deleteTagChip(tag, queryArray, handleChangeTagsSearchArray) {
    const selectedTags = [...queryArray];
    const newTags = selectedTags.filter(x => x !== tag);
    handleChangeTagsSearchArray(newTags);
}

export function addTagChip(tag, queryArray, handleChangeTagsSearchArray) {
    const newTag = tag.find(x => !queryArray.includes(x));

    if (!newTag) {
        return
    }

    let selectedTags = [...queryArray];
    selectedTags = [...selectedTags, newTag];
    handleChangeTagsSearchArray(selectedTags);
}