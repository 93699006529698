import { Button } from '@material-ui/core';
import React from 'react';
import SwapHorizontalCircleRoundedIcon from '@material-ui/icons/SwapHorizontalCircleRounded';

export default function AddScreenItem({ item, swapLeft, itemIndex, swapActivated, blocked, openFindMedia, openFindOffer }) {
    return (
        <>
            {!swapActivated ? undefined : <div style={{ position: "relative" }}>

                {itemIndex === 0 ? undefined :
                    <SwapHorizontalCircleRoundedIcon
                        onClick={() => swapLeft(item)}
                        style={{
                            fontSize: 30,
                            cursor: "pointer",
                            // color: "white",
                            position: "absolute", top: 150, left: -15,
                            zIndex: 100

                        }} />
                }</div>}
            <div style={{
                width: 180,
                height: 320,
                margin: "0 5px",
                padding: "40px 20px",
                backgroundColor: "#d6ddd9",
                justifyContent: 'center',
                border: "solid rgb(107, 135, 120) 2px"
            }}
            >
                <div>
                    <Button
                        disabled={blocked}
                        onClick={() => openFindMedia(itemIndex)}
                        variant="text"
                        style={{
                            display: "block",
                            justifyContent: 'center',
                            textTransform: "none",
                            color: "rgb(19, 63, 39)"
                        }}
                        fullWidth>
                        <img alt="plus" src={process.env.PUBLIC_URL + '/images/filmmakerIcons/plus.png'} />
                        <br />
                        Tilføj medie
                    </Button>
                </div>
                <div style={{
                    marginTop: 45
                }}>
                    <Button
                        disabled={blocked}
                        onClick={() => openFindOffer(itemIndex)}
                        variant="text"
                        style={{
                            display: "block",
                            justifyContent: 'center',
                            textTransform: "none",
                            color: "rgb(19, 63, 39)"
                        }}
                        fullWidth>
                        <img alt="plus" src={process.env.PUBLIC_URL + '/images/filmmakerIcons/plus.png'} />
                        <br />
                        Tilføj tilbud
                    </Button>
                </div>
            </div>
        </>
    )
}
