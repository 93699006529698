import React from "react";
import MUIDataTable from "mui-datatables";
import ActionButtons from "./ActionButtons";
import { getCustomerPhoto } from "../../../helpers/ImageHelper";
import { connect } from "react-redux";
import { fetchCustomers } from "../../../redux/actions/utils/customers.utils.actions";
import {
  loadEntity,
} from "../../../helpers/UtilsReducerHelper";
import {
  getShortDate
} from "../../../helpers/UtilHelper";
import history from "../../../routes/history";

class DataTable extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      columns: [],
    };
  }

  componentDidMount() {
    this.setState({ columns: this.columns() });

    loadEntity(
      this.props.utilsReducer.customers,
      this.props.dispatch,
      fetchCustomers
    );
  }

  columns() {
    return [ "", "Jobnummer", "Kunde", "Navn", "År/Uge", "Importeret", "Gyldigt fra", "Gyldigt til", ""];
  }

  isStatusOpen(status) {
    return status.toLowerCase() === "aktiv";
  }

  getStatusColor(status) {
    switch (status) {
      case 1:
        return { color: "#00A000", name: "Aktiv" };
      case 2:
        return { color: "#888888", name: "Lukket" };
      case 9:
        return { color: "#f63242", name: "Fejl" };
      default:
        return { color: "#4493a9", name: "Ukendt" };
    }
  }

  async handleView(publicationId) {
    history.push("/elektronisk/" + publicationId);
  }

  getDataTable(publications) {
    return publications.map(publication => {

      let customer = this.props.utilsReducer.customers.find(a => a.id === publication.customerId);
      let customerName = customer === null ? "Ukendt" : customer.name;

      return [
        <span style={{ paddingLeft: 20 }} />,
        // publication.id,
        <div onClick={() => this.handleView(publication.id)} style={{ cursor: "pointer" }}>
          {publication.jobNumber}
        </div>,
        <img style={{ width: 50 }} src={getCustomerPhoto(customerName)} alt={customerName}></img>,
        publication.name,
        publication.yearAndWeek,
        getShortDate(publication.creationDate),
        getShortDate(publication.startDate),
        getShortDate(publication.endDate),
        <ActionButtons
          publication={publication}
          isStatusOpen={publication.status === "Aktiv"}
          handleReload={this.props.onSearch}
          handleView={() => this.handleView(publication.id)}
        />
      ];
    });
  }

  render() {
    const data = this.getDataTable(this.props.dataRows);
    const options = {
      print: false,
      download: false,
      viewColumns: false,
      selectableRows: false,
      pagination: false,
      sort: false,
      filter: false,
      search: false,
    };

    return (
      <MUIDataTable
        data={data}
        columns={this.state.columns}
        options={options}
      />
    );
  }
}

export default connect(state => state)(DataTable);
