import React, { useState } from 'react';
import { withStyles, Dialog, DialogContent, DialogActions, Button, DialogTitle, Tabs, Tab } from '@material-ui/core';
import { FilePond } from 'react-filepond';
import { registerPlugin } from 'filepond';
import filepondPluginImagePreview from 'filepond-plugin-image-preview';
import filepondPluginFileValidateType from 'filepond-plugin-file-validate-type';
import { replacePhoto } from '../../../sections/photos/Requests';
import { getMotivId } from '../../../../helpers/UtilHelper';
import {validateFileExtentionOnUpload} from "../../../shared/SharedFunctions";

registerPlugin(filepondPluginImagePreview, filepondPluginFileValidateType);

const styles = theme => ({
    tabsRoot: {
        borderBottom: "1px solid #e8e8e8",
    },
    tabsIndicator: {
        backgroundColor: "#1890ff",
    },
    root: {
        display: "flex",
        flexWrap: "wrap",
        justifyContent: "space-around",
        overflow: "hidden",
        backgroundColor: theme.palette.background.paper,
    },
    contentWrapper: {
        minHeight: 500
    }
});

function DialogReplacePhoto({ motivId, handleCancel,
    handleConfirm, refreshPhoto, performSearch, callback, ...props }) {
    const { classes } = props;
    const [value,] = useState(0);
    const [files, setFiles] = useState([]);
    const [uploading, setUploading] = useState(null);
    const handleSetFile = (fileItems) => {
        setFiles(fileItems.map(fileItem => fileItem.file));
    };

    const handleUpload = async () => {
        setUploading(true)
        const formData = new FormData();

        formData.append("file", files[0]);

        await replacePhoto(formData, motivId);

        if (refreshPhoto) {
            refreshPhoto();
        }

        if (performSearch) {
            performSearch();
        }

        // any function that are not common for all places the dialog is used
        if (callback) {
            callback();
        }
        setUploading(false);
        handleCancel();
    };

    return (
        <>
            <Dialog
                open={true}
                onClose={handleCancel}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                fullWidth={true}
                maxWidth="md"
            >
                <DialogTitle id="alert-dialog-title">Skift billede | {getMotivId(motivId)}</DialogTitle>
                <DialogContent>
                    <Tabs
                        value={value}
                        onChange={() => { }}
                        classes={{
                            root: classes.tabsRoot,
                            indicator: classes.tabsIndicator,
                        }}
                    >
                        <Tab
                            disableRipple
                            classes={{
                                root: classes.tabRoot,
                                selected: classes.tabSelected,
                            }}
                            label={"Upload"}
                        />
                    </Tabs>
                    {value === 0 && (
                        <div className={classes.contentWrapper}>
                            <FilePond
                                allowImagePreview={true}
                                files={files}
                                onupdatefiles={handleSetFile}
                                allowMultiple={false}
                                maxFiles={1}
                                imagePreviewHeight={500}
                                beforeAddFile={(item) => validateFileExtentionOnUpload(item.file)}
                                labelIdle={
                                    'Træk og slip din billedfil eller <span class="filepond--label-action">Gennemse</span>'
                                }
                            />
                        </div>
                    )}
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCancel} color="secondary" autoFocus>
                        Annuller
                    </Button>
                    {uploading ? <Button disabled color="primary" autoFocus>
                        Uploading...
                    </Button> : <Button onClick={handleUpload} color="primary" autoFocus>
                        Skift billed
                    </Button>}
                </DialogActions>
            </Dialog>
        </>
    )

}

export default withStyles(styles)(DialogReplacePhoto)