import React, { useState, useEffect } from "react";
import Chip from "@material-ui/core/Chip";
import TextField from "@material-ui/core/TextField";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { makeStyles } from "@material-ui/core";

export default function AutoSuggestionWithChip({ autoSuggestData, tags, setTags, allowAddNew, label }) {


    const deleteTagChip = (tag) => {
        let selectedTags = [...tags];
        const newTags = selectedTags.filter(x => x !== tag);
        setTags(newTags);
    }

    const addTagChip = (tag) => {
        const newTag = tag.find(x => !tags.includes(x));

        if (!newTag) {
            return
        }
        let selectedTags = [...tags];
        selectedTags = [...selectedTags, newTag];
        setTags(selectedTags);
    }

    return (
        <Autocomplete
            closeIcon={false}
            freeSolo={allowAddNew}
            multiple
            id="fixed-tags-demo"
            name="selectProductTags"
            value={tags}
            onChange={(event, newTag) => addTagChip(newTag)}
            options={autoSuggestData.map(x => x.name)}
            renderTags={(tagValue, getTagProps) =>
                tagValue.map((option, index) => (
                    <Chip
                        label={option}
                        {...getTagProps({ index })}
                        onDelete={() => deleteTagChip(option)}
                    />
                ))
            }
            renderInput={(params) => (
                <TextField
                    {...params}
                    label={!label ? "Tags" : label}
                    variant="outlined"
                    placeholder="Tilføj tag"
                />
            )}
        />
    )
}
