
import React from 'react'
import { styles } from '../shared'
import DescriptionButton from '../sharedComponents/DescriptionButton';
import RemoveCircleIcon from '@material-ui/icons/RemoveCircle';
import { endpoints } from '../../../../../constants/services.constants';

export default function TemplateItemCarousel({ item, selectTemplate, deleteTemplate }) {
    return (
        <div
            style={{
                width: 180,
                margin: "0 5px"
            }}
        >
            <div style={{
                position: "relative"
            }}>
                <RemoveCircleIcon
                    onClick={() => deleteTemplate(item)}
                    style={{
                        fontSize: 30,
                        cursor: "pointer",
                        position: "absolute", right: -8
                    }} />
                <img
                    width="180"
                    height="320"
                    src={endpoints.STORAGE_PUBLIC_FILMMAKER.replace("{path}",item.urlThumbnailRelative)}
                    alt={item.name}
                />
            </div>
            <br />
            <DescriptionButton
                disabled={false}
                onClick={() => selectTemplate(item.id)}
                fullWidth
                text={item.name}
                style={styles.boldButton}
            />
        </div>
    )
}