import React from "react";

import {
  FormControl, 
  TextField, 
  Typography } from '@material-ui/core';

// material

import AutoSuggestionWithChip from "../../../shared/components/autoSuggestionComponent/AutoSuggestionWithChip";
import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import AccordionDetails from "@material-ui/core/AccordionDetails";

import ExpandMoreIcon from "@material-ui/icons/ExpandMore";

import Resources from "../../../../Resources.json"
import {GetResource} from "../../../../helpers/ResourceHelper" 

export const InformationTab = ({
  name,
  description,
  multiProductFieldValues,
  onChange,
  handleMultiProductFieldValuesChange,
  leverandor,
  gs1DataAutoSuggest,
  selectLeverandor,
  selectTags,
  allTags,
  tags,
  ...props
}) => (
  <div style={{ maxHeight: 400 }}>
    <TextField
      margin="normal"
      label={GetResource(Resources.Product_Edit_Information_Name)}
      type="text"
      name="name"
      value={name || ""}
      onChange={onChange}
      fullWidth
      id="textfieldProductName"
    />

    <TextField
      margin="normal"
      label={GetResource(Resources.Product_Edit_Information_Description)}
      type="text"
      name="description"
      value={description || ""}
      onChange={onChange}
      InputProps={{
        multiline: true,
      }}
      fullWidth
      id="textfieldProductDescription"
    />

    <br />
    <br />

    <Accordion>
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls="panel1a-content"
        id="panel1a-header"
      >
        <Typography>Yderligere information</Typography>
      </AccordionSummary>
      <AccordionDetails
        style={{
          display: "inline-block",
          width: "100%",
          marginBottom: "20px",
        }}
      >
        {multiProductFieldValues.map((data, index) => (
          <>
            <TextField
              key={index}
              id={index.toString()}
              data-key={index}
              label={data.fieldName}
              type="text"
              name={data.fieldName}
              value={data.fieldValue || ""}
              InputProps={{
                multiline: true,
              }}
              onChange={handleMultiProductFieldValuesChange}
              fullWidth
              style={{
                marginBottom: "20px",
              }}
            />
          </>
        ))}
      </AccordionDetails>
    </Accordion>
    {allTags == null ? <Typography>Henter tags...</Typography> :
                            <FormControl style={{
                              display: "inline-block",
                              width: "100%",
                              marginTop: "20px",
                            }}>
                            <AutoSuggestionWithChip
          autoSuggestData={allTags}
          tags={tags}
          setTags={(newTags) => selectTags(newTags)}
          allowAddNew={true}
          fullWidth
        />
                  </FormControl>
}
  </div>
);
