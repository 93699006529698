import { ADD_TOASTS_MESSAGE } from "../actions/toasts.actions";
const initState = {
  messages: [
    // {'message':'Error hi2','type':'error'},
    // {'message':'hi32','type':'error'}
  ]
};
export default (state = initState, action) => {
  switch (action.type) {
    case ADD_TOASTS_MESSAGE:
      return {
        ...state,
        messages: state.messages.concat(action.payload.message)
      };
    default:
      return state;
  }
};
